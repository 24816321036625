import { useCurrentLocation } from "./use-current-location";
import { useCurrentParams } from "./use-current-params";

export const useRedirect = () => {
  const { redirect: redirectParam } = useCurrentParams();
  const location = useCurrentLocation();

  const pathWithRedirect = (path: string, redirect: string) => {
    return `${path}?redirect=${encodeURIComponent(redirect)}`;
  };

  const pathWithRedirectToCurrentLocation = (path: string) =>
    pathWithRedirect(path, location);

  return {
    redirect: redirectParam,
    pathWithRedirect,
    pathWithRedirectToCurrentLocation,
  };
};
