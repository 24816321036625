import { memo, ReactElement } from "react";
import isEqual from "react-fast-compare";
import { Alert } from "app/design/alert";
import { ProfileCard } from "app/features/profile/card";
import {
  BaseCareerExperienceProps,
  withCareerExperienceProps,
} from "../with-career-experience";

interface WorkExperienceCardProps extends BaseCareerExperienceProps {
  organisationName: string;
  title: string;
  startDate: string;
  endDate: string;
  description?: string[];
  form: ReactElement;
}

export const WorkExperienceCard = memo(
  withCareerExperienceProps(
    ({
      organisationName,
      title,
      startDate,
      endDate,
      form,
      description = [],
      completed,
      onDelete,
    }: WorkExperienceCardProps) => (
      <ProfileCard
        header={organisationName}
        subheader={title}
        startDate={startDate}
        endDate={endDate}
        showDivider
        form={form}
        onDelete={onDelete}
        showFormOnRender={!completed}
      >
        <ProfileCard.Description description={description} />
        {description.length < 3 ? (
          <Alert sx={{ mt: "$3" }} variant="warning">
            Add 3 bullet points per work experience to help demonstrate the
            knowledge you applied in this role.
          </Alert>
        ) : null}
      </ProfileCard>
    )
  ),
  isEqual
);
