import { Platform } from "react-native";
import { alphaColor } from "app/design/utils";
import { brandColors } from "../colors";
import { SCREEN_PADDING_DESKTOP, SCREEN_PADDING_MOBILE } from "./layout";

const CARD_BLURRY_BACKGROUND_STYLES = {
  backgroundColor:
    Platform.OS === "web" ? alphaColor("#fffdfd", 0.85) : "#fffcfb",
  ...(Platform.OS === "web" && {
    backdropFilter: "blur(7px)",
  }),
};

const card = {
  default: {
    borderRadius: "md",
    p: ["$3", "$4"],
    width: "100%",
    borderWidth: 2,
    borderColor: "$white",
    boxShadow: "default",
    textDecorationLine: "none",
    ...CARD_BLURRY_BACKGROUND_STYLES,
    _hover: {
      backgroundColor: brandColors.white,
    },
  },
  reset: {
    borderRadius: 0,
    backdropFilter: null,
    backgroundColor: "transparent",
    borderWidth: 0,
    boxShadow: "none",
    _hover: {
      backgroundColor: "transparent",
    },
  },
  primary: {
    backgroundColor: alphaColor(brandColors.blue, 0.95),
    borderColor: "$blue",
    _hover: {
      backgroundColor: alphaColor(brandColors.blue, 1),
    },
  },
  secondary: {
    borderColor: alphaColor(
      brandColors.blue,
      Platform.select({ android: 0.11, default: 0.015 })
    ),
    backgroundColor: alphaColor(brandColors.blue, 0.1),
    boxShadow: "none",
    _hover: {
      backgroundColor: alphaColor(brandColors.blue, 0.15),
    },
  },
  tertiary: {
    borderColor: alphaColor(
      "#e08174",
      Platform.select({ android: 0.91, default: 0.1 })
    ),
    backgroundColor: alphaColor("#e08174", 0.9),
    _hover: {
      backgroundColor: alphaColor("#e08174", 1),
    },
  },
  ghost: {
    backgroundColor: alphaColor(brandColors.blue, 0),
    borderColor: "transparent",
    boxShadow: "none",
    backdropFilter: null,
    p: 6,
    mx: -6,
    borderRadius: "sm",
    _hover: {
      backgroundColor: alphaColor(brandColors.blue, 0.075),
    },
  },
  fullWidthMobile: {
    mx: [0 - SCREEN_PADDING_MOBILE, 0],
    px: [SCREEN_PADDING_MOBILE, "$4"],
    borderLeftWidth: [0, 2],
    borderRightWidth: [0, 2],
    borderRadius: [0, "md"],
    width: "auto",
  },
  filters: {
    mt: [0 - SCREEN_PADDING_MOBILE, 0 - SCREEN_PADDING_DESKTOP, null, 0],
    mx: [0 - SCREEN_PADDING_MOBILE, 0],
    px: [SCREEN_PADDING_MOBILE, "$4"],
    borderTopWidth: [0, null, null, 2],
    borderLeftWidth: [0, 2],
    borderRightWidth: [0, 2],
    borderTopLeftRadius: [0, null, null, "md"],
    borderTopRightRadius: [0, null, null, "md"],
    borderBottomLeftRadius: [0, "md"],
    borderBottomRightRadius: [0, "md"],
    width: "auto",
  },
};

export { card, CARD_BLURRY_BACKGROUND_STYLES };
