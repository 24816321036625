import { ReactElement } from "react";
import { useApolloClient } from "@apollo/client";
import { useAppState, isForeground } from "app/hooks/use-app-state";
import { useAuth } from "app/use-auth";

interface RefetchProviderProps {
  children: ReactElement;
}

const RefetchProvider = ({ children }: RefetchProviderProps) => {
  const { isAuthenticated } = useAuth();
  const client = useApolloClient();

  useAppState(
    async (appState, nextAppState) => {
      if (!isForeground(appState, nextAppState)) return;
      if (!isAuthenticated) return;
      client.reFetchObservableQueries();
    },
    [isAuthenticated]
  );

  return children;
};

export { RefetchProvider };
