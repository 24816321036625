import { Pressable, Row, Sx, useDripsyTheme, useSx } from "dripsy";
import { Feather } from "@expo/vector-icons";
import AutoHeightImage from "react-native-scalable-image";
import { Text } from "app/components/text";
import { useWebLink, WebLink } from "app/components/web-link";
import { ChannelMessage } from "../../types";

interface ImageAttachmentProps {
  uri: string;
  width?: number;
  sx?: Sx;
}

const ImageAttachment = ({
  uri,
  width = 200,
  sx: _sx,
}: ImageAttachmentProps) => {
  const sx = useSx();
  const webLinkProps = useWebLink(uri);

  return (
    <Pressable {...webLinkProps} sx={{ alignSelf: "flex-start" }}>
      <AutoHeightImage
        width={width}
        source={{ uri }}
        style={sx({ my: "$1", borderRadius: 6, ..._sx })}
      />
    </Pressable>
  );
};

interface FileAttachmentProps {
  filename: string;
  url: string;
}

const FileAttachment = ({ filename, url }: FileAttachmentProps) => {
  const { theme } = useDripsyTheme();

  return (
    <WebLink
      url={url}
      sx={{
        textDecorationLine: "none",
      }}
    >
      <Row
        sx={{
          alignItems: "center",
          maxWidth: 200,
        }}
      >
        <Feather name="paperclip" size={16} color={theme.colors.$black} />
        <Text
          numberOfLines={1}
          sx={{ ml: "$2", textDecorationLine: "underline" }}
        >
          {filename}
        </Text>
      </Row>
    </WebLink>
  );
};

const IMAGE_REGEX = new RegExp(/.(jpg|jpeg|png|gif)$/i);

type AttachmentType = NonNullable<ChannelMessage["attachment"]>;

type RenderImageAttachment = (props: {
  attachment: AttachmentType;
}) => React.ReactNode;

const defaultRenderImageAttachment: RenderImageAttachment = ({
  attachment,
}) => <ImageAttachment uri={attachment.url} />;

type RenderFileAttachment = (props: {
  attachment: AttachmentType;
}) => React.ReactNode;

const defaultRenderFileAttachment: RenderFileAttachment = ({ attachment }) => (
  <FileAttachment {...attachment} />
);

interface AttachmentProps {
  attachment: AttachmentType;
  renderImage?: RenderImageAttachment;
  renderFile?: RenderFileAttachment;
}

const Attachment = ({
  attachment,
  renderImage = defaultRenderImageAttachment,
  renderFile = defaultRenderFileAttachment,
}: AttachmentProps) => {
  if (attachment.filename.match(IMAGE_REGEX)) {
    return <>{renderImage({ attachment })}</>;
  }

  return <>{renderFile({ attachment })}</>;
};

export type {
  AttachmentType,
  RenderImageAttachment,
  RenderFileAttachment,
  ImageAttachmentProps,
  FileAttachmentProps,
  AttachmentProps,
};

export { Attachment, ImageAttachment, FileAttachment };
