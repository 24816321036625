import {
  useDeleteMessage,
  DeletableMessage,
} from "../../hooks/use-delete-message";
import { MessageAction } from "./message-action-item";

interface DeleteMessageActionProps {
  message: DeletableMessage;
}

const DeleteMessageAction = ({ message }: DeleteMessageActionProps) => {
  const deleteMessage = useDeleteMessage({ message });
  return (
    <MessageAction
      sx={{ ml: "$1" }}
      label="Delete"
      iconName="trash"
      onPress={deleteMessage}
    />
  );
};

export type { DeleteMessageActionProps };
export { DeleteMessageAction };
