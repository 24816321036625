import {
  ApplicationCompletion,
  CandidateV2,
  MatchResponse,
  MatchResponseType,
  ProfileCompletion,
} from "app/types/generated/schema";

export const APPLICATION_SCREEN_TARGETS = {
  applicationQuestion: "application-question",
  passport: "passport",
  workExperience: "work-experience",
  educationHistory: "education-history",
  languageQualifications: "language-qualifications",
  registrationDetails: "registration-details",
};

export const useApplicationCompletionChecks = (
  candidate?: {
    profileCompletion: CandidateV2["profileCompletion"];
  },
  matchResponse?: Pick<MatchResponse, "type"> & {
    applicationCompletion?: ApplicationCompletion[];
  },
  requiresLanguagePass = true
) => {
  if (!candidate || !matchResponse) return { checks: [], percentage: 0 };
  const checks = [
    {
      label: "Application question",
      completed:
        (matchResponse.type === MatchResponseType.Apply ||
          matchResponse.applicationCompletion?.includes(
            ApplicationCompletion.HasApplicationQuestion
          )) ??
        false,
      scrollToTarget: APPLICATION_SCREEN_TARGETS.applicationQuestion,
    },
    {
      label: "Upload passport",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasPassport
      ),
      scrollToTarget: APPLICATION_SCREEN_TARGETS.passport,
    },
    {
      label: "Work experience",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasWorkExperience
      ),
      scrollToTarget: APPLICATION_SCREEN_TARGETS.workExperience,
    },
    {
      label: "Education history",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasEducationHistory
      ),
      scrollToTarget: APPLICATION_SCREEN_TARGETS.educationHistory,
    },
    {
      label: "Registration details",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasRegistrationDetails
      ),
      scrollToTarget: APPLICATION_SCREEN_TARGETS.registrationDetails,
    },
    ...(requiresLanguagePass
      ? [
          {
            label: "Language qualifications",
            completed: candidate.profileCompletion.includes(
              ProfileCompletion.HasLanguageResults
            ),
            scrollToTarget: APPLICATION_SCREEN_TARGETS.languageQualifications,
          },
        ]
      : []),
  ];

  const percentage = Math.round(
    (checks.filter((check) => check.completed).length / checks.length) * 100
  );

  return { checks, percentage };
};
