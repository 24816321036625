import { ProfileCompletion } from "app/types/generated/schema";
import { useProfileCompletionQuery } from "app/types";

const PROFILE_SCREEN_TARGETS = {
  passport: "passport",
  workExperience: "work-experience",
  educationHistory: "education-history",
  languageQualifications: "language-qualifications",
  registrationDetails: "registration-details",
  moreAboutYou: "more-about-you",
};

const useProfileCompletion = () => {
  const { data, loading, error } = useProfileCompletionQuery();

  const candidate = data?.me;

  if (candidate?.__typename !== "CandidateV2")
    return {
      loading,
      error,
      checks: [],
      completed: false,
      percentage: 0,
      currentUser: data?.me,
    };

  const checks = [
    {
      label: "Upload passport",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasPassport
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.passport,
    },
    {
      label: "Work experience",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasWorkExperience
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.workExperience,
    },
    {
      label: "Education history",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasEducationHistory
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.educationHistory,
    },
    {
      label: "Language qualifications",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasLanguageResults
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.languageQualifications,
    },
    {
      label: "Registration details",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasRegistrationDetails
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.registrationDetails,
    },
    {
      label: "More about you",
      completed: candidate.profileCompletion.includes(
        ProfileCompletion.HasMoreAboutYou
      ),
      scrollToTarget: PROFILE_SCREEN_TARGETS.moreAboutYou,
    },
    {
      label: "Add a photo",
      completed: !!candidate.photoUrl,
    },
  ];

  const percentage = Math.round(
    (checks.filter((check) => check.completed).length / checks.length) * 100
  );

  return {
    loading,
    error,
    checks,
    percentage,
    completed: percentage === 100,
    currentUser: data?.me,
  };
};

export { useProfileCompletion, PROFILE_SCREEN_TARGETS };
