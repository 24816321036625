import { H2, Text, View } from "dripsy";
import { Button } from "app/design/button";

export const EmptyMatches = () => (
  <>
    <View sx={{ alignItems: "center" }}>
      <H2 sx={{ mb: "$4" }}>You&apos;ve seen all your matches</H2>
      <Text sx={{ mb: ["$6", "$7"], textAlign: "center" }}>
        We add new jobs every day, so be sure to check back soon to see more. If
        you want to see more jobs now, try changing your preferences.
      </Text>
    </View>
    <Button containerSx={{ mb: "$2" }}>Change preferences</Button>
    <Button href="/jobs" variant="secondary">
      Review my jobs
    </Button>
  </>
);
