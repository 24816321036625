import { useRouter } from "solito/router";
import { useEffect, useState } from "react";
import { useToast } from "app/components/use-toast";
import {
  useCurrentPathname,
  useCurrentUser,
  useEffectAfterRender,
} from "app/hooks";
import { AuthorizationCheck } from "./rules";

const useAuthorizer = <TOutputUser,>() => {
  const router = useRouter();
  const pathname = useCurrentPathname();
  const toast = useToast();
  const { currentUser } = useCurrentUser();

  // Allows users to render loading state to avoid screen flash
  const [authorizing, setAuthorizing] = useState(true);

  useEffect(() => {
    setAuthorizing(true);
  }, [pathname]);

  // This seems to handle locking on authorizing if their is
  // a failure to authorize. This is a bit of a hack, but it
  // works for now.
  useEffectAfterRender(() => {
    setAuthorizing(false);
  }, [pathname]);

  return {
    authorizing,
    authorize: (rule?: AuthorizationCheck<TOutputUser>) => {
      if (!rule) {
        setAuthorizing(false);
        return {
          success: true,
          user: currentUser,
        };
      }

      const result = rule({
        success: true,
        user: currentUser,
      });

      if (!result.success) {
        const { redirect, toastOptions } = result.unauthorizedOptions;
        router.push(redirect);

        if (toastOptions) {
          toast.show(toastOptions);
        }
        return result;
      }

      setAuthorizing(false);
      return result;
    },
  };
};

export { useAuthorizer };
