import { List, ListItem } from "app/components/list";
import { Text } from "app/components/text";
import { JobDetailsQuery } from "app/types";
import { Divider } from "./divider";
import { Section, SectionHeader } from "./section";

interface DescriptionProps {
  detailedDescription: JobDetailsQuery["job"]["detailedDescription"];
  description: JobDetailsQuery["job"]["description"];
}

const Description = ({
  detailedDescription,
  description,
}: DescriptionProps) => {
  return (
    <Section>
      <SectionHeader>What the job involves</SectionHeader>

      {detailedDescription ? (
        <List sx={{ mb: "$4" }}>
          {detailedDescription.map(({ content }, i) => (
            <ListItem key={i}>{content}</ListItem>
          ))}
        </List>
      ) : (
        <Text>{description}</Text>
      )}

      {/* <Button
        variant="secondary"
        containerSx={{ alignSelf: "center" }}
        icon={<Feather name="file-text" size={20} />}
      >
        Download job description
      </Button> */}

      <Divider />
    </Section>
  );
};

export { Description };
