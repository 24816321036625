import { Row, useDripsyTheme, useSx, View } from "dripsy";
import { MotiView, useAnimationState } from "moti";
import { MotiPressable } from "moti/interactions";
import { memo } from "react";
import isEqual from "react-fast-compare";

import {
  Answer as AnswerType,
  Scalars,
  VoteType,
} from "app/types/generated/schema";
import { useEffectAfterRender } from "app/hooks/use-effect-after-render";
import {
  ThumbsUpFilledIcon,
  ThumbsUpIcon,
} from "app/design/svgs/icons/thumbs-up";
import { useAsyncToast } from "app/components/use-toast";
import { Text } from "app/components/text";

import { SKIP_ANIMATION_ON_RENDER } from "app/utils/animation";
import { ProtectCommunityFeature } from "../protect-feature";
import { useToggleAnswerVote } from "./hooks/use-toggle-answer-vote";

const VoteIcon: React.FC<{
  isActive: boolean;
  isHovered?: boolean;
}> = memo(({ isActive, isHovered = false }) => {
  const animationState = useAnimationState(
    {
      initial: {
        rotate: "0deg",
        translateY: 0,
        scale: 1,
      },
      active: {
        rotate: ["-20deg", "0deg"],
        translateY: [-6, 0],
        scale: [1.2, 1],
      },
    },
    SKIP_ANIMATION_ON_RENDER
  );
  const { theme } = useDripsyTheme();

  useEffectAfterRender(() => {
    if (isActive) {
      animationState.transitionTo("active");
    } else {
      animationState.transitionTo("initial");
    }
  }, [isActive]);

  return (
    <MotiView
      state={animationState}
      transition={{
        type: "timing",
        duration: theme.transitionDurations.normal,
      }}
    >
      {isActive ? (
        <ThumbsUpFilledIcon
          fill={isHovered ? theme.colors.$blueHover : theme.colors.$blue}
        />
      ) : (
        <ThumbsUpIcon
          fill={isHovered ? theme.colors.$blue : theme.colors.$black}
        />
      )}
    </MotiView>
  );
}, isEqual);
VoteIcon.displayName = "VoteIcon";

VoteIcon.displayName = "VoteIcon";

interface VoteButtonProps {
  answerId: Scalars["MigrateID"];
  count: number;
  label: string;
  type: VoteType;
  isActive: boolean;
}

const VoteButton: React.FC<VoteButtonProps> = ({
  answerId,
  count,
  label,
  type,
  isActive,
}) => {
  const toggleVote = useToggleAnswerVote(answerId, type);
  const sx = useSx();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't add vote",
      description: "Please try again or contact support.",
    },
  });

  const handlePress = withToast(() =>
    toggleVote({
      variables: {
        input: {
          answerId,
          type,
        },
      },
    })
  );

  return (
    <ProtectCommunityFeature.Vote>
      <MotiPressable
        accessibilityLabel={label}
        onPress={handlePress}
        containerStyle={sx({ mr: "$4", zIndex: 2 })}
        style={sx({ flexDirection: "row", alignItems: "center" })}
        animate={({ hovered }) => {
          "worklet";

          return {
            opacity: hovered ? 0.5 : 1,
          };
        }}
      >
        <View
          sx={{
            transform: [
              { rotate: type === VoteType.Downvote ? "180deg" : "0deg" },
            ],
          }}
        >
          <VoteIcon isActive={isActive} />
        </View>
        <Text
          sx={{
            ml: "$1",
            fontSize: 18,
            lineHeight: 24,
            color: isActive ? "$blue" : "$black",
            fontWeight: "600",
          }}
        >
          {count}
        </Text>
      </MotiPressable>
    </ProtectCommunityFeature.Vote>
  );
};

interface VoteButtonsProps
  extends Pick<AnswerType, "upvotes" | "downvotes" | "myVoteType"> {
  answerId: Scalars["MigrateID"];
}

const VoteButtons: React.FC<VoteButtonsProps> = ({
  answerId,
  upvotes,
  downvotes,
  myVoteType,
}) => {
  return (
    <Row sx={{ mt: "$1" }}>
      <VoteButton
        label="Upvote"
        type={VoteType.Upvote}
        count={upvotes}
        answerId={answerId}
        isActive={myVoteType === VoteType.Upvote}
      />
      <VoteButton
        label="Downvote"
        type={VoteType.Downvote}
        count={downvotes}
        answerId={answerId}
        isActive={myVoteType === VoteType.Downvote}
      />
    </Row>
  );
};

export { VoteButtons };
