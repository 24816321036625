import { DripsyFinalTheme, H3, H4, Row, styled, Sx, Text, View } from "dripsy";
import { ReactNode } from "react";
import { MotiPressable } from "moti/interactions";
import { useLink } from "solito/link";
import { useAvailableWidth } from "app/hooks/use-available-width";
import { ButtonLink } from "app/design/button";
import { Card } from "app/design/card";
import { Grid, GridItem } from "app/design/grid";
import { DataError } from "app/components/data-error";
import {
  ChannelsOrderBy,
  ChannelType,
  FCC,
  useCommunityHomeChannelsQuery,
  useJoinedCommunityChannelCardQuery,
} from "app/types";
import { pagesPath } from "app/lib/$path";
import { JoinedChannelInfo } from "../community/chat/channel/joined-channel-card";
import { ChannelInfoLoading } from "../community/chat/channel/overview-channel-card";
import { ProtectCommunityFeature } from "../community/protect-feature";

const CHANNELS_COUNT = 4;

const ChannelCard = styled(MotiPressable)({
  borderColor: "$ui",
  pr: "$4",
  flexGrow: 1,
});

const ChannelActivityCard = ({
  sx,
  channelId,
}: {
  sx?: Sx;
  channelId: string;
}) => {
  const { data, error } = useJoinedCommunityChannelCardQuery({
    variables: { channelId },
  });
  const linkProps = useLink({
    href: pagesPath.community.channels._channelId(channelId).$url(),
  });
  if (error) return <DataError />;
  return (
    <ChannelCard sx={sx} containerStyle={{ flexGrow: 1 }} {...linkProps}>
      <JoinedChannelInfo
        id={channelId}
        unread={data?.communityPublicChannel?.unread}
        lastMessage={data?.communityPublicChannel?.lastMessage}
        description={data?.communityPublicChannel?.description}
      />
    </ChannelCard>
  );
};

const ChannelActivityContainer = ({ children }: { children: ReactNode }) => (
  <Card>
    <Row
      sx={{ justifyContent: "space-between", alignItems: "center", mb: "$4" }}
    >
      <H3 sx={{ py: "$0" }}>Channel activity</H3>
      <ProtectCommunityFeature.JoinChannels>
        <ButtonLink
          variant="ui"
          href={pagesPath.community.channels.$url()}
          containerSx={{ zIndex: 2 }}
        >
          See all
        </ButtonLink>
      </ProtectCommunityFeature.JoinChannels>
    </Row>
    <Grid
      gutter={32}
      sx={{ mt: (theme: DripsyFinalTheme) => 0 - theme.space.$5 }}
    >
      {children}
    </Grid>
  </Card>
);

const ChannelActivityGridItem: FCC<{ index: number }> = ({
  index,
  children,
}) => {
  const availableWidth = useAvailableWidth();

  const canFitThirds = availableWidth > 900;
  const canFitHalves = availableWidth > 695;

  const gridWidth = canFitThirds ? 1 / 3 : canFitHalves ? 1 / 2 : 1;
  const showDivider = canFitThirds
    ? (index + 1) % 3 !== 0
    : canFitHalves
    ? (index + 1) % 2 !== 0
    : false;

  // Hide the fourth channel on wide screens
  if (canFitThirds && index === 3) return null;

  return (
    <GridItem widths={[gridWidth]} sx={{ mt: "$5" }}>
      {showDivider ? (
        <View
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: -16,
            borderLeftWidth: 1,
            borderColor: "$ui",
          }}
        />
      ) : null}
      {children}
    </GridItem>
  );
};

const ChannelActivityLoading = () => (
  <ChannelActivityContainer>
    {Array.from({ length: CHANNELS_COUNT }).map((_, i) => (
      <ChannelActivityGridItem index={i} key={i}>
        <ChannelInfoLoading />
      </ChannelActivityGridItem>
    ))}
  </ChannelActivityContainer>
);

export const ChannelActivity = () => {
  const { data, loading, error } = useCommunityHomeChannelsQuery({
    variables: {
      where: { type: ChannelType.Community },
      orderBy: ChannelsOrderBy.UpdatedDesc,
      size: CHANNELS_COUNT,
    },
  });

  if (loading) return <ChannelActivityLoading />;
  if (error || !data) return <DataError sx={{ mb: "$4" }} />;

  return (
    <ChannelActivityContainer>
      {data.joinedChannels.data.length > 0 ? (
        data.joinedChannels.data.map((channel, i) => (
          <ChannelActivityGridItem key={channel.id} index={i}>
            <ChannelActivityCard channelId={channel.id} />
          </ChannelActivityGridItem>
        ))
      ) : (
        <GridItem
          widths={[1]}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            mt: "$5",
          }}
        >
          <H4 sx={{ color: "$text", mb: "$3", textAlign: "center" }}>
            No channels joined yet!
          </H4>

          <ProtectCommunityFeature.JoinChannels>
            <ButtonLink
              variant="link"
              href={pagesPath.community.channels.all.$url()}
            >
              Browse channels
            </ButtonLink>
          </ProtectCommunityFeature.JoinChannels>
        </GridItem>
      )}
    </ChannelActivityContainer>
  );
};
