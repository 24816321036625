import { Feather } from "@expo/vector-icons";
import { MatchResponseType, Maybe, Scalars } from "app/types/generated/schema";
import {
  FloatingActionButtonProps,
  FloatingActionToggleButton,
  FloatingActionToggleButtonProps,
} from "app/components/floating-action-list";

import { ColorAnimatedIconProps } from "app/components/color-animated-icon";
import { sendAnalyticsEvent } from "app/client/analytics";
import { ToggleIcon } from "./toggle-icon";
import { MatchResponse, OnActionToggle } from "./types";
import { useHideJobMatch } from "./hooks/use-hide-job-match";
import { useUnhideJobMatch } from "./hooks/use-unhide-job-match";

interface HideToggleIconProps {
  checked: boolean;
  size?: number;
  colorStates: ColorAnimatedIconProps["colorStates"];
}

const HideToggleIcon = ({
  checked,
  size,
  colorStates,
}: HideToggleIconProps) => (
  <ToggleIcon
    iconProps={{ size }}
    checked={checked}
    CheckedIcon={<Feather name="eye" />}
    UncheckedIcon={<Feather name="eye-off" />}
    colorStates={colorStates}
  />
);

interface ToggleHideActionButtonProps
  extends Omit<
    FloatingActionToggleButtonProps,
    "renderToggleIcon" | "children"
  > {
  hidden?: boolean;
}

const ToggleHideActionButton = ({
  hidden = false,
  variant = "card",
  ...props
}: ToggleHideActionButtonProps) => {
  return (
    <FloatingActionToggleButton
      renderToggleIcon={({ colorStates, size }) => (
        <HideToggleIcon
          checked={hidden}
          size={size}
          colorStates={colorStates}
        />
      )}
      variant={variant}
      {...props}
    >
      {hidden ? "Unhide" : "Hide"}
    </FloatingActionToggleButton>
  );
};

interface JobHideActionButtonProps {
  jobId: Scalars["MigrateID"];
  onHide?: (handleHide: () => Promise<void>) => Promise<void>;
}

const JobHideActionButton = ({
  jobId,
  onHide = (handleHide) => handleHide(),
}: JobHideActionButtonProps) => {
  const hideJobMatch = useHideJobMatch();

  const handlePress = () => onHide(() => hideJobMatch({ jobId }));

  return <ToggleHideActionButton onPress={handlePress} />;
};

interface UseToggleHideActionButtonProps {
  jobId: Scalars["MigrateID"];
  matchResponse?: Maybe<MatchResponse>;
  onHide?: (handleHide: () => Promise<void>) => Promise<void>;
}

const useHideActionToggle = ({
  jobId,
  matchResponse,
  onHide = (handleHide) => handleHide(),
}: UseToggleHideActionButtonProps) => {
  const handleUnhide = useUnhideJobMatch();
  const handleHide = useHideJobMatch();

  if (!matchResponse) {
    return {
      hidden: false,
      handleToggle: () => onHide(() => handleHide({ jobId })),
    };
  }

  const hidden = matchResponse.type === MatchResponseType.Hide;

  return {
    hidden,
    handleToggle: hidden
      ? () =>
          onHide(async () => {
            sendAnalyticsEvent("unhide job", { jobId });
            handleUnhide({ matchResponse });
          })
      : () =>
          onHide(async () => {
            sendAnalyticsEvent("hide job", { jobId });
            handleHide({ jobId, existingMatchResponseId: matchResponse.id });
          }),
  };
};

type JobToggleHideActionButtonProps = UseToggleHideActionButtonProps &
  Omit<
    FloatingActionButtonProps,
    "onPress" | "icon" | "children" | "variant"
  > & {
    variant?:
      | FloatingActionButtonProps["variant"]
      | ((hidden: boolean) => FloatingActionButtonProps["variant"]);
    onToggle?: OnActionToggle;
  };

const JobToggleHideActionButton = ({
  jobId,
  matchResponse,
  onHide,
  variant: _variant,
  onToggle = ({ handleToggle }) => handleToggle(),
  ...props
}: JobToggleHideActionButtonProps) => {
  const { hidden, handleToggle } = useHideActionToggle({
    jobId,
    matchResponse,
    onHide,
  });

  const variant = typeof _variant === "function" ? _variant(hidden) : _variant;

  return (
    <ToggleHideActionButton
      hidden={hidden}
      onPress={() => onToggle({ toggled: hidden, handleToggle })}
      variant={variant}
      {...props}
    />
  );
};

export type {
  JobHideActionButtonProps,
  UseToggleHideActionButtonProps as JobToggleHideActionButtonProps,
};
export {
  JobHideActionButton,
  JobToggleHideActionButton,
  ToggleHideActionButton,
};
