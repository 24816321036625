import React from "react";
import { useReloadApp } from "app/hooks/use-reload-app";
import { Button } from "app/design/button";
import { Screen } from "app/components/screen";
import { WebLink } from "app/components/web-link";
import { PageError } from "app/features/page-error/page-error";
import { captureException } from "app/client/error";
import { Background } from "app/features/layout/background";

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (
  props: Props,
  error?: Error
) => React.ReactNode;

type ErrorState = { error?: Error };

const Catch = <Props extends {}>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler
): React.ComponentType<Props> => {
  // eslint-disable-next-line react/display-name
  return class extends React.Component<Props, ErrorState> {
    state: ErrorState = {
      error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
      return { error };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }

    render() {
      return component(this.props, this.state.error);
    }
  };
};

type Props = {
  children: React.ReactNode;
};

const ErrorScreen = () => {
  const reloadApp = useReloadApp();
  return (
    <Background>
      <Screen sx={{ justifyContent: "center", alignItems: "center" }}>
        <PageError
          title="Oops - an error occurred"
          cta={
            <Button sx={{ mt: "$4" }} variant="link" onPress={reloadApp}>
              Back to home
            </Button>
          }
        >
          Please refresh the page or{" "}
          <WebLink url="mailto:support@migratehr.com">contact support</WebLink>.
        </PageError>
      </Screen>
    </Background>
  );
};

const ErrorBoundary = Catch(
  (props: Props, error?: Error) => {
    if (error) {
      return <ErrorScreen />;
    } else {
      return props.children;
    }
  },
  (e) => captureException(e)
);

export { ErrorBoundary };
