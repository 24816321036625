import { alphaColor } from "app/design/utils";
import { brandColors } from "../colors";

const solid = (color: string) => ({
  backgroundColor: color,
  borderColor: color,
});

const bubble = {
  default: {
    ...solid(alphaColor(brandColors.blue, 0.1)),
    color: "$text",
    _container: undefined,
  },
  user: {
    ...solid(alphaColor(brandColors.blue, 0.9)),
    color: "$white",
    _container: undefined,
  },
  error: {
    ...solid(alphaColor(brandColors.blue, 0.9)),
    color: "$white",
    _container: {
      opacity: 0.6,
    },
  },
  unsent: {
    ...solid(alphaColor(brandColors.blue, 0.9)),
    color: "$white",
    _container: {
      opacity: 0.6,
    },
  },
  referenced: {
    ...solid("$cream"),
    color: "$text",
    _container: undefined,
  },
};

export { bubble };
