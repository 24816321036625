import { gql } from "@apollo/client";
import { useDripsyTheme } from "dripsy";
import { Tooltip } from "native-base";
import {
  CandidateV2,
  ProfileCompletion,
  useQuestionResponseFormQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { Alert } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { DefaultProfileCardEmpty } from "../../card/empty";
import { useProfileEntryList } from "../hooks/use-profile-entry-list";
import { ProfileSection } from "../../section";
import { getProfileSectionStatus } from "../utils";
import {
  QuestionResponseFormCard,
  QuestionResponseFormValues,
} from "./form-card";
import { QuestionResponseCard } from "./card";
import { QuestionResponsesLoading } from "./loading";

export const QUESTION_RESPONSES_FORM_QUERY = gql`
  query QuestionResponseForm {
    me {
      id
      ... on CandidateV2 {
        questionResponses {
          id
          question
          response
        }
        profileCompletion
      }
    }
    profileQuestions
  }
`;

interface QuestionResponsesSectionProps {
  showCompletedStatus?: boolean;
}

export const QuestionResponsesSection = ({
  showCompletedStatus = true,
}: QuestionResponsesSectionProps) => {
  const { theme } = useDripsyTheme();
  const { data, loading, error } = useQuestionResponseFormQuery();

  const { fields, append, remove } =
    useProfileEntryList<QuestionResponseFormValues>({
      defaultValues: (data?.me as CandidateV2)?.questionResponses?.map(
        ({ __typename, ...questionResponses }) => questionResponses
      ),
    });

  if (loading) return <QuestionResponsesLoading />;
  if (error) return <DataError title="Failed to load more about you" />;

  const { questionResponses, profileCompletion } = data?.me as CandidateV2;

  const numQuestionResponses = questionResponses?.length || 0;
  const shouldAddMoreResponses = numQuestionResponses < 3;
  const hasReachedResponseLimit = fields.length === 5;

  const status = getProfileSectionStatus({
    hasStarted: numQuestionResponses > 0,
    hasCompleted: profileCompletion.includes(ProfileCompletion.HasMoreAboutYou),
  });

  return (
    <ProfileSection
      title="More about you"
      {...(showCompletedStatus && { status })}
    >
      {shouldAddMoreResponses ? (
        <Alert sx={{ mb: "$4" }} variant="warning">
          We recommend you add{" "}
          {numQuestionResponses === 2 ? "another answer" : "two more answers"}{" "}
          to give your profile the best chance of standing out.
        </Alert>
      ) : null}
      {fields.length === 0 ? (
        <DefaultProfileCardEmpty
          description="Add some personality to your profile by answering some questions."
          callToAction="Add more about you"
          onPress={() => append(QuestionResponseFormCard.createInstance())}
        />
      ) : null}
      {fields.map((field) => (
        <QuestionResponseCard
          key={field.id}
          field={field}
          onCancel={() => remove(field.id)}
          options={data?.profileQuestions.filter(
            (q) => !fields.some(({ question }) => question === q)
          )}
        />
      ))}
      {fields.length > 0 ? (
        <Tooltip
          label={"You can only add a maximum of 5 responses"}
          placement="top"
          isDisabled={!hasReachedResponseLimit}
        >
          <Button
            variant="secondary"
            containerSx={{ alignSelf: "center" }}
            onPress={() => append(QuestionResponseFormCard.createInstance())}
            disabled={hasReachedResponseLimit}
          >
            Add more about you
          </Button>
        </Tooltip>
      ) : null}
    </ProfileSection>
  );
};
