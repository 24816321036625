import { Sx, View, useDripsyTheme } from "dripsy";
import Svg, { Circle } from "react-native-svg";
import Animated, {
  useAnimatedProps,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import { useEffect } from "react";
import { Platform } from "react-native";

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

interface ProgressCircleProps {
  sx?: Sx;
  size?: number;
  percent: number;
  ringWidth?: number;
  borderWidth?: number;
  borderColor?: string;
  color?: string;
  inactiveColor?: string;
}

const ProgressCircle = ({
  sx = {},
  size = 96,
  ringWidth = 4,
  borderWidth = 2,
  percent,
  ...props
}: ProgressCircleProps) => {
  const { theme } = useDripsyTheme();
  const {
    borderColor = theme.colors.$white,
    color = theme.colors.$blue,
    inactiveColor = theme.colors.$ui,
  } = props;
  const circumference = size * Math.PI;

  const commonProps = {
    cx: size / 2,
    cy: size / 2,
    fill: "none",
  };

  const progress = useSharedValue(0);

  const animatedProps = useAnimatedProps(() => ({
    strokeDashoffset: circumference - (circumference * progress.value) / 100,
  }));

  useEffect(() => {
    progress.value = withSpring(percent, { damping: 15 });
  }, [percent]);

  return (
    <View sx={{ transform: [{ rotate: "-90deg" }], ...sx }}>
      <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <Circle
          stroke={borderColor}
          strokeWidth={borderWidth * 2 + ringWidth}
          r={(size - (ringWidth + borderWidth * 2)) / 2}
          {...commonProps}
        />
        <Circle
          stroke={inactiveColor}
          strokeWidth={ringWidth}
          r={(size - borderWidth * 4) / 2}
          {...commonProps}
        />
        <AnimatedCircle
          stroke={color}
          strokeWidth={ringWidth}
          r={(size - borderWidth * 4) / 2}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (circumference * percent) / 100}
          strokeLinecap="round"
          {...commonProps}
          animatedProps={animatedProps}
          // Hack to workaround Reanimated SVG bug
          onPress={() => {}}
          {...(Platform.OS === "web" && { tabIndex: "-1" })}
        />
      </Svg>
    </View>
  );
};

export { ProgressCircle };
