import React from "react";
import { Text, H2, H3, Row, View, useDripsyTheme, styled, Sx } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { ReactNode } from "react";
import { useThemeValue } from "app/hooks/use-theme-value";
import { ButtonLink } from "app/design/button";
import { Accent, DEFAULT_ACCENT_WIDTH } from "app/design/card";
import { List, ListItem } from "app/components/list";
import { OverviewCard } from "app/components/overview-card";
import { Link } from "app/components/link";
import { WebLink } from "app/components/web-link";
import { pagesPath } from "app/lib/$path";
import { CareerQuizResult } from "app/types";
import { CHANNEL_IDS, PROFESSION_IDS, YesNoOption } from "./constants";
import { EnglishLanguageOption } from "./steps/language";

interface CareerQuizAnswers {
  qualified: string;
  minRequirements: string;
  registered: string;
  engLang: string;
}

const getCareerQuizResults =
  (country?: string | null) => (answers: CareerQuizAnswers) => {
    const { qualified, minRequirements, registered, engLang } = answers;

    if (registered === YesNoOption.YES) return CareerQuizResult.Registered;
    if (engLang === EnglishLanguageOption.EXEMPT)
      return CareerQuizResult.PassedLanguage;
    if (minRequirements === YesNoOption.YES)
      return CareerQuizResult.PassedLanguage;
    if (qualified === YesNoOption.NO) return CareerQuizResult.Unqualified;
    if (country === "GB") return CareerQuizResult.PassedLanguage;
    return CareerQuizResult.Qualified;
  };

const getProfessionalBody = (professionId?: string) => {
  switch (professionId) {
    case PROFESSION_IDS.doctor:
      return "the GMC";
    case PROFESSION_IDS.nurse:
      return "the NMC";
    case PROFESSION_IDS.socialWorker:
      return "SWE";
    default:
      return "HCPC";
  }
};

const getStageTitle = (stage: CareerQuizResult, professionId?: string) => {
  switch (stage) {
    case CareerQuizResult.Registered:
      return "Registered";
    case CareerQuizResult.PassedLanguage:
      return "Register with " + getProfessionalBody(professionId);
    case CareerQuizResult.Qualified:
      return "Qualified";
    case CareerQuizResult.Unqualified:
      return "Unqualified";
  }
};

const getStageText = (stage: CareerQuizResult, professionId?: string) => {
  switch (stage) {
    case CareerQuizResult.Registered:
      return "Congratulations! You're a desirable candidate and you need can start applying for jobs straight away.";
    case CareerQuizResult.PassedLanguage:
      return (
        "You now need to register with " +
        getProfessionalBody(professionId) +
        " in order to be eligible to practice in the UK."
      );
    case CareerQuizResult.Qualified:
      return "You now need to pass an English language test in order to register with the relevant professional body.";
    case CareerQuizResult.Unqualified:
      return "You need to be a qualified professional in your home country to continue the process.";
  }
};

const getRegisterRecomendations = (professionId?: string) => {
  switch (professionId) {
    case PROFESSION_IDS.doctor:
      return [
        {
          title: "Join the GMC channel",
          href: pagesPath.community.channels._channelId(CHANNEL_IDS.gmc).$url(),
        },
        {
          title: "GMC website - join the register",
          href: "https://www.gmc-uk.org/registration-and-licensing/join-the-register",
        },
      ];
    case PROFESSION_IDS.nurse:
      return [
        {
          title: "Join the NMC channel",
          href: pagesPath.community.channels._channelId(CHANNEL_IDS.nmc).$url(),
        },
        {
          title: "NMC website - joining the register",
          href: "https://www.nmc.org.uk/registration/joining-the-register/",
        },
      ];
    case PROFESSION_IDS.socialWorker:
      return [
        {
          title: "Join the SWE channel",
          href: pagesPath.community.channels._channelId(CHANNEL_IDS.swe).$url(),
        },
        {
          title: "Social Work England website - apply to join the register",
          href: "https://www.socialworkengland.org.uk/registration/apply-for-registration/",
        },
      ];
    default:
      return [
        {
          title: "Join the HCPC channel",
          href: pagesPath.community.channels
            ._channelId(CHANNEL_IDS.hcpc)
            .$url(),
        },
        {
          title: "HCPC website - getting on the register",
          href: "https://www.hcpc-uk.org/registration/getting-on-the-register/",
        },
      ];
  }
};

const StageStep = styled(View)({
  borderRadius: 20,
  width: 40,
  height: 40,
  alignItems: "center",
  justifyContent: "center",
});

const StageStepText = styled(H3)({
  color: "$white",
});

const Check = () => {
  const { theme } = useDripsyTheme();
  return <Feather name="check" color={theme.colors.$white} size={24} />;
};

const Separator = styled(View)({
  flexGrow: 1,
  height: 1,
});

const ProgressContainer = styled(Row)({
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  mb: "$4",
});

const Progress = ({ stageNumber }: { stageNumber: number }) => (
  <ProgressContainer>
    {Array.from({ length: 4 }).map((_, i) => {
      const currentStage = i + 1;
      const highlightStep = currentStage <= stageNumber;
      const highlightStem = currentStage < stageNumber;
      return (
        <React.Fragment key={currentStage}>
          <StageStep
            sx={{
              bg: highlightStep ? "$blue" : "$ui",
            }}
          >
            {highlightStep ? (
              <Check />
            ) : (
              <StageStepText>{currentStage}</StageStepText>
            )}
          </StageStep>
          {i < 3 && (
            <Separator
              sx={{
                bg: highlightStem ? "$blue" : "$ui",
              }}
            />
          )}
        </React.Fragment>
      );
    })}
  </ProgressContainer>
);

interface CareerStageProps {
  stage: CareerQuizResult;
  professionId?: string;
  sx?: Sx;
}

const CareerStageContainer = ({
  sx,
  children,
}: {
  sx?: Sx;
  children: ReactNode;
}) => {
  const cardPadding = useThemeValue((theme) => theme.card.default.p);

  return (
    <OverviewCard
      variant="default"
      sx={{
        ...sx,
        pl: cardPadding + DEFAULT_ACCENT_WIDTH,
      }}
    >
      <Accent />
      {children}
    </OverviewCard>
  );
};

const CareerStage = ({ sx = {}, stage, professionId }: CareerStageProps) => {
  const stageNumber = {
    [CareerQuizResult.Registered]: 4,
    [CareerQuizResult.PassedLanguage]: 3,
    [CareerQuizResult.Qualified]: 2,
    [CareerQuizResult.Unqualified]: 1,
  }[stage];

  const recommendations = {
    [CareerQuizResult.Registered]: [
      {
        title: "Complete your profile",
        href: pagesPath.profile.$url(),
      },
      {
        title: "Browse your matches",
        href: pagesPath.jobs.matches.$url(),
      },
    ],
    [CareerQuizResult.PassedLanguage]: [
      ...getRegisterRecomendations(professionId),
      {
        title: "See what people are asking in the community",
        href: pagesPath.community.questions.$url(),
      },
    ],
    [CareerQuizResult.Qualified]: [
      {
        title:
          "Join Swoosh English's free weekly webinar to help prepare for your IELTS exam",
        href: "https://go.swooshenglish.com/15-ielt-fast-pass-pack-optin56351078?fpr=migratehr",
      },
      {
        title:
          "Join Swoosh English's free weekly webinar to help prepare for your OET exam",
        href: "https://go.swooshenglish.com/15-oet-fast-pass-pack-optin?fpr=migrate",
      },
      {
        title: "Join the English language channel",
        href: pagesPath.community.channels
          ._channelId(CHANNEL_IDS.engLang)
          .$url(),
      },
      {
        title: "See what people are asking in the community",
        href: pagesPath.community.questions.$url(),
      },
    ],
    [CareerQuizResult.Unqualified]: [
      {
        title: "Join the students channel",
        href: pagesPath.community.channels
          ._channelId(CHANNEL_IDS.students)
          .$url(),
      },
      {
        title: "See what people are asking in the community",
        href: pagesPath.community.questions.$url(),
      },
    ],
  }[stage];

  const linkSx = {
    color: "$blue",
    textDecorationLine: "none",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 21,
  };

  return (
    <CareerStageContainer sx={sx}>
      <Row sx={{ flexWrap: "wrap" }}>
        <H3 sx={{ mb: "$3", flexGrow: 1, mr: "$2" }}>Your stage</H3>
        <ButtonLink
          href={pagesPath.career_quiz.$url()}
          variant="text"
          containerSx={{ alignSelf: "flex-start" }}
          textSx={{ fontSize: 14 }}
        >
          Retake quiz
        </ButtonLink>
      </Row>
      <Progress stageNumber={stageNumber} />
      <H2 sx={{ mb: "$2" }}>{getStageTitle(stage, professionId)}</H2>
      <Text sx={{ mb: "$2" }}>{getStageText(stage, professionId)}</Text>
      <List sx={{ flexShrink: 1 }} itemSx={{ mt: 0 }}>
        {recommendations.map((recommendation) => (
          <ListItem key={recommendation.title} textSx={{ lineHeight: 21 }}>
            {typeof recommendation.href === "string" ? (
              <WebLink url={recommendation.href} sx={linkSx}>
                {recommendation.title}
              </WebLink>
            ) : (
              <Link href={recommendation.href} textSx={linkSx}>
                {recommendation.title}
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </CareerStageContainer>
  );
};

export { CareerStage, CareerStageContainer, Progress, getCareerQuizResults };
