import { Skeleton } from "native-base";
import { DocumentCardLoading } from "app/components/document-card";
import { ProfileCardContainer, ProfileCardDivider } from "../../card";

export const EducationExperienceLoading = () => {
  return (
    <>
      <DocumentCardLoading />
      <ProfileCardContainer>
        <Skeleton.Text
          lines={1}
          _line={{ width: 175, height: 4, rounded: 3 }}
          mb={2}
        />
        <Skeleton.Text
          lines={1}
          _line={{ width: 190, height: 3, rounded: 3 }}
        />
        <ProfileCardDivider />

        <Skeleton.Text
          lines={1}
          _line={{ width: 120, height: 2, rounded: 3 }}
        />
      </ProfileCardContainer>
    </>
  );
};
