import { SectionStatus } from "../section";

export const sortProfileFieldsByStartDate = (
  a: { startDate: string },
  b: { startDate: string }
) => {
  if (a.startDate > b.startDate) return -1;
  if (a.startDate < b.startDate) return 1;
  return 0;
};

export const transformDate = (date: Date | string | undefined) => {
  if (!date) return "";
  if (typeof date === "string") return new Date(date).toISOString();
  return date.toISOString();
};

interface GetProfileSectionStatusProps {
  hasStarted: boolean;
  hasCompleted: boolean;
}

export const getProfileSectionStatus = ({
  hasStarted,
  hasCompleted,
}: GetProfileSectionStatusProps) => {
  if (!hasStarted) return SectionStatus.NOT_STARTED;
  if (hasCompleted) return SectionStatus.COMPLETED;
  return SectionStatus.IN_PROGRESS;
};
