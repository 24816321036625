import { DripsyFinalTheme, styled, View } from "dripsy";
import { ComponentProps } from "react";
import { Platform, StyleSheet } from "react-native";
import { MotiView } from "moti";
import { Stone1, Stone2, Stone3, Stone4 } from "app/design/svgs/stones";
import { alphaColor } from "app/design/utils";
import { conditionalParam } from "app/utils";
import { OverviewCard } from "./overview-card";

const DripsyMotiView = styled(MotiView)();

type Props = ComponentProps<typeof MotiView>;

const getAnimation = (): Pick<Props, "from" | "animate" | "transition"> => ({
  from: {
    translateY:
      Math.ceil(Math.random() * -35) * (Math.round(Math.random()) ? 1 : -1),
    translateX:
      Math.ceil(Math.random() * -55) * (Math.round(Math.random()) ? 1 : -1),
    scale: Math.floor(Math.random() * 6 + 7) / 10,
    rotate: `${Math.floor(
      Math.random() * 100 * (Math.round(Math.random()) ? 1 : -1)
    )}deg`,
  },
  animate: {
    translateY: 0,
    translateX: 0,
    scale: 1,
    rotate: "0deg",
  },
  transition: {
    loop: true,
    type: "timing",
    duration: Math.ceil(Math.random() * 2500) + 4000,
    delay: Math.floor(Math.random() * (150 - 50 + 1) + 50),
  },
});

const FloatingStones = () => {
  const fillColor = alphaColor("#335cc8", 0.95);

  return (
    <>
      <DripsyMotiView
        sx={{
          position: "absolute",
          width: 154,
          height: 140,
          top: -19,
          right: "80%",
        }}
        {...getAnimation()}
      >
        <Stone1 fill={fillColor} />
      </DripsyMotiView>
      <DripsyMotiView
        sx={{
          position: "absolute",
          width: 121,
          height: 121,
          top: -24,
          left: "80%",
        }}
        {...getAnimation()}
      >
        <Stone2 fill={fillColor} />
      </DripsyMotiView>
      <DripsyMotiView
        sx={{
          position: "absolute",
          width: 159,
          height: 149,
          bottom: -80,
          left: ["15%", "25%"],
        }}
        {...getAnimation()}
      >
        <Stone3 fill={fillColor} />
      </DripsyMotiView>
      <DripsyMotiView
        sx={{
          position: "absolute",
          width: 104,
          height: 97,
          bottom: -60,
          right: ["2%", "12%"],
        }}
        {...getAnimation()}
      >
        <Stone4 fill={fillColor} />
      </DripsyMotiView>
    </>
  );
};

const AnimatedOverviewCard = ({
  children,
  ...rest
}: ComponentProps<typeof OverviewCard>) => (
  <OverviewCard {...rest}>
    <View
      sx={{
        ...StyleSheet.absoluteFillObject,
        overflow: "hidden",
        ...conditionalParam(
          {
            clipPath: (theme: DripsyFinalTheme) =>
              `inset(0 round ${theme.card.default.borderRadius}px`,
          },
          Platform.OS === "web"
        ),
      }}
    >
      <FloatingStones />
    </View>
    <View sx={{ zIndex: 1 }}>{children}</View>
  </OverviewCard>
);

export { AnimatedOverviewCard };
