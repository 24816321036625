import { gql, NetworkStatus, useApolloClient } from "@apollo/client";
import { useRef, useState } from "react";
import { useCurrentUserQuery } from "app/types/generated/schema";
import { UserHeader } from "app/features/community/questions/user-header";
import { useAuth } from "app/use-auth";
import { useEffectAfterRender } from "./use-effect-after-render";

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    me {
      ...UserHeaderFragment
      surname
      email
      account {
        id
        verified
      }
      isActive
      notificationsMutedUntil
      ... on CandidateV2 {
        hasSetPreferences
      }
    }
  }
  ${UserHeader.fragments.user}
`;

const useCurrentUser = () => {
  const client = useApolloClient();
  const { isAuthenticated } = useAuth();
  const { data, refetch, error, networkStatus } = useCurrentUserQuery({
    skip: !isAuthenticated,
    // Used downstream by authorizer to display loadiing state
    notifyOnNetworkStatusChange: true,
  });

  /**
   * we use refreshing to keep track of a specific kind of refetch - the one
   * that should trigger the app loading state to render. otherwise, we'd
   * trigger the app loading state every time we refocus the app, which is
   * not what we want.
   */
  const [refreshing, setRefreshing] = useState(false);
  const refresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const prevAuthenticated = useRef(isAuthenticated);
  useEffectAfterRender(() => {
    if (isAuthenticated && !prevAuthenticated.current) refresh();
    if (!isAuthenticated && prevAuthenticated.current) client.clearStore();
    prevAuthenticated.current = isAuthenticated;
  }, [isAuthenticated]);

  if (error) {
    console.error("Error fetching current user", error);
    // TODO: External log?
  }

  return {
    currentUser: isAuthenticated && data ? data.me : null,
    isCandidateV1: data?.me.__typename === "Candidate",
    isVerified: data?.me?.account?.verified || false,
    hasSetJobPreferences:
      data?.me?.__typename === "CandidateV2" && data?.me?.hasSetPreferences,
    loading: networkStatus === NetworkStatus.loading || refreshing,
    error,
  };
};

const useAuthenticatedUser = () => {
  const { currentUser, ...rest } = useCurrentUser();
  // TODO: Better type auth error to catch and redirect to login from app entry?
  if (!currentUser) throw new Error("User not authenticated");
  return { currentUser, ...rest };
};

export { CURRENT_USER_QUERY, useAuthenticatedUser, useCurrentUser };
