import { Sx, Row, View } from "dripsy";
import { Skeleton } from "native-base";
import { AvatarLoading } from "app/design/avatar";
import { Card } from "app/design/card";

export const JobCardLoading = ({ sx = {} }: { sx?: Sx }) => {
  return (
    <Card sx={sx}>
      <Row sx={{ alignItems: "center", mb: ["$2", "$3"] }}>
        <AvatarLoading size={10} />

        <View sx={{ ml: "$3", flex: 1 }}>
          <Skeleton.Text
            lines={1}
            w="100%"
            _line={{
              height: 4,
            }}
            mb={2}
          />
          <Skeleton.Text
            lines={1}
            w="95%"
            _line={{
              height: 2,
            }}
            mb={1}
          />
          <Skeleton.Text
            lines={1}
            w="80%"
            _line={{
              height: 2,
            }}
          />
        </View>
      </Row>
      <Row sx={{ alignItems: "center", alignSelf: "flex-end" }}>
        <Skeleton.Text lines={1} w={95} />
      </Row>
    </Card>
  );
};
