import { HomeScreen } from "app/features/home";
import { CareerQuizScreen } from "app/features/home/career-quiz/screen";
import { CommunityActivityScreen } from "app/features/community/activity/screen";
import { CommunityChannelScreen } from "app/features/community/chat/community-channel-screen";
import { CommunityQuestionScreen } from "app/features/community/questions/question-screen";
import { CommunityQuestionsScreen } from "app/features/community/questions/screen";
import { CommunityHomeScreen } from "app/features/community/screen";
import { JobsScreen } from "app/features/jobs/screen";
import { JobMatchesScreen } from "app/features/jobs/matches/screen";
import { ApplyScreen } from "app/features/jobs/matches/apply/screen";
import { InboxScreen } from "app/features/community/chat/inbox-screen";
import { InboxChannelScreen } from "app/features/community/chat/inbox-channel-screen";
import { ProfileScreen } from "app/features/profile/screen";
import { MyCommunityChannelsScreen } from "app/features/community/chat/my-community-channels-screen";
import { CommunityChannelsScreen } from "app/features/community/chat/channels-screen";
import { PreferencesScreen } from "app/features/onboarding/preferences-screen";
import { WelcomeScreen } from "app/features/welcome/screen";
import { OnboardingScreen } from "app/features/onboarding/screen";
import { LoginScreen } from "app/features/login-screen";
import { ForgotPasswordScreen } from "app/features/welcome/forgot-password/screen";
import { AccountSettingsScreen } from "app/features/profile/account-settings";
import { JobDetailsScreen } from "app/features/jobs/job-details/screen";
import { MyDocumentsScreen } from "app/features/home/my-documents/screen";
import { NotificationsScreen } from "app/features/notifications/screen";

const AuthScreensConfig = [
  {
    isRoot: true,
    name: "welcome",
    route: "welcome",
    component: WelcomeScreen,
  },
  {
    isRoot: true,
    name: "register",
    route: "register/:step",
    component: OnboardingScreen,
  },
  {
    isRoot: true,
    name: "login",
    route: "login",
    component: LoginScreen,
  },
  {
    isRoot: true,
    name: "forgot-password",
    route: "forgot-password",
    component: ForgotPasswordScreen,
  },
] as const;

const AUTH_SCREEN_CONFIGS = [...AuthScreensConfig] as const;
const AUTH_SCREEN_NAMES = AUTH_SCREEN_CONFIGS.map((s) => s.name);
type AuthScreenName = typeof AUTH_SCREEN_NAMES[number];

const HomeScreensConfig = [
  {
    isRoot: true,
    name: "home",
    route: "",
    component: HomeScreen,
  },
  {
    isRoot: true,
    name: "profile",
    route: "profile",
    component: ProfileScreen,
  },
  {
    isRoot: true,
    name: "my-documents",
    route: "my-documents",
    component: MyDocumentsScreen,
  },
  {
    isRoot: true,
    name: "account-settings",
    route: "account-settings",
    component: AccountSettingsScreen,
  },
  {
    isRoot: true,
    name: "notifications",
    route: "notifications",
    component: NotificationsScreen,
  },
] as const;

const JobScreensConfig = [
  {
    isRoot: true,
    name: "jobs",
    route: "jobs",
    component: JobsScreen,
  },
  {
    name: "job-details",
    route: "jobs/:jobId",
    component: JobDetailsScreen,
  },
  {
    name: "job-matches",
    route: "jobs/matches",
    component: JobMatchesScreen,
  },
  {
    name: "apply",
    route: "jobs/:jobId/apply",
    component: ApplyScreen,
  },
] as const;

const CommunityScreensConfig = [
  {
    isRoot: true,
    name: "community",
    route: "community",
    component: CommunityHomeScreen,
  },
  {
    name: "community-activity",
    route: "community/activity",
    component: CommunityActivityScreen,
  },
  {
    name: "community-questions",
    route: "community/questions",
    component: CommunityQuestionsScreen,
  },
  {
    name: "community-question",
    route: "community/questions/:questionId",
    component: CommunityQuestionScreen,
  },
  {
    name: "my-community-channels",
    route: "community/channels",
    component: MyCommunityChannelsScreen,
  },
  {
    name: "community-channel",
    route: "community/channels/:channelId",
    component: CommunityChannelScreen,
  },
  {
    name: "community-channels",
    route: "community/channels/all",
    component: CommunityChannelsScreen,
  },
] as const;

const InboxScreensConfig = [
  {
    isRoot: true,
    name: "inbox",
    route: "inbox",
    component: InboxScreen,
  },
  {
    name: "inbox-channel",
    route: "inbox/:channelId",
    component: InboxChannelScreen,
  },
] as const;

const ModalScreensConfig = [
  {
    isRoot: true,
    name: "career-quiz",
    route: "career-quiz",
    component: CareerQuizScreen,
  },
  {
    isRoot: true,
    name: "job-preferences",
    route: "job-preferences",
    component: PreferencesScreen,
  },
] as const;

const APP_SCREEN_CONFIGS = [
  ...HomeScreensConfig,
  ...InboxScreensConfig,
  ...ModalScreensConfig,
  ...JobScreensConfig,
  ...CommunityScreensConfig,
];

const APP_SCREEN_NAMES = APP_SCREEN_CONFIGS.map((s) => s.name);
type AppScreenName = typeof APP_SCREEN_NAMES[number];
type ScreenName = typeof ALL_SCREEN_CONFIGS[number]["name"];

const ALL_SCREEN_CONFIGS = [...APP_SCREEN_CONFIGS, ...AUTH_SCREEN_CONFIGS];

const UNPROTECTED_SCREEN_NAMES = ["/jobs/[jobId]"];

export type { AppScreenName, AuthScreenName, ScreenName };
export {
  ALL_SCREEN_CONFIGS,
  APP_SCREEN_NAMES,
  AUTH_SCREEN_NAMES,
  UNPROTECTED_SCREEN_NAMES,
  ModalScreensConfig,
  AuthScreensConfig,
  CommunityScreensConfig,
  InboxScreensConfig,
  JobScreensConfig,
  HomeScreensConfig,
};
