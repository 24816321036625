import {
  format,
  formatDistanceStrict,
  isAfter,
  isToday,
  isYesterday,
  subWeeks,
} from "date-fns";

const formatDate = (date: string) => {
  const parsedDate = new Date(date);
  if (isToday(parsedDate))
    return formatDistanceStrict(parsedDate, new Date()) + " ago";
  if (isYesterday(parsedDate))
    return "Yesterday, " + format(parsedDate, "HH:mm");
  if (isAfter(parsedDate, subWeeks(new Date(), 1)))
    return format(parsedDate, "E, H:mm");
  return format(parsedDate, "MMM d, yyyy");
};

const formatDateConcise = (date: string) => {
  const parsedDate = new Date(date);

  if (isToday(parsedDate)) return format(parsedDate, "HH:mm");
  if (isYesterday(parsedDate)) return "Yesterday";
  if (isAfter(parsedDate, subWeeks(new Date(), 1)))
    return format(parsedDate, "EEEE");
  return format(parsedDate, "d/M/yyyy");
};

export { formatDate, formatDateConcise };
