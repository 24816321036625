import { gql } from "@apollo/client";
import { format } from "date-fns";
import {
  MatchResponseType,
  Scalars,
  useJobDetailsQuery,
} from "app/types/generated/schema";
import { Alert, useAlertTextProps } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { Link } from "app/components/link";
import { pagesPath } from "app/lib/$path";
import { JobDetailsLoading } from "./job-details.loading";
import { CardInner, CardOuter } from "./card";
import { Header } from "./header";
import { ApplicationRequirements } from "./application-requirements";
import { Highlights } from "./highlights";
import { Description } from "./description";
import { Attachments } from "./attachments";
import { Share } from "./share";
import { Location } from "./location";
import { RejectedAlert } from "./rejected-alert";
import { JobDetailsMeta } from "./meta";

const JOB_DETAILS_JOB_PROPS = gql`
  fragment JobDetailsJobProps on Job {
    id
    myMatchResponse {
      id
      updatedAt
      type
      ... on RejectedMatchResponse {
        reason
      }
      job {
        id
        myMatchResponse {
          id
        }
      }
    }
    title
    description
    detailedDescription {
      ... on TextBlock {
        content
      }
    }
    organisationName
    organisation {
      id
      name
      photoUrl
      url
    }
    salary
    requiresLanguagePass
    city
    location {
      id
      name
      description
    }
    mapLocation {
      name
      location {
        lat
        lon
      }
    }
    highlights
    files {
      id
      filename
      url
    }
    status
    createdAt
  }
`;

const JOB_DETAILS_QUERY = gql`
  query JobDetails($id: MigrateID!) {
    job(id: $id) {
      ...JobDetailsJobProps
    }
  }
  ${JOB_DETAILS_JOB_PROPS}
`;

interface JobDetailsProps {
  jobId: Scalars["MigrateID"];
  renderMeta?: boolean;
}

const JobDetails = ({ jobId, renderMeta = false }: JobDetailsProps) => {
  const alertTextSx = useAlertTextProps("info");
  const { data, loading, error } = useJobDetailsQuery({
    variables: {
      id: jobId,
    },
  });

  if (loading) {
    return <JobDetailsLoading />;
  }

  if (error || !data) {
    return <DataError />;
  }

  const {
    id,
    myMatchResponse,
    title,
    salary,
    organisation,
    organisationName,
    requiresLanguagePass,
    highlights,
    description,
    detailedDescription,
    city,
    location,
    mapLocation,
    files,
  } = data.job;

  return (
    <>
      {renderMeta ? <JobDetailsMeta job={data.job} /> : null}
      <CardOuter>
        {myMatchResponse?.type === MatchResponseType.Apply ? (
          <Alert sx={{ mb: "$5" }} variant="info">
            You applied for this job on{" "}
            {format(new Date(myMatchResponse.updatedAt), "MMM d, yyyy")}.
          </Alert>
        ) : null}

        {myMatchResponse?.type === MatchResponseType.ApplicationStart ? (
          <Alert sx={{ mb: "$5" }} variant="info">
            You have an in progress application for this job.{" "}
            <Link
              textSx={alertTextSx}
              href={pagesPath.jobs._jobId(id).apply.$url()}
            >
              Continue my application
            </Link>
          </Alert>
        ) : null}

        {myMatchResponse &&
        myMatchResponse.__typename === "RejectedMatchResponse" ? (
          <RejectedAlert reason={myMatchResponse.reason} />
        ) : null}

        <Header
          title={title}
          salary={salary}
          organisationName={organisationName}
          organisation={organisation}
        />

        <CardInner>
          {requiresLanguagePass ? <ApplicationRequirements /> : null}

          {highlights ? <Highlights highlights={highlights} /> : null}

          {description ? (
            <Description
              detailedDescription={detailedDescription}
              description={description}
            />
          ) : null}

          {mapLocation && location ? (
            <Location
              city={city}
              mapLocation={mapLocation}
              location={location}
            />
          ) : null}
        </CardInner>

        {files && files.length > 0 ? <Attachments attachments={files} /> : null}

        <Share title={title} jobId={jobId} />
      </CardOuter>
    </>
  );
};

const JobDetailsNamespace = Object.assign(JobDetails, {
  fragments: {
    job: JOB_DETAILS_JOB_PROPS,
  },
});

export {
  JobDetailsNamespace as JobDetails,
  JobDetailsLoading,
  JOB_DETAILS_QUERY,
};
