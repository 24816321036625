import { useFlags } from "./context";

type StringLiteral<T> = T extends string
  ? string extends T
    ? never
    : T
  : never;

const hookFactory = <TName extends string>(
  flag: string,
  flagBooleanName: StringLiteral<TName>
) => {
  return () => {
    const { hasFeature, ...rest } = useFlags();

    return {
      [flagBooleanName]: hasFeature(flag),
      ...rest,
    } as Omit<ReturnType<typeof useFlags>, "hasFeature"> & {
      [P in StringLiteral<TName>]: boolean;
    };
  };
};

const useNotificationsFlag = hookFactory("notifications", "hasNotifications");

export * from "./context";
export { useNotificationsFlag };
