import { View } from "dripsy";
import { gql } from "@apollo/client";
import {
  CandidateV2,
  CareerExperienceType,
  ProfileCompletion,
  useCareerExperienceSectionQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { DataError } from "app/components/data-error";
import { ProfileCardEmpty } from "../../card/empty";
import { useProfileEntryList } from "../hooks/use-profile-entry-list";
import {
  getProfileSectionStatus,
  sortProfileFieldsByStartDate,
} from "../utils";
import { ProfileSection } from "../../section";
import {
  WorkExperienceFormCard,
  WorkExperienceFormValues,
} from "./work-experience";
import { CareerBreakFormValues } from "./career-break";
import { CareerExperienceCard } from "./card";
import { ImportWorkExperienceFromCVButton } from "./import-from-cv";
import { CareerExperienceLoading } from "./loading";

export const CAREER_EXPERIENCE_FRAGMENT = gql`
  fragment CareerExperienceFields on CareerExperience {
    ... on WorkExperience {
      id
      type
      organisationName
      title
      country
      startDate
      endDate
      description {
        content
      }
    }
    ... on CareerBreak {
      id
      type
      reason
      startDate
      endDate
      description {
        content
      }
    }
  }
`;

export const CAREER_EXPERIENCE_FORM_QUERY = gql`
  ${CAREER_EXPERIENCE_FRAGMENT}
  query CareerExperienceSection {
    me {
      id
      ... on CandidateV2 {
        careerExperiences {
          ...CareerExperienceFields
        }
        profileCompletion
      }
    }
  }
`;

export const CareerExperienceSection = () => {
  const { data, loading, error } = useCareerExperienceSectionQuery();

  const { fields, append, remove, setField, setFields } = useProfileEntryList<
    WorkExperienceFormValues | CareerBreakFormValues
  >({
    defaultValues: (data?.me as CandidateV2)?.careerExperiences?.map(
      ({ __typename, ...careerExperience }) => ({
        ...careerExperience,
        description: careerExperience.description.map((desc) => desc.content),
        endDate: careerExperience.endDate || "",
        hideEndDate: !!!careerExperience.endDate,
      })
    ),
  });

  if (loading) return <CareerExperienceLoading />;
  if (error) return <DataError title="Failed to load work experience" />;

  const { careerExperiences, profileCompletion } = data?.me as CandidateV2;

  const status = getProfileSectionStatus({
    hasStarted: (careerExperiences || []).length > 0,
    hasCompleted: profileCompletion.includes(
      ProfileCompletion.HasWorkExperience
    ),
  });

  return (
    <ProfileSection title="Work experience" status={status}>
      {fields.length === 0 ? (
        <ProfileCardEmpty description="Include complete (?) details of your employment history, including any career gaps.">
          <View sx={{ flexDirection: ["column", "row"], flexWrap: "wrap" }}>
            <ImportWorkExperienceFromCVButton onParseData={setFields} />
            <Button
              variant="secondary"
              onPress={() => append(WorkExperienceFormCard.createInstance())}
              containerSx={{ alignSelf: "flex-start", mt: ["$2", "$0"] }}
            >
              Add work experience
            </Button>
          </View>
        </ProfileCardEmpty>
      ) : null}
      {fields
        .sort((a, b) => sortProfileFieldsByStartDate(a, b))
        .map((field) => (
          <CareerExperienceCard
            key={field.id}
            field={field}
            onChangeType={(type: CareerExperienceType) => {
              setField(field.id, { type });
            }}
            onCancel={() => remove(field.id)}
          />
        ))}
      {fields.length > 0 ? (
        <Button
          variant="secondary"
          containerSx={{ alignSelf: "center" }}
          onPress={() => append(WorkExperienceFormCard.createInstance())}
        >
          Add more work experience
        </Button>
      ) : null}
    </ProfileSection>
  );
};
