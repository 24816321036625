import { H3 } from "dripsy";
import { gql } from "@apollo/client";
import {
  useAccountSettingsFormQuery,
  useUpdateUserMutation,
} from "app/types/generated/schema";
import { Card } from "app/design/card";
import { Button } from "app/design/button";
import { ActivityIndicator } from "app/design/activity-indicator";
import { InputControl } from "app/components/form/input-control";
import { DataError } from "app/components/data-error";
import { useAsyncToast } from "app/components/use-toast";
import { useYupForm, yup } from "app/utils/yup";

const ACCOUNT_SETTINGS_FORM_QUERY = gql`
  query AccountSettingsForm {
    me {
      id
      forenames
      surname
      email
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      forenames
      surname
    }
  }
`;

const schema = yup
  .object({
    forenames: yup.string().required().label("First name"),
    surname: yup.string().required().label("Last name"),
  })
  .required();

const AccountSettingsForm = () => {
  const { data, loading, error } = useAccountSettingsFormQuery();
  const [updateAccountSettings, { loading: updating }] =
    useUpdateUserMutation();

  const withToast = useAsyncToast({
    success: {
      title: "Account settings updated",
    },
    error: {
      title: "Error updating account settings",
    },
  });

  const defaultValues = {
    forenames: data?.me?.forenames,
    surname: data?.me?.surname,
    email: data?.me?.email,
  };

  const { control, formState, handleSubmit, reset } = useYupForm({
    schema,
    defaultValues,
  });

  const onSubmit = withToast(
    async ({ email: _email, ...formData }: typeof defaultValues) => {
      await updateAccountSettings({
        variables: {
          input: {
            ...formData,
          },
        },
      });
      reset(formData);
    }
  );

  if (loading) return <ActivityIndicator />;
  if (error) return <DataError />;

  return (
    <Card sx={{ mb: "$6" }}>
      <H3 sx={{ mb: "$5" }}>Settings</H3>
      <InputControl
        name="forenames"
        control={control}
        inputProps={{ label: "First name" }}
      />
      <InputControl
        name="surname"
        control={control}
        inputProps={{ label: "Last name" }}
      />
      <InputControl
        name="email"
        control={control}
        inputProps={{
          label: "Email",
          disabled: true,
          helperText: "Contact support@migratehr.com to change your email",
        }}
      />
      <Button
        disabled={!formState.isDirty}
        onPress={handleSubmit(onSubmit)}
        loading={updating}
      >
        Save changes
      </Button>
    </Card>
  );
};

export {
  ACCOUNT_SETTINGS_FORM_QUERY,
  UPDATE_USER_MUTATION,
  AccountSettingsForm,
};
