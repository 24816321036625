import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import { useRouter } from "solito/router";
import { useAsyncToast, UseAsyncToastConfig } from "app/components/use-toast";
import { useDeleteAnswerMutation, useDeleteQuestionMutation } from "app/types";
import { pagesPath } from "app/lib/$path";

export const DELETE_QUESTION_MUTATION = gql`
  mutation DeleteQuestion($input: DeleteQuestionInput!) {
    deleteQuestion(input: $input) {
      id
    }
  }
`;

export const DELETE_ANSWER_MUTATION = gql`
  mutation DeleteAnswer($input: DeleteAnswerInput!) {
    deleteAnswer(input: $input) {
      id
    }
  }
`;

const useDeleteCommunityQuestionEntity = (
  handleDelete: () => void,
  toastConfig: UseAsyncToastConfig,
  confirmConfig: {
    title: string;
    description: string;
    confirmLabel: string;
  },
  onSuccess?: () => void
) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast(toastConfig);

  return () =>
    openModal("ConfirmModal", {
      ...confirmConfig,
      onConfirm: withToast(async () => {
        await handleDelete();
        onSuccess?.();
      }),
    });
};

const useDeleteAnswer = (answerId: string) => {
  const [deleteAnswer] = useDeleteAnswerMutation({
    variables: { input: { id: answerId } },
    update(cache, { data }) {
      if (!data) return null;
      cache.evict({ id: cache.identify(data.deleteAnswer) });
      cache.gc();
    },
  });

  return useDeleteCommunityQuestionEntity(
    deleteAnswer,
    {
      success: {
        title: "Successfully deleted answer!",
      },
      error: {
        title: "Failed to delete answer",
        description: "Please try again or contact support.",
      },
    },
    {
      title: "Confirm delete answer",
      description: "Are you sure you want to delete your answer?",
      confirmLabel: "Delete answer",
    }
  );
};

const useDeleteQuestion = (questionId: string) => {
  const router = useRouter();

  const [deleteQuestion] = useDeleteQuestionMutation({
    variables: { input: { id: questionId } },
    update(cache, { data }) {
      if (!data) return null;
      cache.evict({ id: cache.identify(data.deleteQuestion) });
      cache.gc();
    },
  });

  return useDeleteCommunityQuestionEntity(
    deleteQuestion,
    {
      success: null,
      error: {
        title: "Failed to delete question",
        description: "Please try again or contact support.",
      },
    },
    {
      title: "Confirm delete question",
      description: "Are you sure you want to delete your question?",
      confirmLabel: "Delete question",
    },
    () => router.push(pagesPath.community.questions.$url())
  );
};

export { useDeleteQuestion, useDeleteAnswer };
