import { Sx, styled, useDripsyTheme } from "dripsy";
import { MotiView } from "moti";
import { ReactElement } from "react";
import { gql } from "@apollo/client";
import { alphaColor } from "app/design/utils";
import {
  PressableCard,
  PressableCardProps,
} from "app/components/pressable-card";
import { useAsyncToast } from "app/components/use-toast";
import { Scalars, useReadNotificationMutation } from "app/types";
import { NotificationHeaderProps } from "./header";
import { NotificationCardThemeContext } from "./theme-context";

const READ_NOTIFICATION_MUTATION = gql`
  mutation ReadNotification($id: MigrateID!) {
    readNotification(id: $id) {
      id
      unread
    }
  }
`;

const DripsyMotiView = styled(MotiView)();

interface NotificationCardWrapperProps extends PressableCardProps {
  notification: {
    id: Scalars["MigrateID"];
  };
  textSx?: Sx;
  // unread?: boolean;
  children: ReactElement | ReactElement[];
  renderHeaderRight?: NotificationHeaderProps["renderHeaderRight"];
}

const NotificationCardWrapper = ({
  sx,
  textSx,
  notification: { id },
  // unread,
  children,
  onPress,
  ...rest
}: NotificationCardWrapperProps) => {
  const { theme } = useDripsyTheme();

  const [readNotificationMutation] = useReadNotificationMutation({
    variables: {
      id,
    },
  });

  const withToast = useAsyncToast({
    success: null,
    error: {
      title:
        "Could not mark notification as read. Contact support if this continues.",
    },
  });

  const readNotification = withToast(readNotificationMutation);

  // TODO: Remove this when we hookup 'unread' feature
  const unread = false;

  return (
    <PressableCard
      variant="reset"
      activeColor={alphaColor(theme.colors.$blue, 0.1)}
      sx={{
        px: ["$4", "$6"],
        flexDirection: "row",
        alignItems: "flex-start",
        ...sx,
      }}
      // Mark as read when pressed
      onPress={() => {
        onPress?.();
        readNotification();
      }}
      {...rest}
    >
      <DripsyMotiView
        sx={{
          position: "absolute",
          top: [36, 40],
          left: ["$2", "$3"],
          width: 6,
          height: 6,
          ml: -3,
          mt: -3,
          borderRadius: 6,
          backgroundColor: "$blue",
        }}
        animate={{ opacity: unread ? 1 : 0 }}
      />
      <NotificationCardThemeContext.Provider value={{ textSx }}>
        {children}
      </NotificationCardThemeContext.Provider>
    </PressableCard>
  );
};

export { NotificationCardWrapper, READ_NOTIFICATION_MUTATION };
export type { NotificationCardWrapperProps };
