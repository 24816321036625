import { SxProp, useDripsyTheme, useSx, styled, Image, View } from "dripsy";
import { ComponentProps, ReactElement } from "react";
import { Avatar as NativeBaseAvatar, Skeleton } from "native-base";
import { MotiView } from "moti";

import { StyleSheet } from "react-native";
import { Maybe, Scalars } from "app/types/generated/schema";
import { getNumericId } from "app/utils";

import { AvatarIcon } from "../svgs/icons/avatar";
import { JobsIcon } from "../svgs/icons/jobs";
import { CommunityIcon } from "../svgs/icons/community";
import { HospitalIcon } from "../svgs/hospital-icon";
import { generateAvatarColor, generateOrganisationColor } from "./utils";

const DripsyMotiView = styled(MotiView)();

interface AvatarProps extends ComponentProps<typeof NativeBaseAvatar> {
  userId?: Scalars["MigrateID"];
  sx?: SxProp;
  size?: number;
  photoUrl?: Maybe<string>;
  isActive?: boolean;
  fallback?: ReactElement;
}

const getColorProps = (userId?: Scalars["MigrateID"]) => {
  if (userId) {
    const numericId = getNumericId(userId);
    const numericIdReveresed = numericId.split("").reverse().join("");

    const headFill = generateAvatarColor(numericId);
    let bodyFill = generateAvatarColor(numericIdReveresed);

    let i = 0;
    while (bodyFill === headFill) {
      bodyFill = generateAvatarColor(numericIdReveresed + i);
      i++;
    }

    return {
      headFill,
      bodyFill,
    };
  }

  return {};
};

const convertNativeBaseSize = (size: number) => {
  return size > 24 ? size : size * 4;
};

const DEFAULT_AVATAR_SIZE = 10;

interface AvatarBadgeProps {
  avatarSize: number;
  isActive: boolean;
}

const AvatarBadge = ({ isActive, avatarSize }: AvatarBadgeProps) => (
  <DripsyMotiView
    sx={{
      position: "absolute",
      bottom: avatarSize * 0.075,
      right: avatarSize * 0.075,
      width: avatarSize * 0.15,
      height: avatarSize * 0.15,
      borderRadius: 99,
      backgroundColor: "$success",
    }}
    animate={{ opacity: isActive ? 1 : 0 }}
  />
);

const Avatar: React.FC<AvatarProps> = ({
  userId,
  sx: _sx = {},
  size = DEFAULT_AVATAR_SIZE,
  photoUrl,
  children,
  isActive = false,
  ...rest
}) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  // Nativebase size things
  const realAvatarSize = convertNativeBaseSize(size);
  const avatarIconSize = realAvatarSize * 0.4;

  const {
    fallback = <AvatarIcon width={avatarIconSize} {...getColorProps(userId)} />,
  } = rest;
  const inner = children ? children : fallback;

  return (
    <NativeBaseAvatar
      bg={theme.colors.$cream}
      size={size}
      style={sx(_sx)}
      {...rest}
    >
      {photoUrl ? (
        <Image
          sx={{
            ...StyleSheet.absoluteFillObject,
            borderRadius: 9999,
          }}
          source={{ uri: photoUrl }}
        />
      ) : (
        inner
      )}

      <AvatarBadge isActive={isActive} avatarSize={realAvatarSize} />
    </NativeBaseAvatar>
  );
};

interface AvatarLoadingProps {
  size?: number;
}

const AvatarLoadingBase: React.FC<AvatarLoadingProps> = ({
  size,
  ...props
}) => <Skeleton size={size || 12} rounded="full" {...props} />;

const AvatarLoading = styled(AvatarLoadingBase)();

const JobAvatar: React.FC<AvatarProps> = ({
  size = DEFAULT_AVATAR_SIZE,
  ...rest
}) => {
  const { theme } = useDripsyTheme();
  const avatarIconSize = convertNativeBaseSize(size) * 0.5;

  return (
    <Avatar bg={theme.colors["$blue-tint-2"]} {...rest}>
      <JobsIcon fill={theme.colors.$white} width={avatarIconSize} />
    </Avatar>
  );
};

const GroupAvatar: React.FC<AvatarProps> = ({
  size = DEFAULT_AVATAR_SIZE,
  ...rest
}) => {
  const { theme } = useDripsyTheme();
  const avatarIconSize = convertNativeBaseSize(size) * 0.6;

  return (
    <Avatar bg={theme.colors["$orange-tint-1"]} {...rest}>
      <CommunityIcon fill={theme.colors.$white} width={avatarIconSize} />
    </Avatar>
  );
};

const HospitalAvatar = ({
  organisationId,
}: {
  organisationId: Scalars["MigrateID"];
}) => {
  const ICON_HEIGHT = 24;

  const getFillColor = () => {
    const numericId = getNumericId(organisationId);
    const fill = generateOrganisationColor(numericId);
    return fill;
  };

  return (
    <View
      sx={{
        bg: "$cream",
        alignItems: "center",
        justifyContent: "center",
        width: 48,
        height: 48,
        borderRadius: 48,
      }}
    >
      <View
        sx={{
          width: ICON_HEIGHT,
          height: ICON_HEIGHT / HospitalIcon.aspectRatio,
        }}
      >
        <HospitalIcon fill={getFillColor()} />
      </View>
    </View>
  );
};

interface OrganisationAvatarProps
  extends Omit<AvatarProps, "photoUrl" | "fallback"> {
  organisation: {
    photoUrl?: AvatarProps["photoUrl"];
    id: Scalars["MigrateID"];
  };
}

const OrganisationAvatar = ({
  organisation,
  ...rest
}: OrganisationAvatarProps) => {
  const { photoUrl, id } = organisation;
  return (
    <Avatar
      photoUrl={photoUrl}
      fallback={<HospitalAvatar organisationId={id} />}
      {...rest}
    />
  );
};

export type { AvatarProps, AvatarLoadingProps };
export {
  Avatar,
  AvatarLoading,
  GroupAvatar,
  JobAvatar,
  OrganisationAvatar,
  getColorProps,
  convertNativeBaseSize,
};
