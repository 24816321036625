import { UrlObject } from "url";
import { useEffect, useRef } from "react";
import {
  DripsyFinalTheme,
  Pressable,
  Sx,
  SxProp,
  useDripsyTheme,
  View,
  useSx,
} from "dripsy";
import { Platform, View as RNView } from "react-native";

import { useMotiPressable } from "moti/interactions";
import { MotiView } from "moti";
import { FCC } from "app/types/index";
import { useHrefLinkProps } from "app/hooks";

import { conditionalParam } from "app/utils";
import { ColorAnimatedPressable, StateTuple } from "./color-animated-view";

interface PressableCardHoverAccentProps {
  activeOverride?: boolean;
  containerSx?: Sx;
  sx?: Sx;
}

const PressableCardHoverAccent = ({
  activeOverride,
  containerSx = {},
  sx: _sx = {},
}: PressableCardHoverAccentProps) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();
  const state = useMotiPressable(({ hovered, pressed }) => {
    "worklet";

    if (activeOverride !== undefined) return { left: activeOverride ? 0 : -4 };

    return {
      left: hovered || pressed ? 0 : -4,
    };
  });

  return (
    <View
      sx={{
        position: "absolute",
        top: 0 - theme.card.default.borderWidth,
        left: 0 - theme.card.default.borderWidth,
        bottom: 0 - theme.card.default.borderWidth,
        right: 0 - theme.card.default.borderWidth,
        overflow: "hidden",
        borderRadius: "md",
        ...conditionalParam(
          { clipPath: `inset(0 round ${theme.borderRadius.md})` },
          Platform.OS === "web"
        ),
        ...containerSx,
      }}
    >
      <MotiView
        style={sx({
          position: "absolute",
          backgroundColor: theme.colors.$blue,
          width: 4,
          top: 0,
          left: -4,
          bottom: 0,
          ..._sx,
        })}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
        state={state}
      />
    </View>
  );
};

interface PressableCardProps {
  containerSx?: SxProp;
  sx?: SxProp;
  initialColor?: string;
  activeColor?: string;
  variant?: keyof Omit<DripsyFinalTheme["card"], "fullWidthMobile" | "filters">;
  onPress?: () => void;
  href?: UrlObject;
  accessibilityLabel?: string;
  additionalBackgroundColorStates?: StateTuple[];
  accentProps?: PressableCardHoverAccentProps;
  showAccent?: boolean;
}

const PressableCard: FCC<PressableCardProps> = ({
  initialColor: _initialColor,
  activeColor: _activeColor,
  variant = "default",
  children,
  sx,
  containerSx,
  href = "",
  onPress,
  accessibilityLabel,
  additionalBackgroundColorStates = [],
  accentProps,
  showAccent = false,
  ...rest
}) => {
  const { theme } = useDripsyTheme();
  const { width, ...baseCardSx } = theme.card.default;
  const linkProps = useHrefLinkProps(href);
  const pressableRef = useRef<RNView>(null);

  const pressProps = {
    ...(href
      ? {
          ...linkProps,
          // Call onPress if defined as well for additional actions
          onPress: (e?: Parameters<typeof linkProps.onPress>[0]) => {
            onPress?.();
            linkProps.onPress(e);
          },
        }
      : {}),
    ...(onPress ? { onPress } : {}),
  };

  // Prevents card being double-focusable on web
  useEffect(() => {
    if (Platform.OS !== "web") return;

    // View -> HTMLElement on web
    (pressableRef.current as unknown as HTMLElement)?.setAttribute(
      "tabindex",
      "-1"
    );
  }, []);

  const variantStyles = theme.card[variant];

  const initialColor = _initialColor ?? variantStyles.backgroundColor;
  const activeColor = _activeColor ?? variantStyles._hover.backgroundColor;

  return (
    <ColorAnimatedPressable
      ref={pressableRef}
      colorStates={{
        backgroundColor: {
          states: [
            ...additionalBackgroundColorStates,
            ["hovered", activeColor],
            ["pressed", activeColor],
          ],
          initialColor,
        },
      }}
      /**
       * workaround for horrible bug where the AnimatedComponent rendered
       * by ColorAnimatedView loses its animatedStyles when `sx` is updated
       * i.e. when the screen size changes. long term fix TBD...
       */
      sx={{
        ...baseCardSx,
        ...variantStyles,
        ...sx,
        backgroundColor: initialColor,
      }}
      containerSx={{
        width,
        ...containerSx,
      }}
      {...rest}
      {...(Platform.OS !== "web" && { ...pressProps })}
    >
      {variant === "default" || showAccent ? (
        <PressableCardHoverAccent {...accentProps} />
      ) : null}
      {/* To enable 'nested' pressables/links, cover the card with an absolute
      position view. Inner pressables need a zIndex of 1 or above to be
      independently pressable */}
      {Platform.OS === "web" ? (
        <Pressable
          {...pressProps}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
          accessibilityLabel={accessibilityLabel}
        />
      ) : null}
      {children}
    </ColorAnimatedPressable>
  );
};

export type { PressableCardProps };
export { PressableCard };
