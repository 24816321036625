import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAsyncStorageEffect } from "app/hooks/use-async-storage-effect";
import { ProfileCompletion, useProfileTrackingQuery } from "app/types";
import { sendAnalyticsEvent } from "app/client/analytics";

const REQUIRED_BADGES = [
  ProfileCompletion.HasPassport,
  ProfileCompletion.HasWorkExperience,
  ProfileCompletion.HasEducationHistory,
  ProfileCompletion.HasLanguageResults,
  ProfileCompletion.HasRegistrationDetails,
];

const PROFILE_TRACKING_QUERY = gql`
  query ProfileTracking {
    me {
      id
      ... on CandidateV2 {
        profileCompletion
      }
    }
  }
`;

const HAS_COMPLETED_PROFILE = "HAS_COMPLETED_PROFILE";

const useProfileTracking = () => {
  const [shouldTrack, setShouldTrack] = useState(false);
  const { data, error: _error } = useProfileTrackingQuery({
    skip: !shouldTrack,
  });

  const candidate = data?.me;

  useAsyncStorageEffect(HAS_COMPLETED_PROFILE, async (completedProfile) => {
    if (completedProfile !== "true") {
      setShouldTrack(true);
    }
  });

  useEffect(() => {
    if (!shouldTrack) return;
    if (candidate?.__typename !== "CandidateV2") return;
    const hasRequiredBadges = REQUIRED_BADGES.every((badge) =>
      candidate.profileCompletion.includes(badge)
    );
    if (!hasRequiredBadges) return;

    sendAnalyticsEvent("complete profile");
    AsyncStorage.setItem(HAS_COMPLETED_PROFILE, "true");
    setShouldTrack(false);
  }, [candidate]);
};

export { useProfileTracking, PROFILE_TRACKING_QUERY };
