import { View } from "dripsy";
import { gql } from "@apollo/client";
import {
  ApplicationCompletion,
  CoreDocumentsFieldsFragmentDoc,
  useApplicationQuestionSectionQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { Alert } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { ProfileSection } from "app/features/profile/section";
import { getProfileSectionStatus } from "app/features/profile/forms/utils";
import { ProfileCardEmpty } from "app/features/profile/card/empty";
import { useProfileEntryList } from "app/features/profile/forms/hooks/use-profile-entry-list";
import { ApplicationQuestionsLoading } from "./loading";
import { ApplicationQuestionCard } from "./card";
import {
  ApplicationQuestionFormCard,
  ApplicationQuestionFormValues,
} from "./form-card";

const EDUCATION_EXPERIENCE_FORM_QUERY = gql`
  ${CoreDocumentsFieldsFragmentDoc}
  query ApplicationQuestionSection($matchResponseId: MigrateID!) {
    matchResponse(id: $matchResponseId) {
      id
      job {
        id
        organisationName
        organisation {
          id
          name
        }
      }
      ... on ApplicationStartMatchResponse {
        applicationQuestion {
          question
          response
        }
        applicationCompletion
      }
      ... on ApplyMatchResponse {
        applicationQuestion {
          question
          response
        }
        applicationCompletion
      }
    }
  }
`;

interface ApplicationQuestionsSectionProps {
  matchResponseId: string;
}

const ApplicationQuestionsSection = ({
  matchResponseId,
}: ApplicationQuestionsSectionProps) => {
  const { data, loading, error } = useApplicationQuestionSectionQuery({
    variables: {
      matchResponseId,
    },
  });

  const { fields, append, remove } =
    useProfileEntryList<ApplicationQuestionFormValues>(
      (() => {
        if (
          data?.matchResponse?.__typename === "ApplicationStartMatchResponse"
        ) {
          const { applicationQuestion } = data.matchResponse;
          if (applicationQuestion.response)
            return {
              defaultValues: [
                {
                  id: "application-question",
                  response: applicationQuestion.response,
                },
              ],
            };
        }

        return { defaultValues: [] };
      })()
    );

  if (loading) return <ApplicationQuestionsLoading />;
  if (error) return <DataError title="Failed to load application questions" />;
  if (
    !(
      data?.matchResponse?.__typename === "ApplicationStartMatchResponse" ||
      data?.matchResponse.__typename === "ApplyMatchResponse"
    )
  )
    return null;

  const { applicationQuestion, applicationCompletion, job } =
    data.matchResponse;

  const status = getProfileSectionStatus({
    hasStarted: applicationCompletion.includes(
      ApplicationCompletion.HasApplicationQuestion
    ),
    hasCompleted: applicationCompletion.includes(
      ApplicationCompletion.HasApplicationQuestion
    ),
  });

  return (
    <ProfileSection title="Application questions" status={status}>
      {applicationQuestion.response &&
      applicationQuestion.response.length < 40 ? (
        <Alert sx={{ mb: "$4" }} variant="warning">
          We recommend writing some more to give your application the best
          chance of standing out.
        </Alert>
      ) : null}

      {fields.length === 0 ? (
        <ProfileCardEmpty
          description={`Tell ${job.organisationName} why you want to work there to help your application stand out.`}
        >
          <View sx={{ flexDirection: ["column", "row"], flexWrap: "wrap" }}>
            <Button
              variant="secondary"
              onPress={() => append(ApplicationQuestionFormCard.defaultValue)}
              containerSx={{ alignSelf: "flex-start", mt: ["$2", "$0"] }}
            >
              Add your answer
            </Button>
          </View>
        </ProfileCardEmpty>
      ) : null}
      {fields.length > 0
        ? fields.map((field) => (
            <ApplicationQuestionCard
              key={field.id}
              matchResponseId={matchResponseId}
              question={applicationQuestion.question}
              field={field}
              onCancel={() => remove(field.id)}
            />
          ))
        : null}
    </ProfileSection>
  );
};

export { ApplicationQuestionsSection, EDUCATION_EXPERIENCE_FORM_QUERY };
