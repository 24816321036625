// forked from https://github.com/tiaanduplessis/react-hook-form-persist/blob/master/src/index.tsx to add support for AsyncStorage

import { useEffect } from "react";
import { SetFieldValue } from "react-hook-form";
import { AsyncStorageStatic } from "@react-native-async-storage/async-storage/lib/typescript/types";

export interface FormPersistConfig {
  storage?: Storage | AsyncStorageStatic;
  watch: (names?: string | string[]) => any;
  setValue: SetFieldValue<any>;
  exclude?: string[];
  onDataRestored?: (data: any) => void;
  validate?: boolean;
  dirty?: boolean;
  onTimeout?: () => void;
  timeout?: number;
}

const useFormPersist = (
  name: string,
  {
    storage,
    watch,
    setValue,
    exclude = [],
    onDataRestored,
    validate = false,
    dirty = false,
    onTimeout,
    timeout,
  }: FormPersistConfig
) => {
  const watchedValues = watch();

  const getStorage = () => storage || window.sessionStorage;

  const clearStorage = () => getStorage().removeItem(name);

  useEffect(() => {
    const restoreData = async () => {
      const str = await getStorage().getItem(name);

      if (str) {
        const { _timestamp = null, ...values } = JSON.parse(str);
        const dataRestored: { [key: string]: any } = {};
        const currTimestamp = Date.now();

        if (timeout && currTimestamp - _timestamp > timeout) {
          if (onTimeout) onTimeout();
          clearStorage();
          return;
        }

        Object.keys(values).forEach((key) => {
          const shouldSet = !exclude.includes(key);
          if (shouldSet) {
            dataRestored[key] = values[key];
            setValue(key, values[key], {
              shouldValidate: validate,
              shouldDirty: dirty,
            });
          }
        });

        if (onDataRestored) {
          onDataRestored(dataRestored);
        }
      }
    };

    restoreData();
  }, [storage, name, onDataRestored, setValue]);

  useEffect(() => {
    const values = exclude.length
      ? Object.entries(watchedValues)
          .filter(([key]) => !exclude.includes(key))
          .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {})
      : Object.assign({}, watchedValues);

    if (Object.entries(values).length) {
      if (timeout !== undefined) {
        values._timestamp = Date.now();
      }
      getStorage().setItem(name, JSON.stringify(values));
    }
  }, [watchedValues, timeout]);

  return {
    get: () => getStorage().getItem(name),
    clear: () => getStorage().removeItem(name),
  };
};

export { useFormPersist };
