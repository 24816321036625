import { MotiView, useAnimationState } from "moti";
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle,
} from "react";
import { SKIP_ANIMATION_ON_RENDER } from "app/utils/animation";

const TRANSITION_DURATION = 200;

export interface AnimateOnHideImperativeHandle {
  hide: (onHide: () => Promise<void>) => Promise<void>;
}

interface AnimateOnHideProps {
  isLast?: boolean;
  children: ReactNode;
}

export const AnimateOnHide = forwardRef(
  (
    { children, isLast = false }: AnimateOnHideProps,
    ref: ForwardedRef<AnimateOnHideImperativeHandle>
  ) => {
    const hideAnimation = useAnimationState(
      {
        initial: {
          opacity: 1,
          scale: 1,
          translateX: 0,
        },
        hidden: {
          scale: 0.9,
          opacity: 0,
          translateX: 0,
        },
        forceRight: {
          scale: 1,
          translateX: 100,
          opacity: 0,
        },
      },
      SKIP_ANIMATION_ON_RENDER
    );

    const waitForAnimation = async () => {
      await new Promise((resolve) => setTimeout(resolve, TRANSITION_DURATION));
    };

    useImperativeHandle<$TSFixMe, AnimateOnHideImperativeHandle>(ref, () => ({
      hide: async (onHide: () => Promise<void>) => {
        hideAnimation.transitionTo("hidden");
        /**
         * onHide immediately removes the component from the tree, which causes
         * the animation to stop. We need to wait for the animation to finish
         * before calling onHide.
         */
        await waitForAnimation();
        /**
         * if this is the last slide, useSlider will set the activeIndex
         * to the penultimate slide which will trigger animate presence to
         * do its thing - therefore we don't need to animate from the right
         */
        if (!isLast) {
          hideAnimation.transitionTo("forceRight");
          await waitForAnimation();
        }
        await onHide();
        if (!isLast) {
          hideAnimation.transitionTo("initial");
        }
      },
    }));

    return (
      <MotiView
        state={hideAnimation}
        transition={{
          type: "timing",
          duration: TRANSITION_DURATION,
        }}
      >
        {children}
      </MotiView>
    );
  }
);
AnimateOnHide.displayName = "AnimateOnHide";
