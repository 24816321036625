import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 384;
const originalHeight = 410;
const aspectRatio = originalWidth / originalHeight;

const SidebarStones = ({
  width = originalWidth,
  height = originalHeight,
  ...rest
}: SvgProps) => (
  <View style={{ aspectRatio, width, height, opacity: 0.4 }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...rest}
    >
      <Path
        fill="#F6B2A4"
        d="M236.64,266.07c-70.7,12.82-78.03,153.22,26.65,143.44C381.44,398.48,355.7,244.49,236.64,266.07z"
      />
      <Path
        fill="#F38F1A"
        d="M44.58,206.06l-0.31-0.32c-28.71-20.68-48.25-49.09-43.58-76.52c3.87-22.27,30.99-46.13,71.46-53.75
	c51.14-9.7,88.68,10.73,103.58,34.89c5.76,9.16,9.23,19.29,10.07,29.38c0.84,10.09-0.99,19.79-5.29,28.11
	c-9.95,21.01-46.47,62.86-96.14,55.22C70.39,220.89,56.65,215.01,44.58,206.06z"
      />
      <Path
        fill="#F6B2A4"
        d="M226.69,10.31c-9.4,8.07-16.93,21.52-7.01,35.92c4.44,6.27,11.15,10.59,18.72,12.05
	c5.19,1.07,10.55,0.95,15.68-0.36c5.13-1.31,9.89-3.78,13.91-7.21c8.64-7.23,14.8-21.07,10.71-34.15
	c-7.29-23.36-33.79-17.18-46.1-10.35C230.5,7.37,228.52,8.75,226.69,10.31z"
      />
      <Path
        fill="#3B5DA8"
        d="M327.25,80.81h-0.34c-26.7-5.17-52.62,0.44-64.9,20.91c-9.93,16.65-8.13,49.18,9.67,79.87
	c22.44,38.84,53.8,49.8,74.91,43.93c8.06-2.15,15.41-6.39,21.32-12.28c5.9-5.89,10.15-13.23,12.31-21.29
	c5.96-19.73,8.78-69.72-22.23-96.58C349.24,87.84,338.62,82.81,327.25,80.81z"
      />
    </Svg>
  </View>
);

export { SidebarStones };
