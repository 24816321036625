import { ComponentProps, ReactElement } from "react";
import { QueryResult } from "@apollo/client";
import { View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { ListRenderItemInfo } from "react-native";
import { Entity, Scalars } from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import {
  QueryInfiniteScrollFlatList,
  QueryInfiniteScrollFlatListProps,
} from "app/components/query-infinite-flat-list";
import { ChannelListCardLoading } from "./channel-list-card";

type ChannelListRenderItem<TChannel extends Pick<Entity, "id">> = (
  info: ListRenderItemInfo<TChannel> & {
    extraData?: {
      selectedChannelId?: Scalars["MigrateID"];
    };
  }
) => ReactElement | null;

interface ChannelListProps<
  TChannel extends Pick<Entity, "id">,
  TQueryData extends {
    data: TChannel[];
    after?: string;
  },
  THookResult extends QueryResult
> {
  selectedChannelId?: Scalars["MigrateID"];
  renderItem: ChannelListRenderItem<TChannel>;
  queryHookResult: THookResult;
  queryData?: TQueryData;
  emptyListIcon?: ComponentProps<typeof Feather>["name"];
  emptyListHeader?: string;
  emptyListText?: string;
  ListEmptyComponent?: QueryInfiniteScrollFlatListProps<
    null,
    TChannel,
    THookResult
  >["renderListEmptyComponent"];
}

const ChannelList = <
  TChannel extends Pick<Entity, "id">,
  TQueryData extends {
    data: TChannel[];
    after?: string;
  },
  THookResult extends QueryResult
>({
  selectedChannelId,
  renderItem,
  queryData,
  queryHookResult,
  ListEmptyComponent,
}: ChannelListProps<TChannel, TQueryData, THookResult>) => {
  const containerSx = { py: "$2", flexGrow: 1 };

  const LoadingComponent = () => (
    <View
      sx={{
        height: "100%",
        overflow: "hidden",
        ...containerSx,
      }}
    >
      {Array.from({ length: 20 }, (_, i) => (
        <ChannelListCardLoading key={i} />
      ))}
    </View>
  );

  const ErrorComponent = () => (
    <DataError
      sx={{ m: "$4", width: "auto" }}
      title={"Unable to retrieve messages"}
    />
  );

  const { data, after } = queryData || {};

  return (
    <View
      sx={{
        ml: [0, null, -2],
        flex: 1,
      }}
    >
      <QueryInfiniteScrollFlatList
        data={data}
        after={after}
        queryHookResult={queryHookResult}
        keyExtractor={(channel) => channel.id}
        renderItem={(renderProps) =>
          renderItem({ ...renderProps, extraData: { selectedChannelId } })
        }
        onEndReachedThreshold={0.8}
        containerSx={{ ...containerSx }}
        renderLoaderComponent={LoadingComponent}
        renderListEmptyComponent={ListEmptyComponent}
        renderErrorComponent={ErrorComponent}
        extraData={{ selectedChannelId }}
      />
    </View>
  );
};

export { ChannelList };
export type { ChannelListRenderItem };
