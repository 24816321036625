import { useEffect, useRef } from "react";
import { AppState, AppStateStatus } from "react-native";

const isForeground = (appState: AppStateStatus, nextAppState: AppStateStatus) =>
  appState.match(/inactive|background/) && nextAppState === "active";

const useAppState = (
  callback: (appState: AppStateStatus, nextAppState: AppStateStatus) => void,
  dependencies: any[] = []
) => {
  const appState = useRef(AppState.currentState);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    callback(appState.current, nextAppState);
    appState.current = nextAppState;
  };

  useEffect(() => {
    const subscription = AppState.addEventListener(
      "change",
      handleAppStateChange
    );
    return () => {
      subscription.remove();
    };
  }, dependencies);
};

export { useAppState, isForeground };
