import { Feather } from "@expo/vector-icons";
import { Row, Text, useDripsyTheme } from "dripsy";
import { FCC } from "app/types/index";
import { Menu } from "app/components/menu";

interface FilterMenuItemProps {
  onPress?: () => void;
  selected?: boolean;
}

export const FilterMenuItem: FCC<FilterMenuItemProps> = ({
  onPress,
  selected,
  children,
}) => {
  const { theme } = useDripsyTheme();
  return (
    <Menu.Item onPress={onPress}>
      <Row sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Text sx={{ mr: "$1" }}>{children}</Text>
        {selected ? (
          <Feather name="check" size={20} color={theme.colors.$blue} />
        ) : null}
      </Row>
    </Menu.Item>
  );
};
