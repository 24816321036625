import { Row, useDripsyTheme, View } from "dripsy";
import { useState } from "react";
import { Platform } from "react-native";
import { useRetrySendMessage } from "app/hooks/message/use-send-message";
import { Button } from "app/design/button";
import { conditionalParam } from "app/utils";
import {
  MessageBubbleProps,
  RenderMessageBubbleActions,
  MessageBubble,
  MessageBubbleContainer,
  RenderMessageBubbleActionSheet,
} from "../bubble";
import {
  DeleteMessageAction,
  MessageActionList,
  RetrySendMessageAction,
} from "../action-menu";
import {
  DeleteMessageItem,
  MessageActionSheetContent,
  RetrySendMessageItem,
} from "../action-sheet";

const renderErrorMessageActionList: RenderMessageBubbleActions = ({
  message,
}) => {
  return (
    <MessageActionList>
      <RetrySendMessageAction message={message} />
      <DeleteMessageAction message={message} />
    </MessageActionList>
  );
};

const renderErrorMessageActionSheet: RenderMessageBubbleActionSheet = (
  props
) => {
  return (
    <MessageActionSheetContent>
      <RetrySendMessageItem {...props} />
      <DeleteMessageItem {...props} />
    </MessageActionSheetContent>
  );
};

interface ErrorMessageTextProps {
  onPress: () => void;
}

const ErrorMessageText = ({ onPress }: ErrorMessageTextProps) => {
  const { theme } = useDripsyTheme();
  const retryCopy = Platform.OS === "web" ? "Click to retry" : "Tap to retry";

  const buttonProps = {
    variant: "ghostError",
    sx: { px: 0, py: 0 },
    textSx: {
      ...theme.text.sm,
    },
    onPress,
  } as const;

  return (
    <Row sx={{ flex: 1, flexWrap: "wrap", justifyContent: "flex-end" }}>
      <Button {...buttonProps}>An error occurred sending your message.</Button>
      <Button {...buttonProps} sx={{ ...buttonProps.sx, ml: "$1" }}>
        {retryCopy}.
      </Button>
    </Row>
  );
};

type ErrorMessageBubbleProps = Omit<
  MessageBubbleProps,
  "variant" | "renderActions" | "renderActionSheet"
>;

const ErrorMessageBubble = ({
  message,
  ...bubbleProps
}: ErrorMessageBubbleProps) => {
  const [hover, setHover] = useState(false);

  const retrySendMessage = useRetrySendMessage();
  const handlePress = () => retrySendMessage({ message });

  return (
    <View sx={{ flex: 1, alignItems: "flex-end" }}>
      <MessageBubbleContainer variant="error">
        <MessageBubble
          {...bubbleProps}
          variant="error"
          message={message}
          onPress={handlePress}
          renderActions={renderErrorMessageActionList}
          renderActionSheet={renderErrorMessageActionSheet}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
          sx={{
            ...bubbleProps.sx,
            ...conditionalParam(
              {
                cursor: "pointer",
              },
              hover
            ),
          }}
        />
      </MessageBubbleContainer>
      <ErrorMessageText onPress={handlePress} />
    </View>
  );
};

export type { ErrorMessageBubbleProps };
export { ErrorMessageBubble };
