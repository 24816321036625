import { H1, H4, useDripsyTheme } from "dripsy";

import { ButtonLink } from "app/design/button";
import { InputControl } from "app/components/form/input-control";
import { PasswordControl } from "app/components/form/password-control";
import { useToast } from "app/components/use-toast";
import { WelcomeLogo } from "app/components/welcome-logo";
import { Form } from "app/components/form";
import { KeyboardAwareScrollView } from "app/components/keyboard-aware-scroll-view";
import { useAuth } from "app/use-auth";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { useYupForm, yup } from "app/utils/yup";
import { pagesPath } from "app/lib/$path";
import { checkExhaustiveness } from "app/types";
import { captureException } from "app/client/error";
import { Background } from "./layout/background";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .required()
      .min(8, "Please enter at least 8 characters"),
  })
  .required();

interface LoginInput {
  email: string;
  password: string;
}

const LOGIN_SCREEN_MAX_WIDTH = 400;
const LOGIN_SCREEN_EXTRA_HEIGHT = 100;

function LoginScreen() {
  const insets = useSafeArea();
  const toast = useToast();
  const { theme } = useDripsyTheme();

  const { login } = useAuth();

  const { control, handleSubmit, formState } = useYupForm<LoginInput>({
    schema,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (loginInput: LoginInput) => {
    try {
      const res = await login(loginInput);
      if (!res.success) {
        if (res.error.type === "InvalidCredentialsError") {
          toast.error({
            title: "Invalid credentials",
            description: "Please check your email and password and try again.",
          });
          return;
        }
        checkExhaustiveness(res.error.type);
      }
    } catch (e) {
      captureException(e);
      toast.error({
        title: "Couldn't sign in",
      });
    }
  };

  return (
    <Background>
      <KeyboardAwareScrollView
        sx={{
          px: "$4",
          pt: theme.space.$6 + insets.top,
          pb: theme.space.$6 + insets.bottom,
        }}
        contentContainerStyle={{
          width: "100%",
          maxWidth: LOGIN_SCREEN_MAX_WIDTH,
          alignSelf: "center",
        }}
        extraHeight={LOGIN_SCREEN_EXTRA_HEIGHT}
      >
        <WelcomeLogo sx={{ alignSelf: "center", mb: "$9" }} />

        <H1 sx={{ mb: "$7", textAlign: "center", fontSize: 32 }}>Log in</H1>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputControl
            control={control}
            name="email"
            inputProps={{
              label: "Email",
              placeholder: "Enter email address",
              keyboardType: "email-address",
              autoComplete: "email",
              autoCorrect: false,
              textContentType: "emailAddress",
              autoCapitalize: "none",
            }}
          />

          <PasswordControl
            control={control}
            name="password"
            inputProps={{
              label: "Password",
              autoComplete: "password",
              textContentType: "password",
              showSuccessState: false,
            }}
          />

          <Form.SubmitButton
            containerSx={{ my: "$4" }}
            loading={formState.isSubmitting}
          >
            Log in
          </Form.SubmitButton>
        </Form>

        <ButtonLink
          variant="link"
          containerSx={{ mb: "$8", alignSelf: "center" }}
          textSx={{ textDecorationLine: "underline" }}
          href={pagesPath.forgot_password.$url()}
        >
          Forgot your password?
        </ButtonLink>

        <H4 sx={{ mt: "$0", mb: "$4", textAlign: "center" }}>
          Don&apos;t have an account?
        </H4>
        <ButtonLink
          variant="secondary"
          href={pagesPath.register._step("profession").$url()}
        >
          Sign up
        </ButtonLink>
      </KeyboardAwareScrollView>
    </Background>
  );
}

export { LoginScreen, LOGIN_SCREEN_MAX_WIDTH, LOGIN_SCREEN_EXTRA_HEIGHT };
