import { styled, View } from "dripsy";
import { Card as BaseCard, CardProps } from "app/design/card";
import {
  PressableCard as BasePressableCard,
  PressableCardProps as BasePressableCardProps,
} from "app/components/pressable-card";

const Card = styled(BaseCard, {
  themeKey: "card",
  defaultVariant: "default",
})({ mb: "$4" });

type PressableCardProps = Omit<
  BasePressableCardProps,
  "initialColor" | "activeColor"
> &
  CardProps;

const PressableCard: React.FC<PressableCardProps> = ({
  children,
  onPress,
  containerSx,
  sx,
  ...rest
}) => {
  return (
    <BasePressableCard
      containerSx={{ mb: "$4", ...containerSx }}
      sx={{ ...sx }}
      onPress={onPress}
      {...rest}
    >
      {children}
    </BasePressableCard>
  );
};

const ErrorCard = styled(Card)({
  backgroundColor: "$white",
  borderWidth: 2,
  borderColor: "$error",
});

const HeaderContainer = styled(View)({
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  mb: "$3",
  flexGrow: 1,
});

export type { PressableCardProps };
export { Card, PressableCard, ErrorCard, HeaderContainer };
