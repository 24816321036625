import { Text } from "dripsy";
import { format } from "date-fns";

export interface ProfileCardDatesProps {
  startDate?: string;
  endDate?: string;
  isDateRange?: boolean;
}

const formatDates = (
  startDate: string,
  endDate?: string,
  isDateRange?: boolean
) => {
  let formattedDate = format(new Date(startDate), "MMM yyyy");
  if (endDate) {
    formattedDate += ` - ${format(new Date(endDate), "MMM yyyy")}`;
  } else if (isDateRange) {
    formattedDate += " - Present";
  }
  return formattedDate;
};

export const ProfileCardDates = ({
  startDate,
  endDate,
  isDateRange = true,
}: ProfileCardDatesProps) => {
  if (!startDate) return null;
  return (
    <Text variant="xs">
      {formatDates(startDate, endDate, isDateRange).toUpperCase()}
    </Text>
  );
};
