import { gql } from "@apollo/client";
import { useState } from "react";
import {
  Scalars,
  useGetAttachmentUploadTokenQuery,
} from "app/types/generated/schema";
import { asImperativeQuery } from "../../helpers/imperative-query";
import { AnyFile, uploadFile } from "../../helpers/upload-file";

const CHANNEL_ATTACHMENT_UPLOAD_TOKEN_QUERY = gql`
  query GetAttachmentUploadToken($input: GetAttachmentUploadTokenInput!) {
    getAttachmentUploadToken(input: $input) {
      token
      url
    }
  }
`;

const useUploadAttachment = () => {
  const [getAttachmentUploadToken] = asImperativeQuery(
    useGetAttachmentUploadTokenQuery
  );

  const [loading, setLoading] = useState(false);

  const uploadAttachment = async (
    file: AnyFile,
    channelId: Scalars["MigrateID"]
  ) => {
    setLoading(true);

    const {
      data: {
        getAttachmentUploadToken: { url, token },
      },
    } = await getAttachmentUploadToken({
      input: {
        channelId,
        filename: file.name,
      },
    });

    await uploadFile(url, file);
    setLoading(false);

    // Add attachment to cache / return info?

    return token as string;
  };

  return [uploadAttachment, { loading }] as const;
};

export { useUploadAttachment, CHANNEL_ATTACHMENT_UPLOAD_TOKEN_QUERY };
