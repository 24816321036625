import { useSx } from "dripsy";
import { useState } from "react";
import CountryPicker, {
  DEFAULT_THEME,
  CountryCode,
} from "react-native-country-picker-modal";

interface CountrySelectModalProps {
  value: CountryCode;
  onChange: (value: string) => void;
  renderFlagButton: (props: any) => JSX.Element;
}
export const CountrySelectModal = ({
  value,
  onChange,
  renderFlagButton,
}: CountrySelectModalProps) => {
  const [visible, setVisible] = useState(false);
  const sx = useSx();

  const theme = sx({
    ...DEFAULT_THEME,
    fontFamily: "root",
    variant: "text.p",
  });

  return (
    <CountryPicker
      visible={visible}
      countryCode={value}
      onSelect={(country) => {
        onChange(country.cca2);
      }}
      theme={theme}
      withFilter
      renderFlagButton={renderFlagButton}
      onOpen={() => setVisible(true)}
      onClose={() => setVisible(false)}
      // @ts-expect-error `closeButtonImage?: ImageSourcePropType` in docs but not defined in package
      closeButtonImage={require("app/design/images/close.png")}
    />
  );
};
CountrySelectModal.displayName = "CountrySelectModal";
