import { ApolloError, NetworkStatus } from "@apollo/client";
import { useDripsyTheme, useSx, View } from "dripsy";
import { format } from "date-fns";
import { Hoverable } from "moti/build/interactions/pressable/hoverable";
import { useEffect, useState } from "react";
import { Platform, SectionList, ViewProps, Keyboard } from "react-native";

import {
  ChannelMessagesQuery,
  ChannelMessagesQueryResult,
  useMarkChannelMutation,
} from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import {
  FlatListBottomSpacer,
  FlatListLoadingMore,
} from "app/components/screen/flat-list-screen";
import { Text } from "app/components/text";
import { useCurrentUser } from "app/hooks";
import { getQueryLoadingStatus } from "app/utils";

import { ChannelListEmpty } from "app/features/community/chat/channel/channel-list/channel-list-empty";
import { ChannelMessage } from "../types";
import { Message } from "./message";
import { MessageListLoading } from "./list.loading";

const Cell = (props: ViewProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Hoverable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <View {...props} sx={{ zIndex: hovered ? 2 : 1 }} />
    </Hoverable>
  );
};

interface DateTitleProps {
  date: string;
}

const DateTitle = ({ date }: DateTitleProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Text
      sx={{
        fontWeight: "600",
        color: "$grey",
        fontSize: 12,
        lineHeight: 18,
        textAlign: "center",
        mt: "$4",
        mb: 0 - theme.space.$2,
      }}
    >
      {format(new Date(date), "MMM d, yyyy")}
    </Text>
  );
};

const MessageListEmpty = () => (
  <ChannelListEmpty
    header="No messages yet"
    text="Be the first to send a message!"
    sx={{
      transform: [{ scaleY: -1 }],
    }}
  />
);

interface MessageListProps {
  channel?: ChannelMessagesQuery["channel"];
  data?: ChannelMessage[];
  error?: ApolloError;
  after?: string;
  networkStatus: NetworkStatus;
  fetchMore: ChannelMessagesQueryResult["fetchMore"];
}

const MessageList = ({
  channel,
  data = [],
  error,
  after,
  networkStatus,
  fetchMore,
}: MessageListProps) => {
  const { currentUser } = useCurrentUser();
  const sx = useSx();
  const [markRead, { error: markError }] = useMarkChannelMutation();

  const { loading, loadingMore } = getQueryLoadingStatus(networkStatus);

  useEffect(() => {
    if (loading || !channel) return;
    if ("isMember" in channel && !channel.isMember) return;
    const { __typename, id, unread } = channel;
    if (unread && !markError) {
      markRead({
        variables: {
          input: {
            channelId: channel.id,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          markChannel: {
            __typename,
            id,
            unread: false,
          },
        },
        update: (cache) => {
          cache.modify({
            fields: {
              numUnreadMessages(existing) {
                if (!existing) return 0;
                return existing - 1;
              },
            },
          });
        },
      });
    }
  }, [loading, channel]);

  if (loading) return <MessageListLoading />;

  if (error)
    return (
      <View sx={{ flexGrow: 1, p: "$4" }}>
        <DataError
          title="Channel not found"
          description="This channel may have been deleted or you may no longer have access to it, please contact support if you think this is a mistake."
        />
      </View>
    );

  const onEndReached = () => {
    if (loadingMore || !data.length || !after) return;
    fetchMore({
      variables: {
        after,
      },
    });
  };

  const groups = data.reduce(
    (currentGroups: { [date: string]: ChannelMessage[] }, message) => {
      if (message.deletedAt) return currentGroups;

      const date = message.createdAt.split("T")[0];
      if (!currentGroups?.[date]) {
        currentGroups[date] = [];
      }
      currentGroups[date]?.push(message);
      return currentGroups;
    },
    {}
  );

  const sections = Object.keys(groups).map((date) => ({
    title: date,
    data: groups[date] || [],
  }));

  return (
    <SectionList
      sections={sections}
      renderSectionFooter={({ section: { title } }) => (
        <DateTitle date={title} />
      )}
      renderItem={({ item, index, section }) => {
        return (
          <Message
            message={item}
            isMe={item?.author?.id === currentUser?.id}
            nextMessage={section.data?.[index + 1]}
          />
        );
      }}
      contentContainerStyle={sx({
        px: "$4",
        pt: "$2",
        ...(sections.length === 0 && { flex: 1 }),
      })}
      inverted
      onEndReachedThreshold={0.8}
      onEndReached={onEndReached}
      ListFooterComponent={
        loadingMore ? (
          <FlatListLoadingMore sx={{ mb: 0 }} />
        ) : (
          <FlatListBottomSpacer sx={{ borderTopWidth: 0, mb: 0 }} />
        )
      }
      ListEmptyComponent={<MessageListEmpty />}
      CellRendererComponent={Cell}
      disableVirtualization={Platform.OS === "web"}
      {...(Platform.OS !== "web" && { onScroll: Keyboard.dismiss })}
    />
  );
};

export type { MessageListProps };
export { MessageList };
