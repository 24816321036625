import { ComponentProps, ReactNode } from "react";
import { Platform } from "react-native";
import Hyperlink from "react-native-hyperlink";
import * as ExpoWebBrowser from "expo-web-browser";
import { Sx, useSx } from "dripsy";
import { useWebLinkProps } from "./web-link";
import { TextProps, Text } from "./text";

interface BaseHyperlinkTextProps
  extends Omit<ComponentProps<typeof Hyperlink>, "children"> {
  children: ReactNode;
  textProps: TextProps;
  linkSx?: Sx;
  onPress?: (url: string, text?: string) => void;
  injectViewProps?: (
    url: string,
    text?: string
  ) => {
    accessibilityRole?: "link";
    href?: string;
    onPress?: (e?: $TSFixMe) => void;
  };
}

const BaseHyperlinkText = ({
  textProps,
  children,
  linkSx = {},
  ...props
}: BaseHyperlinkTextProps) => {
  const sx = useSx();
  return (
    <Hyperlink
      {...props}
      linkStyle={sx({
        textDecorationLine: "underline",
        ...(Platform.OS === "web" && { wordBreak: "break-all" }),
        ...linkSx,
      })}
    >
      <Text {...textProps}>{children}</Text>
    </Hyperlink>
  );
};
interface HyperlinkTextProps extends TextProps {
  openInNewWindow?: boolean;
  linkSx?: Sx;
}

const WebHyperlinkText = ({
  children,
  linkSx,
  ...props
}: HyperlinkTextProps) => {
  const getWebLinkProps = useWebLinkProps();
  const sx = useSx();
  return (
    <BaseHyperlinkText
      // Note: No onPress here... override with injectViewProps to
      // access the onPress event so it can be used with preventDefault.
      // TODO: Replace Hyperlink with own library -> its source is nasty.
      injectViewProps={(url) => {
        const { onPress: _onPress, ...rest } = getWebLinkProps(url);

        return {
          ...rest,
          onPress: (e) => getWebLinkProps(url).onPress(e),
        };
      }}
      textProps={props}
      linkStyle={sx({ textDecorationLine: "underline", ...linkSx })}
    >
      {children}
    </BaseHyperlinkText>
  );
};

const DefaultHyperlinkText = ({
  children,
  linkSx = {},
  ...props
}: HyperlinkTextProps) => {
  return (
    <BaseHyperlinkText
      onPress={(url: string) => {
        if (Platform.OS !== "web") {
          ExpoWebBrowser.openBrowserAsync(url);
        }
      }}
      injectViewProps={(url: string) => ({
        accessibilityRole: "link",
        href: url,
      })}
      textProps={props}
      linkSx={linkSx}
    >
      {children}
    </BaseHyperlinkText>
  );
};

const HyperlinkText = ({ openInNewWindow, ...props }: HyperlinkTextProps) => {
  if (openInNewWindow) {
    return <WebHyperlinkText {...props} />;
  }

  return <DefaultHyperlinkText {...props} />;
};

export type { HyperlinkTextProps };
export { HyperlinkText };
