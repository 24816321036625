import { View } from "dripsy";
import { useThemeValue } from "app/hooks/use-theme-value";
import { FCC } from "app/types";
import { useScreenSize } from "app/hooks";

const TrendingQuestionsMask: FCC = ({ children }) => {
  const screenPadding = useThemeValue(
    (theme) => theme.layout.screen.container.padding
  );
  const { isMobile } = useScreenSize();

  return (
    <View
      sx={{
        mx: 0 - screenPadding,
        maskImage: !isMobile
          ? `linear-gradient(to right,
              rgba(0,0,0,.5),
              black ${screenPadding}px,
              black,
              black calc(100% - ${screenPadding}px), 
              rgba(0,0,0,.5))`
          : null,
      }}
    >
      {children}
    </View>
  );
};

export { TrendingQuestionsMask };
