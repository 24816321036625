import { H4, P } from "dripsy";
import { Modal } from "native-base";
import { PropsWithChildren } from "react";
import { Button } from "app/design/button";

interface BaseIneligibleModalProps {
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  confirmText: string;
}

type IneligibleModalProps = PropsWithChildren<BaseIneligibleModalProps>;

const IneligibleModal = ({
  loading,
  isOpen,
  onConfirm,
  onClose,
  confirmText,
  children,
}: IneligibleModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header pr="56px">
          <H4 sx={{ my: 0 }}>You won&apos;t be able to apply for jobs</H4>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onPress={onClose}>
            Back
          </Button>
          <Button
            containerSx={{ ml: "$2" }}
            onPress={onConfirm}
            loading={loading}
          >
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

type UserIneligibleModalProps = Omit<IneligibleModalProps, "confirmText">;

const NewUserIneligibleModal = ({
  children,
  ...props
}: UserIneligibleModalProps) => {
  return (
    <IneligibleModal confirmText="Sign up anyway" {...props}>
      <P>{children}</P>
      <P sx={{ mb: 0 }}>
        However, there&apos;s more to Migrate than just jobs - sign up to
        interact with our thriving community of healthcare professionals.
      </P>
    </IneligibleModal>
  );
};

const ExistingUserIneligibleModal = ({
  children,
  ...props
}: UserIneligibleModalProps) => {
  return (
    <IneligibleModal confirmText="Continue" {...props}>
      <P sx={{ mb: 0 }}>{children}</P>
    </IneligibleModal>
  );
};

export type { IneligibleModalProps };
export { IneligibleModal, NewUserIneligibleModal, ExistingUserIneligibleModal };
