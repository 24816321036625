import { H2, styled, Sx, Text } from "dripsy";
import { Skeleton } from "native-base";
import { Card as BaseCard } from "app/design/card";

const OverviewCard = styled(BaseCard, {
  themeKey: "card",
  defaultVariant: "primary",
})({
  mb: ["$6", null, "$7"],
});

const OverviewHeader = styled(H2, {
  themeKey: "text",
  defaultVariant: "headingLight",
})({
  mb: "$4",
});

const OverviewText = styled(Text, {
  themeKey: "text",
  defaultVariant: "light",
})({
  mb: "$4",
});

interface OverviewLoadingProps {
  sx?: Sx;
}

const OverviewLoading = ({ sx = {} }: OverviewLoadingProps) => (
  <OverviewCard sx={sx}>
    <Skeleton.Text
      lines={1}
      _line={{ height: 28, width: 240 }}
      startColor="rgba(255, 255, 255, .2)"
      endColor="rgba(255, 255, 255, .1)"
      mb={4}
    />

    <Skeleton.Text
      lines={2}
      _line={{ width: "100%" }}
      startColor="rgba(255, 255, 255, .2)"
      endColor="rgba(255, 255, 255, .1)"
      mb={4}
    />

    <Skeleton
      width="100%"
      startColor="rgba(255, 255, 255, .2)"
      endColor="rgba(255, 255, 255, .1)"
      rounded={8}
    />
  </OverviewCard>
);

export { OverviewCard, OverviewHeader, OverviewText, OverviewLoading };
