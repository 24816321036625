import { SxProp, View } from "dripsy";
import React, { MutableRefObject, useContext, useRef } from "react";
import { AccessibilityRole, Platform } from "react-native";
import { Button, ButtonProps } from "app/design/button";
import { useKeyboardInterceptor } from "../keyboard-interceptor";

const isWeb = Platform.OS === "web";

const FormContext = React.createContext<{
  onSubmit: () => void;
}>({
  onSubmit: () => {},
});

type FormSubmitButtonProps = Omit<ButtonProps, "onPress">;
export const FormSubmitButton = (props: FormSubmitButtonProps) => {
  const { onSubmit } = useContext(FormContext);
  return <Button {...props} onPress={onSubmit} />;
};

interface FormProps {
  sx?: SxProp;
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
}

const Form = ({ sx, onSubmit, children }: FormProps) => {
  const ref = useRef(null);

  useKeyboardInterceptor({
    componentRef: ref,
    onKeyDown: (event) => {
      if (event.key === "Enter") {
        if (
          document.activeElement &&
          ["INPUT", "SELECT"].includes(document.activeElement.tagName)
        ) {
          onSubmit();
        }
      }
    },
  });

  return (
    <FormContext.Provider value={{ onSubmit }}>
      <View
        ref={ref}
        sx={sx}
        accessibilityRole={isWeb ? ("form" as AccessibilityRole) : undefined}
      >
        {children}
      </View>
    </FormContext.Provider>
  );
};

type IFormComponent = ((
  props: FormProps & { ref?: MutableRefObject<any> }
) => JSX.Element) & {
  SubmitButton: React.MemoExoticComponent<
    (
      props: FormSubmitButtonProps & { ref?: MutableRefObject<any> }
    ) => JSX.Element
  >;
};

let FormTemp: any = Form;
FormTemp.SubmitButton = FormSubmitButton;
const FormExport = FormTemp as IFormComponent;

export { FormExport as Form };
