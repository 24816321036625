import {
  MessageBubbleProps,
  MessageBubble,
  MessageBubbleContainer,
} from "../bubble";

type UnsentMessageBubbleProps = Omit<MessageBubbleProps, "variant">;

const UnsentMessageBubble = (messageBubbleProps: UnsentMessageBubbleProps) => {
  return (
    <MessageBubbleContainer variant="unsent">
      <MessageBubble variant="unsent" {...messageBubbleProps} />
    </MessageBubbleContainer>
  );
};

export type { UnsentMessageBubbleProps };
export { UnsentMessageBubble };
