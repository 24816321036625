import { View } from "dripsy";
import { ReactNode } from "react";
import { ActivityMessage } from "app/design/activity-indicator";
import { StepScreenFormContainer } from "app/components/step-screen-form";
import { DataError } from "app/components/data-error";
import { CandidateV2, useJobPreferencesScreenQuery } from "app/types";
import { CareerQuizForm, ScreenHeader } from "./form";

interface ScreenWrapperProps {
  children: ReactNode;
}

const ScreenWrapper = ({ children }: ScreenWrapperProps) => (
  <StepScreenFormContainer>
    <ScreenHeader stepProgress={0} />
    <View sx={{ mt: ["$7", "$9"], alignItems: "center" }}>{children}</View>
  </StepScreenFormContainer>
);

export const CareerQuizScreen = () => {
  const { data, loading, error } = useJobPreferencesScreenQuery();

  if (error)
    return (
      <ScreenWrapper>
        <DataError />
      </ScreenWrapper>
    );

  if (loading) {
    return (
      <ScreenWrapper>
        <ActivityMessage>Loading preferences</ActivityMessage>
      </ScreenWrapper>
    );
  }

  const { profession, country } = data?.me as CandidateV2;

  return <CareerQuizForm professionId={profession?.id} country={country} />;
};
