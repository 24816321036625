const question = {
  ask: "Ask a question",
  verifyToAsk: "Verify your account to ask a question!",
  answer: "Add your answer",
  verifyToAnswer: "Verify your account to add your answer!",
  verifyToVote: "Verify your account to vote!",
  seeAll: "See all",
};

const channel = {
  seeAll: "See all",
  joinChannels: "Join more channels",
  verifyToJoin: "Verify your account to browse channels!",
};

const community = {
  question,
  channel,
};

export const copy = {
  community,
};
