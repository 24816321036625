import { Skeleton } from "native-base";
import { Divider, DividerSecondary } from "./divider";
import { CardInner, CardOuter } from "./card";

const JobDetailsLoading = () => (
  <CardOuter>
    <Skeleton.Text lines={3} alignItems="center" mb={6} />
    <Skeleton.Text lines={2} alignItems="center" />
    <DividerSecondary />
    <CardInner>
      <Skeleton.Text lines={3} mb={6} />
      <Skeleton.Text lines={2} />
      <Divider sx={{ mb: "$6" }} />
      <Skeleton.Text lines={5} mb={6} />
      <Skeleton.Text lines={3} />
      <Divider sx={{ mb: "$6" }} />
      <Skeleton.Text lines={3} mb={6} />
      <Skeleton.Text lines={2} />
    </CardInner>
    <DividerSecondary sx={{ mt: "$6", mb: "$6" }} />
    <Skeleton.Text lines={3} alignItems="center" mb={6} />
    <Skeleton.Text lines={2} alignItems="center" />
  </CardOuter>
);

export { JobDetailsLoading };
