import { gql } from "@apollo/client";
import {
  Question,
  QuestionsQueryResult,
  useQuestionsAnsweredQuery,
} from "app/types/generated/schema";
import { useAuthenticatedUser } from "app/hooks/use-current-user";
import { conditionalParam } from "app/utils";
import { Answer } from "../questions/answer";
import {
  DeletedQuestion,
  DetailedQuestionList,
} from "../questions/detailed-question-list";
import { useDetailedQuestions } from "../questions/use-detailed-questions";
import { UserHeader } from "../questions/user-header";

export const QuestionsAnsweredQuery = gql`
  query QuestionsAnswered($where: AnswersWhereInput, $size: Int) {
    answers(where: $where, _size: $size) {
      data {
        ...AnswerFragment
        question {
          id
          title
          createdAt
          topics {
            id
            name
          }
          author {
            ...UserHeaderFragment
          }
          answers {
            count
          }
        }
      }
      after
    }
  }
  ${UserHeader.fragments.user}
  ${Answer.fragments.answer}
`;
const QuestionsAnswered = () => {
  const { currentUser } = useAuthenticatedUser();
  const { data, error, fetchMore, networkStatus } = useDetailedQuestions(
    useQuestionsAnsweredQuery,
    {
      where: {
        ...conditionalParam(
          {
            author: {
              in: [currentUser.id],
            },
          },
          !!currentUser
        ),
      },
    },
    { skip: !currentUser }
  );

  return (
    <DetailedQuestionList
      questions={data?.answers.data.map((answer) =>
        !!answer.question
          ? {
              ...answer.question,
              highlightedAnswer: answer,
              isDeleted: false,
            }
          : ({
              id: `deleted-question-${answer.id}`,
              highlightedAnswer: answer,
              isDeleted: true,
            } as DeletedQuestion)
      )}
      after={data?.answers.after}
      error={error}
      errorMessagePretty="Couldn't load questions answered by you"
      fetchMore={fetchMore as QuestionsQueryResult["fetchMore"]}
      networkStatus={networkStatus}
    />
  );
};

export { QuestionsAnswered };
