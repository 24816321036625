import { ToastCloseButton } from "app/components/use-toast";
import { NotificationFieldsFragment } from "app/types";
import { NotificationCard } from "./notification-card";
import { useToastStyles } from "./use-toast-styles";

interface NotificationToastProps {
  notification: NotificationFieldsFragment;
  onClose: () => void;
}

const NotificationToast = ({
  notification,
  onClose,
}: NotificationToastProps) => {
  const toastStyleProps = useToastStyles();

  return (
    <NotificationCard
      {...toastStyleProps}
      notification={notification}
      onPress={onClose}
      renderHeaderRight={() => (
        <ToastCloseButton onClose={onClose} status="info" ml={4} />
      )}
    />
  );
};

export { NotificationToast };
