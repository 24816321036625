import { H2, Pressable, Row, useDripsyTheme, View } from "dripsy";
import { ReactNode, useState } from "react";
import { Feather } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { Badge } from "app/design/badge";
import { AnimateHeight } from "app/components/animate-height";

export enum SectionStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
}

interface ProfileSectionBadgeProps {
  status?: SectionStatus;
}
const ProfileSectionBadge = ({ status }: ProfileSectionBadgeProps) => {
  const { theme } = useDripsyTheme();
  const { t } = useTranslation("enums");
  if (!status) return null;

  const bg = {
    [SectionStatus.NOT_STARTED]: theme.colors["$pink-shade-1"],
    [SectionStatus.IN_PROGRESS]: theme.colors.$orange,
    [SectionStatus.COMPLETED]: theme.colors["$blue-tint-1"],
  }[status];

  return (
    <Badge
      sx={{
        ml: "$3",
        bg,
        cursor: "text",
        py: 1,
        px: "$2",
        borderRadius: 6,
        justifyContent: "center",
      }}
      textSx={{
        fontFamily: "heading",
        fontSize: [10, 12],
        lineHeight: [15, 18],
        textTransform: "uppercase",
        ...(Platform.OS === "web" && {
          position: "relative",
          top: 1,
        }),
        ...(Platform.OS === "android" && {
          top: -1,
        }),
      }}
    >
      {t(`profileSectionStatus.${status}`)}
    </Badge>
  );
};

interface ProfileSectionHeaderProps extends ProfileSectionBadgeProps {
  title: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}
const ProfileSectionHeader = ({
  title,
  status,
  collapsed,
  setCollapsed,
}: ProfileSectionHeaderProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Pressable
      onPress={() => setCollapsed(!collapsed)}
      sx={{ alignSelf: "flex-start", mb: "$4" }}
    >
      <Row sx={{ alignItems: "center" }}>
        <Feather
          name={collapsed ? "chevron-right" : "chevron-down"}
          size={24}
          color={theme.colors.$black}
          onPress={() => setCollapsed(!collapsed)}
        />
        <H2 sx={{ fontSize: [18, 24], lineHeight: [22, 28], mb: 0, ml: "$2" }}>
          {title}
        </H2>
        <ProfileSectionBadge status={status} />
      </Row>
    </Pressable>
  );
};

interface ProfileSectionProps extends ProfileSectionBadgeProps {
  title: string;
  showHeader?: boolean;
  children: ReactNode;
}
export const ProfileSection = ({
  children,
  title,
  status,
  showHeader = true,
}: ProfileSectionProps) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      {showHeader ? (
        <ProfileSectionHeader
          title={title}
          status={status}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      ) : null}
      <AnimateHeight hide={collapsed}>
        <View sx={{ pb: "$6" }}>{children}</View>
      </AnimateHeight>
    </>
  );
};
