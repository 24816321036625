import { Row } from "dripsy";
import { Button } from "app/design/button";
import { InputControl } from "app/components/form/input-control";
import { DropdownControl } from "app/components/form/dropdown-control";
import { useYupForm, yup } from "app/utils/yup";
import { useProfileCardContext } from "../../card/context";
import { useQuestionResponseMutations } from "../hooks/use-question-response-mutations";
import { generateTempId, isTempField, ProfileForm } from "../profile-form";

interface QuestionResponseFormValues {
  id: string;
  question: string;
  response: string;
}

interface QuestionResponseFormCardProps {
  defaultValues: QuestionResponseFormValues;
  onCancel: () => void;
  options?: string[];
  mode?: "create" | "update";
}

const DEFAULT_VALUE = {
  question: "",
  response: "",
};

const schema = yup.object({
  question: yup.string().required().label("Question"),
  response: yup.string().required().min(40).label("Answer"),
});

const QuestionResponseFormCard = ({
  onCancel,
  defaultValues,
  options,
}: QuestionResponseFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const { createQuestionResponse, updateQuestionResponse } =
    useQuestionResponseMutations();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useYupForm<QuestionResponseFormValues>({
    schema,
    defaultValues,
  });

  const fieldId = watch("id");
  const isUpdating = !isTempField({ id: fieldId });

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };

  const onSubmit = async (values: QuestionResponseFormValues) => {
    const { id, ...createInput } = values;

    try {
      if (isUpdating) {
        if (!id)
          throw new Error("question response: id is required for update");

        await updateQuestionResponse({
          variables: {
            input: {
              ...createInput,
              // appease ts
              id,
            },
          },
        });
      } else {
        await createQuestionResponse({
          variables: {
            input: createInput,
          },
        });
      }

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileForm label="question response" onCancel={onClose}>
      <DropdownControl
        control={control}
        name="question"
        dropdownProps={{
          label: "Question",
          containerSx: { mb: "$3" },
          options:
            options?.map((question) => ({
              label: question,
              value: question,
            })) || [],
          placeholder: "Select question",
          loading: !options,
        }}
      />

      <InputControl
        control={control}
        name="response"
        inputProps={{
          multiline: true,
          label: "Answer",
          placeholder: "Enter your answer to this question",
        }}
      />

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

QuestionResponseFormCard.createInstance = () => ({
  id: generateTempId("question-response"),
  ...DEFAULT_VALUE,
});

export type { QuestionResponseFormValues, QuestionResponseFormCardProps };
export { QuestionResponseFormCard };
