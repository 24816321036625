import { useForm, useController } from "react-hook-form";
import { Platform } from "react-native";

const BULLET_POINT = "•";
const BULLET_POINT_WITH_SPACE = `${BULLET_POINT} `;

export const useBulletedInputControl = ({
  initialValue,
}: {
  initialValue: { value: string }[];
}) => {
  const { control, setValue, watch } = useForm<{
    input: string;
  }>({
    defaultValues: {
      input:
        initialValue?.map((block) => `${BULLET_POINT} ${block}`).join("\n") ||
        "",
    },
  });

  const { field } = useController({
    name: "input",
    control,
  });

  return {
    field,
    setInput: (input: string) => setValue("input", input),
    watch,
  };
};

interface InputTarget {
  value: string;
  selectionStart: number;
  selectionEnd: number;
}

interface UseBulletedInputProps<T> {
  setInput: (value: string) => void;
  // optional prop passed on web only
  preventDefault: (e?: T) => void;
  getTarget: (e?: T) => InputTarget;
  setCursorPosition?: (value: number) => void;
}

export const useBulletedInput = <TextInputKeydownEvent>({
  setInput,
  setCursorPosition,
  preventDefault,
  getTarget,
}: UseBulletedInputProps<TextInputKeydownEvent>) => {
  const replaceText = (
    value: string,
    replace: string,
    start: number,
    end: number,
    cursorPositionOverride?: number
  ) => {
    const valueBeforeReplaced = value.slice(0, start);
    const valueAfterReplaced = value.slice(end);
    setInput(`${valueBeforeReplaced}${replace}${valueAfterReplaced}`);
    setCursorPosition?.(cursorPositionOverride || start + replace.length);
  };

  const removeBlock = (e: TextInputKeydownEvent, offset = 1) => {
    const { value, selectionStart, selectionEnd } = getTarget(e);
    const isFirstBlock =
      selectionStart === 0 || selectionStart === BULLET_POINT_WITH_SPACE.length;
    const selectionIncludesNewline = value.charAt(selectionEnd - 1) === "\n";
    replaceText(
      value,
      "",
      isFirstBlock ? 0 : selectionStart - offset,
      isFirstBlock && !selectionIncludesNewline
        ? selectionEnd + 1
        : selectionEnd,
      Platform.OS === "web" ? selectionStart - offset : undefined
    );
  };

  const getCurrentLine = (e: TextInputKeydownEvent) => {
    const { value, selectionStart } = getTarget(e);
    const lines = value.split(/\n/);
    let charCount = 0;
    const currentLineIndex = lines.findIndex((line) => {
      charCount += line.length + 1; // +1 for newline
      return charCount > selectionStart;
    });
    return lines[currentLineIndex]!;
  };

  const maybeRemoveBlock = (e: TextInputKeydownEvent) => {
    const { selectionStart, selectionEnd, value } = getTarget(e);
    const currentLine = getCurrentLine(e);

    const isEmptyBlock = currentLine === BULLET_POINT_WITH_SPACE;
    if (isEmptyBlock) {
      preventDefault(e);
      const isFirstLine = selectionStart === BULLET_POINT_WITH_SPACE.length;
      const offsetBulletAndWhitespace = isFirstLine ? 2 : 3; // +1 for newline;
      removeBlock(e, offsetBulletAndWhitespace);
      return;
    }

    const hasSelectedFullLine =
      selectionEnd - selectionStart === currentLine.length;
    if (hasSelectedFullLine) {
      preventDefault(e);
      removeBlock(e);
      return;
    }

    const isAfterBullet =
      value.substring(selectionStart - 1, selectionStart) === BULLET_POINT;
    const isAfterBulletWithSpace =
      value.substring(selectionStart - 2, selectionStart) ===
      BULLET_POINT_WITH_SPACE;

    if (isAfterBullet || isAfterBulletWithSpace) {
      preventDefault(e);
      return;
    }
  };

  const formatText = (text: string) => {
    const initializedText = text.startsWith(BULLET_POINT_WITH_SPACE)
      ? text
      : BULLET_POINT_WITH_SPACE + text;

    const formattedText = initializedText
      // replace empty blocks (don't allow endless bullets)
      .replace(/•[" "]\n$/gm, BULLET_POINT_WITH_SPACE)
      // replace double empty blocks (when adding block with newline on below block)
      .replace(/•[" "]\n•[" "]$/gm, BULLET_POINT_WITH_SPACE)
      // replace any newlines without bullets after
      .replace(/\n(?!•)/g, "\n" + BULLET_POINT_WITH_SPACE)
      // replace any bullets without spaces after
      .replace(/•([^" "])/g, BULLET_POINT_WITH_SPACE)
      // replace any bullets without newlines before
      .replace(/([^\n])•/g, "");

    return formattedText;
  };

  return {
    maybeRemoveBlock,
    formatText,
    BULLET_POINT,
    BULLET_POINT_WITH_SPACE,
  };
};
