import { gql } from "@apollo/client";
import { Skeleton } from "native-base";
import { H4, Row, styled, SxProp, useDripsyTheme, View } from "dripsy";
import { Feather } from "@expo/vector-icons";

import {
  CommunityChannelCardHeaderUserFragmentFragment,
  Scalars,
  useCommunityChannelCardHeaderQuery,
} from "app/types/generated/schema";
import {
  Avatar,
  AvatarStack as BaseAvatarStack,
  AvatarStackProps as BaseAvatarStackProps,
  AvatarLoading,
} from "app/design/avatar";
import { Text } from "app/components/text";
import { QueryWrapper } from "app/components/query-wrapper";
import { ColorAnimatedView } from "app/components/color-animated-view";

const COMMUNITY_CHANNEL_HEADER_CHANNEL_FRAGMENT = gql`
  fragment CommunityChannelCardHeaderChannelFragment on CommunityPublicChannel {
    id
    name
    isMember
  }
`;

const COMMUNITY_CHANNEL_HEADER_USER_FRAGMENT = gql`
  fragment CommunityChannelCardHeaderUserFragment on User {
    id
    photoUrl
  }
`;

const COMMUNITY_CHANNEL_HEADER_MEMBERS_FRAGMENT = gql`
  fragment CommunityChannelCardHeaderMembersFragment on UserPage {
    data {
      ...CommunityChannelCardHeaderUserFragment
    }
    count
  }
  ${COMMUNITY_CHANNEL_HEADER_USER_FRAGMENT}
`;

const COMMUNITY_CHANNEL_HEADER_QUERY = gql`
  query CommunityChannelCardHeader($channelId: MigrateID!) {
    communityPublicChannel(id: $channelId) {
      ...CommunityChannelCardHeaderChannelFragment
      members(_size: 2) {
        ...CommunityChannelCardHeaderMembersFragment
      }
      messages(_size: 2) {
        data {
          id
          author {
            ...CommunityChannelCardHeaderUserFragment
          }
        }
      }
    }
  }
  ${COMMUNITY_CHANNEL_HEADER_USER_FRAGMENT}
  ${COMMUNITY_CHANNEL_HEADER_CHANNEL_FRAGMENT}
  ${COMMUNITY_CHANNEL_HEADER_MEMBERS_FRAGMENT}
`;

const Container = styled(View)({
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const DescriptionContainer = styled(View)({
  flexDirection: "column",
  justifyContent: "center",
  alignSelf: "center",
  ml: "$4",
  flexShrink: 1,
});

const JoinedBadge = () => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        mr: "$2",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Feather name="check" color={theme.colors.$blue} size={18} />
      <Text sx={{ ml: "$1", fontWeight: "600", color: "$blue" }}>Joined</Text>
    </View>
  );
};

const AvatarStack: React.FC<Pick<BaseAvatarStackProps, "children">> = ({
  children,
}) => (
  <BaseAvatarStack
    avatarProps={{
      size: 12,
    }}
    overlapRatio={0.5}
    max={2}
  >
    {children}
  </BaseAvatarStack>
);

const CommunityChannelCardHeaderLoadingContent = () => (
  <>
    <AvatarStack>
      <AvatarLoading />
    </AvatarStack>

    <DescriptionContainer>
      <Skeleton.Text
        flex={0.5}
        lines={1}
        w={200}
        maxWidth={"100%"}
        _line={{
          height: 4,
        }}
        mb={2}
      />
      <Skeleton.Text
        flex={0.5}
        lines={1}
        w={20}
        _line={{
          height: 3,
        }}
      />
    </DescriptionContainer>
  </>
);

const CommunityChannelCardHeaderLoading = styled(() => (
  <Container>
    <CommunityChannelCardHeaderLoadingContent />
  </Container>
))();

interface CommunityChannelCardHeaderErrorProps {
  message?: string;
}

const CommunityChannelCardHeaderErrorContent: React.FC<
  CommunityChannelCardHeaderErrorProps
> = ({ message }) => {
  const { theme } = useDripsyTheme();
  return (
    <>
      <AvatarStack>
        <Avatar sx={{ bg: theme.colors.$error }}>
          <Feather
            name="alert-triangle"
            color={theme.colors.$white}
            size={24}
          />
        </Avatar>
      </AvatarStack>

      <DescriptionContainer>
        <H4 sx={{ color: theme.colors.$error }}>Channel Load Error</H4>
        <Text sx={{ color: theme.colors.$error }}>{message}</Text>
      </DescriptionContainer>
    </>
  );
};

const CommunityChannelCardHeaderError: React.FC<
  CommunityChannelCardHeaderErrorProps
> = ({ message }) => (
  <Container>
    <CommunityChannelCardHeaderErrorContent message={message} />
  </Container>
);

interface CommunityChannelCardHeaderProps {
  channelId: Scalars["MigrateID"];
  showMembership?: boolean;
  sx?: SxProp;
}

const CommunityChannelCardHeaderComponent = ({
  channelId,
  showMembership,
  sx,
}: CommunityChannelCardHeaderProps) => {
  const { theme } = useDripsyTheme();

  const queryResponse = useCommunityChannelCardHeaderQuery({
    variables: {
      channelId,
    },
  });

  return (
    <Container sx={sx}>
      <QueryWrapper
        queryHookResult={queryResponse}
        loaderComponent={() => <CommunityChannelCardHeaderLoadingContent />}
        errorComponent={(error) => (
          <CommunityChannelCardHeaderErrorContent message={error?.message} />
        )}
      >
        {({ data: { communityPublicChannel } }) => {
          const recentMessageAuthors = communityPublicChannel.messages.data
            .map((message) => message.author)
            .filter(
              (
                author
              ): author is Exclude<
                CommunityChannelCardHeaderUserFragmentFragment,
                undefined
              > => !!author
            );

          const channelMembers = communityPublicChannel.members.data;

          const avatarUsers = [...recentMessageAuthors, ...channelMembers]
            .reduce((acc, user) => {
              if (!acc.find((u) => u.id === user.id)) acc.push(user);
              return acc;
            }, [] as CommunityChannelCardHeaderUserFragmentFragment[])
            .slice(0, 2);

          return (
            <>
              <AvatarStack>
                {avatarUsers.length === 0 ? (
                  <Avatar />
                ) : (
                  avatarUsers.map((author) => (
                    <Avatar
                      key={author.id}
                      userId={author.id}
                      photoUrl={author.photoUrl}
                    />
                  ))
                )}
              </AvatarStack>
              <DescriptionContainer>
                <ColorAnimatedView
                  as="Text"
                  sx={{ ...theme.h4, mt: 0 - 3, flexShrink: 1 }}
                  colorStates={{
                    color: {
                      states: [
                        ["pressed", theme.colors.$blue],
                        ["hovered", theme.colors.$blue],
                      ],
                      initialColor: theme.h4.color,
                    },
                  }}
                >
                  {`#${communityPublicChannel.name}`}
                </ColorAnimatedView>

                <Row>
                  {showMembership && communityPublicChannel.isMember ? (
                    <JoinedBadge />
                  ) : null}
                  <Text
                    sx={{ fontWeight: "500" }}
                  >{`${communityPublicChannel.members.count} members`}</Text>
                </Row>
              </DescriptionContainer>
            </>
          );
        }}
      </QueryWrapper>
    </Container>
  );
};

CommunityChannelCardHeaderComponent.fragments = {
  communityPublicChannel: COMMUNITY_CHANNEL_HEADER_CHANNEL_FRAGMENT,
  user: COMMUNITY_CHANNEL_HEADER_USER_FRAGMENT,
  members: COMMUNITY_CHANNEL_HEADER_MEMBERS_FRAGMENT,
};

export type {
  CommunityChannelCardHeaderProps,
  CommunityChannelCardHeaderErrorProps,
};
export {
  COMMUNITY_CHANNEL_HEADER_CHANNEL_FRAGMENT,
  COMMUNITY_CHANNEL_HEADER_USER_FRAGMENT,
  COMMUNITY_CHANNEL_HEADER_MEMBERS_FRAGMENT,
  COMMUNITY_CHANNEL_HEADER_QUERY,
  CommunityChannelCardHeaderLoading,
  CommunityChannelCardHeaderError,
  CommunityChannelCardHeaderComponent as CommunityChannelCardHeader,
};
