import { Skeleton } from "native-base";
import { ProfileCardContainer } from "../../card";

export const QuestionResponsesLoading = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, i) => (
        <ProfileCardContainer key={i}>
          <Skeleton.Text
            lines={1}
            _line={{ width: 150 + Math.random() * 100, height: 4, rounded: 3 }}
            mb={2}
          />
          <Skeleton.Text
            lines={1}
            _line={{ width: "100%", height: 4, rounded: 3 }}
          />
        </ProfileCardContainer>
      ))}
    </>
  );
};
