import { useScreenSize } from "app/hooks";
import { PropsWithChildren } from "react";

export const MinScreenSize = ({
  children,
  size,
}: PropsWithChildren<{ size: "tablet" | "desktop" }>) => {
  const { isMobile, isTablet } = useScreenSize();
  if (size === "tablet" && isMobile) return null;
  if (size === "desktop" && isTablet) return null;

  return <>{children}</>;
};
