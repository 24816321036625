import { Platform } from "react-native";
import { Tabs } from "app/design/tabs";
import { Grid, GridItem } from "app/design/grid";
import { Card } from "app/design/card";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import { MinScreenSize } from "app/components/min-screen-size";
import { Layout } from "app/features/layout";

import { conditionalParam } from "app/utils";
import { SCREEN_PADDING_DESKTOP } from "app/provider/dripsy/components/layout";
import { useScreenSize } from "app/hooks";
import { memoizeScreen } from "app/utils/screen";
import { ProfileStats } from "./profile-stats";
import { QuestionsAsked } from "./questions-asked";
import { QuestionsAnswered } from "./questions-answered";

const CommunityActivityScreen = memoizeScreen(() => {
  const { isWide, isMobile } = useScreenSize();

  return (
    <Screen scrollable>
      <MinScreenSize size="tablet">
        <ScreenHeader>Community activity</ScreenHeader>
      </MinScreenSize>
      <Grid gutter={isMobile ? 0 : 24} sx={{ flexDirection: "row-reverse" }}>
        <GridItem widths={[1, null, null, 1 / 3]}>
          <Card
            sx={{
              mb: "$4",
              ...conditionalParam(
                { position: "sticky", top: SCREEN_PADDING_DESKTOP },
                Platform.OS === "web" && isWide
              ),
            }}
          >
            <ProfileStats />
          </Card>
        </GridItem>

        <GridItem widths={[1, null, null, 2 / 3]}>
          <Tabs
            tabs={[
              {
                id: "questions-asked",
                title: "Asked by you",
                content: <QuestionsAsked />,
              },
              {
                id: "questions-answered",
                title: "Answered by you",
                content: <QuestionsAnswered />,
              },
            ]}
          />
        </GridItem>
      </Grid>
    </Screen>
  );
});

const headerProps = {
  title: "Community activity",
  backHref: "/community",
};

CommunityActivityScreen.displayName = "CommunityActivityScreen";
CommunityActivityScreen.headerProps = headerProps;
CommunityActivityScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { CommunityActivityScreen };
