import React, { forwardRef, PropsWithChildren } from "react";
import { Sx, SxProp, useDripsyTheme, useSx } from "dripsy";
import { MotiProps } from "moti";
import {
  MotiPressable,
  MotiPressableProps,
  useInterpolateMotiPressable,
} from "moti/interactions";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  interpolateColor,
  useDerivedValue,
} from "react-native-reanimated";
import { StyleProp, TextProps, View } from "react-native";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { FCC } from "app/types/index";
import { useFontWeightStyle } from "./text";

type ColorProperty =
  | "backgroundColor"
  | "borderBottomColor"
  | "borderLeftColor"
  | "borderRightColor"
  | "borderColor"
  | "borderTopColor"
  | "borderEndColor"
  | "borderStartColor"
  | "color"
  | "fill"
  | "shadowColor"
  | "stroke";

type StateTuple = [condition: "hovered" | "pressed" | boolean, color: string];

type ColorStates = {
  [key in ColorProperty]?: {
    states: StateTuple[];
    initialColor: string;
  };
};

type OptionalColorStates = {
  [key in ColorProperty]?: {
    states?: StateTuple[];
    initialColor?: string;
  };
};

interface ColorAnimatedWrapperProps extends MotiProps {
  AnimatedComponent: $TSFixMe;
  colorStates: ColorStates;
  containerSx?: Sx;
  sx?: Sx;
  style?: StyleProp<$TSFixMe>;
  disabled?: boolean;
  duration?: number;
  animate?: {
    opacity: number;
  };
  numberOfLines?: TextProps["numberOfLines"];
}

interface ColorAnimatedComponentProps
  extends Omit<ColorAnimatedWrapperProps, "AnimatedComponent"> {}

interface UseAnimatedColorProps {
  states: StateTuple[];
  initialColor: string;
  colorProperty: ColorProperty;
  disabled?: boolean;
  duration?: number;
}

const useAnimatedColor = ({
  states,
  colorProperty,
  initialColor,
  duration,
  disabled,
}: UseAnimatedColorProps) => {
  /**
   * this hook flip-flops between colorA and colorB, using progress.value to track its state between poles.
   * when the color states change, the inactive pole (A or B) is set to the target color, and progress.value
   * is set to 0 or 1 respectively to animate the transition.
   */

  const progress = useSharedValue(0);
  const colorA = useSharedValue<string>(initialColor);
  const colorB = useSharedValue<string | null>(null);
  const target = useSharedValue<"A" | "B">("B");

  const animatedStyles = useAnimatedStyle(() => {
    /**
     * reanimated 💩:
     * without this ios throws "Can't find variable: colorProperty"
     */
    if (!colorProperty) throw new Error("color property undefined");

    // don't run interpolate before colors have been set
    if (!colorB.value) return { [colorProperty]: initialColor };
    return {
      [colorProperty]: interpolateColor(
        progress.value,
        [0, 1],
        [colorA.value, colorB.value]
      ),
    };
  }, []);

  const getCurrentState = (hovered: boolean, pressed: boolean) => {
    "worklet";

    /**
     * reanimated 💩:
     * without this ios throws "Tried to synchronously call function {find} from a different thread"
     */
    if (!states) throw new Error("states undefined");

    return states.find((state) => {
      if (["hovered", "pressed"].includes(state[0] as string)) {
        if (state[0] === "hovered" && hovered) return true;
        if (state[0] === "pressed" && pressed) return true;

        return false;
      } else {
        return state[0] === true;
      }
    });
  };

  const getActiveColor = (hovered: boolean, pressed: boolean) => {
    "worklet";
    const state = getCurrentState(hovered, pressed);
    if (!state) return initialColor;
    return state[1];
  };

  useInterpolateMotiPressable(
    ({ hovered, pressed }) => {
      "worklet";

      if (disabled) return;

      const currentColor = target.value === "B" ? colorA.value : colorB.value;
      const nextColor = getActiveColor(hovered, pressed);

      if (nextColor !== currentColor) {
        if (currentColor === colorA.value) {
          colorB.value = nextColor;
          progress.value = withTiming(1, { duration });
          target.value = "A";
        } else {
          colorA.value = nextColor;
          progress.value = withTiming(0, { duration });
          target.value = "B";
        }
      }
    },
    [states]
  );

  return animatedStyles;
};

const ColorAnimatedWrapper = memo(
  forwardRef<View, PropsWithChildren<ColorAnimatedWrapperProps>>(
    (
      {
        AnimatedComponent = Animated.View,
        colorStates,
        children,
        sx: _sx = {},
        style = {},
        disabled = false,
        animate,
        ...rest
      },
      ref
    ) => {
      const sx = useSx();
      const { theme } = useDripsyTheme();
      const { duration = theme.transitionDurations.normal } = rest;

      const animatedStyles = Object.keys(colorStates).map((key) =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAnimatedColor({
          // @ts-expect-error Guaranteed by Object.keys, generic too complex in forwardRef
          states: colorStates[key as ColorProperty].states,
          // @ts-expect-error Guaranteed by Object.keys, generic too complex in forwardRef
          initialColor: colorStates[key as ColorProperty].initialColor,
          colorProperty: key as ColorProperty,
          disabled,
          duration,
        })
      );

      // the following is a workaround due to moti not playing nicely on iOS with the latest version of reanimated.
      // we need the latest version of reanimated because of this https://github.com/nandorojo/moti/issues/207

      const progress = useDerivedValue(() => {
        if (animate?.opacity) {
          return withTiming(animate?.opacity, { duration });
        }
        return 1;
      }, [animate]);

      const motiAnimatePropOverride = useAnimatedStyle(() => {
        return {
          opacity: progress.value,
        };
      }, []);

      return (
        <AnimatedComponent
          ref={ref}
          style={[sx(_sx), style, ...animatedStyles, motiAnimatePropOverride]}
          {...rest}
        >
          {children}
        </AnimatedComponent>
      );
    }
  ),
  isEqual
);
ColorAnimatedWrapper.displayName = "ColorAnimatedWrapper";

const ColorAnimatedView: FCC<
  ColorAnimatedComponentProps & { as?: "View" | "Text" }
> = ({ as = "View", children, ...rest }) => {
  const textStyleOverride = useFontWeightStyle(rest.sx || {});
  const AnimatedComponent = {
    View: Animated.View,
    Text: Animated.Text,
  }[as];

  const style = as === "Text" ? [rest.style, textStyleOverride] : rest.style;

  return (
    <ColorAnimatedWrapper
      AnimatedComponent={AnimatedComponent}
      {...rest}
      style={style}
    >
      {children}
    </ColorAnimatedWrapper>
  );
};

type ColorAnimatedPressableProps = ColorAnimatedComponentProps &
  MotiPressableProps & {
    containerSx?: SxProp;
    disableInteraction?: boolean;
  };
const ColorAnimatedPressable = forwardRef<
  View,
  PropsWithChildren<ColorAnimatedPressableProps>
>(
  (
    {
      children,
      containerSx = {},
      onPress,
      accessibilityLabel,
      accessibilityRole,
      href,
      disableInteraction = false,
      sx: _sx = {},
      ...rest
    },
    ref
  ) => {
    const sx = useSx();
    const { disabled = disableInteraction } = rest;
    const {
      flex = undefined,
      flexGrow = undefined,
      flexShrink = undefined,
      flexBasis = undefined,
    } = _sx;

    return (
      <MotiPressable
        containerStyle={sx(containerSx)}
        style={sx({ flex, flexGrow, flexShrink, flexBasis })}
        onPress={!disabled ? onPress : () => {}}
        ref={ref}
        disabled={disabled}
        accessibilityLabel={accessibilityLabel}
        accessibilityRole={accessibilityRole}
        href={!disabled ? href : undefined}
      >
        <ColorAnimatedView sx={_sx} {...rest}>
          {children}
        </ColorAnimatedView>
      </MotiPressable>
    );
  }
);
ColorAnimatedPressable.displayName = "ColorAnimatedPressable";

export type {
  ColorAnimatedComponentProps,
  ColorProperty,
  ColorStates,
  OptionalColorStates,
  StateTuple,
};
export {
  ColorAnimatedPressable,
  ColorAnimatedView,
  ColorAnimatedWrapper,
  useAnimatedColor,
};
