import { H3, H5, Row, Sx, Text, useDripsyTheme, View } from "dripsy";
import { gql } from "@apollo/client";
import { Card } from "app/design/card";
import { DataError } from "app/components/data-error";
import { CompletionCheck } from "./completion-check";
import { ProfileCompletionLoading } from "./loading";
import {
  AVATAR_SIZE,
  CONVERTED_AVATAR_SIZE,
  ProfileAvatar,
} from "./profile-avatar";
import { useProfileCompletion } from "./use-profile-completion";

const PROFILE_COMPLETION_QUERY = gql`
  query ProfileCompletion {
    me {
      id
      fullName
      photoUrl
      ... on CandidateV2 {
        profession {
          id
          name
        }
        countryInfo {
          name
        }
        profileCompletion
      }
    }
  }
`;

const ProfileCompletion = ({ sx = {} }: { sx?: Sx }) => {
  const { theme } = useDripsyTheme();
  const { checks, loading, error, percentage, currentUser } =
    useProfileCompletion();

  if (loading)
    return (
      <ProfileCompletionLoading
        avatarSize={AVATAR_SIZE}
        offsetTop={CONVERTED_AVATAR_SIZE / 2}
      />
    );

  if (error || currentUser?.__typename !== "CandidateV2") return <DataError />;

  return (
    <Card
      sx={{
        alignSelf: "center",
        p: "$6",
        alignItems: "center",
        pt: ["$6", null, null, 0],
        ...sx,
      }}
    >
      <ProfileAvatar currentUser={currentUser} percentage={percentage} />

      <View sx={{ mb: "$4", alignItems: "center" }}>
        <H3 sx={{ textAlign: "center" }}>{currentUser.fullName}</H3>
        <Text variant="sm" sx={{ textAlign: "center" }}>
          {currentUser.profession?.name}, {currentUser.countryInfo?.name}
        </Text>
      </View>
      <Row
        sx={{
          mb: "$2",
          mr: 0 - theme.space.$2,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <H5 sx={{ mr: "$2" }}>Profile completion</H5>
        <H5 sx={{ mr: "$2", color: "$blue" }}>{percentage}%</H5>
      </Row>
      {checks.map((check) => (
        <CompletionCheck key={check.label} {...check} />
      ))}
    </Card>
  );
};

export { PROFILE_COMPLETION_QUERY, ProfileCompletion };
