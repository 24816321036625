import { H4, styled, View } from "dripsy";
import { ComponentProps } from "react";
import { ActivityIndicator } from "app/design/activity-indicator";

const BaseLoadingMore = (props: ComponentProps<typeof View>) => (
  <View {...props}>
    <ActivityIndicator />
  </View>
);

const FlatListLoadingMore = styled(BaseLoadingMore)({
  mt: "$4",
  mb: "$10",
  height: 24,
  alignItems: "center",
  justifyContent: "center",
});

const FlatListBottomSpacer = styled(View)({
  mt: "$4",
  height: 24,
  mb: "$10",
  alignItems: "center",
  borderTopWidth: 1,
  borderColor: "$ui",
});

const ListEmptyComponentText = styled(H4)({
  mt: "$4",
  mb: "$2",
});

const FlatListSidebar = styled(View)({
  // On widescreens only, position as a sidebar
  width: [null, null, null, "33.3333%"],
  pl: [null, null, null, "$3"],
  position: ["relative", null, null, "absolute"],
  top: [null, null, null, 0],
  right: [null, null, null, 0],
});

const FlatListItemWithSidebar = styled(View)({
  width: [null, null, null, "66.6666%"],
  pr: [null, null, null, "$3"],
});

export {
  FlatListLoadingMore,
  FlatListBottomSpacer,
  ListEmptyComponentText,
  FlatListItemWithSidebar,
  FlatListSidebar,
};
