import { Match } from "faunadb";
import { useRef } from "react";
import { ApolloCache, gql, useApolloClient } from "@apollo/client";
import { Scalars, useGetMessageQuery } from "app/types/generated/schema";
import {
  MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE,
  useMessageStore,
} from "app/hooks/message/use-send-message";

import { useStreaming } from "app/hooks/use-streaming";
import { asImperativeQuery } from "app/hooks/helpers/imperative-query";
import { useIdParam } from "app/hooks/use-id-param";
import { useToast } from "app/components/use-toast";
import { useCurrentUser, useEffectAfterRender } from "app/hooks";
import { MessageNotification } from "app/features/notifications/notification-card/message/message-notification";

const NEW_MESSAGE_PAYLOAD = gql`
  ${MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE}
  fragment NewMessagePayload on Message {
    ...MessageFieldsNoLocalState
    channel {
      id
      unread
      type
      lastMessage {
        id
        content
        createdAt
        author {
          id
        }
      }
      ... on CommunityPublicChannel {
        name
      }
      ... on ApplicationDMChannel {
        application {
          id
          job {
            id
            organisationName
            organisation {
              id
              name
            }
          }
        }
      }
    }
    isSent
  }
`;

const deleteChannelItemsFromCache = (cache: ApolloCache<{}>) => {
  cache.evict({ id: "ROOT_QUERY", fieldName: "joinedChannels" });
};

const GET_MESSAGE_MUTATION = gql`
  ${NEW_MESSAGE_PAYLOAD}
  query GetMessage($id: MigrateID!) {
    message(id: $id) {
      ...NewMessagePayload
    }
  }
`;

const useSubscribeToNewMessages = () => {
  const [getMessage] = asImperativeQuery(useGetMessageQuery);
  const apolloClient = useApolloClient();
  const { cache } = apolloClient;
  const { messagesInFlight } = useMessageStore();
  const toast = useToast();
  const { currentUser } = useCurrentUser();
  const channelIdParam = useIdParam("channelId");

  const messagesInFlightRef = useRef(messagesInFlight);
  const queue = useRef<string[]>([]);
  const channelId = useRef(channelIdParam);

  const onNewDocument = async (messageId: Scalars["MigrateID"]) => {
    /**
     * if there are messages in flight, we don't want to process streamed messages
     * immediately, because it will result in a cache race condition (both this function
     * and useSendMessage write resuts to the cache). instead, we add them to a queue and
     * they are processed when messagesInFlight becomes 0.
     *
     * n.b. we use messagesInFlightRef here as onNewDocument is a callback used inside an effect
     */
    if (messagesInFlightRef.current > 0) {
      queue.current = [...queue.current, messageId];
      return;
    }

    const cachedId = cache.identify({ id: messageId, __typename: "Message" });
    if (cachedId && cachedId in cache.extract()) return;

    const { data } = await getMessage({
      id: messageId,
    });
    const { message } = data;
    const { channel } = message;
    cache.modify({
      id: `Channel:${channel.id}`,
      fields: {
        unread: () => {
          return true;
        },
      },
    });
    cache.modify({
      fields: {
        messages(existing, { storeFieldName }) {
          if (!storeFieldName.includes(channel.id)) return existing;

          /**
           * if we've jumped to an old page of messages, existing.before will be non-null.
           * in this case, we don't want to add the message to the cache, because it will result
           * in a non-contiguous list of messages [...oldMessages, newMessage, ...moreOldMessages]
           */
          if (existing.before) return existing;

          const newMessageRef = cache.writeFragment({
            data: message,
            fragment: gql`
              fragment NewMessage on Message {
                id
              }
            `,
          });
          return {
            ...existing,
            data: [newMessageRef, ...existing.data],
          };
        },
      },
    });

    const currentUserIsNotAuthor = currentUser?.id !== message.author?.id;

    if (channelId.current !== message.channel.id && currentUserIsNotAuthor) {
      toast.show({
        render: () => (
          <MessageNotification message={message} onClose={toast.close} />
        ),
        duration: null,
      });
    }
  };

  const onReconnect = () => {
    deleteChannelItemsFromCache(cache);
  };

  const flushQueue = async () => {
    await Promise.all(queue.current.map(onNewDocument));
    queue.current = [];
  };

  /**
   * this effect checks the value of messagesInFlight, and processes streamed messages
   * in the queue only when messagesInFlight is 0 to avoid cache race conditions.
   */
  useEffectAfterRender(() => {
    channelId.current = channelIdParam;
    // update the messagesInFlightRef so onNewDocument has an up to date value
    messagesInFlightRef.current = messagesInFlight;
    // process queued messages when there are no messages in flight
    if (messagesInFlight === 0) flushQueue();
  }, [messagesInFlight, channelIdParam]);

  useStreaming(
    {
      setRef: Match("all_messages"),
    },
    onNewDocument,
    onReconnect
  );
};

export { useSubscribeToNewMessages, GET_MESSAGE_MUTATION };
