import { Feather } from "@expo/vector-icons";
import { Row, Text, View } from "dripsy";
import { JobDetailsJobPropsFragment } from "app/types/generated/schema";
import { WebLink } from "app/components/web-link";
import { DividerSecondary } from "./divider";
import { SectionHeader } from "./section";

interface AttachmentsProps {
  attachments: JobDetailsJobPropsFragment["files"];
}

const Attachments = ({ attachments }: AttachmentsProps) => (
  <>
    <DividerSecondary />
    <View sx={{ mt: "$6", alignSelf: "stretch" }}>
      <SectionHeader>Attachments</SectionHeader>
      {attachments?.map(({ id, filename, url }) => (
        <Row sx={{ alignItems: "center" }} key={id}>
          <WebLink url={url} sx={{ textDecorationLine: "none" }}>
            <Feather name="file-text" size={18} />
            <Text sx={{ ml: "$1", textDecorationLine: "underline" }}>
              {filename}
            </Text>
          </WebLink>
        </Row>
      ))}
    </View>
  </>
);

export { Attachments };
