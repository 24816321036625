import { BodyStone } from "app/design/svgs/stones/body";
import { SidebarStones } from "app/design/svgs/stones/sidebar";
import { View } from "dripsy";
import { StyleSheet } from "react-native";

const Stones = () => (
  <View sx={{ ...StyleSheet.absoluteFillObject, overflow: "hidden" }}>
    <View
      sx={{
        position: "absolute",
        left: -10,
        bottom: -63,
        opacity: [0.75, 1],
        display: ["none", "flex"],
      }}
    >
      <SidebarStones />
    </View>

    <View
      sx={{
        position: "absolute",
        left: ["90%", null, null, "85%"],
        bottom: 13,
        opacity: [0.4, null, null, 0.75],
        display: ["none", "flex"],
      }}
    >
      <BodyStone />
    </View>
  </View>
);

export { Stones };
