import { H4, Sx, View, useDripsyTheme } from "dripsy";
import { Feather } from "@expo/vector-icons";

interface NotificationsEmptyProps {
  sx?: Sx;
}

const NotificationsEmpty = ({ sx = {} }: NotificationsEmptyProps) => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        alignItems: "center",
        justifyContent: "center",
        px: "$6",
        minHeight: 180,
        ...sx,
      }}
    >
      <Feather name="bell-off" size={24} color={theme.colors.$grey} />
      <H4 sx={{ color: "$grey" }}>No notifications yet</H4>
    </View>
  );
};

export { NotificationsEmpty };
