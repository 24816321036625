import { gql } from "@apollo/client";
import {
  Scalars,
  useToggleAnswerVoteMutation,
  VoteType,
} from "app/types/generated/schema";

export const TOGGLE_ANSWER_VOTE_MUTATION = gql`
  mutation ToggleAnswerVote($input: ToggleAnswerVoteInput!) {
    toggleAnswerVote(input: $input) {
      answerVote {
        id
      }
      deleted
    }
  }
`;

export const getNewVoteCount = (
  existingCount: number,
  oldVoteType: VoteType | null,
  newVoteType: VoteType,
  targetType: VoteType
) => {
  // add vote case
  if (oldVoteType === null)
    return existingCount + (newVoteType === targetType ? 1 : 0);

  // remove vote case
  if (oldVoteType === newVoteType)
    return existingCount - (targetType === newVoteType ? 1 : 0);

  // switch vote case
  if (oldVoteType !== newVoteType)
    return existingCount + (newVoteType === targetType ? 1 : -1);
};

export const useToggleAnswerVote = (
  answerId: Scalars["MigrateID"],
  voteType: VoteType
) => {
  const [toggleVote] = useToggleAnswerVoteMutation({
    optimisticResponse: {
      __typename: "Mutation",
      toggleAnswerVote: {
        __typename: "ToggleAnswerVotePayload",
        answerVote: {
          id: `answer_votes-${Math.floor(Math.random() * 100)}`,
        },
        deleted: false,
      },
    },
    update(cache) {
      const currentCache = cache.extract();
      const { myVoteType: oldVoteType } = currentCache[`Answer:${answerId}`];

      cache.modify({
        id: `Answer:${answerId}`,
        fields: {
          myVoteType(existing) {
            if (existing === voteType) {
              return null;
            }

            return voteType;
          },
          upvotes(existing) {
            return getNewVoteCount(
              existing,
              oldVoteType,
              voteType,
              VoteType.Upvote
            );
          },
          downvotes(existing) {
            return getNewVoteCount(
              existing,
              oldVoteType,
              voteType,
              VoteType.Downvote
            );
          },
        },
      });
    },
  });

  return toggleVote;
};
