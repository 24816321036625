import { View } from "dripsy";

import { useIdParam } from "app/hooks/use-id-param";
import { Text } from "app/components/text";
import {
  Chat,
  ChatFooterComponent,
  ChatHeaderComponent,
} from "app/features/chat";

interface ScreenWithLayoutProps {
  HeaderComponent?: ChatHeaderComponent;
  FooterComponent?: ChatFooterComponent;
  getLayout?: (page: React.ReactElement) => React.ReactElement;
}

const ChannelScreen = ({
  HeaderComponent,
  FooterComponent,
}: ScreenWithLayoutProps) => {
  const channelId = useIdParam("channelId");

  if (!channelId) {
    return (
      <View sx={{ flexGrow: 1, justifyContent: "center" }}>
        <Text
          sx={{
            color: "$grey",
            fontWeight: "500",
            px: "$4",
            textAlign: "center",
          }}
        >
          Channel not found.
        </Text>
      </View>
    );
  }

  return (
    <Chat
      channelId={channelId}
      HeaderComponent={HeaderComponent}
      FooterComponent={FooterComponent}
    />
  );
};

export { ChannelScreen };
