import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 355;
const originalHeight = 288;
const aspectRatio = originalWidth / originalHeight;

const BodyStone = ({
  width = originalWidth,
  height = originalHeight,
  ...rest
}: SvgProps) => (
  <View style={{ aspectRatio, width, height, opacity: 0.4 }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...rest}
    >
      <Path
        fill="#f38f1a"
        d="M85.09,253.95l-.6-.6C29.7,213.89-7.61,159.66,1.32,107.29,8.7,64.79,60.47,19.25,137.7,4.71c97.6-18.52,169.25,20.48,197.69,66.6,10.99,17.48,17.62,36.82,19.23,56.08,1.6,19.26-1.88,37.77-10.1,53.65-18.98,40.1-88.7,119.98-183.5,105.4-26.66-4.17-52.9-15.4-75.93-32.49Z"
      />
    </Svg>
  </View>
);

export { BodyStone };
