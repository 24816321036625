import { View } from "dripsy";
import React, { cloneElement } from "react";

import { FCC } from "app/types/index";
import { useIdParam } from "app/hooks/use-id-param";
import { Card } from "app/design/card";
import { Screen } from "app/components/screen";
import { Text } from "app/components/text";
import { Layout, LayoutProps } from "app/features/layout";
import { useChatStore } from "app/features/chat/hooks/use-chat-store";
import { conditionalParam } from "app/utils";
import { useScreenSize } from "app/hooks";

const ChannelContainer: FCC<{
  channelList: React.ReactElement;
  channelListHeader?: React.ReactElement;
  channelListFooter?: React.ReactElement;
}> = ({ channelList, children, channelListHeader, channelListFooter }) => {
  const { isMobile, isAtLeastDesktop } = useScreenSize();
  const channelId = useIdParam("channelId");
  const hasSelectedChannel = channelId !== undefined;

  const shouldRenderChannel = hasSelectedChannel;
  const shouldRenderChannelList = isAtLeastDesktop || !hasSelectedChannel;
  const shouldRenderEmptyState = !hasSelectedChannel && isAtLeastDesktop;

  return (
    <Screen sx={{ maxWidth: null }} containerSx={isMobile ? { p: 0 } : {}}>
      <Card
        sx={{
          flex: 1,
          flexDirection: "row",
          mb: 0,
          p: 0,
          ...conditionalParam(
            {
              borderRadius: 0,
              borderWidth: 0,
            },
            isMobile
          ),
        }}
      >
        {shouldRenderChannelList ? (
          <View
            sx={{
              width: ["100%", null, 350],
              borderRightWidth: [null, null, 1],
              borderColor: "$ui",
              height: "100%",
            }}
          >
            {channelListHeader}
            {cloneElement(channelList, { selectedChannelId: channelId })}
            {channelListFooter}
          </View>
        ) : null}

        {shouldRenderChannel ? <View sx={{ flex: 1 }}>{children}</View> : null}

        {shouldRenderEmptyState ? (
          <View sx={{ flex: 1, justifyContent: "center" }}>
            <Text
              sx={{
                color: "$grey",
                fontWeight: "500",
                px: "$4",
                textAlign: "center",
              }}
            >
              No conversation selected
            </Text>
          </View>
        ) : null}
      </Card>
    </Screen>
  );
};

interface ChannelLayoutProps extends LayoutProps {}

const ChannelLayout: FCC<ChannelLayoutProps> = ({
  headerProps,
  children,
  ...rest
}) => {
  const { chatName } = useChatStore();
  return (
    <Layout
      headerProps={{
        alwaysShowHeader: true,
        ...headerProps,
        title: chatName || headerProps.title,
      }}
      overridePathname="inbox"
      {...rest}
    >
      {children}
    </Layout>
  );
};

export { ChannelLayout, ChannelContainer };
