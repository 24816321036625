import { createParam } from "solito";

interface UseParamConfig {
  initial: string | undefined;
  parse?: (value?: string | string[] | undefined) => any;
}

/**
 * workaround for initial value not working on native,
 * i think we're using this field incorrectly but also
 * this api is useful clearly...
 * cf. https://github.com/nandorojo/solito/issues/172
 */
export const useParam = <T extends {}>(
  param: keyof T,
  config: UseParamConfig = { initial: undefined, parse: (value) => value }
) => {
  const { useParam: useSolitoParam } = createParam<T>();
  const [value, setValue] = useSolitoParam(param, config);
  return [value || config.initial, setValue] as const;
};
