import { useEffect, useMemo, useRef, useState } from "react";
import { Feather } from "@expo/vector-icons";
import countries from "i18n-iso-countries";
import { View, Pressable, useDripsyTheme, styled } from "dripsy";
import { FlashList } from "@shopify/flash-list";
import { CountryCode } from "react-native-country-picker-modal";
import { Input } from "app/design/input";
import { Text } from "../text";

const MenuItem = styled(Pressable)({
  p: "$3",
});

const SearchIcon = () => (
  <View
    sx={{
      bg: "$uiAlpha",
      height: "100%",
      justifyContent: "center",
    }}
  >
    <Feather
      name="search"
      size={16}
      style={{ paddingLeft: 8, paddingRight: 4 }}
    />
  </View>
);

const ESTIMATED_FLASH_LIST_ITEM_SIZE = 44;

export const SearchableCountryList = ({
  onChange,
  searchTermDeps,
}: {
  onChange: (country: CountryCode) => void;
  searchTermDeps: any[];
}) => {
  const { theme } = useDripsyTheme();
  const [searchTerm, setSearchTerm] = useState("");

  const countryObj = countries.getNames("en", { select: "official" }) as {
    [code: string]: string;
  };

  const options = useMemo(
    () =>
      Object.entries(countryObj)
        .filter(([, _value]) =>
          _value.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map(([key, _value]) => {
          return {
            label: _value,
            value: key,
          };
        }),
    [searchTerm]
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setSearchTerm("");
  }, searchTermDeps);

  return (
    <>
      <Input
        ref={inputRef}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        sx={{
          mb: "$0",
          bg: "$uiAlpha",
          px: "$1",
          py: "$2",
        }}
        containerSx={{ mb: "$0", p: "$2", borderWidth: 0 }}
        value={searchTerm}
        onChangeText={setSearchTerm}
        InputLeftElement={<SearchIcon />}
      />
      <FlashList
        estimatedItemSize={ESTIMATED_FLASH_LIST_ITEM_SIZE}
        data={options}
        renderItem={({ item }) => (
          <MenuItem
            key={item.value}
            onPress={() => onChange(item.value as CountryCode)}
            style={({ hovered }) => ({
              backgroundColor: hovered
                ? theme.colors.$cream
                : theme.colors.$white,
            })}
          >
            <Text>{item.label}</Text>
          </MenuItem>
        )}
      />
    </>
  );
};
