import { brandColors } from "./colors";

const shadows = {
  default: {
    shadowColor: brandColors.black,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.05,
    shadowRadius: 12,
  },
  defaultUpwards: {
    shadowColor: brandColors.black,
    shadowOffset: {
      width: 0,
      height: -5,
    },
    shadowOpacity: 0.05,
    shadowRadius: 12,
  },
  md: {
    shadowColor: brandColors.black,
    shadowOffset: {
      width: 0,
      height: 18,
    },
    shadowOpacity: 0.1,
    shadowRadius: 35,
  },
  highlight: {
    shadowColor: brandColors.blue,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 5,
  },
  // Annoying quirk of dripsy means we need to do this on components that
  // have a default variant with a box shadow
  none: {
    shadowColor: "transparent",
  },
};

export { shadows };
