import React from "react";
import { MotiView, useAnimationState } from "moti";
import { useDripsyTheme, useSx } from "dripsy";
import { Platform, StyleProp, Text } from "react-native";
import {
  ColorAnimatedIcon,
  ColorAnimatedIconProps,
} from "app/components/color-animated-icon";
import { SKIP_ANIMATION_ON_RENDER } from "app/utils/animation";
import { useEffectAfterRender } from "app/hooks";

interface IconProps {
  size?: number;
  color?: string;
  style?: StyleProp<Text>;
}
interface ToggleIconProps {
  iconProps?: IconProps;
  checked: boolean;
  CheckedIcon: React.ReactElement<IconProps>;
  UncheckedIcon: React.ReactElement<IconProps>;
  colorStates: ColorAnimatedIconProps["colorStates"];
}
export const ToggleIcon = ({
  checked,
  iconProps,
  CheckedIcon,
  UncheckedIcon,
  colorStates,
}: ToggleIconProps) => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const animationState = useAnimationState(
    {
      initial: {
        scale: 1,
      },
      checked: {
        scale: [1.4, 1],
      },
      unchecked: {
        scale: 1,
      },
    },
    SKIP_ANIMATION_ON_RENDER
  );

  useEffectAfterRender(() => {
    if (checked) {
      animationState.transitionTo("checked");
    } else {
      animationState.transitionTo("unchecked");
    }
  }, [checked]);

  const styledIconProps = {
    ...iconProps,
    style: sx({
      position: "relative",
      top: Platform.select({ web: 1, ios: 3, android: 1 }),
    }),
  };

  return (
    <MotiView
      state={animationState}
      transition={{
        type: "timing",
        duration: theme.transitionDurations.normal,
      }}
    >
      <ColorAnimatedIcon
        colorStates={colorStates}
        icon={
          checked
            ? React.cloneElement(CheckedIcon, { ...styledIconProps })
            : React.cloneElement(UncheckedIcon, { ...styledIconProps })
        }
      />
    </MotiView>
  );
};
