import { styled, useDripsyTheme, View } from "dripsy";
import { memo } from "react";
import isEqual from "react-fast-compare";
import { format } from "date-fns";

import { alphaColor } from "app/design/utils";
import { Text } from "app/components/text";
import { Username, UsernameProps } from "app/components/username";
import { Maybe } from "app/types";

import { ChannelMessage } from "../../types";

interface MessageAuthorProps {
  author?: UsernameProps["user"];
  colorConfig?: UsernameProps["colorConfig"];
}

const MessageAuthor = ({ author, colorConfig }: MessageAuthorProps) => {
  return (
    <Username
      sx={{
        fontWeight: "600",
        mr: "$2",
      }}
      user={author}
      colorConfig={colorConfig}
    />
  );
};

interface MessageHeaderDateProps {
  isoDate: string;
  color: string;
}

const MessageHeaderDate = ({
  isoDate,
  color: _color,
}: MessageHeaderDateProps) => {
  const { theme } = useDripsyTheme();

  const color: string = Object.keys(theme.colors).includes(_color)
    ? theme.colors[_color as keyof typeof theme.colors]
    : _color;

  return (
    <Text
      sx={{
        fontSize: 14,
        color: alphaColor(color, 0.5),
      }}
    >
      {format(new Date(isoDate), "h:mm aa")}
    </Text>
  );
};

const MessageHeaderContainer = styled(View)({
  flexDirection: "row",
  alignItems: "baseline",
});

interface MessageHeaderProps {
  message: {
    author?: Maybe<{
      __typename?: NonNullable<ChannelMessage["author"]>["__typename"];
      displayName?: string;
    }>;
    createdAt: string;
  };
  color: string;
}

const MessageHeader = memo(({ message, color }: MessageHeaderProps) => {
  return (
    <MessageHeaderContainer>
      <MessageAuthor author={message?.author} />
      <MessageHeaderDate isoDate={message.createdAt} color={color} />
    </MessageHeaderContainer>
  );
}, isEqual);

MessageHeader.displayName = "MessageHeader";

export type { MessageAuthorProps, MessageHeaderDateProps, MessageHeaderProps };

export {
  MessageAuthor,
  MessageHeaderContainer,
  MessageHeaderDate,
  MessageHeader,
};
