import { useRef, useState } from "react";
import { FlatList as RNFlatList } from "react-native";
import { useDripsyTheme, useSx, styled, View } from "dripsy";
import { AnimatePresence, MotiView } from "moti";
import { MotiPressable } from "moti/interactions";

import { ColorAnimatedView } from "app/components/color-animated-view";
import { FlatList } from "app/components/flat-list";
import { SCREEN_PADDING_MOBILE } from "app/provider/dripsy/components/layout";
import { guarantee } from "app/types";

const DripsyMotiView = styled(MotiView)();

interface TabButtonProps {
  title: string;
  active: boolean;
  onPress: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ title, active, onPress }) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  return (
    <MotiPressable
      onPress={onPress}
      containerStyle={sx({
        mr: "$5",
      })}
    >
      <ColorAnimatedView
        sx={{
          pb: "$2",
          borderBottomWidth: 2,
          mb: -1,
        }}
        colorStates={{
          borderColor: {
            states: [
              ["hovered", theme.colors.$blue],
              ["pressed", theme.colors.$blue],
              [active, theme.colors.$black],
            ],
            initialColor: "transparent",
          },
        }}
      >
        <ColorAnimatedView
          as="Text"
          sx={{
            ...theme.h4,
            fontWeight: active ? "600" : "normal",
          }}
          colorStates={{
            color: {
              states: [
                ["pressed", theme.colors.$blue],
                ["hovered", theme.colors.$blue],
              ],
              initialColor: theme.colors.$black,
            },
          }}
        >
          {title}
        </ColorAnimatedView>
      </ColorAnimatedView>
    </MotiPressable>
  );
};

interface TabType {
  id: string;
  title: string;
  content: React.ReactElement;
}

interface TabsProps {
  tabs: TabType[];
  initialActiveTabIndex?: number;
}

const HorizontalTabs = ({
  tabs,
  activeTabIndex,
  setActiveTabIndex,
}: {
  tabs: TabType[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}) => {
  const flatlistRef = useRef<RNFlatList>(null);

  return (
    <FlatList
      ref={flatlistRef}
      horizontal
      data={tabs}
      keyExtractor={(item) => item.id}
      containerSx={{
        minWidth: "100%",
        borderBottomWidth: 1,
        borderBottomColor: "$ui",
      }}
      renderItem={({ item, index }) => (
        <TabButton
          title={item.title}
          active={index === activeTabIndex}
          onPress={() => {
            flatlistRef.current?.scrollToIndex({ index, viewPosition: 0.5 });
            setActiveTabIndex(index);
          }}
        />
      )}
      showsHorizontalScrollIndicator={false}
      sx={{
        flexShrink: 1,
        flexGrow: 0,
        mb: "$4",
        mx: 0 - SCREEN_PADDING_MOBILE,
        px: SCREEN_PADDING_MOBILE,
      }}
    />
  );
};

const Tabs: React.FC<TabsProps> = ({ tabs, initialActiveTabIndex = 0 }) => {
  if (tabs.length === 0) throw Error("Tabs must have at least one tab");
  if (initialActiveTabIndex >= tabs.length || initialActiveTabIndex < 0) {
    throw Error(
      `initialActiveTabIndex must be between 0 and ${tabs.length - 1}`
    );
  }
  const { theme } = useDripsyTheme();
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex);

  const activeTab = guarantee(
    tabs[activeTabIndex],
    "Tab index asserted from tabs children - Tab index out of bounds"
  );

  return (
    <>
      <HorizontalTabs
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <AnimatePresence initial={false} exitBeforeEnter>
        <DripsyMotiView
          key={activeTab.id}
          from={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            type: "timing",
            duration: theme.transitionDurations.normal,
          }}
        >
          {activeTab.content}
        </DripsyMotiView>
      </AnimatePresence>
    </>
  );
};

export { Tabs };
