const iconButton = {
  sizes: {
    xs: {
      width: 28,
      height: 28,
    },
    sm: {
      width: 32,
      height: 32,
    },
    md: {
      width: 40,
      height: 40,
    },
    lg: {
      width: 48,
      height: 48,
    },
  },
};

export { iconButton };
