import { Row, styled, Sx, SxProp, useSx, View } from "dripsy";
import { Children, cloneElement, ReactElement } from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FCC } from "app/types";
import { Text } from "./text";

interface ListProps {
  children: ReactElement | ReactElement[];
  itemSx?: Sx;
  numbered?: boolean;
  sx?: Sx;
  Icon?: React.ReactNode;
  flush?: boolean;
}

const List = ({
  children,
  itemSx = {},
  numbered = false,
  Icon,
  flush,
  ...rest
}: ListProps) => {
  return (
    <View {...rest}>
      {Children.map(children, (child, i) =>
        cloneElement(child, {
          sx: {
            mt: i !== 0 ? "$2" : 0,
            ...itemSx,
            ...child.props.sx,
          },
          numbered,
          count: i + 1,
          Icon,
          iconSx: {
            ml: flush ? 0 : child.props.sx?.ml,
          },
        })
      )}
    </View>
  );
};

const Bullet = styled(View)({
  // Set individual x margin so can be overriden by 'flush'
  ml: "$3",
  mr: "$3",
  my: 9,
  width: 5,
  height: 5,
  flexShrink: 0,
  borderRadius: 5,
  backgroundColor: "$black",
});

interface TickProps {
  sx?: SxProp;
}

const Tick = ({ sx: _sx }: TickProps) => {
  const sx = useSx();
  return (
    <MaterialCommunityIcons
      style={sx({
        my: 4,
        // Set individual x margin so can be overriden by 'flush'
        ml: "$2",
        mr: "$2",
        color: "$blue",
        ..._sx,
      })}
      size={14}
      name="check"
    />
  );
};

const Counter: FCC = ({ children }) => (
  <Text
    sx={{
      width: 21,
      flexShrink: 0,
      mr: "$2",
      fontWeight: "500",
      textAlign: "right",
    }}
  >
    {children}
  </Text>
);

interface ListItemProps {
  Icon?: React.ReactElement;
  sx?: Sx;
  textSx?: Sx;
  iconSx?: Sx;
  numbered?: boolean;
  count?: number;
}

const ListItem: FCC<ListItemProps> = ({
  children,
  sx = {},
  textSx = {},
  iconSx = {},
  numbered,
  count,
  Icon = <Bullet />,
}) => {
  return (
    <Row sx={{ alignItems: "flex-start", ...sx }}>
      {numbered ? (
        <Counter>{count}.</Counter>
      ) : (
        cloneElement(Icon, {
          sx: iconSx,
        })
      )}
      <Text sx={{ flexShrink: 1, ...textSx }}>{children}</Text>
    </Row>
  );
};

List.Item = ListItem;

export { List, ListItem, Tick, Bullet };
