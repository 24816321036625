import Svg, {
  ClipPath,
  Defs,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from "react-native-svg";
import Animated, {
  withRepeat,
  withTiming,
  useAnimatedProps,
  useSharedValue,
} from "react-native-reanimated";
import { View } from "dripsy";
import { useEffect } from "react";
import { Platform } from "react-native";
import { Background } from "app/features/layout/background";
import { brandColors } from "app/provider/dripsy/colors";

// Expo compatible react-native-svg *can* have children
declare module "react-native-svg" {
  interface LinearGradientProps {
    children: React.ReactNode;
  }

  interface ClipPathProps {
    children: React.ReactNode;
  }
}

const AnimatedRect = Animated.createAnimatedComponent(Rect);

const LOGO_WIDTH = 32;
const LOGO_HEIGHT = 24;

const LogoLoader = () => {
  const position = useSharedValue(0);
  const animatedProps = useAnimatedProps(() => {
    return {
      x: 0 - LOGO_WIDTH + position.value * (LOGO_WIDTH * 3),
    };
  });

  useEffect(() => {
    position.value = withRepeat(withTiming(1, { duration: 1500 }), -1);
  }, []);

  const sharedRectProps = {
    width: LOGO_WIDTH,
    height: LOGO_HEIGHT,
    clipPath: "url(#loader-logo)",
  };

  return (
    <Svg viewBox={`0 0 ${LOGO_WIDTH} ${LOGO_HEIGHT}`} width={120} height={90}>
      <Defs>
        <ClipPath id="loader-logo">
          <Path
            d="M14.6504 3.72119C9.92975 3.72119 7.17773 5.20657 7.17773 6.65274C7.17773 8.13302 9.69575 9.644 15.8696 9.644C21.2091 9.644 25.0904 8.85441 25.0904 7.41165C25.0904 5.62782 19.9933 3.72119 14.6504 3.72119Z"
            fill="#FFB5A4"
          />
          <Path
            d="M18.4281 10.9556C7.47096 10.9556 0 14.6784 0 18.0449C0 22.1156 9.18694 23.8432 17.4175 23.8432C25.9212 23.8432 32 22.2487 32 18.3996C31.9932 15.306 27.8135 10.9556 18.4281 10.9556Z"
            fill="#305ED9"
          />
          <Path
            d="M16.3172 0C18.5012 0 21.0649 0.530417 21.0649 1.50935C21.0649 2.16084 19.9187 2.75945 17.4244 2.75945C13.5245 2.75945 11.0488 2.18471 11.0488 1.51787C11.0421 0.533815 14.1145 0 16.3172 0Z"
            fill="#FF9200"
          />
        </ClipPath>
        <LinearGradient id="strip">
          <Stop offset="0%" stopColor={brandColors.cream} stopOpacity={0} />
          <Stop offset="50%" stopColor={brandColors.cream} stopOpacity={0.2} />
          <Stop offset="100%" stopColor={brandColors.cream} stopOpacity={0} />
        </LinearGradient>
      </Defs>
      <Rect {...sharedRectProps} fill={brandColors.black} />
      <AnimatedRect
        {...sharedRectProps}
        fill="url(#strip)"
        animatedProps={animatedProps}
        // Hack to fix Reanimated SVG bug
        onPress={() => {}}
        {...(Platform.OS === "web" && { tabIndex: "-1" })}
      />
    </Svg>
  );
};

const AppLoader = () => (
  <Background>
    <View sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <LogoLoader />
    </View>
  </Background>
);

export { AppLoader };
