import {
  FlagButtonProps,
  useFlagButton,
} from "app/components/form/country-control";
import { ToggleButton } from "app/components/form/toggle-button";

export const ToggleFlagButton = ({ countryCode, onOpen }: FlagButtonProps) => {
  const countryName = useFlagButton({ countryCode });

  return (
    <ToggleButton onChange={onOpen} checked={!!countryCode}>
      {countryName ? countryName : "Select country"}
    </ToggleButton>
  );
};
