import { MutableRefObject, useEffect, useCallback, useRef, memo } from "react";
import { ListRenderItem, ScrollView } from "react-native";
import { useModal } from "react-native-modalfy";
import { H2, H4, Row, Text, useDripsyTheme, View } from "dripsy";
import create from "zustand";
import { gql } from "@apollo/client";
import { Feather } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "native-base";
import {
  AnswerFragmentFragment,
  useQuestionQuery,
  QuestionQueryResult,
  QuestionQuery,
  Scalars,
} from "app/types/generated/schema";
import { useAvailableWidth } from "app/hooks/use-available-width";
import { useIdParam } from "app/hooks/use-id-param";
import { Badge } from "app/design/badge";
import { Card } from "app/design/card";
import { Button } from "app/design/button";
import { IconButton } from "app/design/icon-button";
import { FloatyButton } from "app/components/floaty-button";
import { QueryInfiniteScrollFlatListScreen } from "app/components/screen/query-infinite-scroll-flat-list-screen";
import { FlatListItemWithSidebar } from "app/components/flat-list.common";
import { DisableButtonWithTooltip } from "app/components/disable-button-with-tooltip";

import { Layout } from "app/features/layout";
import { pagesPath } from "app/lib/$path";
import { useCurrentUser, useScreenSize } from "app/hooks";
import { SCREEN_PADDING_DESKTOP } from "app/provider/dripsy/components/layout";

import { getQueryLoadingStatus } from "app/utils";
import { ScreenComponent } from "app/navigation/types";
import { memoizeScreen } from "app/utils/screen";
import { FCC } from "app/types";
import { ProtectCommunityFeature } from "../protect-feature";
import { Answer } from "./answer";
import { QuestionScreenLoading } from "./question-screen.loading";
import { Author } from "./card";
import { AnswerCount, Date } from "./question-metadata";
import { useDeleteQuestion } from "./hooks/use-delete";
import { RelatedQuestions } from "./related-questions";

const QUESTION_QUERY = gql`
  query Question(
    $id: MigrateID!
    $answersOrderBy: AnswersOrderBy
    $after: String
  ) {
    question(id: $id) {
      id
      title
      topics {
        id
        name
      }
      author {
        id
        forenames
        photoUrl
        isActive
      }
      createdAt
      myAnswer {
        id
      }
      answers(orderBy: $answersOrderBy, _cursor: $after) {
        count
        data {
          ...AnswerFragment
        }
        after
      }
    }
  }
  ${Answer.fragments.answer}
`;

interface QuestionScreenStore {
  listRef?: MutableRefObject<ScrollView | undefined>;
  setListRef: (listRef: MutableRefObject<ScrollView | undefined>) => void;
}
const useQuestionScreenStore = create<QuestionScreenStore>(
  (set: (values: Partial<QuestionScreenStore>) => void) => ({
    listRef: undefined,
    setListRef: (listRef) => set({ listRef }),
  })
);

interface ListHeaderComponentProps {
  data: NonNullable<QuestionQueryResult["data"]>;
}

const ListHeaderComponent = ({ data }: ListHeaderComponentProps) => {
  const deleteQuestion = useDeleteQuestion(data.question.id);
  const { currentUser } = useCurrentUser();

  if (!data) return null;

  const {
    question: { title, topics, author, createdAt, answers },
  } = data;

  return (
    <>
      {/* TODO: Add answer sorting/filters? Browse related/trending questions cards? */}
      <FlatListItemWithSidebar>
        <Row
          sx={{
            mb: "$4",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Row sx={{ alignItems: "center" }}>
            {topics.map((topic) => (
              <Badge key={topic.id}>{topic.name}</Badge>
            ))}
          </Row>
          {currentUser?.id === author.id && (
            <Tooltip label="Delete">
              <View>
                <IconButton
                  icon={<Feather name="trash" size={20} />}
                  onPress={deleteQuestion}
                  accessibilityLabel="Delete question"
                  size="sm"
                  variant="ghost"
                />
              </View>
            </Tooltip>
          )}
        </Row>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Author author={author} />
        </Row>
        <H2 sx={{ mb: "$4" }}>{title}</H2>
        <Row
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            mb: ["$5", "$6"],
          }}
        >
          <Date createdAt={createdAt} />
          <AnswerCount count={answers.count} />
        </Row>
      </FlatListItemWithSidebar>
    </>
  );
};

const ListEmptyComponent = () => (
  <FlatListItemWithSidebar>
    <Card>
      <H4 sx={{ mb: "$2" }}>This question has no answers</H4>
      <Text>Add your answer to help the Migrate community!</Text>
    </Card>
  </FlatListItemWithSidebar>
);

interface AnswerQuestionButtonWrapperProps {
  alreadyAnswered: boolean;
  children: React.ReactElement;
}

const AnswerQuestionButtonWrapper = ({
  alreadyAnswered,
  children,
}: AnswerQuestionButtonWrapperProps) => {
  if (alreadyAnswered) {
    return (
      <DisableButtonWithTooltip
        when={true}
        tooltip="You've already answered this question!"
      >
        {children}
      </DisableButtonWithTooltip>
    );
  }

  return (
    <ProtectCommunityFeature.AnswerQuestion>
      {children}
    </ProtectCommunityFeature.AnswerQuestion>
  );
};

interface QuestionSidebarProps {
  question?: QuestionQuery["question"];
  questionId: Scalars["MigrateID"];
}

const QuestionSidebar = ({ question, questionId }: QuestionSidebarProps) => {
  const { isWide } = useScreenSize();
  const { openModal } = useModal();
  const { t } = useTranslation("copy", { keyPrefix: "community.question" });
  const { theme } = useDripsyTheme();
  const availableWidth = useAvailableWidth();

  if (!question) return null;

  return isWide ? (
    <View
      sx={{
        position: "absolute",
        right: Math.max(
          theme.space.$6,
          availableWidth / 2 - theme.layout.screen.body.maxWidth / 2
        ),
        top: "$6",
        width: Math.min(
          (availableWidth - SCREEN_PADDING_DESKTOP * 2) / 3,
          theme.layout.screen.body.maxWidth / 3
        ),
        pl: "$3",
      }}
    >
      <RelatedQuestions
        topics={question.topics}
        currentQuestionId={questionId}
      />
      <AnswerQuestionButtonWrapper alreadyAnswered={!!question.myAnswer}>
        <Button
          icon={<Feather name="message-circle" size={24} />}
          containerSx={{ mb: "$2" }}
          onPress={() => {
            openModal("AnswerQuestionModal", { questionId });
          }}
        >
          {t("answer")}
        </Button>
      </AnswerQuestionButtonWrapper>
      <ProtectCommunityFeature.AskQuestion>
        <Button
          variant="link"
          containerSx={{ alignSelf: "center" }}
          onPress={() => openModal("AskQuestionModal", undefined)}
        >
          {t("ask")}
        </Button>
      </ProtectCommunityFeature.AskQuestion>
    </View>
  ) : (
    <AnswerQuestionButtonWrapper alreadyAnswered={!!question.myAnswer}>
      <FloatyButton
        icon={<Feather name="message-circle" size={24} />}
        onPress={() => {
          openModal("AnswerQuestionModal", { questionId });
        }}
      >
        {t("answer")}
      </FloatyButton>
    </AnswerQuestionButtonWrapper>
  );
};

const CommunityQuestions = ({ questionId }: { questionId: string }) => {
  const { setListRef } = useQuestionScreenStore();
  const listRef = useRef<ScrollView>();

  useEffect(() => {
    setListRef(listRef);
  }, []);

  const queryResponse = useQuestionQuery({
    variables: { id: questionId },
    notifyOnNetworkStatusChange: true,
  });

  const { loading } = getQueryLoadingStatus(queryResponse.networkStatus);

  const renderListHeaderComponent = useCallback(
    ({ data }: { data?: QuestionQueryResult["data"] }) => {
      if (!data) return null;
      return <ListHeaderComponent data={data} />;
    },
    [questionId]
  );

  const renderItem: ListRenderItem<AnswerFragmentFragment> = useCallback(
    ({ item: answer }) => (
      <FlatListItemWithSidebar>
        <Answer
          sx={{
            mb: "$4",
          }}
          key={answer.id}
          answerId={answer.id}
          showDate
          showDelete
        />
      </FlatListItemWithSidebar>
    ),
    []
  );

  if (loading) return <QuestionScreenLoading />;

  const question = queryResponse.data?.question;
  const { data, after } = question?.answers || {};

  return (
    <>
      <QueryInfiniteScrollFlatListScreen
        queryHookResult={queryResponse}
        data={data}
        after={after}
        keyExtractor={(answer) => answer.id}
        renderItem={renderItem}
        renderLoaderComponent={() => <QuestionScreenLoading />}
        renderListHeaderComponent={renderListHeaderComponent}
        renderListEmptyComponent={ListEmptyComponent}
      />

      {!loading ? (
        <QuestionSidebar question={question} questionId={questionId} />
      ) : null}
    </>
  );
};

const CommunityQuestionScreen: ScreenComponent = memoizeScreen(() => {
  const questionId = useIdParam("questionId");
  if (!questionId) return null;
  return <CommunityQuestions questionId={questionId} />;
});

CommunityQuestionScreen.displayName = "CommunityQuestionsScreen";
CommunityQuestionScreen.getLayout = (page: React.ReactElement) => {
  return (
    <Layout
      headerProps={{
        backButtonType: "back",
        backHref: pagesPath.community.questions.$url(),
        title: "Questions",
        showTitleOnDesktop: true,
      }}
    >
      {page}
    </Layout>
  );
};

export { CommunityQuestionScreen, useQuestionScreenStore, QUESTION_QUERY };
