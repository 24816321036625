import { Scalars } from "app/types/generated/schema";

export const getNumericId = (migrateId: Scalars["MigrateID"]): string => {
  const splitId = migrateId.split("-");
  if (!splitId[1]) throw new Error("Invalid MigrateId");

  return splitId[1];
};

export const serializeRef = (ref: any) => {
  const { id, collection: collectionRef } = ref.value;
  const { id: collection } = collectionRef.value;
  return `${collection}-${id}`;
};
