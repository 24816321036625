import { H5, Row, styled, View } from "dripsy";
import { Skeleton } from "native-base";
import { useModal } from "react-native-modalfy";
import { Feather } from "@expo/vector-icons";
import { Button } from "app/design/button";
import { FlatListSidebar } from "app/components/flat-list.common";
import { useScreenSize } from "app/hooks";
import { FCC } from "app/types";
import { Card } from "../card";
import { ProtectCommunityFeature } from "../../protect-feature";
import { SelectedTopic } from "./selected-topic";
import { SortFilter, SortFilterProps } from "./sort-filter";
import { TopicsFilter, TopicsFilterProps } from "./topics-filter";

interface QuestionsFilterProps extends SortFilterProps, TopicsFilterProps {}

const FilterHeader = styled(H5)({
  textAlign: "center",
  alignSelf: "center",
  mb: "$4",
  display: ["none", null, null, "flex"],
});

const FilterContainer = styled(View)({
  flexDirection: ["row", null, null, "column"],
  justifyContent: ["space-between", null, null, "flex-start"],
});

const QuestionFilterLoading = () => {
  const { isWide } = useScreenSize();
  return (
    <Skeleton rounded={4} h={9} w={isWide ? "100%" : 24} mt={isWide ? 4 : 0} />
  );
};

const QuestionFiltersWrapper: FCC = ({ children }) => {
  const { isWide } = useScreenSize();
  const { openModal } = useModal();

  return (
    <FlatListSidebar>
      <Card variant="filters" sx={{ mb: "$4" }}>
        {children}
      </Card>
      {isWide ? (
        <ProtectCommunityFeature.AskQuestion>
          <Button
            icon={<Feather name="edit-3" size={24} />}
            onPress={() => {
              openModal("AskQuestionModal", undefined);
            }}
          >
            Ask a question
          </Button>
        </ProtectCommunityFeature.AskQuestion>
      ) : null}
    </FlatListSidebar>
  );
};

export const QuestionFiltersLoading = () => (
  <QuestionFiltersWrapper>
    <FilterHeader sx={{ mb: 0 }}>Sort/filter questions</FilterHeader>
    <FilterContainer>
      <QuestionFilterLoading />
      <QuestionFilterLoading />
    </FilterContainer>
  </QuestionFiltersWrapper>
);

export const QuestionsFilters = ({
  orderBy,
  setOrderBy,
  topicIds,
  setTopicIds,
}: QuestionsFilterProps) => {
  const selectedTopicsGap = 4;

  return (
    <QuestionFiltersWrapper>
      <FilterHeader>Sort/filter questions</FilterHeader>
      <FilterContainer>
        <SortFilter orderBy={orderBy} setOrderBy={setOrderBy} />
        <TopicsFilter topicIds={topicIds} setTopicIds={setTopicIds} />
      </FilterContainer>
      {topicIds.length > 0 ? (
        <Row
          sx={{
            flexWrap: "wrap",
            mt: -selectedTopicsGap,
            ml: -selectedTopicsGap,
            pt: "$4",
          }}
        >
          {topicIds.map((topicId) => (
            <SelectedTopic
              key={topicId}
              sx={{
                mt: selectedTopicsGap,
                ml: selectedTopicsGap,
              }}
              id={topicId}
              onDeselect={() =>
                setTopicIds(topicIds.filter((id) => id !== topicId))
              }
            />
          ))}
        </Row>
      ) : null}
    </QuestionFiltersWrapper>
  );
};
