import { gql } from "@apollo/client";
import { useLanguageRequirementsLinkQuery } from "app/types/generated/schema";
import { Text, TextProps } from "app/components/text";
import { WebLink } from "app/components/web-link";

export const LANGUAGE_REQUIREMENTS_LINK_QUERY = gql`
  query LanguageRequirementsLink {
    me {
      id
      ... on CandidateV2 {
        profession {
          id
          name
        }
      }
    }
  }
`;

interface LanguageRequirementsLinkProps extends TextProps {}

export const LanguageRequirementsLink = ({
  sx = {},
  children,
}: LanguageRequirementsLinkProps) => {
  const { data, loading, error } = useLanguageRequirementsLinkQuery();

  if (
    loading ||
    error ||
    !data ||
    data.me.__typename !== "CandidateV2" ||
    !data.me.profession
  )
    return <Text sx={sx}>{children}</Text>;

  const link = (() => {
    const name = data.me.profession.name;

    if (name === "Nurse")
      return "https://www.nmc.org.uk/registration/joining-the-register/english-language-requirements/accepted-tests/";
    if (name === "Doctor")
      return "https://www.gmc-uk.org/registration-and-licensing/join-the-register/before-you-apply/evidence-of-your-knowledge-of-english";
    if (name === "Social Worker")
      return "https://www.socialworkengland.org.uk/registration/apply-to-register-eea-swiss-and-overseas/knowledge-of-english/";
    return "https://www.hcpc-uk.org/registration/getting-on-the-register/international-applications/documents/certificate-of-english-language-proficiency/";
  })();

  return (
    <WebLink sx={sx} url={link}>
      {children}
    </WebLink>
  );
};
