import { ComponentProps } from "react";
import { styled, SxProp } from "dripsy";
import { MotiView } from "moti";
import { Link } from "solito/link";

import { MigrateHorizontalLogo } from "app/design/svgs/migrate-horizontal-logo";
import { pagesPath } from "app/lib/$path";

const DripsyMotiView = styled(MotiView)();

const LOGO_DIMENSIONS = {
  width: 170,
  height: 32,
};

type MotiViewProps = ComponentProps<typeof MotiView>;

interface WelcomeLogoProps extends MotiViewProps {
  sx?: SxProp;
}

export const WelcomeLogo: React.FC<WelcomeLogoProps> = ({
  sx: _sx = {},
  ...rest
}) => (
  <DripsyMotiView
    sx={{
      width: LOGO_DIMENSIONS.width,
      height: LOGO_DIMENSIONS.height,
      ..._sx,
    }}
    {...rest}
  >
    <Link href={pagesPath.welcome.$url()}>
      <MigrateHorizontalLogo />
    </Link>
  </DripsyMotiView>
);
