import { memo } from "react";
import { FieldValues, Path, UseFormSetValue } from "react-hook-form";

import {
  Scalars,
  useOnboardingProfessionsQuery,
} from "app/types/generated/schema";
import { ActivityMessage } from "app/design/activity-indicator";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";

import { StepHeading } from "./heading";
import { StepToggle, StepToggleButtonGroup } from "./step-toggle";

interface JobTypesStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  professionId?: Scalars["MigrateID"];
}

interface StepFormProps<TFieldValues extends FieldValues>
  extends Omit<
    JobTypesStepProps<TFieldValues>,
    "professionId" | "stepPath" | "setValue"
  > {
  loading?: boolean;
  jobTypes: { id: string; name: string }[];
}

const StepForm = <TFieldValues extends FieldValues>({
  control,
  jobTypes,
  loading,
  name,
}: StepFormProps<TFieldValues>) => {
  return (
    <>
      <StepHeading>What type of jobs are you interested in?</StepHeading>
      {loading ? (
        <ActivityMessage>Job types loading</ActivityMessage>
      ) : (
        <StepToggleButtonGroup
          toggleButtonPropsField="toggleButtonProps"
          sx={{
            maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
          }}
          control={control}
          name={name}
        >
          {/* TODO: Poss handle empty jobTypes case (when profession === undefined for ineligible),
              but should be unreachable unless via direct navigation */}
          {jobTypes.map(({ name: tagName, id }) => (
            <StepToggle
              key={id}
              control={control}
              name={name}
              checkedValue={id}
              isMulti
              rules={{ required: true }}
              label={tagName}
            />
          ))}
        </StepToggleButtonGroup>
      )}
    </>
  );
};

const MemoizedStepForm = memo(StepForm) as typeof StepForm & {
  displayName: "CountryStepForm";
};

MemoizedStepForm.displayName = "CountryStepForm";

const JobTypesStep = <TFieldValues extends FieldValues>({
  control,
  name,
  professionId,
}: JobTypesStepProps<TFieldValues>) => {
  const { data, loading } = useOnboardingProfessionsQuery();

  return (
    <StepForm
      control={control}
      name={name}
      jobTypes={
        data?.getProfessions.data.find(({ id }) => id === professionId)?.tags ||
        []
      }
      loading={loading}
    />
  );
};

export { JobTypesStep };
