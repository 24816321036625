import { ResumeDataWorkExperienceItem } from "@affinda/affinda";
import { CareerExperienceType } from "app/types/generated/schema";
import { useToast } from "app/components/use-toast";
import { ImportFromCVButton } from "../import-from-cv-button";
import { generateTempId } from "../profile-form";
import { transformDate } from "../utils";
import { WorkExperienceFormValues } from "./work-experience";

const transform = (
  affindaWorkExperiences: ResumeDataWorkExperienceItem[]
): WorkExperienceFormValues[] =>
  affindaWorkExperiences.reduce<WorkExperienceFormValues[]>((acc, item) => {
    const { jobTitle, organization, dates, jobDescription } = item;
    // TODO: review valid condition?
    if (!jobTitle && !organization) return acc;

    acc.push({
      id: generateTempId("career-experience"),
      type: CareerExperienceType.WorkExperience,
      title: jobTitle || "",
      organisationName: organization || "",
      startDate: transformDate(dates?.startDate),
      endDate: transformDate(dates?.endDate),
      description:
        jobDescription
          ?.split("\n")
          // replace hyphens or bullets at start of lines
          .map((line) => line.replace(/^(-|•)( ?)*/g, "")) || [],
      hideEndDate: !!!dates?.endDate,
    });
    return acc;
  }, []);

interface ImportWorkExperienceFromCVButtonProps {
  onParseData: (data: WorkExperienceFormValues[]) => void;
}
export const ImportWorkExperienceFromCVButton = ({
  onParseData,
}: ImportWorkExperienceFromCVButtonProps) => {
  const toast = useToast();
  return (
    <ImportFromCVButton
      handleImport={(parsedCV) => {
        if (!parsedCV.data || !parsedCV.data.workExperience) {
          toast.show({
            status: "error",
            title: "Failed to import CV",
            description:
              "We couldn't find any work experience on the CV you uploaded. Please try again with a different document or contact support.",
          });
          return;
        }
        onParseData(transform(parsedCV.data.workExperience));
      }}
    />
  );
};
