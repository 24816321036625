import { Sx } from "dripsy";
import { ComponentProps, ReactNode } from "react";
import { Link as SolitoLink, useLink } from "solito/link";
import { Text, TextProps } from "app/components/text";

export interface LinkProps extends Omit<TextProps, "href" | "sx"> {
  href: Pick<ComponentProps<typeof SolitoLink>, "href">["href"];
  textSx?: Sx;
  children: ReactNode;
}

export const Link = ({ textSx = {}, href, children, ...rest }: LinkProps) => {
  const linkProps = useLink({ href });
  return (
    <Text
      sx={{
        textDecorationLine: "underline",
        ...textSx,
      }}
      {...linkProps}
      {...rest}
    >
      {children}
    </Text>
  );
};
