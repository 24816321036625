import { Platform } from "react-native";
import { useIdParam } from "app/hooks/use-id-param";
import { Grid, GridItem } from "app/design/grid";
import { Screen } from "app/components/screen";
import { Layout } from "app/features/layout";
import { memoizeScreen } from "app/utils/screen";
import { ApplicationProgress } from "./application-progress";
import { ApplyForm } from "./apply-form";

const ApplyScreen = memoizeScreen(() => {
  const jobId = useIdParam("jobId");
  if (!jobId) return null;

  return (
    <Screen scrollable>
      <Grid>
        <GridItem
          widths={[1, null, null, 1 / 3]}
          sx={{ order: [null, null, null, 1] }}
        >
          <ApplicationProgress
            sx={{
              mt: [0, null, null, 44],
              ...(Platform.OS === "web" && {
                position: ["relative", null, null, "sticky"],
                top: [0, null, null, "$6"],
              }),
            }}
            jobId={jobId}
          />
        </GridItem>
        <GridItem widths={[1, null, null, 2 / 3]}>
          <ApplyForm jobId={jobId} />
        </GridItem>
      </Grid>
    </Screen>
  );
});

const headerProps = {
  title: "Apply",
  backButtonType: "back",
  showTitleOnDesktop: true,
} as const;

ApplyScreen.displayName = "ApplyScreen";
ApplyScreen.headerProps = headerProps;
ApplyScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { ApplyScreen };
