import { ActivityIndicator, useDripsyTheme, useSx, View } from "dripsy";
import { Hoverable } from "moti/build/interactions/pressable/hoverable";
import { MotiText, MotiView } from "moti";
import { cloneElement, useEffect, useState } from "react";

import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  ScrollViewProps,
} from "react-native";
import { useAvailableWidth } from "app/hooks/use-available-width";
import {
  Button,
  ButtonIcon,
  ButtonIconBag,
  ButtonProps,
  ButtonVariant,
} from "app/design/button";
import { Screen, ScreenProps } from "app/components/screen";
import { useAvailableHeight } from "app/hooks";
import { conditionalParam } from "app/utils";

// TODO: Possibly delegate to code user
const SCREEN_MAX_WIDTH = 668;
const BUTTON_ICON_SIZE = 21;

const renderButtonIcon = ({
  loading,
  ...props
}: ButtonIconBag & { loading?: boolean }) => {
  if (loading) {
    return (
      <ActivityIndicator
        size={BUTTON_ICON_SIZE}
        color={props.colorStates.color?.initialColor}
      />
    );
  }
  return <ButtonIcon {...props} />;
};

interface FloatingActionButtonProps extends ButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
}

const FloatingActionButton = ({
  children,
  variant = "card",
  loading,
  icon,
  ...rest
}: FloatingActionButtonProps) => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const buttonSize = theme.iconButton.sizes.lg.height;

  const iconWithSize =
    icon &&
    cloneElement(icon, {
      size: BUTTON_ICON_SIZE,
    });

  return (
    <Button
      icon={iconWithSize}
      renderIcon={(iconProps) => renderButtonIcon({ loading, ...iconProps })}
      variant={variant}
      sx={{
        borderRadius: buttonSize / 2,
        overflow: "hidden",
        justifyContent: "flex-start",
        height: buttonSize,
        ...conditionalParam({ boxShadow: "default" }, variant === "primary"),
      }}
      // Workaround as sometimes padding styles in `sx` were not being applied
      // and breaking the icon positioning
      style={{
        paddingLeft: theme.space.$3,
        paddingTop: theme.button.sizes.md.py,
        paddingBottom: theme.button.sizes.md.py,
        paddingRight: 0,
      }}
      containerSx={{ mb: "$2" }}
      {...rest}
    >
      <MotiText
        style={sx({ position: "absolute", top: 11, left: buttonSize })}
        numberOfLines={1}
      >
        {children}
      </MotiText>
    </Button>
  );
};

interface FloatingActionToggleButtonProps
  extends Omit<FloatingActionButtonProps, "renderIcon" | "icon"> {
  renderToggleIcon: (
    props: ButtonIconBag & { size: number }
  ) => React.ReactNode;
}

const FloatingActionToggleButton = ({
  renderToggleIcon,
  ...rest
}: FloatingActionToggleButtonProps) => {
  return (
    <FloatingActionButton
      // TODO: Temporary workaround due to icon being required by Button.tsx - to be refactored
      icon={<></>}
      renderIcon={(iconProps) =>
        renderToggleIcon({ size: BUTTON_ICON_SIZE, ...iconProps })
      }
      {...rest}
    />
  );
};

interface SliderActionListProps {
  children: React.ReactNode;
  expanded: boolean;
}

const FloatingActionList = ({ children, expanded }: SliderActionListProps) => {
  const { theme } = useDripsyTheme();
  const [active, setActive] = useState(true);
  const sx = useSx();
  const availableWidth = useAvailableWidth();
  const availableHeight = useAvailableHeight();

  const buttonSize = theme.iconButton.sizes.lg.height;

  useEffect(() => {
    setActive(expanded);
  }, [expanded]);

  return (
    <Hoverable
      onHoverIn={() => setActive(true)}
      onHoverOut={() => {
        if (!expanded) setActive(false);
      }}
    >
      <MotiView
        animate={{
          width: active ? 120 : buttonSize,
        }}
        style={sx({
          position: "absolute",
          bottom: ["$2", "auto"],
          top: ["auto", availableHeight / 2 + 42],
          right: [
            "$2",
            Math.max(
              availableWidth / 2 - SCREEN_MAX_WIDTH / 2,
              buttonSize / 2 + theme.space.$6
            ),
          ],
          marginRight: [0, 0 - buttonSize / 2],
          ...conditionalParam(
            {
              right: "auto",
              left: availableWidth / 2 + SCREEN_MAX_WIDTH / 2 + 48,
            },
            availableWidth >= 1000
          ),
        })}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
      >
        {children}
      </MotiView>
    </Hoverable>
  );
};

interface RenderFloatingLayerProps {
  isAtTopOrBottom: boolean;
  maxWidth: number;
}

interface FloatingActionScreenProps {
  scrollViewProps?: Omit<ScrollViewProps, "scrollEventThrottle"> & {
    ref?: React.RefObject<ScrollView>;
  };
  children: React.ReactNode;
  renderFloatingLayer?: (props: RenderFloatingLayerProps) => React.ReactNode;
  screenProps?: Omit<ScreenProps, "children">;
}

const FloatingActionScreen = ({
  scrollViewProps: { onScroll, ...scrollViewProps } = {},
  children,
  renderFloatingLayer = () => null,
  screenProps: {
    containerSx: screenContainerSx,
    sx: screenSx,
    ...screenProps
  } = {},
}: FloatingActionScreenProps) => {
  const [isAtTopOrBottom, setIsAtTopOrBottom] = useState(true);

  const handleScroll = ({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { contentOffset, contentSize, layoutMeasurement } = nativeEvent;

    setIsAtTopOrBottom(
      contentOffset.y < 40 ||
        contentOffset.y > contentSize.height - layoutMeasurement.height - 40
    );
  };

  return (
    <View sx={{ flex: 1 }}>
      <ScrollView
        onScroll={(e) => {
          handleScroll(e);
          onScroll?.(e);
        }}
        scrollEventThrottle={50}
        {...scrollViewProps}
      >
        <Screen
          sx={{
            maxWidth: SCREEN_MAX_WIDTH,
            ...screenSx,
          }}
          containerSx={{
            px: ["$4", "$7"],
            ...screenContainerSx,
          }}
          {...screenProps}
        >
          {children}
        </Screen>
      </ScrollView>
      {renderFloatingLayer({ isAtTopOrBottom, maxWidth: SCREEN_MAX_WIDTH })}
    </View>
  );
};

export type {
  SliderActionListProps,
  FloatingActionButtonProps,
  FloatingActionToggleButtonProps,
  RenderFloatingLayerProps,
};
export {
  FloatingActionList,
  FloatingActionButton,
  FloatingActionToggleButton,
  FloatingActionScreen,
};
