import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import { Text, View } from "dripsy";
import { useDeleteCandidateAccountMutation } from "app/types/generated/schema";
import { Button } from "app/design/button";
import { useAsyncToast } from "app/components/use-toast";
import { WebLink } from "app/components/web-link";
import { useAuth } from "app/use-auth";
import { sendAnalyticsEvent } from "app/client/analytics";

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteCandidateAccount {
    deleteCandidateAccount {
      id
    }
  }
`;

const DeleteAccountForm = () => {
  const { openModal } = useModal();
  const { resetStore } = useAuth();
  const [deleteAccount, { error }] = useDeleteCandidateAccountMutation();

  const withToast = useAsyncToast({
    success: {
      title: "Account successfully deleted",
    },
    error: {
      title: "Error deleting account",
      description: "Please contact support to delete your account.",
    },
  });

  const onConfirm = withToast(async () => {
    sendAnalyticsEvent("delete account requested");
    await deleteAccount();
    sendAnalyticsEvent("delete account success");
    resetStore();
  });

  return (
    <View sx={{ mb: "$6" }}>
      <Button
        variant="danger"
        disabled={!!error}
        onPress={() =>
          openModal("ConfirmModal", {
            title: "Really delete your account?",
            description:
              "All of your data and file uploads will be removed - this action cannot be undone.",
            confirmLabel: "Delete my account",
            destructive: true,
            onConfirm,
          })
        }
      >
        Delete my account
      </Button>
      {!!error && (
        <Text sx={{ color: "$error", mt: "$2" }}>
          Please email{" "}
          <WebLink url="mailto:support@migratehr.com" sx={{ color: "$error" }}>
            support@migratehr.com
          </WebLink>{" "}
          to delete your account.
        </Text>
      )}
    </View>
  );
};

export { DeleteAccountForm, DELETE_ACCOUNT_MUTATION };
