import { ReactNode, useRef, useState } from "react";
import {
  TouchableWithoutFeedback,
  useWindowDimensions,
  View as RNView,
} from "react-native";
import { CountryCode } from "react-native-country-picker-modal";
import { useDripsyTheme, View } from "dripsy";
import { Portal } from "@gorhom/portal";
import { SearchableCountryList } from "./searchable-country-list";

type TriggerLayout = {
  top: number;
  height: number;
  left: number;
  width: number;
};

export type Placement =
  | "bottom center screen"
  | "bottom left"
  | "bottom center";

interface CountrySelectMenuProps {
  value?: CountryCode;
  placement?: Placement;
  offsetTop?: number;
  onChange: (value: CountryCode) => void;
  trigger: (props: { value?: CountryCode; onPress: () => void }) => JSX.Element;
}

const MENU_HEIGHT = 300;
const MENU_WIDTH = 400;

const PortalOverlay = ({
  width,
  height,
  onRequestClose,
}: {
  width: number;
  height: number;
  onRequestClose: () => void;
}) => (
  <TouchableWithoutFeedback onPress={onRequestClose}>
    <View
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width,
        height,
      }}
    />
  </TouchableWithoutFeedback>
);

const LABEL_HEIGHT = 24;

const PortalContent = (props: {
  triggerLayout?: TriggerLayout;
  placement?: Placement;
  width: number;
  height: number;
  offsetTop?: number;
  children: ReactNode;
}) => {
  const window = useWindowDimensions();
  const { theme } = useDripsyTheme();
  const {
    triggerLayout = { top: 0, height: 0, left: 0, width: 0 },
    placement = "bottom left",
    width,
    height,
    offsetTop = theme.space.$2,
    children,
  } = props;

  const menuBottomY =
    triggerLayout.top + triggerLayout.height + height + offsetTop;
  const menuBottomYOffset = menuBottomY - window.height;
  const renderAboveTriggerThreshold = 100;

  const getHeight = () => {
    const isRenderingAboveTrigger =
      menuBottomYOffset > renderAboveTriggerThreshold;
    if (isRenderingAboveTrigger) {
      const menuTopY = triggerLayout.top - height;
      if (menuTopY < 0) {
        // not enough space above trigger -> cut off menu at top of trigger
        return triggerLayout.top + LABEL_HEIGHT;
      }
      return height;
    }
    if (menuBottomYOffset > 0) return height - menuBottomYOffset;
    return height;
  };

  const getTop = () => {
    if (menuBottomYOffset > renderAboveTriggerThreshold) {
      // not enough space below trigger -> render above with min top of 0
      return Math.max(triggerLayout.top + LABEL_HEIGHT - height, 0);
    }
    return triggerLayout.top + triggerLayout.height + offsetTop;
  };

  const getLeft = () => {
    /**
     * this option is an unfortunate hack due to layout values not being correct
     * on first render within the onboarding screens...oh well
     */
    if (placement === "bottom center")
      return triggerLayout.left + triggerLayout.width / 2 - width / 2;
    if (placement === "bottom left") return triggerLayout.left;
  };

  return (
    <View
      sx={{
        position: "absolute",
        bg: "$white",
        borderWidth: 1,
        borderColor: "$ui",
        borderRadius: 4,
        maxHeight: getHeight(),
        width,
        overflow: "hidden",
        top: getTop(),
        left: getLeft(),
        pb: "$2",
      }}
    >
      {children}
    </View>
  );
};

export const CountrySelectMenu = ({
  value,
  placement,
  offsetTop,
  onChange,
  trigger,
}: CountrySelectMenuProps) => {
  const window = useWindowDimensions();
  const layoutRef = useRef<RNView | null>(null);
  const [openPortalWithLayout, setOpenPortalWithLayout] =
    useState<TriggerLayout | null>(null);

  const openPortal = () => {
    if (!layoutRef.current) return;
    layoutRef.current.measure(
      (
        _x: number,
        _y: number,
        width: number,
        height: number,
        pageX: number,
        pageY: number
      ) => {
        setOpenPortalWithLayout({
          top: pageY,
          left: pageX,
          width,
          height,
        });
      }
    );
  };

  const closePortal = () => {
    setOpenPortalWithLayout(null);
  };

  return (
    <>
      {openPortalWithLayout ? (
        <Portal>
          <PortalOverlay
            onRequestClose={closePortal}
            width={window.width}
            height={window.height}
          />

          <PortalContent
            triggerLayout={openPortalWithLayout}
            placement={placement}
            offsetTop={offsetTop}
            width={MENU_WIDTH}
            height={MENU_HEIGHT}
          >
            <SearchableCountryList
              onChange={(country) => {
                onChange(country);
                closePortal();
              }}
              searchTermDeps={[open, value]}
            />
          </PortalContent>
        </Portal>
      ) : null}

      <View ref={layoutRef}>
        {trigger({
          value,
          onPress: openPortal,
        })}
      </View>
    </>
  );
};
