import { Feather } from "@expo/vector-icons";
import { Pressable, useDripsyTheme, useSx } from "dripsy";
import { AnimatePresence, MotiView } from "moti";
import { useAvailableWidth } from "app/hooks/use-available-width";
import { IconButton } from "app/design/icon-button";
import { useAvailableHeight } from "app/hooks";
import { conditionalParam } from "app/utils";

interface ArrowProps {
  onPress: () => void;
  direction: "prev" | "next";
  disabled: boolean;
  screenMaxWidth: number;
}

const Arrow = ({
  onPress,
  direction,
  disabled,
  screenMaxWidth,
}: ArrowProps) => {
  const availableWidth = useAvailableWidth();
  const availableHeight = useAvailableHeight();
  const { theme } = useDripsyTheme();
  const sx = useSx();

  const buttonSize = theme.iconButton.sizes.lg.height;

  return (
    <AnimatePresence>
      {!disabled ? (
        <MotiView
          from={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            type: "timing",
            duration: theme.transitionDurations.normal,
          }}
          style={sx({
            position: "absolute",
            bottom: availableHeight / 2 - buttonSize / 2,
            [direction === "prev" ? "left" : "right"]: Math.max(
              availableWidth / 2 - screenMaxWidth / 2,
              buttonSize / 2 + theme.space.$6
            ),
            [direction === "prev" ? "marginLeft" : "marginRight"]:
              0 - buttonSize / 2,
            display: ["none", "flex"],
            ...conditionalParam(
              {
                [direction === "prev" ? "left" : "right"]: "auto",
                [direction === "prev" ? "right" : "left"]:
                  availableWidth / 2 + screenMaxWidth / 2 + 48,
              },
              availableWidth >= 1000
            ),
          })}
        >
          <IconButton
            icon={
              <Feather
                name={direction === "prev" ? "chevron-left" : "chevron-right"}
                size={24}
              />
            }
            variant="card"
            size="lg"
            onPress={onPress}
            accessibilityLabel={direction}
            sx={{ boxShadow: "md" }}
          />
        </MotiView>
      ) : null}
    </AnimatePresence>
  );
};

export { Arrow };
