import { gql } from "@apollo/client";
import { ListRenderItem } from "react-native";
import { Feather } from "@expo/vector-icons";
import { useModal } from "react-native-modalfy";
import { memo } from "react";
import { Grid, GridItem } from "app/design/grid";
import { MinScreenSize } from "app/components/min-screen-size";
import { ScreenHeader } from "app/components/screen/header";
import {
  ListEmptyComponentText,
  QueryInfiniteScrollFlatListScreen,
} from "app/components/screen/query-infinite-scroll-flat-list-screen";
import { DocumentCard } from "app/components/document-card";
import { FloatyButton } from "app/components/floaty-button";
import {
  SetPreferencesPrompt,
  VerifyPrompt,
} from "app/components/overview-card";
import { Screen } from "app/components/screen";
import { Layout } from "app/features/layout";
import {
  CandidateV2,
  Document,
  DocumentTypeProps,
  useMyDocumentsQuery,
} from "app/types";
import { useCurrentUser } from "app/hooks";
import { ScreenComponent } from "app/navigation/types";

const MY_DOCUMENTS_QUERY = gql`
  query MyDocuments {
    me {
      id
      ... on CandidateV2 {
        documents(_size: 100) {
          data {
            id
            createdAt
            url
            filename
            type
            typeProps {
              name
            }
            canDelete
          }
          after
          count
        }
      }
    }
  }
`;

interface DocumentList {
  typeProps: DocumentTypeProps;
  documents: Document[];
}

const renderItem: ListRenderItem<DocumentList> = ({ item }) => (
  <DocumentCard {...item} />
);

const MyDocumentsScreen: ScreenComponent = memo(
  () => {
    const { openModal } = useModal();
    const { isCandidateV1, isVerified } = useCurrentUser();
    const query = useMyDocumentsQuery();

    if (isCandidateV1 || !isVerified)
      return (
        <Screen>
          <MinScreenSize size="tablet">
            <ScreenHeader>My documents</ScreenHeader>
          </MinScreenSize>
          <Grid>
            <GridItem widths={[1, 1, 1 / 2]}>
              {isCandidateV1 ? (
                <SetPreferencesPrompt description="Before you can upload documents, you need to set your job preferences." />
              ) : (
                <VerifyPrompt description="Before you can uploadDocuments, you need to verify your account." />
              )}
            </GridItem>
          </Grid>
        </Screen>
      );

    const currentUser = query.data?.me as CandidateV2;

    const data = Object.values(
      currentUser?.documents.data.reduce(
        (acc: { [key: string]: DocumentList }, item) => {
          if (acc[item.type]) {
            acc[item.type].documents.push(item);
            return acc;
          }
          acc[item.type] = { typeProps: item.typeProps, documents: [item] };
          return acc;
        },
        {}
      ) || {}
    ) as DocumentList[];

    return (
      <>
        <QueryInfiniteScrollFlatListScreen
          queryHookResult={query}
          data={data}
          keyExtractor={(document) => document.typeProps.name}
          renderListHeaderComponent={() => (
            <MinScreenSize size="tablet">
              <ScreenHeader>My documents</ScreenHeader>
            </MinScreenSize>
          )}
          renderItem={renderItem}
          renderListEmptyComponent={() => (
            <ListEmptyComponentText>No documents found</ListEmptyComponentText>
          )}
        />
        <FloatyButton
          icon={<Feather name="file-text" size={24} />}
          onPress={() => {
            openModal("UploadDocumentModal", undefined);
          }}
        >
          Upload document
        </FloatyButton>
      </>
    );
  },
  () => true
);

const headerProps = {
  title: "My documents",
} as const;

MyDocumentsScreen.displayName = "MyDocumentsScreen";
MyDocumentsScreen.headerProps = headerProps;
MyDocumentsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { MY_DOCUMENTS_QUERY, MyDocumentsScreen };
