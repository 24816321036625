import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 113;
const originalHeight = 92;
const aspectRatio = originalWidth / originalHeight;

const Stone6 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="m23.769 8.43-.175.174C7.172 19.687-3.286 35.92.94 52.786c3.473 13.692 21.173 29.522 46.168 36.18 31.592 8.46 53.397-2.031 61.244-15.925a34.568 34.568 0 0 0 .014-34.606C101.259 25.201 76.936-2.007 47.108.118a46.736 46.736 0 0 0-23.34 8.311Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone6 };
