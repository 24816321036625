import { H3 } from "dripsy";
import { Skeleton } from "native-base";
import { List, ListItem } from "app/components/list";
import { CareerStageContainer, Progress } from "./career-stage";

export const CareerStageLoading = () => (
  <CareerStageContainer>
    <H3 sx={{ mb: "$3" }}>Your stage</H3>
    <Progress stageNumber={0} />
    <Skeleton.Text lines={1} _line={{ height: 28, width: 220 }} mb={2} />
    <Skeleton.Text lines={2} _line={{ width: "100%" }} mb={2} />
    <List itemSx={{ mt: 0 }}>
      <ListItem>
        <Skeleton.Text lines={1} _line={{ width: 150, _ios: { mt: 2 } }} />
      </ListItem>
      <ListItem>
        <Skeleton.Text lines={1} _line={{ width: 150, _ios: { mt: 2 } }} />
      </ListItem>
      <ListItem>
        <Skeleton.Text lines={1} _line={{ width: 150, _ios: { mt: 2 } }} />
      </ListItem>
    </List>
  </CareerStageContainer>
);
