import { Gradient } from "@dripsy/gradient";
import { Platform } from "react-native";
import { useDripsyTheme } from "dripsy";
import { brandColors } from "app/provider/dripsy/colors";
import { alphaColor } from "./utils";

interface ShadowGradientProps {
  direction?: "top" | "bottom";
  height?: number;
}

const ShadowGradient = ({
  direction = "bottom",
  height = 12,
}: ShadowGradientProps) => {
  const { theme } = useDripsyTheme();

  return (
    <Gradient
      sx={{
        position: "absolute",
        [direction]: 0 - height - theme.card.default.borderWidth,
        height,
        left: 0,
        right: 0,
        ...(Platform.OS === "web" && { pointerEvents: "none" }),
      }}
      colors={[
        alphaColor(brandColors.black, direction === "top" ? 0 : 0.05),
        alphaColor(brandColors.black, direction === "top" ? 0.05 : 0),
      ]}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1 }}
    />
  );
};

export { ShadowGradient };
