import { ElementRef, useRef, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { View } from "dripsy";
import { View as RNView } from "react-native";
import { MatchedJobsQuery } from "app/types/generated/schema";
import { useAsyncStorageEffect } from "app/hooks/use-async-storage-effect";
import { FloatingActionList } from "app/components/floating-action-list";
import {
  ItemSliderScreen,
  ItemSliderScreenContainer,
} from "app/components/slider";
import { AnimateOnHide } from "app/components/slider/animate-on-hide";
import { useToast } from "app/components/use-toast";
import { ProgressDotsLoading } from "app/components/slider/progress-dots";
import { useScreenSize } from "app/hooks";
import {
  JobApplicationStartActionButton,
  JobHideActionButton,
  JobDetailsLoading,
  JobDetails,
  JobToggleSaveActionButton,
} from "../job-details";

import { useJobLocationStore } from "../job-details/job-details/location";
import { EmptyMatches } from "./empty-matches";

const JOBS_SWIPE_INFO_SEEN = "JOBS_SWIPE_INFO_SEEN";

interface JobSliderProps {
  jobs?: MatchedJobsQuery["myMatchedJobs"]["data"];
  loading: boolean;
}
export const JobSlider = ({ jobs, loading }: JobSliderProps) => {
  const { isMobile, isTablet } = useScreenSize();
  const toast = useToast();
  const mapViewLayout = useJobLocationStore((state) => state.mapViewLayout);
  const [jobDetailsOffset, setJobDetailsOffset] = useState({ x: 0, y: 0 });
  const jobDetailsRef = useRef<RNView>(null);

  const jobDetailsRefs = useRef<{
    [key: string]: ElementRef<typeof AnimateOnHide>;
  }>({});

  useAsyncStorageEffect(JOBS_SWIPE_INFO_SEEN, async (seen) => {
    if (!!seen) return;
    if (!isMobile && !isTablet) return;

    toast.show({
      status: "info",
      title: "Navigate between jobs by swiping left and right!",
      duration: null,
    });

    await AsyncStorage.setItem(JOBS_SWIPE_INFO_SEEN, "true");
  });

  if (loading) {
    return (
      <ItemSliderScreenContainer>
        <ProgressDotsLoading />
        <JobDetailsLoading />
      </ItemSliderScreenContainer>
    );
  }

  if (!jobs || jobs.length === 0) {
    return (
      <ItemSliderScreenContainer>
        <EmptyMatches />
      </ItemSliderScreenContainer>
    );
  }

  return (
    <ItemSliderScreen
      items={jobs}
      renderSlide={({ id }) => (
        <AnimateOnHide
          ref={(el: ElementRef<typeof AnimateOnHide>) =>
            (jobDetailsRefs.current[id] = el)
          }
          isLast={jobs.findIndex((job) => job.id === id) === jobs.length - 1}
        >
          <View
            ref={jobDetailsRef}
            onLayout={() => {
              jobDetailsRef.current?.measure(
                (_x, _y, _width, _height, x, y) => {
                  setJobDetailsOffset({ x, y });
                }
              );
            }}
          >
            <JobDetails jobId={id} />
          </View>
        </AnimateOnHide>
      )}
      renderActions={({ id, myMatchResponse }, { isAtTopOrBottom }) => (
        <FloatingActionList expanded={isAtTopOrBottom}>
          <JobToggleSaveActionButton
            jobId={id}
            matchResponse={myMatchResponse}
            variant="primary"
          />
          <JobApplicationStartActionButton jobId={id} />
          <JobHideActionButton
            jobId={id}
            onHide={async (handleHide) => {
              jobDetailsRefs.current[id]?.hide(handleHide);
            }}
          />
        </FloatingActionList>
      )}
      disableSwipeX={
        mapViewLayout
          ? [
              mapViewLayout.x - jobDetailsOffset.x,
              mapViewLayout.x + mapViewLayout.width - jobDetailsOffset.x,
            ]
          : undefined
      }
      disableSwipeY={
        mapViewLayout
          ? [
              mapViewLayout.y - jobDetailsOffset.y,
              mapViewLayout.y + mapViewLayout.height - jobDetailsOffset.y,
            ]
          : undefined
      }
    />
  );
};
