import { PropsWithChildren, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { merge } from "lodash";
import { View } from "dripsy";
import { useRouter } from "solito/router";

import {
  Scalars,
  SearchStage,
  useJobPreferencesScreenQuery,
  useUpdateJobPreferencesMutation,
} from "app/types/generated/schema";
import { ButtonLink } from "app/design/button";
import { ActivityMessage } from "app/design/activity-indicator";
import { useToast } from "app/components/use-toast";
import {
  StepScreenForm,
  Header,
  StepScreenFormContainer,
} from "app/components/step-screen-form";
import { DataError } from "app/components/data-error";
import { pagesPath } from "app/lib/$path";
import { useYupForm, yup } from "app/utils/yup";
import {
  JOB_PREFERENCES_APPLICANT_PROPS,
  JOB_PREFERENCES_CANDIDATE_V2_PROPS,
} from "app/graphql/job-preferences";

import { useCurrentUser } from "app/hooks";
import { sendAnalyticsEvent } from "app/client/analytics";
import { CountryStep, IneligibleCountryContent } from "./steps/country";
import { JobTypesStep } from "./steps/job-types";
import { LocationStep, LOCATION_DONT_MIND } from "./steps/location";
import {
  IneligibleProfessionContent,
  ProfessionStep,
} from "./steps/profession";
import { SearchStageStep } from "./steps/search-stage";
import { ExistingUserIneligibleModal } from "./steps/ineligible-modal";

const JOB_PREFERENCES_SCREEN_QUERY = gql`
  query JobPreferencesScreen {
    me {
      id
      ...JobPreferencesApplicantProps
      ...JobPreferencesCandidateV2Props
    }
    getProfessions(_size: 100) {
      ...ProfessionStepProfessionPageProps
    }
    locations {
      ...LocationStepLocationProps
    }
  }
  ${ProfessionStep.fragments.professionPage}
  ${LocationStep.fragments.location}
  ${JOB_PREFERENCES_APPLICANT_PROPS}
  ${JOB_PREFERENCES_CANDIDATE_V2_PROPS}
`;

const schema = yup
  .object({
    professionId: yup
      .string()
      .trim()
      .min(1)
      .defined()
      .required("Please select a profession"),
    country: yup.string().min(1).required("Please select a country"),
    tagPreferenceIds: yup
      .array()
      .of(yup.string())
      .min(1, "Please select your job preferences")
      .required(),
    locationPreferenceIds: yup
      .array()
      .of(yup.string())
      .min(1, "Please select your location preferences")
      .required(),
    searchStage: yup.string().required("Please choose an option"),
  })
  .required();

interface PreferencesFormValues {
  professionId?: string;
  country?: string;
  tagPreferenceIds?: Scalars["MigrateID"][];
  locationPreferenceIds?: Scalars["MigrateID"][];
  searchStage?: SearchStage;
}

interface PreferencesScreenHeaderProps {
  stepProgress: number;
}

const PreferencesScreenHeader = ({
  stepProgress,
}: PreferencesScreenHeaderProps) => (
  <Header stepProgress={stepProgress}>
    <ButtonLink variant="ghost" href={pagesPath.$url()}>
      Back
    </ButtonLink>
    <Header.MigrateLogo />
  </Header>
);

interface PreferencesScreenFormProps {
  data: Partial<PreferencesFormValues>;
}

const PreferencesScreenForm = ({ data }: PreferencesScreenFormProps) => {
  const { isCandidateV1 } = useCurrentUser();
  const router = useRouter();

  const goBack = () => {
    router.push(pagesPath.$url());
  };

  const [ineligibleLoading, setIneligibleLoading] = useState(false);
  const [showIneligibleCountryModal, setShowIneligibleCountryModal] =
    useState(false);
  const [showIneligibleProfessionModal, setShowIneligibleProfessionModal] =
    useState(false);

  const toast = useToast();
  const [updateJobPreferences] = useUpdateJobPreferencesMutation({
    update: (cache, { data: _data }) => {
      if (!_data) return;
      cache.modify({
        fields: {
          me() {
            return _data.updateCurrentCandidate;
          },
        },
      });
      cache.evict({ id: "ROOT_QUERY", fieldName: "myMatchedJobs" });
    },
  });
  const preferencesForm = useYupForm<PreferencesFormValues>({
    schema,
    defaultValues: data,
  });

  useEffect(() => {
    const subscription = preferencesForm.watch((_, { name, type }) => {
      if (name === "professionId" && type === "change") {
        preferencesForm.setValue("tagPreferenceIds", []);
      }
    });
    return () => subscription.unsubscribe();
  }, [preferencesForm.watch]);

  const sanitizePreferences = (
    preferences: PreferencesFormValues
  ): PreferencesFormValues => {
    return {
      ...preferences,
      // TODO: Does not allow 'unsetting' profession to ineligible prof or country
      // Add ticket for appropriate flow / backend validation updates
      tagPreferenceIds:
        preferences.tagPreferenceIds?.length === 0
          ? undefined
          : preferences.tagPreferenceIds,
      locationPreferenceIds:
        preferences.locationPreferenceIds &&
        preferences.locationPreferenceIds.filter(
          (id) => id !== LOCATION_DONT_MIND.id
        ),
    };
  };

  const onSubmit = async () => {
    try {
      const shouldTrack = isCandidateV1;
      await updateJobPreferences({
        variables: {
          input: sanitizePreferences(preferencesForm.getValues()),
        },
      });
      if (shouldTrack) sendAnalyticsEvent("set preferences");
      goBack();
      toast.show({
        status: "success",
        title: "Preferences updated",
      });
    } catch (e) {
      console.error(e);
      toast.show({
        status: "error",
        title: "Could not update",
        description: "Please try again later or contact support.",
      });
    }
  };

  const handlePreferencesFormSubmit = preferencesForm.handleSubmit(onSubmit);

  const clearEligblePreferences = () => {
    (["tagPreferenceIds", "locationPreferenceIds", "searchStage"] as const).map(
      (key) => {
        preferencesForm.setValue(key, undefined, { shouldValidate: false });
      }
    );
  };

  const handleIneligible = async () => {
    setIneligibleLoading(true);
    clearEligblePreferences();
    await onSubmit();
    setIneligibleLoading(false);
    goBack();
  };

  return (
    <>
      <StepScreenForm
        HeaderComponent={PreferencesScreenHeader}
        onSubmit={handlePreferencesFormSubmit}
        exitPath={pagesPath}
        path={pagesPath.job_preferences}
        {...preferencesForm}
      >
        <ProfessionStep
          onIneligible={() => setShowIneligibleProfessionModal(true)}
          stepPath="profession"
          name="professionId"
          {...preferencesForm}
        />
        <CountryStep
          onIneligible={() => setShowIneligibleCountryModal(true)}
          stepPath="country"
          name="country"
          {...preferencesForm}
        />
        <JobTypesStep
          stepPath="job-types"
          name="tagPreferenceIds"
          professionId={preferencesForm.watch("professionId")}
          {...preferencesForm}
        />
        <LocationStep
          stepPath="location"
          name="locationPreferenceIds"
          {...preferencesForm}
        />
        <SearchStageStep
          stepPath="search-stage"
          name="searchStage"
          {...preferencesForm}
        />
      </StepScreenForm>

      <ExistingUserIneligibleModal
        loading={ineligibleLoading}
        isOpen={showIneligibleCountryModal}
        onClose={() => setShowIneligibleCountryModal(false)}
        onConfirm={handleIneligible}
      >
        <IneligibleCountryContent />
      </ExistingUserIneligibleModal>

      <ExistingUserIneligibleModal
        loading={ineligibleLoading}
        isOpen={showIneligibleProfessionModal}
        onClose={() => setShowIneligibleProfessionModal(false)}
        onConfirm={handleIneligible}
      >
        <IneligibleProfessionContent />
      </ExistingUserIneligibleModal>
    </>
  );
};

const PreferencesScreenDefaultWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <StepScreenFormContainer>
      <PreferencesScreenHeader stepProgress={0} />
      {children}
    </StepScreenFormContainer>
  );
};

const PreferencesScreen = () => {
  const { data, loading, error } = useJobPreferencesScreenQuery();

  if (loading) {
    return (
      <PreferencesScreenDefaultWrapper>
        <View sx={{ mt: ["$7", "$9"], alignItems: "center" }}>
          <ActivityMessage>Loading preferences</ActivityMessage>
        </View>
      </PreferencesScreenDefaultWrapper>
    );
  }

  if (
    !data ||
    // TODO: Better agent auth
    (data.me.__typename !== "Candidate" &&
      data.me.__typename !== "CandidateV2") ||
    error
  ) {
    const title = data ? "Permission denied" : error?.name;

    const description = data
      ? "Only candidates can update their job preferences"
      : error?.message;

    return (
      <PreferencesScreenDefaultWrapper>
        <DataError
          sx={{ m: "$4", width: "auto" }}
          title={title}
          description={description}
        />
      </PreferencesScreenDefaultWrapper>
    );
  }

  const { me: candidate } = data;

  const defaultJobPreferences = {
    country: "",
    professionId: "",
    locationPreferenceIds: [] as Scalars["MigrateID"][],
    tagPreferenceIds: [] as Scalars["MigrateID"][],
    searchStage: "" as unknown as undefined,
  };

  const applicantJobPreferences = {
    professionId: candidate.profession?.id || undefined,
    country: candidate.country || undefined,
  };

  const candidateV2JobPreferences =
    candidate.__typename === "CandidateV2"
      ? {
          locationPreferenceIds:
            candidate.locationPreferences.length === 0
              ? [LOCATION_DONT_MIND.id]
              : candidate.locationPreferences.map(({ id }) => id),
          tagPreferenceIds: candidate.tagPreferences.map(({ id }) => id),
          searchStage: candidate.searchStage || undefined,
        }
      : {};

  const candidateJobPreferences = merge(
    {},
    defaultJobPreferences,
    applicantJobPreferences,
    candidateV2JobPreferences
  );

  return <PreferencesScreenForm data={candidateJobPreferences} />;
};

PreferencesScreen.getLayout = (page: React.ReactElement) => {
  return page;
};

export { PreferencesScreen, JOB_PREFERENCES_SCREEN_QUERY };
