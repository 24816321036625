import { useResponsiveValue } from "dripsy";
import { useWindowDimensions } from "react-native";

import { SIDEBAR_WIDTHS } from "app/features/layout/constants";

export const useAvailableWidth = () => {
  const { width } = useWindowDimensions();
  const currentSidebarWidth = useResponsiveValue(SIDEBAR_WIDTHS);

  return currentSidebarWidth ? width - currentSidebarWidth : width;
};
