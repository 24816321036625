import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 236;
const originalHeight = 221;
const aspectRatio = originalWidth / originalHeight;

const Stone3 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="M79.643 3.133C-27.32 22.546-38.407 235.062 119.967 220.262 298.697 203.56 259.756-29.527 79.643 3.132Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone3 };
