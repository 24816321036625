import { brandColors } from "./colors";

const a = {
  color: brandColors.blue,
  fontSize: [14, 16],
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "600",
  lineHeight: [21, 24],
};
const h1 = {
  color: brandColors.black,
  fontFamily: "heading",
  fontSize: 32,
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "600",
  lineHeight: 40,
  mt: 0,
};
const h2 = {
  color: brandColors.black,
  fontFamily: "heading",
  fontSize: 24,
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "600",
  lineHeight: 28,
  mt: 0,
};
const h3 = {
  color: brandColors.black,
  fontFamily: "heading",
  fontSize: 20,
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "600",
  lineHeight: 24,
  my: 0,
};
const h4 = {
  color: brandColors.black,
  fontSize: 18,
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "500",
  lineHeight: 24,
  my: 0,
};
const h5 = {
  color: brandColors.black,
  fontFamily: "heading",
  fontSize: 12,
  // fontWeight values must be kept in sync with `patches/@expo+html+elements+0.2.0.patch`!
  fontWeight: "600",
  lineHeight: 18,
  my: 0,
  textTransform: "uppercase",
};

export { a, h1, h2, h3, h4, h5 };
