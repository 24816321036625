import { Pressable } from "dripsy";
import { memo, useState } from "react";
import isEqual from "react-fast-compare";
import { Hoverable } from "moti/build/interactions/pressable/hoverable";
import { Platform } from "react-native";
import { Actionsheet } from "native-base";

import { useScreenSize } from "app/hooks";
import { conditionalParam } from "app/utils";

import { ChannelMessage } from "../../types";
import { MessageContent } from "./content";
import { MessageHeader } from "./header";
import { Attachment } from "./attachment";
import { ReferenceMessageBubble } from "./referenced-message-bubble";
import { BubbleProps, useBubbleTheme, Bubble } from "./bubble";

type RenderMessageBubbleActions = (props: {
  message: ChannelMessage;
}) => React.ReactNode;

type RenderMessageBubbleActionSheet = (props: {
  message: ChannelMessage;
  close: () => void;
}) => React.ReactNode;

type RenderMessageHeader = (props: {
  message: ChannelMessage;
  color: string;
}) => React.ReactNode;

const defaultRenderMessageHeader: RenderMessageHeader = ({
  message,
  color,
}) => <MessageHeader message={message} color={color} />;

interface MessageBubbleProps extends Omit<BubbleProps, "children"> {
  message: ChannelMessage;
  onPress?: () => void;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  renderActions?: RenderMessageBubbleActions;
  renderActionSheet?: RenderMessageBubbleActionSheet;
  renderHeader?: RenderMessageHeader;
}

const MessageBubble = memo(
  ({
    message,
    onPress,
    renderActions,
    renderActionSheet,
    variant = "default",
    renderHeader = defaultRenderMessageHeader,
    onHoverIn,
    onHoverOut,
    ...bubbleProps
  }: MessageBubbleProps) => {
    const getTheme = useBubbleTheme();
    const { color } = getTheme(variant);

    const [showMenu, setShowMenu] = useState(false);
    const [showActionSheet, setShowActionSheet] = useState(false);
    const { isAtLeastDesktop } = useScreenSize();

    const canLongPress = !(Platform.OS === "web" && isAtLeastDesktop);

    return (
      <>
        <Hoverable
          onHoverIn={() => {
            onHoverIn?.();
            setShowMenu(true);
          }}
          onHoverOut={() => {
            onHoverOut?.();
            setShowMenu(false);
          }}
        >
          <Pressable
            accessibilityRole="text"
            style={({ pressed }) => ({
              opacity: (canLongPress || onPress) && pressed ? 0.7 : 1,
              cursor: "text",
            })}
            onPress={onPress}
            {...conditionalParam(
              {
                onLongPress: () => {
                  setShowActionSheet(true);
                },
              },
              canLongPress
            )}
          >
            <Bubble variant={variant} {...bubbleProps}>
              {showMenu && renderActions ? renderActions({ message }) : null}
              {renderHeader({ message, color })}

              {message.referencedMessage ? (
                <ReferenceMessageBubble
                  referencedMessage={message.referencedMessage}
                />
              ) : null}

              {message.attachment ? (
                <Attachment attachment={message.attachment} />
              ) : null}

              {message.content ? (
                <MessageContent
                  sx={{
                    color,
                  }}
                  data={message.content}
                />
              ) : null}
            </Bubble>
          </Pressable>
        </Hoverable>

        {renderActionSheet && showActionSheet ? (
          <Actionsheet
            isOpen={showActionSheet}
            onClose={() => setShowActionSheet(false)}
          >
            {renderActionSheet({
              message,
              close: () => setShowActionSheet(false),
            })}
          </Actionsheet>
        ) : null}
      </>
    );
  },
  isEqual
);
MessageBubble.displayName = "MessageBubble";

export type {
  MessageBubbleProps,
  RenderMessageBubbleActions,
  RenderMessageBubbleActionSheet,
  RenderMessageHeader,
};

export { MessageBubble };
