import { useCallback } from "react";
import {
  MessageBubbleProps,
  RenderMessageBubbleActions,
  SentMessageBubble as BaseSentMessageBubble,
  RenderMessageBubbleActionSheet,
} from "../bubble";
import {
  DeleteMessageAction,
  MessageActionList,
  ReplyToMessageAction,
} from "../action-menu";
import {
  DeleteMessageItem,
  MessageActionSheetContent,
  ReplyToMessageItem,
} from "../action-sheet";

const renderSentMessageActionList: RenderMessageBubbleActions = ({
  message,
}) => {
  return (
    <MessageActionList>
      <ReplyToMessageAction message={message} />
      <DeleteMessageAction message={message} />
    </MessageActionList>
  );
};

const renderSentMessageActionSheet: RenderMessageBubbleActionSheet = (
  props
) => {
  return (
    <MessageActionSheetContent>
      <ReplyToMessageItem {...props} />
      <DeleteMessageItem {...props} />
    </MessageActionSheetContent>
  );
};

type SentMessageBubbleProps = Omit<
  MessageBubbleProps,
  "renderActions" | "renderActionSheet" | "variant"
>;

const SentMessageBubble = (props: SentMessageBubbleProps) => {
  const renderActions = useCallback(renderSentMessageActionList, []);
  const renderActionSheet = useCallback(renderSentMessageActionSheet, []);
  return (
    <BaseSentMessageBubble
      {...props}
      variant="user"
      renderActions={renderActions}
      renderActionSheet={renderActionSheet}
    />
  );
};

export type { SentMessageBubbleProps };
export { SentMessageBubble };
