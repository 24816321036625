import { View } from "dripsy";
import { memo } from "react";
import { MinScreenSize } from "app/components/min-screen-size";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import { ScreenComponent } from "app/navigation/types";
import { Layout } from "../../layout";
import { AccountSettingsForm } from "./account-settings-form";
import { ChangePasswordForm } from "./change-password-form";
import { DeleteAccountForm } from "./delete-account-form";

const ACCOUNT_SETTINGS_MAX_WIDTH = 500;

const AccountSettingsScreen: ScreenComponent = memo(
  () => {
    return (
      <Screen scrollable sx={{ maxWidth: 1056, alignItems: "center" }}>
        <MinScreenSize size="tablet">
          <ScreenHeader>My account</ScreenHeader>
        </MinScreenSize>
        <View sx={{ maxWidth: ACCOUNT_SETTINGS_MAX_WIDTH, width: "100%" }}>
          <AccountSettingsForm />
          <ChangePasswordForm />
          <DeleteAccountForm />
        </View>
      </Screen>
    );
  },
  () => true
);

const headerProps = {
  title: "My account",
};

AccountSettingsScreen.displayName = "AccountSettingsScreen";
AccountSettingsScreen.headerProps = headerProps;
AccountSettingsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { AccountSettingsScreen };
