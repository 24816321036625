import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { Platform } from "react-native";
import { useDripsyTheme } from "dripsy";
import {
  Textarea,
  TextareaProps,
  TEXTAREA_LINE_HEIGHT,
} from "app/design/textarea";
import { FormControl, FormControlProps } from "./form-control";

export interface TextareaControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  inputProps?: TextareaProps;
}

const TextareaControl = forwardRef(
  <T extends FieldValues>(props: TextareaControlProps<T>, ref: any) => {
    const { inputProps, ...rest } = props;

    return (
      <FormControl {...rest}>
        <Textarea ref={ref} {...inputProps} />
      </FormControl>
    );
  }
);
TextareaControl.displayName = "TextareaControl";

const FullHeightTextareaControl = <T extends FieldValues>({
  inputProps = {},
  ...rest
}: TextareaControlProps<T>) => {
  const { theme } = useDripsyTheme();
  const { rows = 3, sx = {}, containerSx = {} } = inputProps;

  return (
    <TextareaControl
      inputProps={{
        ...inputProps,
        sx: {
          ...sx,
          flexGrow: [1, undefined],
          ...(Platform.OS === "web" && {
            minHeight: ["100%", TEXTAREA_LINE_HEIGHT * rows + theme.space.$6],
          }),
        },
        containerSx: {
          flexGrow: [1, undefined],
          ...containerSx,
        },
        passFlexValues: true,
        rows,
      }}
      {...rest}
    />
  );
};

export { TextareaControl, FullHeightTextareaControl };
