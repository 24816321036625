// @ts-nocheck ripped from employer-app. TODO: fix this
import { gql, useApolloClient } from "@apollo/client";
import {
  ReferencedMessageFieldsFragmentDoc,
  Scalars,
  useSendMessageOptimisticAuthorQuery,
} from "app/types/generated/schema";

import {
  MESSAGE_FIELDS_FRAGMENT,
  MESSAGE_AUTHOR_FIELDS_FRAGMENT,
  UploadMessageInput,
  useUploadMessage,
} from "./use-upload-message";

const SEND_MESSAGE_OPTIMISTIC_AUTHOR = gql`
  ${MESSAGE_AUTHOR_FIELDS_FRAGMENT}
  query SendMessageOptimisticAuthor {
    me {
      ...MessageAuthorFields
    }
  }
`;

const generateTempId = (collection: string) =>
  `${collection}-${new Date().getTime()}`;

type SendMessageInput = UploadMessageInput;
type SendMessageConfig = {
  channelId?: Scalars["MigrateID"];
};

const useSendMessage = (_channelId?: Scalars["MigrateID"]) => {
  const { data, error, loading } = useSendMessageOptimisticAuthorQuery();
  const client = useApolloClient();
  const { cache } = client;

  const uploadMessage = useUploadMessage();

  const sendMessage = async (
    { content, attachment, referencedMessageId }: UploadMessageInput,
    { channelId }: SendMessageConfig = { channelId: _channelId }
  ) => {
    if (!channelId) throw Error("Channel id undefined");

    const tempMessageId = generateTempId("messages");
    const messageCacheId = `Message:${tempMessageId}`;
    const tempAttachmentId = generateTempId("attachments");

    const referencedMessage = referencedMessageId
      ? client.readFragment({
          id: `Message:${referencedMessageId}`,
          fragment: ReferencedMessageFieldsFragmentDoc,
          fragmentName: "ReferencedMessageFields",
        })
      : null;

    const optimisticMessage: MessageResponse = {
      __typename: "Message",
      id: tempMessageId,
      createdAt: new Date().toISOString(),
      isSent: false,
      sendError: false,
      author: data?.me,
      deletedAt: null,
      content: content || null,
      attachment: attachment
        ? {
            __typename: "Attachment",
            id: tempAttachmentId,
            filename: attachment.name || null,
            isUploading: true,
            url: null,
          }
        : null,
      referencedMessage:
        (referencedMessage && {
          ...referencedMessage,
          attachment: referencedMessage.attachment || null,
        }) ||
        null,
      reactionInfo: [],
    };

    const optimisticMessageRef = cache.writeFragment({
      fragmentName: "MessageFields",
      id: messageCacheId,
      data: optimisticMessage,
      fragment: MESSAGE_FIELDS_FRAGMENT,
    });

    cache.modify({
      fields: {
        messages(existing, { storeFieldName }) {
          if (!storeFieldName.includes(channelId)) return existing;
          return {
            ...existing,
            data: [optimisticMessageRef, ...existing.data],
          };
        },
      },
    });

    // All errors caught and handled internally in this function
    // TODO: May want to pass through non-network errors
    await uploadMessage(
      {
        content,
        attachment,
        referencedMessageId: referencedMessage?.id,
      },
      cache.identify(optimisticMessageRef),
      channelId
    );
  };

  return {
    sendMessage,
    error,
    loading,
  };
};

export {
  MESSAGE_FIELDS_FRAGMENT,
  MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE,
  UPLOAD_MESSAGE_MUTATION,
} from "./use-upload-message";

export type { SendMessageInput };
export { useSendMessage, SEND_MESSAGE_OPTIMISTIC_AUTHOR };
