import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  useCreateEducationExperienceMutation,
  useDeleteEducationExperienceMutation,
  useUpdateEducationExperienceMutation,
  useEducationExperienceSectionQuery,
  EducationExperience,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const CREATE_EDUCATION_EXPERIENCE_MUTATION = gql`
  mutation CreateEducationExperience($input: CreateEducationExperienceInput!) {
    createEducationExperience(input: $input) {
      id
      educationExperiences {
        id
        institutionName
        qualification
        grade
        startDate
        endDate
        description {
          content
        }
      }
      profileCompletion
    }
  }
`;

export const UPDATE_EDUCATION_EXPERIENCE_MUTATION = gql`
  mutation UpdateEducationExperience($input: UpdateEducationExperienceInput!) {
    updateEducationExperience(input: $input) {
      id
      educationExperiences {
        id
        institutionName
        qualification
        grade
        startDate
        endDate
        description {
          content
        }
      }
      profileCompletion
    }
  }
`;

export const DELETE_EDUCATION_EXPERIENCE_MULTATION = gql`
  mutation DeleteEducationExperience($input: DeleteEducationExperienceInput!) {
    deleteEducationExperience(input: $input) {
      id
      educationExperiences {
        id
        institutionName
        qualification
        grade
        startDate
        endDate
        description {
          content
        }
      }
      profileCompletion
    }
  }
`;

export const useEducationExperienceMutations = (id?: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete education entry",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useEducationExperienceSectionQuery();
  if (error !== undefined)
    throw new Error(
      "unexpected error in useEducationExperienceMutations: " + error.message
    );

  const [createEducationExperience] = useCreateEducationExperienceMutation();
  const [updateEducationExperience] = useUpdateEducationExperienceMutation();
  const [deleteEducationExperience] = useDeleteEducationExperienceMutation({
    update: (cache) => {
      cache.modify({
        id: `Candidate:${data?.me.id}`,
        fields: {
          educationExperiences(existing) {
            return {
              ...existing,
              data: existing.data.filter(
                (educationExperience: EducationExperience) =>
                  educationExperience.id !== id
              ),
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteEducationExperience: {
        ...(data?.me as CandidateV2),
      },
    },
  });

  return {
    createEducationExperience,
    updateEducationExperience,
    deleteEducationExperience: () =>
      openModal("ConfirmModal", {
        title: "Confirm delete education entry",
        description: "Are you sure you want to delete this education entry?",
        confirmLabel: "Delete education",
        onConfirm: withToast(async () => {
          if (!id)
            throw new Error("education experience: id required for deletion");
          await deleteEducationExperience({ variables: { input: { id } } });
        }),
      }),
  };
};
