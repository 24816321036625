const space = {
  $0: 0,
  $1: 4,
  $2: 8,
  $3: 12,
  $4: 16,
  $5: 20,
  $6: 24,
  $7: 32,
  $8: 40,
  $9: 64,
  $10: 128,
};

export { space };
