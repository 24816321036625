import { View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { useState } from "react";

import { Scalars } from "app/types/generated/schema";
import { useSendMessage } from "app/hooks/message/use-send-message";
import { IconButton } from "app/design/icon-button";
import { Input } from "app/design/input";
import { ShadowGradient } from "app/design/shadow-gradient";

import { DataError } from "app/components/data-error";
import { FCC } from "app/types";
import { useChatStore } from "./hooks/use-chat-store";

interface ChatFooterProps {
  channelId: Scalars["MigrateID"];
  disabled: boolean;
}

const FOOTER_PADDING_Y_MOBILE = 8;

const ChatFooterWrapper: FCC = ({ children }) => (
  <View
    sx={{
      flexDirection: "row",
      alignItems: "center",
      px: "$4",
      py: [FOOTER_PADDING_Y_MOBILE, "$4"],
      borderTopWidth: 2,
      borderColor: "$white",
    }}
  >
    <ShadowGradient direction="top" />
    {children}
  </View>
);

const ChatFooter = ({ channelId, disabled }: ChatFooterProps) => {
  const [text, setText] = useState("");
  const { replyingTo, setReplyingTo } = useChatStore();
  const { sendMessage, loading, error } = useSendMessage(channelId);

  const handleSendMessage = () => {
    setText("");
    setReplyingTo(null);
    sendMessage({
      content: text,
      referencedMessageId: replyingTo?.id,
    });
  };

  if (error) {
    // This should only be present if no user object could be fetched...
    // TODO: Perhaps handle in a more user friendly manner / retry?
    return <DataError description={error.message} />;
  }

  return (
    <ChatFooterWrapper>
      {/* <IconButton
        accessibilityLabel="Add attachment"
        variant="ui"
        icon={<Feather name="paperclip" size={16} />}
        containerSx={{
          mt: [null, "$1"],
          mr: ["$2", "$4"],
        }}
        disabled={disabled}
      /> */}

      <Input
        containerSx={{ flex: 1, mb: 0 }}
        disabled={disabled || loading}
        onChange={(textInput) => setText(textInput)}
        onKeyPress={(e) => {
          // @ts-expect-error Apparently doesn't exist on event...
          // TODO: Runtime check
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();

            if (text !== "") {
              handleSendMessage();
            }
          }
        }}
        value={text}
        borderViewSx={{ borderRadius: 23 }}
        placeholder="Type a message..."
        multiline
        showToolbar={false}
      />

      <IconButton
        accessibilityLabel="Send"
        variant="primary"
        icon={<Feather name="send" size={16} />}
        onPress={handleSendMessage}
        containerSx={{
          ml: ["$2", "$4"],
        }}
        disabled={disabled || text === ""}
      />
    </ChatFooterWrapper>
  );
};

export { ChatFooter, ChatFooterWrapper, FOOTER_PADDING_Y_MOBILE };
