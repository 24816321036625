import { H4, H5, styled, Sx, View } from "dripsy";
import { MotiView } from "moti";
import { gql } from "@apollo/client";
import { useRouter } from "solito/router";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  Scalars,
  useApplicationProgressQuery,
  useSubmitApplicationMutation,
} from "app/types/generated/schema";
import { Card } from "app/design/card";
import { Button } from "app/design/button";
import { Menu } from "app/components/menu";
import { DataError } from "app/components/data-error";
import { useAsyncToast } from "app/components/use-toast";
import { MenuTriggerButton } from "app/components/menu-trigger";
import { CompletionCheck } from "app/features/profile/completion/completion-check";
import { pagesPath } from "app/lib/$path";
import { sendAnalyticsEvent } from "app/client/analytics";
import {
  useSaveJobMatch,
  useHideJobMatch,
} from "app/features/jobs/job-details/actions/hooks";
import { useApplicationLimitToast } from "app/features/jobs/job-details/actions/hooks/use-application-limit-toast";
import { useApplicationCompletionChecks } from "./use-application-completion-checks";
import { ApplicationProgressLoading } from "./loading";

const DripsyMotiView = styled(MotiView)();

const PROGRESS_BAR_HEIGHT = 8;

const APPLICATION_PROGRESS_QUERY = gql`
  query ApplicationProgress($jobId: MigrateID!) {
    me {
      id
      ... on CandidateV2 {
        profileCompletion
        canApply
      }
    }
    matchResponseByJob(jobId: $jobId) {
      id
      type
      job {
        id
        title
        requiresLanguagePass
      }
      ... on ApplicationStartMatchResponse {
        applicationCompletion
      }
    }
  }
`;

const SUBMIT_APPLICATION_MUTATION = gql`
  mutation SubmitApplication($input: GenericMatchResponseInput!) {
    submitMatchApplication(input: $input) {
      id
      type
    }
  }
`;

type MoveToOnPress = (handlePress: () => void) => void;

interface MoveToProps {
  matchResponseId: Scalars["MigrateID"];
  jobId: Scalars["MigrateID"];
  onPress?: MoveToOnPress;
}

const MoveToSaveMenuItem = ({
  jobId,
  matchResponseId,
  onPress = (handlePress) => handlePress(),
}: MoveToProps) => {
  const saveJobMatch = useSaveJobMatch();
  const handleSave = () =>
    saveJobMatch({
      jobId,
      existingMatchResponseId: matchResponseId,
    });

  return <Menu.Item onPress={() => onPress(handleSave)}>Save</Menu.Item>;
};

const MoveToHideMenuItem = ({
  jobId,
  matchResponseId,
  onPress = (handlePress) => handlePress(),
}: MoveToProps) => {
  const hideJobMatch = useHideJobMatch();
  const handleHide = () =>
    hideJobMatch({ jobId, existingMatchResponseId: matchResponseId });

  return <Menu.Item onPress={() => onPress(handleHide)}>Hide</Menu.Item>;
};

const AbandonApplicationMenu = (props: Omit<MoveToProps, "onPress">) => {
  const router = useRouter();
  const { openModal } = useModal();

  const confirmToggle: MoveToOnPress = (handleToggle) => {
    openModal("ConfirmModal", {
      title: "Abandon application",
      description:
        "Are you sure? This will delete your application information for this job and you will need to re-enter it if you reapply.",
      onConfirm: () => {
        handleToggle();
        router.push(pagesPath.jobs.$url());
      },
    });
  };

  return (
    <Menu
      trigger={(triggerProps) => (
        <MenuTriggerButton
          variant="ui"
          containerSx={{
            mt: "$2",
          }}
          {...triggerProps}
        >
          Cancel application
        </MenuTriggerButton>
      )}
      placement="bottom right"
    >
      <Menu.Group title="Move to">
        <MoveToSaveMenuItem {...props} onPress={confirmToggle} />
        <MoveToHideMenuItem {...props} onPress={confirmToggle} />
      </Menu.Group>
    </Menu>
  );
};

interface ApplicationProgressProps {
  sx?: Sx;
  jobId: string;
}

const ApplicationProgress = ({ sx = {}, jobId }: ApplicationProgressProps) => {
  const router = useRouter();
  const showErrorToast = useApplicationLimitToast();
  const { data, loading, error } = useApplicationProgressQuery({
    variables: { jobId },
  });

  const [submit, { loading: submitting }] = useSubmitApplicationMutation({
    variables: { input: { jobId } },
    update: (cache) => {
      cache.evict({ fieldName: "myMatchResponses" });
    },
  });

  const withToast = useAsyncToast({
    success: {
      title: "Application submitted",
      description: "We've sent your application across to the employer",
    },
    onSuccess: async () => {
      sendAnalyticsEvent("apply for job");
      router.push(pagesPath.jobs.$url());
    },
    error: {
      title: "Error submitting application",
      description:
        "We couldn't submit your application at this time. Please try again later or contact support.",
    },
  });

  const { me, matchResponseByJob } = data || {};
  const canApply = me?.__typename === "CandidateV2" && me.canApply;

  const { checks, percentage } = useApplicationCompletionChecks(
    me as CandidateV2,
    matchResponseByJob,
    matchResponseByJob?.job.requiresLanguagePass
  );

  if (loading) return <ApplicationProgressLoading />;
  if (error || !data) return <DataError />;

  return (
    <Card sx={{ ...sx, mb: ["$5", "$5", "$5", "$0"] }}>
      <H5 sx={{ color: "$text", textAlign: "center", mb: "$2" }}>
        Applying for
      </H5>
      <H4 sx={{ textAlign: "center", mb: "$2" }}>
        {data.matchResponseByJob.job.title}
      </H4>
      <H5 sx={{ color: "$blue", textAlign: "center", mb: "$2" }}>
        {Math.ceil(percentage)}%
      </H5>
      <View
        sx={{
          borderRadius: PROGRESS_BAR_HEIGHT,
          height: PROGRESS_BAR_HEIGHT,
          backgroundColor: "$ui",
          borderWidth: 2,
          borderColor: "$white",
          mb: "$4",
        }}
      >
        <DripsyMotiView
          sx={{
            width: 0,
            backgroundColor: "$blue",
            borderRadius: PROGRESS_BAR_HEIGHT,
            height: "100%",
          }}
          animate={{
            width: `${percentage}%`,
          }}
        />
      </View>
      {checks.map((check) => (
        <CompletionCheck key={check.label} {...check} />
      ))}
      <Button
        containerSx={{ mt: "$4" }}
        disabled={percentage !== 100}
        onPress={canApply ? withToast(submit) : showErrorToast}
        loading={loading || submitting}
      >
        Send now
      </Button>
      <AbandonApplicationMenu
        jobId={data.matchResponseByJob.job.id}
        matchResponseId={data.matchResponseByJob.id}
      />
    </Card>
  );
};

export {
  ApplicationProgress,
  APPLICATION_PROGRESS_QUERY,
  SUBMIT_APPLICATION_MUTATION,
};
