import create from "zustand";
import { ChannelMessage } from "../types";

export type ChatStore = {
  chatName: string | null;
  setChatName: (name: string | null) => void;
  replyingTo: ChannelMessage | null;
  setReplyingTo: (message: ChannelMessage | null) => void;
};

const store = (set: (values: Partial<ChatStore>) => void) => ({
  chatName: null,
  setChatName: (chatName: string | null) => set({ chatName }),
  replyingTo: null,
  setReplyingTo: (message: ChannelMessage | null) =>
    set({ replyingTo: message }),
});

export const useChatStore = create<ChatStore>(store);
