const BODY_MAX_WIDTH = 1056;
const SCREEN_PADDING_MOBILE = 16;
const SCREEN_PADDING_DESKTOP = 24;

const layout = {
  screen: {
    container: {
      padding: [SCREEN_PADDING_MOBILE, SCREEN_PADDING_DESKTOP],
      flex: 1,
      alignItems: "center",
    },
    body: {
      maxWidth: BODY_MAX_WIDTH,
      width: "100%",
      flex: 1,
    },
  },
};

export {
  layout,
  BODY_MAX_WIDTH,
  SCREEN_PADDING_DESKTOP,
  SCREEN_PADDING_MOBILE,
};
