import { StyleSheet } from "react-native";
import { Gradient } from "@dripsy/gradient";
import { Sx } from "dripsy";
import { alphaColor } from "app/design/utils";
import { FCC } from "app/types";

const Background: FCC<{ sx?: Sx }> = ({ children, sx = {} }) => (
  <Gradient
    sx={{ bg: "$cream", ...sx }}
    style={StyleSheet.absoluteFill}
    colors={[alphaColor("#e8775e", 0.07), alphaColor("#e8775e", 0)]}
    start={{ x: 0, y: 0 }}
    end={{ x: 1, y: 1 }}
  >
    {children}
  </Gradient>
);

export { Background };
