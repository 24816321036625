import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import { useDeleteDocumentMutation } from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocument($documentId: MigrateID!) {
    deleteDocument(id: $documentId) {
      id
    }
  }
`;

export const useDeleteDocument = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  const { openModal } = useModal();
  const [deleteDocument] = useDeleteDocumentMutation({
    update(cache) {
      const normalizedId = cache.identify({
        id,
        __typename: "Document",
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const withToast = useAsyncToast({
    success: { title: "Successfully deleted!" },
    error: { title: "Couldn't delete document" },
  });

  return () =>
    openModal("ConfirmModal", {
      title: "Confirm delete document",
      description: `Are you sure you want to delete your ${
        name ? `${name} document` : "document"
      }?`,
      confirmLabel: "Delete document",
      onConfirm: withToast(async () => {
        await deleteDocument({ variables: { documentId: id } });
      }),
    });
};
