import { differenceInMinutes, isSameDay } from "date-fns";
import { memo } from "react";
import isEqual from "react-fast-compare";

import { ChannelMessage } from "../types";
import { UserMessage } from "./user-message";
import { NonUserMessage } from "./non-user-message";

interface MessageProps {
  message: ChannelMessage;
  isMe: boolean;
  nextMessage?: ChannelMessage;
}

const Message: React.FC<MessageProps> = memo(
  ({ isMe, message, nextMessage }) => {
    if (message.deletedAt) return null;

    const isDifferentUser = message?.author?.id !== nextMessage?.author?.id;
    const isDifferentDay = !isSameDay(
      new Date(message.createdAt),
      new Date(nextMessage?.createdAt)
    );
    const isDifferentMoment =
      differenceInMinutes(
        new Date(message.createdAt),
        new Date(nextMessage?.createdAt)
      ) > 5;

    const isNewThread = isDifferentUser || isDifferentDay || isDifferentMoment;

    const messageProps = {
      message,
      isSameThread: !isNewThread,
    };

    return isMe ? (
      <UserMessage {...messageProps} />
    ) : (
      <NonUserMessage {...messageProps} />
    );
  },
  isEqual
);

Message.displayName = "Message";

export type { MessageProps };
export { Message };
