import { gql } from "@apollo/client";
import {
  MatchResponseType,
  useUnsaveJobMatchMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import {
  createRemoveMatchResponseOptimisticResponse,
  updateRemove,
} from "../cache";
import { MatchResponse } from "../types";

const UNSAVE_JOB_MATCH_MUTATION = gql`
  mutation UnsaveJobMatch($id: MigrateID!) {
    removeMatchResponse(input: { id: $id }) {
      ...JobMatchActionMatchResponseProps
    }
  }
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

interface UseUnsaveJobMatchProps {
  matchResponse: MatchResponse;
}

const useUnsaveJobMatch = () => {
  const [unsaveJobMatchMutation] = useUnsaveJobMatchMutation();

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Unable to unsave job",
      description: "Please try again or contact support",
    },
  });

  return withToast(
    async ({
      matchResponse: {
        id,
        job: { id: jobId },
      },
    }: UseUnsaveJobMatchProps) =>
      unsaveJobMatchMutation({
        variables: {
          id,
        },
        optimisticResponse: createRemoveMatchResponseOptimisticResponse({
          __typename: "GenericMatchResponse",
          jobId,
          type: MatchResponseType.Save,
          existingMatchResponseId: id,
        }),
        update: updateRemove(jobId),
      })
  );
};

export type { UseUnsaveJobMatchProps };
export { UNSAVE_JOB_MATCH_MUTATION, useUnsaveJobMatch };
