import { useState } from "react";
import { ButtonLink } from "app/design/button";
import { InputControl } from "app/components/form/input-control";
import { Form } from "app/components/form";
import { useAsyncToast } from "app/components/use-toast";
import { useYupForm, yup } from "app/utils/yup";
import { useAuth } from "app/use-auth";
import { pagesPath } from "app/lib/$path";

interface EmailStepProps {
  setEmail: (email: string) => void;
}

const useRequestPasswordReset = (onSuccess?: (email: string) => void) => {
  const { forgotPassword } = useAuth();
  const [loading, setLoading] = useState(false);

  const withToast = useAsyncToast({
    success: {
      title: "We've sent you a verification code",
      description: "Please check your email and enter the code below",
    },
    error: {
      title: "Couldn't reset password",
    },
    onError: () => setLoading(false),
    onSuccess: () => setLoading(false),
  });

  return [
    withToast(async (email: string) => {
      setLoading(true);
      await forgotPassword({ email });
      onSuccess?.(email);
    }),
    { loading },
  ] as const;
};

const schema = yup.object({
  email: yup.string().email().required(),
});

const EmailStep = ({ setEmail }: EmailStepProps) => {
  const [requestPasswordReset] = useRequestPasswordReset(setEmail);

  const { control, handleSubmit, formState } = useYupForm({
    schema,
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = ({ email }: { email: string }) =>
    requestPasswordReset(email);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputControl
          name="email"
          control={control}
          inputProps={{
            label: "Email",
            placeholder: "Enter email address",
            keyboardType: "email-address",
            textContentType: "emailAddress",
            autoComplete: "email",
          }}
        />

        <Form.SubmitButton
          containerSx={{ my: "$4" }}
          loading={formState.isSubmitting}
        >
          Continue
        </Form.SubmitButton>
      </Form>

      <ButtonLink
        variant="link"
        containerSx={{ mb: "$8", alignSelf: "center" }}
        textSx={{ textDecorationLine: "underline" }}
        href={pagesPath.login.$url()}
      >
        Know your password?
      </ButtonLink>
    </>
  );
};

export { EmailStep, useRequestPasswordReset };
