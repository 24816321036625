import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const useAsyncStorageEffect = (
  key: string,
  callback: (item: string | null) => void,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const effect = async () => {
      const item = await AsyncStorage.getItem(key);
      callback(item);
    };

    effect();
  }, dependencies);
};
