import { CareerExperienceType } from "app/types/generated/schema";
import { Button } from "app/design/button";

interface ToggleCareerExperienceTypeProps {
  type: CareerExperienceType;
  onChange: (type: CareerExperienceType) => void;
}
export const ToggleCareerExperienceType = ({
  type,
  onChange,
}: ToggleCareerExperienceTypeProps) => {
  const handleChange = () => {
    onChange(
      type === CareerExperienceType.CareerBreak
        ? CareerExperienceType.WorkExperience
        : CareerExperienceType.CareerBreak
    );
  };

  return (
    <Button
      containerSx={{ mb: "$3", alignSelf: "flex-start" }}
      variant="link"
      onPress={handleChange}
    >
      Switch to{" "}
      {type === CareerExperienceType.CareerBreak
        ? "work experience"
        : "career break"}
    </Button>
  );
};
