import { useDripsyTheme, View } from "dripsy";
import { FieldValues, Path } from "react-hook-form";

import { ToggleControl } from "app/components/form/toggle-control";
import { SearchStage } from "app/types/generated/schema";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";

import { StepHeading } from "./heading";

type SearchStageTuple = [SearchStage, string];

interface SearchStageStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
}

const SearchStageStep = <TFieldValues extends FieldValues>({
  control,
  name,
}: SearchStageStepProps<TFieldValues>) => {
  const { theme } = useDripsyTheme();

  const searchStages: SearchStageTuple[] = [
    [SearchStage.Asap, "As soon as possible"],
    [SearchStage.InTheNextFewMonths, "In the next few months"],
    [SearchStage.InTheNextYear, "In the next year"],
    [SearchStage.JustCurious, "Not looking, I'm just curious"],
  ];

  return (
    <>
      <StepHeading>When are you looking to start your next job?</StepHeading>
      <View
        sx={{
          marginTop: 0 - theme.space.$2,
          width: "100%",
          maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
        }}
      >
        {searchStages.map(([value, text]) => (
          <View
            sx={{
              marginTop: "$2",
            }}
            key={value}
          >
            <ToggleControl
              control={control}
              name={name}
              checkedValue={value}
              rules={{ required: true }}
            >
              {text}
            </ToggleControl>
          </View>
        ))}
      </View>
    </>
  );
};

export { SearchStageStep };
