import { View } from "dripsy";
import { ReactNode } from "react";
import {
  InputAccessoryView,
  useWindowDimensions,
  Platform,
} from "react-native";

interface InputAccessoryProps {
  nativeID: string;
  children: ReactNode;
}
export const InputAccessory = ({ nativeID, children }: InputAccessoryProps) => {
  const { width } = useWindowDimensions();
  if (Platform.OS !== "ios") return null;
  return (
    <InputAccessoryView nativeID={nativeID}>
      <View
        sx={{
          width,
          height: 48,
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "#F8F8F8",
          paddingHorizontal: 8,
        }}
      >
        {children}
      </View>
    </InputAccessoryView>
  );
};
