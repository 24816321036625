import React, { ComponentProps, createContext, useContext } from "react";
import { View, H4, Row, SxProp, styled, DripsyFinalTheme } from "dripsy";
import { Skeleton } from "native-base";
import { FCC } from "app/types/index";
import { UserHeaderFragmentFragment } from "app/types/generated/schema";
import { Card as BaseCard } from "app/design/card";
import { Avatar } from "app/design/avatar";
import {
  PressableCard as BasePressableCard,
  PressableCardProps as BasePressableCardProps,
} from "app/components/pressable-card";
import { Text } from "app/components/text";
import { checkExhaustiveness } from "app/types";
import { conditionalParam } from "app/utils";

export type GroupVariant = "none" | "first" | "middle" | "last";

const CardThemeContext = createContext<"light" | "dark">("dark");

const IS_FIRST_GROUP_SX = {
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
};

const IS_LAST_GROUP_SX = {
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
};

const getGroupSx = (variant?: GroupVariant) => {
  if (!variant || variant === "none") {
    return {};
  }
  if (variant === "first") {
    return IS_FIRST_GROUP_SX;
  }
  if (variant === "last") {
    return IS_LAST_GROUP_SX;
  }

  if (variant === "middle") {
    return {
      ...IS_FIRST_GROUP_SX,
      ...IS_LAST_GROUP_SX,
    };
  }
  checkExhaustiveness(variant);
};

export interface CardProps {
  sx?: SxProp;
  groupVariant?: GroupVariant;
  variant?: keyof DripsyFinalTheme["card"];
}

const Card: FCC<CardProps> = ({
  sx,
  groupVariant,
  children,
  variant = "default",
}) => {
  return (
    <BaseCard
      sx={{
        ...getGroupSx(groupVariant),
        ...sx,
      }}
      variant={variant}
    >
      {children}
    </BaseCard>
  );
};

export type PressableCardProps = Omit<
  BasePressableCardProps,
  "initialColor" | "activeColor"
> &
  CardProps;

const PressableCard: FCC<PressableCardProps> = ({
  children,
  variant = "default",
  groupVariant,
  ...props
}) => {
  return (
    <BasePressableCard
      variant={variant}
      sx={{ ...getGroupSx(groupVariant), ...props.sx }}
      {...props}
    >
      <CardThemeContext.Provider
        value={variant === "tertiary" ? "light" : "dark"}
      >
        {children}
      </CardThemeContext.Provider>
    </BasePressableCard>
  );
};

const Author = ({ author }: { author: UserHeaderFragmentFragment }) => {
  const theme = useContext(CardThemeContext);
  return (
    <Row sx={{ alignItems: "center", mb: "$3" }}>
      <Avatar
        userId={author.id}
        photoUrl={author.photoUrl}
        isActive={author.isActive}
        size={6}
      />
      <Text variants={["xs", theme]} sx={{ ml: 8, fontWeight: "500" }}>
        {author.forenames} asked
      </Text>
    </Row>
  );
};

const Title: FCC<ComponentProps<typeof H4>> = ({
  children,
  sx = {},
  ...rest
}) => {
  const theme = useContext(CardThemeContext);

  return (
    <H4
      sx={{
        mb: "$3",
        my: "$0",
        ...sx,
      }}
      {...conditionalParam({ variant: "headingLight" }, theme === "light")}
      {...rest}
    >
      {children}
    </H4>
  );
};

const Footer = styled(Row)({
  justifyContent: "space-between",
  alignItems: "flex-end",
});

const Content = styled(View)({
  mb: "$3",
});

const CardLoadingContent = () => {
  const theme = useContext(CardThemeContext);
  return (
    <>
      <Skeleton.Text
        lines={1}
        w={[240, 375]}
        maxWidth="100%"
        _line={{
          h: 4,
          mb: 3,
        }}
        {...conditionalParam(
          {
            startColor: "rgba(255, 255, 255, .2)",
            endColor: "rgba(255, 255, 255, .1)",
          },
          theme === "light"
        )}
      />
      <Skeleton.Text
        {...conditionalParam(
          {
            startColor: "rgba(255, 255, 255, .2)",
            endColor: "rgba(255, 255, 255, .1)",
          },
          theme === "light"
        )}
      />
    </>
  );
};

const CardLoading = ({
  sx,
  variant = "default",
  containerSx,
}: Pick<PressableCardProps, "sx" | "containerSx" | "variant">) => {
  return (
    <Card sx={{ ...sx, ...containerSx }} variant={variant}>
      <CardThemeContext.Provider
        value={variant === "tertiary" ? "light" : "dark"}
      >
        <CardLoadingContent />
      </CardThemeContext.Provider>
    </Card>
  );
};

export {
  CardThemeContext,
  Card,
  PressableCard,
  Author,
  Title,
  Content,
  Footer,
  CardLoading,
  CardLoadingContent,
};
