import { constructId } from "app/lib/$path";
import { useCurrentParams } from "./use-current-params";
import { useCurrentPathname } from "./use-current-pathname";

const useIdParam = (key: string) => {
  const params = useCurrentParams();
  const pathname = useCurrentPathname();

  if (!params[key]) return undefined;

  return constructId(key, params[key], pathname);
};

export { useIdParam };
