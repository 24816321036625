import { FieldValues, Path } from "react-hook-form";

import { ToggleControl } from "app/components/form/toggle-control";
import { StepComponentProps } from "app/components/step-screen-form";
import { StepHeading } from "app/features/onboarding/steps/heading";
import { yesNoOptions } from "../constants";
import { CareerQuizOption, CareerQuizStepContainer } from "./container";

interface QualifiedStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
}

const QualifiedStep = <TFieldValues extends FieldValues>({
  control,
  name,
}: QualifiedStepProps<TFieldValues>) => (
  <>
    <StepHeading>Are you a qualified healthcare professional?</StepHeading>
    <CareerQuizStepContainer>
      {yesNoOptions.map(([value, text]) => (
        <CareerQuizOption key={value}>
          <ToggleControl
            control={control}
            name={name}
            checkedValue={value}
            rules={{ required: true }}
          >
            {text}
          </ToggleControl>
        </CareerQuizOption>
      ))}
    </CareerQuizStepContainer>
  </>
);

export { QualifiedStep };
