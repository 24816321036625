import { FC, PropsWithChildren } from "react";

// Custom Type for a React functional component with props & children
type FCC<P = {}> = FC<PropsWithChildren<P>>;

const checkExhaustiveness = (x: never): never => {
  throw new Error("exhaustive check fails for: " + x);
};

type StringLiteral<T> = T extends string
  ? string extends T
    ? never
    : T
  : never;

/**
 * Use to assert guaranteed existence but when the compiler does not know.
 * If an element could be missing, then such a case must be handled appropriately.
 */
const guarantee = <T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};

export * from "./generated";
export * from "./interfaces";

export type { FCC, StringLiteral };
export { guarantee, checkExhaustiveness };
