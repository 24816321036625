import { gql } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { memo, useState } from "react";
import { AnimatePresence, MotiView } from "moti";
import { useAsyncStorageEffect } from "app/hooks/use-async-storage-effect";
import { Grid, GridItem } from "app/design/grid";
import { ScreenHeader } from "app/components/screen/header";
import { Screen } from "app/components/screen";
import { HighlightText } from "app/components/highlight-text";
import { DataError } from "app/components/data-error";
import { Layout } from "app/features/layout";
import { CandidateV2, useHomeScreenQuery } from "app/types";

import { useCurrentUser } from "app/hooks";
import { ScreenComponent } from "app/navigation/types";
import { MatchesOverview } from "../jobs/matches-overview";
import { CommunityQuestions } from "../community/screen";
import { TakeQuizCard } from "./career-quiz/take-quiz-card";
import { CareerStage } from "./career-quiz/career-stage";
import { ChannelActivity } from "./channel-activity";
import { CareerStageLoading } from "./career-quiz/career-stage.loading";

const HOME_SCREEN_QUERY = gql`
  query HomeScreen {
    me {
      id
      ... on CandidateV2 {
        hasSetPreferences
        profession {
          id
        }
        careerQuizResult
      }
    }
  }
`;

const IS_FIRST_VISIT = "IS_FIRST_VISIT";

const HomeScreenHeader = ({ username }: { username?: string }) => {
  const [isFirstVisit, setIsFirstVisit] = useState<boolean | null>(null);

  useAsyncStorageEffect(IS_FIRST_VISIT, async (_isFirstVisit) => {
    setIsFirstVisit(_isFirstVisit !== "false");
    await AsyncStorage.setItem(IS_FIRST_VISIT, "false");
  });

  const welcomeCopy = isFirstVisit ? "Welcome" : "Welcome back";

  if (!username) {
    return <ScreenHeader>{welcomeCopy}!</ScreenHeader>;
  }

  return (
    <AnimatePresence>
      {isFirstVisit !== null ? (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "timing" }}
        >
          <ScreenHeader.Container>
            <ScreenHeader.H1>{welcomeCopy}, </ScreenHeader.H1>
            <HighlightText>{username}!</HighlightText>

            <ScreenHeader.Divider />
          </ScreenHeader.Container>
        </MotiView>
      ) : null}
    </AnimatePresence>
  );
};

const HomeScreen: ScreenComponent = memo(
  () => {
    const { isCandidateV1, currentUser } = useCurrentUser();
    const { data, loading, error } = useHomeScreenQuery();

    if (error) return <DataError />;

    return (
      <Screen scrollable>
        <HomeScreenHeader username={currentUser?.forenames} />
        <Grid>
          {isCandidateV1 ? (
            <GridItem widths={[1, 1, 1 / 2]}>
              <MatchesOverview />
            </GridItem>
          ) : (
            <>
              <GridItem widths={[1, 1, 1 / 2]}>
                {(() => {
                  if (loading) return <CareerStageLoading />;

                  const { careerQuizResult, profession } =
                    data?.me as CandidateV2;

                  if (!careerQuizResult)
                    return <TakeQuizCard sx={{ width: "auto" }} />;

                  return (
                    <CareerStage
                      sx={{ width: "auto" }}
                      stage={careerQuizResult}
                      professionId={profession?.id}
                    />
                  );
                })()}
              </GridItem>
              <GridItem widths={[1, 1, 1 / 2]}>
                <MatchesOverview />
              </GridItem>
            </>
          )}
        </Grid>
        <CommunityQuestions renderAskButton={false} />
        <ChannelActivity />
      </Screen>
    );
  },
  () => true
);

const headerProps = {
  title: "Home",
  backButtonType: "none",
} as const;

HomeScreen.displayName = "HomeScreen";
HomeScreen.headerProps = headerProps;
HomeScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { HomeScreen, HOME_SCREEN_QUERY };
