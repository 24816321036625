import { Row, styled } from "dripsy";

const MessageActionList = styled(Row)({
  mx: (theme) => 0 - theme.space.$3,
  position: "absolute",
  top: -30,
  right: 0,
  borderRadius: 8,
  borderWidth: 1,
  borderColor: "$ui",
  bg: "$white",
  zIndex: 99,
});

export { MessageActionList };
