import { useDripsyTheme, View } from "dripsy";
import { lightenColor } from "app/design/utils";

const PaginationDots: React.FC<{
  currentIndex: number;
  numberOfSteps: number;
}> = ({ currentIndex, numberOfSteps }) => {
  const { theme } = useDripsyTheme();

  const dotTheme = {
    borderRadius: 8,
  };

  return (
    <View sx={{ flexDirection: "row", justifyContent: "center" }}>
      {[...new Array(numberOfSteps)].map((_, i) => (
        <View
          key={i}
          sx={{
            width: dotTheme.borderRadius,
            height: dotTheme.borderRadius,
            borderRadius: dotTheme.borderRadius,
            bg:
              currentIndex === i
                ? "$black"
                : lightenColor(theme.colors.$grey, 25),
            marginRight: i === numberOfSteps - 1 ? "$0" : "$2",
            marginBottom: "$4",
          }}
        />
      ))}
    </View>
  );
};

export { PaginationDots };
