import { ReactNode, useState } from "react";
import { Pressable, Sx, useSx } from "dripsy";
import { Platform, StyleSheet } from "react-native";
import { AnyFile } from "app/hooks/helpers/upload-file";
import { UploadFileActionSheet } from "./upload-file-action-sheet";

interface FileUploadWrapperProps {
  sx?: Sx;
  children: ReactNode;
  onUpload: (file: AnyFile) => Promise<void>;
  acceptTypes?: string | string[];
}

export const FileUploadWrapper = ({
  sx: _sx = {},
  acceptTypes = "*",
  onUpload,
  children,
}: FileUploadWrapperProps) => {
  const [open, setOpen] = useState(false);
  const sx = useSx();

  return (
    <Pressable
      sx={_sx}
      onPress={Platform.OS !== "web" ? () => setOpen(true) : () => {}}
    >
      {Platform.OS === "web" ? (
        <label
          style={{
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            style={sx({
              opacity: 0,
              ...StyleSheet.absoluteFillObject,
            })}
            tabIndex={-1}
            onChange={(event) => {
              const file = event.target.files?.[0];
              if (!file) return;
              onUpload(file);
            }}
            accept={
              typeof acceptTypes === "string"
                ? acceptTypes
                : acceptTypes.join(",")
            }
          />
          {children}
        </label>
      ) : (
        <>
          <UploadFileActionSheet
            isOpen={open}
            onClose={() => setOpen(false)}
            onSelectFile={onUpload}
            type={acceptTypes}
          />
          {children}
        </>
      )}
    </Pressable>
  );
};
