import { View, SxProp, useDripsyTheme, styled } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { Tooltip } from "native-base";
import { ComponentProps } from "react";
import { MotiPressable } from "moti/interactions";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";

const DripsyMotiPressable = styled(MotiPressable)();

interface MessageActionProps {
  sx?: SxProp;
  label: string;
  iconName: ComponentProps<typeof Feather>["name"];
  onPress: (args?: unknown) => void;
}

const MessageAction = ({
  sx = {},
  label,
  iconName,
  onPress,
}: MessageActionProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Tooltip label={label} placement="top">
      {/* hack because MotiPressable doesn't like being directly wrapped w Tooltip */}
      <View>
        <DripsyMotiPressable sx={{ ...sx, p: "$2" }} onPress={onPress}>
          <ColorAnimatedIcon
            icon={<Feather name={iconName} size={16} />}
            colorStates={{
              color: {
                states: [["hovered", theme.colors.$orange]],
                initialColor: theme.colors.$black,
              },
            }}
          />
        </DripsyMotiPressable>
      </View>
    </Tooltip>
  );
};

export type { MessageActionProps };
export { MessageAction };
