import { ReactNode } from "react";
import { Button, ButtonProps } from "app/design/button";

export const DEFAULT_DOCUMENT_ACCEPT_TYPES = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export interface UploadButtonProps extends ButtonProps {
  onPress: () => void;
  loading?: boolean;
  label?: string;
}

export type RenderUploadButtonFunction = (
  props: UploadButtonProps
) => ReactNode;

export const defaultRenderUploadButton: RenderUploadButtonFunction = ({
  loading,
  label,
  onPress,
  ...rest
}: UploadButtonProps) => (
  <Button loading={loading} onPress={onPress} {...rest}>
    {label || "Upload document"}
  </Button>
);
