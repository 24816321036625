import { Row, View } from "dripsy";
import { useAnswerApplicationQuestionMutation } from "app/types/generated/schema";
import { Button } from "app/design/button";
import { TextareaControl } from "app/components/form/textarea-control";
import { useYupForm, yup } from "app/utils/yup";
import { useProfileCardContext } from "app/features/profile/card/context";
import {
  generateTempId,
  isTempField,
  ProfileForm,
} from "app/features/profile/forms/profile-form";

interface ApplicationQuestionFormValues {
  id: string;
  response: string;
}

interface ApplicationQuestionFormCardProps {
  matchResponseId: string;
  question: string;
  defaultValues: ApplicationQuestionFormValues;
  onCancel: () => void;
}

const DEFAULT_VALUE = {
  response: "",
};

const schema = yup.object({
  response: yup.string().min(10).required().label("Question response"),
});

const ApplicationQuestionFormCard = ({
  matchResponseId,
  question,
  defaultValues,
  onCancel,
}: ApplicationQuestionFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const [answerApplicationQuestion] = useAnswerApplicationQuestionMutation();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useYupForm<ApplicationQuestionFormValues>({
    schema,
    defaultValues,
  });

  const id = watch("id");
  const isUpdating = !isTempField({ id });

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };
  const onSubmit = async (values: ApplicationQuestionFormValues) => {
    const { response } = values;

    try {
      await answerApplicationQuestion({
        variables: {
          input: {
            matchResponseId,
            response,
          },
        },
      });

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileForm label="application question" onCancel={onClose}>
      <View>
        <TextareaControl
          control={control}
          name="response"
          inputProps={{
            label: question,
            labelSx: { mb: "$3" },
            placeholder: "Type your answer here...",
          }}
        />
      </View>

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

ApplicationQuestionFormCard.defaultValue = {
  id: generateTempId("application-question"),
  ...DEFAULT_VALUE,
};

export type { ApplicationQuestionFormValues, ApplicationQuestionFormCardProps };
export { ApplicationQuestionFormCard };
