import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  useCreateRegistrationDetailMutation,
  useDeleteRegistrationDetailMutation,
  useUpdateRegistrationDetailMutation,
  useRegistrationDetailFormQuery,
  RegistrationDetailV2,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const CREATE_REGISTRATION_DETAIL_EXPERIENCE_MUTATION = gql`
  mutation CreateRegistrationDetail($input: CreateRegistrationDetailInput!) {
    createRegistrationDetail(input: $input) {
      id
      registrationDetails {
        id
        country
        countryInfo {
          name
        }
        referenceNumber
      }
      profileCompletion
    }
  }
`;

export const UPDATE_REGISTRATION_DETAIL_EXPERIENCE_MUTATION = gql`
  mutation UpdateRegistrationDetail($input: UpdateRegistrationDetailInput!) {
    updateRegistrationDetail(input: $input) {
      id
      registrationDetails {
        id
        country
        countryInfo {
          name
        }
        referenceNumber
      }
      profileCompletion
    }
  }
`;

export const DELETE_REGISTRATION_DETAIL_EXPERIENCE_MULTATION = gql`
  mutation DeleteRegistrationDetail($input: DeleteRegistrationDetailInput!) {
    deleteRegistrationDetail(input: $input) {
      id
      registrationDetails {
        id
        country
        countryInfo {
          name
        }
        referenceNumber
      }
      profileCompletion
    }
  }
`;

export const useRegistrationDetailMutations = (id?: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete registration detail",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useRegistrationDetailFormQuery();
  if (error !== undefined)
    throw new Error(
      "unexpected error in useRegistrationDetailMutations: " + error.message
    );

  const [createRegistrationDetail] = useCreateRegistrationDetailMutation();
  const [updateRegistrationDetail] = useUpdateRegistrationDetailMutation();
  const [deleteRegistrationDetail] = useDeleteRegistrationDetailMutation({
    update: (cache) => {
      cache.modify({
        id: `Candidate:${data?.me.id}`,
        fields: {
          registrationDetails(existing) {
            return {
              ...existing,
              data: existing.data.filter(
                (registrationDetail: RegistrationDetailV2) =>
                  registrationDetail.id !== id
              ),
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteRegistrationDetail: {
        ...(data?.me as CandidateV2),
      },
    },
  });

  return {
    createRegistrationDetail,
    updateRegistrationDetail,
    deleteRegistrationDetail: () =>
      openModal("ConfirmModal", {
        title: "Confirm delete registration entry",
        description: "Are you sure you want to delete this registration entry?",
        confirmLabel: "Delete registration",
        onConfirm: withToast(async () => {
          if (!id)
            throw new Error("registration detail: id required for deletion");
          await deleteRegistrationDetail({ variables: { input: { id } } });
        }),
      }),
  };
};
