import Head from "next/head";

interface MetaProps {
  title: string;
}

const Meta = ({ title }: MetaProps) => (
  <Head>
    <title>{title}</title>
  </Head>
);

export { Meta };
