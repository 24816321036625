import { Row, View } from "dripsy";
import { isAfter } from "date-fns";
import { CareerExperienceType } from "app/types/generated/schema";
import { Button } from "app/design/button";
import { CheckboxControl } from "app/components/form/checkbox-control";
import { InputControl } from "app/components/form/input-control";
import { MonthYearControl } from "app/components/form/month-year-control";
import { AnimateHeight } from "app/components/animate-height";
import { BulletedInputControl } from "app/components/form/bulleted-input-control";
import { useYupForm, yup } from "app/utils/yup";
import { useProfileCardContext } from "../../../card/context";
import { useCareerExperienceMutations } from "../../hooks/use-career-experience-mutations";
import { generateTempId, isTempField, ProfileForm } from "../../profile-form";
import { ToggleCareerExperienceType } from "../toggle-career-experience-type";

interface CareerBreakFormValues {
  id: string;
  type: CareerExperienceType;
  reason: string;
  startDate: string;
  endDate: string;
  description: string[];
  // meta
  hideEndDate: boolean;
}

interface CareerBreakFormCardProps {
  defaultValues: CareerBreakFormValues;
  onChangeType: (type: CareerExperienceType) => void;
  onCancel: () => void;
  mode?: "create" | "update";
}

const DEFAULT_VALUE = {
  type: CareerExperienceType.CareerBreak,
  reason: "",
  startDate: "",
  endDate: "",
  description: [],
  hideEndDate: true,
};

const schema = yup.object({
  reason: yup.string().required().label("Reason"),
  startDate: yup.string().required().label("Start date"),
  hideEndDate: yup.boolean(),
  endDate: yup
    .string()
    .when("hideEndDate", (hideEndDate, _schema) =>
      !hideEndDate ? _schema.required() : _schema
    )
    .test(
      "test-after-start-date",
      "End date should be after start date",
      (endDate: string | undefined, context) => {
        const { startDate } = context.parent;
        if (!startDate || !endDate) return true;
        return isAfter(new Date(endDate), new Date(startDate));
      }
    )
    .label("End date"),
  description: yup.array().of(yup.string()).required().label("Description"),
});

const sanitizeCareerBreakValues = (createInput: CareerBreakFormValues) => {
  const sanitizedInput = {
    id: createInput.id,
    reason: createInput.reason.trim(),
    startDate: createInput.startDate,
    endDate: createInput.endDate || null,
    description: createInput.description.map((content) => ({ content })),
  };
  return sanitizedInput;
};

const CareerBreakFormCard = ({
  onChangeType,
  onCancel,
  defaultValues,
}: CareerBreakFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const { createCareerBreak, updateCareerBreak } =
    useCareerExperienceMutations();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useYupForm<CareerBreakFormValues>({
    schema,
    defaultValues,
  });

  const [fieldId, hideEndDate, type] = watch(["id", "hideEndDate", "type"]);
  const isUpdating = !isTempField({ id: fieldId });

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };

  const onSubmit = async (values: CareerBreakFormValues) => {
    const { id, ...sanitizedInput } = sanitizeCareerBreakValues(values);

    try {
      if (isUpdating) {
        if (!id) throw new Error("work experience: id is required for update");

        await updateCareerBreak({
          variables: {
            input: {
              ...sanitizedInput,
              id,
            },
          },
        });
      } else {
        await createCareerBreak({
          variables: {
            input: sanitizedInput,
          },
        });
      }

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileForm label="career break" onCancel={onClose}>
      <View sx={{ maxWidth: [null, 420] }}>
        {!isUpdating ? (
          <ToggleCareerExperienceType type={type} onChange={onChangeType} />
        ) : null}

        <InputControl
          control={control}
          name={"reason"}
          inputProps={{
            label: "Reason for break",
            placeholder:
              "E.g. parenting,  professional development, relocation...",
          }}
        />

        <MonthYearControl
          label="Start date"
          name="startDate"
          control={control}
        />

        <View sx={{ mb: "$3" }}>
          <CheckboxControl
            control={control}
            name="hideEndDate"
            checkboxProps={{
              "aria-label": "Is currently on career break",
              label: "I'm currently on this career break",
            }}
          />
        </View>

        <AnimateHeight hide={!!hideEndDate}>
          <MonthYearControl label="End date" name="endDate" control={control} />
        </AnimateHeight>
      </View>

      <BulletedInputControl
        control={control}
        name="description"
        inputProps={{
          label: "Description",
          containerSx: { mb: "$3" },
          sx: { minHeight: 87, maxHeight: 200 },
          placeholderStrings: ["During this break in my career I..."],
        }}
      />

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

CareerBreakFormCard.createInstance = () => ({
  id: generateTempId("career-experience"),
  ...DEFAULT_VALUE,
});

export type { CareerBreakFormValues, CareerBreakFormCardProps };
export { CareerBreakFormCard };
