import { useDripsyTheme } from "dripsy";
import { motify } from "moti";
import { useMotiPressable } from "moti/interactions";
import { Feather } from "@expo/vector-icons";

const MotiFeather = motify(Feather)();

export const AnimatedArrow: React.FC<{ size?: number }> = ({ size = 24 }) => {
  const { theme } = useDripsyTheme();

  const state = useMotiPressable(({ hovered, pressed }) => {
    "worklet";
    return {
      translateX: hovered || pressed ? 4 : 0,
    };
  });

  return (
    <MotiFeather
      color={theme.colors.$blue}
      name="arrow-right"
      size={size}
      state={state}
      transition={{
        type: "spring",
      }}
    />
  );
};
