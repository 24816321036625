import { useState } from "react";

interface DataState<T> {
  loading: boolean;
  data: T | null;
  error: any | null;
}

export const useData = <T,>() => {
  const [state, setState] = useState<DataState<T>>({
    data: null,
    loading: false,
    error: null,
  });

  const set = (nextState: Partial<DataState<T>>) =>
    setState({
      ...state,
      ...nextState,
    });

  return [state, set] as const;
};
