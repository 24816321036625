import { QueryHookOptions, QueryResult } from "@apollo/client";

type TypedApolloHook<TQueryResponse, TVariables> = (
  baseOptions: QueryHookOptions<TQueryResponse, TVariables>
) => QueryResult<TQueryResponse, TVariables>;

type UseDetailedQuestionsHook<TQueryResponse, TVariables> = TypedApolloHook<
  TQueryResponse,
  TVariables
>;

const useDetailedQuestions = <TQueryResponse, TVariables>(
  query: UseDetailedQuestionsHook<TQueryResponse, TVariables>,
  variables: TVariables,
  params?: { skip?: boolean }
) => {
  const { data, fetchMore, networkStatus, error } = query({
    variables,
    notifyOnNetworkStatusChange: true,
    ...params,
  });

  return {
    data,
    error,
    fetchMore,
    networkStatus,
  };
};

export { useDetailedQuestions };
