import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  Reference,
} from "@apollo/client";
import { Modifier } from "@apollo/client/cache/core/types/common";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import {
  CandidateMatchResponseType,
  Exact,
  JobMatchActionMatchResponsePropsFragment,
  MatchedJobsQuery,
  MyJobsQuery,
  Scalars,
} from "app/types";
import { toCacheReference } from "app/utils";

type ModifierHelpers = Parameters<Modifier<any>>[1];

type MatchResponseTypenames = Exclude<
  JobMatchActionMatchResponsePropsFragment["__typename"],
  undefined
>;

const generateOptimisticTempId = (jobId: Scalars["MigrateID"]) =>
  "optimisticMatchResponse::" + jobId + "::" + Date.now();

interface CreateRespondToMatchOptimisticResponseInput<
  TTypename extends MatchResponseTypenames
> {
  __typename: TTypename;
  jobId: Scalars["MigrateID"];
  type: CandidateMatchResponseType;
  existingMatchResponseId?: Scalars["MigrateID"];
}

const createRespondToMatchOptimisticResponse = <
  TTypename extends MatchResponseTypenames
>({
  __typename,
  jobId,
  type,
  existingMatchResponseId,
}: CreateRespondToMatchOptimisticResponseInput<TTypename>) => {
  const id = existingMatchResponseId ?? generateOptimisticTempId(jobId);
  const createdAt = new Date().toISOString();
  return {
    respondToMatch: {
      __typename,
      id,
      candidate: {
        __typename: "CandidateV2",
        id: "candidateId",
      },
      job: {
        __typename: "Job",
        id: jobId,
        myMatchResponse: {
          __typename: "GenericMatchResponse",
          id,
          type,
          createdAt: createdAt,
          updatedAt: createdAt,
        },
      },
      createdAt: createdAt,
      type,
    },
  } as const;
};

const removeCacheItemFromArray =
  ({
    cache,
    isReference,
  }: {
    cache: ApolloCache<DefaultContext>;
    isReference: (obj: any) => obj is Reference;
  }) =>
  <
    TItem extends { id: Scalars["MigrateID"] },
    TSearchItem extends {
      id: Scalars["MigrateID"];
    }
  >(
    item: TSearchItem,
    array: TItem[] = [],
    extractFunction?: (arrayItem: TItem) => TSearchItem | undefined | null
  ) => {
    return [...array].filter((arrayItem) => {
      const extractedItem = extractFunction
        ? extractFunction(arrayItem)
        : arrayItem;

      if (isReference(extractedItem)) {
        return extractedItem.__ref !== cache.identify(item);
      } else {
        return extractedItem?.id !== item.id;
      }
    });
  };

interface UpdateMyMatchResponses<
  TMatchResponse extends JobMatchActionMatchResponsePropsFragment
> {
  cache: ApolloCache<any>;
  matchResponseType: CandidateMatchResponseType;
  matchResponse: TMatchResponse;
}

const updateMyMatchResponses =
  <TMatchResponse extends JobMatchActionMatchResponsePropsFragment>({
    cache,
    matchResponseType,
    matchResponse,
  }: UpdateMyMatchResponses<TMatchResponse>) =>
  (
    matchResponsePage: MyJobsQuery["myMatchResponses"],
    { storeFieldName, isReference }: ModifierHelpers
  ) => {
    const newMatchResponseRef = cache.writeFragment({
      data: matchResponse,
      fragment: JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS,
    });

    if (storeFieldName.includes(matchResponseType)) {
      const data = [...matchResponsePage.data, newMatchResponseRef];
      return {
        ...matchResponsePage,
        data,
        count: data.length,
      };
    } else {
      const filteredData = removeCacheItemFromArray({
        cache,
        isReference,
      })(matchResponse, matchResponsePage.data);

      return {
        ...matchResponsePage,
        data: filteredData,
        count: filteredData.length,
      };
    }
  };

interface UpdateMyMatchedJobsProps<
  TMatchResponse extends JobMatchActionMatchResponsePropsFragment
> {
  cache: ApolloCache<any>;
  matchResponse: TMatchResponse;
}

const updateMyMatchedJobs =
  <TMatchResponse extends JobMatchActionMatchResponsePropsFragment>({
    cache,
    matchResponse,
  }: UpdateMyMatchedJobsProps<TMatchResponse>) =>
  (
    jobPage: MatchedJobsQuery["myMatchedJobs"],
    { isReference }: ModifierHelpers
  ) => {
    const filteredData = removeCacheItemFromArray({
      cache,
      isReference,
    })(matchResponse.job, jobPage.data);

    return {
      ...jobPage,
      count: filteredData.length,
      data: filteredData,
    };
  };

interface CreateRemoveOptimisticResponseInput<
  TTypename extends MatchResponseTypenames
> {
  __typename: TTypename;
  jobId: Scalars["MigrateID"];
  type: CandidateMatchResponseType;
  existingMatchResponseId: Scalars["MigrateID"];
}

const createRemoveMatchResponseOptimisticResponse = <
  TTypename extends MatchResponseTypenames
>({
  __typename,
  jobId,
  type,
  existingMatchResponseId,
}: CreateRemoveOptimisticResponseInput<TTypename>) => {
  return {
    removeMatchResponse: {
      __typename,
      id: existingMatchResponseId,
      candidate: {
        __typename: "CandidateV2",
        id: "candidateId",
      },
      job: {
        __typename: "Job",
        id: jobId,
        myMatchResponse: null,
      },
      createdAt: new Date().toISOString(),
      type,
    } as const,
  };
};

const updateRemove =
  (
    jobId: Scalars["MigrateID"]
  ): MutationUpdaterFunction<
    {
      removeMatchResponse: JobMatchActionMatchResponsePropsFragment;
    },
    Exact<{
      id: string;
    }>,
    DefaultContext,
    ApolloCache<any>
  > =>
  (cache, { data }) => {
    if (!data) return;

    const jobRef = toCacheReference({
      __typename: "Job",
      id: jobId,
    });

    cache.modify({
      fields: {
        myMatchedJobs(
          jobPage: MatchedJobsQuery["myMatchedJobs"],
          { isReference }
        ) {
          // Filter out first to avoid duplicates from
          // repeat toggles, then reinsert into the list
          const filteredData = removeCacheItemFromArray({
            cache,
            isReference,
          })(data.removeMatchResponse.job, jobPage.data);

          const newData = [...filteredData, jobRef];
          // Update count but do not append to keep unsaved item
          // in match list when user is viewing.
          return {
            ...jobPage,
            count: newData.length,
          };
        },
      },
    });

    cache.evict({ id: cache.identify(data.removeMatchResponse) });
    cache.gc();
  };

export {
  updateMyMatchResponses,
  updateMyMatchedJobs,
  updateRemove,
  createRemoveMatchResponseOptimisticResponse,
  createRespondToMatchOptimisticResponse,
  removeCacheItemFromArray,
};
