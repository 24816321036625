import { gql } from "@apollo/client";
import { useJoinCommunityChannelMutation } from "app/types/generated/schema";
import { Button } from "app/design/button";

export const JOIN_COMMUNITY_CHANNEL_MUTATION = gql`
  mutation JoinCommunityChannel($input: JoinChannelInput!) {
    joinChannel(input: $input) {
      channel {
        id
        isMember
      }
    }
  }
`;

export const useJoinChannel = (channelId: string) => {
  const [joinChannelAPI, { loading }] = useJoinCommunityChannelMutation({
    variables: { input: { channelId } },
  });

  const joinChannelMutation = () =>
    joinChannelAPI({
      variables: {
        input: {
          channelId,
        },
      },
      update: (cache, { data }) => {
        if (!data) return;
        cache.evict({ id: "ROOT_QUERY", fieldName: "joinedChannels" });
      },
    });

  return [joinChannelMutation, { loading }] as const;
};

interface JoinChannelButtonProps {
  channelId: string;
}

export const JoinChannelButton = ({ channelId }: JoinChannelButtonProps) => {
  const [joinChannel, { loading }] = useJoinChannel(channelId);
  return (
    <Button loading={loading} onPress={joinChannel}>
      Join channel
    </Button>
  );
};
