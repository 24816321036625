import { H5, Row, View } from "dripsy";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import { DividerSecondary } from "./divider";

interface ShareProps {
  title: string;
  jobId: string;
}
const Share = ({ jobId, title }: ShareProps) => {
  const iconProps = {
    size: 40,
    borderRadius: 8,
  };

  const url = `https://app.migratehr.com/jobs/${jobId}`;
  const checkThisOut = `Check out this ${title} job`;
  const migrateCopy = `Migrate | a job board and community for international healthcare professionals.`;

  return (
    <>
      <DividerSecondary />
      <View sx={{ mt: "$6", mb: "$2", alignItems: "center" }}>
        <H5 sx={{ mb: "$3" }}>Share this job</H5>
        <Row sx={{ gap: "$2" }}>
          <FacebookShareButton url={url} quote={checkThisOut}>
            <FacebookIcon size={40} borderRadius={8} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={checkThisOut}>
            <TwitterIcon {...iconProps} />
          </TwitterShareButton>
          <WhatsappShareButton
            url={url}
            title={`${checkThisOut}\n\n${migrateCopy}`}
          >
            <WhatsappIcon {...iconProps} />
          </WhatsappShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon {...iconProps} />
          </LinkedinShareButton>
          <EmailShareButton url={url} subject={checkThisOut} body={migrateCopy}>
            <EmailIcon {...iconProps} />
          </EmailShareButton>
        </Row>
      </View>
    </>
  );
};

export { Share };
