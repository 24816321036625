import Head from "next/head";
import { JobDetailsQuery, PublicationStatus } from "app/types";

interface JobDetailsMetaProps {
  job: JobDetailsQuery["job"];
}

const JobDetailsMeta = ({
  job: {
    description,
    title,
    organisation,
    mapLocation,
    createdAt,
    salary,
    status,
  },
}: JobDetailsMetaProps) => {
  return (
    <Head>
      <title>{title} - Migrate</title>
      {description && (
        <>
          <meta name="description" content={description} />
          <meta name="og:description" content={description} />
        </>
      )}
      {status !== PublicationStatus.Published && (
        <meta name="robots" content="noindex" />
      )}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "JobPosting",
          title,
          description: `<p>${description}</p>`,
          employmentType: "FULL_TIME",
          hiringOrganization: {
            "@type": "Organization",
            name: organisation.name,
            sameAs: organisation.url,
          },
          datePosted: createdAt,
          ...(mapLocation && {
            jobLocation: {
              "@type": "Place",
              address: mapLocation.name,
              latitude: mapLocation.location.lat,
              longitude: mapLocation.location.lon,
            },
          }),
          ...(salary?.[0] && {
            baseSalary: {
              "@type": "MonetaryAmount",
              currency: "GBP",
              value: {
                "@type": "QuantitativeValue",
                ...(salary?.[1]
                  ? {
                      minValue: salary[0],
                      maxValue: salary[1],
                    }
                  : { value: salary[0] }),
                unitText: "YEAR",
              },
            },
          }),
        })}
      </script>
    </Head>
  );
};

export { JobDetailsMeta };
