import { ChannelMessage } from "../../types";
import { useChatStore } from "../../hooks/use-chat-store";

interface UseReplyToMessageProps {
  message: ChannelMessage;
}

const useReplyToMessage = ({ message }: UseReplyToMessageProps) => {
  const { setReplyingTo } = useChatStore();
  return () => setReplyingTo(message);
};

export type { UseReplyToMessageProps };
export { useReplyToMessage };
