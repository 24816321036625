import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Candidate {
    workExperiences: [WorkExperience]
  }

  extend type Query {
    englishSpeakingCountries: [String]!
    redListCountries: [String]!
    tags: [Tag!]!
  }

  extend type Message {
    sendError: Boolean!
  }

  extend type Attachment {
    isUploading: Boolean!
    localFile: FileFromFilesystem
  }

  type FileFromFilesystem {
    uri: String!
    name: String
  }
`;
