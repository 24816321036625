import * as Sentry from "@sentry/nextjs";
import { SENTRY_CONFIG } from "./config";

// initialised in apps/next/sentry.*.config.ts
const initSentry = () => {
  Sentry.init(SENTRY_CONFIG);
};

const captureException = Sentry.captureException;
const captureMessage = Sentry.captureMessage;

export { captureException, captureMessage, Sentry, initSentry };
