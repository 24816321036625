import { Alert, useAlertTextProps } from "app/design/alert";
import { HyperlinkText } from "app/components/hyperlink-text";

interface RejectedAlertProps {
  reason: string;
}

export const RejectedAlert = ({ reason }: RejectedAlertProps) => {
  const sx = useAlertTextProps("error");

  return (
    <Alert sx={{ mb: "$5" }} variant="error">
      <HyperlinkText sx={sx}>{reason}</HyperlinkText>
    </Alert>
  );
};
