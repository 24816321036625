import {
  ComponentProps,
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDripsyTheme, View } from "dripsy";
import { AnimatePresence, MotiView } from "moti";
import { Stack } from "expo-router";
import create from "zustand";
import { Platform } from "react-native";
import { Grid, GridItem } from "app/design/grid";
import { useCurrentPathname, useScreenSize } from "app/hooks";
import { ScreenComponent as ScreenComponentType } from "app/navigation/types";
import { FCC } from "app/types";
import { Sidebar } from "./sidebar";
import { Header, HeaderProps } from "./header";
import { Tabs } from "./tabs";
import { Stones } from "./stones";
import { Background } from "./background";
import { Meta } from "./meta";

type LayoutStore = {
  showTitleInHeader: boolean;
  setShowTitleInHeader: (showTitleInHeader: boolean) => void;
};

const layoutStore = (set: (values: Partial<LayoutStore>) => void) => ({
  showTitleInHeader: false,
  setShowTitleInHeader: (showTitleInHeader: boolean) =>
    set({ showTitleInHeader }),
});

const useLayoutStore = create<LayoutStore>(layoutStore);

interface LayoutProps {
  headerProps: HeaderProps;
  scrollable?: boolean;
  /**
   * passed from related component containers to avoid
   * fading between routes
   */
  overridePathname?: string | null;
  children: ReactNode;
  meta?: ReactNode;
}

interface FadeProps {
  viewKey: string;
  children: ReactNode;
}

const Fade = ({ viewKey, children }: FadeProps) => {
  const { theme } = useDripsyTheme();

  return (
    <AnimatePresence exitBeforeEnter>
      <MotiView
        key={viewKey}
        from={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ flex: 1 }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
      >
        {children}
      </MotiView>
    </AnimatePresence>
  );
};

const Layout = ({
  headerProps,
  children,
  overridePathname = null,
  meta = <Meta title={`${headerProps.title} - Migrate`} />,
}: LayoutProps) => {
  // TODO: We need to see if any params have changed too for the below
  // transitions to work - not sure how to do this
  const pathname = useCurrentPathname();
  const { showTitleInHeader, setShowTitleInHeader } = useLayoutStore();

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setShowTitleInHeader(false);
  }, [pathname]);

  const body = useMemo(
    () => <Fade viewKey={overridePathname || pathname}>{children}</Fade>,
    [pathname, overridePathname]
  );

  return (
    <Background>
      <Stones />
      <Header
        {...headerProps}
        showTitleOnDesktop={headerProps.showTitleOnDesktop || showTitleInHeader}
      />
      {meta}
      <View sx={{ flex: 1, flexDirection: "row" }}>
        <Sidebar />
        {body}
      </View>
      {Platform.OS === "web" && <Tabs />}
    </Background>
  );
};

interface NativeScreenProps {
  headerProps: HeaderProps;
  config: ComponentProps<typeof Stack.Screen>;
}
const NativeScreen = ({
  headerProps = { title: "" },
  config,
}: NativeScreenProps) => {
  const { isMobile } = useScreenSize();

  return (
    <Stack.Screen
      options={{
        header: () => (
          <View sx={{ bg: !isMobile ? "$cream" : undefined }}>
            <Header {...headerProps} />
          </View>
        ),
      }}
      {...config}
    />
  );
};

const renderScreen = (
  ScreenComponent: React.ComponentType & Pick<NativeScreenProps, "headerProps">,
  config: NativeScreenProps["config"] = {}
) => (
  <>
    <NativeScreen headerProps={ScreenComponent.headerProps} config={config} />
    <Background>
      <View sx={{ flex: 1, flexDirection: "row" }}>
        <Sidebar />
        <ScreenComponent />
      </View>
    </Background>
  </>
);

interface ScreenWrapperProps {
  ScreenComponent: ScreenComponentType;
  config?: NativeScreenProps["config"];
}

const ScreenWrapper = memo(
  ({ ScreenComponent, config = {} }: ScreenWrapperProps) => (
    <>
      <NativeScreen
        headerProps={ScreenComponent.headerProps as HeaderProps}
        config={config}
      />
      <Background>
        <View sx={{ flex: 1, flexDirection: "row" }}>
          <Sidebar />
          <ScreenComponent />
        </View>
      </Background>
    </>
  ),
  () => true
);
ScreenWrapper.displayName = "ScreenWrapper";

const HalfDesktopWrapper: FCC = ({ children }) => (
  <Grid>
    <GridItem widths={[1, 1, 1 / 2]}>{children}</GridItem>
  </Grid>
);

export type { LayoutProps };
export {
  Layout,
  renderScreen,
  ScreenWrapper,
  useLayoutStore,
  HalfDesktopWrapper,
};
