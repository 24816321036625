import { Text } from "dripsy";
import { ReactNode } from "react";
import { Button } from "app/design/button";
import { ProfileCardContainer } from "./index";

export interface ProfileCardEmptyProps {
  description: string;
  children?: ReactNode;
}

const ProfileCardEmpty = ({ description, children }: ProfileCardEmptyProps) => {
  return (
    <ProfileCardContainer>
      <Text variant="sm" sx={{ mb: "$4" }}>
        {description}
      </Text>
      {children}
    </ProfileCardContainer>
  );
};

interface DefaultProfileCardEmptyProps
  extends Omit<ProfileCardEmptyProps, "children"> {
  callToAction: string;
  onPress: () => void;
}

const DefaultProfileCardEmpty = ({
  description,
  callToAction,
  onPress,
}: DefaultProfileCardEmptyProps) => {
  return (
    <ProfileCardEmpty description={description}>
      <Button
        variant="secondary"
        onPress={onPress}
        containerSx={{ alignSelf: "flex-start" }}
      >
        {callToAction}
      </Button>
    </ProfileCardEmpty>
  );
};

export { DefaultProfileCardEmpty, ProfileCardEmpty };
