import { useDripsyTheme, useSx, View } from "dripsy";
import { useEffect, useRef } from "react";
import { FCC } from "app/types";
import { useEffectAfterRender } from "app/hooks";
import { AnimateHeightProps } from "./animate-height";

const AnimateHeight: FCC<AnimateHeightProps> = ({
  hide,
  sx: _sx = {},
  children,
}) => {
  const sx = useSx();
  const containerRef = useRef<HTMLDivElement>(null);
  const { theme } = useDripsyTheme();

  const duration = theme.transitionDurations.slow;
  const transition = `opacity ${duration}ms, height ${duration}ms`;

  useEffect(() => {
    if (containerRef.current === null || !hide) return;

    const element = containerRef.current;
    element.style.height = "0px";
    element.style.opacity = "0";
    element.style.display = "none";
  }, []);

  useEffectAfterRender(() => {
    if (containerRef.current === null) return;

    const element = containerRef.current;

    if (hide) {
      const expandedHeight = element.scrollHeight;

      requestAnimationFrame(() => {
        element.style.height = `${expandedHeight}px`;
        element.style.transition = transition;

        requestAnimationFrame(() => {
          element.style.height = "0px";
          element.style.opacity = "0";

          const handleHideEnd = (e: TransitionEvent) => {
            if (e.target !== element) return;

            element.removeEventListener("transitionend", handleHideEnd);
            element.style.display = "none";
          };

          element.addEventListener("transitionend", handleHideEnd);
        });
      });
    } else {
      element.style.display = "block";

      requestAnimationFrame(() => {
        const expandedHeight = element.scrollHeight;

        requestAnimationFrame(() => {
          element.style.height = `${expandedHeight}px`;
          element.style.opacity = "1";

          const handleShowEnd = (e: TransitionEvent) => {
            if (e.target !== element) return;

            element.removeEventListener("transitionend", handleShowEnd);
            element.style.height = "auto";
          };

          element.addEventListener("transitionend", handleShowEnd);
        });
      });
    }
  }, [hide]);

  return (
    <div
      ref={containerRef}
      {...(hide && { tabindex: "-1" })}
      style={{
        transition,
        overflow: "hidden",
        ...sx(_sx),
      }}
    >
      <View>{children}</View>
    </div>
  );
};

export { AnimateHeight };
