import { Feather } from "@expo/vector-icons";
import { Row, SxProp, Text, useDripsyTheme } from "dripsy";
import { MotiPressable } from "moti/interactions";
import { gql, useApolloClient } from "@apollo/client";

export const SelectedTopic = ({
  sx,
  id,
  onDeselect,
}: {
  sx?: SxProp;
  id: string;
  onDeselect: () => void;
}) => {
  const { theme } = useDripsyTheme();
  const client = useApolloClient();
  const data = client.readFragment({
    id: `QuestionTopic:${id}`,
    fragment: gql`
      fragment QuestionTopic on QuestionTopic {
        id
        name
      }
    `,
  });

  return (
    <Row
      sx={{
        px: "$2",
        py: "$1",
        bg: "$blue-tint-1",
        alignSelf: "flex-start",
        alignItems: "center",
        borderRadius: 4,
        ...sx,
      }}
    >
      <MotiPressable
        onPress={onDeselect}
        animate={({ hovered, pressed }) => {
          "worklet";
          return { opacity: hovered || pressed ? 1 : 0.75 };
        }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
      >
        <Feather name="x" size={20} color={theme.colors.$white} />
      </MotiPressable>
      <Text sx={{ color: "$white", ml: "$2" }}>{data.name}</Text>
    </Row>
  );
};
