import { useDripsyTheme } from "dripsy";
import { forwardRef } from "react";
import { Input, InputProps } from "./input";

export interface TextareaProps extends InputProps {
  rows?: number;
}

const TEXTAREA_LINE_HEIGHT = 21;

const Textarea = forwardRef(
  ({ sx = {}, rows = 3, ...rest }: TextareaProps, ref: any) => {
    const { theme } = useDripsyTheme();

    return (
      <Input
        multiline
        ref={ref}
        sx={{
          minHeight: TEXTAREA_LINE_HEIGHT * rows + theme.space.$6, // $6 = total vertical padding
          ...sx,
        }}
        {...rest}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea, TEXTAREA_LINE_HEIGHT };
