import { AlertText, useAlertTextProps } from "app/design/alert";
import { useToast } from "app/components/use-toast";
import { Link } from "app/components/link";
import { pagesPath } from "app/lib/$path";

export const useApplicationLimitToast = () => {
  const { show, close } = useToast();
  const textSx = useAlertTextProps("error");

  const showToast = () =>
    show({
      status: "error",
      title: "Application limit exceeded",
      description: (
        <AlertText variant="error">
          You can only apply to 5 jobs at a time -{" "}
          <Link textSx={textSx} href={pagesPath.jobs.$url()} onPress={close}>
            see in progress applications
          </Link>
          .
        </AlertText>
      ),
    });

  return showToast;
};
