import { gql } from "@apollo/client";
import {
  MatchResponseType,
  useUnhideJobMatchMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import {
  createRemoveMatchResponseOptimisticResponse,
  updateRemove,
} from "../cache";
import { MatchResponse } from "../types";

const UNHIDE_JOB_MATCH_MUTATION = gql`
  mutation UnhideJobMatch($id: MigrateID!) {
    removeMatchResponse(input: { id: $id }) {
      ...JobMatchActionMatchResponseProps
    }
  }

  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

interface UnhideJobMatchProps {
  matchResponse: MatchResponse;
}

const useUnhideJobMatch = () => {
  const [unhideJobMatch] = useUnhideJobMatchMutation();

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: `Unable to unhide job`,
      description: "Please try again or contact support",
    },
  });

  return withToast(
    async ({
      matchResponse: {
        id,
        job: { id: jobId },
      },
    }: UnhideJobMatchProps) => {
      unhideJobMatch({
        variables: {
          id,
        },
        optimisticResponse: createRemoveMatchResponseOptimisticResponse({
          __typename: "GenericMatchResponse",
          jobId,
          type: MatchResponseType.Hide,
          existingMatchResponseId: id,
        }),
        update: updateRemove(jobId),
      });
    }
  );
};

export type { UnhideJobMatchProps };
export { UNHIDE_JOB_MATCH_MUTATION, useUnhideJobMatch };
