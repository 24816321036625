import React, { memo } from "react";
import { Sx, useDripsyTheme } from "dripsy";
import { NetworkStatus } from "@apollo/client";
import { Card } from "app/design/card";
import { MinScreenSize } from "app/components/min-screen-size";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import {
  FlatListLoadingMore,
  QueryInfiniteScrollFlatListScreen,
} from "app/components/screen/query-infinite-scroll-flat-list-screen";
import { ScreenComponent } from "app/navigation/types";

import { useScreenSize } from "app/hooks";
import { Layout } from "../layout";
import { HeaderProps } from "../layout/header";
import {
  NotificationCard,
  NotificationCardGroup,
  NotificationCardLoading,
  NotificationDivider,
} from "./notification-card";
import { useErrorFilteredNotificationsQuery } from "./hooks/use-notifications-query";
import { NotificationsEmpty } from "./empty";

const NotificationsHeader = ({ sx = {} }: { sx?: Sx }) => (
  <MinScreenSize size="tablet">
    <ScreenHeader sx={sx}>Notifications</ScreenHeader>
  </MinScreenSize>
);

const NotificationsLoading = () => (
  <Screen scrollable>
    <NotificationsHeader />
    <Card variant="fullWidthMobile" sx={{ px: 0, py: "$2", mb: "$6" }}>
      <NotificationCardGroup>
        {Array.from({ length: 10 }, (_, i) => (
          <NotificationCardLoading key={i} />
        ))}
      </NotificationCardGroup>
    </Card>
  </Screen>
);

const NotificationsScreen: ScreenComponent = memo(
  () => {
    const { theme } = useDripsyTheme();
    const query = useErrorFilteredNotificationsQuery({
      variables: {
        size: 20,
      },
      notifyOnNetworkStatusChange: true,
    });
    const { isMobile, isAtLeastTablet } = useScreenSize();
    const notifications = query.data?.notifications.data || [];
    const after = query.data?.notifications.after;

    return (
      <QueryInfiniteScrollFlatListScreen
        queryHookResult={query}
        data={notifications}
        containerSx={{
          ...theme.card.default,
          px: 0,
          py: "$2",
          mb: "$8",
        }}
        sx={{
          ...(isMobile && { px: 0 }),
          // TODO: See renderListHeaderComponent comment
          ...(isAtLeastTablet && { pt: 88 }),
        }}
        after={after}
        keyExtractor={(notification) => notification.id}
        // Ignore errors as data is partially fetched. Just if query borks (data === null)
        errorCondition={({ data }) => !data}
        renderItem={({ item, index }) => (
          <>
            <NotificationCard notification={item} />
            {index < notifications.length - 1 ? <NotificationDivider /> : null}
          </>
        )}
        renderLoaderComponent={() => <NotificationsLoading />}
        renderListEmptyComponent={() => <NotificationsEmpty />}
        renderListHeaderComponent={() => (
          <NotificationsHeader
            // TODO: These styles are nasty and magic number-ish due to FlatList
            // limitations. When we develop filters for this screen we'll need
            // to refactor into a web-only screen that doesn't use FlatList (as
            // it has no perf benefits on web anyway)
            sx={{ position: "absolute", top: -74, left: -2, right: -2 }}
          />
        )}
        renderListFooterComponent={({ networkStatus }) =>
          networkStatus === NetworkStatus.fetchMore ? (
            <FlatListLoadingMore
              sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
            />
          ) : null
        }
      />
    );
  },
  () => true
);

const headerProps: HeaderProps = {
  title: "Notifications",
  showTitleOnDesktop: true,
};

NotificationsScreen.displayName = "JobsScreen";
NotificationsScreen.headerProps = headerProps;
NotificationsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { NotificationsScreen };
