import { useDripsyTheme } from "dripsy";
import { ForwardedRef, forwardRef, ReactElement } from "react";
import { QueryResult } from "@apollo/client";
import {
  FlatListBottomSpacer,
  FlatListLoadingMore,
  ListEmptyComponentText,
} from "../flat-list";
import {
  QueryInfiniteScrollFlatListProps,
  QueryInfiniteScrollFlatList,
  ListRenderItem,
} from "../query-infinite-flat-list";
import { Screen, ScreenProps } from "./";

interface QueryInfiniteScrollFlatListScreenProps<
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
> extends QueryInfiniteScrollFlatListProps<TAfter, TItem, THookResult> {
  screenProps?: Omit<ScreenProps, "children">;
}

const QueryInfiniteScrollFlatListScreenInner = <
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
>(
  {
    screenProps = { containerSx: {}, sx: {} },
    containerSx = {},
    sx = {},
    ...props
  }: QueryInfiniteScrollFlatListScreenProps<TAfter, TItem, THookResult>,
  ref: ForwardedRef<$TSFixMe>
) => {
  const { theme } = useDripsyTheme();

  return (
    <Screen
      // overwrite styles so scrollbar is on the edge of the container
      containerSx={{ padding: "$0", ...screenProps.containerSx }}
      sx={{
        maxWidth: undefined,
        ...screenProps.sx,
      }}
    >
      <QueryInfiniteScrollFlatList<TAfter, TItem, THookResult>
        ref={ref}
        containerSx={{
          maxWidth: theme.layout.screen.body.maxWidth,
          width: "100%",
          alignSelf: "center",
          ...containerSx,
        }}
        sx={{
          pt: theme.layout.screen.container.padding,
          px: theme.layout.screen.container.padding,
          ...sx,
        }}
        {...props}
      />
    </Screen>
  );
};

export const QueryInfiniteScrollFlatListScreen = forwardRef(
  QueryInfiniteScrollFlatListScreenInner
) as <TAfter, TItem, THookResult extends QueryResult<any, any>>(
  p: QueryInfiniteScrollFlatListScreenProps<TAfter, TItem, THookResult> & {
    ref?: ForwardedRef<$TSFixMe>;
  }
) => ReactElement;

export { FlatListBottomSpacer, FlatListLoadingMore, ListEmptyComponentText };

export type { ListRenderItem };
