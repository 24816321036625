// @ts-nocheck can't use theme type within its own definition, handled by dripsy
/* eslint-disable @typescript-eslint/no-shadow */

import { DripsyProvider, makeTheme } from "dripsy";
import { Platform } from "react-native";

import { alphaColor } from "app/design/utils";

import { borderRadius } from "./border-radius";
import { brandColors, themeColors } from "./colors";
import { components } from "./components";
import { customFonts, fonts } from "./fonts";
import { shadows } from "./shadows";
import { space } from "./space";
import { a, h1, h2, h3, h4, h5 } from "./text";
import { transitionDurations } from "./transition-durations";

const theme = makeTheme({
  customFonts,
  fonts,
  colors: themeColors,
  space,
  borderRadius,
  shadows,
  // TODO: Dripsy theme breaks if we import and use 'text' from
  // `./text.ts` rather than having it inlined here, for some reason.
  // Is unrelated to using theme values via `(theme) => whatever`
  text: {
    caption: {
      color: (theme) => theme.colors.$text,
      fontSize: [14, 16],
      fontWeight: "500",
      lineHeight: [24, 24],
      ...((Platform.OS === "web" && {
        letterSpacing: "0.02em",
      }) ||
        {}),
      textTransform: "uppercase",
    },
    p: {
      color: (theme) => theme.colors.$text,
      fontSize: [14, 16],
      lineHeight: [21, 24],
      mt: 0,
    },
    sm: {
      color: (theme) => theme.colors.$text,
      fontSize: [12, 14],
      lineHeight: [18, 21],
    },
    xs: {
      color: (theme) => theme.colors.$text,
      fontSize: 12,
      lineHeight: 18,
    },
    body: {
      color: (theme) => theme.colors.$text,
      fontSize: [14, 16],
      lineHeight: [21, 24],
      mt: 0,
    },
    lg: {
      color: (theme) => theme.colors.$text,
      fontSize: [16, 18],
      lineHeight: [24, 27],
    },
    button: {
      fontSize: [16, 18],
      fontWeight: "500",
      lineHeight: [24, 24],
    },
    buttonSmall: {
      fontSize: [14, 16],
      fontWeight: "500",
      lineHeight: [20, 24],
    },
    link: {
      fontSize: [16, 18],
      fontWeight: "400",
      lineHeight: [24, 24],
      textDecorationLine: "underline",
    },
    headingDark: {
      color: brandColors.black,
    },
    headingLight: {
      color: brandColors.white,
    },
    light: {
      color: alphaColor(brandColors.white, 0.85),
    },
    dark: {
      color: alphaColor(brandColors.black, 0.7),
    },
  },
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  transitionDurations,
  ...components,
});

export function Dripsy({ children }: { children: React.ReactNode }) {
  return (
    <DripsyProvider
      theme={theme}
      // this disables SSR, since react-native-web doesn't have support for it (yet)
      ssr
    >
      {children}
    </DripsyProvider>
  );
}

type MyTheme = typeof theme;

declare module "dripsy" {
  interface DripsyCustomTheme extends MyTheme {}
}
