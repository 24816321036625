import { Sx, useSx } from "dripsy";
import { ComponentProps } from "react";
import { Menu as BaseMenu } from "native-base";
import { CARD_BLURRY_BACKGROUND_STYLES } from "app/provider/dripsy/components/card";

interface MenuProps extends ComponentProps<typeof BaseMenu> {
  sx?: Sx;
}

const Menu = ({ sx: _sx = {}, ...rest }: MenuProps) => {
  const sx = useSx();

  return (
    <BaseMenu
      {...rest}
      style={sx({
        boxShadow: "default",
        ...CARD_BLURRY_BACKGROUND_STYLES,
        ..._sx,
        // TODO: Remove when Chrome patches backdropFilter bug
        backgroundColor: "#fffcfb",
      })}
    />
  );
};

Menu.Item = BaseMenu.Item;
Menu.ItemOption = BaseMenu.ItemOption;
Menu.Group = BaseMenu.Group;
Menu.OptionGroup = BaseMenu.OptionGroup;

export { Menu };
