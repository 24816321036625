import { Row, Text, useDripsyTheme } from "dripsy";
import { AntDesign, Feather } from "@expo/vector-icons";
import { cloneElement } from "react";
import { Card } from "app/design/card";
import { MatchResponseType, CandidateMatchResponseType } from "app/types";

interface TypeProps {
  label: string;
  icon: React.ReactElement;
}

const typeConfig: Record<CandidateMatchResponseType, TypeProps> = {
  [MatchResponseType.Save]: {
    label: "Jobs that you save will appear here.",
    icon: <AntDesign name="heart" />,
  },
  [MatchResponseType.ApplicationStart]: {
    label: "Jobs with in progress applications will appear here.",
    icon: <Feather name="edit-3" />,
  },
  [MatchResponseType.Apply]: {
    label: "Jobs that you have applied to will appear here.",
    icon: <AntDesign name="checkcircle" />,
  },
  [MatchResponseType.Hide]: {
    label: "Jobs that you hide will appear here.",
    icon: <Feather name="eye-off" />,
  },
  [MatchResponseType.Rejected]: {
    label: "Jobs that you have been rejected from will appear here",
    icon: <AntDesign name="closecircle" />,
  },
};

interface ApplicationStatusEmptyCardProps {
  type: CandidateMatchResponseType;
}

export const ApplicationStatusEmptyCard = ({
  type,
}: ApplicationStatusEmptyCardProps) => {
  const { theme } = useDripsyTheme();

  const iconProps = {
    size: 40,
    color: theme.colors.$blue,
  };

  const { icon, label } = typeConfig[type];

  return (
    <Card variant="secondary" sx={{ mb: "$0" }}>
      <Row sx={{ alignItems: "center" }}>
        {cloneElement(icon, iconProps)}
        <Text sx={{ ml: "$4", flex: 1, flexWrap: "wrap" }}>{label}</Text>
      </Row>
    </Card>
  );
};
