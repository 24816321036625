import { gql } from "@apollo/client";
import {
  CandidateV2,
  CoreDocumentsFieldsFragmentDoc,
  DocumentType,
  ProfileCompletion,
  useLanguageResultFormQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { Alert, useAlertTextProps } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { WebLink } from "app/components/web-link";
import { DefaultProfileCardEmpty } from "../../card/empty";
import { useProfileEntryList } from "../hooks/use-profile-entry-list";
import { ProfileDocumentCard } from "../../document-card";
import { ProfileSection } from "../../section";
import { getProfileSectionStatus } from "../utils";
import { LanguageResultFormCard, LanguageResultFormValues } from "./form-card";
import { LanguageResultCard } from "./card";
import { LanguageResultsLoading } from "./loading";
import { LanguageRequirementsLink } from "./language-requirements-link";

export const LANGUAGE_RESULTS_FORM_QUERY = gql`
  ${CoreDocumentsFieldsFragmentDoc}
  query LanguageResultForm {
    me {
      id
      ... on CandidateV2 {
        coreDocuments {
          languageCertificate {
            ...CoreDocumentsFields
          }
        }
        languageResults {
          id
          awardingBody
          date
          listening
          reading
          writing
          speaking
        }
        meetsLanguageRequirements
        isExemptFromLanguageTest
        profileCompletion
      }
    }
    languageResultSchema {
      IELTS
      OET
    }
  }
`;

export const LanguageResultsSection = () => {
  const { data, loading, error } = useLanguageResultFormQuery();
  const alertTextSx = useAlertTextProps("info");

  const { fields, append, remove } =
    useProfileEntryList<LanguageResultFormValues>({
      defaultValues: (data?.me as CandidateV2)?.languageResults?.map(
        ({ __typename, ...languageResults }) => languageResults
      ),
    });

  if (loading) return <LanguageResultsLoading />;
  if (error)
    return <DataError title="Failed to load language qualifications" />;

  const {
    coreDocuments,
    languageResults,
    profileCompletion,
    meetsLanguageRequirements,
    isExemptFromLanguageTest,
  } = data?.me as CandidateV2;
  const hasLanguageResults = !!languageResults?.length;
  const hasCertificate = !!coreDocuments?.languageCertificate.length;

  const status = getProfileSectionStatus({
    hasStarted:
      (languageResults || []).length > 0 ||
      hasCertificate ||
      isExemptFromLanguageTest,
    hasCompleted: profileCompletion.includes(
      ProfileCompletion.HasLanguageResults
    ),
  });

  return (
    <ProfileSection title="Language qualifications" status={status}>
      {(() => {
        if (isExemptFromLanguageTest)
          return (
            <Alert sx={{ mb: "$4" }} variant="info">
              You are exempt from taking an English language test and do not
              need to complete this section.
            </Alert>
          );

        if (!hasLanguageResults && !hasCertificate)
          return (
            <Alert sx={{ mb: "$4" }} variant="info">
              If you are exempt from taking an English language test due to
              recent practice in an English speaking country, please complete
              your work experience (specifying the country and dates of
              practice).{"\n\n"}
              If you have completed a TOEFL test, please upload your certificate
              below and email{" "}
              <WebLink sx={alertTextSx} url="mailto:support@migratehr.com">
                support@migratehr.com
              </WebLink>{" "}
              so we can verify your result.
            </Alert>
          );

        if (hasLanguageResults && !hasCertificate)
          return (
            <Alert sx={{ mb: "$4" }} variant="warning">
              You need to upload a copy of your certificate to complete this
              section.
            </Alert>
          );

        if (hasLanguageResults && hasCertificate && !meetsLanguageRequirements)
          return (
            <Alert sx={{ mb: "$4" }} variant="warning">
              Your scores don&apos;t meet our{" "}
              <LanguageRequirementsLink sx={{ variant: "text.sm" }}>
                general application requirements
              </LanguageRequirementsLink>{" "}
              . You can still apply to jobs where employers don&apos;t require a
              passing language result, but you won&apos;t be able to apply to
              all of the jobs on Migrate.
            </Alert>
          );
      })()}

      <ProfileDocumentCard
        typeProps={{
          type: DocumentType.LanguageCertificate,
          name: "Qualification",
        }}
        documents={coreDocuments?.languageCertificate}
        emptyDescription="Upload a copy of your IELTS/OET certificate to prove your language results."
        emptyCTA="Add certificate"
      />

      {fields.length === 0 ? (
        <DefaultProfileCardEmpty
          description="Add results for any English language qualifications you hold."
          callToAction="Add language result"
          onPress={() => append(LanguageResultFormCard.createInstance())}
        />
      ) : null}
      {fields.map((field) => (
        <LanguageResultCard
          key={field.id}
          field={field}
          onCancel={() => remove(field.id)}
          resultSchema={data?.languageResultSchema}
        />
      ))}
      {fields.length > 0 ? (
        <Button
          variant="secondary"
          containerSx={{ alignSelf: "center" }}
          onPress={() => append(LanguageResultFormCard.createInstance())}
        >
          Add language result
        </Button>
      ) : null}
    </ProfileSection>
  );
};
