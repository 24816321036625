import { useDripsyTheme, View, Sx } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { AlertCard, AlertHeader, AlertText } from "app/design/alert";

interface DataErrorProps {
  sx?: Sx;
  title?: string;
  description?: string;
}

const DataError: React.FC<DataErrorProps> = ({
  sx = {},
  title = "An error has occurred",
  description = "Please refresh the page or contact support.",
}) => {
  const { theme } = useDripsyTheme();

  return (
    <AlertCard sx={sx} variant="error">
      <View
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: ["$2", "$1"],
        }}
      >
        <View sx={{ flexDirection: "row", flexShrink: 1 }}>
          <Feather
            name="alert-triangle"
            color={theme.alert.error.color}
            size={20}
          />
          <AlertHeader
            variant="error"
            sx={{
              ml: "$2",
              alignSelf: "center",
            }}
          >
            {title}
          </AlertHeader>
        </View>
      </View>
      <AlertText variant="error">{description}</AlertText>
    </AlertCard>
  );
};

export { DataError };
