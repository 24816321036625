import { gql, useApolloClient } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  MessageFieldsFragment,
  useDeleteMessageMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

const DELETE_MESSAGE_MUTATION = gql`
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      message {
        id
        deletedAt
      }
    }
  }
`;

type DeletableMessage = Pick<
  MessageFieldsFragment,
  "id" | "__typename" | "sendError"
>;

interface UseDeleteMessageProps {
  message: DeletableMessage;
}

const useDeleteMessage = ({ message }: UseDeleteMessageProps) => {
  const { openModal } = useModal();
  const { cache } = useApolloClient();
  const [deleteMessage] = useDeleteMessageMutation();

  const handleServerDelete = async () => {
    await deleteMessage({
      variables: { input: { id: message.id } },
      optimisticResponse: {
        deleteMessage: {
          __typename: "DeleteMessagePayload",
          message: {
            ...message,
            deletedAt: new Date().toISOString(),
          },
        },
      },
    });
  };

  const handleClientDelete = async () => {
    cache.evict({
      id: cache.identify(message),
    });
    cache.gc();
  };

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete message",
      description: "Please try again or contact support.",
    },
  });

  const submit = withToast(
    message.sendError ? handleClientDelete : handleServerDelete
  );

  return () =>
    openModal("ConfirmModal", {
      title: "Confirm delete message",
      description: "Are you sure you want to delete your message?",
      confirmLabel: "Delete message",
      onConfirm: submit,
    });
};

export type { DeletableMessage, UseDeleteMessageProps };
export { useDeleteMessage, DELETE_MESSAGE_MUTATION };
