import { Sx, useDripsyTheme, useSx } from "dripsy";
import { ComponentProps } from "react";
import { Popover as BasePopover } from "native-base";
import { AnimatePresence, MotiView } from "moti";
import { CARD_BLURRY_BACKGROUND_STYLES } from "app/provider/dripsy/components/card";

interface PopoverProps extends ComponentProps<typeof BasePopover> {
  contentSx?: Sx;
}

const Popover = ({ children, contentSx = {}, ...rest }: PopoverProps) => {
  const sx = useSx();
  const { theme } = useDripsyTheme();

  return (
    <BasePopover {...rest}>
      <AnimatePresence>
        <MotiView
          from={{ translateY: -5 }}
          animate={{ translateY: 0 }}
          exit={{ translateY: 0 }}
          transition={{
            type: "timing",
            duration: theme.transitionDurations.normal,
          }}
        >
          <BasePopover.Content
            style={sx({
              mt: "$3",
              boxShadow: "default",
              ...CARD_BLURRY_BACKGROUND_STYLES,
              ...contentSx,
              // TODO: Remove when Chrome patches backdropFilter bug
              backgroundColor: "#fffcfb",
            })}
          >
            {children}
          </BasePopover.Content>
        </MotiView>
      </AnimatePresence>
    </BasePopover>
  );
};

export { Popover };
