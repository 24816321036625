import { useCurrentPathname } from "./use-current-pathname";

const isLinkActive = (currentPathname: string, linkHref: string) =>
  // for currentPathname = "/"
  currentPathname === linkHref ||
  // for currentPathname = "/${...}"
  (linkHref !== "/" && currentPathname.includes(linkHref));

export const useIsLinkActive = (linkHref: string) => {
  const pathname = useCurrentPathname();
  return isLinkActive(pathname, linkHref);
};
