import { List, ListItem } from "app/components/list";
import { Divider } from "./divider";
import { Section, SectionHeader } from "./section";

interface HighlightsProps {
  highlights: string[];
}

const Highlights = ({ highlights }: HighlightsProps) => (
  <Section>
    <SectionHeader>Job highlights</SectionHeader>
    <List>
      {highlights.map((highlight, i) => (
        <ListItem key={i}>{highlight}</ListItem>
      ))}
    </List>

    <Divider />
  </Section>
);

export { Highlights };
