import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  useCreateQuestionResponseMutation,
  useDeleteQuestionResponseMutation,
  useUpdateQuestionResponseMutation,
  useQuestionResponseFormQuery,
  QuestionResponse,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const CREATE_QUESTION_RESPONSE_MUTATION = gql`
  mutation CreateQuestionResponse($input: CreateQuestionResponseInput!) {
    createQuestionResponse(input: $input) {
      id
      questionResponses {
        id
        question
        response
      }
      profileCompletion
    }
  }
`;

export const UPDATE_QUESTION_RESPONSE_MUTATION = gql`
  mutation UpdateQuestionResponse($input: UpdateQuestionResponseInput!) {
    updateQuestionResponse(input: $input) {
      id
      questionResponses {
        id
        question
        response
      }
      profileCompletion
    }
  }
`;

export const DELETE_QUESTION_RESPONSE_MULTATION = gql`
  mutation DeleteQuestionResponse($input: DeleteQuestionResponseInput!) {
    deleteQuestionResponse(input: $input) {
      id
      questionResponses {
        id
        question
        response
      }
      profileCompletion
    }
  }
`;

export const useQuestionResponseMutations = (id?: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete question response",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useQuestionResponseFormQuery();
  if (error !== undefined)
    throw new Error(
      "unexpected error in useQuestionResponseMutations: " + error.message
    );

  const [createQuestionResponse] = useCreateQuestionResponseMutation();
  const [updateQuestionResponse] = useUpdateQuestionResponseMutation();
  const [deleteQuestionResponse] = useDeleteQuestionResponseMutation({
    update: (cache) => {
      cache.modify({
        id: `Candidate:${data?.me.id}`,
        fields: {
          questionResponses(existing) {
            return {
              ...existing,
              data: existing.data.filter(
                (questionResponse: QuestionResponse) =>
                  questionResponse.id !== id
              ),
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteQuestionResponse: {
        ...(data?.me as CandidateV2),
      },
    },
  });

  return {
    createQuestionResponse,
    updateQuestionResponse,
    deleteQuestionResponse: () =>
      openModal("ConfirmModal", {
        title: "Confirm delete question response",
        description: "Are you sure you want to delete this question response?",
        confirmLabel: "Delete question response",
        onConfirm: withToast(async () => {
          if (!id)
            throw new Error("question response: id required for deletion");
          await deleteQuestionResponse({ variables: { input: { id } } });
        }),
      }),
  };
};
