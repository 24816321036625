import { Platform } from "react-native";
import { alphaColor, darkenColor } from "app/design/utils";

const bgAlphaColor = (hex: string) => alphaColor(hex, 0.95);
// TODO: Reinstate when Chrome patches backdropFilter bug
// alphaColor(hex, Platform.OS === "web" ? 0.85 : 0.95);

const textProps = {
  fontWeight: "500",
  fontSize: 14,
  lineHeight: 21,
};

const defaultProps = {
  ...textProps,
  borderRadius: "sm",
  p: "$3",
  pl: 18,
};

interface CreateAlertProps {
  color: string;
  bg: string;
  accentBg: string;
}

const generateAlert = ({ color, bg, accentBg }: CreateAlertProps) => ({
  ...defaultProps,
  color,
  bg: bgAlphaColor(bg),
  // Android doesn't stack BG colours with border colors, so up the opacity on that platform
  borderColor: alphaColor(bg, Platform.select({ android: 0.98, default: 0.5 })),
  _accent: {
    bg: accentBg,
  },
  _hover: {
    bg: alphaColor(darkenColor(bg, 5), 1),
  },
});

const alert = {
  text: {
    ...textProps,
  },
  success: generateAlert({
    bg: "#ADD8BF",
    color: "#417553",
    accentBg: "$success",
  }),
  info: generateAlert({
    bg: "#B7CAFF",
    color: "#3a487c",
    accentBg: "$blue",
  }),
  warning: generateAlert({
    bg: "#F9C996",
    color: "#9C6017",
    accentBg: "$orange",
  }),
  error: generateAlert({
    bg: "#FFB2B1",
    color: "#914646",
    accentBg: "$error",
  }),
};

export { alert };
