import { SxProp, useSx, styled } from "dripsy";
import { ForwardedRef, forwardRef, useRef } from "react";
import { FlatListProps, FlatList as DefaultFlatList } from "react-native";

const DripsyFlatList = styled(DefaultFlatList)();

export const FlatList = forwardRef(
  (
    {
      onEndReached,
      onEndReachedThreshold,
      sx: _sx = {},
      containerSx = {},
      ...rest
    }: Omit<FlatListProps<$TSFixMe>, "onScroll"> & {
      sx?: SxProp;
      containerSx?: SxProp;
      onEndReached?: () => void;
    },
    ref: ForwardedRef<$TSFixMe>
  ) => {
    const sx = useSx();
    const hasReachedThreshold = useRef(false);
    return (
      <DripsyFlatList
        ref={ref}
        sx={_sx}
        contentContainerStyle={sx(containerSx)}
        onScroll={(e) => {
          const { contentOffset, contentSize, layoutMeasurement } =
            e.nativeEvent;
          const contentEnd = contentSize.height - layoutMeasurement.height;

          const threshold = onEndReachedThreshold
            ? onEndReachedThreshold * contentEnd
            : contentEnd;

          if (contentOffset.y < threshold && hasReachedThreshold.current) {
            hasReachedThreshold.current = false;
          }

          if (contentOffset.y >= threshold && onEndReached) {
            if (!hasReachedThreshold.current) {
              hasReachedThreshold.current = true;
              onEndReached();
            }
          }
        }}
        {...rest}
        scrollEventThrottle={rest.scrollEventThrottle || 50}
      />
    );
  }
);
FlatList.displayName = "FlatList";

export * from "./flat-list.common";
