import { gql } from "@apollo/client";
import { Row, useDripsyTheme, Sx } from "dripsy";
import { useJobMatchesOverviewQuery } from "app/types/generated/schema";
import { ButtonLink } from "app/design/button";
import { DataError } from "app/components/data-error";
import {
  AnimatedOverviewCard,
  ChangePreferencesButton,
  OverviewCard,
  OverviewHeader,
  OverviewLoading,
  OverviewText,
  SetPreferencesPrompt,
  VerifyPrompt,
} from "app/components/overview-card";
import { pagesPath } from "app/lib/$path";
import { useCurrentUser } from "app/hooks";

const JOB_MATCHES_OVERVIEW_JOB_PAGE_PROPS = gql`
  fragment JobMatchesOverviewJobPageProps on JobPage {
    count
  }
`;

const JOB_MATCHES_OVERVIEW_QUERY = gql`
  query JobMatchesOverview($input: MyMatchedJobsInput) {
    myMatchedJobs(input: $input) {
      ...JobMatchesOverviewJobPageProps
    }
  }
  ${JOB_MATCHES_OVERVIEW_JOB_PAGE_PROPS}
`;

const MatchesEmpty = ({ sx }: { sx: Sx }) => (
  <OverviewCard variant="default" sx={sx}>
    <OverviewHeader variant="headingDark">No job matches</OverviewHeader>
    <OverviewText variant="p">
      Looks like there are no job matches for you right now. Check back later or
      change your preferences now.
    </OverviewText>
    <ChangePreferencesButton
      variant="link"
      containerSx={{ alignSelf: "flex-start" }}
    >
      Change preferences
    </ChangePreferencesButton>
  </OverviewCard>
);

interface MatchesOverviewContentProps {
  count: number;
  sx?: Sx;
}

const MatchesOverviewContent = ({
  sx = {},
  count,
}: MatchesOverviewContentProps) => {
  const { theme } = useDripsyTheme();
  if (count === 0) return <MatchesEmpty sx={sx} />;

  const countHeaderText = count === 1 ? "a new job match" : `new job matches`;
  const countButtonText = count === 1 ? "View match" : "View matches";

  return (
    <AnimatedOverviewCard sx={sx}>
      <OverviewHeader>You have {countHeaderText}!</OverviewHeader>
      <OverviewText>
        Jobs are available for you to apply to, click the button below to get
        started, or update your preferences to see more jobs.
      </OverviewText>
      <Row
        sx={{
          flexDirection: ["column", "row"],
          alignItems: ["stretch", "center"],
          flexWrap: ["nowrap", "wrap"],
          mt: 0 - theme.space.$2,
        }}
      >
        <ButtonLink
          variant="light"
          size="md"
          href={pagesPath.jobs.matches.$url()}
          sx={{ px: "$6" }}
          containerSx={{ mr: [0, "$6"], mt: "$2", flexGrow: [1, 0] }}
        >
          {countButtonText}
        </ButtonLink>
        <ChangePreferencesButton
          containerSx={{ mt: "$2", flexGrow: [1, 0] }}
          variant="ghostLight"
        >
          Change preferences
        </ChangePreferencesButton>
      </Row>
    </AnimatedOverviewCard>
  );
};

interface MatchesOverviewProps {
  sx?: Sx;
}

const MatchesOverview = ({ sx = {} }: MatchesOverviewProps) => {
  const {
    hasSetJobPreferences,
    isVerified,
    isCandidateV1,
    loading: loadingCurrentUser,
  } = useCurrentUser();

  const {
    data,
    loading: loadingJobMatches,
    error,
  } = useJobMatchesOverviewQuery({
    skip: isCandidateV1 || !isVerified,
  });

  if (loadingCurrentUser || loadingJobMatches)
    return <OverviewLoading sx={sx} />;

  if (!isVerified)
    return (
      <VerifyPrompt
        sx={sx}
        description="Before we can show you your matches, you need to verify your account."
      />
    );

  if (isCandidateV1 || !hasSetJobPreferences)
    return (
      <SetPreferencesPrompt
        sx={sx}
        description="Before we can show you your matches, you need to set your job preferences."
      />
    );

  if (error || !data)
    return (
      <DataError
        sx={{
          mb: ["$6", "$7"],
        }}
        title="Unable to fetch matches"
      />
    );

  return <MatchesOverviewContent sx={sx} count={data.myMatchedJobs.count} />;
};

MatchesOverview.fragments = {
  jobPage: JOB_MATCHES_OVERVIEW_JOB_PAGE_PROPS,
};

export { JOB_MATCHES_OVERVIEW_QUERY, MatchesOverview };
