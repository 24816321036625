import { useDripsyTheme, View } from "dripsy";

import { MigrateIcon } from "app/design/migrate-icon";
import { pagesPath } from "app/lib/$path";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { useAuth } from "app/use-auth";

import { useCurrentUser } from "app/hooks";
import { useNumUnreadMessagesQuery } from "app/types";
import { useProfileCompletion } from "app/features/profile/completion/use-profile-completion";
import { captureException } from "app/client/error";
import { SIDEBAR_WIDTHS } from "../constants";
import { MenuItem, MenuItemLink } from "./menu-item";

interface SidebarContainerProps {
  children: React.ReactNode;
}

const SidebarContainer = ({ children }: SidebarContainerProps) => {
  const { theme } = useDripsyTheme();
  const safeArea = useSafeArea();
  return (
    <View
      sx={{
        display: ["none", "flex"],
        width: SIDEBAR_WIDTHS,
        maxWidth: "25%",
        pt: 88,
        pb: theme.space.$6 + safeArea.bottom,
        pl: "$6",
        justifyContent: "space-between",
      }}
    >
      {children}
    </View>
  );
};

interface SidebarGroupProps {
  children: React.ReactNode;
}

const SidebarGroup = ({ children }: SidebarGroupProps) => {
  const { theme } = useDripsyTheme();
  return <View sx={{ mt: 0 - theme.space.$1 }}>{children}</View>;
};

const HomeMenuItemLink = () => (
  <MenuItemLink
    href={pagesPath.$url()}
    icon={<MigrateIcon name="home" size={18} />}
  >
    Home
  </MenuItemLink>
);

const JobsMenuItemLink = () => (
  <MenuItemLink
    href={pagesPath.jobs.$url()}
    icon={<MigrateIcon name="jobs" size={18} />}
  >
    Jobs
  </MenuItemLink>
);

const CommunityMenuItemLink = () => {
  const { isVerified } = useCurrentUser();

  return (
    <MenuItemLink
      href={pagesPath.community.$url()}
      icon={<MigrateIcon name="community" size={16} />}
      submenuItems={[
        {
          href: pagesPath.community.questions.$url(),
          label: "Questions",
        },
        ...(isVerified
          ? [
              {
                href: pagesPath.community.channels.all.$url(),
                label: "Community channels",
              },
              {
                href: pagesPath.community.channels.$url(),
                label: "My channels",
              },
            ]
          : []),
      ]}
    >
      Community
    </MenuItemLink>
  );
};

const InboxMenuItemLink = () => {
  const { data: { numUnreadMessages } = {}, error } =
    useNumUnreadMessagesQuery();

  // Capture if we can't get number of unread messages
  if (error) captureException(error);

  return (
    <MenuItemLink
      href={pagesPath.inbox.$url()}
      icon={<MigrateIcon name="message-square-filled" size={18} />}
      badgeProps={{
        value: numUnreadMessages,
      }}
    >
      Inbox
    </MenuItemLink>
  );
};

const ProfileMenuItemLink = () => {
  const { theme } = useDripsyTheme();
  const { completed, loading } = useProfileCompletion();

  return (
    <MenuItemLink
      href={pagesPath.profile.$url()}
      icon={<MigrateIcon name="user" size={20} />}
      badgeProps={{
        value: !loading && !completed ? "!" : undefined,
        backgroundColor: theme.colors.$orange,
        textSx: { fontSize: 13 },
      }}
    >
      My profile
    </MenuItemLink>
  );
};

const LogoutMenuItem = () => {
  const { logout } = useAuth();
  return (
    <MenuItem
      onPress={() => logout()}
      icon={<MigrateIcon name="exit" size={20} />}
    >
      Log out
    </MenuItem>
  );
};

const AuthenticatedSidebar = ({ showInbox }: { showInbox: boolean }) => (
  <SidebarContainer>
    <SidebarGroup>
      <HomeMenuItemLink />
      <JobsMenuItemLink />
      <CommunityMenuItemLink />
      {showInbox ? <InboxMenuItemLink /> : null}
      <ProfileMenuItemLink />
    </SidebarGroup>
    <LogoutMenuItem />
  </SidebarContainer>
);

const UnauthenticatedSidebar = () => (
  <SidebarContainer>
    <SidebarGroup>
      <HomeMenuItemLink />
      <JobsMenuItemLink />
      <CommunityMenuItemLink />
    </SidebarGroup>
  </SidebarContainer>
);

const Sidebar: React.FC = () => {
  const { currentUser, isVerified } = useCurrentUser();
  return currentUser ? (
    <AuthenticatedSidebar
      showInbox={currentUser.__typename === "CandidateV2" && isVerified}
    />
  ) : (
    <UnauthenticatedSidebar />
  );
};

export { Sidebar };
