import create from "zustand";

type MessageStore = {
  messagesInFlight: number;
  incrementMessagesInFlight: () => void;
  decrementMessagesInFlight: () => void;
};

const useMessageStore = create<MessageStore>((set) => ({
  messagesInFlight: 0,
  incrementMessagesInFlight: () =>
    set((state) => ({ messagesInFlight: state.messagesInFlight + 1 })),
  decrementMessagesInFlight: () =>
    set((state) => ({ messagesInFlight: state.messagesInFlight - 1 })),
}));

export type { MessageStore };
export { useMessageStore };
