import { Text, Sx, useDripsyTheme, View } from "dripsy";
import { ReactNode } from "react";

interface BadgeProps {
  sx?: Sx;
  textSx?: Sx;
  children?: string | number;
}

const Badge = ({ children, sx = {}, textSx = {} }: BadgeProps) => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        bg: theme.colors.$blue,
        px: "$2",
        py: "$1",
        borderRadius: 4,
        mr: "$2",
        ...sx,
      }}
    >
      <Text variant="sm" sx={{ color: theme.colors.$white, ...textSx }}>
        {children}
      </Text>
    </View>
  );
};

interface AbsoluteBadgeProps extends BadgeProps {
  backgroundColor?: string;
}

const AbsoluteBadge = ({
  backgroundColor,
  sx = {},
  textSx = {},
  children,
}: AbsoluteBadgeProps) => {
  return (
    <Badge
      sx={{
        position: "absolute",
        top: -8,
        left: "100%",
        ml: -8,
        mr: 0,
        backgroundColor,
        px: "$1",
        py: "$0",
        borderRadius: 99,
        height: 16,
        minWidth: 16,
        ...sx,
      }}
      textSx={{
        mt: 1,
        lineHeight: 15,
        fontSize: 12,
        textAlign: "center",
        fontFamily: "heading",
        ...textSx,
      }}
    >
      {children}
    </Badge>
  );
};

interface BadgedProps extends Omit<AbsoluteBadgeProps, "children"> {
  value?: string | number;
  hidden?: boolean;
  containerSx?: Sx;
  children: ReactNode;
}

const Badged = ({ children, value, containerSx, ...props }: BadgedProps) => {
  const { theme } = useDripsyTheme();

  const { hidden = !value, backgroundColor = theme.colors.$blue } = props;

  return (
    <View sx={{ zIndex: 1, ...containerSx }}>
      {children}
      {!hidden && (
        <AbsoluteBadge backgroundColor={backgroundColor} {...props}>
          {value && value > 99 ? "99+" : value}
        </AbsoluteBadge>
      )}
    </View>
  );
};

export type { BadgedProps };
export { AbsoluteBadge, Badge, Badged };
