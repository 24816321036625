import { memo } from "react";
import isEqual from "react-fast-compare";
import { ProfileCard } from "../../card";
import { useQuestionResponseMutations } from "../hooks/use-question-response-mutations";
import { isTempField } from "../profile-form";
import {
  QuestionResponseFormCard,
  QuestionResponseFormValues,
} from "./form-card";

interface QuestionResponseCardProps {
  field: QuestionResponseFormValues;
  onCancel: () => void;
  options?: string[];
}

export const QuestionResponseCard = memo(
  ({ field, onCancel, options }: QuestionResponseCardProps) => {
    const { id, question, response } = field;
    const { deleteQuestionResponse } = useQuestionResponseMutations(id);
    const completed = !isTempField({ id });

    return (
      <ProfileCard
        header={question}
        subheader={response}
        form={
          <QuestionResponseFormCard
            defaultValues={field}
            onCancel={onCancel}
            options={options}
          />
        }
        onDelete={deleteQuestionResponse}
        showFormOnRender={!completed}
      />
    );
  },
  (a, b) => isEqual(a.field, b.field)
);
QuestionResponseCard.displayName = "QuestionResponseCard";
