import { useEffect, useState } from "react";
import Constants from "expo-constants";
import {
  Client,
  Select,
  Map,
  Paginate,
  Documents,
  Collection,
  Get,
} from "faunadb";
import { Platform } from "react-native";
import { FCC } from "app/types";
import { FeatureFlagsContext } from "./context";

const flagClient = new Client({
  headers: { "Content-Type": "application/json" },
  secret:
    Platform.OS === "web"
      ? process.env.FLAGS_API_KEY
      : // @ts-expect-error not sure how to fix this?
        Constants.expoConfig.extra.flagsKey,
  domain: "db.eu.fauna.com",
});

type Flag = { name: string; enabled: boolean };

const FeatureFlagsProvider: FCC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [flags, setFlags] = useState({});

  const getFlags = async () => {
    const res = await flagClient.query<{ data: Flag }[]>(
      Select(
        "data",
        Map(Paginate(Documents(Collection("Flag"))), (ref) => Get(ref))
      )
    );
    setFlags(
      res.reduce((acc, item) => {
        const { name, enabled } = item.data;
        // @ts-ignore not a clue mans
        acc[name] = enabled;
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    const initialise = async () => {
      await getFlags();
      setLoading(false);
    };

    initialise();
  }, []);

  // @ts-ignore ???
  const hasFeature = (flag: string) => flags[flag] || false;

  return (
    <FeatureFlagsContext.Provider
      value={{ loading, hasFeature, refreshFlags: getFlags }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsProvider };
