import { gql, useApolloClient } from "@apollo/client";
import { Scalars } from "app/types/generated/schema";
import { AnyFile } from "app/hooks/helpers/upload-file";

type MessageCacheId = string;

type RetryMessageState = {
  channelId: Scalars["MigrateID"];
  attachment?: AnyFile;
};

// TODO: Replace with something immutable? This isn't test friendly...
const retryStateMap = new Map<MessageCacheId, RetryMessageState>();

const useSetMessageError = () => {
  const client = useApolloClient();
  const { cache } = client;

  const prepareRetryState = (
    messageCacheId: string,
    state: RetryMessageState
  ) => {
    retryStateMap.set(messageCacheId, state);
    return state;
  };

  const clearRetryState = (messageCacheId: string) => {
    const state = retryStateMap.get(messageCacheId);
    retryStateMap.delete(messageCacheId);
    return state;
  };

  const getRetryState = (messageCacheId: string) =>
    retryStateMap.get(messageCacheId);

  const setMessageError = (cachedMessageId: Scalars["MigrateID"]) => {
    cache.modify({
      id: cachedMessageId,
      fields: {
        sendError() {
          return true;
        },
        attachment(cachedAttachmentRef) {
          if (cachedAttachmentRef) {
            client.writeFragment({
              id: client.cache.identify(cachedAttachmentRef),
              fragment: gql`
                fragment AttachmentError on Attachment {
                  isUploading @client
                }
              `,
              data: {
                isUploading: false,
              },
            });
          }
          return cachedAttachmentRef;
        },
        isSent() {
          return false;
        },
      },
    });
  };

  return {
    prepareRetryState,
    clearRetryState,
    getRetryState,
    setMessageError,
  };
};

export { useSetMessageError };
