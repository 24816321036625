import { View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { gql } from "@apollo/client";
import { Platform } from "react-native";
import { OrganisationAvatar } from "app/design/avatar";
import {
  CandidateApplicationRejectedNotification,
  Notification,
  NotificationType,
  ApplicationRejectedFieldsFragment,
} from "app/types";
import { pagesPath } from "app/lib/$path";
import {
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
} from "./common";
import { NotificationHeader } from "./header";
import {
  NotificationCardWrapper,
  NotificationCardWrapperProps,
} from "./wrapper";

interface ApplicationRejectedCardProps
  extends Omit<NotificationCardWrapperProps, "children" | "href"> {
  notification: Pick<
    CandidateApplicationRejectedNotification,
    "createdAt" | "id"
  > &
    ApplicationRejectedFieldsFragment;
}

const ApplicationRejectedCard = ({
  notification,
  renderHeaderRight,
  ...rest
}: ApplicationRejectedCardProps) => (
  <NotificationCardWrapper
    notification={notification}
    href={pagesPath.jobs._jobId(notification.matchResponse.job.id).$url()}
    {...rest}
  >
    <View>
      <OrganisationAvatar
        organisation={notification.matchResponse.job.organisation}
        size={12}
      />
      <Badge icon={<Feather name="x" size={12} color="white" />} />
    </View>
    <NotificationContent>
      <NotificationHeader
        createdAt={notification.createdAt}
        renderHeaderRight={renderHeaderRight}
      >
        We&apos;ve rejected your application for the role of{" "}
        <Highlight>{notification.matchResponse.job.title}</Highlight>
      </NotificationHeader>
      <NotificationBody>
        Unfortunately, we&apos;ve rejected your application for this position -{" "}
        {Platform.OS === "web" ? "click" : "tap"} this notification for more
        details.
      </NotificationBody>
    </NotificationContent>
  </NotificationCardWrapper>
);

ApplicationRejectedCard.fragment = gql`
  fragment ApplicationRejectedFields on CandidateApplicationRejectedNotification {
    matchResponse {
      id
      job {
        id
        title
        organisation {
          id
          photoUrl
        }
      }
    }
  }
`;

ApplicationRejectedCard.typeguard = (
  notification: Pick<Notification, "type">
): notification is CandidateApplicationRejectedNotification =>
  notification.type === NotificationType.CandidateApplicationRejected;

export { ApplicationRejectedCard };
