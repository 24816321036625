import { H1, useDripsyTheme } from "dripsy";
import { useState } from "react";
import { WelcomeLogo } from "app/components/welcome-logo";
import { KeyboardAwareScrollView } from "app/components/keyboard-aware-scroll-view";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { Background } from "app/features/layout/background";
import {
  LOGIN_SCREEN_EXTRA_HEIGHT,
  LOGIN_SCREEN_MAX_WIDTH,
} from "app/features/login-screen";
import { EmailStep } from "./email-step";
import { ResetStep } from "./reset-step";

export const ForgotPasswordScreen = () => {
  const insets = useSafeArea();
  const [email, setEmail] = useState("");
  const { theme } = useDripsyTheme();

  return (
    <Background>
      <KeyboardAwareScrollView
        sx={{
          px: "$4",
          pt: theme.space.$6 + insets.top,
          pb: theme.space.$6 + insets.bottom,
        }}
        contentContainerStyle={{
          width: "100%",
          maxWidth: LOGIN_SCREEN_MAX_WIDTH,
          alignSelf: "center",
        }}
        extraHeight={LOGIN_SCREEN_EXTRA_HEIGHT}
      >
        <WelcomeLogo sx={{ alignSelf: "center", mb: "$9" }} />

        <H1 sx={{ mb: "$7", textAlign: "center", fontSize: 32 }}>
          Forgot password
        </H1>

        {!email ? (
          <EmailStep setEmail={setEmail} />
        ) : (
          <ResetStep email={email} />
        )}
      </KeyboardAwareScrollView>
    </Background>
  );
};
