import dynamic from "next/dynamic";
import {
  createModalStack,
  ModalProvider as ModalfyProvider,
  ModalStackParams,
  UsableModalComponentProp,
  UsableModalProp,
} from "react-native-modalfy";
import { brandColors } from "./dripsy/colors";

const AskQuestionModal = dynamic(
  () =>
    import("app/features/community/questions/ask-question-modal").then(
      (mod) => mod.AskQuestionModal
    ),
  { ssr: false }
);

const AnswerQuestionModal = dynamic(
  () =>
    import("app/features/community/questions/answer-question-modal").then(
      (mod) => mod.AnswerQuestionModal
    ),
  { ssr: false }
);

const ConfirmModal = dynamic(
  () => import("app/components/confirm-modal").then((mod) => mod.ConfirmModal),
  { ssr: false }
);

const ImportFromCVModal = dynamic(
  () =>
    import("app/features/profile/import-from-cv-modal").then(
      (mod) => mod.ImportFromCVModal
    ),
  { ssr: false }
);

const VerifyAccountModal = dynamic(
  () =>
    import("app/components/verify-account-modal").then(
      (mod) => mod.VerifyAccountModal
    ),
  { ssr: false }
);

const LeaveChannelModal = dynamic(
  () =>
    import(
      "app/features/community/chat/channel-header/leave-channel-modal"
    ).then((mod) => mod.LeaveChannelModal),
  { ssr: false }
);

const UploadDocumentModal = dynamic(
  () =>
    import("app/features/home/my-documents/upload-document-modal").then(
      (mod) => mod.UploadDocumentModal
    ),
  { ssr: false }
);

const modalConfig: Record<keyof ModalStackParams, any> = {
  ConfirmModal,
  AskQuestionModal,
  AnswerQuestionModal,
  ImportFromCVModal,
  VerifyAccountModal,
  LeaveChannelModal,
  UploadDocumentModal,
};

// Below types for declarative modal parameters.
// Overrides params to be required in openModal...
// Requires a manually writing undefined, but you get autocomplete and this is
// infinitely safer than allowing undefined to be passed in for required params...
// Also removes the annoying Params | undefined type in modal components.
declare module "react-native-modalfy" {
  interface ModalStackParams {}
  function useModal(): Omit<UsableModalProp<ModalStackParams>, "openModal"> & {
    openModal: <N extends keyof ModalStackParams>(
      modalName: N,
      // Override openModal to force passing the params dammit!!
      params: ModalStackParams[N],
      callback?: () => void
    ) => void;
  };
}

export type ModalProps<
  TModalName extends keyof ModalStackParams,
  TExtraParams = {}
> = TExtraParams & {
  title: string;
  forceWindowed?: boolean;
} & {
  modal: Omit<
    UsableModalComponentProp<ModalStackParams, TModalName>,
    "params"
  > & {
    // There will be params dammit!!
    params: ModalStackParams[TModalName];
  };
};

const defaultOptions = {
  backdropOpacity: 0.6,
  backdropColor: brandColors.black,
  // Disabled this gesture as it causes unexpected behaviour on web (detects short
  // click and drags as a 'fling' and dismisses the modal). I also think this is
  // unexpected behaviour of native/mobile too, as the modals are full-screen
  disableFlingGesture: true,
};

const stack = createModalStack(modalConfig, defaultOptions);

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  return <ModalfyProvider stack={stack}>{children}</ModalfyProvider>;
};
