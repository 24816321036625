import { format } from "date-fns";
import { Text, useDripsyTheme, View } from "dripsy";
import { ReactElement, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Portal } from "react-overlays";
import "react-datepicker/dist/react-datepicker.css";

import { InputWrapper } from "../input";
import { DatePickerProps } from "./index";
import "./index.web.css";

const DatepickerPortal = ({ children }: { children: ReactElement }) => {
  const el = document.getElementById("calendar-container");
  return <Portal container={el}>{children}</Portal>;
};

export const DatePicker = ({
  value,
  onChange,
  placeholder,
  ...props
}: DatePickerProps) => {
  const { containerSx } = props;
  const { theme } = useDripsyTheme();
  const ref = useRef<ReactDatePicker>();

  return (
    <View sx={containerSx}>
      <ReactDatePicker
        ref={ref}
        selected={value ? new Date(value) : null}
        onChange={(v: Date) => onChange?.(v.toISOString())}
        popperPlacement="bottom"
        popperContainer={DatepickerPortal}
        customInput={
          <InputWrapper
            {...props}
            editable={false}
            onPress={() => ref.current?.setOpen(true)}
          >
            <Text
              sx={{
                ...theme.text.p,
                lineHeight: 21,
                py: ["$2", "$3"],
                px: ["$3", "$4"],
                color: !!value ? "$black" : "$placeholder",
              }}
            >
              {value ? format(new Date(value), "dd/MM/yyyy") : placeholder}
            </Text>
          </InputWrapper>
        }
      />
    </View>
  );
};
