import { pipe } from "lodash/fp";
import { useRouter } from "solito/router";
import { CurrentUserQuery } from "app/types/generated/schema";
import { ToastProps } from "app/components/use-toast";

type User = CurrentUserQuery["me"];
type UserRole = CurrentUserQuery["me"]["__typename"];
type Path = Parameters<ReturnType<typeof useRouter>["push"]>[0];
type Redirect = Path;

interface UnauthorizedOptions {
  redirect: Redirect;
  toastOptions?: ToastProps;
}

type AuthorizationCheckResult<TUser> =
  | {
      success: true;
      user: TUser;
    }
  | {
      success: false;
      unauthorizedOptions: UnauthorizedOptions;
    };

const createAuthorizationCheckFunction =
  <TModifier,>(predicate: (user: User | null) => boolean) =>
  (unauthorizedOptions: UnauthorizedOptions) =>
  <TUser extends User | null>(
    user: AuthorizationCheckResult<TUser>
  ): AuthorizationCheckResult<TUser & TModifier> => {
    if (!user.success) return user;
    const checkResult = predicate(user.user);
    if (checkResult) {
      return {
        success: true,
        user: user.user as TUser & TModifier,
      };
    }

    return {
      success: false,
      unauthorizedOptions,
    };
  };

export type AuthorizationCheck<TOutputUser> = (
  user: AuthorizationCheckResult<User | null>
) => AuthorizationCheckResult<TOutputUser>;

const hasRole = <TRoles extends UserRole[]>(roles: TRoles) =>
  createAuthorizationCheckFunction<{
    __typename: TRoles[number];
  }>((user) => (user ? roles.includes(user.__typename) : false));

const isVerified = createAuthorizationCheckFunction<{
  account: {
    verified: true;
  };
}>((user) => (user ? !!user.account?.verified : false));

const isCandidateV2 = hasRole(["CandidateV2"]);

const chainAuthorizationRules = pipe;

const rules = {
  isVerified,
  hasRole,
  isCandidateV2,
};

// Types for mapping rule configs to user types
type AuthorizationMap<TInput> = TInput extends {
  name: string;
  authorizer: (
    user: AuthorizationCheckResult<User>
  ) => AuthorizationCheckResult<infer TUser>;
}
  ? {
      [key in TInput["name"]]: TUser & User;
    }
  : never;

type UnionToIntersection<Union> = (
  Union extends any ? (argument: Union) => void : never
) extends (argument: infer Intersection) => void
  ? Intersection
  : never;

type ScreenConfigAuthorizationMap<TInput extends readonly object[]> =
  UnionToIntersection<AuthorizationMap<TInput[number]>>;

export type { ScreenConfigAuthorizationMap };
export { rules, chainAuthorizationRules };
