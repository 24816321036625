import { UrlObject } from "url";
import { IMenuItemProps, Tooltip } from "native-base";
import { View } from "dripsy";
import { JobCardQuery } from "app/types/generated/schema";
import { Menu } from "app/components/menu";
import { pagesPath } from "app/lib/$path";
import { useHrefLinkProps } from "app/hooks";

interface JobCardMenuItemProps extends IMenuItemProps {
  href: UrlObject;
  disabled?: boolean;
}

const DisabledJobCardMenuItem = ({ children }: { children: string }) => (
  <Menu.Item opacity={0.5}>{children}</Menu.Item>
);

const JobCardMenuItem = ({
  href,
  disabled,
  children,
}: JobCardMenuItemProps) => {
  const linkProps = useHrefLinkProps(href);
  return (
    <Menu.Item {...linkProps} opacity={disabled ? 0.5 : 1}>
      {children}
    </Menu.Item>
  );
};

const ViewJobAction = ({ jobId }: { jobId: string }) => (
  <JobCardMenuItem href={pagesPath.jobs._jobId(jobId).$url()}>
    View job
  </JobCardMenuItem>
);

const DisabledMessageEmployerAction = () => (
  <Tooltip label="We've received your application and are waiting for the employer to review it.">
    {/* silly nativebase tooltip issues requiring the view wrap */}
    <View>
      <DisabledJobCardMenuItem>Message employer</DisabledJobCardMenuItem>
    </View>
  </Tooltip>
);

const MessageEmployerAction = ({
  myMatchResponse,
}: {
  myMatchResponse: JobCardQuery["job"]["myMatchResponse"];
}) => {
  if (myMatchResponse?.__typename !== "ApplicationApprovedMatchResponse")
    return null;

  return (
    <JobCardMenuItem
      href={pagesPath.inbox
        ._channelId(myMatchResponse.application.channel.id)
        .$url()}
    >
      Message employer
    </JobCardMenuItem>
  );
};

interface JobCardActionsProps {
  jobId: string;
  myMatchResponse: JobCardQuery["job"]["myMatchResponse"];
}

const DefaultMatchActions = (props: JobCardActionsProps) => (
  <>
    <ViewJobAction {...props} />
  </>
);

const ApplicationStartMatchActions = (props: JobCardActionsProps) => (
  <>
    <ViewJobAction {...props} />
    <JobCardMenuItem href={pagesPath.jobs._jobId(props.jobId).apply.$url()}>
      Continue application
    </JobCardMenuItem>
  </>
);

const ApplyMatchActions = (props: JobCardActionsProps) => (
  <>
    <ViewJobAction {...props} />
    <DisabledMessageEmployerAction />
  </>
);

const ApplicationApprovedMatchActions = (props: JobCardActionsProps) => (
  <>
    <ViewJobAction jobId={props.jobId} />
    <MessageEmployerAction myMatchResponse={props.myMatchResponse} />
  </>
);

export {
  DefaultMatchActions,
  ApplicationStartMatchActions,
  ApplyMatchActions,
  ApplicationApprovedMatchActions,
};
