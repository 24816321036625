import { View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { gql } from "@apollo/client";
import { OrganisationAvatar } from "app/design/avatar";
import {
  CandidateInterviewInviteNotification,
  Notification,
  NotificationType,
  InterviewInviteFieldsFragment,
} from "app/types";
import { pagesPath } from "app/lib/$path";
import {
  Badge,
  NotificationBody,
  Highlight,
  formatInterviewDatetime,
  NotificationContent,
} from "./common";
import { NotificationHeader } from "./header";
import {
  NotificationCardWrapper,
  NotificationCardWrapperProps,
} from "./wrapper";

interface InterviewInviteCardProps
  extends Omit<NotificationCardWrapperProps, "children" | "href"> {
  notification: Pick<CandidateInterviewInviteNotification, "createdAt" | "id"> &
    InterviewInviteFieldsFragment;
}

const InterviewInviteCard = ({
  notification,
  renderHeaderRight,
  ...rest
}: InterviewInviteCardProps) => (
  <NotificationCardWrapper
    notification={notification}
    href={pagesPath.inbox
      ._channelId(notification.interview.application.channel.id)
      .$url()}
    {...rest}
  >
    <View>
      <OrganisationAvatar
        organisation={notification.interview.application.job.organisation}
        size={12}
      />
      <Badge icon={<Feather name="calendar" size={12} color="white" />} />
    </View>
    <NotificationContent>
      <NotificationHeader
        createdAt={notification.createdAt}
        renderHeaderRight={renderHeaderRight}
      >
        You&apos;ve been invited to an interview for{" "}
        <Highlight>{notification.interview.application.job.title}</Highlight>!
      </NotificationHeader>
      <NotificationBody>
        It is scheduled for{" "}
        <Highlight>
          {formatInterviewDatetime(notification.interview.startDatetime)}{" "}
        </Highlight>
        - please let the employer know ASAP if you are not able to attend.
      </NotificationBody>
    </NotificationContent>
  </NotificationCardWrapper>
);

InterviewInviteCard.fragment = gql`
  fragment InterviewInviteFields on CandidateInterviewInviteNotification {
    interview {
      id
      startDatetime
      endDatetime
      application {
        id
        channel {
          id
        }
        job {
          id
          title
          organisation {
            id
            photoUrl
          }
        }
      }
    }
  }
`;

InterviewInviteCard.typeguard = (
  notification: Pick<Notification, "type">
): notification is CandidateInterviewInviteNotification =>
  notification.type === NotificationType.CandidateInterviewInvite;

export { InterviewInviteCard };
