import { List, ListItem } from "app/components/list";
import { LanguageRequirementsLink } from "app/features/profile/forms/language-results/language-requirements-link";
import { Divider } from "./divider";
import { Section, SectionHeader } from "./section";

const ApplicationRequirements = () => (
  <Section>
    <SectionHeader>Application requirements</SectionHeader>

    <List>
      <ListItem>
        This job requires a language pass for non-native English speakers.{"\n"}
        <LanguageRequirementsLink sx={{ color: "$blue", fontWeight: "500" }}>
          Learn more
        </LanguageRequirementsLink>
      </ListItem>
    </List>

    <Divider />
  </Section>
);

export { ApplicationRequirements };
