import { View } from "dripsy";
import { useWindowDimensions } from "react-native";
import { FCC } from "app/types/index";

export const HeaderWrapper: FCC = ({ children }) => {
  const { width } = useWindowDimensions();
  const isSuperNarrow = width <= 350;
  return (
    <View
      sx={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        mt: isSuperNarrow ? 0 : "$7",
        mb: "$2",
      }}
    >
      {children}
    </View>
  );
};
