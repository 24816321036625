import { useCallback, useEffect, useRef } from "react";
import { FieldValues, useController } from "react-hook-form";
import { debounce } from "lodash";
import { Input, InputProps } from "app/design/input";
import {
  useBulletedInput,
  useBulletedInputControl,
} from "app/hooks/use-bulleted-input";

import { FormControlProps } from "./form-control";

interface BulletedInputControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  inputProps: Omit<InputProps, "placeholder"> & {
    placeholderStrings?: string[];
  };
}

export const BulletedInputControl = ({
  inputProps,
  ...controlProps
}: BulletedInputControlProps<$TSFixMe>) => {
  const { placeholderStrings = [], ...rest } = inputProps;
  const { name, control, rules, defaultValue } = controlProps;

  const {
    field: { value: initialValue, onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const { field, setInput, watch } = useBulletedInputControl({
    initialValue,
  });

  const updateValue = useCallback(debounce(onChange, 250), []);

  useEffect(() => {
    const subscription = watch(({ input }) => {
      const blocks = input!
        .split(/\n/)
        .map((block) => block.substring(2, block.length))
        .filter((block) => block.length > 0);

      updateValue(blocks);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cursorPositionRef = useRef<number | undefined>();

  const setCursorPosition = (position: number) => {
    cursorPositionRef.current = position;
  };

  const { maybeRemoveBlock, formatText, BULLET_POINT } = useBulletedInput<{
    target: HTMLTextAreaElement;
    preventDefault: () => void;
  }>({
    setInput,
    setCursorPosition,
    preventDefault: (e) => e.preventDefault(),
    getTarget: (e) => e.target,
  });

  return (
    <Input
      {...field}
      {...rest}
      ref={inputRef}
      onChange={(text) => {
        const formattedText = formatText(text);

        const offset = formattedText.length - text.length;
        cursorPositionRef.current = inputRef.current?.selectionStart
          ? inputRef.current.selectionStart + offset
          : undefined;

        field.onChange(formattedText);
      }}
      onKeyPress={(e: any) => {
        if (e.key !== "Backspace") return;
        maybeRemoveBlock(e);
      }}
      multiline
      selection={
        cursorPositionRef.current
          ? { start: cursorPositionRef.current, end: cursorPositionRef.current }
          : undefined
      }
      onSelectionChange={() => {
        cursorPositionRef.current = undefined;
      }}
      placeholder={placeholderStrings
        .map((string) => `${BULLET_POINT} ${string}`)
        .join("\n")}
      error={error?.message}
    />
  );
};
