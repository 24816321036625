import { gql } from "@apollo/client";
import { H5, View } from "dripsy";
import { useInboxChannelHeaderQuery } from "app/types/generated/schema";
import { GroupAvatar, JobAvatar } from "app/design/avatar";
import { DataError } from "app/components/data-error";
import { Text } from "app/components/text";
import { ChatHeaderProps } from "app/features/chat";
import { ChannelHeader } from "./common";
import { ChannelHeaderLoading } from "./loading";

const INBOX_CHANNEL_HEADER_QUERY = gql`
  query InboxChannelHeader($channelId: MigrateID!) {
    channel(id: $channelId) {
      id
      ... on OrganisationPrivateChannel {
        organisation {
          id
          name
        }
        members {
          count
        }
      }
      ... on ApplicationDMChannel {
        application {
          id
          job {
            id
            title
            organisationName
            organisation {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface InboxChannelHeaderProps extends ChatHeaderProps {}

const InboxChannelHeader = ({ channelId }: InboxChannelHeaderProps) => {
  const { data, loading, error } = useInboxChannelHeaderQuery({
    variables: { channelId },
  });

  if (error)
    return (
      <ChannelHeader>
        <DataError />
      </ChannelHeader>
    );
  if (loading) return <ChannelHeaderLoading />;

  if (data?.channel.__typename === "OrganisationPrivateChannel")
    return (
      <ChannelHeader>
        <GroupAvatar sx={{ mr: "$3" }} />
        <View sx={{ flexShrink: 1 }}>
          <H5 numberOfLines={1}>{data.channel.organisation.name}</H5>
          <Text variant="sm" numberOfLines={1}>
            Group • {data.channel.members.count} participants
          </Text>
        </View>
      </ChannelHeader>
    );

  if (data?.channel.__typename === "ApplicationDMChannel")
    return (
      <ChannelHeader>
        <JobAvatar sx={{ mr: "$3" }} />
        <View sx={{ flexShrink: 1 }}>
          <H5 numberOfLines={1}>
            {data.channel.application.job.organisationName}
          </H5>
          <Text variant="sm" numberOfLines={1}>
            {data.channel.application.job.title}
          </Text>
        </View>
      </ChannelHeader>
    );

  return null;
};

export { InboxChannelHeader, INBOX_CHANNEL_HEADER_QUERY };
