import {
  ToggleControl,
  ToggleControlProps,
} from "app/components/form/toggle-control";
import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "app/components/form/toggle-button";

import { SxProp, View } from "dripsy";
import { memo } from "react";
import { Control, FieldValues, Path } from "react-hook-form";

interface StepToggleProps extends Omit<ToggleControlProps<any>, "children"> {
  sx?: SxProp;
  label: string;
}

export const StepToggle: React.FC<StepToggleProps> = memo(
  ({ sx, label, toggleButtonProps, ...rest }) => (
    <View sx={sx}>
      <ToggleControl
        toggleButtonProps={{
          variant: "homeToggle",
          size: "md",
          sx: {
            borderRadius: "md",
            ...toggleButtonProps?.sx,
          },
          ...toggleButtonProps,
        }}
        {...rest}
      >
        {label}
      </ToggleControl>
    </View>
  )
);
StepToggle.displayName = "StepToggle";

interface StepToggleButtonGroupProps<TFieldValues extends FieldValues>
  extends ToggleButtonGroupProps {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
}

export const StepToggleButtonGroup = <TFieldValues extends FieldValues>({
  sx,
  name,
  control,
  ...props
}: StepToggleButtonGroupProps<TFieldValues>) => {
  return (
    <View sx={sx}>
      <ToggleButtonGroup {...props} />
    </View>
  );
};
