import { ApolloError, NetworkStatus, QueryResult } from "@apollo/client";
import {
  FlashList,
  FlashListProps,
  ListRenderItem as FlashListRenderItem,
} from "@shopify/flash-list";
import { SxProp, View, Text, useSx } from "dripsy";
import { Ref, ReactElement, forwardRef } from "react";
import { FlatListProps, ListRenderItem } from "react-native";
import { ActivityIndicator } from "app/design/activity-indicator";
import {
  FlatList,
  FlatListLoadingMore,
  FlatListBottomSpacer,
} from "app/components/flat-list";
import { DataError } from "app/components/data-error";
import { FCC } from "app/types";
import { getQueryLoadingStatus } from "app/utils";
import { Screen } from "./screen";

const EmptyListWrapper: FCC = ({ children }) => (
  <View sx={{ flexGrow: 1, justifyContent: "center" }}>
    <Text
      sx={{
        color: "$grey",
        fontWeight: "500",
        px: "$4",
        textAlign: "center",
      }}
    >
      {children}
    </Text>
  </View>
);

const defaultLoaderComponent = () => <ActivityIndicator />;

const defaultErrorComponent = (error: ApolloError | undefined) => (
  <Screen>
    <DataError title={error?.message} />
  </Screen>
);

const defaultListFooterComponent = <THookResult extends QueryResult<any, any>>({
  networkStatus,
}: THookResult) =>
  networkStatus === NetworkStatus.fetchMore ? (
    <FlatListLoadingMore sx={{ mb: "$4" }} />
  ) : (
    <FlatListBottomSpacer sx={{ borderTopWidth: 0, mb: "$4" }} />
  );

type ListEmptyComponentInput<THookResult extends QueryResult<any, any>> =
  THookResult & {
    emptyListText?: string;
  };

const defaultListEmptyComponent = <THookResult extends QueryResult<any, any>>({
  emptyListText,
}: ListEmptyComponentInput<THookResult>) => (
  <EmptyListWrapper>{emptyListText}</EmptyListWrapper>
);

const defaultLoading = <THookResult extends QueryResult<any, any>>({
  networkStatus,
}: THookResult) => getQueryLoadingStatus(networkStatus).loading;

const defaultErrorCondition = <THookResult extends QueryResult<any, any>>({
  error,
}: THookResult) => !!error;

interface UseQueryFlatListConfig<
  TItem,
  TData extends TItem[],
  TAfter,
  THookResult extends QueryResult<any, any>
> {
  queryHookResult: THookResult;
  after: TAfter;
  data: TData;
}

const useQueryFlatList = <
  TItem,
  TData extends TItem[],
  TAfter,
  THookResult extends QueryResult<any, any>
>({
  queryHookResult: { networkStatus, fetchMore },
  data,
  after,
}: UseQueryFlatListConfig<TItem, TData, TAfter, THookResult>) => {
  const onEndReached = () => {
    if (networkStatus === NetworkStatus.fetchMore || !data.length || !after)
      return;

    fetchMore({
      variables: {
        after,
      },
    });
  };

  return { onEndReached };
};

type QueryInfiniteScrollListCommonProps<
  /** The data in queryData - may not correspond to the query
   * i.e. if a nested paginated response is used
   */
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
> = {
  queryHookResult: THookResult;
  data?: TItem[];
  after?: TAfter;
  sx?: SxProp;
  containerSx?: SxProp;
  emptyListText?: string;
  loading?: (hookResult: THookResult) => boolean;
  /** The condition on which to render the error component */
  errorCondition?: (hookResult: THookResult) => boolean;
  renderLoaderComponent?: (hookResult?: THookResult) => React.ReactNode;
  renderErrorComponent?: (error: ApolloError | undefined) => React.ReactNode;
  renderListHeaderComponent?: (
    hookResult: THookResult
  ) => FlatListProps<TItem>["ListHeaderComponent"];
  renderListFooterComponent?: (
    hookResult: THookResult
  ) => FlatListProps<TItem>["ListFooterComponent"];
  renderListEmptyComponent?: (
    hookResult: ListEmptyComponentInput<THookResult>
  ) => FlatListProps<TItem>["ListEmptyComponent"];
};

type QueryInfiniteScrollFlatListProps<
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
> = Omit<
  FlatListProps<TItem>,
  | "onEndReached"
  | "data"
  | "ListHeaderComponent"
  | "ListFooterComponent"
  | "ListEmptyComponent"
> &
  QueryInfiniteScrollListCommonProps<TAfter, TItem, THookResult> & {
    // Improved typing from generic
    renderItem: ListRenderItem<TItem> | null;
  };

const QueryInfiniteScrollFlatListWithoutRef = <
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
>(
  {
    queryHookResult,
    data = [],
    after,
    loading = defaultLoading,
    emptyListText = "No data",
    errorCondition = defaultErrorCondition,
    renderLoaderComponent = defaultLoaderComponent,
    renderErrorComponent = defaultErrorComponent,
    renderListFooterComponent = defaultListFooterComponent,
    renderListEmptyComponent = defaultListEmptyComponent,
    renderListHeaderComponent = () => null,
    onEndReachedThreshold = 0.8,
    sx,
    containerSx,
    ...flatListProps
  }: QueryInfiniteScrollFlatListProps<TAfter, TItem, THookResult>,
  ref: Ref<$TSFixMe>
) => {
  const { onEndReached } = useQueryFlatList({ queryHookResult, after, data });
  const { error } = queryHookResult;

  if (loading(queryHookResult)) return <>{renderLoaderComponent()}</>;
  if (errorCondition(queryHookResult) && renderErrorComponent)
    return <>{renderErrorComponent(error)}</>;

  return (
    <FlatList
      ref={ref}
      data={data}
      onEndReachedThreshold={onEndReachedThreshold}
      onEndReached={onEndReached}
      containerSx={containerSx}
      sx={sx}
      ListEmptyComponent={renderListEmptyComponent({
        emptyListText,
        ...queryHookResult,
      })}
      ListFooterComponent={renderListFooterComponent(queryHookResult)}
      ListHeaderComponent={renderListHeaderComponent?.(queryHookResult)}
      {...flatListProps}
    />
  );
};

// Assertion for generic + forwarded ref
const QueryInfiniteScrollFlatList = forwardRef(
  QueryInfiniteScrollFlatListWithoutRef
) as <TAfter, TItem, THookResult extends QueryResult<any, any>>(
  p: QueryInfiniteScrollFlatListProps<TAfter, TItem, THookResult> & {
    ref?: Ref<$TSFixMe>;
  }
) => ReactElement;

type QueryInfiniteScrollFlashListProps<
  TExtraData,
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
> = Omit<
  FlashListProps<TItem>,
  | "onEndReached"
  | "data"
  | "ListHeaderComponent"
  | "ListFooterComponent"
  | "ListEmptyComponent"
  | "extraData"
  | "renderItem"
> &
  Omit<
    QueryInfiniteScrollListCommonProps<TAfter, TItem, THookResult>,
    "renderItem"
  > & {
    extraData?: TExtraData;
    renderItem: FlashListRenderItem<TItem & { extraData?: TExtraData }>;
  };

const QueryInfiniteScrollFlashList = <
  TExtraData,
  TAfter,
  TItem,
  THookResult extends QueryResult<any, any>
>({
  queryHookResult,
  data = [],
  after,
  loading = defaultLoading,
  emptyListText = "No data",
  errorCondition = defaultErrorCondition,
  renderLoaderComponent = defaultLoaderComponent,
  renderErrorComponent = defaultErrorComponent,
  renderListFooterComponent = defaultListFooterComponent,
  renderListEmptyComponent = defaultListEmptyComponent,
  renderListHeaderComponent = () => null,
  onEndReachedThreshold = 0.8,
  sx: _sx = {},
  containerSx = {},
  renderItem,
  extraData,
  ...flashListProps
}: QueryInfiniteScrollFlashListProps<
  TExtraData,
  TAfter,
  TItem,
  THookResult
>) => {
  const sx = useSx();
  const { onEndReached } = useQueryFlatList({ queryHookResult, after, data });
  const { error } = queryHookResult;

  if (loading(queryHookResult)) return <>{renderLoaderComponent()}</>;
  if (errorCondition(queryHookResult) && renderErrorComponent)
    return <>{renderErrorComponent(error)}</>;

  return (
    <FlashList
      data={data}
      // NOTE: CI only
      // @ts-expect-error Complains about incompatibility but we just munge an any type
      renderItem={renderItem}
      extraData={extraData}
      onEndReachedThreshold={onEndReachedThreshold}
      onEndReached={onEndReached}
      style={sx(_sx)}
      contentContainerStyle={sx(containerSx)}
      ListEmptyComponent={renderListEmptyComponent({
        emptyListText,
        ...queryHookResult,
      })}
      ListFooterComponent={renderListFooterComponent(queryHookResult)}
      ListHeaderComponent={renderListHeaderComponent?.(queryHookResult)}
      {...flashListProps}
    />
  );
};

export type {
  ListRenderItem,
  QueryInfiniteScrollFlashListProps,
  QueryInfiniteScrollFlatListProps,
  QueryInfiniteScrollListCommonProps,
};

export {
  QueryInfiniteScrollFlashList,
  QueryInfiniteScrollFlatList,
  EmptyListWrapper,
};
