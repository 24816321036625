import { H4, Sx, View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { ComponentProps } from "react";
import { Text } from "app/components/text";
import { brandColors } from "app/provider/dripsy/colors";

interface ChannelListEmptyProps {
  icon?: ComponentProps<typeof Feather>["name"];
  header?: string;
  text: string;
  footer?: React.ReactElement;
  sx?: Sx;
}

const ChannelListEmpty = ({
  icon = "mail",
  header = "No channels found",
  text,
  footer,
  sx = {},
}: ChannelListEmptyProps) => (
  <View
    sx={{
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      px: "$4",
      ...sx,
    }}
  >
    <Feather name={icon} size={32} color={brandColors.grey} />
    <H4 sx={{ textAlign: "center", color: "$grey" }}>{header}</H4>
    <Text sx={{ textAlign: "center", color: "$grey", fontWeight: "500" }}>
      {text}
    </Text>
    {footer}
  </View>
);

export { ChannelListEmpty };
