import { Match } from "faunadb";
import { ApolloCache, gql, useApolloClient } from "@apollo/client";
import { Scalars, useGetNotificationQuery } from "app/types/generated/schema";

import { useStreaming } from "app/hooks/use-streaming";
import { asImperativeQuery } from "app/hooks/helpers/imperative-query";
import { useToast } from "app/components/use-toast";
import { NotificationCard } from "../notification-card";
import { NotificationToast } from "../notification-card/toast";

const deleteNotificationItemsFromCache = (cache: ApolloCache<{}>) => {
  cache.evict({ id: "ROOT_QUERY", fieldName: "notifications" });
};

const GET_NOTIFICATION_MUTATION = gql`
  ${NotificationCard.fragment}
  query GetNotification($id: MigrateID!) {
    notification(id: $id) {
      ...NotificationFields
    }
  }
`;

const useSubscribeToNotifications = () => {
  const [getNotification] = asImperativeQuery(useGetNotificationQuery);
  const apolloClient = useApolloClient();
  const toast = useToast();
  const { cache } = apolloClient;

  const onNewDocument = async (notificationId: Scalars["MigrateID"]) => {
    const cachedId = cache.identify({
      id: notificationId,
      __typename: "Notification",
    });
    if (cachedId && cachedId in cache.extract()) return;

    const { data } = await getNotification({
      id: notificationId,
    });
    const { notification } = data;
    cache.modify({
      fields: {
        notifications(existing) {
          const newMessageRef = cache.writeFragment({
            data: notification,
            fragment: gql`
              fragment NewNotification on Notification {
                id
              }
            `,
          });
          return {
            ...existing,
            data: [newMessageRef, ...existing.data],
            count: existing ? existing.count + 1 : 1,
          };
        },
      },
    });

    toast.show({
      render: () => (
        <NotificationToast notification={notification} onClose={toast.close} />
      ),
      duration: 15000,
    });
  };

  const onReconnect = () => {
    deleteNotificationItemsFromCache(cache);
  };

  useStreaming(
    {
      setRef: Match("all_notifications"),
    },
    onNewDocument,
    onReconnect
  );
};

export { useSubscribeToNotifications, GET_NOTIFICATION_MUTATION };
