import { UrlObject } from "url";
import { useLink } from "solito/link";
import { useRouter } from "solito/router";

const useHrefWithHash = () => {
  const { parseNextPath } = useRouter();

  const hrefWithHash = (href: UrlObject | string) => {
    if (typeof href === "string") return href;

    // TODO: Handle hashes on native - convert to a param?

    return `${parseNextPath(href)}${href.hash ? href.hash : ""}`;
  };

  return hrefWithHash;
};

export const useHrefLinkProps = (href: UrlObject | string) => {
  const hrefWithHash = useHrefWithHash();

  const linkProps = useLink({
    href: hrefWithHash(href),
  });

  return linkProps;
};
