import { ReactNode } from "react";
import { ScrollView } from "@nandorojo/anchor";
import { styled } from "dripsy";

const DripsyScrollView = styled(ScrollView)();

interface KeyboardAwareScrollViewProps {
  children: ReactNode;
}

export const KeyboardAwareScrollView = ({
  children,
  ...props
}: KeyboardAwareScrollViewProps) => (
  <DripsyScrollView {...props}>{children}</DripsyScrollView>
);
