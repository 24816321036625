import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 65;
const originalHeight = 59;
const aspectRatio = originalWidth / originalHeight;

const Stone1 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="M11.69 10.312c-9.4 8.074-16.932 21.524-7.006 35.916A29.818 29.818 0 0 0 23.41 58.277a34.768 34.768 0 0 0 15.68-.364 34.648 34.648 0 0 0 13.906-7.214C61.64 43.464 67.8 29.633 63.702 16.552 56.414-6.806 29.915-.629 17.607 6.198a36.096 36.096 0 0 0-5.917 4.114Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone1 };
