import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  useCreateLanguageResultMutation,
  useDeleteLanguageResultMutation,
  useUpdateLanguageResultMutation,
  useLanguageResultFormQuery,
  LanguageResult,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const CREATE_QUESTION_RESPONSE_MUTATION = gql`
  mutation CreateLanguageResult($input: CreateLanguageResultInput!) {
    createLanguageResult(input: $input) {
      id
      languageResults {
        id
        awardingBody
        date
        listening
        reading
        writing
        speaking
      }
      meetsLanguageRequirements
      profileCompletion
    }
  }
`;

export const UPDATE_QUESTION_RESPONSE_MUTATION = gql`
  mutation UpdateLanguageResult($input: UpdateLanguageResultInput!) {
    updateLanguageResult(input: $input) {
      id
      languageResults {
        id
        awardingBody
        date
        listening
        reading
        writing
        speaking
      }
      meetsLanguageRequirements
      profileCompletion
    }
  }
`;

export const DELETE_QUESTION_RESPONSE_MULTATION = gql`
  mutation DeleteLanguageResult($input: DeleteLanguageResultInput!) {
    deleteLanguageResult(input: $input) {
      id
      languageResults {
        id
        awardingBody
        date
        listening
        reading
        writing
        speaking
      }
      meetsLanguageRequirements
      profileCompletion
    }
  }
`;

export const useLanguageResultMutations = (id?: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete result entry",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useLanguageResultFormQuery();
  if (error !== undefined)
    throw new Error(
      "unexpected error in useLanguageResultMutations: " + error.message
    );

  const [createLanguageResult] = useCreateLanguageResultMutation();
  const [updateLanguageResult] = useUpdateLanguageResultMutation();
  const [deleteLanguageResult] = useDeleteLanguageResultMutation({
    update: (cache) => {
      cache.modify({
        id: `Candidate:${data?.me.id}`,
        fields: {
          LanguageResults(existing) {
            return {
              ...existing,
              data: existing.data.filter(
                (languageResult: LanguageResult) => languageResult.id !== id
              ),
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteLanguageResult: {
        ...(data?.me as CandidateV2),
      },
    },
  });

  return {
    createLanguageResult,
    updateLanguageResult,
    deleteLanguageResult: () =>
      openModal("ConfirmModal", {
        title: "Confirm delete language result",
        description: "Are you sure you want to delete this language result?",
        confirmLabel: "Delete result",
        onConfirm: withToast(async () => {
          if (!id) throw new Error("language result: id required for deletion");
          await deleteLanguageResult({ variables: { input: { id } } });
        }),
      }),
  };
};
