import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 31;
const originalHeight = 25;
const aspectRatio = originalWidth / originalHeight;

const JobsIcon = ({ width, height, ...rest }: SvgProps) => (
  <View style={{ aspectRatio, width, height }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...rest}
    >
      <Path d="M27,6H23.15V4a4,4,0,0,0-4-4H12.33a4,4,0,0,0-4,4V6H4a4,4,0,0,0-4,4V21a4,4,0,0,0,4,4H27a4,4,0,0,0,4-4V10A4,4,0,0,0,27,6ZM20.71,16.56a.49.49,0,0,1-.5.49H17.79a.5.5,0,0,0-.5.5v2.64a.5.5,0,0,1-.5.5H14.88a.5.5,0,0,1-.5-.5V17.55a.5.5,0,0,0-.5-.5H11.25a.5.5,0,0,1-.5-.5V14.93a.5.5,0,0,1,.5-.5h2.63a.5.5,0,0,0,.5-.5V11.5a.49.49,0,0,1,.2-.39h2.47a.48.48,0,0,1,.2.38l0,2.44a.5.5,0,0,0,.5.5h2.46a.5.5,0,0,1,.5.5ZM21.3,6H10.19V4.07a2.22,2.22,0,0,1,2.22-2.22h6.11A2.79,2.79,0,0,1,21.3,4.63Z" />
    </Svg>
  </View>
);

export { JobsIcon };
