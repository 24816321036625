import { cloneElement, ForwardedRef, forwardRef } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export interface FormControlProps<T extends FieldValues>
  extends UseControllerProps<T> {
  // some trickiness with typing controllers here https://github.com/react-hook-form/react-hook-form/discussions/7354
  control?: $TSFixMe;
  children: React.ReactElement;
}

export const FormControl = forwardRef(
  <T extends FieldValues>(
    props: FormControlProps<T>,
    ref: ForwardedRef<any>
  ) => {
    const { name, control, rules, defaultValue, children } = props;

    const {
      field: { onChange, value },
      fieldState: { error },
    } = useController({ name, control, rules, defaultValue });

    return cloneElement(children, {
      ref,
      value,
      onChange,
      error: error?.message,
    });
  }
);
FormControl.displayName = "FormControl";
