import { gql } from "@apollo/client";
import { H5, Row, View } from "dripsy";
import { useCommunityChannelHeaderQuery } from "app/types/generated/schema";
import { GroupAvatar } from "app/design/avatar";
import { DataError } from "app/components/data-error";
import { Text } from "app/components/text";
import { ChatHeaderProps } from "app/features/chat";
import { ChannelHeader } from "./common";
import { CommunityChannelHeaderMenu } from "./community.menu";
import { ChannelHeaderLoading } from "./loading";
import { SwooshBanner } from "./swoosh-banner";
const COMMUNITY_CHANNEL_HEADER_QUERY = gql`
  query CommunityChannelHeader($channelId: MigrateID!) {
    communityPublicChannel(id: $channelId) {
      id
      mutedUntil
      isMember
      name
      members {
        count
      }
      categories {
        id
        name
      }
    }
  }
`;

interface CommunityChannelHeaderProps extends ChatHeaderProps {}

const CommunityChannelHeader = ({ channelId }: CommunityChannelHeaderProps) => {
  const { data, loading, error } = useCommunityChannelHeaderQuery({
    variables: { channelId },
  });

  if (loading) return <ChannelHeaderLoading />;
  if (error || !data)
    return (
      <ChannelHeader>
        <DataError />
      </ChannelHeader>
    );

  const { name, members, isMember, mutedUntil, categories } =
    data.communityPublicChannel;

  return (
    <>
      <ChannelHeader
        sx={{ justifyContent: "space-between" }}
        renderHeader={() => (
          <SwooshBanner
            show={
              !!categories.find(
                ({ name: categoryName }) => categoryName === "English Language"
              )
            }
          />
        )}
      >
        <Row sx={{ alignItems: "center", flexShrink: 1 }}>
          <GroupAvatar sx={{ mr: "$3" }} />
          <View sx={{ flexShrink: 1 }}>
            <H5>#{name}</H5>
            <Text variant="sm" sx={{ flexShrink: 1 }} numberOfLines={1}>
              Community channel • {members.count} participants
            </Text>
          </View>
        </Row>
        {isMember ? (
          <CommunityChannelHeaderMenu
            channelId={channelId}
            mutedUntil={mutedUntil}
          />
        ) : null}
      </ChannelHeader>
    </>
  );
};

export { COMMUNITY_CHANNEL_HEADER_QUERY, CommunityChannelHeader };
