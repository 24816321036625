import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  ApplicationCompletion,
  ApplicationStartMatchResponse,
  useApplicationQuestionSectionQuery,
  useRemoveApplicationQuestionResponseMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const ANSWER_APPLICATION_QUESTION_MUTATION = gql`
  mutation AnswerApplicationQuestion($input: AnswerApplicationQuestionInput!) {
    answerApplicationQuestion(input: $input) {
      id
      ... on ApplicationStartMatchResponse {
        applicationQuestion {
          response
        }
        applicationCompletion
      }
    }
  }
`;

export const REMOVE_APPLICATION_QUESTION_RESPONSE_MUTATION = gql`
  mutation RemoveApplicationQuestionResponse(
    $input: RemoveApplicationQuestionResponseInput!
  ) {
    removeApplicationQuestionResponse(input: $input) {
      id
      ... on ApplicationStartMatchResponse {
        applicationQuestion {
          response
        }
        applicationCompletion
      }
    }
  }
`;

export const useDeleteApplicationQuestionAnswer = (matchResponseId: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete education entry",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useApplicationQuestionSectionQuery({
    variables: { matchResponseId },
  });

  if (error) console.log(error);

  const { applicationCompletion } =
    data?.matchResponse as ApplicationStartMatchResponse;

  const [deleteApplicationQuestionAnswer] =
    useRemoveApplicationQuestionResponseMutation({
      variables: {
        input: {
          matchResponseId,
        },
      },
      optimisticResponse: {
        removeApplicationQuestionResponse: {
          __typename: "ApplicationStartMatchResponse",
          id: matchResponseId,
          applicationQuestion: {
            __typename: "ApplicationQuestion",
            response: null,
          },
          applicationCompletion: applicationCompletion.filter(
            (badge) => badge !== ApplicationCompletion.HasApplicationQuestion
          ),
        },
      },
    });

  return () =>
    openModal("ConfirmModal", {
      title: "Confirm delete answer",
      description: "Are you sure you want to delete your answer?",
      confirmLabel: "Delete answer",
      onConfirm: withToast(async () => {
        if (!matchResponseId)
          throw new Error("application question: id required for deletion");
        await deleteApplicationQuestionAnswer();
      }),
    });
};
