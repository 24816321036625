import { DripsyFinalTheme, useBreakpointIndex, useDripsyTheme } from "dripsy";

/**
 * takes a callback returning a theme value and returns the actual value.
 * e.g. if the dripsy value is ['$3', '$4'], it will return 12 on mobile screens
 * and 16 on table/desktop screens
 */
export const useThemeValue = (callback: (theme: DripsyFinalTheme) => any) => {
  const { theme } = useDripsyTheme();
  const breakpoint = useBreakpointIndex();
  const dripsyValue = callback(theme);

  let returnValue = dripsyValue;

  if (Array.isArray(dripsyValue)) {
    if (breakpoint > dripsyValue.length - 1)
      returnValue = dripsyValue[dripsyValue.length - 1];
    else returnValue = dripsyValue[breakpoint];
  }

  if (theme.space[returnValue as keyof typeof theme["space"]])
    return theme.space[returnValue as keyof typeof theme["space"]];
  return returnValue;
};
