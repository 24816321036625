import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { copy, enums } from "./en";

export const resources = {
  en: {
    copy,
    enums,
  },
} as const;

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources,
});

// eslint-disable-next-line import/no-default-export
export default i18n;
