import { ForwardedRef, forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { PasswordInputProps, PasswordInput } from "app/design/input";
import { FormControl, FormControlProps } from "./form-control";

export interface PasswordControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  inputProps?: PasswordInputProps;
}

export const PasswordControl = forwardRef(
  <T extends FieldValues>(
    props: PasswordControlProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { inputProps, ...rest } = props;

    return (
      <FormControl {...rest}>
        <PasswordInput ref={ref} {...inputProps} />
      </FormControl>
    );
  }
);
PasswordControl.displayName = "PasswordControl";
