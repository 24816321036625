import { useState, useRef } from "react";
import { FlatList, Platform } from "react-native";
import { Text, useDripsyTheme, View } from "dripsy";
import { gql, NetworkStatus } from "@apollo/client";
import { Feather } from "@expo/vector-icons";
import { useModal } from "react-native-modalfy";
import { QuestionsQuery, useQuestionsQuery } from "app/types/generated/schema";
import { useEffectAfterRender } from "app/hooks/use-effect-after-render";
import { Screen } from "app/components/screen";

import {
  QueryInfiniteScrollFlatListScreen,
  ListRenderItem,
  ListEmptyComponentText,
} from "app/components/screen/query-infinite-scroll-flat-list-screen";
import { FloatyButton } from "app/components/floaty-button";
import {
  FlatListBottomSpacer,
  FlatListItemWithSidebar,
  FlatListLoadingMore,
} from "app/components/flat-list.common";
import { pagesPath } from "app/lib/$path";
import { conditionalParam } from "app/utils";

import { useScreenSize } from "app/hooks";
import { memoizeScreen } from "app/utils/screen";
import { Layout } from "../../layout";
import { PressableQuestionCard } from "./question";
import { QuestionFiltersLoading, QuestionsFilters } from "./questions-filters";
import { ANSWER_FRAGMENT } from "./answer";
import { SortFilterOption } from "./questions-filters/types";
import { CardLoading } from "./card";

const isAndroid = Platform.OS === "android";

const screenSx = {
  containerSx: { width: "100%" },
  // workaround for no overflow: visible in scroll views on android
  sx: isAndroid ? { px: 0, pt: 0 } : {},
};

const QUESTIONS_QUERY = gql`
  ${ANSWER_FRAGMENT}
  query Questions(
    $where: QuestionsWhereInput
    $orderBy: QuestionsOrderBy
    $size: Int
    $after: String
  ) {
    questions(where: $where, orderBy: $orderBy, _cursor: $after, _size: $size) {
      data {
        id
        title
        createdAt
        topics {
          id
          name
        }
        highlightedAnswer {
          ...AnswerFragment
        }
        answers {
          count
        }
        author {
          id
          forenames
          photoUrl
          isActive
        }
      }
      after
    }
  }
`;

const QuestionsScreenDataLoading = () => (
  <>
    {Array.from({ length: 7 }).map((_, i) => (
      <FlatListItemWithSidebar key={i} sx={{ mb: "$4" }}>
        <CardLoading key={i} />
      </FlatListItemWithSidebar>
    ))}
  </>
);

const QuestionsScreenLoading = () => {
  return (
    <Screen {...screenSx}>
      <QuestionFiltersLoading />
      <QuestionsScreenDataLoading />
    </Screen>
  );
};

type Question = QuestionsQuery["questions"]["data"][number];

const WorkaroundNoOverflowVisibleOnAndroid = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { theme } = useDripsyTheme();
  return (
    <FlatListItemWithSidebar
      sx={{
        mb: "$4",
        mx: isAndroid ? theme.layout.screen.container.padding : "$0",
      }}
    >
      {children}
    </FlatListItemWithSidebar>
  );
};

const renderItem: ListRenderItem<Question> = ({ item: question }) => (
  <WorkaroundNoOverflowVisibleOnAndroid>
    <PressableQuestionCard
      key={question.id}
      questionId={question.id}
      {...question}
    />
  </WorkaroundNoOverflowVisibleOnAndroid>
);

const CommunityQuestionsScreen = memoizeScreen(() => {
  const { openModal } = useModal();
  const { isWide } = useScreenSize();
  const { theme } = useDripsyTheme();

  const [orderBy, setOrderBy] = useState(SortFilterOption.LATEST);
  const [topicIds, setTopicIds] = useState<string[]>([]);

  const queryResponse = useQuestionsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const flatListRef = useRef<FlatList>(null);

  useEffectAfterRender(() => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
    queryResponse.refetch({
      where: {
        ...conditionalParam(
          {
            topic: {
              in: topicIds,
            },
          },
          topicIds.length > 0
        ),
        ...conditionalParam(
          {
            answered: {
              in: [orderBy === SortFilterOption.ANSWERED],
            },
          },
          orderBy !== SortFilterOption.LATEST
        ),
      },
    });
  }, [orderBy, topicIds]);

  const { data, after } = queryResponse.data?.questions || {};

  return (
    <>
      <QueryInfiniteScrollFlatListScreen
        ref={flatListRef}
        {...screenSx}
        queryHookResult={queryResponse}
        data={data}
        after={after}
        keyExtractor={(question) => question.id}
        // Overload loading to ignore refetch and setvariables
        loading={({ networkStatus }) => networkStatus === NetworkStatus.loading}
        renderLoaderComponent={() => <QuestionsScreenLoading />}
        stickyHeaderIndices={[0]}
        renderItem={renderItem}
        renderListHeaderComponent={() => (
          <View
            sx={{
              pt: isAndroid ? theme.layout.screen.container.padding : "$0",
              px: isAndroid ? theme.layout.screen.container.padding : "$0",
            }}
          >
            <QuestionsFilters
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              topicIds={topicIds}
              setTopicIds={setTopicIds}
            />
          </View>
        )}
        renderListFooterComponent={({ networkStatus }) =>
          networkStatus === NetworkStatus.fetchMore ? (
            <FlatListItemWithSidebar>
              <FlatListLoadingMore sx={{ mb: "$4" }} />
            </FlatListItemWithSidebar>
          ) : (
            <FlatListBottomSpacer sx={{ borderTopWidth: 0, mb: "$4" }} />
          )
        }
        renderListEmptyComponent={({ networkStatus }) =>
          networkStatus === NetworkStatus.setVariables ? (
            <QuestionsScreenDataLoading />
          ) : (
            <>
              <ListEmptyComponentText>
                No questions found
              </ListEmptyComponentText>
              <Text>Change your filters or ask your own question!</Text>
            </>
          )
        }
      />
      {!isWide ? (
        <FloatyButton
          icon={<Feather name="edit-3" size={24} />}
          onPress={() => {
            openModal("AskQuestionModal", undefined);
          }}
        >
          Ask question
        </FloatyButton>
      ) : null}
    </>
  );
});

const headerProps = {
  backHref: pagesPath.community.$url(),
  title: "Questions",
  showTitleOnDesktop: true,
  headerSx: {
    boxShadow: [null, "default"],
  },
};

CommunityQuestionsScreen.displayName = "CommunityQuestionsScreen";
CommunityQuestionsScreen.headerProps = headerProps;
CommunityQuestionsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export {
  CommunityQuestionsScreen,
  QuestionsScreenLoading,
  QuestionsScreenDataLoading,
  QUESTIONS_QUERY,
};
