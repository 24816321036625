import { Row, Text } from "dripsy";
import { useCurrentUser } from "app/hooks/use-current-user";
import { Accent } from "app/design/card";
import { DataError } from "app/components/data-error";
import { PressableCard } from "app/components/pressable-card";
import { pagesPath } from "app/lib/$path";
import { BaseContent, UserHeader } from "./questions/user-header";

export const CommunityProfileCard = () => {
  const { currentUser } = useCurrentUser();

  if (!currentUser)
    return <DataError sx={{ width: [null, null, null, "50%"] }} />;

  return (
    <PressableCard
      containerSx={{ width: [null, null, null, "50%"] }}
      sx={{
        p: ["$4"],
        borderRadius: 8,
        textDecorationLine: "none",
      }}
      href={pagesPath.community.activity.$url()}
      accessibilityLabel="View profile"
    >
      <Accent />
      <Row>
        <UserHeader userId={currentUser.id} sx={{ mb: 0 }}>
          {(user) => (
            <BaseContent
              userId={user.id}
              name={user.forenames}
              photoUrl={user.photoUrl}
              variant="lg"
            >
              <Text variant="link" sx={{ color: "$blue" }}>
                Show community activity
              </Text>
            </BaseContent>
          )}
        </UserHeader>
      </Row>
    </PressableCard>
  );
};
