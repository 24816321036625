import { gql } from "@apollo/client";
import { useEffect } from "react";
import { KeyboardAvoidingView } from "react-native";

import { Scalars, useChannelMessagesQuery } from "app/types/generated/schema";
import { MESSAGE_AUTHOR_FIELDS_FRAGMENT } from "app/hooks/message/use-send-message";
import { AnimateHeight } from "app/components/animate-height";
import { getQueryLoadingStatus } from "app/utils";

import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { TAB_BUTTON_SIZE } from "../layout/tabs";
import { useChatStore } from "./hooks/use-chat-store";
import { MessageList } from "./message/message-list";
import { ChatFooter, FOOTER_PADDING_Y_MOBILE } from "./footer";
import { ReplyingTo } from "./replying-to";

const MARK_CHANNEL_MUTATION = gql`
  mutation MarkChannel($input: MarkChannelInput!) {
    markChannel(input: $input) {
      id
      unread
    }
  }
`;

// TODO: Improve with fragment (SEE USE-SEND-MESSAGE)
const MESSAGES_QUERY = gql`
  ${MESSAGE_AUTHOR_FIELDS_FRAGMENT}
  query ChannelMessages($id: MigrateID!, $after: String) {
    messages(where: { channel: { in: [$id] } }, _cursor: $after) {
      data {
        id
        content
        createdAt
        author {
          ...MessageAuthorFields
        }
        isSent
        sendError @client
        attachment {
          id
          filename
          url
        }
        deletedAt
        referencedMessage {
          id
          attachment {
            id
            filename
            url
          }
          content
          author {
            id
            displayName
          }
          deletedAt
        }
      }
      after
    }
    channel(id: $id) {
      id
      unread
      ... on WithName {
        name
      }
      ... on CommunityPublicChannel {
        isMember
      }
    }
  }
`;

interface ChatHeaderProps {
  channelId: Scalars["MigrateID"];
}

type ChatHeaderComponent = ({
  channelId,
}: ChatHeaderProps) => JSX.Element | null;

interface ChatFooterProps {
  channelId: Scalars["MigrateID"];
  disabled?: boolean;
}

type ChatFooterComponent = ({
  channelId,
  disabled,
}: ChatFooterProps) => JSX.Element | null;

interface ChatProps {
  channelId: Scalars["MigrateID"];
  HeaderComponent?: ChatHeaderComponent;
  FooterComponent?: ChatFooterComponent;
}

const EXTRA_PADDING_BOTTOM = 78;

const Chat: React.FC<ChatProps> = ({
  channelId,
  HeaderComponent = null,
  FooterComponent = ({ disabled = false }) => (
    <ChatFooter channelId={channelId} disabled={disabled} />
  ),
}) => {
  const safeArea = useSafeArea();
  const { replyingTo, setReplyingTo, setChatName } = useChatStore();

  const { data, networkStatus, fetchMore, error } = useChannelMessagesQuery({
    variables: { id: channelId },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const { loading } = getQueryLoadingStatus(networkStatus);

  useEffect(() => {
    setReplyingTo(null);
  }, [channelId]);

  useEffect(() => {
    if (data?.channel.__typename !== "CommunityPublicChannel") return;
    if (data?.channel.name) setChatName(`#${data.channel.name}`);
    return () => {
      setChatName(null);
    };
  }, [data?.channel]);

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior="padding"
      keyboardVerticalOffset={
        safeArea.bottom + FOOTER_PADDING_Y_MOBILE + EXTRA_PADDING_BOTTOM
      }
    >
      {HeaderComponent ? <HeaderComponent channelId={channelId} /> : null}

      <MessageList
        channel={data?.channel}
        data={data?.messages?.data}
        error={error}
        after={data?.messages?.after}
        networkStatus={networkStatus}
        fetchMore={fetchMore}
      />

      <AnimateHeight hide={!replyingTo}>
        {replyingTo ? <ReplyingTo /> : null}
      </AnimateHeight>

      <FooterComponent channelId={channelId} disabled={!!error || loading} />
    </KeyboardAvoidingView>
  );
};

export type {
  ChatProps,
  ChatFooterProps,
  ChatFooterComponent,
  ChatHeaderProps,
  ChatHeaderComponent,
};

export { Chat, MARK_CHANNEL_MUTATION, MESSAGES_QUERY };
