import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { Input, InputProps } from "app/design/input";
import { FormControl, FormControlProps } from "./form-control";

export interface InputControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  inputProps?: InputProps;
}

export const InputControl = forwardRef(
  <T extends FieldValues>(props: InputControlProps<T>, ref: any) => {
    const { inputProps, ...rest } = props;

    return (
      <FormControl {...rest}>
        <Input ref={ref} {...inputProps} />
      </FormControl>
    );
  }
);
InputControl.displayName = "InputControl";
