import { useQuestionsQuery } from "app/types/generated/schema";
import { useAuthenticatedUser } from "app/hooks/use-current-user";
import { conditionalParam } from "app/utils";
import { DetailedQuestionList } from "../questions/detailed-question-list";
import { useDetailedQuestions } from "../questions/use-detailed-questions";

const QuestionsAsked = () => {
  const { currentUser } = useAuthenticatedUser();
  const { data, error, fetchMore, networkStatus } = useDetailedQuestions(
    useQuestionsQuery,
    {
      where: {
        ...conditionalParam(
          {
            author: {
              in: [currentUser.id],
            },
          },
          !!currentUser
        ),
      },
    },
    { skip: !currentUser }
  );

  return (
    <DetailedQuestionList
      questions={data?.questions.data.map((question) => ({
        ...question,
        isDeleted: false,
      }))}
      after={data?.questions.after}
      error={error}
      errorMessagePretty="Couldn't load questions asked by you"
      fetchMore={fetchMore}
      networkStatus={networkStatus}
    />
  );
};

export { QuestionsAsked };
