import { styled, View, useDripsyTheme, SxProp } from "dripsy";
import { MotiView } from "moti";
import { merge } from "lodash";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import {
  ColorAnimatedView,
  ColorProperty,
  ColorStates,
} from "../components/color-animated-view";

const DripsyMotiView = styled(MotiView)();

export const AnimatedCheckIcon: React.FC<{
  sx?: SxProp;
  checked?: boolean;
  iconSize?: number[];
  shouldAnimateOpacity?: boolean;
  colorStates?: ColorStates;
  checkedStates?: {
    [key in ColorProperty]?: {
      to: string;
      from: string;
    };
  };
  disabled?: boolean;
}> = ({
  sx: _sx,
  checked = false,
  iconSize = [16, 20],
  shouldAnimateOpacity = true,
  colorStates: _colorStates = {},
  checkedStates = {},
  disabled = false,
}) => {
  const { theme } = useDripsyTheme();

  const defaultColorStates = {
    borderColor: {
      states: [
        [checked, theme.colors.$blue],
        ["hovered", theme.colors.$blue],
      ],
      initialColor: theme.colors.$ui,
    },
  };

  const checkedColorStates = Object.keys(checkedStates).reduce(
    (acc, property) => ({
      ...acc,
      [property]: {
        states: [[checked, checkedStates[property as ColorProperty]!.to]],
        initialColor: checkedStates[property as ColorProperty]!.from,
      },
    }),
    {}
  );

  const colorStates = merge(
    {},
    defaultColorStates,
    checkedColorStates,
    _colorStates
  );

  const getIconOffset = () => {
    // Keep icon centered as icon box grows responsively
    if (iconSize.length === 0) return 0;
    if (iconSize.length === 1) return iconSize[0]! / 2;
    return (iconSize[1]! - iconSize[0]!) / 2;
  };

  return (
    <View sx={_sx}>
      <ColorAnimatedView
        colorStates={colorStates}
        animate={{
          opacity: shouldAnimateOpacity && checked ? 0 : 1,
        }}
        sx={{
          borderRadius: "sm",
          backgroundColor: "$uiAlpha",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "$ui",
          width: iconSize,
          height: iconSize,
        }}
        disabled={disabled}
        duration={theme.transitionDurations.speedy}
      />
      <DripsyMotiView
        sx={{
          position: "absolute",
          left: [0, getIconOffset()],
          top: [0, getIconOffset()],
        }}
        animate={{
          opacity: checked ? 1 : 0,
        }}
      >
        <MaterialCommunityIcons
          name="check-bold"
          size={iconSize[0]}
          color={theme.colors.$white}
        />
      </DripsyMotiView>
    </View>
  );
};
