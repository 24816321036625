import {
  useDripsyTheme,
  Sx,
  SxProp,
  TextInput,
  View,
  styled,
  useSx,
} from "dripsy";
import { useState, forwardRef, useRef, ReactElement, ReactNode } from "react";
import {
  Button,
  TextInputProps,
  Platform,
  TextInput as RNTextInput,
  Keyboard,
} from "react-native";
import { useHover } from "@react-native-aria/interactions";
import { Feather } from "@expo/vector-icons";
import { DripsyFinalTheme } from "@dripsy/core";
import { MotiPressable } from "moti/interactions";
import { FCC } from "app/types/index";
import { alphaColor } from "app/design/utils";
import { ColorAnimatedView } from "app/components/color-animated-view";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { Text } from "app/components/text";
import { InputAccessory } from "app/components/input-accessory";
import { mergeRefs } from "app/utils/react";

const isAndroid = Platform.OS === "android";
const isWeb = Platform.OS === "web";

const DripsyMotiPressable = styled(MotiPressable)();

interface SharedProps {
  sx?: Sx;
  containerSx?: Sx;
  label?: string;
  labelSx?: Sx;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  helperTextSx?: SxProp;
  InputLeftElement?: ReactElement;
  InputRightElement?: ReactElement;
  passFlexValues?: boolean;
  borderViewSx?: Sx;
  onChange?: (text: string) => void;
  onBlur?: () => void;
  // only when editable=false
  onPress?: () => void;
}

export interface InputWrapperProps extends SharedProps {
  focused?: boolean;
  onFocus?: () => void;
  accessibilityLabel?: string;
  editable?: boolean;
  children?: ReactNode;
}

export interface InputProps
  extends SharedProps,
    Omit<TextInputProps, "onChange" | "onBlur"> {
  showToolbar?: boolean;
}

interface InputFooterItemProps {
  color: keyof DripsyFinalTheme["colors"];
  sx?: SxProp;
}

const InputFooterItem: FCC<InputFooterItemProps> = ({
  color,
  children,
  sx,
}) => (
  <Text variant="sm" sx={{ color, mt: "$2", fontWeight: "500", ...sx }}>
    {children}
  </Text>
);

export const ErrorFooter: FCC = ({ children }) => (
  <InputFooterItem color="$error">{children}</InputFooterItem>
);

interface HelperFooterProps {
  sx?: SxProp;
}
const HelperFooter: FCC<HelperFooterProps> = ({ children, sx }) => (
  <InputFooterItem sx={sx} color="$input">
    {children}
  </InputFooterItem>
);

interface InputFooterProps {
  error?: InputProps["error"];
  helperText?: InputProps["helperText"];
  helperTextSx?: InputProps["helperTextSx"];
}

export const InputFooter = ({
  error,
  helperText,
  helperTextSx,
}: InputFooterProps) => {
  if (error) return <ErrorFooter>{error}</ErrorFooter>;
  if (helperText)
    return <HelperFooter sx={helperTextSx}>{helperText}</HelperFooter>;
  return null;
};

interface InputLabelProps {
  accessibilityLabel?: string;
  disabled?: boolean;
  onPress?: () => void;
  sx?: SxProp;
}

export const InputLabel: FCC<InputLabelProps> = ({
  sx = {},
  accessibilityLabel,
  disabled,
  onPress,
  children,
}) => {
  const { theme } = useDripsyTheme();

  return (
    <Text
      sx={{
        ...theme.h4,
        mt: 0,
        mb: "$2",
        color: disabled ? "$input" : "$black",
        cursor: "default",
        alignSelf: "flex-start",
        ...sx,
      }}
      accessible
      accessibilityLabel={
        isAndroid
          ? accessibilityLabel
          : `${accessibilityLabel} ${disabled ? ": Disabled!" : ""}`
      }
      accessibilityRole={isWeb ? "label" : undefined}
      focusable={false}
      accessibilityState={{ disabled }}
      onPress={onPress}
    >
      {children}
    </Text>
  );
};

export const InputWrapper = ({
  sx: _sx = {},
  containerSx = {},
  label,
  labelSx = {},
  error,
  helperText,
  helperTextSx = {},
  disabled = false,
  InputLeftElement,
  InputRightElement,
  focused = false,
  onPress,
  onFocus,
  borderViewSx = {},
  accessibilityLabel,
  passFlexValues = false,
  editable,
  children,
}: InputWrapperProps) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  const shouldRenderErrorState = error !== undefined && !focused;

  // TODO: Add a hook (I can't think of a better name at the moment)
  // = usePassFlexValues(condition, sx);
  const passedFlexValues = passFlexValues
    ? {
        flex: _sx?.flex,
        flexGrow: _sx?.flexGrow,
        flexShrink: _sx?.flexShrink,
        flexBasis: _sx?.flexBasis,
      }
    : {};

  return (
    <View sx={{ mb: "$3", opacity: disabled ? 0.6 : 1, ...containerSx }}>
      {label ? (
        <InputLabel
          sx={labelSx}
          accessibilityLabel={accessibilityLabel}
          disabled={disabled}
          onPress={onFocus}
        >
          {label}
        </InputLabel>
      ) : null}
      {/* disabled pressable enables hoverable states */}
      <DripsyMotiPressable
        containerStyle={sx({ ...passedFlexValues })}
        sx={{ ...passedFlexValues }}
        disabled={editable !== false}
        onPress={onPress}
      >
        <ColorAnimatedView
          colorStates={{
            borderColor: {
              states: [
                [disabled, theme.colors.$ui],
                [shouldRenderErrorState, theme.colors.$error],
                [focused, theme.colors.$blue],
                ["hovered", theme.colors.$blue],
              ],
              initialColor: shouldRenderErrorState
                ? theme.colors.$error
                : theme.colors.$ui,
            },
          }}
          sx={{
            borderWidth: 1,
            borderColor: "$ui",
            borderRadius: 4,
            overflow: "hidden",
            backgroundColor: "$white",
            ...(Platform.OS === "web" && {
              transition: `box-shadow ${theme.transitionDurations.normal}ms`,
            }),
            ...((shouldRenderErrorState && {
              borderColor: "$error",
              ...(Platform.OS === "web" && {
                boxShadow: `0 0 0 2px ${alphaColor(theme.colors.$error, 0.2)}`,
              }),
            }) ||
              {}),
            ...(focused &&
              Platform.OS === "web" && {
                boxShadow: `0 0 0 2px ${alphaColor(theme.colors.$blue, 0.5)}`,
              }),
            ...borderViewSx,
            ...passedFlexValues,
          }}
        >
          <View
            sx={{
              flexDirection: "row",
              alignItems: "center",
              ...passedFlexValues,
            }}
          >
            {InputLeftElement}
            {children}
            {InputRightElement}
          </View>
        </ColorAnimatedView>
      </DripsyMotiPressable>
      <InputFooter
        error={error}
        helperText={helperText}
        helperTextSx={helperTextSx}
      />
    </View>
  );
};

export const Input = forwardRef((props: InputProps, externalRef: any) => {
  // pull out textInputProps so we're not accidentally passing anything else to TextInput
  const {
    sx = {},
    placeholder,
    disabled = false,
    value,
    onChange,
    onBlur,
    showToolbar = true,
    multiline = false,
    // TODO: There must be a better way ha!
    onPress: _onPress,
    containerSx: _containerSx,
    label: _label,
    error: _error,
    helperText: _helperText,
    helperTextSx: _helperTextSx,
    InputLeftElement: _InputLeftElement,
    InputRightElement: _InputRightElement,
    borderViewSx: _borderViewSx,
    accessibilityLabel: _accessibilityLabel,
    passFlexValues: _passFlexValues,
    editable: _editable,

    ...textInputProps
  } = props;

  const inputAccessoryViewID = "keyboard-toolbar";

  const localRef = useRef<RNTextInput | null>(null);
  const { theme } = useDripsyTheme();
  const [focused, setFocused] = useState(false);

  const _ref = useRef(null);
  const { hoverProps } = useHover({}, _ref);

  return (
    <InputWrapper
      {...props}
      focused={focused}
      onFocus={() => localRef.current?.focus()}
    >
      <TextInput
        ref={mergeRefs([externalRef, localRef])}
        inputAccessoryViewID={inputAccessoryViewID}
        sx={{
          fontSize: theme.text.p.fontSize,
          color: "$black",
          px: ["$3", "$4"],
          cursor: disabled ? "not-allowed" : "text",
          flexGrow: 1,
          alignSelf: "stretch",
          bg: "$white",
          // Native treats single-line input line-height oddly
          ...(Platform.OS === "web" || multiline
            ? {
                lineHeight: 21,
                py: ["$2", "$3"],
              }
            : {
                py: [10, 14],
              }),
          ...(Platform.OS === "web" && {
            outlineWidth: 0,
            outlineStyle: "solid",
          }),
          ...sx,
          justifyContent: "flex-start",
        }}
        multiline={multiline}
        textAlignVertical="top"
        placeholder={placeholder}
        // @ts-expect-error wtf!!!
        placeholderTextColor={theme.colors.$placeholder}
        editable={!disabled}
        selectTextOnFocus={!disabled}
        value={value || ""}
        onChangeText={onChange}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          onBlur?.();
          setFocused(false);
        }}
        disabled={disabled}
        {...hoverProps}
        {...textInputProps}
      />
      {showToolbar && (
        <InputAccessory nativeID={inputAccessoryViewID}>
          <Button title="Done" onPress={Keyboard.dismiss} />
        </InputAccessory>
      )}
    </InputWrapper>
  );
});

Input.displayName = "Input";

export interface PasswordInputProps extends InputProps {
  showSuccessState?: boolean;
}

export const PasswordInput = forwardRef(
  (props: PasswordInputProps, ref: any) => {
    const { showSuccessState = true } = props;
    const { theme } = useDripsyTheme();
    const [show, setShow] = useState(false);

    const valid = props.value && props.value.length > 7;

    return (
      <Input
        ref={ref}
        {...props}
        placeholder={props.placeholder || "••••••••"}
        secureTextEntry={!show}
        helperText={
          showSuccessState
            ? `${valid ? "✓ " : ""}Please enter at least 8 characters`
            : undefined
        }
        helperTextSx={{
          color: props.value && props.value.length > 7 ? "$success" : "$input",
        }}
        InputRightElement={
          <MotiPressable
            onPress={() => setShow(!show)}
            containerStyle={{ marginRight: theme.space.$2 }}
          >
            <ColorAnimatedIcon
              icon={<Feather name={show ? "eye" : "eye-off"} size={20} />}
              colorStates={{
                color: {
                  states: [["hovered", theme.colors.$orange]],
                  initialColor: theme.colors.$grey,
                },
              }}
            />
          </MotiPressable>
        }
      />
    );
  }
);
PasswordInput.displayName = "PasswordInput";
