import { View } from "dripsy";
import { ComponentProps } from "react";
import { conditionalParam } from "app/utils";
import { useChatStore } from "../../hooks/use-chat-store";
import { MessageBubbleProps, MessageBubble } from "./message-bubble";
import { useBubbleTheme, BubbleVariant } from "./bubble";

interface MessageBubbleContainerProps
  extends Omit<ComponentProps<typeof View>, "variant"> {
  variant: BubbleVariant;
}

const MessageBubbleContainer = ({
  variant,
  children,
  sx: _sx,
  ...props
}: MessageBubbleContainerProps) => {
  const getTheme = useBubbleTheme();
  const { _container } = getTheme(variant);

  return (
    <View
      sx={{
        flexShrink: 1,
        alignItems: "flex-start",
        ..._container,
        ..._sx,
      }}
      {...props}
    >
      {children}
    </View>
  );
};

const SentMessageBubble = ({
  message,
  variant = "default",
  sx,
  ...bubbleProps
}: MessageBubbleProps) => {
  const { replyingTo } = useChatStore();

  return (
    <MessageBubbleContainer variant={variant}>
      <MessageBubble
        message={message}
        variant={variant}
        sx={{
          ...conditionalParam(
            {
              boxShadow: "highlight",
            },
            replyingTo?.id === message.id
          ),
          ...sx,
        }}
        {...bubbleProps}
      />
    </MessageBubbleContainer>
  );
};

export * from "./header";
export * from "./bubble";
export * from "./message-bubble";

export { MessageBubbleContainer, SentMessageBubble };
