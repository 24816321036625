import React from "react";
import { Sx, View } from "dripsy";
import { PressableCardProps } from "app/components/pressable-card";
import { NotificationFieldsFragment } from "app/types";
import { NOTIFICATION_FIELDS_FRAGMENT } from "../hooks/use-notifications-query";
import { QuestionAnsweredCard } from "./question-answered";
import { InterviewUpdatedCard } from "./interview-updated";
import { InterviewInviteCard } from "./interview-invite";
import { ApplicationRejectedCard } from "./application-rejected";
import { CanReapplyCard } from "./can-reapply";
import { NotificationCardLoading } from "./loading";
import { NotificationHeaderProps } from "./header";

interface NotificationCardProps extends PressableCardProps {
  notification: NotificationFieldsFragment;
  textSx?: Sx;
  renderHeaderRight?: NotificationHeaderProps["renderHeaderRight"];
}

const NotificationCard = ({ notification, ...rest }: NotificationCardProps) => {
  const { unread = false } = notification;

  const commonProps = {
    unread,
    ...rest,
  };

  return (() => {
    if (ApplicationRejectedCard.typeguard(notification))
      return (
        <ApplicationRejectedCard {...commonProps} notification={notification} />
      );
    if (CanReapplyCard.typeguard(notification))
      return <CanReapplyCard {...commonProps} notification={notification} />;
    if (QuestionAnsweredCard.typeguard(notification))
      return (
        <QuestionAnsweredCard {...commonProps} notification={notification} />
      );
    if (InterviewInviteCard.typeguard(notification))
      return (
        <InterviewInviteCard {...commonProps} notification={notification} />
      );
    if (InterviewUpdatedCard.typeguard(notification))
      return (
        <InterviewUpdatedCard {...commonProps} notification={notification} />
      );

    return null;
  })();
};

NotificationCard.fragment = NOTIFICATION_FIELDS_FRAGMENT;

const NotificationDivider = () => (
  <View
    sx={{
      position: "absolute",
      left: ["$4", "$6"],
      right: ["$4", "$6"],
      borderBottomWidth: 1,
      borderColor: "$ui",
      bottom: 0,
    }}
  />
);

interface NotificationCardGroupProps {
  divider?: boolean;
  children:
    | React.ReactElement<NotificationCardProps>
    | React.ReactElement<NotificationCardProps>[];
}

const NotificationCardGroup = ({
  divider = true,
  children,
}: NotificationCardGroupProps) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<NotificationCardProps>[];

  return (
    <>
      {React.Children.map(childrenArray, (child, i) => {
        return (
          <View>
            {React.cloneElement(child, {
              key: `notification-card-group-child-${i}`,
            })}
            {divider && i < childrenArray.length - 1 ? (
              <NotificationDivider />
            ) : null}
          </View>
        );
      })}
    </>
  );
};

export type { NotificationCardProps, NotificationCardGroupProps };
export {
  NotificationCard,
  NotificationDivider,
  NotificationCardGroup,
  NotificationCardLoading,
};
