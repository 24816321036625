import { ApolloError, NetworkStatus } from "@apollo/client";
import { Text, useDripsyTheme, View } from "dripsy";
import {
  QuestionsQuery,
  QuestionsQueryResult,
} from "app/types/generated/schema";
import { FCC } from "app/types/index";
import { DataError } from "app/components/data-error";
import { QueryButtonPagination } from "app/components/pagination";
import { getQueryLoadingStatus } from "app/utils";
import {
  DeletedQuestionCard,
  PressableQuestionCard,
  QuestionCardLoading,
} from "./question";

const QuestionListWrapper: FCC = ({ children }) => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        mb: 0 - theme.space.$2,
        flex: 1,
      }}
    >
      {children}
    </View>
  );
};

type Question = QuestionsQuery["questions"]["data"][number] & {
  isDeleted: false;
};
type DeletedQuestion = {
  id: string;
  highlightedAnswer: NonNullable<
    QuestionsQuery["questions"]["data"][number]["highlightedAnswer"]
  >;
  isDeleted: true;
};

interface DetailedQuestionListProps {
  questions?: (Question | DeletedQuestion)[];
  error?: ApolloError;
  errorMessagePretty?: string;
  after?: string;
  fetchMore: QuestionsQueryResult["fetchMore"];
  networkStatus: NetworkStatus;
}

const DetailedQuestionList: React.FC<DetailedQuestionListProps> = ({
  questions = [],
  error,
  errorMessagePretty = "Couldn't load questions",
  after,
  fetchMore,
  networkStatus,
}) => {
  if (error) return <DataError title={errorMessagePretty} />;

  const { loading } = getQueryLoadingStatus(networkStatus);

  if (loading)
    return (
      <QuestionListWrapper>
        {Array.from({ length: 5 }).map((_, i) => (
          <QuestionCardLoading key={i} sx={{ mb: "$2" }} />
        ))}
      </QuestionListWrapper>
    );

  if (questions.length === 0) {
    return <Text variant="p">No questions found.</Text>;
  }

  return (
    <QueryButtonPagination
      after={after}
      fetchMore={fetchMore}
      networkStatus={networkStatus}
    >
      <QuestionListWrapper>
        {questions.map((question) =>
          !question.isDeleted ? (
            <PressableQuestionCard
              key={question.id}
              questionId={question.id}
              {...question}
              containerSx={{ mb: "$2" }}
            />
          ) : (
            <DeletedQuestionCard
              key={question.id}
              containerSx={{ mb: "$2" }}
              highlightedAnswer={question.highlightedAnswer}
            />
          )
        )}
      </QuestionListWrapper>
    </QueryButtonPagination>
  );
};

export { DetailedQuestionList };
export type { DeletedQuestion };
