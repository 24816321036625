import { createContext, useContext } from "react";

type FeatureFlagsContextState = {
  loading: boolean;
  hasFeature: (flag: string) => boolean;
  refreshFlags: () => Promise<void>;
};

const FeatureFlagsContext = createContext({} as FeatureFlagsContextState);
const useFlags = () => useContext(FeatureFlagsContext);

export type { FeatureFlagsContextState };
export { FeatureFlagsContext, useFlags };
