import { ReactNode, ComponentProps, forwardRef } from "react";
import {
  useDripsyTheme,
  SxProp,
  ActivityIndicator,
  DripsyFinalTheme,
} from "dripsy";
import { MotiPressable } from "moti/interactions";
import { useLink, UseLinkProps } from "solito/link";

import { merge } from "lodash";
import { ForwardedRef } from "react";
import { View } from "react-native";
import {
  ColorAnimatedIcon,
  ColorAnimatedIconProps,
} from "app/components/color-animated-icon";
import {
  ColorAnimatedPressable,
  ColorAnimatedView,
  ColorStates,
  OptionalColorStates,
} from "app/components/color-animated-view";

type ButtonVariant = keyof Omit<DripsyFinalTheme["button"], "sizes">;
type ButtonSize = keyof DripsyFinalTheme["button"]["sizes"];

const DEFAULT_SIZE: ButtonSize = "md";
const DEFAULT_VARIANT: ButtonVariant = "primary";

const useButtonTextColorStates = (variant: ButtonVariant): ColorStates => {
  const { theme } = useDripsyTheme();

  return {
    color: {
      states: [
        ["pressed", theme.button[variant]._hover.color],
        ["hovered", theme.button[variant]._hover.color],
      ],
      initialColor: theme.button[variant].color,
    },
  };
};

interface ButtonTextProps {
  variant: ButtonVariant;
  size?: ButtonSize;
  sx?: SxProp;
  disabled?: boolean;
  colorStates: OptionalColorStates;
  children?: ReactNode | string;
  truncateText?: boolean;
}

const ButtonText = ({
  variant = DEFAULT_VARIANT,
  size = DEFAULT_SIZE,
  disabled = false,
  sx,
  colorStates,
  truncateText = false,
  children,
}: ButtonTextProps) => {
  const { theme } = useDripsyTheme();
  const textColorStates = useButtonTextColorStates(variant);

  return (
    <ColorAnimatedView
      as="Text"
      sx={{
        variant: "text.button",
        fontSize: theme.button.sizes[size]?.fontSize,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        ...sx,
      }}
      colorStates={merge({}, textColorStates, colorStates)}
      {...(truncateText && { numberOfLines: 1 })}
      disabled={disabled}
    >
      {children}
    </ColorAnimatedView>
  );
};

interface ButtonContainerPressableProps
  extends ComponentProps<typeof MotiPressable> {
  variant?: ButtonVariant;
  sx?: SxProp;
  size?: ButtonSize;
  containerSx?: SxProp;
  loading?: boolean;
  colorStates?: ColorStates;
  /**
   * If true, the button will be disabled without affecting the UI.
   */
  disableInteraction?: boolean;
  children?: ReactNode | string;
}

const ButtonContainerPressable = forwardRef(
  (
    {
      variant = DEFAULT_VARIANT,
      size = DEFAULT_SIZE,
      sx,
      colorStates = {},
      children,
      loading,
      ...rest
    }: ButtonContainerPressableProps,
    ref: ForwardedRef<View>
  ) => {
    const { theme } = useDripsyTheme();
    const disabled = rest.disabled || loading;
    const variantStyles = theme.button[variant];

    return (
      <ColorAnimatedPressable
        ref={ref}
        sx={{
          borderWidth: [1.5, 2],
          borderRadius: 4,
          cursor: disabled ? "not-allowed" : "pointer",
          textDecorationLine: "none",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          ...variantStyles,
          ...theme.button.sizes[size],
          ...(["link", "ghost", "ghostLight", "text"].includes(variant) && {
            px: 0,
            py: 0,
          }),
          ...sx,
        }}
        disabled={disabled}
        colorStates={merge(
          {},
          {
            backgroundColor: {
              states: [
                ["pressed", variantStyles._hover.backgroundColor],
                ["hovered", variantStyles._hover.backgroundColor],
              ],
              initialColor: variantStyles.backgroundColor,
            },
            borderColor: {
              states: [
                ["pressed", variantStyles._hover.borderColor],
                ["hovered", variantStyles._hover.borderColor],
              ],
              initialColor: variantStyles.borderColor,
            },
          },
          colorStates
        )}
        // @ts-expect-error no clue - should be handled by ColorAnimatedWrapperProps extends MotiProps :/
        animate={{
          opacity: disabled ? 0.6 : 1,
        }}
        {...rest}
      >
        {children}
      </ColorAnimatedPressable>
    );
  }
);

ButtonContainerPressable.displayName = "Button";

interface ButtonIconBag {
  icon: ColorAnimatedIconProps["icon"];
  colorStates: ColorStates;
}

type RenderButtonIconFunction = (props: ButtonIconBag) => ReactNode;

const defaultRenderIcon: RenderButtonIconFunction = ({ icon, ...props }) => {
  return icon ? <ColorAnimatedIcon icon={icon} {...props} /> : null;
};

interface ButtonTextBag {
  variant: ButtonVariant;
  size?: ButtonSize;
  sx?: SxProp;
  colorStates: ColorStates;
  disabled?: boolean;
  children?: ReactNode | string;
  truncateText?: boolean;
}

type RenderButtonTextFunction = (props: ButtonTextBag) => ReactNode;

const defaultRenderText: RenderButtonTextFunction = (props) => {
  return <ButtonText {...props} />;
};

interface ButtonProps extends ButtonContainerPressableProps {
  icon?: ColorAnimatedIconProps["icon"];
  iconPosition?: "left" | "right";
  textSx?: SxProp;
  truncateText?: boolean;
  // Use custom render to pass consistent colorStates
  // from this component vs recomposing with hook
  renderIcon?: RenderButtonIconFunction;
  renderText?: RenderButtonTextFunction;
}

const Button = forwardRef(
  (
    {
      variant = "primary",
      size = "md",
      sx = {},
      textSx = {},
      truncateText = true,
      children,
      icon,
      iconPosition = "left",
      loading,
      renderIcon = defaultRenderIcon,
      renderText = defaultRenderText,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<View>
  ) => {
    const textColorStates = useButtonTextColorStates(variant);

    const buttonTextProps = {
      variant,
      size,
      colorStates: textColorStates,
      disabled: rest.disabled,
      sx: {
        ml: !!icon && iconPosition === "left" ? "$3" : "$0",
        mr: !!icon && iconPosition === "right" ? "$3" : "$0",
        ...textSx,
      },
      children,
      truncateText,
    };

    return (
      <ButtonContainerPressable
        ref={ref}
        variant={variant}
        size={size}
        sx={sx}
        loading={loading}
        {...rest}
      >
        {icon && iconPosition === "left"
          ? renderIcon({
              icon,
              colorStates: textColorStates,
            })
          : null}
        {children ? renderText(buttonTextProps) : null}
        {icon && iconPosition === "right"
          ? renderIcon({
              icon,
              colorStates: textColorStates,
            })
          : null}
        {loading ? (
          <ActivityIndicator
            sx={{ ml: "$2" }}
            color={textColorStates.color?.initialColor}
          />
        ) : null}
      </ButtonContainerPressable>
    );
  }
);
Button.displayName = "Button";

type ButtonLinkProps = UseLinkProps &
  Omit<
    Exclude<ButtonProps, "onPress">,
    // ignore props that will be overridden by useLink
    keyof UseLinkProps | keyof ReturnType<typeof useLink>
  >;

const ButtonLink = ({ href, as, shallow, ...rest }: ButtonLinkProps) => {
  const linkProps = useLink({ href, as, shallow });
  return <Button {...rest} {...linkProps} />;
};

export type {
  ButtonContainerPressableProps,
  ButtonVariant,
  ButtonSize,
  ButtonProps,
  ButtonTextProps,
  ButtonLinkProps,
  ButtonIconBag,
  RenderButtonIconFunction,
  ButtonTextBag,
  RenderButtonTextFunction,
};
export {
  Button,
  ButtonContainerPressable,
  ButtonText,
  ButtonLink,
  ColorAnimatedIcon as ButtonIcon,
  useButtonTextColorStates,
};
