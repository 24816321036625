import {
  InMemoryCache,
  gql,
  defaultDataIdFromObject,
  FieldMergeFunction,
} from "@apollo/client";
import { StrictTypedTypePolicies } from "app/types/generated/apollo-helpers";
import { ENGLISH_SPEAKING_COUNTRIES, RED_LIST_COUNTRIES } from "app/constants";
import fragmentTypes from "app/graphql/fragmentTypes.json";

const { possibleTypes } = fragmentTypes;

const defaultMerge: FieldMergeFunction = (
  existing,
  incoming,
  { mergeObjects }
) => mergeObjects(existing, incoming);

const typePolicies: StrictTypedTypePolicies = {
  ApplicationStartMatchResponse: {
    fields: {
      applicationQuestion: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  CandidateV2: {
    fields: {
      coreDocuments: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  Question: {
    fields: {
      answers: {
        keyArgs: [],
        merge(existing, incoming, { args, mergeObjects }) {
          const answers = args?._cursor ? [...(existing?.data || [])] : [];
          return mergeObjects(incoming, {
            data: [...answers, ...(incoming.data || [])],
          });
        },
      },
    },
  },
  MatchResponse: {
    fields: {
      job: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  Query: {
    fields: {
      answer: {
        read(_, { args, toReference }) {
          if (!args) return;
          return toReference({
            __typename: "Answer",
            id: args.id,
          });
        },
      },
      channel: {
        read(_, { args, toReference }) {
          if (!args) return;
          return toReference({
            __typename: "Channel",
            id: args.id,
          });
        },
      },
      communityPublicChannel: {
        read(existing, { args, toReference }) {
          if (existing) return existing;
          return toReference({
            __typename: "Channel",
            id: args?.id,
          });
        },
      },
      job: {
        read(_, { args, toReference }) {
          if (!args) return;
          return toReference({
            __typename: "Job",
            id: args.id,
          });
        },
      },
      user: {
        read(_, { args, toReference, cache: cacheInstance }) {
          if (!args) return;

          const ref = possibleTypes.User.map((__typename) =>
            toReference({
              __typename,
              id: args.id,
            })
          ).find((reference) =>
            cacheInstance.readFragment({
              id: reference?.__ref,
              fragment: gql`
                fragment UserInCache on User {
                  id
                }
              `,
            })
          );
          return ref;
        },
      },
      englishSpeakingCountries: {
        read() {
          return ENGLISH_SPEAKING_COUNTRIES;
        },
      },
      redListCountries: {
        read() {
          return RED_LIST_COUNTRIES;
        },
      },
      questions: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
      matchResponse: {
        read(existing, { args, toReference }) {
          if (existing) return existing;
          return toReference({
            __typename: "MatchResponse",
            id: args?.id,
          });
        },
      },
      matchResponseByJob: {
        read(existing, { args, toReference }) {
          if (existing) return existing;
          return toReference({
            __typename: "MatchResponse",
            id: args?.jobId,
          });
        },
      },
      myMatchedJobs: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      message: {
        read(existing, { args, toReference }) {
          if (existing) return existing;
          return toReference({
            __typename: "Message",
            id: args?.id,
          });
        },
      },
      messages: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
      myMatchResponses: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
      notifications: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
      joinedChannels: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
      communityPublicChannels: {
        keyArgs: ["where", "_size"],
        // eslint-disable-next-line @typescript-eslint/default-param-last
        merge(existing = { data: [] }, incoming, { args }) {
          const data = args?._cursor ? existing.data : [];
          return {
            ...incoming,
            data: [...data, ...incoming.data],
          };
        },
      },
    },
  },
  Message: {
    merge: defaultMerge,
    fields: {
      // Client field
      sendError: {
        read(sendError = false) {
          return sendError;
        },
      },
    },
  },
};

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    // Don't use __typename for channel types or match responses
    // because id will always be unique in collections despite
    // subtype
    if ((responseObject.id as string)?.includes("channels")) {
      return `Channel:${responseObject.id}`;
    }
    if ((responseObject.id as string)?.includes("match_responses")) {
      return `MatchResponse:${responseObject.id}`;
    }
    if ((responseObject.id as string)?.includes("notifications")) {
      return `Notification:${responseObject.id}`;
    }
    return defaultDataIdFromObject(responseObject);
  },
  possibleTypes,
  typePolicies,
});
