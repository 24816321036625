import { useRouter } from "solito/router";
import { gql } from "@apollo/client";
import { ButtonLink } from "app/design/button";
import {
  StepScreenForm,
  Header,
  HeaderProps,
} from "app/components/step-screen-form";
import { useToast } from "app/components/use-toast";
import { pagesPath } from "app/lib/$path";
import { useYupForm, yup } from "app/utils/yup";
import { useUpdateCareerQuizResultMutation } from "app/types";
import { EnglishLanguageOption, EnglishLanguageStep } from "./steps/language";
import { MinimumRequirementsStep } from "./steps/requirements";
import { RegisteredStep } from "./steps/registered";
import { QualifiedStep } from "./steps/qualified";
import { YesNoOption } from "./constants";
import { getCareerQuizResults } from "./career-stage";

const UPDATE_CAREER_QUIZ_RESULT_MUTATION = gql`
  mutation UpdateCareerQuizResult($input: UpdateCareerQuizResultInput!) {
    updateCareerQuizResult(input: $input) {
      id
      careerQuizResult
    }
  }
`;

const ScreenHeader = ({ stepProgress }: HeaderProps) => (
  <Header stepProgress={stepProgress}>
    <ButtonLink variant="ghost" href={pagesPath.$url()}>
      Back
    </ButtonLink>
    <Header.MigrateLogo />
  </Header>
);

const schema = yup
  .object({
    qualified: yup.string().min(1).required("Please choose an option"),
    engLang: yup.string().min(1).required("Please choose an option"),
    minRequirements: yup.string().min(1).required("Please choose an option"),
    registered: yup.string().required("Please choose an option"),
    results: yup.bool(),
  })
  .required();

interface CareerQuizFormProps {
  professionId?: string;
  country?: string | null;
}

const CareerQuizForm = ({
  professionId = "",
  country = null,
}: CareerQuizFormProps) => {
  const toast = useToast();
  const router = useRouter();
  const [update, { loading }] = useUpdateCareerQuizResultMutation();

  const careerQuizForm = useYupForm({
    schema,
    defaultValues: {
      qualified: "",
      engLang: "",
      minRequirements: "",
      registered: "",
    },
  });

  const getResults = getCareerQuizResults(country);

  const onSubmit = async () => {
    try {
      await update({
        variables: {
          input: {
            careerQuizResult: getResults(careerQuizForm.getValues()),
          },
        },
      });
      router.push(pagesPath.$url());
      toast.show({
        status: "success",
        title: "Quiz results saved",
      });
    } catch (e) {
      console.error(e);
      toast.show({
        status: "error",
        title: "Could not save results",
        description: "Please try again later or contact support.",
      });
    }
  };

  const qualified = careerQuizForm.watch("qualified");
  const minRequirements = careerQuizForm.watch("minRequirements");
  const engLang = careerQuizForm.watch("engLang");

  const getStepOverride = () => {
    if (
      qualified === YesNoOption.NO ||
      minRequirements === YesNoOption.NO ||
      engLang === EnglishLanguageOption.NA
    )
      return onSubmit;

    return undefined;
  };

  const showEnglishLanguageStep = country !== "GB";
  const showMinimumRequirementsStep =
    showEnglishLanguageStep && engLang !== EnglishLanguageOption.EXEMPT;

  return (
    <>
      <StepScreenForm
        HeaderComponent={ScreenHeader}
        onSubmit={onSubmit}
        exitPath={pagesPath}
        path={pagesPath.career_quiz}
        overrideNext={getStepOverride()}
        submitting={loading}
        {...careerQuizForm}
      >
        <QualifiedStep
          stepPath="qualified"
          name="qualified"
          {...careerQuizForm}
        />
        {showEnglishLanguageStep && (
          <EnglishLanguageStep
            stepPath="language"
            name="engLang"
            {...careerQuizForm}
          />
        )}
        {showMinimumRequirementsStep && (
          <MinimumRequirementsStep
            stepPath="requirements"
            name="minRequirements"
            professionId={professionId}
            engLang={careerQuizForm.watch("engLang") as EnglishLanguageOption}
            {...careerQuizForm}
          />
        )}
        <RegisteredStep
          stepPath="registered"
          name="registered"
          {...careerQuizForm}
        />
      </StepScreenForm>
    </>
  );
};

export { CareerQuizForm, ScreenHeader, UPDATE_CAREER_QUIZ_RESULT_MUTATION };
