import { styled, View } from "dripsy";
import { ReactElement, ReactNode, useState } from "react";
import { Card, withAccent } from "app/design/card";
import { List } from "app/components/list";
import { ProfileCardHeader, ProfileCardHeaderProps } from "./header";
import { ProfileCardDates, ProfileCardDatesProps } from "./dates";
import { ProfileCardContext } from "./context";

interface ProfileCardProps
  extends ProfileCardHeaderProps,
    ProfileCardDatesProps {
  showDivider?: boolean;
  form: ReactElement;
  showFormOnRender?: boolean;
  children?: ReactNode;
}

const ProfileCardContainer = styled(Card)({
  mb: "$4",
});

const ProfileStripeCard = withAccent(ProfileCardContainer);

const ProfileCardDivider = styled(View)({
  borderBottomWidth: 1,
  borderColor: "$muted",
  width: 100,
  my: "$3",
});

const ProfileCard = ({
  header,
  subheader,
  showDivider,
  startDate,
  endDate,
  isDateRange,
  form,
  onDelete,
  showFormOnRender = false,
  children,
}: ProfileCardProps) => {
  const [showForm, setShowForm] = useState(showFormOnRender);

  const renderBody = () => {
    if (showForm) return form;

    return (
      <>
        <ProfileCardHeader
          header={header}
          subheader={subheader}
          onDelete={onDelete}
        />
        {showDivider ? <ProfileCardDivider /> : null}
        <ProfileCardDates
          startDate={startDate}
          endDate={endDate}
          isDateRange={isDateRange}
        />
        {children ? <View sx={{ mt: "$3" }}>{children}</View> : null}
      </>
    );
  };

  return (
    <ProfileCardContext.Provider value={{ showForm, setShowForm }}>
      <ProfileCardContainer>{renderBody()}</ProfileCardContainer>
    </ProfileCardContext.Provider>
  );
};

const ProfileCardDescription = ({ description }: { description: string[] }) => (
  <List>
    {description.map((item, i) => (
      <List.Item key={i} textSx={{ color: "$black-70" }}>
        {item}
      </List.Item>
    ))}
  </List>
);

ProfileCard.Description = ProfileCardDescription;

export {
  ProfileCardContainer,
  ProfileCardDivider,
  ProfileCard,
  ProfileStripeCard,
};
