import { UrlObject } from "url";
import React, { cloneElement } from "react";
import { useLink } from "solito/link";
import { useRouter } from "solito/router";
import { H3, Pressable, Row, styled, Sx, useDripsyTheme, View } from "dripsy";
import { Platform, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Divider, IMenuItemProps, useDisclose } from "native-base";
import { AnimatePresence, motify, MotiView } from "moti";
import { LinkProps } from "next/link";

import { gql } from "@apollo/client";
import { useScreenSize } from "app/hooks/use-screen-size";
import { useCurrentUser } from "app/hooks/use-current-user";
import { useNavigation } from "app/hooks/use-navigation";
import { useNotificationsFlag } from "app/hooks/flags";
import { Avatar } from "app/design/avatar";
import { IconButton, IconButtonLink } from "app/design/icon-button";
import { MigrateStones } from "app/design/svgs/migrate-stones";
import { MigrateHorizontalLogo } from "app/design/svgs/migrate-horizontal-logo";
import { AbsoluteBadge, Badged } from "app/design/badge";
import { ButtonLink } from "app/design/button";
import { useToast } from "app/components/use-toast";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { Text } from "app/components/text";
import { useWebLink } from "app/components/web-link";
import { Menu } from "app/components/menu";
import { MenuTriggerIconButton } from "app/components/menu-trigger";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { useAuth } from "app/use-auth";
import { pagesPath } from "app/lib/$path";
import { conditionalParam } from "app/utils";
import { useNumUnreadMessagesQuery } from "app/types";
import { useProfileCompletion } from "../profile/completion/use-profile-completion";
import { NotificationsMenu } from "../notifications/menu";
import { SIDEBAR_WIDTHS } from "./constants";

const MotiH3 = motify(H3)();
const DripsyMotiView = styled(MotiView)();

interface JobCardMenuItemWebProps extends IMenuItemProps {
  url: string;
}

interface JobCardMenuItemProps extends IMenuItemProps {
  href: UrlObject;
}

const MenuItemWebLink = ({ url, ...rest }: JobCardMenuItemWebProps) => {
  const linkProps = useWebLink(url);
  return <Menu.Item {...linkProps} {...rest} />;
};

const MenuItemLink = ({ href, ...rest }: JobCardMenuItemProps) => {
  const linkProps = useLink({ href });
  return <Menu.Item {...linkProps} {...rest} />;
};

const GetTheAppLink = () => {
  const { theme } = useDripsyTheme();
  const linkProps = useWebLink("https://migratehr.com#join");
  return (
    <>
      <Divider my={1} bg={theme.colors.$muted} />
      <Menu.Item {...linkProps}>
        <Row sx={{ justifyContent: "space-between", width: "100%" }}>
          <Text
            sx={{
              fontSize: 16,
              lineHeight: 24,
              color: "$blue",
              fontWeight: "500",
            }}
          >
            Get the app
          </Text>
          <MigrateStones width={24} />
        </Row>
      </Menu.Item>
      <Divider my={1} bg={theme.colors.$muted} />
    </>
  );
};

interface HeaderProps {
  title?: string;
  backButtonType?: "back" | "close" | "none";
  backHref?: LinkProps["href"];
  showAdditionalActions?: boolean;
  showTitleOnDesktop?: boolean;
  alwaysShowHeader?: boolean;
  goBack?: () => void;
  headerSx?: Sx;
}

// TODO: Replace with option on profile
const HeaderMenu: React.FC<{
  incompleteProfile: boolean;
  children: JSX.Element;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}> = ({ children, incompleteProfile, isOpen, onOpen, onClose }) => {
  const { logout } = useAuth();
  const toast = useToast();
  const router = useRouter();
  const { isMobile } = useScreenSize();

  const handleLogout = async () => {
    try {
      await logout();
      router.push(pagesPath.welcome.$url());
      toast.show({
        status: "success",
        title: "Successfully logged out",
      });
    } catch (e) {
      toast.show({
        status: "error",
        title: "Couldn't log out",
        description: "Please try again later or contact support.",
      });
    }
  };
  return (
    <Menu
      trigger={(triggerProps) => cloneElement(children, triggerProps)}
      placement="bottom"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      mr={3}
      // Magic numbers to align with notifications dropdown
      mt={isMobile ? "7px" : "11px"}
    >
      <MenuItemLink href={pagesPath.job_preferences.$url()}>
        Change preferences
      </MenuItemLink>
      <MenuItemLink href={pagesPath.profile.$url()}>
        <Text sx={{ fontSize: 16, lineHeight: 24, color: "$black" }}>
          My profile
        </Text>
        {incompleteProfile ? (
          <AbsoluteBadge
            backgroundColor="$orange"
            sx={{
              left: "auto",
              right: 0,
              top: 4,
              ml: 0,
            }}
          >
            !
          </AbsoluteBadge>
        ) : (
          <></>
        )}
      </MenuItemLink>
      <MenuItemLink href={pagesPath.my_documents.$url()}>
        My documents
      </MenuItemLink>
      <MenuItemLink href={pagesPath.account_settings.$url()}>
        Settings
      </MenuItemLink>
      <MenuItemWebLink url="mailto:support@migratehr.com">
        Support
      </MenuItemWebLink>
      {Platform.OS === "web" && <GetTheAppLink />}
      <Menu.Item onPress={handleLogout}>Log out</Menu.Item>
    </Menu>
  );
};

const HEADER_HORIZONTAL_PADDING = ["$3", null, null, "$6"];

const UnauthorizedHeaderActions = () => {
  return (
    <>
      <ButtonLink
        href={pagesPath.login.$url()}
        variant="ghost"
        containerSx={{ mr: "$4" }}
      >
        Log in
      </ButtonLink>
      <ButtonLink
        href={pagesPath.register._step("profession").$url()}
        size="sm"
      >
        Sign up
      </ButtonLink>
    </>
  );
};

const UNREAD_MESSAGES_QUERY = gql`
  query NumUnreadMessages {
    numUnreadMessages
  }
`;

const InboxButton = () => {
  const { data: { numUnreadMessages } = {}, error } =
    useNumUnreadMessagesQuery();
  if (error) console.error(error);

  return (
    <IconButtonLink
      variant="ghost"
      size="sm"
      href={pagesPath.inbox.$url()}
      icon={<Feather name="message-square" size={20} />}
      renderIcon={(props) => (
        <Badged value={numUnreadMessages}>
          <ColorAnimatedIcon {...props} />
        </Badged>
      )}
      accessibilityLabel="Inbox"
      containerSx={{ ml: "$3", zIndex: 2 }}
    />
  );
};

const AuthorizedHeaderActions = () => {
  const { theme } = useDripsyTheme();
  const { currentUser, isVerified } = useCurrentUser();
  const { isMobile } = useScreenSize();
  const { completed, loading } = useProfileCompletion();
  const { hasNotifications } = useNotificationsFlag();

  const {
    isOpen: notificationsAreOpen,
    onClose: closeNotifications,
    onOpen: openNotifications,
  } = useDisclose();

  const {
    isOpen: accountMenuIsOpen,
    onOpen: openAccountMenu,
    onClose: closeAccountMenu,
  } = useDisclose();

  const incompleteProfile = !loading && !completed;

  return (
    <>
      {hasNotifications ? (
        <NotificationsMenu
          isOpen={notificationsAreOpen}
          onOpen={() => {
            openNotifications();
            closeAccountMenu();
          }}
          onClose={closeNotifications}
        >
          <MenuTriggerIconButton
            variant="ghost"
            size="sm"
            icon={<Feather name="bell" size={20} />}
            accessibilityLabel="Inbox"
            containerSx={{ ml: "$3", zIndex: 2 }}
          />
        </NotificationsMenu>
      ) : null}

      {currentUser?.__typename === "CandidateV2" && isVerified ? (
        <InboxButton />
      ) : null}

      <HeaderMenu
        incompleteProfile={incompleteProfile}
        isOpen={accountMenuIsOpen}
        onOpen={() => {
          openAccountMenu();
          closeNotifications();
        }}
        onClose={closeAccountMenu}
      >
        <Pressable sx={{ ml: "$3" }} accessibilityLabel="Your profile">
          <Badged
            backgroundColor="$orange"
            sx={{ mt: [10, 6], mr: 4 }}
            value={incompleteProfile ? "!" : undefined}
            textSx={{ fontSize: 13 }}
          >
            <Avatar
              backgroundColor={!isMobile ? theme.colors.$white : undefined}
              size={isMobile ? 10 : 8}
              userId={currentUser?.id}
              photoUrl={currentUser?.photoUrl}
              isActive={currentUser?.isActive && completed}
            />
          </Badged>
        </Pressable>
      </HeaderMenu>
    </>
  );
};

const HeaderActions = () => {
  const { currentUser } = useCurrentUser();

  return (
    <Row sx={{ alignItems: "center", pr: HEADER_HORIZONTAL_PADDING }}>
      {currentUser ? (
        <AuthorizedHeaderActions />
      ) : (
        <UnauthorizedHeaderActions />
      )}
    </Row>
  );
};

interface HeaderTitleProps {
  title?: string;
  headerInView?: boolean;
}

const HeaderTitle = ({ title, headerInView = true }: HeaderTitleProps) => {
  const { theme } = useDripsyTheme();
  return (
    <AnimatePresence exitBeforeEnter>
      <MotiH3
        key={title}
        sx={{
          display: ["flex", null],
          my: 0,
          flex: 1,
          fontSize: 16,
        }}
        from={{ opacity: 0 }}
        animate={{
          opacity: headerInView ? 1 : 0,
        }}
        exit={{ opacity: 0 }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
        numberOfLines={1}
      >
        {title}
      </MotiH3>
    </AnimatePresence>
  );
};

const Header: React.FC<HeaderProps> = ({
  title,
  backButtonType = "back",
  backHref,
  showAdditionalActions = true,
  showTitleOnDesktop = true,
  alwaysShowHeader = false,
  headerSx = {},
}) => {
  const { theme } = useDripsyTheme();
  const safeArea = useSafeArea();
  const { isMobile, isWide } = useScreenSize();
  const navigation = useNavigation();

  const backLinkProps = useLink({ href: backHref || "" });
  const homeLinkProps = useLink({ href: pagesPath.$url() });
  const router = useRouter();

  const headerInView = isMobile || showTitleOnDesktop || alwaysShowHeader;

  return (
    <View
      sx={{
        pt: [safeArea.top + theme.space.$2, safeArea.top + theme.space.$3],
        ...conditionalParam(
          {
            backgroundColor: theme.card.default.backgroundColor,
            borderBottomWidth: theme.card.default.borderWidth,
            borderColor: theme.card.default.borderColor,
            boxShadow: "default",
          },
          isMobile
        ),
        ...headerSx,
      }}
    >
      <DripsyMotiView
        sx={{
          ...StyleSheet.absoluteFillObject,
          boxShadow: "default",
        }}
        animate={{
          opacity: headerInView ? 1 : 0,
        }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
      />

      <View
        sx={{
          flexDirection: "row",
          alignItems: "center",
          pb: ["$2", "$3"],
          pl: HEADER_HORIZONTAL_PADDING,
        }}
      >
        <View
          sx={{
            width: SIDEBAR_WIDTHS,
            display: ["none", "flex"],
          }}
        >
          <Pressable
            sx={{ alignSelf: "flex-start", ml: [20, null, null, 0] }}
            accessibilityLabel="Migrate"
            {...homeLinkProps}
          >
            {isWide ? (
              <MigrateHorizontalLogo width={131} />
            ) : (
              <MigrateStones width={29} />
            )}
          </Pressable>
        </View>

        {backButtonType === "none" ? (
          <Pressable
            sx={{
              width: 27,
              height: 20,
              mr: "$4",
              display: [null, "none"],
            }}
            {...homeLinkProps}
          >
            <MigrateStones />
          </Pressable>
        ) : (
          <IconButton
            icon={
              <Feather
                name={backButtonType === "back" ? "chevron-left" : "x"}
                size={24}
              />
            }
            size="sm"
            accessibilityLabel="Back"
            variant="ghost"
            containerSx={{ mr: "$4" }}
            {...(backHref
              ? backLinkProps
              : {
                  onPress: () => {
                    /**
                     * ideally we'd just call router.back here, but we need to handle
                     * orphaned routes navigated to from deep links on native -- solution
                     * for now is to replace the router with the top level (app) route
                     * which effectively simulates going back to home. keep an eye on this
                     * issue: https://github.com/expo/router/issues/121
                     */
                    if (Platform.OS === "web") return router.back();
                    if (navigation.canGoBack()) return router.back();
                    // @ts-expect-error expo-router doesn't type replace
                    return navigation.replace("(app)");
                  },
                })}
          />
        )}
        <HeaderTitle title={title} headerInView={headerInView} />
        {showAdditionalActions ? <HeaderActions /> : null}
      </View>
    </View>
  );
};

export type { HeaderProps };
export { Header, UNREAD_MESSAGES_QUERY };
