import { ReactElement } from "react";
import { useCareerExperienceMutations } from "../hooks/use-career-experience-mutations";
import { isTempField } from "../profile-form";

export interface BaseCareerExperienceProps {
  id: string;
  type: string;
  startDate: string;
  endDate: string;
  description?: string[];
  form: ReactElement;
  completed: boolean;
  onDelete: () => void;
}

export const withCareerExperienceProps = <T extends BaseCareerExperienceProps>(
  WrappedComponent: React.ComponentType<T>
) => {
  const WithCareerExperienceProps = ({
    id,
    ...props
  }: Omit<T, "completed" | "onDelete">) => {
    const { deleteCareerExperience } = useCareerExperienceMutations(id);
    const completed = !isTempField({ id });

    return (
      <WrappedComponent
        {...(props as T)}
        completed={completed}
        onDelete={deleteCareerExperience}
      />
    );
  };

  return WithCareerExperienceProps;
};
