import { useEffect, useRef } from "react";

export const useEffectAfterRender = (callback: () => any, deps: any[]) => {
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    callback();
  }, deps);
};
