import { useDripsyTheme, styled, View } from "dripsy";
import { MotiPressable } from "moti/interactions";
import { Feather } from "@expo/vector-icons";

const DripsyFeather = styled(Feather)();

type PaginationDirection = "left" | "right";

type PaginationButtonProps = {
  direction: PaginationDirection;
  paginate: (direction: PaginationDirection) => () => void;
  disabled?: boolean;
};

const PaginationButton: React.FC<PaginationButtonProps> = ({
  direction,
  paginate,
  disabled = false,
}) => {
  const { theme } = useDripsyTheme();

  return (
    <MotiPressable
      disabled={disabled}
      from={{
        opacity: 0,
        translateX: direction === "right" ? -5 : 5,
      }}
      animate={({ hovered, pressed }) => {
        "worklet";
        return {
          scale: pressed ? 0.95 : 1,
          opacity: disabled ? 0 : pressed || hovered ? 0.5 : 1,
          translateX: hovered ? (direction === "right" ? 5 : -5) : 0,
        };
      }}
      transition={{
        type: "timing",
        duration: theme.transitionDurations.normal,
      }}
      onPress={paginate(direction)}
    >
      <View
        sx={{
          backgroundColor: theme.colors.$white,
          width: 60,
          height: 60,
          borderRadius: 60,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "md",
        }}
      >
        <DripsyFeather
          name={direction === "left" ? "arrow-left" : "arrow-right"}
          size={24}
          color={theme.colors.$orange}
        />
      </View>
    </MotiPressable>
  );
};

export type { PaginationDirection, PaginationButtonProps };
export { PaginationButton };
