import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 128;
const originalHeight = 148;
const aspectRatio = originalWidth / originalHeight;

const Stone2 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="M71.253 1.806h-.34C44.21-3.363 18.292 2.249 6.012 22.714c-9.929 16.653-8.126 49.177 9.667 79.873 22.444 38.841 53.797 49.804 74.908 43.931a47.54 47.54 0 0 0 21.316-12.283 47.464 47.464 0 0 0 12.311-21.285c5.957-19.734 8.778-69.72-22.235-96.58A64.145 64.145 0 0 0 71.253 1.807Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone2 };
