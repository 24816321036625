import { Target } from "@nandorojo/anchor";
import { Row, View } from "dripsy";
import { KeyboardAvoidingView } from "react-native";
import { memo } from "react";
import { Alert } from "app/design/alert";

import { MinScreenSize } from "app/components/min-screen-size";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import {
  SetPreferencesPrompt,
  VerifyPrompt,
} from "app/components/overview-card";
import { useCurrentUser, useScreenSize } from "app/hooks";
import { ScreenComponent } from "app/navigation/types";
import { Layout, HalfDesktopWrapper } from "../layout";
import { ProfileCompletion } from "./completion";
import { CareerExperienceSection } from "./forms";
import { EducationExperienceSection } from "./forms/education-experience";
import { LanguageResultsSection } from "./forms/language-results";
import { ProfileOverviewCard } from "./forms/overview";
import { QuestionResponsesSection } from "./forms/question-responses";
import { RegistrationDetailsSection } from "./forms/registration-details";
import { PassportSection } from "./passport-section";
import {
  PROFILE_SCREEN_TARGETS,
  useProfileCompletion,
} from "./completion/use-profile-completion";

/**
 * these values tend to be the result of trial and error
 * for what feels like a good amount of padding below the
 * input when the keyboard is open...
 * variable for prosperity.
 */
const PROFILE_SCREEN_EXTRA_HEIGHT = 299;

const ProfileScreenContent = () => {
  const { isCandidateV1, isVerified } = useCurrentUser();
  const { isWide } = useScreenSize();
  const { completed, loading } = useProfileCompletion();

  if (isCandidateV1)
    return (
      <HalfDesktopWrapper>
        <SetPreferencesPrompt description="Before you can complete your profile, you need to set your job preferences." />
      </HalfDesktopWrapper>
    );

  if (!isVerified)
    return (
      <HalfDesktopWrapper>
        <VerifyPrompt description="Before you can complete your profile, you need to verify your account." />
      </HalfDesktopWrapper>
    );

  return (
    <KeyboardAvoidingView
      behavior="padding"
      keyboardVerticalOffset={PROFILE_SCREEN_EXTRA_HEIGHT}
      enabled
    >
      <Row sx={{ pt: "$3", flexDirection: "row-reverse" }}>
        {isWide ? (
          <View sx={{ flex: [1, null, null, 1 / 3] }}>
            <ProfileCompletion sx={{ position: "sticky", top: 60 }} />
          </View>
        ) : null}

        <View
          sx={{ flex: [1, null, null, 2 / 3], mr: [null, null, null, "$6"] }}
        >
          {!loading && !completed ? (
            <Alert variant="warning" sx={{ mb: "$6" }}>
              Your profile is incomplete, fill out the sections below to improve
              your chances of being shortlisted!
            </Alert>
          ) : null}
          <ProfileOverviewCard />

          {!isWide ? <ProfileCompletion sx={{ mb: "$6" }} /> : null}

          <Target name={PROFILE_SCREEN_TARGETS.passport}>
            <PassportSection />
          </Target>

          <Target name={PROFILE_SCREEN_TARGETS.workExperience}>
            <CareerExperienceSection />
          </Target>

          <Target name={PROFILE_SCREEN_TARGETS.educationHistory}>
            <EducationExperienceSection />
          </Target>

          <Target name={PROFILE_SCREEN_TARGETS.registrationDetails}>
            <RegistrationDetailsSection />
          </Target>

          <Target name={PROFILE_SCREEN_TARGETS.languageQualifications}>
            <LanguageResultsSection />
          </Target>

          <Target name={PROFILE_SCREEN_TARGETS.moreAboutYou}>
            <QuestionResponsesSection />
          </Target>
        </View>
      </Row>
    </KeyboardAvoidingView>
  );
};

const ProfileScreen: ScreenComponent = memo(
  () => {
    return (
      <Screen scrollable sx={{ maxWidth: 1056 }}>
        <MinScreenSize size="tablet">
          <ScreenHeader>My profile</ScreenHeader>
        </MinScreenSize>
        <ProfileScreenContent />
      </Screen>
    );
  },
  () => true
);

const headerProps = {
  title: "My profile",
};

ProfileScreen.displayName = "ProfileScreen";
ProfileScreen.headerProps = headerProps;
ProfileScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { ProfileScreen };
