import {
  PressableCard,
  PressableCardProps,
} from "app/components/pressable-card";
import { FCC } from "app/types";
import { NotificationCardThemeContext } from "../theme-context";
import { useToastStyles } from "../use-toast-styles";

interface MessageNotificationWrapperProps extends PressableCardProps {
  onClose: () => void;
}

const MessageNotificationWrapper: FCC<MessageNotificationWrapperProps> = ({
  children,
  onClose,
  ...rest
}) => {
  const { sx, textSx, ...toastStyleProps } = useToastStyles();

  return (
    <PressableCard
      sx={{
        flexDirection: "row",
        alignItems: "flex-start",
        ...sx,
      }}
      {...toastStyleProps}
      onPress={onClose}
      {...rest}
    >
      <NotificationCardThemeContext.Provider value={{ textSx }}>
        {children}
      </NotificationCardThemeContext.Provider>
    </PressableCard>
  );
};

export { MessageNotificationWrapper };
export type { MessageNotificationWrapperProps };
