import { UrlObject } from "url";
import { styled, useDripsyTheme, View } from "dripsy";
import { MotiPressable } from "moti/interactions";
import { useLink } from "solito/link";
import { memo } from "react";

import { Platform } from "react-native";
import { useIsLinkActive } from "app/hooks/use-is-link-active";
import { MigrateIcon } from "app/design/migrate-icon";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { pagesPath } from "app/lib/$path";
import { useCurrentPathname } from "app/hooks";

const TAB_BUTTON_SIZE = 60;

const DripsyMotiPressable = styled(MotiPressable)();

const TabsButton: React.FC<{
  href: UrlObject;
  icon: React.ReactElement;
  accessibilityLabel: string;
}> = ({ href, icon, accessibilityLabel }) => {
  const linkProps = useLink({ href });
  const { theme } = useDripsyTheme();
  const active = useIsLinkActive(linkProps.href);

  return (
    <DripsyMotiPressable
      {...linkProps}
      accessibilityLabel={accessibilityLabel}
      sx={{
        width: TAB_BUTTON_SIZE,
        height: TAB_BUTTON_SIZE,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ColorAnimatedIcon
        colorStates={{
          color: {
            states: [[active, theme.colors.$blue]],
            initialColor: theme.colors.$grey,
          },
        }}
        sx={{ color: active ? theme.colors.$blue : theme.colors.$grey }}
        icon={icon}
      />
    </DripsyMotiPressable>
  );
};

const isOnChannelScreen = (pathname: string) =>
  Platform.OS === "web"
    ? pathname === "/community/channels/[channelId]" ||
      pathname === "/inbox/[channelId]"
    : pathname.match(/community\/channels\/[0-9]+/) ||
      pathname.match(/inbox\/[0-9]+/);

const hideTabs = (pathname: string) => {
  const checks = [isOnChannelScreen];
  return checks.some((check) => check(pathname));
};

const Tabs: React.FC = memo(
  () => {
    const pathname = useCurrentPathname();
    const safeArea = useSafeArea();
    const { theme } = useDripsyTheme();

    const { backgroundColor, borderWidth, borderColor } = theme.card.default;

    if (hideTabs(pathname))
      return <View sx={{ backgroundColor, pb: safeArea.bottom }} />;

    return (
      <View
        sx={{
          backgroundColor,
          display: ["flex", "none"],
          flexDirection: "row",
          justifyContent: "space-around",
          borderTopWidth: borderWidth,
          borderColor,
          pb: safeArea.bottom,
          boxShadow: "defaultUpwards",
        }}
      >
        <TabsButton
          accessibilityLabel="Home"
          href={pagesPath.$url()}
          icon={<MigrateIcon name="home" size={24} />}
        />
        <TabsButton
          accessibilityLabel="Jobs"
          href={pagesPath.jobs.$url()}
          icon={<MigrateIcon name="jobs" size={26} />}
        />
        <TabsButton
          accessibilityLabel="community"
          href={pagesPath.community.$url()}
          icon={<MigrateIcon name="community" size={26} />}
        />
      </View>
    );
  },
  () => true
);
Tabs.displayName = "Tabs";

export { Tabs, TAB_BUTTON_SIZE };
