import { View } from "dripsy";
import { SpeechBubbleIcon } from "app/design/svgs/icons/speech-bubble";
import { Avatar } from "app/design/avatar";
import { pagesPath } from "app/lib/$path";
import { NewMessagePayloadFragment } from "app/types";
import {
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
} from "../common";
import { NotificationHeader } from "../header";
import {
  MessageNotificationWrapper,
  MessageNotificationWrapperProps,
} from "./wrapper";

interface CommunityPublicMessageCardProps
  extends MessageNotificationWrapperProps {
  message: NewMessagePayloadFragment;
  renderHeaderRight: () => React.ReactElement;
}

const CommunityPublicMessageCard = ({
  message,
  renderHeaderRight,
  ...rest
}: CommunityPublicMessageCardProps) => {
  const { channel, content, author, createdAt } = message;

  if (channel.__typename !== "CommunityPublicChannel")
    throw new Error("Invalid message notification config");

  return (
    <MessageNotificationWrapper
      href={pagesPath.community.channels._channelId(channel.id).$url()}
      {...rest}
    >
      <View>
        <Avatar
          userId={author?.id}
          photoUrl={author?.photoUrl}
          isActive={author?.isActive}
          size={12}
        />
        <Badge icon={<SpeechBubbleIcon />} />
      </View>
      <NotificationContent>
        <NotificationHeader
          createdAt={createdAt}
          renderHeaderRight={renderHeaderRight}
        >
          <Highlight>{author?.displayName}</Highlight> posted in{" "}
          <Highlight>#{channel.name}</Highlight>
        </NotificationHeader>
        <NotificationBody>{content}</NotificationBody>
      </NotificationContent>
    </MessageNotificationWrapper>
  );
};

export { CommunityPublicMessageCard };
