import { memo } from "react";
import isEqual from "react-fast-compare";
import { CareerExperienceType } from "app/types/generated/schema";
import {
  CareerBreakCard,
  CareerBreakFormCard,
  CareerBreakFormValues,
} from "./career-break";
import {
  WorkExperienceCard,
  WorkExperienceFormCard,
  WorkExperienceFormValues,
} from "./work-experience";

type CareerExperienceFormValues =
  | CareerBreakFormValues
  | WorkExperienceFormValues;

const isCareerBreakFormValues = (
  values: CareerExperienceFormValues
): values is CareerBreakFormValues =>
  values.type === CareerExperienceType.CareerBreak;

interface CareerExperienceCardProps {
  field: CareerExperienceFormValues;
  onChangeType: (type: CareerExperienceType) => void;
  onCancel: () => void;
}

interface CareerExperienceCardProps {
  field: CareerBreakFormValues | WorkExperienceFormValues;
  onChangeType: (type: CareerExperienceType) => void;
  onCancel: () => void;
}

export const CareerExperienceCard = memo(
  ({ field, onChangeType, onCancel }: CareerExperienceCardProps) => {
    if (isCareerBreakFormValues(field))
      return (
        <CareerBreakCard
          key={field.id}
          {...field}
          form={
            <CareerBreakFormCard
              defaultValues={field}
              onChangeType={onChangeType}
              onCancel={onCancel}
            />
          }
        />
      );

    return (
      <WorkExperienceCard
        key={field.id}
        {...field}
        form={
          <WorkExperienceFormCard
            defaultValues={field}
            onChangeType={onChangeType}
            onCancel={onCancel}
          />
        }
      />
    );
  },
  (a, b) => isEqual(a.field, b.field)
);
CareerExperienceCard.displayName = "CareerExperienceCard";
