import React, { forwardRef, ReactNode, useState } from "react";
import { merge } from "lodash";

import { styled, useDripsyTheme, View, SxProp } from "dripsy";
import { View as NativeView, ViewProps } from "react-native";
import { Feather } from "@expo/vector-icons";

import { FCC } from "app/types/index";
import { AnimatedCheckIcon } from "app/design/animated-check-icon";
import { IconButton, IconButtonProps } from "app/design/icon-button";

import {
  ButtonContainerPressable,
  ButtonContainerPressableProps,
  ButtonText,
  ButtonTextProps,
  useButtonTextColorStates,
} from "app/design/button";

const ICON_SIZE = 16;
const ICON_SIZE_MD = 20;

interface ToggleButtonTextProps extends Omit<ButtonTextProps, "colorStates"> {
  checked: boolean;
}

export const ToggleButtonText: FCC<ToggleButtonTextProps> = ({
  checked,
  ...props
}) => {
  const { theme } = useDripsyTheme();
  return (
    <ButtonText
      colorStates={{
        color: {
          states: [[checked, theme.colors.$white]],
        },
      }}
      {...props}
    />
  );
};

type ToggleButtonVariants = "homeToggle" | "uiToggle";

export interface ToggleButtonProps extends ButtonContainerPressableProps {
  children?: ReactNode;
  variant?: ToggleButtonVariants;
  hasIcon?: boolean;
  checked?: boolean;
  onChange?: () => void;
}

export const ToggleButton = forwardRef<NativeView, ToggleButtonProps>(
  (
    {
      size = "sm",
      variant = "homeToggle",
      children,
      hasIcon = false,
      checked = false,
      onChange,
      sx,
      containerSx,
    },
    ref
  ) => {
    const { theme } = useDripsyTheme();

    const themeProps = theme.button[variant];

    const buttonTextColorStates = useButtonTextColorStates(variant);
    const withIconSx = {
      px: [ICON_SIZE + theme.space.$6, ICON_SIZE_MD + theme.space.$6],
    };

    return (
      <ButtonContainerPressable
        ref={ref}
        onPress={onChange}
        variant={variant}
        size={size}
        colorStates={{
          backgroundColor: {
            states: [[checked, themeProps._checked.backgroundColor]],
            initialColor: themeProps.backgroundColor,
          },
          borderColor: {
            states: [[checked, themeProps._checked.borderColor]],
            initialColor: themeProps.borderColor,
          },
        }}
        sx={{
          position: "relative",
          ...(hasIcon ? withIconSx : {}),
          ...sx,
        }}
        containerSx={containerSx}
      >
        {hasIcon && (
          <AnimatedCheckIcon
            sx={{
              position: "absolute",
              left: theme.button.sizes[size].py,
            }}
            colorStates={merge({}, buttonTextColorStates, {
              borderColor: {
                states: [[checked, themeProps._checked.backgroundColor]],
                initialColor: theme.colors.$ui,
              },
            })}
            checked={checked}
            iconSize={[ICON_SIZE, ICON_SIZE_MD]}
          />
        )}
        <ToggleButtonText checked={checked} variant={variant}>
          {children}
        </ToggleButtonText>
      </ButtonContainerPressable>
    );
  }
);

ToggleButton.displayName = "ToggleButton";

const ToggleButtonGroupContainer = styled(View)({
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  ml: (theme) => 0 - theme.space.$2,
  mt: (theme) => 0 - theme.space.$2,
});

const toggleButtonInGroupSx = { marginLeft: "$2", marginTop: "$2" };

export interface ToggleButtonGroupProps extends ViewProps {
  sx?: SxProp;
  toggleButtonProps?: ToggleButtonProps;
  toggleButtonPropsField?: string;
  children:
    | React.ReactElement<ToggleButtonProps>
    | React.ReactElement<ToggleButtonProps>[];
}

const ToggleButtonGroupContent: FCC<ToggleButtonGroupProps> = ({
  toggleButtonProps = {},
  toggleButtonPropsField: targetFieldName,
  children,
}) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<ToggleButtonProps>[];

  return (
    <>
      {React.Children.map(childrenArray, (child, index) => {
        const { sx: childSx, ...childProps } = child.props;

        const newProps = {
          key: `toggle-button-group-child-${index}`,
          ...toggleButtonProps,
          ...childProps,
          sx: {
            ...toggleButtonProps.sx,
            ...childSx,
          },
          containerSx: {
            ...toggleButtonInGroupSx,
            ...toggleButtonProps.containerSx,
          },
        };

        return React.cloneElement(
          child,
          targetFieldName ? { [targetFieldName]: newProps } : newProps
        );
      })}
    </>
  );
};

export const ToggleButtonGroup: FCC<ToggleButtonGroupProps> = ({
  sx,
  ...props
}) => {
  return (
    <ToggleButtonGroupContainer sx={sx}>
      <ToggleButtonGroupContent {...props} />
    </ToggleButtonGroupContainer>
  );
};

interface CollapseButtonProps
  extends Omit<IconButtonProps, "icon" | "accessibilityLabel"> {
  children?: ReactNode;
  collapsed?: boolean;
}

const CollapseButton: FCC<CollapseButtonProps> = ({
  collapsed,
  ...iconButtonProps
}) => (
  <IconButton
    {...iconButtonProps}
    rounded={false}
    icon={<Feather name={collapsed ? "plus" : "minus"} size={20} />}
    accessibilityLabel={
      collapsed ? "Show additional categories" : "Hide additional categories"
    }
  />
);

interface CollapseableToggleButtonGroupProps extends ToggleButtonGroupProps {
  count: number;
}

export const CollapseableToggleButtonGroup: FCC<
  CollapseableToggleButtonGroupProps
> = ({ count, sx, children, ...toggleButtonGroupProps }) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<ToggleButtonProps>[];

  const [collapsed, setCollapsed] = useState(true);

  const displayedChildren = collapsed
    ? childrenArray.slice(0, count)
    : childrenArray;

  const hasMore = childrenArray.slice(count).length;

  return (
    <ToggleButtonGroupContainer sx={sx}>
      <ToggleButtonGroupContent {...toggleButtonGroupProps}>
        {displayedChildren}
      </ToggleButtonGroupContent>
      {hasMore && (
        <CollapseButton
          {...toggleButtonGroupProps.toggleButtonProps}
          sx={{
            ...toggleButtonGroupProps.toggleButtonProps?.sx,
          }}
          containerSx={toggleButtonInGroupSx}
          collapsed={collapsed}
          onPress={() => setCollapsed(!collapsed)}
        />
      )}
    </ToggleButtonGroupContainer>
  );
};
