import { Row } from "dripsy";
import { Skeleton } from "native-base";
import { NotificationContent } from "./common";

const NotificationCardLoading = () => (
  <Row
    sx={{
      px: ["$4", "$6"],
      py: ["$3", "$4"],
    }}
  >
    <Skeleton rounded="full" size={12} />
    <NotificationContent>
      <Skeleton.Text lines={3} />
    </NotificationContent>
  </Row>
);

export { NotificationCardLoading };
