import { SearchStage } from "app/types/generated/schema";
import { SectionStatus } from "app/features/profile/section";

export const searchStage: Record<`${SearchStage}`, string> = {
  [SearchStage.Asap]: "As soon as possible",
  [SearchStage.InTheNextFewMonths]: "In the next few months",
  [SearchStage.InTheNextYear]: "In the next year",
  [SearchStage.JustCurious]: "Not looking, I'm just curious",
};

export const profileSectionStatus: Record<`${SectionStatus}`, string> = {
  [SectionStatus.NOT_STARTED]: "Not started",
  [SectionStatus.IN_PROGRESS]: "In progress",
  [SectionStatus.COMPLETED]: "Complete",
};

export const enums = {
  searchStage,
  profileSectionStatus,
};
