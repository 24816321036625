import { NetworkStatus } from "@apollo/client";

const getQueryLoadingStatus = (networkStatus: NetworkStatus) => ({
  loading: [
    NetworkStatus.loading,
    NetworkStatus.refetch,
    NetworkStatus.setVariables,
  ].includes(networkStatus),
  loadingMore: networkStatus === NetworkStatus.fetchMore,
});

const conditionalParam = <TParam extends object>(
  param: TParam,
  condition: boolean
) => {
  return condition ? param : {};
};

interface ToCacheReferenceProps {
  __typename: string;
  id: string;
}

const toCacheReference = ({ __typename, id }: ToCacheReferenceProps) => ({
  __ref: `${__typename}:${id}`,
});

export type { ToCacheReferenceProps };
export { getQueryLoadingStatus, conditionalParam, toCacheReference };
