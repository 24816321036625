import { Row } from "dripsy";
import { useDisclose } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAsyncStorageEffect } from "app/hooks/use-async-storage-effect";
import { AlertCard, AlertText } from "app/design/alert";
import { Button } from "app/design/button";
import { ToastCloseButton } from "app/components/use-toast";
import { useWebLink } from "app/components/web-link";

const SWOOSH_PROMO_DISMISSED = "SWOOSH_PROMO_DISMISSED";

const SwooshBanner = ({ show }: { show: boolean }) => {
  const ieltsWebLinkProps = useWebLink(
    "https://go.swooshenglish.com/15-ielt-fast-pass-pack-optin56351078?fpr=migratehr"
  );
  const oetWebLinkProps = useWebLink(
    "https://go.swooshenglish.com/15-oet-fast-pass-pack-optin?fpr=migrate"
  );

  const { isOpen, onClose, onOpen } = useDisclose();

  useAsyncStorageEffect(
    SWOOSH_PROMO_DISMISSED,
    (dismissed) => {
      if (dismissed) return;
      if (show) {
        onOpen();
      } else {
        onClose();
      }
    },
    [show]
  );

  if (!isOpen) return null;

  return (
    <AlertCard
      sx={{
        borderRadius: 0,
        zIndex: 2,
      }}
      accentSx={{ borderRadius: 0 }}
    >
      <Row sx={{ alignItems: "flex-start" }}>
        <AlertText sx={{ mb: "$2", fontWeight: "600", flex: 1, mr: "$3" }}>
          Need help preparing for your language exam?
        </AlertText>
        <ToastCloseButton
          status="info"
          onClose={async () => {
            onClose();
            await AsyncStorage.setItem(SWOOSH_PROMO_DISMISSED, "true");
          }}
        />
      </Row>
      <AlertText sx={{ mb: "$3" }}>
        Migrate are partnered with Swoosh who offer offer fast-pass preparation
        courses for IELTs &amp; OET. Click the links below to secure your place
        in the free weekly webinar.
      </AlertText>
      <Row>
        <Button size="xs" containerSx={{ mr: "$3" }} {...ieltsWebLinkProps}>
          IELTs webinar
        </Button>
        <Button size="xs" {...oetWebLinkProps}>
          OET webinar
        </Button>
      </Row>
    </AlertCard>
  );
};

export { SwooshBanner };
