import { ReactNode } from "react";
import { Grid, GridItem } from "app/design/grid";
import { MinScreenSize } from "app/components/min-screen-size";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import { useCurrentUser } from "app/hooks";
import { memoizeScreen } from "app/utils/screen";
import { Layout } from "../layout";
import { YourJobs } from "./your-jobs";
import { MatchesOverview } from "./matches-overview";

const IsVerifiedCandidateWithPreferences = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isCandidateV1, isVerified } = useCurrentUser();
  const isVerifiedCandidateWithPreferences = !isCandidateV1 && isVerified;
  if (!isVerifiedCandidateWithPreferences) return null;
  return <>{children}</>;
};

const JobsScreen = memoizeScreen(() => (
  <Screen scrollable>
    <MinScreenSize size="tablet">
      <ScreenHeader>Jobs</ScreenHeader>
    </MinScreenSize>
    <Grid>
      <GridItem widths={[1, 1, 1 / 2]}>
        <MatchesOverview />
      </GridItem>
    </Grid>
    <IsVerifiedCandidateWithPreferences>
      <YourJobs />
    </IsVerifiedCandidateWithPreferences>
  </Screen>
));

const headerProps = {
  title: "Jobs",
  backButtonType: "none",
} as const;

JobsScreen.displayName = "JobsScreen";
JobsScreen.headerProps = headerProps;
JobsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { JobsScreen };
