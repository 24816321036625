import { memo, useEffect } from "react";
import { View } from "dripsy";
import {
  FieldValues,
  Path,
  UseFormResetField,
  useWatch,
} from "react-hook-form";
import { gql } from "@apollo/client";

import { useRedListCountriesQuery } from "app/types/generated/schema";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";
import { WebLink } from "app/components/web-link";
import { CountryControl } from "app/components/form/country-control";

import { StepHeading } from "./heading";
import { ToggleFlagButton } from "./toggle-flag-button";

export const RedListCountriesQuery = gql`
  query RedListCountries {
    redListCountries @client
  }
`;

interface CountryStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  resetField: UseFormResetField<TFieldValues>;
  name: Path<TFieldValues>;
  onIneligible: () => void;
}

interface StepFormProps<TFieldValues extends FieldValues>
  extends Omit<CountryStepProps<TFieldValues>, "resetField" | "stepPath"> {
  onReset: () => void;
}

const StepForm = <TFieldValues extends FieldValues>({
  control,
  onReset,
  onIneligible,
  name,
}: StepFormProps<TFieldValues>) => {
  const country = useWatch({ control, name });
  const { data } = useRedListCountriesQuery();
  const isRedListCountry = data?.redListCountries.includes(country);

  useEffect(() => {
    if (isRedListCountry) {
      onReset();
      onIneligible();
    }
  }, [isRedListCountry]);

  return (
    <>
      <StepHeading>What country are you currently working in?</StepHeading>
      <View
        sx={{
          maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
        }}
      >
        <CountryControl
          control={control}
          name={name}
          rules={{ required: true }}
          menuProps={{
            placement: "bottom center",
            FlagButtonComponent: ToggleFlagButton,
          }}
        />
      </View>
    </>
  );
};

const MemoizedStepForm = memo(StepForm) as typeof StepForm & {
  displayName: "CountryStepForm";
};

MemoizedStepForm.displayName = "CountryStepForm";

const CountryStep = <TFieldValues extends FieldValues>({
  control,
  resetField,
  onIneligible,
  name,
}: CountryStepProps<TFieldValues>) => {
  return (
    <MemoizedStepForm
      onIneligible={onIneligible}
      control={control}
      name={name}
      onReset={() => {
        // Reset default value to prevent lock on indeligible default value
        resetField(name, {
          defaultValue: "",
        });
      }}
    />
  );
};

const IneligibleCountryContent = () => (
  <>
    Due to the UK goverment&apos;s{" "}
    <WebLink url="https://www.gov.uk/government/publications/code-of-practice-for-the-international-recruitment-of-health-and-social-care-personnel/code-of-practice-for-the-international-recruitment-of-health-and-social-care-personnel-in-england">
      code of practice
    </WebLink>{" "}
    for health and social care organisations in England, we&apos;re not allowed
    to advertise jobs to candidates in your country. To search for jobs, visit{" "}
    <WebLink url="https://www.jobs.nhs.uk/">NHS Jobs</WebLink>.
  </>
);

export { CountryStep, IneligibleCountryContent };
