import { H4, H5, styled, View } from "dripsy";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";

import {
  useJobPreferencesOverviewCardQuery,
  useProfileOverviewCardQuery,
} from "app/types/generated/schema";
import { Card } from "app/design/card";
import { ButtonLink } from "app/design/button";
import { ActivityIndicator } from "app/design/activity-indicator";
import { List, Tick } from "app/components/list";
import { Text } from "app/components/text";
import { DataError } from "app/components/data-error";
import {
  JOB_PREFERENCES_APPLICANT_PROPS,
  JOB_PREFERENCES_CANDIDATE_V2_PROPS,
} from "app/graphql/job-preferences";
import { pagesPath } from "app/lib/$path";

import { ProfileStripeCard } from "../../card";

const JobPreferencesTitle = styled(H5)({
  mt: 0,
  mb: "$3",
});

const JobPreferencesCard = styled(Card)({
  bg: "$cream",
  borderColor: "$cream",
  py: "$3",
  px: "$4",
  boxShadow: "none",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

interface JobPreferenceItemProps {
  title: string;
  value: string;
}

const JobPreferenceItem = ({ title, value }: JobPreferenceItemProps) => {
  return (
    <View
      sx={{
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Text
        variant="sm"
        sx={{
          width: [100, 130],
        }}
      >
        {title}
      </Text>
      <Text
        variant="sm"
        sx={{
          fontWeight: "500",
          flex: 1,
          color: "$black",
        }}
      >
        {value}
      </Text>
    </View>
  );
};

export const JOB_PREFERENCES_OVERVIEW_CARD_QUERY = gql`
  query JobPreferencesOverviewCard {
    me {
      id
      ...JobPreferencesApplicantProps
      ...JobPreferencesCandidateV2Props
    }
  }
  ${JOB_PREFERENCES_APPLICANT_PROPS}
  ${JOB_PREFERENCES_CANDIDATE_V2_PROPS}
`;

const JobPreferencesOverviewContent = () => {
  const { data, loading, error } = useJobPreferencesOverviewCardQuery();
  const { t } = useTranslation("enums");

  if (loading) {
    return (
      <View
        sx={{
          my: "$3",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  if (error || !data) {
    return <DataError title={error?.name} description={error?.message} />;
  }

  if (data.me.__typename !== "CandidateV2") {
    return (
      <Text variant="sm">No preferences set. Please update your profile.</Text>
    );
  }

  const {
    tagPreferences,
    locationPreferences,
    searchStage,
    countryInfo,
    profession,
  } = data.me;

  const tagPreferencesList =
    tagPreferences.length > 0
      ? tagPreferences.map(({ name }) => name).join(", ")
      : undefined;

  const locationPreferencesList =
    locationPreferences.length > 0
      ? locationPreferences.map(({ name }) => name).join(", ")
      : undefined;

  return (
    <>
      {profession ? (
        <JobPreferenceItem title="Profession" value={profession.name} />
      ) : null}

      {countryInfo ? (
        <JobPreferenceItem title="Your country" value={countryInfo.name} />
      ) : null}

      {tagPreferencesList ? (
        <JobPreferenceItem title="Job types" value={tagPreferencesList} />
      ) : null}

      {locationPreferencesList ? (
        <JobPreferenceItem title="Locations" value={locationPreferencesList} />
      ) : null}
      {searchStage ? (
        <JobPreferenceItem
          title="Search stage"
          value={t(`searchStage.${searchStage}`)}
        />
      ) : null}
    </>
  );
};

const JobPreferencesOverviewCard = () => {
  return (
    <JobPreferencesCard>
      <JobPreferencesTitle>Your search preferences</JobPreferencesTitle>
      <JobPreferencesOverviewContent />
      <ButtonLink
        variant="link"
        size="sm"
        containerSx={{
          mt: "$3",
        }}
        href={pagesPath.job_preferences.$url()}
      >
        Edit my search preferences
      </ButtonLink>
    </JobPreferencesCard>
  );
};

export const PROFILE_OVERVIEW_CARD_QUERY = gql`
  query ProfileOverviewCard {
    me {
      id
      forenames
    }
  }
`;

export const ProfileOverviewCard = () => {
  const { data } = useProfileOverviewCardQuery();

  const title = `Hey ${
    data?.me?.forenames ?? "there"
  }, this is your Migrate profile`;

  return (
    <ProfileStripeCard sx={{ mb: "$6", pl: [18, 22] }}>
      <H4 sx={{ mb: "$4" }}>{title}</H4>
      <List Icon={<Tick />} flush sx={{ mb: "$4" }}>
        <List.Item>
          Use your profile to easily apply to all of the jobs on Migrate
        </List.Item>
        <List.Item>
          Applicants with complete profiles are more likely to be shortlisted
        </List.Item>
        <List.Item>
          Employers will see your profile when you apply to their jobs
        </List.Item>
      </List>
      <JobPreferencesOverviewCard />
    </ProfileStripeCard>
  );
};
