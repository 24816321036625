import { DripsyFinalTheme, useDripsyTheme, Sx, H4 } from "dripsy";
import { AccentCard, CardProps } from "app/design/card";
import { Text } from "app/components/text";
import {
  PressableCard,
  PressableCardProps,
} from "app/components/pressable-card";
import { FCC } from "app/types";
type AlertCardVariant = keyof Omit<DripsyFinalTheme["alert"], "text">;

interface AlertCardProps extends Omit<CardProps, "variant" | "variants"> {
  variant?: AlertCardVariant;
  accentSx?: Sx;
}

const DEFAULT_VARIANT = "info";

const AlertCard = ({
  variant = DEFAULT_VARIANT,
  sx: _sx,
  accentSx = {},
  ...props
}: AlertCardProps) => {
  const { theme } = useDripsyTheme();
  const {
    _accent: { bg: accentBg },
    borderRadius,
    ...sx
  } = theme.alert[variant];

  return (
    <AccentCard
      accentColor={accentBg}
      sx={{
        borderRadius,
        ...sx,
        ..._sx,
      }}
      accentSx={{
        borderRadius,
        ...accentSx,
      }}
      {...props}
    />
  );
};

interface AlertTextProps {
  sx?: Sx;
  variant?: AlertCardVariant;
}

const AlertHeader: FCC<AlertTextProps> = ({
  variant = "info",
  sx = {},
  children,
}) => {
  const { theme } = useDripsyTheme();
  const { color } = theme.alert[variant];

  return <H4 sx={{ color, ...sx }}>{children}</H4>;
};

const useAlertTextProps = (variant: AlertCardVariant) => {
  const { theme } = useDripsyTheme();
  const { color, fontWeight, fontSize, lineHeight } = theme.alert[variant];

  return {
    color,
    fontWeight,
    fontSize,
    lineHeight,
  };
};

const AlertText: FCC<AlertTextProps> = ({
  variant = "info",
  sx = {},
  children,
}) => {
  const { color, fontWeight, fontSize, lineHeight } =
    useAlertTextProps(variant);

  return (
    <Text
      sx={{
        color,
        fontWeight,
        fontSize,
        lineHeight,
        ...sx,
      }}
    >
      {children}
    </Text>
  );
};

interface AlertProps extends AlertCardProps {
  textSx?: Sx;
}

const Alert = ({
  children,
  variant = "info",
  textSx,
  ...props
}: AlertProps) => {
  return (
    <AlertCard variant={variant} {...props}>
      <AlertText variant={variant} sx={textSx}>
        {children}
      </AlertText>
    </AlertCard>
  );
};

interface PressableAlertCardProps extends Omit<PressableCardProps, "variant"> {
  variant?: AlertCardVariant;
}

const PressableAlertCard = ({
  variant = DEFAULT_VARIANT,
  sx: _sx = {},
  ...rest
}: PressableAlertCardProps) => {
  const { theme } = useDripsyTheme();
  const {
    _accent: { bg: accentBg },
    _hover: { bg: activeColor },
    ...sx
  } = theme.alert[variant];

  return (
    <PressableCard
      sx={{ ...sx, ..._sx }}
      initialColor={sx.bg}
      activeColor={activeColor}
      accentProps={{
        activeOverride: true,
        sx: {
          backgroundColor: accentBg,
        },
        containerSx: {
          borderRadius: "sm",
        },
      }}
      {...rest}
    />
  );
};

export {
  Alert,
  AlertCard,
  AlertHeader,
  AlertText,
  PressableAlertCard,
  useAlertTextProps,
};
