import { useState } from "react";
import { H4, View } from "dripsy";
import { FieldValues, useController } from "react-hook-form";
import { Dropdown } from "app/design/dropdown";
import { InputFooter } from "app/design/input";
import { Grid, GridItem } from "app/design/grid";
import { MONTHS, YEARS } from "app/constants/dates";
import { FormControlProps } from "./form-control";

export interface MonthYearControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  label: string;
}

const monthWithLeadingZero = (month: number) => {
  return month < 10 ? `0${month}` : month.toString();
};

const getInitialValues = (value: string) => {
  if (!value)
    return {
      month: "",
      year: "",
    };

  const date = new Date(value);
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear().toString();

  return {
    month: monthWithLeadingZero(month + 1),
    year,
  };
};

export const MonthYearControl = <T extends FieldValues>(
  props: MonthYearControlProps<T>
) => {
  const { name, control, rules, defaultValue, label } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const initialValues = getInitialValues(value);

  const [month, setMonth] = useState(initialValues.month);
  const [year, setYear] = useState(initialValues.year);

  const constructDate = (m: string, y: string) => {
    return new Date(y + "-" + m + "-01").toISOString();
  };

  const isRequiredError = error?.message?.includes("required");

  const getError = (fieldValue: string) => {
    if (!error) return undefined;
    if (isRequiredError) return !fieldValue ? "" : undefined;
    return "";
  };

  return (
    <View sx={{ mb: "$3" }}>
      <H4 sx={{ mb: "$2" }}>{label}</H4>
      <Grid>
        <GridItem widths={[2 / 3]}>
          <Dropdown
            value={month}
            onChange={(m) => {
              setMonth(m);
              if (!!year) onChange(constructDate(m, year));
            }}
            options={MONTHS.map((m, i) => ({
              label: m,
              value: monthWithLeadingZero(i + 1),
            }))}
            placeholder="Month"
            error={getError(month)}
          />
        </GridItem>
        <GridItem widths={[1 / 3]}>
          <Dropdown
            value={year}
            onChange={(y) => {
              setYear(y);
              if (!!month) onChange(constructDate(month, y));
            }}
            options={YEARS.map((y) => ({ label: y, value: y }))}
            placeholder="Year"
            error={getError(year)}
          />
        </GridItem>
      </Grid>
      <InputFooter error={error?.message} />
    </View>
  );
};
