import { alert } from "./alert";
import { bubble } from "./bubble";
import { button } from "./button";
import { card } from "./card";
import { dropdownMenu } from "./dropdown-menu";
import { iconButton } from "./icon-button";
import { layout } from "./layout";

const components = {
  alert,
  bubble,
  button,
  card,
  dropdownMenu,
  iconButton,
  layout,
  modal: {
    fullScreen: {
      p: "$4",
    },
  },
};

export { components };
