import { useDripsyTheme, DripsyFinalTheme } from "dripsy";
import { useLink, UseLinkProps } from "solito/link";
import { FCC } from "app/types/index";
import { Button, ButtonProps } from "./button";

interface IconButtonProps extends ButtonProps {
  rounded?: boolean;
  size?: keyof DripsyFinalTheme["iconButton"]["sizes"];
  icon: React.ReactElement;
  accessibilityLabel: string;
}

const IconButton: FCC<IconButtonProps> = ({
  rounded = true,
  size = "md",
  variant = "primary",
  sx: _sx = {},
  ...rest
}) => {
  const { theme } = useDripsyTheme();

  return (
    <Button
      variant={variant}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        ...(rounded ? { borderRadius: 100 } : {}),
        px: 0,
        py: 0,
        ...theme.iconButton.sizes[size],
        ..._sx,
      }}
      {...rest}
    />
  );
};

type IconButtonLinkProps = UseLinkProps &
  Omit<
    IconButtonProps,
    // ignore props that will be overridden by useLink
    keyof UseLinkProps | keyof ReturnType<typeof useLink>
  >;

const IconButtonLink: FCC<IconButtonLinkProps> = ({
  href,
  as,
  shallow,
  ...rest
}) => {
  const linkProps = useLink({ href, as, shallow });

  return <IconButton {...rest} {...linkProps} />;
};

export type { IconButtonProps };
export { IconButton, IconButtonLink };
