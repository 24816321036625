import { ComponentProps } from "react";
import { useWindowDimensions } from "react-native";
import { styled, useDripsyTheme, View } from "dripsy";
import { MotiView } from "moti";
import {
  Stone1,
  Stone2,
  Stone3,
  Stone4,
  Stone5,
  Stone6,
} from "app/design/svgs/stones";

const DripsyMotiView = styled(MotiView)();

type Props = ComponentProps<typeof MotiView>;

const getAnimation = (): Pick<Props, "from" | "animate" | "transition"> => ({
  from: {
    translateY: Math.ceil(Math.random() * -50),
    translateX: Math.ceil(Math.random() * -20),
    scale: Math.floor(Math.random() * (12 - 7 + 1) + 7) / 10,
  },
  animate: {
    translateY: 0,
    translateX: 0,
    scale: 1,
  },
  transition: {
    loop: true,
    type: "timing",
    duration: Math.ceil(Math.random() * 5000) + 5000,
    delay: Math.floor(Math.random() * (150 - 50 + 1) + 50),
  },
});

const FloatyStones = () => {
  const { theme } = useDripsyTheme();
  const { height } = useWindowDimensions();
  return (
    <View sx={{ position: "absolute", top: height * 0.15 }}>
      <View sx={{ right: [100, 200, 300], position: "absolute", top: 20 }}>
        <DripsyMotiView
          sx={{ width: 65, height: 59, top: 10 }}
          {...getAnimation()}
        >
          <Stone1 fill={theme.colors.$orange} />
        </DripsyMotiView>
        <DripsyMotiView
          sx={{ width: 128, height: 148, top: 50, right: 75 }}
          {...getAnimation()}
        >
          <Stone2 fill={theme.colors.$blue} />
        </DripsyMotiView>
        <DripsyMotiView
          sx={{ width: 236, height: 221, top: 85, right: [150, 50] }}
          {...getAnimation()}
        >
          <Stone3 fill={theme.colors.$pink} />
        </DripsyMotiView>
      </View>
      <View sx={{ left: [150, 200, 300] }}>
        <DripsyMotiView
          sx={{ width: 75, height: 70, top: 0, left: 130 }}
          {...getAnimation()}
        >
          <Stone4 fill={theme.colors.$blue} />
        </DripsyMotiView>
        <DripsyMotiView
          sx={{ width: 186, height: 151, top: 40, left: 140 }}
          {...getAnimation()}
        >
          <Stone5 fill={theme.colors.$pink} />
        </DripsyMotiView>
        <DripsyMotiView
          sx={{ width: 283, height: 228, right: [-100, 0], top: 60 }}
          {...getAnimation()}
        >
          <Stone6 fill={theme.colors.$orange} />
        </DripsyMotiView>
      </View>
    </View>
  );
};

export { FloatyStones };
