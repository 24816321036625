import { useCallback } from "react";
import { ChannelMessage } from "../../types";
import {
  MessageBubbleProps,
  RenderMessageHeader,
  MessageHeaderContainer,
  MessageHeaderDate,
} from "../bubble";
import { MessageContainer, AvatarSpacer } from "../message-container";
import { UnsentMessageBubble } from "./unsent";
import { ErrorMessageBubble } from "./error";
import { SentMessageBubble } from "./sent";

const UserMessageContent = (props: MessageBubbleProps) => {
  const { message } = props;

  if (message.isSent) {
    return <SentMessageBubble {...props} />;
  }

  if (message.sendError) {
    return <ErrorMessageBubble {...props} />;
  }

  return <UnsentMessageBubble {...props} />;
};

const renderUserMessageHeader: RenderMessageHeader = ({ message, color }) => {
  return (
    <MessageHeaderContainer>
      <MessageHeaderDate color={color} isoDate={message.createdAt} />
    </MessageHeaderContainer>
  );
};

interface UserMessageProps {
  message: ChannelMessage;
  isSameThread: boolean;
}

const UserMessage = ({ isSameThread, message }: UserMessageProps) => {
  const renderHeader = useCallback(renderUserMessageHeader, []);

  return (
    <MessageContainer messagePosition="right" isSameThread={isSameThread}>
      {!isSameThread ? <AvatarSpacer /> : null}
      <UserMessageContent
        message={message}
        renderHeader={renderHeader}
        showNotch={!isSameThread}
        notchPosition="right"
      />
    </MessageContainer>
  );
};

export * from "./loading";
export type { UserMessageProps };
export { UserMessage };
