import { memo, useEffect, useRef } from "react";
import { useDripsyTheme, View } from "dripsy";
import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { gql } from "@apollo/client";

import {
  LocationStepLocationPropsFragment,
  Scalars,
  useLocationsQuery,
} from "app/types/generated/schema";
import { ActivityMessage } from "app/design/activity-indicator";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";

import { StepHeading } from "./heading";
import { StepToggle } from "./step-toggle";

export const LOCATION_STEP_LOCATION_PROPS = gql`
  fragment LocationStepLocationProps on Location {
    id
    name
  }
`;

export const LocationsQuery = gql`
  query Locations {
    locations {
      ...LocationStepLocationProps
    }
  }

  ${LOCATION_STEP_LOCATION_PROPS}
`;

interface LocationStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  setValue: UseFormSetValue<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  name: Path<TFieldValues>;
}

interface StepFormProps<TFieldValues extends FieldValues>
  extends Omit<
    LocationStepProps<TFieldValues>,
    "setValue" | "watch" | "stepPath"
  > {
  locations: LocationStepLocationPropsFragment[];
  loading?: boolean;
}

const StepForm = <TFieldValues extends FieldValues>({
  control,
  locations,
  loading,
}: StepFormProps<TFieldValues>) => {
  const { theme } = useDripsyTheme();

  return (
    <>
      <StepHeading>Where would you like to work?</StepHeading>
      {loading ? (
        <ActivityMessage>Loading locations</ActivityMessage>
      ) : (
        <View
          sx={{
            marginTop: 0 - theme.space.$2,
            width: "100%",
            maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
          }}
        >
          {locations.map((location) => (
            <StepToggle
              sx={{
                marginTop: "$2",
              }}
              key={location.id}
              control={control}
              name="locationPreferenceIds"
              label={location.name}
              checkedValue={location.id}
              isMulti
              rules={{ required: true }}
              toggleButtonProps={{ hasIcon: true }}
            />
          ))}
        </View>
      )}
    </>
  );
};

const MemoizedStepForm = memo(StepForm) as typeof StepForm & {
  displayName: "LocationStepForm";
};

MemoizedStepForm.displayName = "LocationStepForm";

export const LOCATION_DONT_MIND: LocationStepLocationPropsFragment = {
  id: "location-dont-mind",
  name: "I don't mind",
};

const LocationStep = <TFieldValues extends FieldValues>({
  control,
  watch,
  setValue,
  name,
}: LocationStepProps<TFieldValues>) => {
  const { data, loading } = useLocationsQuery();

  const selectedLocations = watch(name);
  const previousSelectedLocationsRef = useRef<string[]>([]);
  const previouslySelectedLocations = previousSelectedLocationsRef.current;

  useEffect(() => {
    const shouldAmendSelection = selectedLocations.length > 1;

    if (
      shouldAmendSelection &&
      previouslySelectedLocations.includes(LOCATION_DONT_MIND.id)
    ) {
      setValue(
        name,
        selectedLocations.filter(
          (l: Scalars["MigrateID"]) => l !== LOCATION_DONT_MIND.id
        )
      );
      return;
    }

    if (
      shouldAmendSelection &&
      selectedLocations.includes(LOCATION_DONT_MIND.id)
    )
      setValue(
        name,
        // @ts-expect-error Custom code to remove don't mind option in parent
        [LOCATION_DONT_MIND.id]
      );

    previousSelectedLocationsRef.current = selectedLocations;
  }, [selectedLocations]);

  return (
    <MemoizedStepForm
      name={name}
      control={control}
      locations={[LOCATION_DONT_MIND, ...(data?.locations || [])]}
      loading={loading}
    />
  );
};

LocationStep.fragments = {
  location: LOCATION_STEP_LOCATION_PROPS,
};

export { LocationStep };
