import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 299;
const originalHeight = 280;
const aspectRatio = originalWidth / originalHeight;

const Stone4 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="M191.642 4.652C321.297 28.14 364.545 346.594 142.763 267.37-79.02 188.146-26.683-34.865 191.642 4.652Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone4 };
