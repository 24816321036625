import { ForwardedRef, forwardRef } from "react";
import { FieldValues, useController } from "react-hook-form";
import { View } from "react-native";
import { FormControlProps } from "./form-control";
import { ToggleButton, ToggleButtonProps } from "./toggle-button";

export interface ToggleControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  toggleButtonProps?: ToggleButtonProps;
  children: React.ReactNode;
  isMulti?: boolean;
  checkedValue: string;
}

export const ToggleControl = forwardRef(
  <T extends FieldValues>(
    {
      checkedValue,
      toggleButtonProps,
      children,
      isMulti = false,
      ...rest
    }: ToggleControlProps<T>,
    ref: ForwardedRef<View>
  ) => {
    const {
      field: { onChange, value },
    } = useController(rest);

    return (
      <ToggleButton
        ref={ref}
        checked={
          isMulti ? value.includes(checkedValue) : value === checkedValue
        }
        onChange={() => {
          if (isMulti) {
            onChange(
              value.includes(checkedValue)
                ? value.filter((v: keyof FieldValues) => v !== checkedValue)
                : [...value, checkedValue]
            );
          } else {
            onChange(value === checkedValue ? "" : checkedValue);
          }
        }}
        {...toggleButtonProps}
      >
        {children}
      </ToggleButton>
    );
  }
);
ToggleControl.displayName = "ToggleControl";
