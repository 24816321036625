import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState } from "react";
import { useAsyncStorageEffect } from "app/hooks/use-async-storage-effect";
import { Button } from "app/design/button";
import { Text } from "app/components/text";
import { List, Tick } from "app/components/list";
import { Link } from "app/components/link";
import { pagesPath } from "app/lib/$path";
import { ProfileStripeCard } from "app/features/profile/card";

const APPLY_TUTORIAL_DISMISSED = "APPLY_TUTORIAL_DISMISSED";

export const ApplyTutorial = () => {
  const [show, setShow] = useState(false);

  useAsyncStorageEffect(APPLY_TUTORIAL_DISMISSED, async (dismissed) => {
    if (dismissed === "false") {
      setShow(true);
    }
  });

  if (!show) return null;

  return (
    <ProfileStripeCard sx={{ mb: "$6", pl: [18, 22] }}>
      <List Icon={<Tick />} flush sx={{ mb: "$4" }}>
        <List.Item>
          We’ll automatically save any progress you make - you can close this
          application form and resume it any time by selecting{" "}
          <Text sx={{ fontWeight: "600" }}>Application Started</Text> within{" "}
          <Link href={pagesPath.jobs.$url()}>Your jobs</Link>.
        </List.Item>
        <List.Item>
          Changes you make here will appear on your profile.{" "}
        </List.Item>
      </List>
      <Button
        containerSx={{ alignSelf: "flex-start" }}
        variant="secondary"
        onPress={() => {
          AsyncStorage.setItem(APPLY_TUTORIAL_DISMISSED, "true");
          setShow(false);
        }}
      >
        Dismiss tutorial
      </Button>
    </ProfileStripeCard>
  );
};
