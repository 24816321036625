import { H3 } from "dripsy";
import { useUpdateUserMutation } from "app/types/generated/schema";
import { Card } from "app/design/card";
import { Button } from "app/design/button";
import { PasswordControl } from "app/components/form/password-control";
import { useAsyncToast } from "app/components/use-toast";
import { useYupForm, yup } from "app/utils/yup";

const schema = yup
  .object({
    oldPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .label("Old password")
      .when("newPassword", (newPassword, _schema) =>
        newPassword
          ? _schema.required("Please enter your old password")
          : _schema
      ),
    newPassword: yup
      .string()
      .required()
      .min(8, "Password must be at least 8 characters")
      .label("New password"),
    newPasswordConfirm: yup
      .string()
      .label("New password")
      .when("newPassword", (newPassword, _schema) =>
        newPassword
          ? _schema.required("Please confirm your new password")
          : _schema
      )
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

export const ChangePasswordForm = () => {
  const [updateAccountSettings, { loading: updating }] =
    useUpdateUserMutation();

  const withToast = useAsyncToast({
    success: {
      title: "Password changed",
    },
    error: {
      title: "Error changing password",
    },
  });

  const { control, formState, handleSubmit, reset } = useYupForm({
    schema,
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
  });

  const onSubmit = withToast(async ({ oldPassword, newPassword }: any) => {
    await updateAccountSettings({
      variables: {
        input: {
          oldPassword,
          newPassword,
        },
      },
    });
    reset();
  });

  return (
    <Card sx={{ mb: "$6" }}>
      <H3 sx={{ mb: "$5" }}>Change password</H3>
      <PasswordControl
        name="oldPassword"
        control={control}
        inputProps={{ label: "Old password" }}
      />
      <PasswordControl
        name="newPassword"
        control={control}
        inputProps={{ label: "New password" }}
      />
      <PasswordControl
        name="newPasswordConfirm"
        control={control}
        inputProps={{ label: "Confirm new password" }}
      />
      <Button
        disabled={!formState.isDirty}
        onPress={handleSubmit(onSubmit)}
        loading={updating}
      >
        Change password
      </Button>
    </Card>
  );
};
