import { useApolloClient } from "@apollo/client";
import { Maybe, Scalars } from "app/types";
import { useSendMessage } from "./use-send-message";
import { useSetMessageError } from "./use-set-message-error";
import { MessageResponse, UploadMessageInput } from "./use-upload-message";

type RetrySendMessage = {
  __typename?: MessageResponse["__typename"];
  id: MessageResponse["id"];
  content?: MessageResponse["content"];
  referencedMessage?: Maybe<{
    id: Scalars["MigrateID"];
  }>;
};

interface RetrySendMessageProps {
  message: RetrySendMessage;
}

const useRetrySendMessage = () => {
  const client = useApolloClient();
  const { cache } = client;

  // TODO: Handle error / loading -> Assumes success / existing cache
  const { sendMessage } = useSendMessage();
  const { getRetryState } = useSetMessageError();

  return async ({ message }: RetrySendMessageProps) => {
    const existingMessageCacheId = cache.identify(message);

    // Do nothing if cannot identify the message
    if (!existingMessageCacheId) {
      console.error("No existing message cache id found");
      return;
    }

    // If no retry state exists, the error has been handled. Do nothing
    const retryState = getRetryState(existingMessageCacheId);

    if (!retryState) {
      console.error("No retry state found");
      return;
    }

    const { channelId, attachment } = retryState;

    // Remove errored message from cache. Defer to `sendMessage` cache handler
    // to create new optimistic cached message at the front of the message list.
    cache.evict({ id: existingMessageCacheId });
    cache.gc();

    await sendMessage(
      {
        content: message.content,
        attachment,
        referencedMessageId: message?.referencedMessage?.id,
        // TODO: Find a way around this type assertion
        // content || attachment will exist when resending
        // as uses original sendMessage data
      } as UploadMessageInput,
      { channelId }
    );
  };
};

export { useRetrySendMessage };
export type { RetrySendMessageProps };
