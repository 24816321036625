import { Row, View } from "dripsy";
import { isAfter } from "date-fns";
import { Button } from "app/design/button";
import { CheckboxControl } from "app/components/form/checkbox-control";
import { InputControl } from "app/components/form/input-control";
import { MonthYearControl } from "app/components/form/month-year-control";
import { AnimateHeight } from "app/components/animate-height";
import { BulletedInputControl } from "app/components/form/bulleted-input-control";
import { useYupForm, yup } from "app/utils/yup";
import { useProfileCardContext } from "../../card/context";
import { useEducationExperienceMutations } from "../hooks/use-education-experience-mutations";
import { generateTempId, isTempField, ProfileForm } from "../profile-form";

interface EducationExperienceUpdateValues {
  id: string;
  institutionName: string;
  qualification: string;
  grade?: string | null;
  startDate: string;
  endDate: string;
  description: string[];
}

interface EducationExperienceFormMetadata {
  hideEndDate: boolean;
}
type EducationExperienceFormValues = EducationExperienceUpdateValues &
  EducationExperienceFormMetadata;

interface EducationExperienceFormCardProps {
  defaultValues: EducationExperienceFormValues;
  onCancel: () => void;
  mode?: "create" | "update";
}

const DEFAULT_VALUE = {
  institutionName: "",
  qualification: "",
  grade: "",
  startDate: "",
  endDate: "",
  description: [],
  hideEndDate: true,
};

const schema = yup.object({
  institutionName: yup.string().required().label("Institution name"),
  qualification: yup.string().required().label("Qualification"),
  grade: yup.string().label("Grade"),
  startDate: yup.string().required().label("Start date"),
  hideEndDate: yup.boolean(),
  endDate: yup
    .string()
    .when("hideEndDate", (hideEndDate, _schema) =>
      !hideEndDate ? _schema.required() : _schema
    )
    .test(
      "test-after-start-date",
      "End date should be after start date",
      (endDate: string | undefined, context) => {
        const { startDate } = context.parent;
        if (!startDate || !endDate) return true;
        return isAfter(new Date(endDate), new Date(startDate));
      }
    )
    .label("End date"),
  description: yup.array().of(yup.string()).required().label("Description"),
});

const EducationExperienceFormCard = ({
  onCancel,
  defaultValues,
}: EducationExperienceFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const { createEducationExperience, updateEducationExperience } =
    useEducationExperienceMutations();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useYupForm<EducationExperienceFormValues>({
    schema,
    defaultValues,
  });

  const [fieldId, hideEndDate] = watch(["id", "hideEndDate"]);
  const isUpdating = !isTempField({ id: fieldId });

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };

  const onSubmit = async (values: EducationExperienceFormValues) => {
    const { hideEndDate: _removed, id, ...createInput } = values;
    const sanitizedInput = {
      ...createInput,
      grade: createInput.grade || null,
      endDate: createInput.endDate || null,
      description: values.description.map((content) => ({ content })),
    };

    try {
      if (isUpdating) {
        if (!id)
          throw new Error("education experience: id is required for update");

        await updateEducationExperience({
          variables: {
            input: {
              ...sanitizedInput,
              id,
            },
          },
        });
      } else {
        await createEducationExperience({
          variables: {
            input: sanitizedInput,
          },
        });
      }

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileForm label="education experience" onCancel={onClose}>
      <View sx={{ maxWidth: [null, 420] }}>
        <InputControl
          control={control}
          name={"institutionName"}
          inputProps={{
            label: "College/university name",
            placeholder: "Enter name of institution",
          }}
        />

        <InputControl
          control={control}
          name="qualification"
          inputProps={{
            label: "Qualification",
            placeholder: "BSc Nursing (Adult)",
          }}
        />

        <InputControl
          control={control}
          name="grade"
          inputProps={{
            label: "Grade",
            placeholder: "2.1",
          }}
        />

        <MonthYearControl
          label="Start date"
          name="startDate"
          control={control}
        />

        <View sx={{ mb: "$3" }}>
          <CheckboxControl
            control={control}
            name="hideEndDate"
            checkboxProps={{
              "aria-label": "Is currently studying here",
              label: "I'm currently studying here",
            }}
          />
        </View>

        <AnimateHeight hide={!!hideEndDate}>
          <MonthYearControl label="End date" name="endDate" control={control} />
        </AnimateHeight>
      </View>

      <BulletedInputControl
        control={control}
        name="description"
        inputProps={{
          label: "Description",
          containerSx: { mb: "$3" },
          sx: { minHeight: 87, maxHeight: 200 },
          placeholderStrings: [
            "Led a team of...",
            "Designed and implemented...",
            "Ran a workshop to...",
          ],
        }}
      />

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

EducationExperienceFormCard.createInstance = () => ({
  id: generateTempId("education-experience"),
  ...DEFAULT_VALUE,
});

export type { EducationExperienceFormValues, EducationExperienceFormCardProps };
export { EducationExperienceFormCard };
