import { Skeleton } from "native-base";
import { ProfileCardContainer } from "app/features/profile/card";

export const ApplicationQuestionsLoading = () => {
  return (
    <ProfileCardContainer>
      <Skeleton.Text
        lines={1}
        _line={{ width: 200, height: 4, rounded: 3 }}
        mb={2}
      />
      <Skeleton.Text lines={1} _line={{ width: 100, height: 3, rounded: 3 }} />
    </ProfileCardContainer>
  );
};
