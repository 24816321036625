import { Skeleton } from "native-base";
import { ProfileCardContainer } from "../../card";

export const RegistrationDetailsLoading = () => {
  return (
    <>
      <ProfileCardContainer>
        <Skeleton.Text
          lines={1}
          _line={{ width: 250, height: 4, rounded: 3 }}
          mb={2}
        />
        <Skeleton.Text
          lines={1}
          _line={{ width: 150, height: 3, rounded: 3 }}
        />
      </ProfileCardContainer>
    </>
  );
};
