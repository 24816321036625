import { View, H1 } from "dripsy";
import { ReactNode } from "react";
import { TextProps } from "./text";

interface HighlightTextProps extends TextProps {
  children: ReactNode;
}
export const HighlightText = ({
  children,
  sx = {},
  ...rest
}: HighlightTextProps) => (
  <View
    sx={{
      px: "$2",
      bg: "$highlightAlpha",
      borderRadius: 4,
    }}
  >
    {/* @ts-expect-error accessibilityRole prop issue */}
    <H1
      sx={{
        color: "$orange",
        mb: "$0",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </H1>
  </View>
);
