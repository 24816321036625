import Constants from "expo-constants";
import { Platform } from "react-native";

declare module "expo-constants" {
  interface Constants {
    expoConfig: {
      extra: {
        channel?: string;
      };
    };
  }
}

const CHANNEL =
  (Platform.OS === "web"
    ? process.env.CHANNEL
    : Constants.expoConfig.extra.channel) || "dev";

const IS_LOCAL = process.env.IS_LOCAL_DEV === "true" || __DEV__;

const config = {
  dev: {
    api: {
      http: "https://uwnw927ze8.execute-api.eu-west-2.amazonaws.com/dev/graphql",
      auth: "https://r3vncjvovb5alh2gb5p6ytefry0kabkn.lambda-url.eu-west-2.on.aws",
    },
    unauthorisedToken: "fnAEPnMoDvAAwAq9A7JDicT0k55ixOuezs_aChzi",
  },
  test: {
    api: {
      http: "https://qlsmotp4b3.execute-api.eu-west-2.amazonaws.com/test/graphql",
      auth: "https://qki4qmhbswtd2mhdlfqxyozvyy0ezgvp.lambda-url.eu-west-2.on.aws",
    },
    unauthorisedToken: "fnAE1gznduAAygYbmINpFETjFpDg8uDIu1SgYN4e",
  },
  prod: {
    api: {
      http: "https://xlpk86r75h.execute-api.eu-west-2.amazonaws.com/prod/graphql",
      auth: "https://lbrawkfqr2ad4b3eeqijqwn74q0ntens.lambda-url.eu-west-2.on.aws",
    },
    unauthorisedToken: "fnAEPnZoEEAAwEk5KhYwbqSJ2m1DKjwsw-TgUoIj",
  },
};

const MAPS_KEY = "AIzaSyBW-fDc4InGWu_t-S9WXOTq2jSvQoDjlkI";
const SENTRY_DSN =
  "https://c4c38fddf027498f96fb1cc71fe57539@o356592.ingest.sentry.io/4504016240246784";
const AMPLITUDE_KEY =
  CHANNEL === "dev"
    ? "7e6759da7e1f1f86550f0a5acf4bfe4e"
    : "dd9602604b9520906f1ad7a4a431fddf";

const SENTRY_CONFIG = {
  dsn: SENTRY_DSN,
  debug: true,
  environment: CHANNEL === "dev" ? "development" : "production",
  enabled: !IS_LOCAL,
};

const { api, unauthorisedToken } = config[CHANNEL as keyof typeof config];
export {
  api,
  unauthorisedToken,
  CHANNEL,
  MAPS_KEY,
  SENTRY_CONFIG,
  AMPLITUDE_KEY,
  IS_LOCAL,
};
