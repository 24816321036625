import { ChangeEventHandler } from "react";
import { useSx } from "dripsy";
import { useUploadDocument } from "app/hooks/use-upload-document";
import { useAsyncToast } from "../use-toast";
import {
  DEFAULT_DOCUMENT_ACCEPT_TYPES,
  defaultRenderUploadButton,
} from "./common";
import { UploadDocumentButtonProps } from "./index";

export const UploadDocumentButton = ({
  sx: _sx = {},
  documentType,
  appendToCache,
  refetchQueries,
  acceptTypes = DEFAULT_DOCUMENT_ACCEPT_TYPES,
  onUpload,
  onUploadStatusChange,
  renderUploadButton = defaultRenderUploadButton,
  ...rest
}: UploadDocumentButtonProps) => {
  const sx = useSx();
  const [upload, { loading }] = useUploadDocument({
    refetchQueries,
    appendToCache,
  });
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Failed to upload document",
      description: "Please try again or contact support.",
    },
    onError: () => {
      onUploadStatusChange?.(false);
    },
  });

  const handleUpload: ChangeEventHandler<HTMLInputElement> = withToast(
    async (event) => {
      const file = event.target.files?.[0];
      if (!file) return;
      onUploadStatusChange?.(true);
      const { data } = await upload({ file, documentType });
      if (!data) throw new Error("couldn't upload document");
      onUploadStatusChange?.(false);
      onUpload?.(data.uploadDocument);
    }
  );

  return (
    <label className="upload-document-button" style={sx(_sx)}>
      <input
        type="file"
        onChange={handleUpload}
        style={{ opacity: 0, position: "absolute" }}
        accept={
          typeof acceptTypes === "string" ? acceptTypes : acceptTypes.join(",")
        }
      />
      {renderUploadButton({
        loading,
        onPress: () => {},
        ...rest,
      })}
    </label>
  );
};
