import { Feather } from "@expo/vector-icons";
import { H1 } from "dripsy";
import { ReactElement } from "react";
import { ButtonLink } from "app/design/button";
import { Text } from "app/components/text";
import { pagesPath } from "app/lib/$path";
import { brandColors } from "app/provider/dripsy/colors";
import { FCC } from "app/types";

interface PageErrorProps {
  title: string;
  cta?: ReactElement;
}

const PageError: FCC<PageErrorProps> = ({
  children,
  title,
  cta = (
    <ButtonLink
      containerSx={{ mt: "$4" }}
      variant="link"
      href={pagesPath.$url()}
    >
      Back home
    </ButtonLink>
  ),
}) => (
  <>
    <Feather name="alert-circle" color={brandColors.black} size={48} />
    <H1 sx={{ mt: "$2", textAlign: "center" }}>{title}</H1>
    <Text variant="lg" sx={{ textAlign: "center" }}>
      {children}
    </Text>
    {cta}
  </>
);

export { PageError };
