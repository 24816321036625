import React, { ComponentProps, useEffect, useState } from "react";
import { styled, SxProp, useDripsyTheme, useSx, View } from "dripsy";
import { AnimatePresence } from "moti";
import {
  Control,
  FieldValues,
  Path,
  useFormState,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { Platform, StyleSheet } from "react-native";
import { useRouter } from "solito/router";

import { useParam } from "app/hooks/use-param";
import { guarantee } from "app/types";

import { Background } from "app/features/layout/background";
import { KeyboardAwareScrollView } from "../keyboard-aware-scroll-view";
import { Footer } from "./footer";
import { Header } from "./header";
import { StepContainer } from "./container";

type RoutePath<TQuery = undefined> = (url?: { hash?: string }) => {
  pathname: string;
  query?: TQuery;
  hash: string | undefined;
};

interface UseStepNaviationInput {
  initialStep: string;
  steps: string[];
  path: {
    _step: (step: string | number) => {
      $url: RoutePath<{ step: string | number }>;
    };
  };
  exitPath: {
    $url: RoutePath;
  };
  onSubmit: () => void;
}

const useStepNavigation = ({
  initialStep,
  steps,
  path,
  exitPath,
  onSubmit,
}: UseStepNaviationInput) => {
  const router = useRouter();
  const [navDirection, setNavDirection] = useState<"left" | "right">("right");

  const [step, setStep] = useParam("step", {
    initial: initialStep,
  });

  const stepIndex = steps.indexOf(step);
  const prevStep = steps?.[stepIndex - 1];
  const nextStep = steps?.[stepIndex + 1];
  const isLastStep = !nextStep;
  const stepProgress = stepIndex / steps.length;

  const navigateTo = (_step: string) =>
    Platform.OS === "web"
      ? router.push(path._step(_step).$url())
      : setStep(_step);

  const onBack = () => {
    setNavDirection("left");
    if (!prevStep) {
      router.push(exitPath.$url());
      return;
    }
    navigateTo(prevStep);
  };

  const onNext = async () => {
    setNavDirection("right");
    if (!nextStep) return onSubmit();
    navigateTo(nextStep);
  };

  return {
    onBack,
    onNext,
    stepIndex,
    prevStep,
    nextStep,
    isLastStep,
    step,
    steps,
    stepProgress,
    navDirection,
  };
};

interface UseStepFormState<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  trigger: UseFormTrigger<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  currentChild: React.ReactElement<StepComponentProps<TFieldValues>>;
}

const useStepFormState = <TFieldValues extends FieldValues>({
  control,
  trigger,
  watch,
  currentChild,
}: UseStepFormState<TFieldValues>) => {
  // Prompt dev if parent form using unsupported reValidateMode (will break next button validation)
  const reValidateMode = control._options.reValidateMode;
  if (reValidateMode && reValidateMode !== "onChange") {
    console.error("StepScreenForm: reValidateMode must be set to 'onChange'");
  }

  const { errors, isSubmitting } = useFormState({
    control,
  });

  const currentFieldNameArray = guarantee(
    (currentChild.props.name && [currentChild.props.name]) ||
      (currentChild.props.names && Object.values(currentChild.props.names)),
    "Form names must exist in step-screen-form"
  );

  useEffect(() => {
    trigger(currentFieldNameArray);
  }, [currentChild.props.stepPath]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      // Destructured { value } in watch 2nd param does not exist when watching all fields
      // Get current value from `values` instead.

      // @ts-expect-error TODO: use getValue from react-hook-form to handle dot-path case, but this will work for simple field name indexing
      const value = values[name];

      const isFieldWithErrorChanged =
        name && currentFieldNameArray.includes(name) && name in errors;

      const isFieldUnset = !value || (Array.isArray(value) && !value.length);

      if (isFieldWithErrorChanged || isFieldUnset) {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, errors, currentFieldNameArray]);

  const isStepValid = currentFieldNameArray?.reduce(
    (prevValidValue, fieldName) => {
      if (!(fieldName in control._defaultValues)) return prevValidValue && true;
      return prevValidValue && !errors[fieldName];
    },
    true
  );

  return {
    triggerStep: async (config?: Parameters<typeof trigger>[1]) =>
      trigger(currentFieldNameArray, config),
    isStepValid,
    isSubmitting,
  };
};

export interface StepComponentProps<TFieldValues extends FieldValues> {
  stepPath: string;
  control: Control<TFieldValues>;
  name?: Path<TFieldValues>;
  names?: Record<any, Path<TFieldValues>>;
}

export interface StepScreenBag {
  stepProgress: number;
}

export interface StepScreenFormContainerProps
  extends ComponentProps<typeof View> {
  sx?: SxProp;
}

export const StepScreenFormContainer = ({
  sx,
  ...props
}: StepScreenFormContainerProps) => (
  <Background
    sx={{
      flex: 1,
      ...sx,
    }}
    style={StyleSheet.absoluteFill}
    {...props}
  />
);

const FOOTER_HEIGHT_OFFSET = 100;

export interface StepScreenProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  trigger: UseFormTrigger<TFieldValues>;
  children: (React.ReactElement<StepComponentProps<TFieldValues>> | false)[];
  onSubmit: () => void;
  overrideNext?: () => void;
  submitting?: boolean;
  path: {
    _step: (step: string | number) => {
      $url: RoutePath<{ step: string | number }>;
    };
  };
  exitPath: {
    $url: RoutePath;
  };
  HeaderComponent?: (bag: StepScreenBag) => React.ReactNode;
}

export const StepScreenForm = <TFieldValues extends FieldValues>({
  watch,
  control,
  trigger,
  path,
  exitPath,
  onSubmit,
  overrideNext,
  submitting,
  children,
  HeaderComponent = (props) => <Header {...props} />,
}: StepScreenProps<TFieldValues>) => {
  const sx = useSx();

  const childArray = React.Children.toArray(children) as React.ReactElement<
    StepComponentProps<TFieldValues>
  >[];

  const stepPaths = childArray.map((child) => child.props.stepPath);

  // const initialStepPath = guarantee(
  //   stepPaths[0],
  //   "Children are required in StepScreenForm"
  // );

  const initialStepPath = stepPaths[0];

  const {
    isLastStep,
    onBack,
    onNext,
    stepIndex,
    step,
    stepProgress,
    navDirection,
  } = useStepNavigation({
    initialStep: initialStepPath,
    steps: stepPaths,
    onSubmit,
    path,
    exitPath,
  });

  const currentChild = childArray[stepIndex];

  const { isStepValid, isSubmitting, triggerStep } = useStepFormState({
    control,
    trigger,
    currentChild,
    watch,
  });

  const handleSubmitStep = async () => {
    if (overrideNext) {
      overrideNext();
      return;
    }

    const res = await triggerStep({ shouldFocus: true });
    if (res) {
      onNext();
    }
  };

  const stepBag = { stepProgress };

  return (
    <StepScreenFormContainer>
      {HeaderComponent(stepBag)}
      <View sx={{ flex: 1 }}>
        <KeyboardAwareScrollView
          contentContainerStyle={sx({
            px: "$4",
            pt: ["$7", "$9"],
            pb: ["$7", "$9"],
          })}
          extraHeight={FOOTER_HEIGHT_OFFSET}
        >
          <AnimatePresence exitBeforeEnter>
            {childArray.map((child) =>
              step === child.props.stepPath ? (
                <StepContainer
                  key={child.props.stepPath}
                  navDirection={navDirection}
                >
                  {child}
                </StepContainer>
              ) : null
            )}
          </AnimatePresence>
        </KeyboardAwareScrollView>
      </View>
      <Footer
        disabled={!isStepValid}
        submitting={submitting || isSubmitting}
        onBack={onBack}
        onNext={handleSubmitStep}
      >
        {isLastStep ? "Finish" : "Next"}
      </Footer>
    </StepScreenFormContainer>
  );
};
