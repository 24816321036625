import { SxProp, View, H3, styled } from "dripsy";
import React, { ReactElement } from "react";
import { FCC } from "app/types/index";
import { Avatar, AvatarProps } from "./avatar";

const PlusAvatarsText = styled(H3)();

const calculateTextSize = (avatarSize: number, plusCount: number) => {
  const plusCountDigits = plusCount.toString().length;
  const baseTextToSizeRatio = 1.5;
  const digitTextToSizeRatio = 1 - plusCountDigits * 0.1;
  return avatarSize * baseTextToSizeRatio * digitTextToSizeRatio;
};

const splitAvatarChildren = (
  children: JSX.Element[] | JSX.Element,
  max?: number
) => {
  let childrenArray = React.Children.toArray(children) as ReactElement[];
  let plusCount: number = 0;

  if (!!max && max < childrenArray.length && max > 0) {
    plusCount = childrenArray.length - max;
    childrenArray = childrenArray.slice(0, max);
  }

  return {
    plusCount,
    firstChild: childrenArray[0]!,
    trailingChildren: childrenArray.slice(1),
    count: childrenArray.length,
  };
};

interface GetAvatarGroupChildren {
  children: JSX.Element[] | JSX.Element;
  space?: number;
  max?: number;
  /** Add padding so width will match max elements */
  widthToMax?: boolean;
  hiddenAvatarPlaceholderProps?: AvatarProps;
  avatarProps?: AvatarProps;
  reverseStack?: boolean;
}

const getAvatarGroupChildren = ({
  children,
  space = 0,
  widthToMax,
  max,
  hiddenAvatarPlaceholderProps,
  avatarProps,
  reverseStack,
}: GetAvatarGroupChildren) => {
  const { firstChild, plusCount, trailingChildren, count } =
    splitAvatarChildren(children, max);

  const spacingProps = {
    ml: space,
  };

  const borderProps = {
    borderWidth: 1,
    borderColor: "$white",
  };

  const additionalPaddingToMaxProps =
    !max || !widthToMax
      ? {}
      : {
          mr: space * (max - count) * -1,
        };

  const first = React.cloneElement(
    firstChild,
    {
      ...avatarProps,
      sx: {
        ...(avatarProps?.sx || {}),
        ...(reverseStack ? {} : borderProps),
        ...additionalPaddingToMaxProps,
      },
      ...firstChild.props,
    },
    firstChild.props.children
  );

  const plusAvatar =
    plusCount > 0 ? (
      <Avatar
        sx={{
          ...(avatarProps?.sx || {}),
          ...(hiddenAvatarPlaceholderProps?.sx || {}),
          ...(reverseStack ? borderProps : {}),
          ...spacingProps,
        }}
        {...avatarProps}
        {...hiddenAvatarPlaceholderProps}
      >
        <PlusAvatarsText
          sx={{
            fontSize: avatarProps?.size
              ? calculateTextSize(avatarProps?.size, plusCount)
              : 24,
          }}
        >
          {`+ ${plusCount}`}
        </PlusAvatarsText>
      </Avatar>
    ) : null;

  const rest = React.Children.map(
    trailingChildren.reverse(),
    (child: any, index: number) => {
      return React.cloneElement(
        child,
        {
          key: `avatar-group-child-${index}`,
          ...avatarProps,
          isActive: false,
          sx: {
            ...(avatarProps?.sx || {}),
            ...spacingProps,
            ...borderProps,
          },
          ...child.props,
        },
        child.props.children
      );
    }
  );

  return reverseStack ? [first, rest, plusAvatar] : [plusAvatar, rest, first];
};

interface AvatarGroupProps extends GetAvatarGroupChildren {
  sx?: SxProp;
}

const AvatarGroup: FCC<AvatarGroupProps> = ({ sx, ...avatarGroupProps }) => {
  return (
    <View
      sx={{
        flexDirection: avatarGroupProps.reverseStack ? "row" : "row-reverse",
        ...sx,
      }}
    >
      {getAvatarGroupChildren(avatarGroupProps)}
    </View>
  );
};

interface AvatarStackProps extends Omit<AvatarGroupProps, "space"> {
  // 0.25 = quarter of avatar size, 0.5 = half, etc.
  overlapRatio?: number;
}

const AvatarStack: FCC<AvatarStackProps> = ({
  overlapRatio = 0.25,
  avatarProps,
  ...avatarGroupProps
}) => {
  const avatarSize = avatarProps?.size || 16;
  const rootSpace = -4 * avatarSize;
  const spaceIncrement = avatarSize * 4 * overlapRatio;

  return (
    <AvatarGroup
      space={rootSpace + spaceIncrement}
      avatarProps={{ ...avatarProps, size: avatarSize }}
      {...avatarGroupProps}
    />
  );
};

export type { AvatarGroupProps, AvatarStackProps };
export { AvatarGroup, AvatarStack };
