import { ReactElement, useEffect, useState } from "react";
import { useDripsyTheme } from "dripsy";
import { Feather } from "@expo/vector-icons";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { CountryCode } from "react-native-country-picker-modal";
import { getCountryInfoAsync } from "react-native-country-picker-modal/lib/CountryService";
import { useScreenSize } from "app/hooks/use-screen-size";
import { Button } from "app/design/button";
import { FormControlProps } from "./form-control";
import {
  CountrySelectMenu,
  CountrySelectModal,
  Placement,
} from "../country-select";

export interface UseFlagButtonProps {
  countryCode?: CountryCode;
}

export interface FlagButtonProps extends UseFlagButtonProps {
  onOpen?: () => void;
  controllerProps: UseControllerProps;
}

export const useFlagButton = ({ countryCode }: UseFlagButtonProps) => {
  const initialState = {
    countryName: "",
    currency: "",
    callingCode: "",
  };

  const [{ countryName }, setState] = useState(initialState);

  useEffect(() => {
    if (countryCode) {
      getCountryInfoAsync({ countryCode }).then(setState).catch(console.warn);
      return;
    }
    setState(initialState);
  }, [countryCode]);

  return countryName;
};

const FlagButton = ({ countryCode, onOpen }: FlagButtonProps) => {
  const { theme } = useDripsyTheme();
  const countryName = useFlagButton({ countryCode });

  return (
    <Button
      variant="ghost"
      onPress={onOpen}
      sx={{ alignSelf: "flex-start", px: "$0" }}
      icon={
        <Feather name="chevron-down" color={theme.colors.$black} size={24} />
      }
      iconPosition="right"
    >
      {countryName ? countryName : "Select country"}
    </Button>
  );
};

export interface CountryControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  menuProps?: {
    placement?: Placement;
    offsetTop?: number;
    FlagButtonComponent?: (props: FlagButtonProps) => ReactElement;
  };
}

export const CountryControl = <T extends FieldValues>({
  menuProps = {},
  ...props
}: CountryControlProps<T>) => {
  const { FlagButtonComponent = FlagButton } = menuProps;
  const { isMobile } = useScreenSize();
  const {
    field: { onChange, value },
  } = useController(props);

  return isMobile ? (
    <CountrySelectModal
      value={value}
      onChange={onChange}
      renderFlagButton={(flagProps: FlagButtonProps) => (
        <FlagButtonComponent {...flagProps} controllerProps={props} />
      )}
    />
  ) : (
    <CountrySelectMenu
      value={value}
      onChange={onChange}
      placement={menuProps?.placement}
      offsetTop={menuProps?.offsetTop}
      trigger={(triggerProps) => (
        <FlagButtonComponent
          countryCode={triggerProps.value}
          onOpen={triggerProps.onPress}
          controllerProps={props}
        />
      )}
    />
  );
};

CountryControl.displayName = "CountryControl";
