import { View } from "dripsy";
import { gql } from "@apollo/client";
import {
  CandidateV2,
  CoreDocumentsFieldsFragmentDoc,
  DocumentType,
  ProfileCompletion,
  useEducationExperienceSectionQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { Alert } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { ProfileCardEmpty } from "../../card/empty";
import { useProfileEntryList } from "../hooks/use-profile-entry-list";
import {
  getProfileSectionStatus,
  sortProfileFieldsByStartDate,
} from "../utils";
import { ProfileDocumentCard } from "../../document-card";
import { ProfileSection } from "../../section";
import {
  EducationExperienceFormCard,
  EducationExperienceFormValues,
} from "./form-card";
import { EducationExperienceCard } from "./card";
import { ImportEducationHistoryFromCVButton } from "./import-from-cv";
import { EducationExperienceLoading } from "./loading";

export const EDUCATION_EXPERIENCE_FORM_QUERY = gql`
  ${CoreDocumentsFieldsFragmentDoc}
  query EducationExperienceSection {
    me {
      id
      ... on CandidateV2 {
        coreDocuments {
          qualification {
            ...CoreDocumentsFields
          }
        }
        educationExperiences {
          id
          institutionName
          qualification
          grade
          startDate
          endDate
          description {
            content
          }
        }
        profileCompletion
      }
    }
  }
`;

export const EducationExperienceSection = () => {
  const { data, loading, error } = useEducationExperienceSectionQuery();

  const { fields, append, remove, setFields } =
    useProfileEntryList<EducationExperienceFormValues>({
      defaultValues: (data?.me as CandidateV2)?.educationExperiences?.map(
        ({ __typename, ...educationExperience }) => ({
          ...educationExperience,
          description: educationExperience.description.map(
            (desc) => desc.content
          ),
          endDate: educationExperience.endDate || "",
          hideEndDate: !!!educationExperience.endDate,
        })
      ),
    });

  if (loading) return <EducationExperienceLoading />;
  if (error) return <DataError title="Failed to load education history" />;

  const { coreDocuments, educationExperiences, profileCompletion } =
    data?.me as CandidateV2;
  const hasEducation = !!educationExperiences?.length;
  const hasQualification = !!coreDocuments?.qualification.length;

  const status = getProfileSectionStatus({
    hasStarted: hasEducation || hasQualification,
    hasCompleted: profileCompletion.includes(
      ProfileCompletion.HasEducationHistory
    ),
  });

  return (
    <ProfileSection title="Education history" status={status}>
      {hasEducation && !hasQualification ? (
        <Alert sx={{ mb: "$4" }} variant="warning">
          You need to upload a copy of your qualification to complete this
          section.
        </Alert>
      ) : null}

      <ProfileDocumentCard
        typeProps={{
          type: DocumentType.Qualification,
          name: "Qualification",
        }}
        documents={coreDocuments?.qualification}
        emptyDescription="Upload a copy of the qualification that allowed you to register in the country you trained in."
        emptyCTA="Add qualification"
      />

      {fields.length === 0 ? (
        <ProfileCardEmpty description="Include details of your most relevant university-level education.">
          <View sx={{ flexDirection: ["column", "row"], flexWrap: "wrap" }}>
            <ImportEducationHistoryFromCVButton onParseData={setFields} />
            <Button
              variant="secondary"
              onPress={() =>
                append(EducationExperienceFormCard.createInstance())
              }
              containerSx={{ alignSelf: "flex-start", mt: ["$2", "$0"] }}
            >
              Add education
            </Button>
          </View>
        </ProfileCardEmpty>
      ) : null}
      {fields
        .sort((a, b) => sortProfileFieldsByStartDate(a, b))
        .map((field) => (
          <EducationExperienceCard
            key={field.id}
            field={field}
            onCancel={() => remove(field.id)}
          />
        ))}
      {fields.length > 0 ? (
        <Button
          variant="secondary"
          containerSx={{ alignSelf: "center" }}
          onPress={() => append(EducationExperienceFormCard.createInstance())}
        >
          Add more education
        </Button>
      ) : null}
    </ProfileSection>
  );
};
