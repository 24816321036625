import { useEffect } from "react";
import { Row, View } from "dripsy";
import { Button } from "app/design/button";
import { CheckboxControl } from "app/components/form/checkbox-control";
import { InputControl } from "app/components/form/input-control";
import { CountryControl } from "app/components/form/country-control";
import { useYupForm, yup } from "app/utils/yup";
import { useProfileCardContext } from "../../card/context";
import { useRegistrationDetailMutations } from "../hooks/use-registration-detail-mutations";
import { generateTempId, isTempField, ProfileForm } from "../profile-form";
import { ProfileCountrySelect } from "../profile-country-select";

interface RegistrationDetailFormValues {
  id: string;
  country: string;
  referenceNumber?: string | null;
  // meta
  isInProgress: boolean;
}

interface RegistrationDetailFormCardProps {
  defaultValues: RegistrationDetailFormValues;
  onCancel: () => void;
  mode?: "create" | "update";
}

const DEFAULT_VALUE = {
  country: "",
  referenceNumber: "",
  isInProgress: false,
};

const schema = yup.object({
  country: yup.string().required().label("Country"),
  isInProgress: yup.boolean(),
  referenceNumber: yup
    .string()
    .nullable()
    .when("isInProgress", (isInProgress, _schema) =>
      !isInProgress ? _schema.required() : _schema
    )
    .label("Registration number"),
});

const RegistrationDetailFormCard = ({
  onCancel,
  defaultValues,
}: RegistrationDetailFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const { createRegistrationDetail, updateRegistrationDetail } =
    useRegistrationDetailMutations();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = useYupForm<RegistrationDetailFormValues>({
    schema,
    defaultValues,
  });

  const [fieldId, isInProgress] = watch(["id", "isInProgress"]);
  const isUpdating = !isTempField({ id: fieldId });

  useEffect(() => {
    clearErrors("referenceNumber");
    if (isInProgress) setValue("referenceNumber", null);
  }, [isInProgress]);

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };

  const onSubmit = async (values: RegistrationDetailFormValues) => {
    const { isInProgress: _removed, id, ...createInput } = values;

    try {
      if (isUpdating) {
        if (id)
          throw new Error("registration detail: id is required for update");

        await updateRegistrationDetail({
          variables: {
            input: {
              ...createInput,
              // appease ts
              id,
              referenceNumber: isInProgress
                ? null
                : createInput.referenceNumber,
            },
          },
        });
      } else {
        await createRegistrationDetail({
          variables: {
            input: {
              ...createInput,
              referenceNumber: isInProgress
                ? null
                : createInput.referenceNumber,
            },
          },
        });
      }

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ProfileForm label="registration detail" onCancel={onClose}>
      <View sx={{ maxWidth: [null, 420] }}>
        <CountryControl
          control={control}
          name="country"
          menuProps={{
            offsetTop: 0,
            FlagButtonComponent: ProfileCountrySelect,
          }}
        />

        <View sx={{ mb: "$3" }}>
          <CheckboxControl
            control={control}
            name="isInProgress"
            checkboxProps={{
              "aria-label": "Is registration in progress",
              label: "My registration is in progress",
            }}
          />
        </View>

        <InputControl
          control={control}
          name="referenceNumber"
          inputProps={{
            disabled: isInProgress,
            label: "Registration number",
            placeholder: "Enter registration number",
          }}
        />
      </View>

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

RegistrationDetailFormCard.createInstance = () => ({
  id: generateTempId("registration-detail"),
  ...DEFAULT_VALUE,
});

export type { RegistrationDetailFormValues, RegistrationDetailFormCardProps };
export { RegistrationDetailFormCard };
