import { useEffect, useRef } from "react";
import { Client, Expr } from "faunadb";
import { captureMessage } from "@sentry/nextjs";
import { Scalars } from "app/types/generated/schema";
import { serializeRef } from "app/utils/fauna";
import { useAuth } from "app/use-auth";
import { CHANNEL } from "app/client";

const SHOULD_USE_STREAMING = true; // CHANNEL !== "dev";

type StreamEventSet = {
  document: {
    ref: {
      id: string;
    };
  };
};

const useStreaming = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  streamConfig: { setRef: Expr },
  onNewDocument: (newDocumentId: Scalars["MigrateID"]) => void,
  onReconnect: () => void
) => {
  const { isAuthenticated, getAccessToken } = useAuth();
  const streamRef = useRef<ReturnType<Client["stream"]> | null>(null);
  const initialised = useRef(false);

  const closeStream = () => {
    if (streamRef?.current) streamRef.current.close();
    initialised.current = false;
  };

  const restartStream = () => {
    closeStream();
    run();
  };

  const onError = (e: any) => {
    if (e.code === "permission denied") {
      restartStream();
      onReconnect();
    } else {
      // TODO: figure out why this is sometimes Unauthorized / handle re-establishing stream...
      console.log("Error:", e);
    }
  };

  const run = async () => {
    initialised.current = true;

    const secret = await getAccessToken();

    if (!secret) {
      const errorMessage = "Unable to get access token, stream not started";
      console.error(errorMessage);
      captureMessage(errorMessage);
      return;
    }

    const client = new Client({
      secret,
      domain: "db.eu.fauna.com",
      scheme: "https",
      headers: {
        "Content-Type": "application/json",
      },
    });

    streamRef.current = client
      .stream(streamConfig.setRef)
      .on("start", (start) => {
        console.log("starting stream at", start);
      })
      .on("set", (set) => {
        const typedSet = set as unknown as StreamEventSet;
        onNewDocument(serializeRef(typedSet.document.ref));
      })
      .on("error", onError)
      .start();
  };

  useEffect(() => {
    if (!initialised.current && isAuthenticated && SHOULD_USE_STREAMING) {
      run();
    }

    return () => {
      closeStream();
    };
  }, [isAuthenticated]);
};
export { useStreaming };
