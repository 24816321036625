const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const YEARS = (() => {
  const years: string[] = [];
  for (
    let i = new Date().getFullYear();
    i > new Date().getFullYear() - 50;
    i--
  ) {
    years.push(i.toString());
  }
  return years;
})();

export { MONTHS, YEARS };
