import { View } from "dripsy";
import { Button, ButtonProps } from "app/design/button";
import { FCC } from "app/types";

export interface FloatyButtonProps extends ButtonProps {
  bottom: number[];
  right: number[];
}

export const BaseFloatyButton: FCC<FloatyButtonProps> = ({
  bottom,
  right,
  children,
  ...buttonProps
}) => (
  <View
    sx={{
      position: "absolute",
      bottom,
      right,
      zIndex: 1,
    }}
  >
    <Button {...buttonProps}>{children}</Button>
  </View>
);

export const FloatyButton: FCC<ButtonProps> = ({ sx, ...props }) => (
  <BaseFloatyButton
    bottom={[24, 64]}
    right={[16, 48]}
    sx={{ borderRadius: 24, px: "$4", ...sx }}
    {...props}
  />
);
