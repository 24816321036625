import { H5, View } from "dripsy";
import { Skeleton } from "native-base";
import { Card } from "app/design/card";
import { FakeCompletionCheck } from "app/features/profile/completion/loading";

export const ApplicationProgressLoading = () => {
  return (
    <Card sx={{ mb: ["$5", "$5", "$5", "$0"] }}>
      <View
        sx={{
          alignItems: "center",
        }}
      >
        <H5 sx={{ color: "$text", textAlign: "center", mb: "$2" }}>
          Applying for
        </H5>
        <Skeleton.Text
          lines={1}
          w={250}
          _line={{
            height: 4,
          }}
          mb={2}
        />

        {[
          "Application questions",
          "Upload passport",
          "Work experience",
          "Education history",
          "Registration details",
          "Language qualifications",
        ].map((label) => (
          <FakeCompletionCheck key={label}>{label}</FakeCompletionCheck>
        ))}
      </View>
    </Card>
  );
};
