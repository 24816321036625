/* eslint-disable */
// prettier-ignore
const OVERWRITE_PREFIX_CONFIG = {
  inbox: "channels",
};

// prettier-ignore
const constructId = (key: string, numericId: string, pathname: string) => {
  const pathSegments = pathname.split("/");

  const isWebIdParam = (s: string) => s === `[${key}]`;
  const isNativeIdParam = (s: string) => s === numericId;

  const idPrefix =
    pathSegments[
      pathSegments.findIndex((s) => isWebIdParam(s) || isNativeIdParam(s)) - 1
    ];

  // failed to construct id - react navigation may be out of sync with the route
  if (!idPrefix) return null;
  const sanitizedIdPrefix =
    OVERWRITE_PREFIX_CONFIG[idPrefix as keyof typeof OVERWRITE_PREFIX_CONFIG] ||
    idPrefix;
  return `${sanitizedIdPrefix}-${numericId}`;
};

// prettier-ignore
const migrateIdRegex = /[a-z|0-9|;@+$\\\-_.!~%\w]+-\d{18}/;

// prettier-ignore
const transformId = (input: string) => {
  if (!migrateIdRegex.test(input)) return input;
  const [_prefix, numericId] = input.split("-");
  if (!numericId) throw new Error("Could not transform id");
  return numericId;
};

// prettier-ignore
export { constructId, transformId, OVERWRITE_PREFIX_CONFIG };


// prettier-ignore
export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  account_settings: {
    $url: (url?: { hash?: string }) => ({ pathname: '/account-settings' as const, hash: url?.hash })
  },
  career_quiz: {
    _step: (step: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/career-quiz/[step]' as const, query: { step }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/career-quiz' as const, hash: url?.hash })
  },
  community: {
    activity: {
      $url: (url?: { hash?: string }) => ({ pathname: '/community/activity' as const, hash: url?.hash })
    },
    channels: {
      _channelId: (channelId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/community/channels/[channelId]' as const, query: { channelId: transformId(channelId as string) }, hash: url?.hash })
      }),
      all: {
        $url: (url?: { hash?: string }) => ({ pathname: '/community/channels/all' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/community/channels' as const, hash: url?.hash })
    },
    questions: {
      _questionId: (questionId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/community/questions/[questionId]' as const, query: { questionId: transformId(questionId as string) }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/community/questions' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/community' as const, hash: url?.hash })
  },
  forgot_password: {
    $url: (url?: { hash?: string }) => ({ pathname: '/forgot-password' as const, hash: url?.hash })
  },
  inbox: {
    _channelId: (channelId: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/inbox/[channelId]' as const, query: { channelId: transformId(channelId as string) }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/inbox' as const, hash: url?.hash })
  },
  job_preferences: {
    _step: (step: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/job-preferences/[step]' as const, query: { step }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/job-preferences' as const, hash: url?.hash })
  },
  jobs: {
    _jobId: (jobId: string | number) => ({
      apply: {
        $url: (url?: { hash?: string }) => ({ pathname: '/jobs/[jobId]/apply' as const, query: { jobId: transformId(jobId as string) }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/jobs/[jobId]' as const, query: { jobId: transformId(jobId as string) }, hash: url?.hash })
    }),
    matches: {
      $url: (url?: { hash?: string }) => ({ pathname: '/jobs/matches' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/jobs' as const, hash: url?.hash })
  },
  login: {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  my_documents: {
    $url: (url?: { hash?: string }) => ({ pathname: '/my-documents' as const, hash: url?.hash })
  },
  notifications: {
    $url: (url?: { hash?: string }) => ({ pathname: '/notifications' as const, hash: url?.hash })
  },
  profile: {
    $url: (url?: { hash?: string }) => ({ pathname: '/profile' as const, hash: url?.hash })
  },
  register: {
    _step: (step: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/register/[step]' as const, query: { step }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/register' as const, hash: url?.hash })
  },
  welcome: {
    $url: (url?: { hash?: string }) => ({ pathname: '/welcome' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath
