import { UrlObject } from "url";
import { Row, styled, Sx, useDripsyTheme, View } from "dripsy";
import { MotiView } from "moti";
import { Skeleton } from "native-base";
import { gql } from "@apollo/client";
import { Platform } from "react-native";

import {
  CommunityPublicChannelListCardPropsFragment,
  BaseChannelListCardPropsFragment,
  OrganisationPrivateChannelListCardPropsFragment,
  ApplicationDmChannelListCardPropsFragment,
  CommunityChannelCardHeaderMembersFragmentFragment,
  Scalars,
} from "app/types/generated/schema";
import {
  Avatar,
  AvatarLoading,
  AvatarStack,
  GroupAvatar,
  JobAvatar,
} from "app/design/avatar";
import { alphaColor } from "app/design/utils";
import { PressableCard } from "app/components/pressable-card";
import { Text } from "app/components/text";
import { pagesPath } from "app/lib/$path";
import { formatDateConcise } from "app/utils";
import { getMessageContentSummary } from "../../utils/message";
import { CommunityChannelCardHeader } from "../community-channel-card-header";

const DripsyMotiView = styled(MotiView)();

const BASE_CHANNEL_LIST_CARD_PROPS_FRAGMENT = gql`
  fragment BaseChannelListCardProps on Channel {
    id
    lastMessage {
      id
      createdAt
      content
      attachment {
        id
      }
      author {
        id
        displayName
      }
    }
    createdAt
    unread
    type
  }
`;

interface ChannelListCardProps {
  avatar: React.ReactElement;
  channel: BaseChannelListCardPropsFragment;
  title: string;
  href?: UrlObject;
  selectedChannelId?: Scalars["MigrateID"];
}

const BaseChannelListCard: React.FC<ChannelListCardProps> = ({
  avatar = <Avatar />,
  title,
  channel: { id: channelId, lastMessage, unread: _unread, createdAt },
  selectedChannelId,
  href,
}) => {
  const { theme } = useDripsyTheme();

  const FONT_STYLES = { fontSize: 13, lineHeight: 20 };

  // TODO: Some channels are unread but have blank lastMessages? Weird
  const unread = _unread && lastMessage?.content;
  const content = lastMessage
    ? `${
        lastMessage?.author?.displayName || "Unknown"
      }: ${getMessageContentSummary(lastMessage)}`
    : "No messages yet";

  const active = channelId === selectedChannelId;

  return (
    <PressableCard
      href={href || pagesPath.inbox._channelId(channelId).$url()}
      sx={{
        flexDirection: "row",
        borderRadius: 0,
        p: 0,
        px: "$4",
        py: "$4",
        borderWidth: 0,
        boxShadow: "none",
        backgroundColor: active
          ? alphaColor(theme.colors.$blue, 0.1)
          : alphaColor(theme.colors.$blue, 0),
        ...(Platform.OS === "web" && { backdropFilter: "none" }),
      }}
      containerSx={{ mb: 0 }}
      initialColor={alphaColor(theme.colors.$blue, 0)}
      activeColor={alphaColor(theme.colors.$blue, 0.05)}
      additionalBackgroundColorStates={[
        [active, alphaColor(theme.colors.$blue, 0.1)],
      ]}
      accentProps={{
        activeOverride: active,
        containerSx: {
          top: 0,
          left: -1,
          bottom: 0,
          clipPath: null,
          borderRadius: 0,
        },
      }}
    >
      <View
        sx={{
          position: "absolute",
          left: "$4",
          right: "$4",
          borderBottomWidth: 1,
          borderColor: "$ui",
          bottom: 0,
        }}
      />
      <DripsyMotiView
        sx={{
          position: "absolute",
          top: 36,
          left: [6, "$2"],
          width: 6,
          height: 6,
          ml: -3,
          mt: -3,
          borderRadius: 6,
          backgroundColor: "$blue",
        }}
        animate={{ opacity: unread ? 1 : 0 }}
      />
      <View sx={{ mr: "$2" }}>{avatar}</View>
      <View sx={{ flex: 1 }}>
        <Row sx={{ justifyContent: "space-between" }}>
          <Text
            sx={{
              flexShrink: 1,
              fontWeight: unread ? "600" : "normal",
              color: "$black",
              ...FONT_STYLES,
            }}
            numberOfLines={1}
          >
            {title}
          </Text>
          <Text
            sx={{
              ml: "$2",
              textAlign: "right",
              flexShrink: 0,
              color: unread ? "$black" : "$text",
              fontWeight: unread ? "500" : "normal",
              ...FONT_STYLES,
            }}
          >
            {formatDateConcise(lastMessage?.createdAt || createdAt)}
          </Text>
        </Row>
        <Text
          sx={{
            color: unread ? "$black" : "$text",
            fontWeight: unread ? "500" : "normal",
            fontStyle: !lastMessage ? "italic" : "normal",
            ...FONT_STYLES,
          }}
          numberOfLines={1}
        >
          {content}
        </Text>
      </View>
    </PressableCard>
  );
};

const ChannelListCardLoading: React.FC = () => {
  return (
    <Row
      sx={{
        mx: "$4",
        py: "$4",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: "$ui",
      }}
    >
      <AvatarLoading size={10} />
      <View sx={{ ml: "$2", flex: 1 }}>
        <Skeleton.Text lines={2} _line={{ height: 2 }} />
      </View>
    </Row>
  );
};

const APPLICATION_DM_CHANNEL_LIST_CARD_PROPS_FRAGMENT = gql`
  fragment ApplicationDMChannelListCardProps on ApplicationDMChannel {
    ...BaseChannelListCardProps
    application {
      job {
        id
        title
        organisationName
        organisation {
          id
          name
        }
      }
    }
  }
  ${BASE_CHANNEL_LIST_CARD_PROPS_FRAGMENT}
`;

interface ApplicationChannelCardProps {
  channel: ApplicationDmChannelListCardPropsFragment;
  selectedChannelId?: Scalars["MigrateID"];
}

const ApplicationChannelCardComponent = ({
  channel,
  selectedChannelId,
}: ApplicationChannelCardProps) => {
  return (
    <BaseChannelListCard
      avatar={<JobAvatar />}
      title={channel.application.job.organisationName}
      channel={channel}
      selectedChannelId={selectedChannelId}
    />
  );
};

ApplicationChannelCardComponent.fragments = {
  applicationDMChannel: APPLICATION_DM_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
};

const ApplicationChannelCard = ApplicationChannelCardComponent;

const ORGANISATION_PRIVATE_CHANNEL_LIST_CARD_PROPS_FRAGMENT = gql`
  fragment OrganisationPrivateChannelListCardProps on OrganisationPrivateChannel {
    ...BaseChannelListCardProps
    name
  }
  ${BASE_CHANNEL_LIST_CARD_PROPS_FRAGMENT}
`;

interface OrganisationChannelCardProps {
  channel: OrganisationPrivateChannelListCardPropsFragment;
  selectedChannelId?: Scalars["MigrateID"];
}

const OrganisationChannelCardComponent = ({
  channel,
  selectedChannelId,
}: OrganisationChannelCardProps) => {
  return (
    <BaseChannelListCard
      avatar={<GroupAvatar />}
      title={channel.name}
      channel={channel}
      selectedChannelId={selectedChannelId}
    />
  );
};

OrganisationChannelCardComponent.fragments = {
  organisationPrivateChannel:
    ORGANISATION_PRIVATE_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
};

const OrganisationChannelCard = OrganisationChannelCardComponent;

const COMMUNITY_PUBLIC_CHANNEL_LIST_CARD_PROPS_FRAGMENT = gql`
  fragment CommunityPublicChannelListCardProps on CommunityPublicChannel {
    ...BaseChannelListCardProps
    name
    members(_size: 2) {
      ...CommunityChannelCardHeaderMembersFragment
    }
  }
  ${BASE_CHANNEL_LIST_CARD_PROPS_FRAGMENT}
  ${CommunityChannelCardHeader.fragments.members}
`;

interface CommunityAvatarStackProps {
  members: CommunityChannelCardHeaderMembersFragmentFragment["data"][number][];
  sx?: Sx;
}

const CommunityAvatarStack = ({
  members,
  sx = {},
}: CommunityAvatarStackProps) => {
  return (
    <AvatarStack
      avatarProps={{
        size: 10,
      }}
      overlapRatio={0.25}
      max={2}
      sx={sx}
    >
      {members.length === 0 ? (
        <GroupAvatar />
      ) : (
        members.map((author) => (
          <Avatar
            key={author.id}
            userId={author.id}
            photoUrl={author.photoUrl}
          />
        ))
      )}
    </AvatarStack>
  );
};

interface CommunityChannelCardProps {
  channel: CommunityPublicChannelListCardPropsFragment;
  selectedChannelId?: Scalars["MigrateID"];
}

const CommunityChannelCardComponent = ({
  channel,
  selectedChannelId,
}: CommunityChannelCardProps) => (
  <BaseChannelListCard
    avatar={<CommunityAvatarStack members={channel.members.data} />}
    title={`#${channel.name}`}
    channel={channel}
    href={pagesPath.community.channels._channelId(channel.id).$url()}
    selectedChannelId={selectedChannelId}
  />
);

CommunityChannelCardComponent.fragments = {
  communityPublicChannel: COMMUNITY_PUBLIC_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
};

const CommunityChannelCard = CommunityChannelCardComponent;

export {
  CommunityChannelCard,
  CommunityChannelCardComponent,
  OrganisationChannelCard,
  ApplicationChannelCard,
  ChannelListCardLoading,
  BASE_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
  APPLICATION_DM_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
  COMMUNITY_PUBLIC_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
  ORGANISATION_PRIVATE_CHANNEL_LIST_CARD_PROPS_FRAGMENT,
};
