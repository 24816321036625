import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ProtectFeature } from "app/components/protect-feature";

interface ChildrenProp {
  children: ReactElement;
}

const AskQuestion = ({ children }: ChildrenProp) => {
  const { t } = useTranslation("copy", { keyPrefix: "community.question" });
  return <ProtectFeature label={t("verifyToAsk")}>{children}</ProtectFeature>;
};

const AnswerQuestion = ({ children }: ChildrenProp) => {
  const { t } = useTranslation("copy", { keyPrefix: "community.question" });
  return (
    <ProtectFeature label={t("verifyToAnswer")}>{children}</ProtectFeature>
  );
};

const Vote = ({ children }: ChildrenProp) => {
  const { t } = useTranslation("copy", { keyPrefix: "community.question" });
  return <ProtectFeature label={t("verifyToVote")}>{children}</ProtectFeature>;
};

const JoinChannels = ({ children }: ChildrenProp) => {
  const { t } = useTranslation("copy", { keyPrefix: "community.channel" });
  return <ProtectFeature label={t("verifyToJoin")}>{children}</ProtectFeature>;
};

export const ProtectCommunityFeature = {
  AskQuestion,
  AnswerQuestion,
  Vote,
  JoinChannels,
};
