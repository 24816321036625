import { gql } from "@apollo/client";
import { captureException } from "app/client/error";
import { NotificationsQuery, useNotificationsQuery } from "app/types";
import { ApplicationRejectedCard } from "../notification-card/application-rejected";
import { CanReapplyCard } from "../notification-card/can-reapply";
import { InterviewInviteCard } from "../notification-card/interview-invite";
import { InterviewUpdatedCard } from "../notification-card/interview-updated";
import { QuestionAnsweredCard } from "../notification-card/question-answered";

const NOTIFICATION_FIELDS_FRAGMENT = gql`
  ${ApplicationRejectedCard.fragment}
  ${CanReapplyCard.fragment}
  ${QuestionAnsweredCard.fragment}
  ${InterviewInviteCard.fragment}
  ${InterviewUpdatedCard.fragment}
  fragment NotificationFields on Notification {
    id
    createdAt
    unread
    type
    ... on CandidateApplicationRejectedNotification {
      ...ApplicationRejectedFields
    }
    ... on CandidateCanReapplyNotification {
      ...CanReapplyFields
    }
    ... on CandidateQuestionAnsweredNotification {
      ...QuestionAnsweredFields
    }
    ... on CandidateInterviewInviteNotification {
      ...InterviewInviteFields
    }
    ... on CandidateInterviewUpdatedNotification {
      ...InterviewUpdatedFields
    }
  }
`;

const NOTIFICATIONS_QUERY = gql`
  ${NOTIFICATION_FIELDS_FRAGMENT}
  query Notifications(
    $where: NotificationsWhereInput
    $size: Int
    $after: String
  ) {
    notifications(where: $where, _size: $size, _cursor: $after) {
      data {
        ...NotificationFields
      }
      count
      after
    }
  }
`;

type Notification = NonNullable<
  NotificationsQuery["notifications"]["data"][number]
>;

const useErrorFilteredNotificationsQuery = (
  baseOptions?: Omit<Parameters<typeof useNotificationsQuery>[0], "errorPolicy">
) => {
  const { data, error, ...rest } = useNotificationsQuery({
    ...baseOptions,
    errorPolicy: "all",
  });

  const filteredData = data
    ? {
        ...data,
        notifications: {
          ...data?.notifications,
          data: data?.notifications?.data?.filter(
            (notification): notification is Notification => !!notification
          ),
        },
      }
    : data;

  //    Log any errors on sentry that pertain to non-cascade deletes
  if (error?.message === "Cannot return null for non-nullable field") {
    captureException(error);
  }

  return {
    /** Better to handle !data as data partially returned */
    error,
    data: filteredData,
    ...rest,
  };
};

export type { Notification };

export {
  useErrorFilteredNotificationsQuery,
  useNotificationsQuery,
  NOTIFICATIONS_QUERY,
  NOTIFICATION_FIELDS_FRAGMENT,
};
