import { useRef } from "react";
import { useDripsyTheme, View } from "dripsy";
import {
  useToast as useNativeBaseToast,
  IconButton,
  CloseIcon,
  IToastProps,
  IIconButtonProps,
} from "native-base";
import { Feather } from "@expo/vector-icons";
import { useWindowDimensions } from "react-native";
import { merge } from "lodash";
import { FCC } from "app/types/index";
import {
  AlertCard,
  AlertHeader,
  AlertText,
  PressableAlertCard,
} from "../design/alert";

const errorDefaults: ToastProps = {
  title: "Something went wrong...",
  description: "Please try again or contact support.",
  status: "error",
};

const successDefaults: ToastProps = {
  title: "Success!",
  status: "success",
};

type Status = "error" | "success" | "info" | "warning";

const ICON_CONFIG: Record<
  Status,
  {
    icon: keyof typeof Feather.glyphMap;
  }
> = {
  error: {
    icon: "alert-triangle",
  },
  success: {
    icon: "check-circle",
  },
  info: {
    icon: "info",
  },
  warning: {
    icon: "alert-triangle",
  },
} as const;

interface ToastProps extends IToastProps {
  status?: Status;
  onPress?: () => void;
  renderBody?: (renderBodyBag: RenderBodyBag) => React.ReactElement;
}
interface RenderBodyBag extends Omit<ToastProps, "onPress" | "renderBody"> {
  onClose: OnClose;
}

type OnClose = () => void;

interface WithStatus {
  status: keyof typeof ICON_CONFIG;
}

const ToastIcon: React.FC<WithStatus> = ({ status }) => {
  const { theme } = useDripsyTheme();
  const { icon } = ICON_CONFIG[status];
  return <Feather name={icon} color={theme.alert[status].color} size={20} />;
};

const ToastTitle: FCC<WithStatus> = ({ status, children }) => {
  return (
    <View sx={{ flexDirection: "row", flexShrink: 1 }}>
      <ToastIcon status={status} />
      <AlertHeader
        variant={status}
        sx={{
          ml: "$2",
          flexShrink: 1,
          fontSize: 16,
          lineHeight: 20,
          alignSelf: "center",
        }}
      >
        {children}
      </AlertHeader>
    </View>
  );
};

interface ToastCloseButtonProps extends IIconButtonProps {
  onClose: OnClose;
  status: Status;
}

const ToastCloseButton = ({
  onClose,
  status,
  ...rest
}: ToastCloseButtonProps) => {
  const { theme } = useDripsyTheme();
  return (
    <IconButton
      p={1}
      onPress={onClose}
      variant="unstyled"
      icon={<CloseIcon size="4" />}
      opacity={0.7}
      zIndex={1}
      _icon={{
        color: theme.alert[status].color,
      }}
      _hover={{
        opacity: 1,
      }}
      _focus={{
        borderWidth: 0,
      }}
      {...rest}
    />
  );
};

const ToastHeader: FCC<{ onClose: OnClose } & WithStatus> = ({
  children,
  onClose,
  status,
}) => (
  <View
    sx={{
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexShrink: 1,
    }}
  >
    <ToastTitle status={status}>{children}</ToastTitle>
    <ToastCloseButton status={status} onClose={onClose} />
  </View>
);

const SCREEN_MARGIN_X = 16;
const MAX_TOAST_WIDTH = 400;

const useToast = () => {
  const toast = useNativeBaseToast();
  const toastRef = useRef();
  const { width } = useWindowDimensions();

  const defaultRenderBody = ({
    title,
    status = "info",
    description,
    onClose,
  }: RenderBodyBag) => (
    <View sx={{ flexShrink: 1 }}>
      <ToastHeader status={status} onClose={onClose}>
        {title}
      </ToastHeader>
      {description ? (
        <AlertText variant={status}>{description}</AlertText>
      ) : null}
    </View>
  );

  const show = ({
    title,
    description,
    status = "info",
    onPress,
    renderBody = defaultRenderBody,
    ...rest
  }: ToastProps) => {
    const AlertComponent = onPress ? PressableAlertCard : AlertCard;

    toastRef.current = toast.show({
      render: () => (
        <AlertComponent
          sx={{
            width: width - SCREEN_MARGIN_X * 2,
            maxWidth: MAX_TOAST_WIDTH,
          }}
          variant={status}
          onPress={onPress}
        >
          {renderBody({
            title,
            description,
            status,
            onClose: () => toast.close(toastRef.current),
            ...rest,
          })}
        </AlertComponent>
      ),
      ...rest,
    });
  };

  return {
    show,
    error: (props: Omit<ToastProps, "status"> = {}) =>
      show({
        ...errorDefaults,
        ...props,
      }),
    success: (props: Omit<ToastProps, "status"> = {}) =>
      show({
        ...successDefaults,
        ...props,
      }),
    isActive: toast.isActive,
    close: () => toast.close(toastRef.current),
    closeAll: toast.closeAll,
  };
};

interface UseAsyncToastConfig {
  throwError?: boolean;
  logError?: boolean;
  error?: Omit<ToastProps, "status">;
  success?: Omit<ToastProps, "status"> | null;
  onSuccess?: (res?: any) => void;
  onError?: (err?: unknown) => void;
}

const useAsyncToast = (config: UseAsyncToastConfig = {}) => {
  const toast = useToast();

  const defaults = {
    throwError: false,
    logError: true,
    error: errorDefaults,
    success: successDefaults,
    onSuccess: () => {},
    onError: () => {},
  };

  const { throwError, logError, onError, onSuccess, error, success } = merge(
    defaults,
    config
  );

  return <TInput extends any[], TOutput>(
      callback: (...input: TInput) => Promise<TOutput>
    ) =>
    async (...input: TInput): Promise<TOutput | void> => {
      try {
        const res = await callback(...input);
        if (success) toast.show(success);
        if (onSuccess) onSuccess(res);
        return res;
      } catch (e) {
        toast.show(error);
        if (onError) onError(e);
        if (logError) console.error(e);
        if (throwError) throw e;
      }
    };
};

export { useToast, useAsyncToast, ToastCloseButton };
export type { ToastProps, UseAsyncToastConfig };
