import { Row, useDripsyTheme, useSx } from "dripsy";
import { Skeleton } from "native-base";
import { Card } from "app/design/card";
import { Screen } from "app/components/screen";
import {
  FlatListItemWithSidebar,
  FlatListSidebar,
} from "app/components/flat-list.common";
import { useScreenSize } from "app/hooks";
import { RelatedQuestionsLoading } from "./related-questions";

export const QuestionScreenLoading = () => {
  const sx = useSx();
  const { theme } = useDripsyTheme();
  const { isWide } = useScreenSize();

  return (
    <Screen
      scrollable={false}
      sx={{ overflow: "hidden" }}
      containerSx={{ pb: 0 }}
    >
      {isWide ? (
        <FlatListSidebar>
          <RelatedQuestionsLoading />
          <Skeleton startColor={theme.colors.$blue} rounded="md" />
        </FlatListSidebar>
      ) : null}
      <FlatListItemWithSidebar>
        <Skeleton.Text
          style={sx({ mb: "$4" })}
          lines={1}
          _line={{ width: 280 }}
        />
        <Row sx={{ mb: "$6" }}>
          {Array.from({ length: 3 }).map((_, i) => (
            <Skeleton
              key={i}
              rounded={4}
              mr={2}
              h={8}
              w={20}
              startColor={theme.colors["$blue-tint-1"]}
            />
          ))}
        </Row>
        {Array.from({ length: 5 }).map((_, i) => (
          <Card key={i} sx={{ mb: "$4" }}>
            <Skeleton.Text mb={4} lines={1} _line={{ width: 85 }} />
            <Row sx={{ alignItems: "center", mb: "$4" }}>
              <Skeleton size={12} rounded="full" mr={3} />
              <Skeleton.Text flex={0.5} lines={2} w={150} />
            </Row>
            <Skeleton.Text mb={6} />
          </Card>
        ))}
      </FlatListItemWithSidebar>
    </Screen>
  );
};
