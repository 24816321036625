import { Feather } from "@expo/vector-icons";
import { DocumentType } from "app/types/generated/schema";
import { UploadDocumentButton } from "app/components/upload-document-button";
import {
  DocumentCard,
  DocumentCardProps,
  UPDATE_CANDIDATE_CORE_DOCS_CACHE_QUERY,
} from "app/components/document-card";
import { ProfileCardEmpty, ProfileCardEmptyProps } from "./card/empty";

interface ProfileDocumentCardEmptyProps
  extends Omit<ProfileCardEmptyProps, "children"> {
  callToAction: string;
  type: DocumentType;
}
const ProfileDocumentCardEmpty = ({
  description,
  callToAction,
  type,
}: ProfileDocumentCardEmptyProps) => (
  <ProfileCardEmpty description={description}>
    <UploadDocumentButton
      sx={{ alignSelf: "flex-start" }}
      label={callToAction}
      variant="secondary"
      documentType={type}
      icon={<Feather name="file-text" size={24} />}
      refetchQueries={[{ query: UPDATE_CANDIDATE_CORE_DOCS_CACHE_QUERY }]}
    />
  </ProfileCardEmpty>
);

interface ProfileDocumentCardProps extends DocumentCardProps {
  emptyCTA: string;
  emptyDescription: string;
}

const ProfileDocumentCard = ({
  typeProps,
  documents = [],
  emptyCTA,
  emptyDescription,
}: ProfileDocumentCardProps) => {
  if (!documents.length)
    return (
      <ProfileDocumentCardEmpty
        description={emptyDescription}
        callToAction={emptyCTA}
        type={typeProps.type}
      />
    );

  return <DocumentCard typeProps={typeProps} documents={documents} />;
};

export { ProfileDocumentCard, ProfileDocumentCardEmpty };
