import { uniqueId } from "lodash";
import { Button } from "app/design/button";
import { H4, Row, useDripsyTheme } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { ProfileCardDivider } from "../card";

interface ProfileFormProps {
  label: string;
  children: React.ReactNode;
  onCancel?: () => void;
}

const TEMPORARY_ID_PREFIX = "tmp";

export const isTempField = (field: { id: string }) =>
  field.id.includes(TEMPORARY_ID_PREFIX);

export const generateTempId = (fieldKey: string) =>
  `${TEMPORARY_ID_PREFIX}-${fieldKey}-${uniqueId()}`;

export const ProfileForm = ({
  label,
  children,
  onCancel,
}: ProfileFormProps) => {
  const { theme } = useDripsyTheme();
  return (
    <>
      <Row sx={{ justifyContent: "space-between" }}>
        <H4>Adding {label}</H4>
        <Button
          variant="ghost"
          sx={{ py: 0, px: 0 }}
          icon={<Feather name="x" color={theme.colors.$black} size={24} />}
          onPress={onCancel}
        />
      </Row>
      <ProfileCardDivider />
      {children}
    </>
  );
};
