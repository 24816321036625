import { Sx } from "dripsy";
import { useModal } from "react-native-modalfy";
import { Button, ButtonLink, ButtonLinkProps } from "app/design/button";
import { conditionalParam } from "app/utils";
import { pagesPath } from "app/lib/$path";
import { AnimatedOverviewCard } from "./animated-overview-card";
import { OverviewHeader, OverviewText } from "./overview-card";

interface ChangePreferencesButtonProps {
  variant?: ButtonLinkProps["variant"];
  containerSx?: Sx;
  children: React.ReactNode;
}

const ChangePreferencesButton = ({
  variant = "light",
  children,
  containerSx = {},
}: ChangePreferencesButtonProps) => (
  <ButtonLink
    href={pagesPath.job_preferences.$url()}
    variant={variant}
    containerSx={{
      alignSelf: "flex-start",
      ...conditionalParam({ alignSelf: "center" }, variant === "ghostLight"),
      ...containerSx,
    }}
  >
    {children}
  </ButtonLink>
);

interface PromptProps {
  sx?: Sx;
  description: string;
}
const SetPreferencesPrompt = ({ sx, description }: PromptProps) => (
  <AnimatedOverviewCard sx={sx}>
    <OverviewHeader>Set your job preferences</OverviewHeader>
    <OverviewText>{description}</OverviewText>
    <ChangePreferencesButton>Set preferences</ChangePreferencesButton>
  </AnimatedOverviewCard>
);

const VerifyPrompt = ({ sx, description }: PromptProps) => {
  const { openModal } = useModal();
  return (
    <AnimatedOverviewCard sx={sx}>
      <OverviewHeader>Verify your account</OverviewHeader>
      <OverviewText>{description}</OverviewText>
      <Button
        variant="light"
        containerSx={{ alignSelf: "flex-start" }}
        onPress={() => openModal("VerifyAccountModal", undefined)}
      >
        Verify account
      </Button>
    </AnimatedOverviewCard>
  );
};

export { ChangePreferencesButton, SetPreferencesPrompt, VerifyPrompt };
