import { Feather } from "@expo/vector-icons";
import {
  FlagButtonProps,
  useFlagButton,
} from "app/components/form/country-control";
import { Input } from "app/design/input";
import { Pressable, useDripsyTheme } from "dripsy";
import { useController, UseControllerProps } from "react-hook-form";

interface ProfileCountrySelectProps extends FlagButtonProps {
  label?: string;
}
export const ProfileCountrySelect = ({
  controllerProps,
  countryCode,
  label,
  onOpen,
}: ProfileCountrySelectProps & { controllerProps: UseControllerProps }) => {
  const { theme } = useDripsyTheme();
  const countryName = useFlagButton({ countryCode });

  const {
    fieldState: { error },
  } = useController(controllerProps);

  return (
    <Input
      label={label || "Country"}
      value={countryName}
      error={error?.message}
      placeholder="Select country"
      onFocus={onOpen}
      onPress={onOpen}
      editable={false}
      pointerEvents="none"
      InputRightElement={
        <Pressable sx={{ mr: "$2" }} onPress={onOpen}>
          <Feather name="chevron-down" color={theme.colors.$input} size={24} />
        </Pressable>
      }
    />
  );
};
