import { Text } from "dripsy";
import { gql } from "@apollo/client";
import { useJoinedCommunityChannelCardQuery } from "app/types/generated/schema";
import { QueryWrapper } from "app/components/query-wrapper";
import { pagesPath } from "app/lib/$path";
import { HeaderContainer, PressableCard } from "./card";
import { CommunityChannelCardHeader } from "./community-channel-card-header";
import { OverviewChannelCardLoading } from "./overview-channel-card";
import { MessagePreview } from "./message-preview";

const JOINED_COMMUNITY_CHANNEL_FRAGMENT = gql`
  fragment JoinedCommunityChannel on CommunityPublicChannel {
    ...CommunityChannelCardHeaderChannelFragment
    members(_size: 2) {
      ...CommunityChannelCardHeaderMembersFragment
    }
    messages(_size: 2) {
      data {
        id
        author {
          ...CommunityChannelCardHeaderUserFragment
          ...MessagePreviewAuthorFragment
        }
      }
    }
    description
    lastMessage {
      ...MessagePreviewFragment
    }
    unread
  }
  ${CommunityChannelCardHeader.fragments.communityPublicChannel}
  ${CommunityChannelCardHeader.fragments.members}
  ${CommunityChannelCardHeader.fragments.user}
  ${MessagePreview.fragments.message}
  ${MessagePreview.fragments.author}
`;

const JOINED_COMMUNITY_CHANNEL_CARD_QUERY = gql`
  query JoinedCommunityChannelCard($channelId: MigrateID!) {
    communityPublicChannel(id: $channelId) {
      ...JoinedCommunityChannel
    }
  }
  ${JOINED_COMMUNITY_CHANNEL_FRAGMENT}
`;

// Alias for now, but can be changed in the future
const JoinedChannelCardLoading = OverviewChannelCardLoading;

interface JoinedChannelInfoProps {
  id: string;
  lastMessage?: {
    id: string;
  } | null;
  description?: string | null;
  unread?: boolean;
}
const JoinedChannelInfo = ({
  id,
  lastMessage,
  description,
  unread,
}: JoinedChannelInfoProps) => {
  return (
    <>
      <HeaderContainer>
        <CommunityChannelCardHeader channelId={id} />
      </HeaderContainer>
      {lastMessage ? (
        <MessagePreview messageId={lastMessage.id} unread={unread} />
      ) : (
        <Text>{description}</Text>
      )}
    </>
  );
};

interface JoinedChannelCardProps {
  channelId: string;
}

const JoinedChannelCardComponent = ({ channelId }: JoinedChannelCardProps) => {
  const queryResponse = useJoinedCommunityChannelCardQuery({
    variables: { channelId },
  });

  return (
    <QueryWrapper
      queryHookResult={queryResponse}
      loaderComponent={() => <JoinedChannelCardLoading />}
    >
      {({ data: { communityPublicChannel } }) => {
        const { id, lastMessage, description, unread } = communityPublicChannel;

        return (
          <PressableCard
            href={pagesPath.community.channels._channelId(id).$url()}
            containerSx={{ flexGrow: 1 }}
            sx={{ flexGrow: 1 }}
          >
            <JoinedChannelInfo
              id={id}
              lastMessage={lastMessage}
              description={description}
              unread={unread}
            />
          </PressableCard>
        );
      }}
    </QueryWrapper>
  );
};

JoinedChannelCardComponent.fragments = {
  channel: JOINED_COMMUNITY_CHANNEL_FRAGMENT,
};

export type { JoinedChannelCardProps };
export {
  JOINED_COMMUNITY_CHANNEL_CARD_QUERY,
  JoinedChannelCardComponent as JoinedChannelCard,
  JoinedChannelCardLoading,
  JoinedChannelInfo,
};
