import { View } from "dripsy";
import { NonUserMessageLoading } from "./non-user-message";
import { UserMessageLoading } from "./user-message";

interface ThreadLoadingProps {
  isMe?: boolean;
  messageCount?: number;
}

const ThreadLoading = ({
  isMe = false,
  messageCount = 3,
}: ThreadLoadingProps) => {
  return (
    <View
      sx={{
        mx: "$4",
        transform: [{ scaleY: -1 }],
      }}
    >
      {Array.from({ length: messageCount }, (_, i) => {
        const messageProps = {
          key: i,
          sx: {
            width: ["100%", "90%", "30%"][i],
          },
          isSameThread: i !== 0,
          lines: 3 - i,
          showAvatar: i === 0,
        };

        return isMe ? (
          <UserMessageLoading {...messageProps} />
        ) : (
          <NonUserMessageLoading {...messageProps} />
        );
      })}
    </View>
  );
};

const MessageListLoading: React.FC = () => {
  return (
    <View
      sx={{
        flex: 1,
        overflow: "hidden",
        transform: [
          {
            scaleY: -1,
          },
        ],
      }}
    >
      {Array.from({ length: 5 }, (_, i) => (
        <ThreadLoading isMe={i % 3 === 0} key={i} />
      ))}
    </View>
  );
};

export { MessageListLoading };
