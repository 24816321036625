import { useQuestionTopicsQuery } from "app/types/generated/schema";
import { Menu } from "app/components/menu";
import { useScreenSize } from "app/hooks";
import { FilterMenuItem } from "./filter-menu-item";
import { TopicsFilterProps } from "./topics-filter";
import { TopicsFilterTrigger } from "./filter-triggers";

export const TopicsFilter = ({ topicIds, setTopicIds }: TopicsFilterProps) => {
  const { data, loading } = useQuestionTopicsQuery();
  const { isWide } = useScreenSize();

  return (
    <Menu
      closeOnSelect={false}
      trigger={(triggerProps) => (
        <TopicsFilterTrigger loading={loading} {...triggerProps} />
      )}
      placement={isWide ? "bottom left" : "bottom right"}
    >
      {data?.questionTopics.map(({ id, name }) => (
        <FilterMenuItem
          onPress={() =>
            topicIds.includes(id)
              ? setTopicIds(topicIds.filter((tId) => tId !== id))
              : setTopicIds([...topicIds, id])
          }
          selected={topicIds.includes(id)}
          key={id}
        >
          {name}
        </FilterMenuItem>
      ))}
    </Menu>
  );
};
