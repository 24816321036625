import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { DropdownProps, Dropdown } from "app/design/dropdown";
import { FormControl, FormControlProps } from "./form-control";

export interface DropdownControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  dropdownProps: DropdownProps;
}

export const DropdownControl = forwardRef(
  <T extends FieldValues>(props: DropdownControlProps<T>, ref: any) => {
    const { dropdownProps, ...rest } = props;

    return (
      <FormControl {...rest}>
        <Dropdown ref={ref} {...dropdownProps} />
      </FormControl>
    );
  }
);
DropdownControl.displayName = "DropdownControl";
