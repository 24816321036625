import { styled, useDripsyTheme, View } from "dripsy";
import { cloneElement, ReactElement, useContext } from "react";
import { format } from "date-fns";
import { Text, TextProps } from "app/components/text";
import { FCC } from "app/types";
import { NotificationCardThemeContext } from "./theme-context";

const TEXT_SX = {
  fontSize: 14,
  lineHeight: 21,
};

const NotificationText = ({ sx = {}, children, ...props }: TextProps) => {
  const { textSx: themeTextSx } = useContext(NotificationCardThemeContext);

  return (
    <Text
      sx={{
        ...TEXT_SX,
        ...sx,
        ...themeTextSx,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

interface BadgeProps {
  icon: ReactElement;
}

const Badge = ({ icon }: BadgeProps) => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        position: "absolute",
        bottom: -2,
        right: -2,
        width: 17,
        height: 17,
        backgroundColor: "$blue",
        borderRadius: 99,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {cloneElement(icon, {
        fill: theme.colors.$white,
        height: 9,
      })}
    </View>
  );
};

const Highlight: FCC = ({ children }) => (
  <NotificationText sx={{ fontWeight: "500", color: "$black" }}>
    {children}
  </NotificationText>
);

const NotificationContent = styled(View)({
  flex: 1,
  alignSelf: "center",
  ml: "$3",
});

const NotificationBody: FCC = ({ children }) => (
  <NotificationText sx={{ flexShrink: 1 }} numberOfLines={2}>
    {children}
  </NotificationText>
);

const formatInterviewDatetime = (date: string) =>
  `${format(new Date(date), "E do MMM yyy")} at ${format(
    new Date(date),
    "H:mm"
  )}`;

export {
  NotificationText,
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
  formatInterviewDatetime,
};
