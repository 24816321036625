import { useDripsyTheme, View } from "dripsy";

import { useCallback } from "react";
import { Text } from "app/components/text";

import { HyperlinkText } from "app/components/hyperlink-text";
import { ChannelMessage } from "../../types";
import { MessageAuthor } from "./header";
import {
  Attachment,
  ImageAttachment,
  RenderImageAttachment,
} from "./attachment";

interface ReferenceMessageBubbleProps {
  referencedMessage: NonNullable<ChannelMessage["referencedMessage"]>;
}

const ReferenceMessageBubble = ({
  referencedMessage,
}: ReferenceMessageBubbleProps) => {
  const renderImageAttachment = useCallback<RenderImageAttachment>(
    ({ attachment }) => <ImageAttachment width={100} uri={attachment.url} />,
    []
  );

  const { theme } = useDripsyTheme();
  const { color, ...referencedBubbleThemeProps } = theme.bubble.referenced;

  const { attachment, content, author, deletedAt } = referencedMessage;

  return (
    <View
      sx={{
        zIndex: 0,
        alignSelf: "flex-start",
        p: "$2",
        borderRadius: 6,
        mx: 0 - theme.space.$1,
        mt: "$1",
        mb: "$1",
        ...referencedBubbleThemeProps,
      }}
    >
      {deletedAt || !author ? (
        <Text
          variant="sm"
          sx={{
            color,
          }}
        >
          Message deleted
        </Text>
      ) : (
        <>
          <MessageAuthor author={author} colorConfig={{ default: color }} />
          {attachment ? (
            <Attachment
              attachment={attachment}
              renderImage={renderImageAttachment}
            />
          ) : (
            // TODO: Removed truncation for now as it breaks nested hyperlinks, can't see a way around it currently
            <HyperlinkText openInNewWindow variant="sm">
              {content}
            </HyperlinkText>
          )}
        </>
      )}
    </View>
  );
};

export { ReferenceMessageBubble };
