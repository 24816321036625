import { useDripsyTheme, View, styled } from "dripsy";
import Select, { MenuPlacement } from "react-select";
import { forwardRef, memo, useEffect, useRef, useState } from "react";
import { useScreenSize } from "app/hooks/use-screen-size";
import { mergeRefs } from "app/utils/react";
import { InputLabel } from "../input";
import { InputFooter } from "../input";
import { alphaColor } from "../utils";
import { DropdownProps } from ".";

const DripsySelect = styled(Select)();

export const Dropdown = memo(
  forwardRef(
    (
      {
        sx = {},
        containerSx = {},
        options,
        placeholder,
        multiple = false,
        value,
        error,
        label,
        helperText,
        loading = false,
        onChange,
        menuPlacement = "auto",
      }: DropdownProps,
      ref: any
    ) => {
      if (!onChange) throw new Error("onChange is required");
      const localRef = useRef<any>();

      const [open, setOpen] = useState(false);
      const { theme } = useDripsyTheme();

      const { isMobile } = useScreenSize();
      const breakpointIndex = isMobile ? 0 : 1;

      const providedPropsWithThemedText =
        (color = theme.colors.$black) =>
        (provided) => ({
          ...provided,
          fontFamily: theme.fonts.root,
          color,
        });

      const shouldRenderErrorState = error !== undefined && !open;

      const indicatorStyles = {
        color: theme.colors.$black,
        opacity: 0.75,
        "&:hover": {
          opacity: 1,
          color: theme.colors.$black,
        },
      };

      useEffect(() => {
        const resetIfValueNotInOptions = () => {
          if (!multiple) {
            if (options.some((o) => o.value === value)) return;
            onChange("");
            return;
          }

          if (value.some((v) => options.find((o) => o.value === v))) return;
          onChange(value.filter((v) => options.find((o) => o.value === v)));
        };

        const valueIsEmpty = [[], ""].includes(value);
        if (valueIsEmpty) return;
        resetIfValueNotInOptions();
      }, [options]);

      return (
        <View sx={{ ...containerSx }}>
          <InputLabel
            onPress={() => {
              localRef.current?.focus();
              setOpen(true);
            }}
          >
            {label}
          </InputLabel>
          <DripsySelect
            ref={mergeRefs([localRef, ref])}
            sx={sx}
            closeMenuOnSelect={!multiple}
            isMulti={multiple}
            menuPortalTarget={document.body}
            isSearchable={false}
            styles={{
              menu: (provided) => ({
                ...provided,
                borderColor: theme.colors.$muted,
              }),
              control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused
                  ? theme.colors.$blue
                  : shouldRenderErrorState
                  ? theme.colors.$error
                  : theme.colors.$muted,
                boxShadow: state.isFocused
                  ? `0 0 0 2px ${alphaColor(theme.colors.$blue, 0.5)}`
                  : shouldRenderErrorState
                  ? `0 0 0 2px ${alphaColor(theme.colors.$error, 0.5)}`
                  : undefined,
                transition: `border-color ${theme.transitionDurations.normal}ms, box-shadow ${theme.transitionDurations.normal}ms`,
                "&:hover": {
                  borderColor: shouldRenderErrorState
                    ? theme.colors.$error
                    : theme.colors.$blue,
                },
              }),
              clearIndicator: (provided) => ({
                ...provided,
                ...indicatorStyles,
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                ...indicatorStyles,
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                background: theme.colors.$ui,
              }),
              valueContainer: (provided) => ({
                ...provided,
                paddingLeft: [theme.space.$2, theme.space.$3][breakpointIndex],
                paddingRight: [theme.space.$3, theme.space.$4][breakpointIndex],
                paddingTop: [theme.space.$1, theme.space.$2][breakpointIndex],
                paddingBottom: [theme.space.$2, theme.space.$3][
                  breakpointIndex
                ],
              }),
              input: (provided) => ({
                ...provided,
                fontFamily: theme.fonts.root,
                color: theme.colors.$black,
                margin: 0,
                paddingTop: 2,
                paddingBottom: 3,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: theme.fonts.root,
                color: theme.colors.$placeholder,
                lineHeight: "21px",
                marginTop: 4,
                marginLeft: 4,
                marginRight: 0,
              }),
              option: (provided, state) => ({
                ...provided,
                color: theme.colors.$black,
                fontFamily: theme.fonts.root,
                backgroundColor:
                  state.isSelected || state.isFocused
                    ? theme.colors.$cream
                    : "transparent",
                "&:active": {
                  backgroundColor: theme.colors.$highlight,
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                fontFamily: theme.fonts.root,
                lineHeight: "21px",
                marginTop: 4,
                marginLeft: 4,
                marginRight: 0,
              }),
              multiValue: (provided) => ({
                ...provided,
                margin: 0,
                marginLeft: 4,
                marginTop: 4,
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                fontFamily: theme.fonts.root,
                fontSize: 13,
              }),
              multiValueRemove: (provided) => ({
                ...provided,
                cursor: "pointer",
              }),
              noOptionsMessage: providedPropsWithThemedText(
                theme.colors["$grey-shade-1"]
              ),
              menuPortal: (provided) => ({
                ...provided,
                zIndex: 99,
              }),
            }}
            placeholder={placeholder}
            options={options}
            // transform options to selected values to keep onChange API consistent with native dropdown
            value={
              multiple
                ? options.filter((option) => value.includes(option.value))
                : options.find((option) => option.value === value)
            }
            onChange={(changeValue: any) =>
              onChange(
                multiple ? changeValue.map((v) => v.value) : changeValue.value
              )
            }
            menuIsOpen={open}
            onMenuOpen={() => setOpen(true)}
            onMenuClose={() => setOpen(false)}
            menuPlacement={menuPlacement as MenuPlacement}
            isLoading={loading}
          />
          <InputFooter error={error} helperText={helperText} />
        </View>
      );
    }
  )
);
Dropdown.displayName = "Dropdown";
