import { gql } from "@apollo/client";
import { useModal } from "react-native-modalfy";
import {
  CandidateV2,
  CareerExperience,
  CareerExperienceFieldsFragmentDoc,
  useCareerExperienceSectionQuery,
  useCreateCareerBreakMutation,
  useCreateWorkExperienceMutation,
  useDeleteCareerExperienceMutation,
  useUpdateCareerBreakMutation,
  useUpdateWorkExperienceMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";

export const CREATE_WORK_EXPERIENCE_MUTATION = gql`
  ${CareerExperienceFieldsFragmentDoc}
  mutation CreateWorkExperience($input: CreateWorkExperienceInput!) {
    createWorkExperience(input: $input) {
      id
      careerExperiences {
        ...CareerExperienceFields
      }
      profileCompletion
      isExemptFromLanguageTest
    }
  }
`;

export const UPDATE_WORK_EXPERIENCE_MUTATION = gql`
  ${CareerExperienceFieldsFragmentDoc}
  mutation UpdateWorkExperience($input: UpdateWorkExperienceInput!) {
    updateWorkExperience(input: $input) {
      id
      careerExperiences {
        ...CareerExperienceFields
      }
      profileCompletion
      isExemptFromLanguageTest
    }
  }
`;

export const CREATE_CAREER_EXPERIENCE_MUTATION = gql`
  ${CareerExperienceFieldsFragmentDoc}
  mutation CreateCareerBreak($input: CreateCareerBreakInput!) {
    createCareerBreak(input: $input) {
      id
      careerExperiences {
        ...CareerExperienceFields
      }
      profileCompletion
    }
  }
`;

export const UPDATE_CAREER_BREAK_MUTATION = gql`
  ${CareerExperienceFieldsFragmentDoc}
  mutation UpdateCareerBreak($input: UpdateCareerBreakInput!) {
    updateCareerBreak(input: $input) {
      id
      careerExperiences {
        ...CareerExperienceFields
      }
      profileCompletion
    }
  }
`;

export const DELETE_CAREER_EXPERIENCE_MUTATION = gql`
  ${CareerExperienceFieldsFragmentDoc}
  mutation DeleteCareerExperience($input: DeleteCareerExperienceInput!) {
    deleteCareerExperience(input: $input) {
      id
      careerExperiences {
        ...CareerExperienceFields
      }
      profileCompletion
      isExemptFromLanguageTest
    }
  }
`;

export const useCareerExperienceMutations = (id?: string) => {
  const { openModal } = useModal();
  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't delete career experience",
      description: "Please try again or contact support.",
    },
  });

  const { data, error } = useCareerExperienceSectionQuery();
  if (error !== undefined)
    throw new Error(
      "unexpected error in useCareerExperienceMutations: " + error.message
    );

  const [createWorkExperience] = useCreateWorkExperienceMutation();
  const [updateWorkExperience] = useUpdateWorkExperienceMutation();
  const [createCareerBreak] = useCreateCareerBreakMutation();
  const [updateCareerBreak] = useUpdateCareerBreakMutation();

  const [deleteCareerExperience] = useDeleteCareerExperienceMutation({
    update: (cache) => {
      cache.modify({
        id: `Candidate:${data?.me.id}`,
        fields: {
          careerExperiences(existing) {
            return {
              ...existing,
              data: existing.data.filter(
                (exp: CareerExperience) => exp.id !== id
              ),
            };
          },
        },
      });
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteCareerExperience: {
        ...(data?.me as CandidateV2),
      },
    },
  });

  return {
    createWorkExperience,
    updateWorkExperience,
    createCareerBreak,
    updateCareerBreak,
    deleteCareerExperience: () =>
      openModal("ConfirmModal", {
        title: "Confirm delete career experience",
        description: "Are you sure you want to delete this career experience?",
        confirmLabel: "Delete career experience",
        onConfirm: withToast(async () => {
          if (!id)
            throw new Error("career experience: id required for deletion");
          await deleteCareerExperience({
            variables: { input: { id } },
          });
        }),
      }),
  };
};
