import { ReactNode } from "react";
import { Row, styled, Text, useDripsyTheme, View } from "dripsy";
import { ScrollTo } from "@nandorojo/anchor";
import { AntDesign } from "@expo/vector-icons";

interface LabelProps {
  active?: boolean;
  children: ReactNode;
}
const Label = styled(Text, {
  defaultVariant: "text.sm",
})(({ active }: LabelProps) => ({
  textDecorationLine: active ? "underline" : "none",
}));

export const CompletionCheck = ({
  label,
  completed,
  scrollToTarget,
}: {
  label: string;
  completed: boolean;
  scrollToTarget?: string;
}) => {
  const { theme } = useDripsyTheme();

  return (
    <Row
      sx={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        mb: ["$2", "$0"],
      }}
    >
      {scrollToTarget ? (
        <ScrollTo target={scrollToTarget}>
          <Label active>{label}</Label>
        </ScrollTo>
      ) : (
        <Label>{label}</Label>
      )}
      <View sx={{ flexGrow: 1, height: 1, bg: "$muted", mx: "$2" }} />
      {completed ? (
        <AntDesign name="checkcircle" color={theme.colors.$blue} size={16} />
      ) : (
        <View
          sx={{
            borderRadius: 16,
            width: 16,
            height: 16,
            bg: "$white",
            borderWidth: 1,
            borderColor: "$muted",
          }}
        />
      )}
    </Row>
  );
};
