import { H4, Row, useDripsyTheme, View } from "dripsy";
import { Skeleton, Tooltip } from "native-base";
import { Feather } from "@expo/vector-icons";
import { gql } from "@apollo/client";
import {
  CoreDocumentsFieldsFragmentDoc,
  DocumentType,
} from "app/types/generated/schema";
import { useDeleteDocument } from "app/hooks/use-delete-document";
import { Button } from "app/design/button";
import { Text } from "app/components/text";
import { MenuTriggerButton } from "app/components/menu-trigger";
import { useWebLink, WebLink } from "app/components/web-link";
import { UploadDocumentButton } from "app/components/upload-document-button";
import { formatDate } from "app/utils/time";
import { ProfileCardContainer } from "app/features/profile/card";
import { Menu } from "./menu";

const CORE_DOCUMENTS_FIELDS_FRAGMENT = gql`
  fragment CoreDocumentsFields on Document {
    id
    createdAt
    url
    filename
    typeProps {
      type
      name
    }
  }
`;

const UPDATE_CANDIDATE_CORE_DOCS_CACHE_QUERY = gql`
  ${CoreDocumentsFieldsFragmentDoc}
  query UpdateCandidateCoreDocsCache {
    me {
      id
      ... on CandidateV2 {
        coreDocuments {
          passport {
            ...CoreDocumentsFields
          }
          qualification {
            ...CoreDocumentsFields
          }
          languageCertificate {
            ...CoreDocumentsFields
          }
        }
        profileCompletion
      }
    }
  }
`;

const DocumentCardLoading = () => (
  <ProfileCardContainer>
    <Row sx={{ alignItems: "center", mb: "$2" }}>
      <Feather name="file-text" size={24} />
      <Skeleton.Text
        lines={1}
        _line={{ width: 200, height: 4, rounded: 3 }}
        ml={2}
      />
    </Row>
    <Skeleton.Text
      lines={1}
      _line={{ width: 300, height: 3, rounded: 3 }}
      mb={3}
    />
    <Skeleton.Text lines={1} _line={{ width: 150, height: 2.5, rounded: 3 }} />
  </ProfileCardContainer>
);

interface DocumentMenuProps {
  id: string;
  name: string;
  url: string;
}
const DocumentMenu = ({ id, name, url }: DocumentMenuProps) => {
  const { theme } = useDripsyTheme();
  const { onPress: handleView } = useWebLink(url);
  const handleDelete = useDeleteDocument({ id, name });

  return (
    <Menu
      trigger={(triggerProps) => (
        <MenuTriggerButton
          variant="ghost"
          containerSx={{ p: 0, alignSelf: "flex-start" }}
          sx={{ py: 0, px: 0 }}
          accessibilityLabel="More"
          icon={
            <Feather
              name="more-horizontal"
              size={24}
              color={theme.colors.$black}
            />
          }
          {...triggerProps}
        />
      )}
    >
      <Menu.Item onPress={handleView}>View</Menu.Item>
      <Menu.Item onPress={handleDelete}>Delete</Menu.Item>
    </Menu>
  );
};

interface DocumentCardProps {
  typeProps: {
    type: DocumentType;
    name: string;
  };
  documents?: {
    id: string;
    filename: string;
    createdAt: string;
    url: string;
  }[];
}

const DocumentCard = ({ typeProps, documents = [] }: DocumentCardProps) => (
  <ProfileCardContainer>
    <Row sx={{ alignItems: "center", mb: "$2" }}>
      <Feather name="file-text" size={24} color="black" />
      <H4 sx={{ ml: "$2" }}>{typeProps.name}</H4>
    </Row>

    <View sx={{ mb: "$2" }}>
      {documents.map(({ id, createdAt, filename, url }) => (
        <Row key={id} sx={{ alignItems: "center", mb: "$1" }}>
          <WebLink sx={{ flexShrink: 1 }} numberOfLines={1} url={url}>
            {filename}
          </WebLink>
          <Text sx={{ mr: "$2", flexShrink: 0 }} variant="sm">
            {" "}
            - {formatDate(createdAt)}
          </Text>
          <DocumentMenu
            id={id}
            name={`${typeProps.name} (${filename})`}
            url={url}
          />
        </Row>
      ))}
    </View>

    <UploadDocumentButton
      sx={{ alignSelf: "flex-start" }}
      documentType={typeProps.type}
      renderUploadButton={(triggerProps) => (
        <Tooltip label="Upload each file if your document is split across multiple files.">
          <Button size="sm" variant="link" {...triggerProps}>
            Add another file
          </Button>
        </Tooltip>
      )}
      refetchQueries={[{ query: UPDATE_CANDIDATE_CORE_DOCS_CACHE_QUERY }]}
    />
  </ProfileCardContainer>
);

export type { DocumentCardProps };
export {
  DocumentCard,
  DocumentCardLoading,
  CORE_DOCUMENTS_FIELDS_FRAGMENT,
  UPDATE_CANDIDATE_CORE_DOCS_CACHE_QUERY,
};
