import { View, H3, H2, styled } from "dripsy";
import { MatchResponseType } from "app/types/generated/schema";
import { Tabs } from "app/design/tabs";
import { useScreenSize } from "app/hooks";
import { JobsList } from "./jobs-list";

const sections = [
  {
    id: "saved",
    title: "Saved",
    content: <JobsList type={MatchResponseType.Save} />,
  },
  {
    id: "application_started",
    title: "Application started",
    content: <JobsList type={MatchResponseType.ApplicationStart} />,
  },
  {
    id: "applied",
    title: "Applied",
    content: <JobsList type={MatchResponseType.Apply} />,
  },
  {
    id: "hidden",
    title: "Hidden",
    content: <JobsList type={MatchResponseType.Hide} />,
  },
  {
    id: "rejected",
    title: "Rejected",
    content: <JobsList type={MatchResponseType.Rejected} />,
  },
];

const Title = styled(H2)({ mb: ["$4", "$6"] });

const YourJobsTabs = () => {
  return (
    <>
      <Title>Your jobs</Title>
      <Tabs tabs={sections} />
    </>
  );
};

const YourJobsList = () => {
  return (
    <>
      <Title>Your Jobs</Title>
      {sections.map((section) => (
        <View key={section.id} sx={{ mb: "$6" }}>
          <H3 sx={{ mb: "$4" }}>{section.title}</H3>
          {section.content}
        </View>
      ))}
    </>
  );
};

export const YourJobs = () => {
  const { isMobile } = useScreenSize();
  return isMobile ? <YourJobsTabs /> : <YourJobsList />;
};
