import { memo, ReactElement } from "react";
import isEqual from "react-fast-compare";
import { ProfileCard } from "app/features/profile/card";
import {
  BaseCareerExperienceProps,
  withCareerExperienceProps,
} from "../with-career-experience";

interface CareerBreakCardProps extends BaseCareerExperienceProps {
  reason: string;
  startDate: string;
  endDate: string;
  description?: string[];
  form: ReactElement;
}

export const CareerBreakCard = memo(
  withCareerExperienceProps(
    ({
      reason,
      startDate,
      endDate,
      description = [],
      onDelete,
      completed,
      form,
    }: CareerBreakCardProps) => (
      <ProfileCard
        header={reason}
        subheader="Career break"
        startDate={startDate}
        endDate={endDate}
        showDivider
        form={form}
        onDelete={onDelete}
        showFormOnRender={!completed}
      >
        <ProfileCard.Description description={description} />
      </ProfileCard>
    )
  ),
  isEqual
);
