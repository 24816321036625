import { useBreakpoints } from "dripsy";
import { useWindowDimensions } from "react-native";

import { HEADER_HEIGHT, TABS_HEIGHT } from "app/features/layout/constants";

export const useAvailableHeight = () => {
  const { width, height } = useWindowDimensions();
  const [mobileBreakpoint] = useBreakpoints();
  const isMobile = width <= mobileBreakpoint!;

  return isMobile
    ? height - HEADER_HEIGHT - TABS_HEIGHT
    : height - HEADER_HEIGHT;
};
