import { styled, View } from "dripsy";
import { ComponentProps } from "react";
import { ShadowGradient } from "app/design/shadow-gradient";

const BaseChannelHeader = styled(View)({
  width: "100%",
  p: "$4",
  flexDirection: "row",
  alignItems: "center",
  zIndex: 2,
  borderBottomWidth: 2,
  borderColor: "$white",
});

const ChannelHeader = ({
  children,
  renderHeader,
  ...rest
}: ComponentProps<typeof BaseChannelHeader> & {
  renderHeader?: () => React.ReactNode;
}) => (
  <>
    <BaseChannelHeader {...rest}>
      {children}
      <ShadowGradient />
    </BaseChannelHeader>
    {renderHeader?.()}
  </>
);

export { ChannelHeader };
