import { AnimatePresence, MotiView } from "moti";
import { useDripsyTheme } from "dripsy";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  runOnJS,
} from "react-native-reanimated";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from "react-native";
import { useEffect, useRef, useState } from "react";

import { useAvailableWidth } from "app/hooks/use-available-width";
import { ScreenProps } from "app/components/screen";
import {
  FloatingActionScreen,
  RenderFloatingLayerProps,
} from "app/components/floating-action-list";
import { FCC, guarantee } from "app/types";
import { ProgressDots } from "./progress-dots";
import { Arrow } from "./arrow";
import {
  SliderContextValue,
  SliderScreenProvider,
  useSliderContext,
} from "./slider.context";

interface SlideSwipeProps {
  onNext: () => void;
  onPrev: () => void;
  disablePrev: boolean;
  disableNext: boolean;
}

const SliderSwipe: FCC<SlideSwipeProps> = ({
  onNext,
  onPrev,
  disableNext,
  disablePrev,
  children,
}) => {
  const { disableSwipeX, disableSwipeY } = useSliderContext();
  const translationX = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translationX.value }],
    };
  });
  const availableWidth = useAvailableWidth();
  const { theme } = useDripsyTheme();

  const swipeThreshold =
    Math.min(availableWidth, theme.layout.screen.body.maxWidth) / 5;

  const gesture = Gesture.Pan()
    .activeOffsetX([-5, 5])
    .failOffsetY([-10, 10])
    .maxPointers(1)
    .shouldCancelWhenOutside(true)
    .onChange((e) => {
      "worklet";

      if (
        disableSwipeX &&
        e.x >= disableSwipeX[0] &&
        e.x <= disableSwipeX[1] &&
        disableSwipeY &&
        e.y >= disableSwipeY[0] &&
        e.y <= disableSwipeY[1]
      )
        return;

      translationX.value += e.changeX;
    })
    .onFinalize(() => {
      "worklet";

      if (translationX.value < 0 - swipeThreshold && !disableNext) {
        runOnJS(onNext)();
        translationX.value = withSpring(translationX.value - 500, {
          damping: 12,
        });
        return;
      }
      if (translationX.value > swipeThreshold && !disablePrev) {
        runOnJS(onPrev)();
        translationX.value = withSpring(translationX.value + 500, {
          damping: 12,
        });
        return;
      }

      translationX.value = withSpring(0, {
        damping: 12,
      });
    });

  return (
    <GestureDetector gesture={gesture}>
      <Animated.View style={animatedStyles}>{children}</Animated.View>
    </GestureDetector>
  );
};

type SlideDirection = "left" | "right";

interface SliderProps {
  slideDirection: SlideDirection;
  activeIndex: number;
  children: React.ReactNode;
  onPrev: () => void;
  onNext: () => void;
  disablePrev: boolean;
  disableNext: boolean;
}

const Slider = ({
  slideDirection,
  activeIndex,
  children,
  onNext,
  onPrev,
  disableNext,
  disablePrev,
}: SliderProps) => {
  const { theme } = useDripsyTheme();

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <MotiView
        key={activeIndex}
        from={{
          opacity: 0,
          translateX: slideDirection === "right" ? 100 : -100,
        }}
        animate={{
          opacity: 1,
          translateX: 0,
        }}
        exit={{
          opacity: 0,
          translateX: slideDirection === "right" ? -100 : 100,
        }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
      >
        <SliderSwipe
          onNext={onNext}
          onPrev={onPrev}
          disableNext={disableNext}
          disablePrev={disablePrev}
        >
          {children}
        </SliderSwipe>
      </MotiView>
    </AnimatePresence>
  );
};

interface UseSliderConfig {
  length: number;
  initialIndex?: number;
}

const useSlider = ({ length, initialIndex = 0 }: UseSliderConfig) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [slideDirection, setSlideDirection] = useState<SlideDirection>("right");

  const setSlide = (index: number) => {
    if (index < 0 || index > length - 1) {
      console.error("Index out of bounds", index);
      return;
    }
    setSlideDirection(index > activeIndex ? "right" : "left");
    setActiveIndex(index);
  };

  const prevLength = useRef(length);
  const resetAfterError = useRef(false);

  /**
   * handle hiding the last slide:
   * - set active index to penultimate slide
   * - reset to last slide if hiding results in an error
   */
  useEffect(() => {
    if (length === prevLength.current + 1 && resetAfterError.current) {
      setActiveIndex(activeIndex + 1);
      resetAfterError.current = false;
    }

    if (activeIndex >= length) {
      setActiveIndex(activeIndex - 1);
      resetAfterError.current = true;
    }

    prevLength.current = length;
  }, [activeIndex, length]);

  return {
    setSlide,
    activeIndex,
    slideDirection,
    isAtStart: activeIndex === 0,
    isAtEnd: activeIndex === length - 1,
  };
};

type SliderState = ReturnType<typeof useSlider>;

interface SliderScreenProps {
  length: number;
  renderSlide: (sliderState: SliderState) => React.ReactNode;
  onChange?: (sliderState: SliderState) => void;
  initialIndex?: number;
  renderActions?: (
    sliderState: SliderState,
    renderFloatingLayerProps: RenderFloatingLayerProps
  ) => React.ReactNode;
  screenProps?: Omit<ScreenProps, "children">;
}

const SliderScreen = ({
  onChange,
  renderSlide,
  renderActions,
  length,
  initialIndex = 0,
  screenProps = {},
}: SliderScreenProps) => {
  // Responsibility of parent to ensure valid input
  if (length <= 0) {
    throw new Error("SliderScreen length must be greater than 0");
  }

  if (initialIndex < 0 || initialIndex > length - 1) {
    throw new Error("SliderScreen initialIndex must be within length bounds");
  }

  const scrollViewRef = useRef<ScrollView>(null);
  const { theme } = useDripsyTheme();

  const sliderState = useSlider({
    length,
    initialIndex,
  });

  const { activeIndex, isAtEnd, isAtStart, setSlide, slideDirection } =
    sliderState;

  useEffect(() => {
    onChange?.(sliderState);
  }, [activeIndex, slideDirection, onChange]);

  useEffect(() => {
    setTimeout(() => {
      scrollViewRef.current?.scrollTo({ y: 0, animated: true });
    }, theme.transitionDurations.normal * 2);
  }, [activeIndex]);

  const handleNext = () => setSlide(activeIndex + 1);
  const handlePrev = () => setSlide(activeIndex - 1);

  return (
    <FloatingActionScreen
      scrollViewProps={{
        ref: scrollViewRef,
      }}
      screenProps={screenProps}
      renderFloatingLayer={(renderFloatingLayerProps) => (
        <>
          <Arrow
            onPress={handlePrev}
            direction="prev"
            disabled={isAtStart}
            screenMaxWidth={renderFloatingLayerProps.maxWidth}
          />
          <Arrow
            onPress={handleNext}
            direction="next"
            disabled={isAtEnd}
            screenMaxWidth={renderFloatingLayerProps.maxWidth}
          />
          {renderActions?.(sliderState, renderFloatingLayerProps)}
        </>
      )}
    >
      <ProgressDots index={activeIndex} length={length} onChange={setSlide} />
      <Slider
        activeIndex={activeIndex}
        slideDirection={slideDirection}
        disableNext={isAtEnd}
        disablePrev={isAtStart}
        onNext={handleNext}
        onPrev={handlePrev}
      >
        {renderSlide(sliderState)}
      </Slider>
    </FloatingActionScreen>
  );
};

interface ItemSliderScreenContainerProps {
  children: React.ReactNode;
}

/**
 * For wrapping common layout for loading components etc
 */
const ItemSliderScreenContainer = ({
  children,
}: ItemSliderScreenContainerProps) => (
  <FloatingActionScreen>{children}</FloatingActionScreen>
);

interface ItemSliderScreenProps<TItem> extends SliderContextValue {
  items: TItem[];
  renderSlide: (item: TItem, sliderState: SliderState) => React.ReactNode;
  onScroll?: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
  renderActions?: (
    item: TItem,
    renderActionsState: SliderState & RenderFloatingLayerProps
  ) => React.ReactNode;
  onChange?: (item: TItem, sliderState: SliderState) => void;
  initialIndex?: number;
  screenProps?: Omit<ScreenProps, "children">;
}

const ItemSliderScreen = <TItem,>({
  items,
  renderSlide,
  renderActions,
  onChange,
  initialIndex,
  disableSwipeX,
  disableSwipeY,
}: ItemSliderScreenProps<TItem>) => {
  const getItemFromState = (sliderState: SliderState) => {
    const item = guarantee(
      /**
       * if the active index becomes out of bounds after hiding the last element,
       * return the previous slide and wait for useSlider to reset the active index
       */
      sliderState.activeIndex >= items.length
        ? items[sliderState.activeIndex - 1]
        : items[sliderState.activeIndex],
      "Item guaranteed by slider state"
    );
    return item;
  };

  return (
    <SliderScreenProvider
      disableSwipeX={disableSwipeX}
      disableSwipeY={disableSwipeY}
    >
      <SliderScreen
        length={items.length}
        initialIndex={initialIndex}
        onChange={(sliderState) => {
          onChange?.(getItemFromState(sliderState), sliderState);
        }}
        renderSlide={(sliderState) =>
          renderSlide(getItemFromState(sliderState), sliderState)
        }
        renderActions={(sliderState, renderActionsState) =>
          renderActions?.(getItemFromState(sliderState), {
            ...sliderState,
            ...renderActionsState,
          })
        }
      />
    </SliderScreenProvider>
  );
};

export type {
  ItemSliderScreenProps,
  SlideDirection,
  SliderState,
  ItemSliderScreenContainerProps,
};
export { ItemSliderScreen, ItemSliderScreenContainer };
