import { gql } from "@apollo/client";
import {
  CandidateV2,
  CoreDocumentsFieldsFragmentDoc,
  DocumentType,
  usePassportSectionQuery,
} from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import { DocumentCardLoading } from "app/components/document-card";
import { ProfileDocumentCard } from "./document-card";
import { ProfileSection } from "./section";

export const PASSPORT_SECTION_QUERY = gql`
  ${CoreDocumentsFieldsFragmentDoc}
  query PassportSection {
    me {
      id
      ... on CandidateV2 {
        coreDocuments {
          passport {
            ...CoreDocumentsFields
          }
        }
      }
    }
  }
`;

interface PassportSectionProps {
  showHeader?: boolean;
}

export const PassportSection = ({
  showHeader = false,
}: PassportSectionProps) => {
  const { data, loading, error } = usePassportSectionQuery();

  if (loading) return <DocumentCardLoading />;
  if (error || !data) return <DataError title="Couldn't load your passport" />;

  const { coreDocuments } = data.me as CandidateV2;

  return (
    <ProfileSection title="Passport" showHeader={showHeader}>
      <ProfileDocumentCard
        typeProps={{
          type: DocumentType.Passport,
          name: "Passport",
        }}
        documents={coreDocuments?.passport}
        emptyDescription="Upload a copy of your government ID so we can confirm your identity."
        emptyCTA="Add identification"
      />
    </ProfileSection>
  );
};
