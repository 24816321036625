import { styled } from "dripsy";
import { Card } from "app/design/card";
import { alphaColor } from "app/design/utils";
import { brandColors } from "app/provider/dripsy/colors";

const CardOuter = styled(Card)({
  alignItems: "center",
  boxShadow: "md",
  mb: 0,
  p: ["$4", "$5"],
});
const CardInner = styled(Card)({
  backgroundColor: alphaColor(brandColors.blue, 0.1),
  mt: "$6",
  mb: 0,
  mx: [-8, 0],
  width: ["auto", "100%"],
  boxShadow: "none",
  borderWidth: 0,
  p: ["$4", "$5"],
  alignSelf: "stretch",
});

export { CardInner, CardOuter };
