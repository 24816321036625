import { useState } from "react";

interface ToggleState {
  readonly isSelected: boolean;
  setSelected(isSelected: boolean): void;
  toggle(): void;
}

interface ToggleProps {
  isSelected?: boolean;
}

export const useToggleState = ({
  isSelected: defaultIsSelected = false,
}: ToggleProps): ToggleState => {
  const [isSelected, setSelected] = useState(defaultIsSelected);

  const toggle = () => {
    setSelected(!isSelected);
  };

  return {
    isSelected,
    setSelected,
    toggle,
  };
};
