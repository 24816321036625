import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 16;
const originalHeight = 16;
const aspectRatio = originalWidth / originalHeight;

const SpeechBubbleIcon = ({
  width = originalWidth,
  ...rest
}: SvgProps & { width?: number }) => (
  <Svg
    width={width}
    height={width * aspectRatio}
    viewBox={`0 0 ${originalWidth} ${originalHeight}`}
    {...rest}
  >
    <Path d="M16,7.56c0,1.17-.27,2.33-.8,3.38-1.28,2.56-3.89,4.18-6.76,4.18-1.17,0-2.33-.27-3.38-.8l-5.07,1.69,1.69-5.07c-.53-1.05-.8-2.2-.8-3.38,0-2.86,1.62-5.48,4.18-6.76C6.11,.27,7.27,0,8.44,0h.44c3.84,.21,6.9,3.27,7.11,7.11v.44Z" />
  </Svg>
);

export { SpeechBubbleIcon };
