import { styled, useDripsyTheme } from "dripsy";
import { MotiView } from "moti";
import { PropsWithChildren } from "react";

const DripsyMotiView = styled(MotiView)();

interface StepContainerProps {
  navDirection: "left" | "right";
}

const StepContainer = ({
  navDirection,
  children,
}: PropsWithChildren<StepContainerProps>) => {
  const { theme } = useDripsyTheme();

  return (
    <DripsyMotiView
      sx={{
        alignItems: "center",
      }}
      from={{
        opacity: 0,
        translateX: navDirection === "right" ? 100 : -100,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      exit={{
        opacity: 0,
        translateX: navDirection == "right" ? -100 : 100,
      }}
      transition={{
        type: "timing",
        duration: theme.transitionDurations.normal,
      }}
    >
      {children}
    </DripsyMotiView>
  );
};

export { StepContainer };
