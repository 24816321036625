import { ForwardedRef, forwardRef } from "react";
import { FieldValues, useController } from "react-hook-form";
import { Checkbox, CheckboxProps } from "app/design/checkbox";
import { FormControlProps } from "./form-control";

export interface CheckboxControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  checkboxProps?: CheckboxProps;
}

export const CheckboxControl = forwardRef(
  <T extends FieldValues>(
    props: CheckboxControlProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { name, control, rules, defaultValue, checkboxProps } = props;

    const {
      field: { onChange, value },
    } = useController({ name, control, rules, defaultValue });

    return (
      <Checkbox
        ref={ref}
        onChange={onChange}
        isSelected={value}
        {...checkboxProps}
      />
    );
  }
);
CheckboxControl.displayName = "CheckboxControl";
