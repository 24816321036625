import { gql, InternalRefetchQueriesInclude } from "@apollo/client";
import {
  Document,
  DocumentType,
  useGetDocumentUploadTokenQuery,
  useUploadDocumentMutation,
} from "../types/generated/schema";
import { asImperativeQuery } from "./helpers/imperative-query";
import { useCurrentUser } from "./use-current-user";
import { AnyFile, uploadFile } from "./helpers/upload-file";
import { useData } from "./use-data";

export const DOCUMENT_UPLOAD_TOKEN_QUERY = gql`
  query GetDocumentUploadToken($filename: String) {
    getDocumentUploadToken(input: { filename: $filename }) {
      token
      url
    }
  }
`;

export const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation UploadDocument($input: UploadDocumentInput!) {
    uploadDocument(input: $input) {
      id
      fileMetadata {
        filename
        size
        createdAt
      }
      url
      type
      typeProps {
        name
      }
    }
  }
`;

export interface UploadDocumentInput {
  file: AnyFile;
  documentType: DocumentType;
}

interface UseUploadDocumentProps {
  refetchQueries?: InternalRefetchQueriesInclude;
  awaitRefetchQueries?: boolean;
  appendToCache?: boolean;
}

export const useUploadDocument = ({
  refetchQueries,
  awaitRefetchQueries = true,
  appendToCache = true,
}: UseUploadDocumentProps) => {
  const { currentUser } = useCurrentUser();

  const [getDocumentUploadToken] = asImperativeQuery(
    useGetDocumentUploadTokenQuery
  );

  // eslint-disable-next-line local/handle-mutation-error
  const [uploadDocumentMutation] = useUploadDocumentMutation({
    update(cache, { data }) {
      if (!data) throw new Error("No data returned from mutation");
      const { uploadDocument } = data;
      cache.modify({
        id: `${currentUser?.__typename}:${currentUser?.id}`,
        fields: {
          documents(cachedDocuments) {
            const newDocumentRef = cache.writeFragment({
              data: uploadDocument,
              fragment: gql`
                fragment NewDocument on Document {
                  id
                  type
                }
              `,
            });

            if (appendToCache)
              return {
                ...cachedDocuments,
                data: [newDocumentRef, ...cachedDocuments.data],
              };

            const filteredDocuments = cachedDocuments.data.filter(
              (cachedDocument: { __ref: string }) => {
                const cacheResult = cache.readFragment<Document>({
                  // eslint-disable-next-line no-underscore-dangle
                  id: cachedDocument.__ref,
                  fragment: gql`
                    fragment ReadDocument on Document {
                      id
                      type
                    }
                  `,
                });
                return cacheResult?.type !== uploadDocument.type;
              }
            );

            return {
              ...cachedDocuments,
              data: [newDocumentRef, ...filteredDocuments],
            };
          },
        },
      });
    },
    refetchQueries,
    awaitRefetchQueries,
  });

  const [state, setState] = useData();

  const uploadDocument = async ({
    file,
    documentType,
  }: UploadDocumentInput) => {
    setState({ loading: true });

    const {
      data: {
        getDocumentUploadToken: { url, token },
      },
    } = await getDocumentUploadToken({
      filename: file.name,
    });

    await uploadFile(url, file);

    const data = await uploadDocumentMutation({
      variables: {
        input: {
          type: documentType,
          token,
        },
      },
    });

    setState({ loading: false, data });

    return data;
  };

  return [uploadDocument, state] as const;
};
