import { ComponentProps } from "react";
import { Row, Text, useDripsyTheme } from "dripsy";
import { Actionsheet } from "native-base";
import { Feather } from "@expo/vector-icons";

interface ActionsheetItemProps extends ComponentProps<typeof Actionsheet.Item> {
  iconName: ComponentProps<typeof Feather>["name"];
  children: React.ReactNode;
}

const ActionsheetItem = ({
  iconName,
  children,
  ...actionsheetItemProps
}: ActionsheetItemProps) => {
  const { theme } = useDripsyTheme();

  return (
    <Actionsheet.Item
      style={{ backgroundColor: "white" }}
      {...actionsheetItemProps}
    >
      <Row sx={{ alignItems: "center" }}>
        <Feather name={iconName} size={24} color={theme.colors.$black} />
        <Text sx={{ ml: "$3" }}>{children}</Text>
      </Row>
    </Actionsheet.Item>
  );
};

interface ClosingActionsheetItemProps extends ActionsheetItemProps {
  close: () => void;
}

const ClosingActionsheetItem = ({
  onPress,
  close,
  ...actionsheetItemProps
}: ClosingActionsheetItemProps) => {
  return (
    <ActionsheetItem
      onPress={(e) => {
        onPress?.(e);
        close();
      }}
      {...actionsheetItemProps}
    />
  );
};

export type { ActionsheetItemProps, ClosingActionsheetItemProps };

export { ActionsheetItem, ClosingActionsheetItem };
