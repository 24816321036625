import { memo } from "react";
import { P, useDripsyTheme } from "dripsy";
import { FieldValues, Path } from "react-hook-form";

import { Form } from "app/components/form";
import { InputControl } from "app/components/form/input-control";
import { PasswordControl } from "app/components/form/password-control";
import { CheckboxControl } from "app/components/form/checkbox-control";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";

import { StepHeading } from "./heading";

interface CreateAccountStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  names: {
    forenames: Path<TFieldValues>;
    surname: Path<TFieldValues>;
    email: Path<TFieldValues>;
    password: Path<TFieldValues>;
    signUpToMailingList: Path<TFieldValues>;
  };
  onSubmit: () => void;
}

const StepForm = <TFieldValues extends FieldValues>({
  names,
  control,
  onSubmit,
}: CreateAccountStepProps<TFieldValues>) => {
  const { theme } = useDripsyTheme();

  return (
    <>
      <StepHeading>Create your account</StepHeading>
      <Form
        sx={{
          marginTop: 0 - theme.space.$2,
          width: "100%",
          maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
        }}
        onSubmit={onSubmit}
      >
        <P sx={{ textAlign: "center" }}>
          Apply for jobs and receive support from the Migrate community by
          creating an account.
        </P>
        <InputControl
          control={control}
          name={names.forenames}
          inputProps={{
            label: "First name",
            placeholder: "Enter first name",
            autoComplete: "name-given",
            textContentType: "givenName",
          }}
        />

        <InputControl
          control={control}
          name={names.surname}
          inputProps={{
            label: "Last name",
            placeholder: "Enter last name",
            autoComplete: "name-family",
            textContentType: "familyName",
          }}
        />

        <InputControl
          control={control}
          name={names.email}
          inputProps={{
            label: "Email",
            placeholder: "Enter email address",
            keyboardType: "email-address",
            autoComplete: "email",
            autoCorrect: false,
            textContentType: "emailAddress",
            autoCapitalize: "none",
          }}
        />

        <PasswordControl
          control={control}
          name={names.password}
          inputProps={{
            label: "Password",
            autoComplete: "password",
            textContentType: "password",
          }}
        />

        <CheckboxControl
          control={control}
          name={names.signUpToMailingList}
          checkboxProps={{
            "aria-label": "Sign up to Migrate's mailing list",
            label:
              "I'd like to receive emails relating to job search and updates about new features.",
          }}
        />
      </Form>
    </>
  );
};

const MemoizedStepForm = memo(StepForm) as typeof StepForm & {
  displayName: "CreateAccountStep";
};

MemoizedStepForm.displayName = "CreateAccountStep";

const CreateAccountStep = <TFieldValues extends FieldValues>(
  props: CreateAccountStepProps<TFieldValues>
) => <MemoizedStepForm {...props} />;

export { CreateAccountStep };
