import { Feather } from "@expo/vector-icons";
import { Row, useDripsyTheme, useSx, View } from "dripsy";
import { MotiPressable } from "moti/interactions";

import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { Text } from "app/components/text";

import { getMessageContentSummary } from "../community/chat/utils/message";
import { useChatStore } from "./hooks/use-chat-store";

const ReplyingTo: React.FC = () => {
  const { theme } = useDripsyTheme();
  const { replyingTo, setReplyingTo } = useChatStore();
  const sx = useSx();

  if (!replyingTo)
    throw new Error("replyingTo must be set to render component");

  const { attachment, content, author } = replyingTo;

  return (
    <Row
      sx={{
        bg: "$uiBg",
        width: "100%",
        py: "$2",
        px: "$4",
        zIndex: 1,
        alignItems: "flex-start",
      }}
    >
      <View sx={{ mr: "$2", flex: 1 }}>
        <Text sx={{ fontFamily: "heading", color: "$black" }}>
          {author ? author?.displayName : "unknown user"}
        </Text>
        <Text>{getMessageContentSummary({ attachment, content })}</Text>
      </View>
      <MotiPressable
        containerStyle={sx({ mt: "$1" })}
        onPress={() => setReplyingTo(null)}
      >
        <ColorAnimatedIcon
          icon={<Feather name="x" color={theme.colors.$black} size={20} />}
          colorStates={{
            color: {
              states: [["hovered", theme.colors.$orange]],
              initialColor: theme.colors.$black,
            },
          }}
        />
      </MotiPressable>
    </Row>
  );
};

export { ReplyingTo };
