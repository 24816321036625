import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 34;
const originalHeight = 22;
const aspectRatio = originalWidth / originalHeight;

const CommunityIcon = ({ width, height, ...rest }: SvgProps) => (
  <View style={{ aspectRatio, width, height }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...rest}
    >
      <Path d="M14.6719 15.1804C13.1146 12.138 9.65099 10.0355 5.35787 12.3471C2.40916 13.9345 0.651448 17.4402 0.0243091 20.2592C-0.00845327 20.4048 -0.00809489 20.5558 0.0253578 20.7012C0.0588104 20.8466 0.124502 20.9826 0.217577 21.0992C0.310652 21.2158 0.42873 21.3099 0.563084 21.3747C0.697437 21.4395 0.844631 21.4733 0.993785 21.4735H14.9836C15.115 21.4736 15.2451 21.4477 15.3664 21.3971C15.4876 21.3466 15.5977 21.2725 15.6901 21.179C15.7825 21.0856 15.8555 20.9748 15.9047 20.8529C15.954 20.7311 15.9786 20.6007 15.977 20.4693C15.956 18.6292 15.5093 16.819 14.6719 15.1804V15.1804Z" />
      <Path d="M7.58442 10.1497C9.84462 10.1497 11.9734 8.53064 11.9734 6.33223C11.9734 4.07244 10.5034 2.45337 8.24417 2.45337C5.92932 2.45337 4.18982 4.35347 4.18982 6.67369C4.18982 8.93349 5.29834 10.1497 7.58442 10.1497Z" />
      <Path d="M32.1601 21.5001C32.2989 21.5007 32.4362 21.4721 32.5633 21.4161C32.6903 21.3601 32.8041 21.278 32.8973 21.1752C32.9906 21.0723 33.0611 20.951 33.1045 20.8191C33.1478 20.6872 33.1628 20.5477 33.1487 20.4096C32.7882 17.1215 30.9537 14.1577 28.5919 12.1406C28.5804 12.1297 28.5689 12.1201 28.5574 12.1118C27.5783 11.2812 26.3998 10.5647 24.9365 10.5263C20.5762 10.4151 17.9085 16.8722 17.2919 19.9568C17.266 20.0882 17.242 20.2187 17.22 20.3491C17.197 20.4912 17.2053 20.6366 17.2441 20.7752C17.2829 20.9138 17.3514 21.0423 17.4448 21.1518C17.5382 21.2613 17.6543 21.3491 17.785 21.4093C17.9158 21.4694 18.058 21.5004 18.2019 21.5001H32.1601Z" />
      <Path d="M24.664 0.5C22.2906 0.5 20.578 2.68114 20.578 4.98889C20.578 7.36186 22.1219 9.06054 24.4943 9.06054C26.9232 9.06054 28.75 7.51245 28.75 5.07617C28.75 2.70608 27.0652 0.5 24.664 0.5Z" />
    </Svg>
  </View>
);

export { CommunityIcon };
