import { styled, View } from "dripsy";

const MessageContainer = styled(View)(
  ({
    isSameThread,
    messagePosition,
  }: {
    isSameThread?: boolean;
    messagePosition: "left" | "right";
  }) => {
    const alignment = messagePosition === "left" ? "flex-start" : "flex-end";
    return {
      flexDirection: "row",
      width: ["90%", "80%"],
      maxWidth: 720,
      mb: 2,
      mt: !isSameThread ? "$4" : 0,
      alignSelf: alignment,
      justifyContent: alignment,
    };
  }
);

const AvatarSpacer = styled(View)({
  mr: 48,
});

export { MessageContainer, AvatarSpacer };
