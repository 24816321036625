import { useEffect, useMemo } from "react";
import { NativeBaseProvider, extendTheme } from "native-base";
import { useDripsyTheme } from "dripsy";
import { ApolloProvider } from "@apollo/client";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import countries from "i18n-iso-countries";
import { PortalProvider } from "@gorhom/portal";

import { FCC } from "app/types/index";
import { useCurrentUser } from "app/hooks/use-current-user";
import { FeatureFlagsProvider } from "app/hooks/flags/provider";
import { alphaColor, lightenColor } from "app/design/utils";
import { AppLoader } from "app/components/app-loader";
import i18n from "app/i18n/config";
import { useSubscribeToNewMessages } from "app/features/chat/hooks/use-subscribe-to-new-messages";
import { useSubscribeToNotifications } from "app/features/notifications/hooks/use-subscribe-to-notifications";
import { createApolloClient } from "app/client/apollo";
import { IS_LOCAL } from "app/client";
import { setAnalyticsUser } from "app/client/analytics";
import { captureException, Sentry } from "app/client/error";
import { useProfileTracking } from "app/features/profile/completion/use-profile-tracking";

import { Dripsy } from "./dripsy";
import { ModalProvider } from "./modal";
import { ErrorBoundary } from "./error-boundary";
import { NavigationProvider } from "./navigation";
import { RefetchProvider } from "./refetch";

// Initialise i18n (not sure why import with no name does not do this...)
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
i18n;

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const NativeBase: FCC = ({ children }) => {
  const { theme } = useDripsyTheme();

  const nativeBaseTheme = extendTheme({
    // e.g. extend nativebase theme with dripsy colors
    colors: {
      blue: {
        400: theme.colors.$blue,
        500: theme.colors.$blue,
        600: theme.colors.$blue,
      },
      primary: {
        400: theme.colors.$blue,
        500: theme.colors.$blue,
        600: theme.colors.$blue,
      },
    },
    components: {
      Input: {
        defaultProps: {
          borderColor: theme.colors.$ui,
          _focus: {
            bg: lightenColor(theme.colors.$blue, 200),
          },
        },
      },
      ModalContent: {
        baseStyle: {
          _light: {
            bg: theme.colors.$white,
          },
        },
      },
      ModalHeader: {
        baseStyle: {
          _light: {
            bg: theme.colors.$white,
            borderColor: theme.colors.$ui,
          },
        },
      },
      ModalFooter: {
        baseStyle: {
          _light: {
            bg: theme.colors.$white,
            borderColor: theme.colors.$ui,
          },
        },
      },
      Modal: {
        baseStyle: {
          _fade: {
            // TODO: This isn't working, modal is super slow https://github.com/GeekyAnts/NativeBase/issues/4997
            entryDuration: theme.transitionDurations.normal,
            exitDuration: theme.transitionDurations.normal,
          },
        },
        sizes: {
          md: {
            contentSize: {
              width: "90%",
            },
          },
        },
      },
      Skeleton: {
        baseStyle: {
          startColor: alphaColor(theme.colors.$black, 0.125),
          endColor: alphaColor(theme.colors.$black, 0.075),
        },
      },
      SkeletonText: {
        baseStyle: {
          startColor: alphaColor(theme.colors.$black, 0.125),
          endColor: alphaColor(theme.colors.$black, 0.075),
        },
      },
      Menu: {
        baseStyle: {
          shadow: null,
          borderWidth: 1,
          borderRadius: "md",
          minWidth: 220,
          borderColor: theme.colors.$ui,
          bg: theme.card.default.backgroundColor,
          mt: 1,
          _presenceTransition: {
            initial: { translateY: -5 },
            animate: { translateY: 0 },
            exit: { translateY: 0 },
          },
        },
      },
      MenuGroup: {
        baseStyle: {
          px: 6,
          _title: {
            fontFamily: theme.fonts.heading,
            color: theme.colors.$grey,
          },
        },
      },
      MenuItem: {
        baseStyle: {
          _light: {
            _text: {
              fontFamily: theme.fonts.root,
              fontSize: 16,
              color: theme.colors.$black,
            },
            _hover: {
              bg: alphaColor(theme.colors.$blue, 0.1),
            },
            _focus: {
              bg: alphaColor(theme.colors.$blue, 0.1),
            },
            _pressed: {
              bg: alphaColor(theme.colors.$blue, 0.1),
            },
            _focusVisible: {
              _web: {
                bg: alphaColor(theme.colors.$blue, 0.1),
              },
            },
          },
        },
      },
      PopoverContent: {
        baseStyle: {
          shadow: null,
          borderWidth: 1,
          borderRadius: "md",
          minWidth: 220,
          bg: theme.card.default.backgroundColor,
          borderColor: theme.colors.$ui,
          _light: {
            bg: alphaColor("#fffffe", 0.85),
          },
        },
      },
      Tooltip: {
        baseStyle: {
          px: 2,
          bg: theme.colors.$black,
          shadow: 2,
          maxWidth: 300,
          _text: {
            fontFamily: theme.fonts.root,
            color: theme.colors.$white,
          },
        },
      },
      Toast: {
        baseStyle: {
          _overlay: {
            style: {
              zIndex: 10,
            },
          },
        },
      },
    },
  });

  return (
    <NativeBaseProvider theme={nativeBaseTheme}>{children}</NativeBaseProvider>
  );
};

const CurrentUserProvider: FCC = ({ children }) => {
  const { loading, currentUser } = useCurrentUser();

  useEffect(() => {
    if (loading || IS_LOCAL) return;

    const setSentryAndAnalyticsUser = async () => {
      if (!currentUser?.account) return;
      try {
        await Promise.all([
          setAnalyticsUser(currentUser.account.id),
          Sentry.setUser(currentUser),
        ]);
      } catch (e) {
        captureException(e);
      }
    };

    setSentryAndAnalyticsUser();
  }, [currentUser, loading]);

  if (loading) {
    return <AppLoader />;
  }

  return <>{children}</>;
};

const RootHooks: React.FC = () => {
  useSubscribeToNewMessages();
  useSubscribeToNotifications();
  useProfileTracking();
  return null;
};

export function Provider({ children }: { children: React.ReactNode }) {
  const apolloClient = useMemo(createApolloClient, []);
  return (
    <Dripsy>
      <ErrorBoundary>
        <NavigationProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <ApolloProvider client={apolloClient}>
              <RefetchProvider>
                <NativeBase>
                  <RootHooks />
                  <PortalProvider>
                    <ModalProvider>
                      <FeatureFlagsProvider>
                        <CurrentUserProvider>{children}</CurrentUserProvider>
                      </FeatureFlagsProvider>
                    </ModalProvider>
                  </PortalProvider>
                </NativeBase>
              </RefetchProvider>
            </ApolloProvider>
          </GestureHandlerRootView>
        </NavigationProvider>
      </ErrorBoundary>
    </Dripsy>
  );
}
