import { DropdownControl } from "app/components/form/dropdown-control";
import {
  LanguageResultAwardingBody,
  LanguageResultSchema,
} from "app/types/generated/schema";
import { Sx } from "dripsy";
import { capitalize } from "lodash";
import { UseControllerProps } from "react-hook-form";

interface LanguageResultInputProps extends UseControllerProps<any> {
  resultSchema?: LanguageResultSchema;
  awardingBody: LanguageResultAwardingBody;
  sx?: Sx;
}

export const LanguageResultInput = ({
  name,
  control,
  resultSchema,
  awardingBody,
  sx = {},
}: LanguageResultInputProps) => (
  <DropdownControl
    control={control}
    name={name}
    dropdownProps={{
      label: capitalize(name) + " result",
      containerSx: {
        mb: ["$3", "$3", "$0"],
        flex: [, , 0.5],
        ...sx,
      },
      options:
        resultSchema?.[awardingBody].map((score) => ({
          label: score,
          value: score,
        })) || [],
      placeholder: "Select " + name + " result",
    }}
  />
);
