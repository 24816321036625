import { View } from "dripsy";
import { gql } from "@apollo/client";
import { OrganisationAvatar } from "app/design/avatar";
import { JobsIcon } from "app/design/svgs/icons/jobs";
import {
  CandidateCanReapplyNotification,
  Notification,
  NotificationType,
  CanReapplyFieldsFragment,
} from "app/types";
import { pagesPath } from "app/lib/$path";
import {
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
} from "./common";
import { NotificationHeader } from "./header";
import {
  NotificationCardWrapper,
  NotificationCardWrapperProps,
} from "./wrapper";

interface CanReapplyCardProps
  extends Omit<NotificationCardWrapperProps, "children" | "href"> {
  notification: Pick<CandidateCanReapplyNotification, "createdAt" | "id"> &
    CanReapplyFieldsFragment;
}

const CanReapplyCard = ({
  notification,
  renderHeaderRight,
  ...rest
}: CanReapplyCardProps) => (
  <NotificationCardWrapper
    notification={notification}
    href={pagesPath.jobs._jobId(notification.job.id).$url()}
    {...rest}
  >
    <View>
      <OrganisationAvatar
        organisation={notification.job.organisation}
        size={12}
      />
      <Badge icon={<JobsIcon />} />
    </View>
    <NotificationContent>
      <NotificationHeader
        createdAt={notification.createdAt}
        renderHeaderRight={renderHeaderRight}
      >
        Update about your <Highlight>{notification.job.title}</Highlight>{" "}
        application
      </NotificationHeader>
      <NotificationBody>
        You can now reapply for this position - we&apos;ve added the application
        back to your jobs.
      </NotificationBody>
    </NotificationContent>
  </NotificationCardWrapper>
);

CanReapplyCard.fragment = gql`
  fragment CanReapplyFields on CandidateCanReapplyNotification {
    job {
      id
      title
      organisation {
        id
        photoUrl
      }
    }
  }
`;

CanReapplyCard.typeguard = (
  notification: Pick<Notification, "type">
): notification is CandidateCanReapplyNotification =>
  notification.type === NotificationType.CandidateCanReapply;

export { CanReapplyCard };
