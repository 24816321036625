import { ResumeDataEducationItem } from "@affinda/affinda";
import { useToast } from "app/components/use-toast";
import { ImportFromCVButton } from "../import-from-cv-button";
import { generateTempId } from "../profile-form";
import { transformDate } from "../utils";
import { EducationExperienceFormValues } from "./form-card";

const transform = (
  affindaWorkExperiences: ResumeDataEducationItem[]
): EducationExperienceFormValues[] =>
  affindaWorkExperiences.reduce<EducationExperienceFormValues[]>(
    (acc, item) => {
      const { accreditation, organization, dates } = item;
      // TODO: review valid condition?
      if (!accreditation?.education && !organization) return acc;

      acc.push({
        id: generateTempId("education-experience"),
        institutionName: organization || "",
        qualification: accreditation?.education || "",
        startDate: transformDate(dates?.startDate),
        endDate: transformDate(dates?.completionDate),
        description: [],
        hideEndDate: !!!dates?.completionDate,
      });
      return acc;
    },
    []
  );

interface ImportEducationHistoryFromCVButtonProps {
  onParseData: (data: EducationExperienceFormValues[]) => void;
}
export const ImportEducationHistoryFromCVButton = ({
  onParseData,
}: ImportEducationHistoryFromCVButtonProps) => {
  const toast = useToast();
  return (
    <ImportFromCVButton
      handleImport={(parsedCV) => {
        if (!parsedCV.data || !parsedCV.data.education) {
          toast.show({
            status: "error",
            title: "Failed to import CV",
            description:
              "We couldn't find your education history on the CV you uploaded. Please try again with a different document or contact support.",
          });
          return;
        }
        onParseData(transform(parsedCV.data.education));
      }}
    />
  );
};
