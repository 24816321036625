import { View } from "dripsy";
import { Skeleton } from "native-base";
import { ChannelHeader } from "./common";

export const ChannelHeaderLoading = () => (
  <ChannelHeader>
    <Skeleton width={10} rounded={20} mr={3} />
    <View>
      <Skeleton.Text lines={1} _line={{ width: 250 }} mb={1} />
      <Skeleton.Text lines={1} _line={{ width: 200 }} />
    </View>
  </ChannelHeader>
);
