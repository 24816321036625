import React from "react";
import { ApolloError, QueryResult } from "@apollo/client";
import { ActivityIndicator } from "app/design/activity-indicator";
import { DataError } from "./data-error";

export type SuccessfulQueryBag<THookResult extends QueryResult> = Omit<
  THookResult,
  "data" | "loading" | "error"
> & {
  data: Exclude<THookResult["data"], undefined>;
};

export interface QueryWrapperProps<THookResult extends QueryResult> {
  queryHookResult: THookResult;
  children: (res: SuccessfulQueryBag<THookResult>) => React.ReactNode;
  errorComponent?: (error: ApolloError | undefined) => React.ReactNode;
  loaderComponent?: (hookResult?: THookResult) => React.ReactNode;
  loading?: (hookResult: THookResult) => boolean;
}

/**
 * Wraps an apollo query result with a loader and error component.
 * The error component will appear if there is a query error or data is undefined / null
 */
export const QueryWrapper = <THookResult extends QueryResult<any, any>>({
  queryHookResult,
  loaderComponent = () => <ActivityIndicator />,
  loading = ({ loading: hookLoading }) => hookLoading,
  errorComponent = (errorInput) => <DataError title={errorInput?.message} />,
  children,
}: QueryWrapperProps<THookResult>) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data, error, loading: _loading, ...queryBag } = queryHookResult;

  if (loading(queryHookResult)) return <>{loaderComponent()}</>;
  if (error || !data) return <>{errorComponent(error)}</>;

  return (
    <>
      {children({
        ...queryBag,
        data: data as Exclude<THookResult["data"], undefined>,
      })}
    </>
  );
};
