import { gql } from "@apollo/client";
import {
  CandidateV2,
  ProfileCompletion,
  useRegistrationDetailFormQuery,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { DataError } from "app/components/data-error";
import { DefaultProfileCardEmpty } from "../../card/empty";
import { useProfileEntryList } from "../hooks/use-profile-entry-list";
import { ProfileSection } from "../../section";
import { getProfileSectionStatus } from "../utils";
import {
  RegistrationDetailFormCard,
  RegistrationDetailFormValues,
} from "./form-card";
import { RegistrationDetailCard } from "./card";
import { RegistrationDetailsLoading } from "./loading";

export const REGISTRATION_DETAILS_EXPERIENCE_FORM_QUERY = gql`
  query RegistrationDetailForm {
    me {
      id
      ... on CandidateV2 {
        registrationDetails {
          id
          country
          countryInfo {
            name
          }
          referenceNumber
        }
        profileCompletion
      }
    }
  }
`;

export const RegistrationDetailsSection = () => {
  const { data, loading, error } = useRegistrationDetailFormQuery();

  const { fields, append, remove } =
    useProfileEntryList<RegistrationDetailFormValues>({
      defaultValues: (data?.me as CandidateV2)?.registrationDetails?.map(
        ({ __typename, ...registrationDetails }) => ({
          ...registrationDetails,
          isInProgress: !!!registrationDetails.referenceNumber,
        })
      ),
    });

  if (loading) return <RegistrationDetailsLoading />;
  if (error) return <DataError title="Failed to load registration details" />;

  const { registrationDetails, profileCompletion } = data?.me as CandidateV2;

  const status = getProfileSectionStatus({
    hasStarted: (registrationDetails || []).length > 0,
    hasCompleted: profileCompletion.includes(
      ProfileCompletion.HasRegistrationDetails
    ),
  });

  return (
    <ProfileSection title="Registration details" status={status}>
      {fields.length === 0 ? (
        <DefaultProfileCardEmpty
          description="Include details of any in progress or complete professional registrations."
          callToAction="Add registration"
          onPress={() => append(RegistrationDetailFormCard.createInstance())}
        />
      ) : null}
      {fields.map(
        ({
          // @ts-expect-error we're pulling it out of the field here so this is fine
          countryInfo,
          ...field
        }) => (
          <RegistrationDetailCard
            key={field.id}
            field={field}
            country={countryInfo?.name}
            onCancel={() => remove(field.id)}
          />
        )
      )}
      {fields.length > 0 ? (
        <Button
          variant="secondary"
          containerSx={{ alignSelf: "center" }}
          onPress={() => append(RegistrationDetailFormCard.createInstance())}
        >
          Add another registration
        </Button>
      ) : null}
    </ProfileSection>
  );
};
