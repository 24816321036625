import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 22;
const originalHeight = 20;
const aspectRatio = originalWidth / originalHeight;

const ThumbsUpIcon = ({
  width = 22,
  ...rest
}: SvgProps & { width?: number }) => (
  <Svg
    width={width}
    height={width / aspectRatio}
    viewBox={`0 0 ${originalWidth} ${originalHeight}`}
    {...rest}
  >
    <Path d="M2,8c-1.1,0-2,.9-2,2v8c0,1.1,.9,2,2,2h2V8H2Z" />
    <Path d="M20,7h-6.32l.96-4.57c.02-.1,.03-.21,.03-.32,0-.41-.17-.79-.44-1.06l-1.06-1.05L6.59,6.58c-.37,.37-.59,.87-.59,1.42v12h11c.83,0,1.54-.5,1.84-1.22l3.02-7.05c.09-.23,.14-.47,.14-.73v-2c0-1.11-.9-2-2-2Zm0,4l-3,7H8V7.99L12.34,3.66l-.62,2.93-.51,2.41h8.78v2Z" />
  </Svg>
);

const ThumbsUpFilledIcon = ({
  width = 22,
  ...rest
}: SvgProps & { width?: number }) => (
  <Svg
    width={width}
    height={width / aspectRatio}
    viewBox={`0 0 ${originalWidth} ${originalHeight}`}
    {...rest}
  >
    <Path d="M2,8c-1.1,0-2,.9-2,2v8c0,1.1,.9,2,2,2h2V8H2Z" />
    <Path d="M20,7h-6.32l.96-4.57c.02-.1,.03-.21,.03-.32,0-.41-.17-.79-.44-1.06l-1.06-1.05L6.59,6.58c-.37,.37-.59,.87-.59,1.42v12h11c.83,0,1.54-.5,1.84-1.22l3.02-7.05c.09-.23,.14-.47,.14-.73v-2c0-1.11-.9-2-2-2Z" />
  </Svg>
);

export { ThumbsUpIcon, ThumbsUpFilledIcon };
