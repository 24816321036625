import { styled, H1, P } from "dripsy";
import { MotiImage, MotiView } from "moti";
import { ImageSourcePropType } from "react-native";
import { HighlightText } from "../highlight-text";
import { HeaderWrapper } from "./header";

const DripsyMotiImage = styled(MotiImage)();
const DripsyMotiView = styled(MotiView)();

const HighlightCarouselText = styled(HighlightText)({
  fontSize: [32, 48],
  lineHeight: [38, 58],
});

const CarouselHeader = styled(H1)({
  fontSize: [32, 48],
  lineHeight: [38, 58],
  textAlign: "center",
  pr: "$2",
  mb: "$0",
});

const CarouselBody = styled(P)({
  variant: "text.lg",
  textAlign: "center",
  mb: "$5",
});

const CarouselPageOne: React.FC = () => (
  <>
    <HeaderWrapper>
      <HighlightCarouselText>Healthcare</HighlightCarouselText>
      <CarouselHeader> jobs</CarouselHeader>
    </HeaderWrapper>
    <CarouselBody>
      Say goodbye to complicated job searches and hello to roles you love at the
      best NHS trusts.
    </CarouselBody>
  </>
);

const CarouselPageTwo: React.FC = () => (
  <>
    <HeaderWrapper>
      <CarouselHeader>Specialist</CarouselHeader>
      <HighlightCarouselText>support</HighlightCarouselText>
    </HeaderWrapper>
    <CarouselBody>
      Access useful tips from our skilled Career Champions - they&apos;ll
      support you at every stage.
    </CarouselBody>
  </>
);

const CarouselPageThree: React.FC = () => (
  <>
    <HeaderWrapper>
      <HighlightCarouselText>Everything</HighlightCarouselText>
      <CarouselHeader> you need</CarouselHeader>
    </HeaderWrapper>
    <CarouselBody>
      Migrate provides support, and a connected community to make changing jobs
      simpler.
    </CarouselBody>
  </>
);

const CarouselPage: React.FC<{
  index: number;
  url: ImageSourcePropType;
  imageHeight: number;
}> = ({ index, url, imageHeight }) => {
  const Copy =
    (index === 0 && CarouselPageOne) ||
    (index === 1 && CarouselPageTwo) ||
    CarouselPageThree;

  return (
    <DripsyMotiView
      from={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      sx={{
        flex: 1,
        justifyContent: "center",
        px: ["$4", 0],
      }}
    >
      <DripsyMotiImage
        sx={{
          width: "100%",
          height: imageHeight,
        }}
        resizeMode="contain"
        source={url}
      />
      <Copy />
    </DripsyMotiView>
  );
};

export { CarouselPage };
