import { View, Row, Text } from "dripsy";
import { Skeleton } from "native-base";
import { ReactNode } from "react";
import { AvatarLoading } from "app/design/avatar";
import { Card } from "app/design/card";

export const FakeCompletionCheck = ({ children }: { children: ReactNode }) => (
  <Row
    sx={{
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      mb: ["$2", "$0"],
    }}
  >
    <Text variant="sm">{children}</Text>
    <View sx={{ flexGrow: 1, height: 1, bg: "$muted", mx: "$2" }} />
    <Skeleton size={4} rounded="full" />
  </Row>
);

interface ProfileCompletionLoadingProps {
  avatarSize: number;
  offsetTop: number;
}
export const ProfileCompletionLoading = ({
  avatarSize,
  offsetTop,
}: ProfileCompletionLoadingProps) => {
  return (
    <Card sx={{ p: "$6", pt: ["$6", null, null, 0] }}>
      <View
        sx={{
          mt: [null, null, null, 0 - offsetTop],
          alignItems: "center",
        }}
      >
        <View
          sx={{
            mb: "$3",
            backgroundColor: "$white",
            borderRadius: 999,
          }}
        >
          <AvatarLoading
            size={avatarSize}
            sx={{ borderWidth: 2, borderColor: "$white" }}
          />
        </View>
        <Skeleton.Text
          lines={1}
          w={125}
          _line={{
            height: 6,
          }}
          mb={2}
        />
        <Skeleton.Text
          lines={1}
          w={175}
          _line={{
            height: 3,
          }}
          mb={6}
        />
        <Skeleton.Text lines={1} w={150} _line={{ height: 2 }} mb={2} />

        {[
          "Upload passport",
          "Work experience",
          "Education history",
          "Registration details",
          "Language qualifications",
          "More about you",
          "Add a photo",
        ].map((label) => (
          <FakeCompletionCheck key={label}>{label}</FakeCompletionCheck>
        ))}
      </View>
    </Card>
  );
};
