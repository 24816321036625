import { useModal } from "react-native-modalfy";
import { View, Row, H3, styled } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

import { FCC } from "app/types/index";
import { Button, ButtonLink, ButtonLinkProps } from "app/design/button";
import { Grid, GridItem } from "app/design/grid";
import { Screen } from "app/components/screen";
import { ScreenHeader } from "app/components/screen/header";
import { MinScreenSize } from "app/components/min-screen-size";
import { pagesPath } from "app/lib/$path";

import { memoizeScreen } from "app/utils/screen";
import { Layout } from "../layout";
import { CommunityProfileCard } from "./profile-card";
import { TrendingQuestions } from "./questions/trending-questions";
import { JoinedChannels } from "./chat/channel/joined-channels";
import { ProtectCommunityFeature } from "./protect-feature";

const Section = styled(View)({
  mb: "$7",
});

const SectionHeader = styled(Row)({
  justifyContent: "space-between",
  alignItems: "center",
  mb: "$4",
});

const SectionHeaderTitle = styled(H3)({ my: "$0" });

const SectionButtonWrapper: FCC = ({ children }) => (
  <Grid gutter={16} sx={{ justifyContent: "flex-end" }}>
    <GridItem widths={[1, 1 / 2, 1 / 3]}>{children}</GridItem>
  </Grid>
);

const SectionHeaderLink: FCC<ButtonLinkProps> = (props) => (
  <ButtonLink variant="ui" {...props}></ButtonLink>
);

const CommunityProfile = () => (
  <Section>
    <CommunityProfileCard />
  </Section>
);

interface CommunityQuestionsProps {
  renderAskButton?: boolean;
}
const CommunityQuestions = ({
  renderAskButton = true,
}: CommunityQuestionsProps) => {
  const { openModal } = useModal();
  const { t } = useTranslation("copy", { keyPrefix: "community.question" });

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderTitle>Latest questions</SectionHeaderTitle>
        <SectionHeaderLink href={pagesPath.community.questions.$url()}>
          {t("seeAll")}
        </SectionHeaderLink>
      </SectionHeader>
      <TrendingQuestions />
      {renderAskButton && (
        <SectionButtonWrapper>
          <ProtectCommunityFeature.AskQuestion>
            <Button
              containerSx={{ mt: "$4" }}
              icon={<Feather name="edit-3" size={20} />}
              onPress={() => openModal("AskQuestionModal", undefined)}
            >
              {t("ask")}
            </Button>
          </ProtectCommunityFeature.AskQuestion>
        </SectionButtonWrapper>
      )}
    </Section>
  );
};

const YourChannels: React.FC = () => {
  const { t } = useTranslation("copy", { keyPrefix: "community.channel" });

  return (
    <Section>
      <SectionHeader>
        <SectionHeaderTitle>Your channels</SectionHeaderTitle>
        <ProtectCommunityFeature.JoinChannels>
          <SectionHeaderLink href={pagesPath.community.channels.$url()}>
            {t("seeAll")}
          </SectionHeaderLink>
        </ProtectCommunityFeature.JoinChannels>
      </SectionHeader>
      <JoinedChannels />
      <SectionButtonWrapper>
        <ProtectCommunityFeature.JoinChannels>
          <ButtonLink href={pagesPath.community.channels.all.$url()}>
            {t("joinChannels")}
          </ButtonLink>
        </ProtectCommunityFeature.JoinChannels>
      </SectionButtonWrapper>
    </Section>
  );
};

const CommunityHomeScreen = memoizeScreen(() => {
  return (
    <Screen scrollable>
      <MinScreenSize size="tablet">
        <ScreenHeader>Community</ScreenHeader>
      </MinScreenSize>

      <CommunityProfile />
      <CommunityQuestions />
      <YourChannels />
    </Screen>
  );
});

const headerProps = {
  title: "Community",
  backButtonType: "none",
  showTitleOnDesktop: false,
} as const;

CommunityHomeScreen.displayName = "CommunityHomeScreen";
CommunityHomeScreen.headerProps = headerProps;
CommunityHomeScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { CommunityHomeScreen, CommunityQuestions };
