import { useDripsyTheme, View, SxProp, Sx } from "dripsy";
import { cloneElement, ReactElement, useContext } from "react";

import { Feather } from "@expo/vector-icons";
import { FCC } from "app/types/index";
import { Text } from "app/components/text";
import { formatDate } from "app/utils/time";

import { CardThemeContext } from "./card";

const QuestionCardDetail: FCC<{
  sx?: SxProp;
  icon: ReactElement;
}> = ({ children, icon, sx = {} }) => {
  const { theme } = useDripsyTheme();
  const textTheme = useContext(CardThemeContext);

  return (
    <View
      sx={{
        mt: "$1",
        mr: "$3",
        flexDirection: "row",
        alignItems: "center",
        ...sx,
      }}
    >
      {cloneElement(icon, {
        color: theme.text[textTheme].color,
        size: 16,
      })}
      <Text variants={["xs", textTheme]} sx={{ ml: "$2", fontWeight: "500" }}>
        {children}
      </Text>
    </View>
  );
};

export interface AnswerCountProps {
  count?: number;
  sx?: Sx;
}

export const AnswerCount: React.FC<AnswerCountProps> = ({ count = 0, sx }) => (
  <QuestionCardDetail icon={<Feather name="message-circle" />} sx={sx}>
    {count ? `${count} answer${count !== 1 ? "s" : ""}` : "No answers yet!"}
  </QuestionCardDetail>
);

export interface DateProps {
  createdAt: string;
  sx?: Sx;
}

export const Date: React.FC<DateProps> = ({ createdAt, sx }) => (
  <QuestionCardDetail icon={<Feather name="clock" />} sx={sx}>
    {formatDate(createdAt)}
  </QuestionCardDetail>
);

interface QuestionMetadataProps {
  sx?: SxProp;
  createdAt: string;
  answerCount: number;
}

export const QuestionMetadata = ({
  sx = {},
  createdAt,
  answerCount,
}: QuestionMetadataProps) => {
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        mt: 0 - theme.space.$1,
        mr: 0 - theme.space.$2,
        ...sx,
      }}
    >
      <Date createdAt={createdAt} />
      <AnswerCount count={answerCount} />
    </View>
  );
};
