import { useRef, useState } from "react";
import { H4, P, Pressable, Row, useDripsyTheme, View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { motify } from "moti";
import create from "zustand";
import { View as RNView } from "react-native";

import {
  Location as MigrateLocation,
  MapLocation,
} from "app/types/generated/schema";
import { AnimateHeight } from "app/components/animate-height";
import { MapView } from "app/components/map-view";
import { Text } from "app/components/text";

import { Section, SectionHeader } from "./section";

const MotiFeather = motify(Feather)();

interface ToggleProps {
  active: boolean;
  onPress: () => void;
}

const Toggle = ({ active, onPress }: ToggleProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Pressable sx={{ alignSelf: "center" }} onPress={onPress}>
      <Row sx={{ alignItems: "center" }}>
        <Text sx={{ color: "$blue", fontWeight: "500" }}>
          Show {active ? "less" : "more"}
        </Text>
        <MotiFeather
          size={20}
          name="chevron-up"
          color={theme.colors.$blue}
          animate={{ rotate: active ? "0deg" : "180deg" }}
          style={{ marginLeft: 2 }}
        />
      </Row>
    </Pressable>
  );
};

interface LocationProps {
  city?: string | null;
  location: Pick<MigrateLocation, "name" | "description">;
  mapLocation: MapLocation;
}

interface Layout {
  width: number;
  height: number;
  x: number;
  y: number;
}
interface JobLocationStore {
  mapViewLayout: Layout | null;
  setMapViewLayout: (mapViewRef: Layout | null) => void;
}
const useJobLocationStore = create<JobLocationStore>(
  (set: (values: Partial<JobLocationStore>) => void) => ({
    mapViewLayout: null,
    setMapViewLayout: (mapViewLayout) => set({ mapViewLayout }),
  })
);

const Location = ({ city, location, mapLocation }: LocationProps) => {
  const [open, setOpen] = useState(false);
  const mapViewRef = useRef<RNView>(null);
  const setMapViewLayout = useJobLocationStore(
    (state) => state.setMapViewLayout
  );

  return (
    <Section sx={{ mb: 0 }}>
      <SectionHeader>Location</SectionHeader>

      <View
        ref={mapViewRef}
        onLayout={() => {
          mapViewRef.current?.measure((_, __, width, height, x, y) =>
            setMapViewLayout({
              width,
              height,
              x,
              y,
            })
          );
        }}
        sx={{ borderRadius: 8, overflow: "hidden", mb: "$4" }}
      >
        <MapView
          style={{ borderRadius: 8, height: 250 }}
          initialRegion={{
            latitude: mapLocation.location.lat,
            longitude: mapLocation.location.lon,
            latitudeDelta: 0.5,
            longitudeDelta: 0.5,
          }}
          markerProps={{
            coordinate: {
              latitude: mapLocation.location.lat,
              longitude: mapLocation.location.lon,
            },
            pinColor: "red",
          }}
        />
      </View>
      {city ? (
        <P sx={{ alignSelf: "center", textAlign: "center", mb: "$6" }}>
          {city}
        </P>
      ) : null}

      <AnimateHeight hide={!open}>
        <H4 sx={{ mb: "$4" }}>Information about the county</H4>
        <P>{location.description}</P>
      </AnimateHeight>
      <Toggle onPress={() => setOpen(!open)} active={open} />
    </Section>
  );
};

export { Location, useJobLocationStore };
