import { memo } from "react";
import { pagesPath } from "app/lib/$path";
import { ScreenComponent } from "app/navigation/types";
import { InboxChannels } from "./inbox-screen";
import { ChannelContainer, ChannelLayout } from "./channel-layout";
import { ChannelScreen } from "./channel-screen";
import { InboxChannelHeader } from "./channel-header/inbox";

const InboxChannelScreen: ScreenComponent = memo(
  () => (
    <ChannelContainer channelList={<InboxChannels />}>
      <ChannelScreen HeaderComponent={InboxChannelHeader} />
    </ChannelContainer>
  ),
  () => true
);

const headerProps = {
  title: "Inbox",
  alwaysShowHeader: true,
  backHref: pagesPath.$url(),
  headerSx: {
    boxShadow: [null, "default"],
  },
};

InboxChannelScreen.displayName = "InboxChannelScreen";
InboxChannelScreen.headerProps = headerProps;
InboxChannelScreen.getLayout = (page: React.ReactElement) => {
  return (
    <ChannelLayout headerProps={headerProps} overridePathname="inbox">
      {page}
    </ChannelLayout>
  );
};

export { InboxChannelScreen };
