import { Row, useDripsyTheme } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { gql } from "@apollo/client";
import { add, format } from "date-fns";
import { useModal } from "react-native-modalfy";
import {
  useMuteChannelMutation,
  useUnmuteChannelMutation,
} from "app/types/generated/schema";
import { TooltipMenuTriggerIconButton } from "app/components/menu-trigger";
import { Menu } from "app/components/menu";
import { useAsyncToast } from "app/components/use-toast";

interface CommunityChannelHeaderMenuProps {
  channelId: string;
  mutedUntil?: string;
}

const MUTE_CHANNEL_MUTATION = gql`
  mutation MuteChannel($input: MuteChannelInput!) {
    muteChannel(input: $input) {
      id
      mutedUntil
    }
  }
`;

const UNMUTE_CHANNEL_MUTATION = gql`
  mutation UnmuteChannel($input: UnmuteChannelInput!) {
    unmuteChannel(input: $input) {
      id
      mutedUntil
    }
  }
`;

const CommunityChannelHeaderMenu = ({
  channelId,
  mutedUntil,
}: CommunityChannelHeaderMenuProps) => {
  const { theme } = useDripsyTheme();
  const { openModal } = useModal();

  const [muteChannel] = useMuteChannelMutation();
  const [unmuteChannel] = useUnmuteChannelMutation();

  const withMuteToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't mute channel",
    },
  });

  const withUnmuteToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't unmute channel",
    },
  });

  const handleMute = (minutes: number | null) =>
    withMuteToast(async () => {
      const until = minutes ? add(new Date(), { minutes }) : null;
      await muteChannel({
        variables: { input: { channelId, until } },
        optimisticResponse: {
          muteChannel: {
            __typename: "CommunityPublicChannel",
            id: channelId,
            mutedUntil: until ? until.toISOString() : null,
          },
        },
      });
    });

  const handleUnmute = withUnmuteToast(async () => {
    await unmuteChannel({
      variables: { input: { channelId } },
      optimisticResponse: {
        unmuteChannel: {
          __typename: "CommunityPublicChannel",
          id: channelId,
          mutedUntil: null,
        },
      },
    });
  });

  return (
    <Row>
      <Menu
        trigger={(triggerProps) => (
          <TooltipMenuTriggerIconButton
            variant="ghost"
            containerSx={{ alignSelf: "flex-start" }}
            size="sm"
            icon={
              <Feather
                name={!!mutedUntil ? "bell-off" : "bell"}
                size={20}
                color={theme.colors.$black}
              />
            }
            label={mutedUntil ? "Unmute this channel" : "Mute this channel"}
            {...triggerProps}
          />
        )}
        placement="bottom right"
      >
        {!!mutedUntil ? (
          <Menu.Group
            title={`Muted until ${format(
              new Date(mutedUntil),
              "dd/MM h:mm a"
            )}`}
          >
            <Menu.Item onPress={handleUnmute}>Unmute this channel</Menu.Item>
          </Menu.Group>
        ) : (
          <Menu.Group title="Mute notifications">
            <Menu.Item onPress={handleMute(15)}>For 15 minutes</Menu.Item>
            <Menu.Item onPress={handleMute(60)}>For 1 hour</Menu.Item>
            <Menu.Item onPress={handleMute(8 * 60)}>For 8 hours</Menu.Item>
            <Menu.Item onPress={handleMute(24 * 60)}>For 24 hours</Menu.Item>
            <Menu.Item onPress={handleMute(null)}>
              Until I turn it back on
            </Menu.Item>
          </Menu.Group>
        )}
      </Menu>

      <Menu
        trigger={(triggerProps) => (
          <TooltipMenuTriggerIconButton
            variant="ghost"
            containerSx={{ alignSelf: "flex-start" }}
            size="sm"
            icon={
              <Feather name="settings" size={20} color={theme.colors.$black} />
            }
            label="Settings"
            {...triggerProps}
          />
        )}
        placement="bottom right"
      >
        <Menu.Item
          onPress={() =>
            openModal("LeaveChannelModal", {
              channelId,
            })
          }
        >
          Leave channel
        </Menu.Item>
      </Menu>
    </Row>
  );
};

export {
  MUTE_CHANNEL_MUTATION,
  UNMUTE_CHANNEL_MUTATION,
  CommunityChannelHeaderMenu,
};
