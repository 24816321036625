import { gql } from "@apollo/client";
import { Text, useDripsyTheme } from "dripsy";
import { Skeleton } from "native-base";
import { useOverviewCommunityChannelCardQuery } from "app/types/generated/schema";
import { PressableCardProps } from "app/components/pressable-card";
import { QueryWrapper } from "app/components/query-wrapper";
import { Card, ErrorCard, HeaderContainer, PressableCard } from "./card";
import {
  CommunityChannelCardHeader,
  CommunityChannelCardHeaderError,
  CommunityChannelCardHeaderErrorProps,
  CommunityChannelCardHeaderLoading,
  CommunityChannelCardHeaderProps,
} from "./community-channel-card-header";

export const OVERVIEW_COMMUNITY_CHANNEL_CARD_CHANNEL_FRAGMENT = gql`
  fragment OverviewCommunityChannelCardChannelProps on CommunityPublicChannel {
    id
    isMember
    description
    ...CommunityChannelCardHeaderChannelFragment
    members(_size: 2) {
      ...CommunityChannelCardHeaderMembersFragment
    }
    messages(_size: 2) {
      data {
        id
        author {
          ...CommunityChannelCardHeaderUserFragment
        }
      }
    }
  }
  ${CommunityChannelCardHeader.fragments.communityPublicChannel}
  ${CommunityChannelCardHeader.fragments.members}
  ${CommunityChannelCardHeader.fragments.user}
`;

export const OVERVIEW_COMMUNITY_CHANNEL_CARD_QUERY = gql`
  query OverviewCommunityChannelCard($channelId: MigrateID!) {
    communityPublicChannel(id: $channelId) {
      ...OverviewCommunityChannelCardChannelProps
    }
  }
  ${OVERVIEW_COMMUNITY_CHANNEL_CARD_CHANNEL_FRAGMENT}
`;

const ChannelInfoLoading = () => (
  <>
    <HeaderContainer>
      <CommunityChannelCardHeaderLoading />
    </HeaderContainer>
    <Skeleton.Text lines={2} />
  </>
);

const OverviewChannelCardLoading = () => {
  return (
    <Card>
      <ChannelInfoLoading />
    </Card>
  );
};
interface OverviewChannelCardErrorProps
  extends CommunityChannelCardHeaderErrorProps {}

const OverviewChannelCardError: React.FC<OverviewChannelCardErrorProps> = ({
  message,
}) => {
  const { theme } = useDripsyTheme();
  return (
    <ErrorCard>
      <HeaderContainer>
        <CommunityChannelCardHeaderError message={message} />
      </HeaderContainer>
      <Text sx={{ color: theme.colors.$error }}>
        Please try refreshing or contact support.
      </Text>
    </ErrorCard>
  );
};

export interface OverviewChannelCardProps {
  channelId: string;
  onPress?: () => void;
  href?: PressableCardProps["href"];
  headerProps?: Omit<CommunityChannelCardHeaderProps, "channelId">;
}

const OverviewChannelCard = ({
  channelId,
  onPress,
  href,
  headerProps,
}: OverviewChannelCardProps) => {
  const queryResponse = useOverviewCommunityChannelCardQuery({
    variables: { channelId },
  });
  return (
    <QueryWrapper
      queryHookResult={queryResponse}
      loaderComponent={() => <OverviewChannelCardLoading />}
    >
      {({
        data: {
          communityPublicChannel: { id, description },
        },
      }) => {
        return (
          <PressableCard href={href} onPress={onPress}>
            <HeaderContainer>
              <CommunityChannelCardHeader channelId={id} {...headerProps} />
            </HeaderContainer>
            <Text>{description}</Text>
          </PressableCard>
        );
      }}
    </QueryWrapper>
  );
};

OverviewChannelCard.fragments = {
  communityPublicChannel: OVERVIEW_COMMUNITY_CHANNEL_CARD_CHANNEL_FRAGMENT,
};

export {
  OverviewChannelCard,
  OverviewChannelCardError,
  OverviewChannelCardLoading,
  ChannelInfoLoading,
};
