import { Sx } from "dripsy";
import { Skeleton } from "native-base";
import { Bubble } from "../bubble";
import { MessageContainer } from "../message-container";

interface UserMessageLoadingProps {
  sx?: Sx;
  isSameThread?: boolean;
  lines?: number;
}

const UserMessageLoading = ({
  isSameThread,
  sx,
  lines = 3,
}: UserMessageLoadingProps) => {
  return (
    <MessageContainer messagePosition="right" isSameThread={isSameThread}>
      <Bubble
        variant="user"
        sx={sx}
        notchPosition="right"
        showNotch={!isSameThread}
      >
        <Skeleton.Text
          startColor="rgba(255, 255, 255, .3)"
          endColor="rgba(255, 255, 255, .1)"
          my={2}
          lines={lines}
        />
      </Bubble>
    </MessageContainer>
  );
};

export type { UserMessageLoadingProps };
export { UserMessageLoading };
