import { styled, Sx } from "dripsy";
// @ts-ignore @types/emoji-utils package doesn't exist
import emojiUtils from "emoji-utils";
import { HyperlinkText } from "app/components/hyperlink-text";
import { Text } from "app/components/text";
import { Message } from "app/types";

const PureEmojiMessageText = styled(Text)({
  fontSize: 28,
  lineHeight: 30,
});

interface MessageContentProps {
  data: Message["content"];
  sx?: Sx;
}

const MessageContent = ({ data, sx }: MessageContentProps) => {
  if (emojiUtils.isPureEmojiString(data)) {
    <PureEmojiMessageText sx={sx}>{data}</PureEmojiMessageText>;
  }

  return (
    <HyperlinkText openInNewWindow sx={sx}>
      {data}
    </HyperlinkText>
  );
};

export { MessageContent };
