import { gql } from "@apollo/client";
import { H4, View } from "dripsy";
import { useCommunityChannelFooterQuery } from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import { ChatFooterProps } from "app/features/chat";
import { ChatFooter, ChatFooterWrapper } from "app/features/chat/footer";
import { JoinChannelButton } from "./join-channel-button";

const COMMUNITY_CHANNEL_HEADER_QUERY = gql`
  query CommunityChannelFooter($channelId: MigrateID!) {
    communityPublicChannel(id: $channelId) {
      id
      isMember
    }
  }
`;

interface CommunityChannelFooterProps extends ChatFooterProps {}

const CommunityChannelFooter = ({ channelId }: CommunityChannelFooterProps) => {
  const { data, loading, error } = useCommunityChannelFooterQuery({
    variables: { channelId },
  });

  if (loading) return <ChatFooter channelId={channelId} disabled={true} />;
  if (error || !data) return <DataError />;
  if (!data.communityPublicChannel.isMember)
    return (
      <ChatFooterWrapper>
        <View sx={{ alignItems: "center", flex: 1 }}>
          <H4 sx={{ mb: "$2" }}>
            Join this channel to participate in the conversation
          </H4>
          <JoinChannelButton channelId={channelId} />
        </View>
      </ChatFooterWrapper>
    );

  return <ChatFooter channelId={channelId} disabled={loading} />;
};

export { CommunityChannelFooter, COMMUNITY_CHANNEL_HEADER_QUERY };
