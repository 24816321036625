import {
  sendAnalyticsEvent,
  setAnalyticsUser,
  reset,
} from "app/client/analytics";

export interface AuthAnalytics {
  accountCreated: () => void;
  loggedIn: (accountId: string) => void;
  loggedOut: () => void;
  /** Used to refresh with persistent storage on web */
  setUser: (accountId?: string | null) => void;
}

export const authAnalytics: AuthAnalytics = {
  accountCreated: () => {
    sendAnalyticsEvent("create account");
  },
  loggedIn: (accountId: string) => {
    setAnalyticsUser(accountId);
    sendAnalyticsEvent("log in");
  },
  loggedOut: () => {
    reset();
  },
  setUser: (accountId?: string | null) =>
    !!accountId ? setAnalyticsUser(accountId) : reset(),
};
