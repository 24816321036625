import { AffindaAPIGetResumeResponse } from "@affinda/affinda";
import { useModal } from "react-native-modalfy";
import { Button } from "app/design/button";

interface ImportFromCvButtonProps {
  handleImport: (cv: AffindaAPIGetResumeResponse) => void;
}

export const ImportFromCVButton = ({
  handleImport,
}: ImportFromCvButtonProps) => {
  const { openModal } = useModal();

  const promptUploadCV = () => {
    openModal("ImportFromCVModal", {
      onClose: handleImport,
    });
  };

  return (
    <Button
      containerSx={{ alignSelf: "flex-start", mr: "$3" }}
      onPress={promptUploadCV}
    >
      Import from CV
    </Button>
  );
};
