import { api, unauthorisedToken } from "app/client";
import { authStore, useAuthStore } from "./store";
import { authAnalytics } from "./analytics";
import { createAuthClient, AuthClient } from "./client";

/** For use with non-reactive components (e.g. Apollo Link) */
const authClient = createAuthClient({
  authStore,
  authAnalytics,
  unauthorizedToken: unauthorisedToken,
  url: api.auth,
});

const useAuth = () => {
  // Hide state managed by authClient
  const { isAuthenticated, reset } = useAuthStore();

  return {
    isAuthenticated,
    resetStore: reset,
    signup: authClient.signup,
    login: authClient.login,
    logout: authClient.logout,
    verifyAccount: authClient.verifyAccount,
    getAccessToken: authClient.getAccessToken,
    resetVerificationCode: authClient.resetVerificationCode,
    forgotPassword: authClient.forgotPassword,
    resetPasswordWithCode: authClient.resetPasswordWithCode,
  };
};

export type { AuthClient };
export { useAuth, authClient };
