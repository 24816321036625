import { ForwardedRef, forwardRef } from "react";
import { Pressable, View } from "react-native";
import { ITooltipProps, Tooltip } from "native-base";
import { Button, ButtonProps } from "app/design/button";
import { IconButton, IconButtonProps } from "app/design/icon-button";

interface MenuTriggerButtonProps extends ButtonProps {}

const MenuTriggerButton = forwardRef(
  (props: MenuTriggerButtonProps, ref: ForwardedRef<View>) => (
    // hack to work around menu refs not working with our buttons
    <Pressable ref={ref} disabled>
      <Button {...props} />
    </Pressable>
  )
);

MenuTriggerButton.displayName = "MenuTriggerButton";

interface MenuTriggerIconButtonProps extends IconButtonProps {
  accessibilityLabel: string;
}

const MenuTriggerIconButton = forwardRef(
  (props: MenuTriggerIconButtonProps, ref: ForwardedRef<View>) => (
    // hack to work around menu refs not working with our buttons
    <Pressable ref={ref} disabled>
      <IconButton {...props} />
    </Pressable>
  )
);

MenuTriggerIconButton.displayName = "MenuTriggerIconButton";

interface TooltipMenuTriggerIconButtonProps
  extends Omit<IconButtonProps, "accessibilityLabel"> {
  label: string;
  tooltipProps?: ITooltipProps;
}

const TooltipMenuTriggerIconButton = forwardRef(
  (
    { label, tooltipProps, ...rest }: TooltipMenuTriggerIconButtonProps,
    ref: ForwardedRef<View>
  ) => (
    <Tooltip {...tooltipProps} label={label}>
      <MenuTriggerIconButton ref={ref} accessibilityLabel={label} {...rest} />
    </Tooltip>
  )
);

TooltipMenuTriggerIconButton.displayName = "TooltipMenuTriggerIconButton";

export {
  MenuTriggerButton,
  MenuTriggerIconButton,
  TooltipMenuTriggerIconButton,
};
