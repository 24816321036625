import { cloneElement } from "react";
import {
  ColorAnimatedComponentProps,
  ColorAnimatedView,
} from "./color-animated-view";

interface ColorAnimatedIconProps extends ColorAnimatedComponentProps {
  icon: React.ReactElement;
}

const ColorAnimatedIcon: React.FC<ColorAnimatedIconProps> = ({
  icon,
  ...rest
}) => {
  const size = icon.props?.size || 18;

  return (
    <ColorAnimatedView
      as="Text"
      sx={{ height: size, lineHeight: size }}
      {...rest}
    >
      {cloneElement(icon, { color: undefined, size })}
    </ColorAnimatedView>
  );
};

export type { ColorAnimatedIconProps };
export { ColorAnimatedIcon };
