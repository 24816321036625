import { Row, Text, useDripsyTheme, View } from "dripsy";
import { cloneElement } from "react";
import { Skeleton } from "native-base";
import { gql } from "@apollo/client";
import { FCC } from "app/types/index";
import { useCommunityActivityQuery } from "app/types/generated/schema";
import { EditIcon } from "app/design/svgs/icons/edit";
import { MessageCircleIcon } from "app/design/svgs/icons/message-circle";
import { ThumbsUpFilledIcon } from "app/design/svgs/icons/thumbs-up";
import { DataError } from "app/components/data-error";
import { UserHeader, UserHeaderLoading } from "../questions/user-header";

export const CommunityActivityQuery = gql`
  query CommunityActivity {
    me {
      ...UserHeaderFragment
    }
    communityActivity {
      questionCount
      answerCount
      upvoteCount
    }
  }
  ${UserHeader.fragments.user}
`;

interface StatItemProps {
  icon: React.ReactElement;
  text: string | React.ReactElement;
}

const StatItem: React.FC<StatItemProps> = ({ icon, text }) => {
  const { theme } = useDripsyTheme();

  return (
    <Row sx={{ alignItems: "center", mt: "$2" }}>
      {cloneElement(icon, {
        fill: theme.colors.$blue,
        width: 24,
      })}

      <View sx={{ ml: "$2", flexGrow: 1 }}>
        {typeof text === "string" ? <Text variant="p">{text}</Text> : text}
      </View>
    </Row>
  );
};

const PROFILE_MARGIN_BOTTOM = "$6";

const StatsWrapper: FCC = ({ children }) => {
  const { theme } = useDripsyTheme();
  return <View sx={{ mt: 0 - theme.space.$2 }}>{children}</View>;
};

const ProfileStatsLoading: React.FC = () => {
  return (
    <>
      <UserHeaderLoading variant="lg" sx={{ mb: PROFILE_MARGIN_BOTTOM }} />

      <StatsWrapper>
        <StatItem
          icon={<MessageCircleIcon />}
          text={<Skeleton.Text lines={1} _line={{ width: 200 }} />}
        />
        <StatItem
          icon={<EditIcon />}
          text={<Skeleton.Text lines={1} _line={{ width: 150 }} />}
        />
        <StatItem
          icon={<ThumbsUpFilledIcon />}
          text={<Skeleton.Text lines={1} _line={{ width: 240 }} />}
        />
      </StatsWrapper>
    </>
  );
};

const ProfileStats: React.FC = () => {
  const { data, loading, error } = useCommunityActivityQuery();

  if (loading) return <ProfileStatsLoading />;

  if (error || !data)
    return <DataError title={"Couldn't retrieve user data"} />;

  const { me, communityActivity } = data;

  return (
    <>
      <UserHeader
        variant="lg"
        sx={{ mb: PROFILE_MARGIN_BOTTOM }}
        userId={me.id}
      />

      <StatsWrapper>
        <StatItem
          icon={<MessageCircleIcon />}
          text={`${communityActivity.answerCount} answers given`}
        />
        <StatItem
          icon={<EditIcon />}
          text={`${communityActivity.questionCount} questions asked`}
        />
        <StatItem
          icon={<ThumbsUpFilledIcon />}
          text={`${communityActivity.upvoteCount} upvotes received`}
        />
      </StatsWrapper>
    </>
  );
};

export { ProfileStats };
