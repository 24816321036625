// replace occurrences with dripsy text when fontWeight issue fixed
// https://github.com/nandorojo/dripsy/issues/206
import {
  StyledProps,
  Sx,
  SxProp,
  Text as DripsyText,
  Theme,
  useDripsyTheme,
} from "dripsy";
import { ComponentProps } from "react";
import { AccessibilityRole } from "react-native";
import { FCC } from "app/types/index";

interface TextProps
  extends Omit<ComponentProps<typeof DripsyText>, "accessibilityRole">,
    StyledProps<"text"> {
  sx?: Sx;
  accessibilityRole?: AccessibilityRole | "label";
  focusable?: boolean;
}

type Variant = keyof Theme["text"];

const getFontWeightFromTheme = (
  theme: Theme,
  variant: Variant
): Sx["fontWeight"] | undefined => {
  return (
    (variant &&
      theme.text[variant] &&
      // @ts-expect-error fontWeight not always in text themes
      theme.text[variant].fontWeight) ||
    undefined
  );
};

const getFontWeightFromSx = (
  theme: Theme,
  sx: SxProp
): Sx["fontWeight"] | undefined => {
  if (typeof sx === "function")
    throw new Error("getFontWeightFromSx not implemented for theme functions");

  if (sx.fontWeight) return sx.fontWeight;
  if (sx.variant) {
    const textVariant = sx.variant.split("text.")?.[1] as Variant | undefined;
    if (!textVariant) return undefined;
    return getFontWeightFromTheme(theme, textVariant);
  }

  return undefined;
};

const useFontWeightStyle = (
  sx: SxProp,
  variantOverride?: Variant
): Pick<Sx, "fontWeight"> => {
  const { theme } = useDripsyTheme();
  const fontWeight =
    getFontWeightFromSx(theme, sx) ||
    (variantOverride && getFontWeightFromTheme(theme, variantOverride)) ||
    "normal";

  return { fontWeight };
};

const Text: FCC<TextProps> = ({ sx = {}, children, ...rest }) => {
  const style = useFontWeightStyle(sx, rest.variant);
  return (
    // @ts-expect-error for modified AccessibilityRole type
    <DripsyText sx={sx} {...rest} style={style}>
      {children}
    </DripsyText>
  );
};

export type { TextProps };
export { Text, useFontWeightStyle };
