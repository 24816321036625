import { FontAwesome } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import { Actionsheet } from "native-base";
import { useDripsyTheme, Text } from "dripsy";
import { ExpoFile } from "app/hooks/helpers/upload-file";

export interface UploadFileActionSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectFile: (file: ExpoFile) => Promise<void>;
  type?: string | string[];
}

export const UploadFileActionSheet = ({
  isOpen,
  onClose,
  onSelectFile,
  type = "*",
}: UploadFileActionSheetProps) => {
  const { theme } = useDripsyTheme();

  const selectFile = (file: ExpoFile) => {
    onSelectFile(file);
    onClose();
  };

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        <Actionsheet.Item
          bg="white"
          startIcon={
            <FontAwesome name="photo" size={24} color={theme.colors.$blue} />
          }
          onPress={async () => {
            await ImagePicker.requestMediaLibraryPermissionsAsync();
            const document = await ImagePicker.launchImageLibraryAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
            });
            if (!document || document.cancelled === true) return;
            selectFile(document);
          }}
        >
          <Text>Photos</Text>
        </Actionsheet.Item>

        <Actionsheet.Item
          bg="white"
          startIcon={
            <FontAwesome name="camera" size={24} color={theme.colors.$blue} />
          }
          onPress={async () => {
            await ImagePicker.requestCameraPermissionsAsync();
            const document = await ImagePicker.launchCameraAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
            });
            if (!document || document.cancelled === true) return;
            selectFile(document);
          }}
        >
          <Text>Camera</Text>
        </Actionsheet.Item>

        <Actionsheet.Item
          bg="white"
          startIcon={
            <FontAwesome
              name="file"
              size={24}
              color={theme.colors.$blue}
              style={{ marginLeft: 3 }}
            />
          }
          onPress={async () => {
            const document = await DocumentPicker.getDocumentAsync({
              type,
            });
            if (!document || document.type === "cancel") return;
            selectFile(document);
          }}
        >
          <Text sx={{ ml: "$1" }}>Files</Text>
        </Actionsheet.Item>
      </Actionsheet.Content>
    </Actionsheet>
  );
};
