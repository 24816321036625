import { ToastCloseButton } from "app/components/use-toast";
import { NewMessagePayloadFragment } from "app/types";
import { ApplicationDMMessageCard } from "./application-dm-message";
import { CommunityPublicMessageCard } from "./community-public-message";

interface MessageNotificationProps {
  message: NewMessagePayloadFragment;
  onClose: () => void;
}

const MessageNotification = ({
  message,
  onClose,
  ...rest
}: MessageNotificationProps) => {
  const renderHeaderRight = () => (
    <ToastCloseButton onClose={onClose} status="info" ml={4} />
  );

  const props = {
    message,
    renderHeaderRight,
    onClose,
    ...rest,
  };

  if (message.channel.__typename === "ApplicationDMChannel")
    return <ApplicationDMMessageCard {...props} />;

  if (message.channel.__typename === "CommunityPublicChannel")
    return <CommunityPublicMessageCard {...props} />;

  return null;
};

export { MessageNotification };
