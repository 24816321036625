import { Platform } from "react-native";
import { alphaColor, darkenColor } from "app/design/utils";
import { brandColors } from "../colors";

const button = {
  primary: {
    backgroundColor: brandColors.blue,
    borderColor: brandColors.blue,
    color: brandColors.white,
    _hover: {
      borderColor: brandColors.blueHover,
      backgroundColor: brandColors.blueHover,
      color: brandColors.white,
    },
  },
  secondary: {
    backgroundColor: "transparent",
    borderColor: brandColors.black,
    color: brandColors.black,
    _hover: {
      borderColor: brandColors.black,
      backgroundColor: brandColors.black,
      color: brandColors.white,
    },
  },
  tertiary: {
    backgroundColor: alphaColor(brandColors.blue, 0),
    borderColor: brandColors.blue,
    color: brandColors.blue,
    _hover: {
      borderColor: brandColors.blue,
      backgroundColor: brandColors.blue,
      color: brandColors.white,
    },
  },
  danger: {
    backgroundColor: "#d3403e",
    borderColor: "#d3403e",
    color: brandColors.white,
    _hover: {
      borderColor: darkenColor("#d3403e", 40),
      backgroundColor: darkenColor("#d3403e", 40),
      color: brandColors.white,
    },
  },
  ui: {
    backgroundColor: "transparent",
    borderColor: brandColors.ui,
    color: brandColors.black,
    borderWidth: 1,
    _hover: {
      borderColor: brandColors.ui,
      backgroundColor: "transparent",
      color: brandColors.orange,
    },
  },
  card: {
    backgroundColor: alphaColor(brandColors.white, 0.75),
    borderColor: brandColors.white,
    color: brandColors.black,
    boxShadow: "default",
    backdropFilter: Platform.OS === "web" ? "blur(5px)" : undefined,
    _hover: {
      borderColor: brandColors.white,
      backgroundColor: alphaColor(brandColors.white, 1),
      color: brandColors.orange,
    },
  },
  cardToggle: {
    backgroundColor: brandColors.orange,
    borderColor: brandColors.orange,
    color: brandColors.black,
    boxShadow: "default",
    backdropFilter: Platform.OS === "web" ? "blur(5px)" : undefined,
    _hover: {
      borderColor: brandColors.orange,
      backgroundColor: brandColors.orange,
      color: brandColors.black,
    },
  },
  ghost: {
    backgroundColor: "transparent",
    color: brandColors.black,
    borderColor: "transparent",
    borderWidth: 0,
    _hover: {
      borderColor: "transparent",
      backgroundColor: "transparent",
      color: brandColors.orange,
    },
  },
  light: {
    backgroundColor: brandColors.white,
    borderColor: brandColors.white,
    color: brandColors.black,
    _hover: {
      borderColor: brandColors.white,
      backgroundColor: brandColors.white,
      color: brandColors.blue,
    },
  },
  ghostLight: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: alphaColor(brandColors.white, 0.75),
    borderWidth: 0,
    _hover: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: brandColors.white,
    },
  },
  ghostError: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: brandColors.error,
    borderWidth: 0,
    _hover: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: brandColors.error,
    },
  },
  link: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: brandColors.blue,
    borderWidth: 0,
    _hover: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: brandColors.orange,
    },
  },
  text: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: brandColors.text,
    borderWidth: 0,
    _hover: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: brandColors.black,
    },
  },
  homeToggle: {
    borderColor: brandColors.white,
    backgroundColor: brandColors.white,
    color: brandColors.black,
    _checked: {
      backgroundColor: brandColors.blue,
      borderColor: brandColors.blue,
      color: brandColors.white,
    },
    _hover: {
      backgroundColor: brandColors.white,
      borderColor: brandColors.white,
      color: brandColors.blue,
    },
  },
  uiToggle: {
    borderColor: brandColors.ui,
    backgroundColor: brandColors.white,
    color: brandColors.black,
    borderWidth: 1,
    _checked: {
      backgroundColor: brandColors.blue,
      borderColor: brandColors.blue,
      color: brandColors.white,
    },
    _hover: {
      borderColor: brandColors.ui,
      backgroundColor: brandColors.white,
      color: brandColors.blue,
    },
  },
  sizes: {
    xs: {
      px: 6,
      py: 2,
      fontSize: 12,
    },
    sm: {
      px: 8,
      py: 4,
      fontSize: 14,
    },
    md: {
      px: 24,
      py: 6,
      fontSize: 16,
    },
    lg: {
      px: 24,
      py: 12,
      fontSize: [16, 18],
    },
  },
};

export { button };
