import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { MarkerProps, MapViewProps as RNMapViewProps } from "react-native-maps";
import { MAPS_KEY } from "app/client";

const MapWithAMarker = withScriptjs(
  withGoogleMap(({ initialRegion, markerProps }) => (
    // @ts-expect-error this package sux!
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{
        lat: initialRegion.latitude,
        lng: initialRegion.longitude,
      }}
    >
      <Marker
        position={{
          lat: markerProps.coordinate.latitude,
          lng: markerProps.coordinate.longitude,
        }}
      />
    </GoogleMap>
  ))
);

interface MapViewProps extends RNMapViewProps {
  markerProps: MarkerProps;
}

export const MapView = ({ style, ...rest }: MapViewProps) => (
  <MapWithAMarker
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    // @ts-expect-error this package sux!
    containerElement={<div style={style} />}
    mapElement={<div style={{ height: `100%` }} />}
    {...rest}
  />
);
