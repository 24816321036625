import { useReplyToMessage } from "../../hooks/use-reply-to-message";
import { ChannelMessage } from "../../../types";
import { MessageAction } from "./message-action-item";

interface ReplyToMessageActionProps {
  message: ChannelMessage;
}

const ReplyToMessageAction = ({ message }: ReplyToMessageActionProps) => {
  const replyToMessage = useReplyToMessage({ message });
  return (
    <MessageAction
      label="Reply"
      iconName="corner-down-left"
      onPress={replyToMessage}
    />
  );
};

export type { ReplyToMessageActionProps };
export { ReplyToMessageAction };
