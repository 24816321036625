import { H5, View } from "dripsy";
import { Skeleton } from "native-base";
import { Card } from "app/design/card";
import { ButtonLink } from "app/design/button";
import { DataError } from "app/components/data-error";
import { Text } from "app/components/text";
import { FCC, QuestionQuery, Scalars, useQuestionsQuery } from "app/types";
import { pagesPath } from "app/lib/$path";

const RelatedQuestionsWrapper: FCC = ({ children }) => (
  <Card sx={{ mb: "$5" }}>
    <H5 sx={{ mb: "$4" }}>Related questions</H5>
    {children}
  </Card>
);

const RelatedQuestionsLoading = () => (
  <RelatedQuestionsWrapper>
    <Skeleton.Text lines={3} />
  </RelatedQuestionsWrapper>
);

interface RelatedQuestionsProps {
  topics?: QuestionQuery["question"]["topics"];
  currentQuestionId: Scalars["MigrateID"];
}

const RelatedQuestions = ({
  topics = [],
  currentQuestionId,
}: RelatedQuestionsProps) => {
  const { data, loading, error } = useQuestionsQuery({
    variables: {
      size: 4,
      where: {
        topic: {
          in: topics.map(({ id }) => id),
        },
      },
    },
    skip: topics.length === 0,
  });

  if (loading) return <RelatedQuestionsLoading />;
  if (error) return <DataError sx={{ mb: "$5" }} />;

  const relatedQuestions =
    data?.questions.data.filter(({ id }) => id !== currentQuestionId) || [];

  if (relatedQuestions.length === 0)
    return (
      <RelatedQuestionsWrapper>
        <Text>No related questions found!</Text>
      </RelatedQuestionsWrapper>
    );

  return (
    <RelatedQuestionsWrapper>
      {relatedQuestions.map((question, i) => (
        <View
          key={question.id}
          sx={{
            flexShrink: 1,
            borderColor: "$ui",
            alignItems: "flex-start",
            ...(i !== 0 && {
              mt: "$2",
              pt: "$2",
              borderTopWidth: 1,
            }),
          }}
        >
          <ButtonLink
            variant="text"
            size="sm"
            href={pagesPath.community.questions._questionId(question.id).$url()}
            textSx={{ justifyContent: "flex-start", textAlign: "left" }}
            truncateText={false}
          >
            {question.title.replace(/\n/g, "")}
          </ButtonLink>
        </View>
      ))}
    </RelatedQuestionsWrapper>
  );
};

export { RelatedQuestions, RelatedQuestionsLoading };
