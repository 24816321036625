import { FieldValues, Path } from "react-hook-form";

import { ToggleControl } from "app/components/form/toggle-control";
import { StepComponentProps } from "app/components/step-screen-form";
import { StepHeading } from "app/features/onboarding/steps/heading";
import { getMinLanguageTestRequirements, yesNoOptions } from "../constants";
import { EnglishLanguageOption } from "./language";
import { CareerQuizStepContainer, CareerQuizOption } from "./container";

interface MinimumRequirementsStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
  professionId: string;
  engLang: EnglishLanguageOption;
}

const MinimumRequirementsStep = <TFieldValues extends FieldValues>({
  control,
  name,
  professionId,
  engLang,
}: MinimumRequirementsStepProps<TFieldValues>) => {
  if (engLang === EnglishLanguageOption.NA) {
    throw new Error("English language option is not set");
  }
  if (engLang === EnglishLanguageOption.EXEMPT) {
    throw new Error(
      "English language exemption should skip minimum requirements"
    );
  }

  return (
    <>
      <StepHeading>
        {getMinLanguageTestRequirements(professionId, engLang)}
      </StepHeading>
      <CareerQuizStepContainer>
        {yesNoOptions.map(([value, text]) => (
          <CareerQuizOption key={value}>
            <ToggleControl
              control={control}
              name={name}
              checkedValue={value}
              rules={{ required: true }}
            >
              {text}
            </ToggleControl>
          </CareerQuizOption>
        ))}
      </CareerQuizStepContainer>
    </>
  );
};

export { MinimumRequirementsStep };
