import { H1 as DripsyH1, Row, styled, Sx, View } from "dripsy";
import { ReactNode } from "react";

const Container = styled(Row)({
  alignItems: "center",
  flexWrap: "wrap",
  mb: "$6",
});

const H1 = styled(DripsyH1)({
  mb: 0,
});

const Divider = styled(View)({
  borderTopWidth: 1,
  borderColor: "$ui",
  flex: 1,
  ml: "$6",
  display: ["none", null, "flex"],
});

interface ScreenHeaderProps {
  children: ReactNode;
  showDivider?: boolean;
  sx?: Sx;
}

const ScreenHeader = ({
  sx = {},
  children,
  showDivider = true,
}: ScreenHeaderProps) => (
  <Container sx={sx}>
    <H1>{children}</H1>
    {showDivider ? <Divider /> : null}
  </Container>
);

ScreenHeader.Divider = Divider;
ScreenHeader.H1 = H1;
ScreenHeader.Container = Container;

export type { ScreenHeaderProps };
export { ScreenHeader };
