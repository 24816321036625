import { useBreakpoints } from "dripsy";
import { useWindowDimensions } from "react-native";

export const useScreenSize = () => {
  const { width } = useWindowDimensions();
  const [mobileBreakpoint, tabletBreakpoint, desktopBreakpoint] =
    useBreakpoints();

  if (!mobileBreakpoint || !tabletBreakpoint || !desktopBreakpoint)
    throw new Error("Invalid breakpoint configuration");

  const isMobile = width < mobileBreakpoint;
  const isTablet = width >= mobileBreakpoint && width < tabletBreakpoint;
  const isAtLeastTablet = width >= mobileBreakpoint;
  const isDesktop = width >= tabletBreakpoint && width < desktopBreakpoint;
  const isAtLeastDesktop = width >= tabletBreakpoint;
  const isWide = width >= desktopBreakpoint;

  return {
    isMobile,
    isTablet,
    isAtLeastTablet,
    isDesktop,
    isAtLeastDesktop,
    isWide,
  };
};
