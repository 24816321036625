import { Platform, useWindowDimensions } from "react-native";
import { ScrollView, useDripsyTheme, View } from "dripsy";

import { useQuestionsQuery } from "app/types/generated/schema";
import { FCC } from "app/types/index";
import { useThemeValue } from "app/hooks/use-theme-value";
import { DataError } from "app/components/data-error";
import { pagesPath } from "app/lib/$path";
import { useScreenSize } from "app/hooks";

import { PressableQuestionCardSummary, QuestionCardLoading } from "./question";
import { TrendingQuestionsMask } from "./trending-questions-mask";

const MOBILE_CARD_WIDTH_MODIFER = 0.7;
const TRENDING_QUESTIONS_COUNT = 3;

const QuestionsWrapper: FCC = ({ children }) => {
  const { width } = useWindowDimensions();
  const { theme } = useDripsyTheme();
  const { isMobile } = useScreenSize();
  const offsetWidth = isMobile
    ? width * MOBILE_CARD_WIDTH_MODIFER
    : (theme.layout.screen.body.maxWidth + theme.space.$4) / 3;
  const screenPadding = useThemeValue(
    (themeValues) => themeValues.layout.screen.container.padding
  );

  return (
    <TrendingQuestionsMask>
      {/* @ts-expect-error Dripsy incompatabilities */}
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerSx={{
          pr: screenPadding,
          pl: screenPadding - theme.space.$4,
        }}
        sx={{
          // This avoids scrollview collapsing on initial render
          // https://stackoverflow.com/questions/63148495/react-native-scrollview-height-issues
          flexGrow: 0,
          scrollPadding:
            Platform.OS === "web" ? screenPadding - theme.space.$4 : null,
        }}
        pagingEnabled
        {...(Platform.OS !== "web" && {
          snapToOffsets: Array.from(
            { length: TRENDING_QUESTIONS_COUNT },
            (_, i) => i * offsetWidth
          ),
        })}
      >
        {children}
      </ScrollView>
    </TrendingQuestionsMask>
  );
};

export const TrendingQuestions = () => {
  const { width } = useWindowDimensions();
  const { theme } = useDripsyTheme();
  const { data, loading, error } = useQuestionsQuery({
    variables: {
      size: TRENDING_QUESTIONS_COUNT,
    },
  });

  /**
   * on web, pagingEnabled ScrollViews snap to the start of elements,
   * so we need to add additional left margin to prevent the card from
   * snapping to a position outside the container's padding
   */
  const questionItemSx = {
    pl: "$4",
    flex: 1,
    width: [
      width * MOBILE_CARD_WIDTH_MODIFER,
      (theme.layout.screen.body.maxWidth + theme.space.$4) / 3,
    ],
  };

  if (loading)
    return (
      <QuestionsWrapper>
        {Array.from({ length: TRENDING_QUESTIONS_COUNT }).map((_, i) => (
          <View key={i} sx={questionItemSx}>
            <QuestionCardLoading variant="tertiary" />
          </View>
        ))}
      </QuestionsWrapper>
    );

  if (error || !data)
    return <DataError title={"Couldn't load latest questions"} />;

  return (
    <QuestionsWrapper>
      {data.questions.data.map((question) => (
        <View key={question.id} sx={questionItemSx}>
          <PressableQuestionCardSummary
            variant="tertiary"
            href={pagesPath.community.questions._questionId(question.id).$url()}
            title={question.title}
            author={question.author}
            titleSx={{ minHeight: theme.h4.lineHeight * 2, flex: 1 }}
            createdAt={question.createdAt}
            answers={question.answers}
            containerSx={{ flex: 1 }}
            sx={{ flex: 1 }}
          />
        </View>
      ))}
    </QuestionsWrapper>
  );
};
