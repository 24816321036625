import { alphaColor } from "app/design/utils";

const brandColors = {
  white: "#fff",
  black: "#3A2F2E",
  blue: "#305ED8",
  blueHover: "#1a3e97",
  cream: "#FFF2EE",
  orange: "#FF9100",
  pink: "#FFB4A3",
  grey: "#C2B5BB",
  ui: alphaColor("#3A2F2E", 0.1),
  uiBg: alphaColor("#ece8e6", 0.9),
  highlight: "#FEDCCC",
  highlightHover: "#FFCFB8",
  text: alphaColor("#3A2F2E", 0.7),
  error: "#EB7372",
};

const avatarColorOptions = [
  brandColors.pink,
  brandColors.orange,
  brandColors.grey,
  "#a4b0f7",
  "#94bfda",
  "#da6f6f",
  "#8cbf89",
  "#bc96dd",
  "#e0d30a",
];

// in case we want to customise this
const organisationColorOptions = avatarColorOptions;

const themeColors = {
  $white: brandColors.white,
  $black: brandColors.black,
  "$black-70": alphaColor(brandColors.black, 0.7),
  $blue: brandColors.blue,
  "$blue-tint-1": "#5C80E0",
  "$blue-tint-2": "#98a8e3",
  $blueHover: brandColors.blueHover,
  $cream: brandColors.cream,
  $orange: brandColors.orange,
  "$orange-tint-1": "#fdc277",
  $pink: brandColors.pink,
  "$pink-shade-1": "#FF8B70",
  $grey: brandColors.grey,
  "$grey-shade-1": "#AC9AA3",
  "$grey-shade-2": "#957E89",
  $header: brandColors.black,
  $text: alphaColor(brandColors.black, 0.7),
  $input: "#707070",
  $error: brandColors.error,
  $success: "#48BB78",
  $muted: alphaColor(brandColors.black, 0.15),
  $highlight: brandColors.highlight,
  $highlightAlpha: "rgba(255, 220, 205, .85)",
  $ui: alphaColor(brandColors.black, 0.15),
  $uiAlpha: "rgba(229, 229, 229, .2)",
  $uiBg: brandColors.uiBg,
  $placeholder: "#A3A3A3",
};

export {
  brandColors,
  avatarColorOptions,
  organisationColorOptions,
  themeColors,
};
