import { ReactNode } from "react";
import { Row, Sx } from "dripsy";
import { isAfter } from "date-fns";
import {
  LanguageResultAwardingBody,
  LanguageResultSchema,
} from "app/types/generated/schema";
import { Button } from "app/design/button";
import { DropdownControl } from "app/components/form/dropdown-control";
import { DatePickerControl } from "app/components/form/date-picker-control";
import { useYupForm, yup } from "app/utils/yup";
import { useScreenSize } from "app/hooks";
import { useProfileCardContext } from "../../card/context";
import { useLanguageResultMutations } from "../hooks/use-language-result-mutations";
import { generateTempId, isTempField, ProfileForm } from "../profile-form";
import { LanguageResultInput } from "./result-input";

interface LanguageResultFormValues {
  id: string;
  awardingBody: LanguageResultAwardingBody | null;
  date: string;
  listening: string;
  reading: string;
  writing: string;
  speaking: string;
}

interface LanguageResultFormCardProps {
  defaultValues: LanguageResultFormValues;
  onCancel: () => void;
  resultSchema?: LanguageResultSchema;
  mode?: "create" | "update";
}

const DEFAULT_VALUE = {
  awardingBody: null,
  date: "",
  listening: "",
  reading: "",
  writing: "",
  speaking: "",
};

const schema = yup.object({
  awardingBody: yup.string().required().label("Awarding body"),
  date: yup
    .string()
    .required()
    .label("Date")
    .test("is_future", "Date must not be in the future", function (value) {
      if (!value) return true;
      return !isAfter(new Date(value), new Date());
    }),
  listening: yup.string().required().label("Listening"),
  reading: yup.string().required().label("Reading"),
  writing: yup.string().required().label("Writing"),
  speaking: yup.string().required().label("Speaking"),
});

const LanguageResultFormCard = ({
  onCancel,
  defaultValues,
  resultSchema,
}: LanguageResultFormCardProps) => {
  const { setShowForm } = useProfileCardContext();
  const { createLanguageResult, updateLanguageResult } =
    useLanguageResultMutations();
  const { isAtLeastDesktop } = useScreenSize();

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useYupForm<LanguageResultFormValues>({
    schema,
    defaultValues,
  });

  const [fieldId, awardingBody] = watch(["id", "awardingBody"]);
  const isUpdating = !isTempField({ id: fieldId });

  const onClose = () => {
    if (isUpdating) {
      setShowForm(false);
      return;
    }
    onCancel();
  };

  const onSubmit = async (values: LanguageResultFormValues) => {
    const { id, ...createInput } = values;

    try {
      if (isUpdating) {
        if (!id) throw new Error("language result: id is required for update");

        await updateLanguageResult({
          variables: {
            input: {
              ...createInput,
              id,
            },
          },
        });
      } else {
        if (!createInput.awardingBody)
          throw new Error(
            "language result: awarding body is required for create"
          );

        await createLanguageResult({
          variables: {
            input: {
              ...createInput,
              awardingBody: createInput.awardingBody,
            },
          },
        });
      }

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  // 2 columns on desktop and above, 1 column otherwise
  // ...i'm too dumb to do it with flex
  const InputWrapper = ({ children, sx }: { children: ReactNode; sx: Sx }) =>
    isAtLeastDesktop ? <Row sx={sx}>{children}</Row> : <>{children}</>;

  return (
    <ProfileForm label="language result" onCancel={onClose}>
      <InputWrapper sx={{ mb: ["$0", "$0", "$3"] }}>
        <DropdownControl
          control={control}
          name="awardingBody"
          dropdownProps={{
            label: "Awarding body",
            containerSx: {
              flex: [, , 0.5],
              mb: ["$3", "$3", "$0"],
              mr: ["$0", "$0", "$4"],
            },
            options: [
              { label: "IELTS", value: LanguageResultAwardingBody.Ielts },
              { label: "OET", value: LanguageResultAwardingBody.Oet },
            ],
            placeholder: "Select awarding body",
          }}
        />

        <DatePickerControl
          control={control}
          name="date"
          inputProps={{
            label: "Date",
            containerSx: { flex: [, , 0.5], mb: ["$3", "$3", "$0"] },
            placeholder: "Select date",
          }}
        />
      </InputWrapper>

      {awardingBody ? (
        <>
          <InputWrapper sx={{ mb: ["$0", "$0", "$3"] }}>
            <LanguageResultInput
              name="listening"
              control={control}
              resultSchema={resultSchema}
              awardingBody={awardingBody}
              sx={{ mr: ["$0", "$0", "$4"] }}
            />

            <LanguageResultInput
              name="reading"
              control={control}
              resultSchema={resultSchema}
              awardingBody={awardingBody}
            />
          </InputWrapper>

          <InputWrapper sx={{ mb: ["$0", "$0", "$3"] }}>
            <LanguageResultInput
              name="writing"
              control={control}
              resultSchema={resultSchema}
              awardingBody={awardingBody}
              sx={{ mr: ["$0", "$0", "$4"] }}
            />

            <LanguageResultInput
              name="speaking"
              control={control}
              resultSchema={resultSchema}
              awardingBody={awardingBody}
            />
          </InputWrapper>
        </>
      ) : null}

      <Row sx={{ alignSelf: "flex-end", mt: "$3", mb: "$2" }}>
        <Button
          variant="secondary"
          containerSx={{ mr: "$3" }}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
          Save
        </Button>
      </Row>
    </ProfileForm>
  );
};

LanguageResultFormCard.createInstance = () => ({
  id: generateTempId("language-result"),
  ...DEFAULT_VALUE,
});

export type { LanguageResultFormCardProps, LanguageResultFormValues };
export { LanguageResultFormCard };
