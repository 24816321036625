import { memo } from "react";
import { pagesPath } from "app/lib/$path";
import { ScreenComponent } from "app/navigation/types";
import {
  CommunityChannelsListHeader,
  MyCommunityChannels,
  MyCommunityChannelsScreen,
} from "./my-community-channels-screen";
import { ChannelContainer, ChannelLayout } from "./channel-layout";
import { ChannelScreen } from "./channel-screen";
import { CommunityChannelHeader } from "./channel-header";
import { CommunityChannelFooter } from "./community-channel-footer";

const CommunityChannelScreen: ScreenComponent = memo(
  () => (
    <ChannelContainer
      channelList={<MyCommunityChannels />}
      channelListHeader={<CommunityChannelsListHeader />}
    >
      <ChannelScreen
        HeaderComponent={CommunityChannelHeader}
        FooterComponent={CommunityChannelFooter}
      />
    </ChannelContainer>
  ),
  () => true
);

const headerProps = {
  title: "My channels",
  backHref: pagesPath.community.$url(),
  headerSx: MyCommunityChannelsScreen.headerProps?.headerSx,
};

CommunityChannelScreen.displayName = "CommunityChannelScreen";
CommunityChannelScreen.headerProps = headerProps;
CommunityChannelScreen.getLayout = (page: React.ReactElement) => {
  return (
    <ChannelLayout headerProps={headerProps} overridePathname="my-channels">
      {page}
    </ChannelLayout>
  );
};

export { CommunityChannelScreen };
