import { styled, View } from "dripsy";

const Divider = styled(View)({
  alignSelf: "stretch",
  borderTopWidth: 1,
  borderColor: "$white",
  mt: "$6",
});
const DividerSecondary = styled(Divider)({ borderColor: "$ui" });

export { Divider, DividerSecondary };
