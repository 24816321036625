import { useApolloClient, Reference, gql } from "@apollo/client";
import {
  Maybe,
  MessageFieldsFragment,
  Scalars,
  useUploadMessageMutation,
} from "app/types/generated/schema";
import { AnyFile } from "app/hooks/helpers/upload-file";
import { toCacheReference } from "app/utils";
import { useMessageStore } from "./use-message-store";
import { useUploadAttachment } from "./use-upload-attachment";
import { useSetMessageError } from "./use-set-message-error";

const MESSAGE_AUTHOR_FIELDS_FRAGMENT = gql`
  fragment MessageAuthorFields on User {
    id
    isActive
    displayName
    photoUrl
  }
`;

const MESSAGE_ATTACHMENT_FIELDS_FRAGMENT = gql`
  fragment MessageAttachmentFields on Attachment {
    id
    filename
    url
  }
`;

const REFERENCED_MESSAGE_FIELDS_FRAGMENT = gql`
  ${MESSAGE_AUTHOR_FIELDS_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FIELDS_FRAGMENT}
  fragment ReferencedMessageFields on Message {
    id
    author {
      ...MessageAuthorFields
    }
    content
    attachment {
      ...MessageAttachmentFields
    }
    createdAt
    deletedAt
  }
`;

const MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE = gql`
  ${MESSAGE_AUTHOR_FIELDS_FRAGMENT}
  ${MESSAGE_ATTACHMENT_FIELDS_FRAGMENT}
  ${REFERENCED_MESSAGE_FIELDS_FRAGMENT}
  fragment MessageFieldsNoLocalState on Message {
    id
    createdAt
    content
    isSent
    deletedAt
    attachment {
      ...MessageAttachmentFields
    }
    author {
      ...MessageAuthorFields
    }
    referencedMessage {
      ...ReferencedMessageFields
    }
  }
`;

const MESSAGE_FIELDS_FRAGMENT = gql`
  ${MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE}
  ${MESSAGE_ATTACHMENT_FIELDS_FRAGMENT}
  fragment MessageFields on Message {
    ...MessageFieldsNoLocalState
    sendError @client
    attachment {
      ...MessageAttachmentFields
      isUploading @client
      localFile @client {
        uri
        name
      }
    }
  }
`;

const UPLOAD_MESSAGE_MUTATION = gql`
  ${MESSAGE_FIELDS_FRAGMENT}
  mutation UploadMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      ...MessageFields
    }
  }
`;

//  TODO: Refactor to allow native file types?
// Use existing file code?

type ContentMessageInput = {
  content: string;
  attachment?: AnyFile;
  referencedMessageId?: Scalars["MigrateID"];
};

type AttachmentMessageInput = {
  attachment: AnyFile;
  content?: Maybe<string>;
  referencedMessageId?: Scalars["MigrateID"];
};

type UploadMessageInput = ContentMessageInput | AttachmentMessageInput;

// Separate types from query to ensure type errors if out of sync
type MessageResponse = MessageFieldsFragment;

const useUploadMessage = () => {
  const client = useApolloClient();
  const { cache } = client;
  const [createMessageMutation] = useUploadMessageMutation();
  const [uploadAttachment] = useUploadAttachment();
  const { incrementMessagesInFlight, decrementMessagesInFlight } =
    useMessageStore();
  const { setMessageError, prepareRetryState, clearRetryState } =
    useSetMessageError();

  // TODO: Improve api ->
  // Add loading state / error state in return call + retry callback?
  return async (
    { content, attachment, referencedMessageId }: UploadMessageInput,
    cachedMessageId: string,
    channelId: Scalars["MigrateID"]
  ) => {
    prepareRetryState(cachedMessageId, {
      channelId,
      attachment,
    });

    incrementMessagesInFlight();

    try {
      // await new Promise((r) => setTimeout(r, 2000));
      // throw new Error("Derp");
      const attachmentToken = attachment
        ? await uploadAttachment(attachment, channelId)
        : null;

      await createMessageMutation({
        variables: {
          input: {
            channelId,
            content,
            attachmentToken,
            referencedMessageId,
          },
        },

        update(updateCache, { data }) {
          if (!data) return;

          const { createMessage } = data;

          updateCache.modify({
            id: `Channel:${channelId}`,
            fields: {
              lastMessage: () => {
                return createMessage;
              },
            },
          });

          updateCache.modify({
            fields: {
              // eslint-disable-next-line @typescript-eslint/default-param-last
              messages(existing = { data: [] }, { storeFieldName }) {
                if (!storeFieldName.includes(channelId)) return existing;

                const newMessageRef = toCacheReference({
                  __typename: createMessage.__typename || "Message",
                  id: createMessage.id,
                });

                return {
                  ...existing,
                  data: [
                    newMessageRef,
                    ...existing.data.filter((messageRef: Reference) => {
                      return (
                        cachedMessageId !== updateCache.identify(messageRef)
                      );
                    }),
                  ],
                };
              },
            },
          });

          cache.evict({
            id: cachedMessageId,
          });

          cache.gc();

          decrementMessagesInFlight();
          clearRetryState(cachedMessageId);
        },
      });
    } catch (e) {
      console.error(e);
      decrementMessagesInFlight();
      setMessageError(cachedMessageId);
    }
  };
};

export type { UploadMessageInput, MessageResponse };

export {
  useUploadMessage,
  MESSAGE_AUTHOR_FIELDS_FRAGMENT,
  MESSAGE_ATTACHMENT_FIELDS_FRAGMENT,
  UPLOAD_MESSAGE_MUTATION,
  MESSAGE_FIELDS_FRAGMENT,
  MESSAGE_FIELDS_FRAGMENT_NO_LOCAL_STATE,
};
