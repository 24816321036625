import { NetworkStatus, QueryResult } from "@apollo/client";
import { Button } from "app/design/button";

interface QueryButtonPaginationProps<
  TAfter,
  THookResult extends QueryResult<any, { after?: TAfter }>
> {
  networkStatus: NetworkStatus;
  fetchMore: THookResult["fetchMore"];
  after: TAfter;
  children: React.ReactNode;
}

const QueryButtonPagination = <
  TAfter,
  THookResult extends QueryResult<any, { after?: TAfter }>
>({
  networkStatus,
  after,
  fetchMore,
  children,
}: QueryButtonPaginationProps<TAfter, THookResult>) => {
  return (
    <>
      {children}
      {after ? (
        <Button
          containerSx={{ alignSelf: "center", mt: "$4" }}
          variant="ui"
          loading={networkStatus === NetworkStatus.fetchMore}
          onPress={() =>
            fetchMore({
              variables: {
                after,
              },
            })
          }
        >
          Load more
        </Button>
      ) : null}
    </>
  );
};

export type { QueryButtonPaginationProps };
export { QueryButtonPagination };
