/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { toNestError, validateFieldsNatively } from "@hookform/resolvers";
import {
  appendErrors,
  FieldError,
  FieldValues,
  useForm,
  UseFormProps,
} from "react-hook-form";
import { Resolver } from "@hookform/resolvers/yup";
import { MessageParams } from "yup/lib/types";
import { CHANNEL } from "app/client";

yup.setLocale({
  mixed: {
    // notType's default message is extreme user unfriendly, so in prod we want
    // to show something generic, could be useful to see in dev though.
    notType:
      CHANNEL === "dev" ? "${path} has an invalid type" : "${path} is invalid",
  },
});

const { formatError } = yup.ValidationError;
const parseCamelCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

yup.ValidationError.formatError = (message, params) => {
  if (!params.label) {
    // eslint-disable-next-line no-param-reassign
    params.label = parseCamelCase(String(params.path));
  }

  return formatError.call(this, message, params);
};

// custom hookform yupResolver

const parseErrorSchema = (
  error: yup.ValidationError,
  validateAllFieldCriteria: boolean
) => {
  return (error.inner || []).reduce<Record<string, FieldError>>(
    (previous, e) => {
      if (!previous[e.path!]) {
        previous[e.path!] = { message: e.message, type: e.type! };
      }

      if (validateAllFieldCriteria) {
        const types = previous[e.path!]!.types;
        const messages = types && types[e.type!];

        previous[e.path!] = appendErrors(
          e.path!,
          validateAllFieldCriteria,
          previous,
          e.type!,
          messages
            ? ([] as string[]).concat(messages as string[], e.message)
            : e.message
        ) as FieldError;
      }

      return previous;
    },
    {}
  );
};

const yupResolver: Resolver =
  (schema, schemaOptions = {}, resolverOptions = {}) =>
  async (values, context, options) => {
    try {
      if (schemaOptions.context && process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.warn(
          "You should not used the yup options context. Please, use the 'useForm' context object instead"
        );
      }

      const result = await schema[
        resolverOptions.mode === "sync" ? "validateSync" : "validate"
      ](
        values,
        Object.assign({ abortEarly: false }, schemaOptions, { context })
      );

      if (options.shouldUseNativeValidation) {
        validateFieldsNatively({}, options);
      }

      return {
        values: resolverOptions.rawValues ? values : result,
        errors: {},
      };
    } catch (e: any) {
      if (!e.inner) {
        throw e;
      }

      return {
        values: {},
        errors: toNestError(
          parseErrorSchema(
            e,
            !options.shouldUseNativeValidation && options.criteriaMode === "all"
          ),
          options
        ),
      };
    }
  };

const minLengthValidation = (minLength: number) =>
  [
    minLength,
    ({ value }: MessageParams) =>
      `Please enter ${minLength - value.length} more characters`,
  ] as const;

type UseYupFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
> = Omit<UseFormProps<TFieldValues, TContext>, "resolver"> & {
  schema: yup.AnyObjectSchema;
};

const useYupForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>({
  schema,
  ...props
}: UseYupFormProps<TFieldValues, TContext>) => {
  return useForm({
    resolver: yupResolver(schema),
    ...props,
  });
};

export { yup, minLengthValidation, useYupForm };
