import { Menu } from "app/components/menu";
import { FilterMenuItem } from "./filter-menu-item";
import { SortFilterProps } from "./sort-filter";
import { SortFilterTrigger } from "./filter-triggers";
import { SortFilterOption } from "./types";

export const SortFilter = ({ orderBy, setOrderBy }: SortFilterProps) => (
  <Menu
    trigger={(triggerProps) => (
      <SortFilterTrigger orderBy={orderBy} {...triggerProps} />
    )}
    placement="bottom left"
  >
    <FilterMenuItem
      onPress={() => setOrderBy(SortFilterOption.LATEST)}
      selected={orderBy === SortFilterOption.LATEST}
    >
      Latest
    </FilterMenuItem>
    <FilterMenuItem
      onPress={() => setOrderBy(SortFilterOption.ANSWERED)}
      selected={orderBy === SortFilterOption.ANSWERED}
    >
      Answered
    </FilterMenuItem>
    <FilterMenuItem
      onPress={() => setOrderBy(SortFilterOption.UNANSWERED)}
      selected={orderBy === SortFilterOption.UNANSWERED}
    >
      Unanswered
    </FilterMenuItem>
  </Menu>
);
