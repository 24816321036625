import { useCallback } from "react";
import { Skeleton } from "native-base";
import { Sx } from "dripsy";
import { Avatar } from "app/design/avatar";
import { ChannelMessage } from "../types";
import {
  Bubble,
  RenderMessageBubbleActions,
  RenderMessageBubbleActionSheet,
  SentMessageBubble,
} from "./bubble";
import { MessageActionList, ReplyToMessageAction } from "./action-menu";
import { MessageContainer, AvatarSpacer } from "./message-container";
import { MessageActionSheetContent, ReplyToMessageItem } from "./action-sheet";

interface NonUserMessageLoadingProps {
  sx?: Sx;
  isSameThread?: boolean;
  lines?: number;
  showAvatar?: boolean;
}

const NonUserMessageLoading = ({
  isSameThread,
  sx,
  lines,
  showAvatar,
}: NonUserMessageLoadingProps) => {
  return (
    <MessageContainer messagePosition="left" isSameThread={isSameThread}>
      <Skeleton
        size={10}
        rounded="full"
        mr={3}
        // Retain width for spacing if not shown
        height={!showAvatar ? 0 : undefined}
      />
      <Bubble
        variant="default"
        sx={sx}
        notchPosition="left"
        showNotch={!isSameThread}
      >
        <Skeleton.Text my={2} lines={lines} />
      </Bubble>
    </MessageContainer>
  );
};

const renderNonUserMessageActionList: RenderMessageBubbleActions = ({
  message,
}) => {
  return (
    <MessageActionList>
      <ReplyToMessageAction message={message} />
    </MessageActionList>
  );
};

const renderNonUserMessageActionSheet: RenderMessageBubbleActionSheet = (
  props
) => {
  return (
    <MessageActionSheetContent>
      <ReplyToMessageItem {...props} />
    </MessageActionSheetContent>
  );
};

interface NonUserMessageProps {
  message: ChannelMessage;
  isSameThread: boolean;
}

const NonUserMessage = ({ isSameThread, message }: NonUserMessageProps) => {
  const renderMessageActions = useCallback(renderNonUserMessageActionList, []);
  const renderActionSheet = useCallback(renderNonUserMessageActionSheet, []);

  return (
    <MessageContainer messagePosition="left" isSameThread={isSameThread}>
      {!isSameThread ? (
        <Avatar
          userId={message.author?.id}
          photoUrl={message.author?.photoUrl}
          isActive={message.author?.isActive}
          sx={{ mr: "$2" }}
        />
      ) : (
        <AvatarSpacer />
      )}

      <SentMessageBubble
        message={message}
        notchPosition="left"
        renderActions={renderMessageActions}
        renderActionSheet={renderActionSheet}
        showNotch={!isSameThread}
      />
    </MessageContainer>
  );
};

export type { NonUserMessageProps, NonUserMessageLoadingProps };
export { NonUserMessage, NonUserMessageLoading };
