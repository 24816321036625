import { memo } from "react";
import { useDripsyTheme } from "dripsy";
import { MotiPressable } from "moti/interactions";
import { FieldValues, Path, UseFormResetField } from "react-hook-form";
import { gql } from "@apollo/client";

import {
  useOnboardingProfessionsQuery,
  ProfessionStepProfessionPropsFragment,
} from "app/types/generated/schema";
import { ActivityMessage } from "app/design/activity-indicator";
import { ColorAnimatedView } from "app/components/color-animated-view";
import {
  StepComponentProps,
  STEP_SCREEN_FORM_MAX_WIDTH,
} from "app/components/step-screen-form";

import { StepHeading } from "./heading";
import { StepToggle, StepToggleButtonGroup } from "./step-toggle";

const PROFESSION_STEP_PROFESSION_PROPS = gql`
  fragment ProfessionStepProfessionProps on Profession {
    id
    name
    tags {
      id
      name
    }
  }
`;

const PROFESSION_STEP_PROFESSION_PAGE_PROPS = gql`
  fragment ProfessionStepProfessionPageProps on ProfessionPage {
    data {
      ...ProfessionStepProfessionProps
    }
  }
  ${PROFESSION_STEP_PROFESSION_PROPS}
`;

export const ProfessionsQuery = gql`
  query OnboardingProfessions {
    getProfessions(_size: 100) {
      ...ProfessionStepProfessionPageProps
    }
  }
  ${PROFESSION_STEP_PROFESSION_PAGE_PROPS}
`;

interface ProfessionStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
  resetField: UseFormResetField<TFieldValues>;
  onIneligible: () => void;
}

interface StepFormProps<TFieldValues extends FieldValues>
  extends Omit<ProfessionStepProps<TFieldValues>, "stepPath"> {
  professions?: ProfessionStepProfessionPropsFragment[];
  loading?: boolean;
}

const StepForm = <TFieldValues extends FieldValues>({
  onIneligible,
  control,
  professions = [],
  name,
  loading,
  resetField,
}: StepFormProps<TFieldValues>) => {
  const { theme } = useDripsyTheme();

  return (
    <>
      <StepHeading>What&apos;s your profession?</StepHeading>
      {loading ? (
        <ActivityMessage>Loading professions</ActivityMessage>
      ) : (
        <>
          <StepToggleButtonGroup
            toggleButtonPropsField="toggleButtonProps"
            sx={{
              maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,

              mb: ["$7", "$9"],
            }}
            control={control}
            name={name}
          >
            {professions.map(({ id, name: professionName }) => (
              <StepToggle
                key={id}
                control={control}
                name={name}
                label={professionName}
                checkedValue={id}
                rules={{ required: true }}
              />
            ))}
          </StepToggleButtonGroup>
          <MotiPressable
            onPress={() => {
              resetField(name, {
                keepError: false,
              });
              onIneligible();
            }}
          >
            <ColorAnimatedView
              as="Text"
              colorStates={{
                color: {
                  states: [["hovered", theme.colors.$orange]],
                  initialColor: theme.colors.$text,
                },
              }}
              sx={{
                textDecorationLine: "underline",
                fontFamily: "root",
                fontSize: 16,
                lineHeight: 24,
              }}
            >
              My profession isn&apos;t listed here
            </ColorAnimatedView>
          </MotiPressable>
        </>
      )}
    </>
  );
};

const MemoizedStepForm = memo(StepForm) as typeof StepForm & {
  displayName: "ProfessionStepForm";
};

MemoizedStepForm.displayName = "ProfessionStepForm";

const ProfessionStep = <TFieldValues extends FieldValues>({
  control,
  resetField,
  name,
  onIneligible,
}: ProfessionStepProps<TFieldValues>) => {
  const { data, loading } = useOnboardingProfessionsQuery();

  return (
    <MemoizedStepForm
      name={name}
      control={control}
      onIneligible={onIneligible}
      resetField={resetField}
      professions={data?.getProfessions?.data}
      loading={loading}
    />
  );
};

ProfessionStep.fragments = {
  profession: PROFESSION_STEP_PROFESSION_PROPS,
  professionPage: PROFESSION_STEP_PROFESSION_PAGE_PROPS,
};

const IneligibleProfessionContent = () => (
  <>
    As we haven&apos;t added your profession to the platform yet, you won&apos;t
    be able to search and apply for jobs at this time.
  </>
);

export { ProfessionStep, IneligibleProfessionContent };
