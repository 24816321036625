import { gql } from "@apollo/client";
import { useState } from "react";
import {
  useGetProfilePhotoUploadTokenQuery,
  useUploadProfilePhotoMutation,
} from "app/types/generated/schema";
import { asImperativeQuery } from "app/hooks/helpers/imperative-query";
import { AnyFile, uploadFile } from "app/hooks/helpers/upload-file";
import { useAsyncToast } from "app/components/use-toast";

export const PROFILE_PHOTO_UPLOAD_TOKEN_QUERY = gql`
  query GetProfilePhotoUploadToken {
    getProfilePhotoUploadToken {
      token
      url
    }
  }
`;

export const UPLOAD_PROFILE_PHOTO_MUTATION = gql`
  mutation uploadProfilePhoto($input: UploadProfilePhotoInput!) {
    uploadProfilePhoto(input: $input) {
      id
      photoUrl
    }
  }
`;

export interface UploadProfilePhotoInput {
  file: AnyFile;
}

export const useUploadProfilePhoto = () => {
  const [getProfilePhotoUploadToken] = asImperativeQuery(
    useGetProfilePhotoUploadTokenQuery
  );
  const [uploadProfilePhotoMutation] = useUploadProfilePhotoMutation();
  const [loading, setLoading] = useState(false);

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Error uploading profile photo",
      description: "Please try again or contact support",
    },
    onError: () => {
      setLoading(false);
    },
  });

  const uploadProfilePhoto = withToast(
    async ({ file }: UploadProfilePhotoInput) => {
      setLoading(true);
      const {
        data: {
          getProfilePhotoUploadToken: { url, token },
        },
      } = await getProfilePhotoUploadToken();

      await uploadFile(url, file);

      await uploadProfilePhotoMutation({
        variables: {
          input: {
            token,
          },
        },
      });
      setLoading(false);
    }
  );

  return [uploadProfilePhoto, { loading }] as const;
};
