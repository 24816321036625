import { MatchResponseType as GQLMatchResponseType } from "./generated/schema";

export type CandidateMatchResponseType = Exclude<
  GQLMatchResponseType,
  GQLMatchResponseType.ApplicationApproved
>;

export const getMatchResponseTypes = (input: CandidateMatchResponseType) => {
  switch (input) {
    case GQLMatchResponseType.Apply:
      return [
        GQLMatchResponseType.ApplicationApproved,
        GQLMatchResponseType.Apply,
      ];

    default:
      return [input];
  }
};
