import { gql } from "@apollo/client";
import { useMatchedJobsQuery } from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import { chainAuthorizationRules, rules } from "app/navigation/authorization";
import { pagesPath } from "app/lib/$path";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import { memoizeScreen } from "app/utils/screen";
import { Layout } from "../../layout";
import { JobDetails } from "../job-details";
import { JobSlider } from "./job-slider";

export const MATCHED_JOBS_QUERY = gql`
  query MatchedJobs($input: MyMatchedJobsInput) {
    myMatchedJobs(input: $input) {
      data {
        ...JobDetailsJobProps
        # Will be null initially, but used for caching on job action
        myMatchResponse {
          ...JobMatchActionMatchResponseProps
        }
      }
      count
    }
  }
  ${JobDetails.fragments.job}
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

export const JobMatchesScreen = memoizeScreen(() => {
  const { data, loading, error } = useMatchedJobsQuery();
  if (error) return <DataError />;
  return <JobSlider jobs={data?.myMatchedJobs.data ?? []} loading={loading} />;
});

const headerProps = {
  title: "Jobs",
  backButtonType: "back",
  showTitleOnDesktop: true,
} as const;

JobMatchesScreen.displayName = "JobMatchesScreen";
JobMatchesScreen.headerProps = headerProps;
JobMatchesScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

JobMatchesScreen.getAuthorizer = () =>
  chainAuthorizationRules(
    rules.isVerified({
      redirect: pagesPath.$url(),
      toastOptions: {
        title: "Please verify your email address to view your matches!",
      },
    }),
    rules.isCandidateV2({
      redirect: pagesPath.job_preferences.$url(),
      toastOptions: {
        title: "Please set your job preferences to view your matches!",
      },
    })
  );
