import { useDripsyTheme } from "dripsy";
import { useWindowDimensions } from "react-native";
import { DEFAULT_ACCENT_WIDTH } from "app/design/card";

const useToastStyles = () => {
  const { width: screenWidth } = useWindowDimensions();
  const { theme } = useDripsyTheme();
  const { color, _accent, _hover, ...infoAlertSx } = theme.alert.info;

  return {
    textSx: { color },
    sx: {
      ...infoAlertSx,
      borderWidth: theme.card.default.borderWidth,
      width: screenWidth - 16,
      maxWidth: 400,
    },
    accentProps: {
      activeOverride: true,
      sx: {
        bg: _accent.bg,
        width: DEFAULT_ACCENT_WIDTH,
      },
      containerSx: {
        borderRadius: infoAlertSx.borderRadius,
      },
    },
    initialColor: infoAlertSx.bg,
    activeColor: _hover.bg,
    showAccent: true,
  };
};

export { useToastStyles };
