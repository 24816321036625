import { gql } from "@apollo/client";

import { useModal } from "react-native-modalfy";
import React from "react";
import {
  JobMatchActionMatchResponsePropsFragment,
  MatchResponseType,
  Maybe,
  Scalars,
  useJobDetailsScreenQuery,
} from "app/types/generated/schema";
import { useIdParam } from "app/hooks/use-id-param";
import { DataError } from "app/components/data-error";
import {
  FloatingActionList,
  FloatingActionScreen,
  RenderFloatingLayerProps,
} from "app/components/floating-action-list";

import { LoginToAccess } from "app/components/protect-feature";
import { SetPreferencesPrompt } from "app/components/overview-card";
import { Screen } from "app/components/screen";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import { memoizeScreen } from "app/utils/screen";
import { useCurrentUser } from "app/hooks";
import { Layout, HalfDesktopWrapper } from "../../layout";
import { JobDetails, JobDetailsLoading } from "./job-details";

import {
  OnActionToggle,
  JobToggleHideActionButton,
  ToggleHideActionButton,
  JobToggleSaveActionButton,
  ToggleSaveActionButton,
  JobApplicationStartActionButton,
  ApplicationStartActionButton,
} from "./actions";

const JOB_DETAILS_SCREEN_QUERY = gql`
  query JobDetailsScreen($jobId: MigrateID!) {
    job(id: $jobId) {
      id
      myMatchResponse {
        ...JobMatchActionMatchResponseProps
      }
    }
  }
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

interface MockJobDetailsActionsProps {
  expanded: boolean;
}

// Use dumb components to avoid additional queries in Job* variants
const UnauthenticatedJobDetailsActions = ({
  expanded,
}: MockJobDetailsActionsProps) => {
  return (
    <FloatingActionList expanded={expanded}>
      <LoginToAccess label="Log in or sign up to save this job!">
        <ToggleSaveActionButton />
      </LoginToAccess>
      <LoginToAccess label="Log in or sign up to apply for this job!">
        <ApplicationStartActionButton />
      </LoginToAccess>
      <LoginToAccess label="Log in or sign up to hide this job!">
        <ToggleHideActionButton />
      </LoginToAccess>
    </FloatingActionList>
  );
};
interface JobDetailsActionsProps {
  jobId: Scalars["MigrateID"];
  matchResponse?: Maybe<JobMatchActionMatchResponsePropsFragment>;
  expanded: boolean;
}

const DefaultJobDetailsActions = ({
  expanded,
  ...jobProps
}: JobDetailsActionsProps) => {
  return (
    <FloatingActionList expanded={expanded}>
      <JobToggleSaveActionButton
        variant={(saved) => (saved ? "primary" : "card")}
        {...jobProps}
      />
      <JobApplicationStartActionButton variant={"card"} {...jobProps} />
      <JobToggleHideActionButton
        variant={(hidden) => (hidden ? "primary" : "card")}
        {...jobProps}
      />
    </FloatingActionList>
  );
};

const ApplicationStartJobDetailsActions = ({
  expanded,
  ...jobProps
}: JobDetailsActionsProps) => {
  const { openModal } = useModal();

  const confirmToggle: OnActionToggle = ({ toggled, handleToggle }) => {
    if (!toggled) {
      openModal("ConfirmModal", {
        title: "Abandon application",
        description:
          "Are you sure? This will delete your application information for this job and you will need to re-enter it if you reapply.",
        onConfirm: handleToggle,
      });
      return;
    }

    return handleToggle();
  };

  return (
    <FloatingActionList expanded={expanded}>
      <JobToggleSaveActionButton
        variant={"card"}
        onToggle={confirmToggle}
        {...jobProps}
      />
      <JobToggleHideActionButton
        variant={"card"}
        onToggle={confirmToggle}
        {...jobProps}
      />
    </FloatingActionList>
  );
};

const AuthenticatedJobDetailsActions = (props: JobDetailsActionsProps) => {
  // TODO: Only render if eligible for job based on preferences (Backend query)
  const { matchResponse } = props;
  if (!matchResponse) {
    return <DefaultJobDetailsActions {...props} />;
  }
  const { type } = matchResponse;

  switch (type) {
    case MatchResponseType.ApplicationStart:
      return <ApplicationStartJobDetailsActions {...props} />;

    case MatchResponseType.Save:
    case MatchResponseType.Hide:
      return <DefaultJobDetailsActions {...props} />;

    default:
      return null;
  }
};

const JobDetailsScreenContent = ({ jobId }: { jobId: string }) => {
  const { currentUser, loading: currentUserLoading } = useCurrentUser();

  const { data, loading, error } = useJobDetailsScreenQuery({
    variables: { jobId },
  });
  console.log(error?.message);

  // TODO: Protect route (use auth stuff when ready?)

  if (loading || currentUserLoading)
    return (
      <FloatingActionScreen>
        <JobDetailsLoading />
      </FloatingActionScreen>
    );

  if (error?.message === "Candidate is not a CandidateV2") {
    return (
      <Screen scrollable sx={{ maxWidth: 1056 }}>
        <HalfDesktopWrapper>
          <SetPreferencesPrompt description="Before you can apply for this job, you need to set your job preferences." />
        </HalfDesktopWrapper>
      </Screen>
    );
  }

  if (error || !data)
    return (
      <FloatingActionScreen>
        <DataError />
      </FloatingActionScreen>
    );

  const renderFloatingLayer = ({ isAtTopOrBottom }: RenderFloatingLayerProps) =>
    currentUser ? (
      <AuthenticatedJobDetailsActions
        jobId={jobId}
        matchResponse={data.job.myMatchResponse}
        expanded={isAtTopOrBottom}
      />
    ) : (
      <UnauthenticatedJobDetailsActions expanded={isAtTopOrBottom} />
    );

  return (
    <FloatingActionScreen renderFloatingLayer={renderFloatingLayer}>
      <JobDetails jobId={jobId} renderMeta />
    </FloatingActionScreen>
  );
};

const JobDetailsScreen = memoizeScreen(() => {
  const jobId = useIdParam("jobId");

  return jobId ? (
    <JobDetailsScreenContent jobId={jobId} />
  ) : // TODO: Render not found screen or something better
  null;
});

const headerProps = {
  title: "Jobs",
  showTitleOnDesktop: true,
};

JobDetailsScreen.displayName = "JobDetailsScreen";
JobDetailsScreen.headerProps = headerProps;
JobDetailsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { JOB_DETAILS_SCREEN_QUERY, JobDetailsScreen };
