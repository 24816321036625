import { gql } from "@apollo/client";
import { Feather } from "@expo/vector-icons";
import { useRouter } from "solito/router";
import {
  MatchResponseType,
  Scalars,
  useApplicationStartActionButtonQuery,
  useApplyToJobMatchMutation,
} from "app/types/generated/schema";
import {
  FloatingActionButton,
  FloatingActionButtonProps,
} from "app/components/floating-action-list";
import { useAsyncToast } from "app/components/use-toast";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import { pagesPath } from "app/lib/$path";
import { sendAnalyticsEvent } from "app/client/analytics";
import { updateMyMatchedJobs, updateMyMatchResponses } from "./cache";
import { useApplicationLimitToast } from "./hooks/use-application-limit-toast";

export const APPLICATION_START_JOB_MATCH_MUTATION = gql`
  mutation ApplyToJobMatch($jobId: MigrateID!) {
    respondToMatch(input: { jobId: $jobId, type: APPLICATION_START }) {
      ...JobMatchActionMatchResponseProps
    }
  }
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

export const APPLICATION_START_ACTION_BUTTON_QUERY = gql`
  query ApplicationStartActionButton {
    me {
      id
      ... on CandidateV2 {
        canApply
      }
    }
  }
`;

interface ApplicationStartActionButtonProps
  extends Omit<FloatingActionButtonProps, "icon" | "children"> {}

const ApplicationStartActionButton = ({
  variant = "card",
  ...props
}: ApplicationStartActionButtonProps) => {
  return (
    <FloatingActionButton
      icon={<Feather name="edit-3" />}
      variant={variant}
      {...props}
    >
      Apply
    </FloatingActionButton>
  );
};

interface JobApplicationStartActionButtonProps {
  jobId: Scalars["MigrateID"];
  variant?: FloatingActionButtonProps["variant"];
}

const JobApplicationStartActionButton = ({
  jobId,
  variant,
  ...props
}: JobApplicationStartActionButtonProps) => {
  const router = useRouter();
  const showErrorToast = useApplicationLimitToast();

  const {
    data: currentUser,
    loading: fetching,
    error,
  } = useApplicationStartActionButtonQuery();

  if (error) console.log(error);

  const canApply =
    currentUser?.me.__typename === "CandidateV2" && currentUser.me.canApply;

  const [applyToJobMatch, { loading }] = useApplyToJobMatchMutation({
    variables: {
      jobId,
    },
    update(cache, { data }) {
      const matchResponse = data?.respondToMatch;
      if (!matchResponse) return;

      cache.modify({
        fields: {
          myMatchedJobs: updateMyMatchedJobs({ cache, matchResponse }),
          myMatchResponses: updateMyMatchResponses({
            cache,
            matchResponse,
            matchResponseType: MatchResponseType.ApplicationStart,
          }),
        },
      });
    },
  });

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Unable to apply for job",
      description: "Please try again or contact support",
    },
  });

  const handlePress = withToast(async () => {
    sendAnalyticsEvent("start application", { jobId });
    await applyToJobMatch();
    router.push(pagesPath.jobs._jobId(jobId).apply.$url());
  });

  return (
    <ApplicationStartActionButton
      onPress={canApply ? handlePress : showErrorToast}
      loading={loading || fetching}
      variant={variant}
      {...props}
    />
  );
};

export type { JobApplicationStartActionButtonProps };
export { JobApplicationStartActionButton, ApplicationStartActionButton };
