import { memo } from "react";
import isEqual from "react-fast-compare";
import { ProfileCard } from "../../card";
import { useEducationExperienceMutations } from "../hooks/use-education-experience-mutations";
import { isTempField } from "../profile-form";
import {
  EducationExperienceFormCard,
  EducationExperienceFormValues,
} from "./form-card";

interface EducationExperienceCardProps {
  field: EducationExperienceFormValues;
  onCancel: () => void;
}

export const EducationExperienceCard = memo(
  ({ field, onCancel }: EducationExperienceCardProps) => {
    const {
      id,
      institutionName,
      qualification,
      startDate,
      endDate,
      description,
    } = field;

    const { deleteEducationExperience } = useEducationExperienceMutations(id);
    const completed = !isTempField({ id });

    return (
      <ProfileCard
        header={institutionName}
        subheader={qualification}
        startDate={startDate}
        endDate={endDate}
        showDivider
        form={
          <EducationExperienceFormCard
            defaultValues={field}
            onCancel={onCancel}
          />
        }
        onDelete={deleteEducationExperience}
        showFormOnRender={!completed}
      >
        <ProfileCard.Description description={description} />
      </ProfileCard>
    );
  },
  (a, b) => isEqual(a.field, b.field)
);
EducationExperienceCard.displayName = "EducationExperienceCard";
