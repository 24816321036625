import {
  avatarColorOptions,
  organisationColorOptions,
} from "app/provider/dripsy/colors";

const cyrb53 = function (str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.round((hash % (max - min)) + min);
};

const generateColorFromOptions = (name: string, options: string[]) => {
  const hash = cyrb53(name);
  return options[normalizeHash(hash, 0, options.length - 1)];
};

export const generateColor = (options: string[]) => (id: string) =>
  generateColorFromOptions(id, options);

export const generateAvatarColor = generateColor(avatarColorOptions);
export const generateOrganisationColor = generateColor(
  organisationColorOptions
);
