import { gql } from "@apollo/client";
import {
  MatchedJobsQuery,
  MatchResponseType,
  Scalars,
  useSaveJobMatchMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import {
  createRespondToMatchOptimisticResponse,
  removeCacheItemFromArray,
  updateMyMatchResponses,
} from "../cache";

const SAVE_JOB_MATCH_MUTATION = gql`
  mutation SaveJobMatch($jobId: MigrateID!) {
    respondToMatch(input: { jobId: $jobId, type: SAVE }) {
      ...JobMatchActionMatchResponseProps
    }
  }
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

interface UseSaveJobMatchProps {
  jobId: Scalars["MigrateID"];
  existingMatchResponseId?: Scalars["MigrateID"];
}

const useSaveJobMatch = () => {
  const [saveJobMatchMutation] = useSaveJobMatchMutation();

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Unable to save job",
      description: "Please try again or contact support",
    },
  });

  return withToast(
    async ({ jobId, existingMatchResponseId }: UseSaveJobMatchProps) =>
      saveJobMatchMutation({
        variables: { jobId },
        optimisticResponse: createRespondToMatchOptimisticResponse({
          __typename: "GenericMatchResponse",
          jobId,
          type: MatchResponseType.Save,
          existingMatchResponseId,
        }),
        update: (cache, { data }) => {
          const matchResponse = data?.respondToMatch;
          if (!matchResponse) return null;

          cache.modify({
            fields: {
              myMatchedJobs(
                jobPage: MatchedJobsQuery["myMatchedJobs"],
                { isReference }
              ) {
                const filteredData = removeCacheItemFromArray({
                  cache,
                  isReference,
                })(matchResponse.job, jobPage.data);
                // Update count but do not filter to keep saved item
                // in match list when user is viewing.
                return {
                  ...jobPage,
                  count: filteredData.length,
                };
              },
              myMatchResponses: updateMyMatchResponses({
                cache,
                matchResponseType: MatchResponseType.Save,
                matchResponse,
              }),
            },
          });
        },
      })
  );
};

export type { UseSaveJobMatchProps };
export { SAVE_JOB_MATCH_MUTATION, useSaveJobMatch };
