import { FieldValues, Path } from "react-hook-form";

import { ToggleControl } from "app/components/form/toggle-control";
import { StepComponentProps } from "app/components/step-screen-form";
import { StepHeading } from "app/features/onboarding/steps/heading";
import { CareerQuizOption, CareerQuizStepContainer } from "./container";

export enum EnglishLanguageOption {
  IELTS = "IELTS",
  OET = "OET",
  EXEMPT = "EXEMPT",
  NA = "NA",
}
type EnglishLanguageTuple = [EnglishLanguageOption, string];

interface EnglishLanguageStepProps<TFieldValues extends FieldValues>
  extends StepComponentProps<TFieldValues> {
  name: Path<TFieldValues>;
}

const EnglishLanguageStep = <TFieldValues extends FieldValues>({
  control,
  name,
}: EnglishLanguageStepProps<TFieldValues>) => {
  const options: EnglishLanguageTuple[] = [
    [EnglishLanguageOption.IELTS, "I have taken IELTS"],
    [EnglishLanguageOption.OET, "I have taken OET"],
    [
      EnglishLanguageOption.EXEMPT,
      "I have worked in / I am from an English speaking country",
    ],
    [EnglishLanguageOption.NA, "I have not taken an exam"],
  ];

  return (
    <>
      <StepHeading>Have you taken an English language exam?</StepHeading>
      <CareerQuizStepContainer>
        {options.map(([value, text]) => (
          <CareerQuizOption key={value}>
            <ToggleControl
              control={control}
              name={name}
              checkedValue={value}
              rules={{ required: true }}
            >
              {text}
            </ToggleControl>
          </CareerQuizOption>
        ))}
      </CareerQuizStepContainer>
    </>
  );
};

export { EnglishLanguageStep };
