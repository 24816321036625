import {
  RetrySendMessageProps,
  useRetrySendMessage,
} from "app/hooks/message/use-send-message";

import { MessageAction } from "./message-action-item";

type RetrySendMessageActionProps = RetrySendMessageProps;

const RetrySendMessageAction = ({ message }: RetrySendMessageActionProps) => {
  const retrySendMessage = useRetrySendMessage();
  return (
    <MessageAction
      sx={{ ml: "$1" }}
      label="Resend"
      iconName="repeat"
      onPress={() => retrySendMessage({ message })}
    />
  );
};

export type { RetrySendMessageActionProps };
export { RetrySendMessageAction };
