import { useDripsyTheme } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { JobCardQuery, MatchResponseType } from "app/types/generated/schema";
import { Menu } from "app/components/menu";
import { MenuTriggerButton } from "app/components/menu-trigger";
import {
  ApplicationApprovedMatchActions,
  ApplicationStartMatchActions,
  ApplyMatchActions,
  DefaultMatchActions,
} from "./job-card.menu.actions";

interface JobCardMenuProps {
  jobId: string;
  myMatchResponse: JobCardQuery["job"]["myMatchResponse"];
}

const JobCardMenu = (props: JobCardMenuProps) => {
  const { theme } = useDripsyTheme();
  const { myMatchResponse } = props;

  return (
    <Menu
      trigger={(triggerProps) => (
        <MenuTriggerButton
          variant="ghost"
          containerSx={{ alignSelf: "flex-start", zIndex: 2 }}
          accessibilityLabel="More"
          icon={
            <Feather
              name="more-vertical"
              size={24}
              color={theme.colors.$black}
            />
          }
          {...triggerProps}
        />
      )}
      placement="bottom right"
    >
      {(() => {
        if (myMatchResponse?.type === MatchResponseType.ApplicationStart)
          return <ApplicationStartMatchActions {...props} />;

        if (myMatchResponse?.type === MatchResponseType.Apply)
          return <ApplyMatchActions {...props} />;

        if (myMatchResponse?.type === MatchResponseType.ApplicationApproved)
          return <ApplicationApprovedMatchActions {...props} />;

        return <DefaultMatchActions {...props} />;
      })()}
    </Menu>
  );
};

export { JobCardMenu };
