import { RefObject, useEffect } from "react";
import { Platform } from "react-native";

interface UseKeyboardInterceptorProps {
  componentRef: RefObject<$TSFixMe>;
  onKeyDown?: (e: KeyboardEvent) => void;
  onKeyPress?: (e: KeyboardEvent) => void;
}

export const useKeyboardInterceptor = ({
  componentRef,
  onKeyDown = () => {},
  onKeyPress = () => {},
}: UseKeyboardInterceptorProps) => {
  useEffect(() => {
    if (Platform.OS !== "web") return;

    componentRef?.current?.addEventListener("keydown", onKeyDown);
    componentRef?.current?.addEventListener("keypress", onKeyPress);

    return () => {
      componentRef?.current?.removeEventListener("keydown", onKeyDown);
      componentRef?.current?.removeEventListener("keypress", onKeyPress);
    };
  }, []);
};
