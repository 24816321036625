import { styled, SxProp, useDripsyTheme, useSx, View } from "dripsy";
import { ReactNode } from "react";
import { AnchorProvider } from "@nandorojo/anchor";
import { useLayoutStore } from "app/features/layout";
import { useScreenSize } from "app/hooks";
import { KeyboardAwareScrollView } from "../keyboard-aware-scroll-view";

const TITLE_DIVIDER_Y = 32;

interface ContainerProps {
  scrollable?: boolean;
  children: ReactNode;
}

const Container = ({ children, scrollable }: ContainerProps) => {
  const sx = useSx();
  const { isMobile } = useScreenSize();
  const { showTitleInHeader, setShowTitleInHeader } = useLayoutStore();

  const onScroll = ({ nativeEvent: { contentOffset } }: any) => {
    if (!showTitleInHeader && contentOffset.y > TITLE_DIVIDER_Y)
      setShowTitleInHeader(true);
    else if (showTitleInHeader && contentOffset.y < TITLE_DIVIDER_Y)
      setShowTitleInHeader(false);
  };

  return scrollable ? (
    <AnchorProvider horizontal={false}>
      <KeyboardAwareScrollView
        contentContainerStyle={sx({ flexGrow: 1 })}
        onScroll={isMobile ? undefined : onScroll}
        scrollEventThrottle={50}
      >
        {children}
      </KeyboardAwareScrollView>
    </AnchorProvider>
  ) : (
    // recreate web ScrollView behaviour for non-scrollable layouts
    <View
      sx={{
        flex: 1,
        overflowY: "auto",
      }}
    >
      <View sx={{ flex: 1 }}>{children}</View>
    </View>
  );
};

export interface ScreenProps {
  containerSx?: SxProp;
  sx?: SxProp;
  scrollable?: boolean;
  children: ReactNode;
}

const Screen = ({
  children,
  sx,
  containerSx,
  scrollable = false,
}: ScreenProps) => {
  const { theme } = useDripsyTheme();
  return (
    <Container scrollable={scrollable}>
      <View sx={{ ...theme.layout.screen.container, ...containerSx }}>
        <View sx={{ ...theme.layout.screen.body, ...sx }}>{children}</View>
      </View>
    </Container>
  );
};

const ScreenFilterHeaderContainer = styled(View)({
  // On widescreens only, position as a sidebar
  width: [null, null, null, "33.3333%"],
  pl: [null, null, null, "$3"],
  position: ["relative", null, null, "absolute"],
  top: [null, null, null, 0],
  right: [null, null, null, 0],
});

const ScreenFilterItemContainer = styled(View)({
  width: [null, null, null, "66.6666%"],
  pr: [null, null, null, "$3"],
});

export { Screen, ScreenFilterHeaderContainer, ScreenFilterItemContainer };
