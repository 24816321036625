import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import {
  AppRouter,
  errors as Errors,
} from "@migratehr/auth-frontend/dist/types/services/auth/frontend";
// @ts-ignore Types not exported properly from package
import { errors } from "@migratehr/auth-frontend/dist/index";
import { AuthStore } from "./store";
import { AuthAnalytics } from "./analytics";

type CreateClient = typeof createTRPCProxyClient<AppRouter>;
type TRPCAuthClient = ReturnType<CreateClient>;

interface FetchAccessTokenFactoryDependencies {
  authStore: Pick<AuthStore, "getRefreshToken" | "setAccessToken" | "reset">;
  /** Lazy to allow initialisation */
  getClient: () => TRPCAuthClient | undefined;
}

const fetchAccessTokenFactory =
  ({ authStore, getClient }: FetchAccessTokenFactoryDependencies) =>
  async () => {
    const refreshToken = authStore.getRefreshToken();
    try {
      const client = getClient();
      if (!client) throw new Error("uninitialised");
      if (!refreshToken) throw new Error("unauthenticated");
      const { accessToken } = await client.refresh.mutate({ refreshToken });
      authStore.setAccessToken(accessToken);
      return accessToken;
    } catch (e) {
      // TODO: Handle errors specifically vs catch all
      console.error(e);
      authStore.reset();
      return null;
    }
  };

interface GetAccessTokenDependencies {
  authStore: Pick<AuthStore, "getAccessToken" | "getAccountId">;
  authAnalytics: Pick<AuthAnalytics, "setUser">;
  refreshAccessToken: () => Promise<string | null>;
}

interface GetAccessTokenInput {
  forceFetchToken?: boolean;
}

const getAccessTokenFactory =
  ({
    authStore,
    refreshAccessToken,
    authAnalytics,
  }: GetAccessTokenDependencies) =>
  async ({ forceFetchToken = false }: GetAccessTokenInput = {}) => {
    const existingAccessToken = authStore.getAccessToken();
    if (existingAccessToken && !forceFetchToken) {
      authAnalytics.setUser(authStore.getAccountId());
      return existingAccessToken;
    }
    const maybeNewAccessToken = await refreshAccessToken();
    authAnalytics.setUser(authStore.getAccountId());
    return maybeNewAccessToken;
  };

interface GetAuthorizationHeaderFactoryDependencies {
  authStore: Pick<AuthStore, "getRefreshToken">;
  getAccessToken: (input: GetAccessTokenInput) => Promise<string | null>;
  unauthorizedToken: string;
}

const getAuthorizationHeaderFactory =
  ({
    authStore,
    getAccessToken,
    unauthorizedToken,
  }: GetAuthorizationHeaderFactoryDependencies) =>
  async ({ forceFetchToken }: GetAccessTokenInput = {}) => {
    const unauthorizedHeader = `Bearer ${unauthorizedToken}`;
    const isSoftAuthenticated = authStore.getRefreshToken();
    if (!isSoftAuthenticated) return unauthorizedHeader;
    const maybeAccessToken = await getAccessToken({ forceFetchToken });
    return maybeAccessToken ? `Bearer ${maybeAccessToken}` : unauthorizedHeader;
  };

interface CreateAuthClientConfig {
  authStore: AuthStore;
  authAnalytics: AuthAnalytics;
  url: string;
  unauthorizedToken: string;
}

interface LoginConfig {
  preAuthenticateHook: () => Promise<any>;
  postAuthenticateHook?: () => Promise<any>;
}

interface VerifyAccountConfig {
  postVerifyHook: () => Promise<any>;
}

const createAuthClient = ({
  url,
  unauthorizedToken,
  authStore,
  authAnalytics,
}: CreateAuthClientConfig) => {
  // Lazy client definition to allow it to call itself
  // to refresh with its own access token in `headers()`
  // TODO: Pehaps this is a mistake in server implementation,
  // it should be with refresh token in header to prevent circular munge
  let client: TRPCAuthClient | undefined = undefined;

  const fetchAccessToken = fetchAccessTokenFactory({
    authStore,
    getClient: () => client,
  });

  const getAccessToken = getAccessTokenFactory({
    authStore,
    refreshAccessToken: fetchAccessToken,
    authAnalytics,
  });

  const getAuthorizationHeader = getAuthorizationHeaderFactory({
    authStore,
    getAccessToken,
    unauthorizedToken,
  });

  client = createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url,
        headers: async () => ({
          Authorization: await getAuthorizationHeader(),
        }),
      }),
    ],
  });

  // Redeclation for type safety
  const initialisedClient = client;

  const signup = async (
    input: Parameters<TRPCAuthClient["registerCandidate"]["mutate"]>[0]
  ) => {
    try {
      const data = await initialisedClient.registerCandidate.mutate(input);
      authAnalytics.accountCreated();
      return {
        success: true,
        data,
      } as const;
    } catch (e) {
      if (
        errors.registerCandidate.AccountAlreadyExistsErrorSchema.safeParse(e)
          .success
      ) {
        return {
          success: false,
          error: {
            type: "AccountAlreadyExistsError",
          },
        } as const;
      }
      throw e;
    }
  };

  const login = async (
    input: Parameters<TRPCAuthClient["login"]["mutate"]>[0],
    config?: LoginConfig
  ) => {
    try {
      const data = await initialisedClient.login.mutate(input);
      const { accessToken, refreshToken, accountId } = data;
      authStore.softAuthenticate({
        accessToken,
        refreshToken,
      });
      await config?.preAuthenticateHook?.();
      authStore.authenticate();
      await config?.postAuthenticateHook?.();
      authAnalytics.loggedIn(accountId);
      return {
        success: true,
        data,
      } as const;
    } catch (e) {
      if (
        (errors as typeof Errors).login.InvalidCredentialsErrorSchema.safeParse(
          e
        ).success
      ) {
        return {
          success: false,
          error: {
            type: "InvalidCredentialsError",
          },
        } as const;
      }
      throw e;
    }
  };

  const logout = async () => {
    const refreshToken = authStore.getRefreshToken();
    if (refreshToken) {
      await initialisedClient.logout.mutate({
        refreshToken,
      });
    } else {
      console.error("No refresh token to logout with");
      // TODO: Sentry error (Inject for testing)
    }
    authStore.reset();
    authAnalytics.loggedOut();
  };

  const verifyAccount = async (
    input: Parameters<TRPCAuthClient["verifyAccount"]["mutate"]>[0],
    config?: VerifyAccountConfig
  ) => {
    // TODO: Why refetch? Document
    const maybeNewAccessToken = await fetchAccessToken();
    if (!maybeNewAccessToken) {
      throw new Error("Unauthenticated");
    }

    await initialisedClient.verifyAccount.mutate(input);
    await config?.postVerifyHook?.();
    // TODO: Add analytics to compare verified / unverified users?
  };

  const resetVerificationCode = async (
    input: Parameters<TRPCAuthClient["resetVerificationCode"]["mutate"]>[0]
  ) => initialisedClient.resetVerificationCode.mutate(input);

  const forgotPassword = async (
    input: Parameters<TRPCAuthClient["forgotPassword"]["mutate"]>[0]
  ) => initialisedClient.forgotPassword.mutate(input);

  const resetPasswordWithCode = async (
    input: Parameters<TRPCAuthClient["resetPasswordWithCode"]["mutate"]>[0]
  ) => {
    try {
      initialisedClient.resetPasswordWithCode.mutate(input);
      return {
        success: true,
      } as const;
    } catch (e) {
      // TODO: Create schema in client lib
      if ((e as { message?: string }).message === "Invalid code") {
        return {
          success: false,
          error: {
            type: "InvalidCodeError",
          },
        } as const;
      }
      throw e;
    }
  };

  return {
    isSoftAuthenticated: () => !!authStore.getRefreshToken(),
    getAccessToken,
    fetchAccessToken,
    getAuthorizationHeader,
    signup,
    login,
    logout,
    verifyAccount,
    resetVerificationCode,
    forgotPassword,
    resetPasswordWithCode,
  };
};

type AuthClient = ReturnType<typeof createAuthClient>;

export type { AuthClient, CreateAuthClientConfig };
export { createAuthClient };
