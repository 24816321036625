import {
  RetrySendMessageProps,
  useRetrySendMessage,
} from "app/hooks/message/use-send-message";

import {
  ClosingActionsheetItem,
  ClosingActionsheetItemProps,
} from "./action-sheet-item";

type RetrySendMessageItemProps = Omit<
  ClosingActionsheetItemProps,
  "onPress" | "iconName" | "children"
> &
  RetrySendMessageProps;

const RetrySendMessageItem = ({
  message,
  ...props
}: RetrySendMessageItemProps) => {
  const retrySendMessage = useRetrySendMessage();

  return (
    <ClosingActionsheetItem
      {...props}
      iconName="repeat"
      onPress={() => retrySendMessage({ message })}
    >
      Resend
    </ClosingActionsheetItem>
  );
};

export type { RetrySendMessageItemProps };
export { RetrySendMessageItem };
