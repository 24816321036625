import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 186;
const originalHeight = 151;
const aspectRatio = originalWidth / originalHeight;

const Stone5 = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...props}
    >
      <Path
        d="m44.583 133.056-.314-.315C15.559 112.066-3.986 83.655.692 56.216 4.559 33.946 31.685 10.085 72.15 2.467c51.136-9.703 88.677 10.73 103.578 34.894 5.76 9.157 9.234 19.291 10.073 29.384.839 10.093-.986 19.789-5.293 28.112-9.946 21.009-46.471 62.863-96.144 55.223-13.97-2.186-27.714-8.068-39.782-17.024Z"
        fill={props.fill}
      />
    </Svg>
  </View>
);

export { Stone5 };
