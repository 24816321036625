import create, { StateCreator } from "zustand";
import { persist } from "zustand/middleware";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

const getStorage = () => {
  if (Platform.OS !== "web") return AsyncStorage;
  const isSSR = typeof window === "undefined";
  return isSSR ? undefined : localStorage;
};

const storage = getStorage();

interface AuthStoreState {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  /** Used to reset userId for analytics */
  accountId: string | null;
  reset: () => void;
}

type StateWithoutMutators = Omit<AuthStoreState, "reset">;

const initialState: StateWithoutMutators = {
  isAuthenticated: false,
  accessToken: null,
  /** If not null then is 'soft' authenticated */
  refreshToken: null,
  accountId: null,
};

const store: StateCreator<AuthStoreState> = (set) => ({
  ...initialState,
  reset: () => set(initialState),
});

/** Reactive hook */
const useAuthStore = storage
  ? create(
      persist(store, {
        name: "app.migratehr",
        getStorage: () => storage,
        // Note will break if another store is persisted!
        // May need to adjust keys or use global zustand store in that case
        serialize: (state) =>
          JSON.stringify({
            auth: state,
          }),
        deserialize: (str) => {
          try {
            const { auth } = JSON.parse(str);
            return auth;
          } catch (e) {
            return initialState;
          }
        },
        partialize: (state) => ({
          isAuthenticated: state.isAuthenticated,
          accessToken: state.accessToken,
          refreshToken: state.refreshToken,
        }),
      })
    )
  : create(store);

type UseAuthStore = typeof useAuthStore;

/** Non-reactive lib for client SDKs */
const authStore = {
  getAccountId: () => useAuthStore.getState().accountId,
  getAccessToken: () => useAuthStore.getState().accessToken,
  setAccessToken: (accessToken: string) => {
    useAuthStore.setState({ accessToken });
  },
  getRefreshToken: () => useAuthStore.getState().refreshToken,
  softAuthenticate: (input: { refreshToken: string; accessToken: string }) => {
    useAuthStore.setState(input);
  },
  authenticate: () => {
    useAuthStore.setState({ isAuthenticated: true });
  },
  reset: () => {
    useAuthStore.setState(initialState);
  },
};

type AuthStore = typeof authStore;

export type { AuthStore, UseAuthStore };
export { useAuthStore, authStore };
