import { Row, styled, View } from "dripsy";
import { gql, NetworkStatus } from "@apollo/client";
import { Alert } from "app/design/alert";
import { DataError } from "app/components/data-error";
import { FlatList } from "app/components/flat-list";
import { QueryButtonPagination } from "app/components/pagination/query-button-pagination";
import {
  JobCardPropsFragment,
  useMyJobsQuery,
  CandidateMatchResponseType,
  getMatchResponseTypes,
  MatchResponseType,
} from "app/types";
import { useScreenSize } from "app/hooks";
import { ApplicationStatusEmptyCard } from "./application-status-empty-card";
import { JobCard } from "./job-card";
import { JobCardLoading } from "./job-card.loading";

const MY_JOBS_QUERY = gql`
  query MyJobs($types: [MatchResponseType!]!, $after: String) {
    myMatchResponses(where: { type: { in: $types } }, _cursor: $after) {
      data {
        id
        job {
          ...JobCardProps
        }
      }
      count
      after
    }
    me {
      id
      ... on CandidateV2 {
        applicationsRemaining
      }
    }
  }

  ${JobCard.fragments.job}
`;

interface JobsListContentProps {
  data: JobCardPropsFragment[];
}

const JobsListColumn = ({ data }: JobsListContentProps) => {
  return (
    <FlatList
      data={data}
      renderItem={({ item }) => <JobCard jobId={item.id} sx={{ mb: "$3" }} />}
    />
  );
};

const JobsListWrapContainer = styled(Row)({
  flexWrap: "wrap",
  mt: (theme) => [0 - theme.space.$3, 0 - theme.space.$4],
  ml: (theme) => 0 - theme.space.$4,
  flex: 1,
});
const JobsListWrapItem = styled(View)({
  width: ["100%", null, "50%"],
  pl: "$4",
});

const JobsListWrap = ({ data }: JobsListContentProps) => (
  <JobsListWrapContainer>
    {data.map(({ id }) => (
      <JobsListWrapItem key={id}>
        <JobCard jobId={id} sx={{ mb: 0, mt: "$4" }} />
      </JobsListWrapItem>
    ))}
  </JobsListWrapContainer>
);

const JobsListLoading = () => (
  <JobsListWrapContainer>
    {Array.from({ length: 4 }, (_, i) => (
      <JobsListWrapItem key={i}>
        <JobCardLoading sx={{ mt: ["$3", "$4"] }} />
      </JobsListWrapItem>
    ))}
  </JobsListWrapContainer>
);

interface JobsListProps {
  type: CandidateMatchResponseType;
}

const ApplicationLimitAlert = ({
  applicationsRemaining,
}: {
  applicationsRemaining: number;
}) => {
  const variant = (() => {
    if (applicationsRemaining === 0) return "error";
    if (applicationsRemaining === 1) return "warning";
    if (applicationsRemaining === 2) return "info";
  })();

  const copy = (() => {
    if (applicationsRemaining === 0)
      return "You have reached your application limit and won't be able to apply to more jobs unless you withdraw or are removed from one of your current applications.";
    if (applicationsRemaining === 1) return "You have 1 application left.";
    if (applicationsRemaining === 2) return "You have 2 applications left.";
  })();

  return (
    <Alert sx={{ mb: "$4" }} variant={variant}>
      {copy}
    </Alert>
  );
};

const JobsList = ({ type }: JobsListProps) => {
  const { isMobile } = useScreenSize();
  const { data, error, networkStatus, fetchMore } = useMyJobsQuery({
    variables: { types: getMatchResponseTypes(type) },
    notifyOnNetworkStatusChange: true,
  });

  if (networkStatus === NetworkStatus.loading) return <JobsListLoading />;
  if (!data || error) return <DataError />;

  const jobs = data.myMatchResponses.data.map(({ job }) => job);

  if (jobs.length === 0) return <ApplicationStatusEmptyCard type={type} />;

  return (
    <QueryButtonPagination
      after={data.myMatchResponses.after}
      fetchMore={fetchMore}
      networkStatus={networkStatus}
    >
      {type === MatchResponseType.Apply &&
        data.me.__typename === "CandidateV2" &&
        data.me.applicationsRemaining < 3 && (
          <ApplicationLimitAlert
            applicationsRemaining={data.me.applicationsRemaining}
          />
        )}
      {isMobile ? <JobsListColumn data={jobs} /> : <JobsListWrap data={jobs} />}
    </QueryButtonPagination>
  );
};

export { JobsList, MY_JOBS_QUERY };
