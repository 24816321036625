import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useRedirect } from "app/hooks/use-redirect";
import { AppLoader } from "app/components/app-loader";
import { useAuth } from "app/use-auth";
import { useCurrentPathname } from "app/hooks";
import { pagesPath } from "app/lib/$path";

import {
  AppScreenName,
  APP_SCREEN_NAMES,
  AuthScreenName,
  AUTH_SCREEN_NAMES,
  UNPROTECTED_SCREEN_NAMES,
} from "app/navigation/config";
import { AuthorizationCheck, useAuthorizer } from "../authorization";

const useAuthenticator = () => {
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const pathname = useCurrentPathname();
  const { redirect, pathWithRedirectToCurrentLocation } = useRedirect();

  const lastAuthenticated = useRef(isAuthenticated);
  useEffect(() => {
    lastAuthenticated.current = isAuthenticated;
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    authenticate: () => {
      if (UNPROTECTED_SCREEN_NAMES.includes(pathname)) return false;
      /**
       * we don't care if we're at /register/[step], it's still unprotected.
       * likewise, we don't care if we're at /community/question/[id], it's still protected
       */
      const parsedPathname = pathname.split("/")[1] ?? "";
      const redirectConditions = [
        ["", ...APP_SCREEN_NAMES].includes(parsedPathname as AppScreenName) &&
          !isAuthenticated,
        AUTH_SCREEN_NAMES.includes(parsedPathname as AuthScreenName) &&
          isAuthenticated,
      ];

      const pathnameWithoutLeadingSlash = pathname.replace(/^\/+/g, "");
      const redirectFromAuthedRoute =
        !lastAuthenticated.current && pathnameWithoutLeadingSlash !== ""
          ? pathWithRedirectToCurrentLocation(pagesPath.welcome.$url().pathname)
          : pagesPath.welcome.$url();

      if (redirectConditions[0]) router.push(redirectFromAuthedRoute);
      if (redirectConditions[1])
        router.push(redirect ? redirect : pagesPath.$url());

      return redirectConditions.some((condition) => condition);
    },
  };
};

interface AuthProps {
  children: React.ReactNode;
  authorizationRule?: AuthorizationCheck<any> | undefined;
}

const AuthRedirect = ({ children, authorizationRule }: AuthProps) => {
  const router = useRouter();

  // TODO: Encapsulate this better (see also native navigator)
  const { authenticate, isAuthenticated } = useAuthenticator();
  const { authorize, authorizing } = useAuthorizer();
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    const isRedirecting = authenticate();
    setRedirecting(isRedirecting);
    if (!isRedirecting) {
      authorize(authorizationRule);
    }
  }, [router.asPath, redirecting, isAuthenticated]);

  if (authorizing || redirecting) return <AppLoader />;

  return <>{children}</>;
};

export type { AuthProps };
export { AuthRedirect };
