import { Row, Sx, View } from "dripsy";
import { createContext, useContext } from "react";
import { ViewProps } from "react-native";

const GridContext = createContext<{ gutter: number } | null>(null);

interface GridProps extends ViewProps {
  gutter?: number;
  sx?: Sx;
}

const Grid = ({ gutter = 24, sx = {}, children, ...rest }: GridProps) => {
  return (
    <Row sx={{ marginLeft: 0 - gutter, flexWrap: "wrap", ...sx }} {...rest}>
      <GridContext.Provider value={{ gutter }}>{children}</GridContext.Provider>
    </Row>
  );
};

interface GridItemProps extends ViewProps {
  widths: (number | null | undefined)[];
  sx?: Sx;
}

const GridItem = ({ widths, sx = {}, ...rest }: GridItemProps) => {
  const gridData = useContext(GridContext);

  if (!gridData) throw new Error("A GridItem must have a Grid parent");

  return (
    <View
      sx={{
        paddingLeft: gridData.gutter,
        width: widths.map((width) => (width ? `${width * 100}%` : null)),
        ...sx,
      }}
      {...rest}
    />
  );
};

export { Grid, GridItem };
