import React from "react";
import { Row, styled, SxProp, Text, useDripsyTheme, View } from "dripsy";
import { Skeleton } from "native-base";
import { titleCase } from "title-case";
import { Feather } from "@expo/vector-icons";
import { gql } from "@apollo/client";
import {
  CandidateV2,
  Maybe,
  Scalars,
  UserHeaderQuery,
  useUserHeaderQuery,
} from "app/types/generated/schema";
import { FCC } from "app/types/index";
import { Avatar, AvatarLoading } from "app/design/avatar";
import { QueryWrapper } from "app/components/query-wrapper";
import { Username } from "app/components/username";

export const USER_HEADER_FRAGMENT = gql`
  fragment UserHeaderFragment on User {
    id
    forenames
    photoUrl
    isActive
    ... on Candidate {
      countryInfo {
        name
      }
    }
    ... on CandidateV2 {
      countryInfo {
        name
      }
      profession {
        id
        name
      }
    }
  }
`;

export const USER_HEADER_QUERY = gql`
  ${USER_HEADER_FRAGMENT}
  query UserHeader($userId: MigrateID!) {
    user(id: $userId) {
      ...UserHeaderFragment
    }
  }
`;

const Container = styled(Row)({
  mb: "$3",
  alignItems: "center",
});

const DescriptionContainer = styled(View)({
  justifyContent: "center",
  ml: "$3",
  flexShrink: 1,
});

export interface LoadingContentProps {
  variant?: "lg";
}

const LoadingContent: React.FC<LoadingContentProps> = ({ variant }) => (
  <>
    <AvatarLoading size={variant ? 16 : 12} sx={{ mr: "$2" }} />
    <Skeleton.Text flex={0.5} lines={2} w={150} />
  </>
);

interface UserHeaderLoadingProps extends LoadingContentProps {
  sx?: SxProp;
}

export const UserHeaderLoading: React.FC<UserHeaderLoadingProps> = ({
  sx,
  ...loadingContentProps
}) => (
  <Container sx={sx}>
    <LoadingContent {...loadingContentProps} />
  </Container>
);

export interface BaseContentProps {
  __typename?: string;
  userId?: Scalars["MigrateID"];
  name: string;
  photoUrl?: Maybe<string>;
  variant?: "lg";
  isActive?: boolean;
}

export const BaseContent: FCC<BaseContentProps> = ({
  __typename,
  userId,
  name,
  photoUrl,
  isActive,
  children,
  variant,
}) => {
  const { theme } = useDripsyTheme();
  return (
    <>
      <Avatar
        userId={userId}
        photoUrl={photoUrl}
        isActive={isActive}
        size={variant ? 16 : 10}
      />
      <DescriptionContainer>
        <Username
          sx={{
            ...(variant ? theme.h3 : theme.h4),
            my: "$0",
          }}
          user={{ displayName: name, __typename }}
        />
        {children}
      </DescriptionContainer>
    </>
  );
};

const ErrorContent = ({
  variant,
}: Omit<BaseContentProps, "__typename" | "name">) => {
  const { theme } = useDripsyTheme();
  return (
    <BaseContent variant={variant} name="<Unknown>" __typename="Unknown">
      <View sx={{ flexDirection: "row", alignItems: "center" }}>
        <Feather name="alert-triangle" color={theme.colors.$error} size={16} />
        <Text sx={{ color: "$error", ml: "$2" }}>Unable to fetch user</Text>
      </View>
    </BaseContent>
  );
};

export interface ApplicantHeaderProps extends BaseContentProps {
  professionName?: string;
  countryName?: string;
}

export const ApplicantHeader: React.FC<ApplicantHeaderProps> = ({
  professionName,
  countryName,
  ...baseContentProps
}) => {
  const description = [professionName, countryName]
    .filter((x): x is string => !!x)
    .map(titleCase)
    .join(", ");

  return (
    <BaseContent {...baseContentProps}>
      <Text variant="sm">{description}</Text>
    </BaseContent>
  );
};

interface UserHeaderProps {
  userId: Scalars["MigrateID"];
  variant?: "lg";
  sx?: SxProp;
  children?: (
    user: Exclude<UserHeaderQuery["user"], undefined>
  ) => React.ReactNode;
}

const UserHeaderComponent = ({
  userId,
  variant,
  sx,
  children,
}: UserHeaderProps) => {
  const queryResult = useUserHeaderQuery({
    variables: {
      userId,
    },
  });

  return (
    <Container sx={sx}>
      <QueryWrapper
        queryHookResult={queryResult}
        loaderComponent={() => <LoadingContent variant={variant} />}
        errorComponent={() => <ErrorContent variant={variant} />}
      >
        {({ data }) => {
          const { user } = data;

          if (children) return children(user);

          switch (user.__typename) {
            case "Candidate":
              return (
                <ApplicantHeader
                  __typename={user.__typename}
                  userId={user.id}
                  name={user.forenames}
                  countryName={user.countryInfo?.name}
                  photoUrl={user.photoUrl}
                  variant={variant}
                  isActive={user.isActive}
                />
              );
            case "CandidateV2":
              return (
                <ApplicantHeader
                  __typename={user.__typename}
                  userId={user.id}
                  name={user.forenames}
                  professionName={(user as CandidateV2).profession?.name}
                  countryName={user.countryInfo?.name}
                  photoUrl={user.photoUrl}
                  isActive={user.isActive}
                  variant={variant}
                />
              );
            case "Staff":
              return (
                <BaseContent
                  __typename={user.__typename}
                  userId={user.id}
                  name={user.forenames}
                  photoUrl={user.photoUrl}
                  variant={variant}
                  isActive={user.isActive}
                >
                  <Text variant="sm">Migrate Staff</Text>
                </BaseContent>
              );
            default:
              return (
                <BaseContent
                  __typename={user.__typename}
                  userId={user.id}
                  name={user.forenames}
                  photoUrl={user.photoUrl}
                  variant={variant}
                  isActive={user.isActive}
                />
              );
          }
        }}
      </QueryWrapper>
    </Container>
  );
};

UserHeaderComponent.fragments = {
  user: USER_HEADER_FRAGMENT,
};

export const UserHeader = UserHeaderComponent;
