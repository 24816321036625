import { gql } from "@apollo/client";

export const JOB_PREFERENCES_APPLICANT_PROPS = gql`
  fragment JobPreferencesApplicantProps on Applicant {
    id
    profession {
      id
      name
    }
    country
  }
`;

export const JOB_PREFERENCES_CANDIDATE_V2_PROPS = gql`
  fragment JobPreferencesCandidateV2Props on CandidateV2 {
    id
    profession {
      id
      name
    }
    country
    countryInfo {
      name
    }
    tagPreferences {
      id
      name
    }
    locationPreferences {
      id
      name
    }
    searchStage
    hasSetPreferences
  }
`;

export const UPDATE_JOB_PREFERENCES_MUTATION = gql`
  mutation UpdateJobPreferences($input: UpdateCurrentCandidateInput!) {
    updateCurrentCandidate(input: $input) {
      id
      ...JobPreferencesCandidateV2Props
    }
  }
  ${JOB_PREFERENCES_CANDIDATE_V2_PROPS}
`;
