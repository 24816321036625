import { CHANNEL } from "app/client";
import { EnglishLanguageOption } from "./steps/language";

enum YesNoOption {
  YES = "YES",
  NO = "NO",
}

type YesNoTuple = [YesNoOption, string];

const yesNoOptions: YesNoTuple[] = [
  [YesNoOption.YES, "Yes"],
  [YesNoOption.NO, "No"],
];

const PROFESSION_IDS = (() => {
  if (CHANNEL === "prod")
    return {
      nurse: "professions-301542226929912327",
      doctor: "professions-301542226929911303",
      socialWorker: "professions-321144949075607750",
      speechAndLanguageTherapist: "professions-324938966754132161",
    };

  if (CHANNEL === "test")
    return {
      nurse: "professions-306461594800357574",
      doctor: "professions-306461594800358598",
      socialWorker: "professions-321144966330974406",
      speechAndLanguageTherapist: "professions-324938908812968129",
    };

  return {
    nurse: "professions-306457646296203458",
    doctor: "professions-306457646297252034",
    socialWorker: "professions-321144978621333702",
    speechAndLanguageTherapist: "professions-324938624147652801",
  };
})();

const CHANNEL_IDS = (() => {
  if (CHANNEL === "prod")
    return {
      nmc: "channels-301542227150112263",
      gmc: "channels-301542227150111239",
      swe: "channels-321844205754253511",
      hcpc: "channels-345309299734479051",
      engLang: "channels-301542227150113287",
      students: "channels-345310056464515275",
    };

  if (CHANNEL === "test")
    return {
      nmc: "channels-342967413437890762",
      gmc: "channels-342967413432647882",
      swe: "channels-342967413404336330",
      hcpc: "channels-345309268627423435",
      engLang: "channels-342967413406434506",
      students: "channels-345309998294761675",
    };

  return {
    nmc: "channels-339076085534687435",
    gmc: "channels-339076085530493131",
    swe: "channels-339076085495891147",
    hcpc: "channels-345309164285722827",
    engLang: "channels-339076085501133003",
    students: "channels-339076085534687435",
  };
})();

const getMinLanguageTestRequirements = (
  professionId: string,
  engLang: Exclude<
    EnglishLanguageOption,
    EnglishLanguageOption.NA | EnglishLanguageOption.EXEMPT
  >
) => {
  const oetRequirements = {
    [EnglishLanguageOption.OET]:
      "Did you score at least C+ in the writing section and at least B in the other sections?",
  };

  if ([PROFESSION_IDS.nurse, PROFESSION_IDS.doctor].includes(professionId)) {
    return {
      [EnglishLanguageOption.IELTS]:
        "Did you score at least 6.5 in the writing section and at least 7 in the other sections?",
      ...oetRequirements,
    }[engLang];
  }

  if (professionId === PROFESSION_IDS.speechAndLanguageTherapist) {
    return {
      [EnglishLanguageOption.IELTS]:
        "Did you score at least 7.5 in all sections?",
      ...oetRequirements,
    }[engLang];
  }

  return {
    [EnglishLanguageOption.IELTS]:
      "Did you score at least 6.5 in all sections?",
    ...oetRequirements,
  }[engLang];
};

export {
  YesNoOption,
  yesNoOptions,
  getMinLanguageTestRequirements,
  PROFESSION_IDS,
  CHANNEL_IDS,
};
