import { memo } from "react";
import isEqual from "react-fast-compare";
import { LanguageResultSchema } from "app/types/generated/schema";
import { ProfileCard } from "../../card";
import { useLanguageResultMutations } from "../hooks/use-language-result-mutations";
import { isTempField } from "../profile-form";
import { LanguageResultFormCard, LanguageResultFormValues } from "./form-card";

interface LanguageResultCardProps {
  field: LanguageResultFormValues;
  onCancel: () => void;
  resultSchema?: LanguageResultSchema;
}

export const LanguageResultCard = memo(
  ({ field, onCancel, resultSchema }: LanguageResultCardProps) => {
    const { id, awardingBody, date } = field;
    const { deleteLanguageResult } = useLanguageResultMutations(id);
    const completed = !isTempField({ id });

    return (
      <ProfileCard
        header={awardingBody || ""}
        startDate={date}
        isDateRange={false}
        form={
          <LanguageResultFormCard
            defaultValues={field}
            onCancel={onCancel}
            resultSchema={resultSchema}
          />
        }
        onDelete={deleteLanguageResult}
        showFormOnRender={!completed}
      />
    );
  },
  (a, b) => isEqual(a.field, b.field)
);
LanguageResultCard.displayName = "LanguageResultCard";
