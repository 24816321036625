import { UrlObject } from "url";
import { useDripsyTheme, useSx } from "dripsy";
import { AnimatePresence, MotiView } from "moti";
import { MotiPressable } from "moti/interactions";
import { useLink } from "solito/link";
import { Platform } from "react-native";
import { alphaColor } from "app/design/utils";
import { ColorAnimatedView } from "app/components/color-animated-view";
import { FCC } from "app/types";

interface SubmenuItemProps {
  href: UrlObject;
}

const SubmenuItem: FCC<SubmenuItemProps> = ({ href, children }) => {
  const { theme } = useDripsyTheme();
  const linkProps = useLink({ href });
  const sx = useSx();

  return (
    <MotiPressable containerStyle={sx({ my: 6 })} {...linkProps}>
      <ColorAnimatedView
        as="Text"
        colorStates={{
          color: {
            states: [["hovered", theme.colors.$orange]],
            initialColor: theme.colors.$white,
          },
        }}
        sx={{
          whiteSpace: "nowrap",
          fontWeight: "500",
          fontSize: 16,
          lineHeight: 24,
        }}
      >
        {children}
      </ColorAnimatedView>
    </MotiPressable>
  );
};

type SubmenuConfig = {
  href: UrlObject;
  label: string;
};

interface SubmenuProps {
  visible: boolean;
  items: SubmenuConfig[];
}

const Submenu = ({ visible, items }: SubmenuProps) => {
  const { theme } = useDripsyTheme();
  const sx = useSx();

  if (Platform.OS !== "web") return null;

  return (
    <AnimatePresence>
      {visible ? (
        <MotiView
          from={{
            opacity: 0,
            translateX: -8,
          }}
          animate={{
            opacity: 1,
            translateX: 0,
          }}
          exit={{
            opacity: 0,
            translateX: 0,
          }}
          transition={{
            type: "timing",
            duration: theme.transitionDurations.normal,
          }}
          style={sx({
            position: "absolute",
            left: "100%",
            ml: "$1",
            top: 0,
            backgroundColor: alphaColor(theme.colors.$black, 0.95),
            borderWidth: 2,
            borderColor: alphaColor(theme.colors.$black, 0.2),
            boxShadow: "default",
            zIndex: 1,
            borderRadius: "md",
            px: "$6",
            py: 6,
            backdropFilter: "blur(5px)",
          })}
        >
          <MotiView
            style={sx({
              position: "absolute",
              right: "100%",
              top: 16,
              borderWidth: 8,
              borderColor: "transparent",
              borderRightColor: "#463a38", // alphaColor(theme.colors.$black, 0.95),
            })}
            from={{
              opacity: 0,
              translateX: 8,
            }}
            animate={{
              opacity: 1,
              translateX: 0,
            }}
            exit={{
              opacity: 0,
              translateX: 8,
            }}
            transition={{
              type: "timing",
              duration: theme.transitionDurations.normal,
            }}
          />
          {items.map(({ href, label }) => (
            <SubmenuItem href={href} key={label}>
              {label}
            </SubmenuItem>
          ))}
        </MotiView>
      ) : null}
    </AnimatePresence>
  );
};

export type { SubmenuConfig };
export { Submenu };
