import { gql } from "@apollo/client";
import { Target } from "@nandorojo/anchor";
import { useApplyFormQuery } from "app/types/generated/schema";
import { DataError } from "app/components/data-error";
import {
  CareerExperienceSection,
  EducationExperienceSection,
  LanguageResultsSection,
  QuestionResponsesSection,
  RegistrationDetailsSection,
} from "app/features/profile/forms";
import { PassportSection } from "app/features/profile/passport-section";
import { ApplicationQuestionsSection } from "./application-question";
import { APPLICATION_SCREEN_TARGETS } from "./application-progress/use-application-completion-checks";
import { ApplyTutorial } from "./apply-tutorial";
import { ApplicationQuestionsLoading } from "./application-question/loading";

export const APPLY_SCREEN_QUERY = gql`
  query ApplyForm($jobId: MigrateID!) {
    matchResponseByJob(jobId: $jobId) {
      id
      job {
        id
        title
      }
      ... on ApplicationStartMatchResponse {
        applicationQuestion {
          question
          response
        }
        applicationCompletion
      }
    }
  }
`;

export const ApplyForm = ({ jobId }: { jobId: string }) => {
  const { data, error } = useApplyFormQuery({
    variables: { jobId },
  });

  if (error) return <DataError />;

  return (
    <>
      <ApplyTutorial />
      <Target name={APPLICATION_SCREEN_TARGETS.applicationQuestion}>
        {data ? (
          <ApplicationQuestionsSection
            matchResponseId={data.matchResponseByJob.id}
          />
        ) : (
          <ApplicationQuestionsLoading />
        )}
      </Target>
      <Target name={APPLICATION_SCREEN_TARGETS.passport}>
        <PassportSection showHeader />
      </Target>
      <Target name={APPLICATION_SCREEN_TARGETS.workExperience}>
        <CareerExperienceSection />
      </Target>
      <Target name={APPLICATION_SCREEN_TARGETS.educationHistory}>
        <EducationExperienceSection />
      </Target>
      <Target name={APPLICATION_SCREEN_TARGETS.registrationDetails}>
        <RegistrationDetailsSection />
      </Target>
      <Target name={APPLICATION_SCREEN_TARGETS.languageQualifications}>
        <LanguageResultsSection />
      </Target>
      <QuestionResponsesSection showCompletedStatus={false} />
    </>
  );
};
