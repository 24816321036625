import { useReplyToMessage, UseReplyToMessageProps } from "../../hooks";
import {
  ClosingActionsheetItem,
  ClosingActionsheetItemProps,
} from "./action-sheet-item";

type ReplyToMessageItemProps = Omit<
  ClosingActionsheetItemProps,
  "iconName" | "onPress" | "children"
> &
  UseReplyToMessageProps;

const ReplyToMessageItem = ({ message, ...props }: ReplyToMessageItemProps) => {
  const replyToMessage = useReplyToMessage({ message });

  return (
    <ClosingActionsheetItem
      {...props}
      iconName="corner-down-left"
      onPress={replyToMessage}
    >
      Reply
    </ClosingActionsheetItem>
  );
};

export type { ReplyToMessageItemProps };
export { ReplyToMessageItem };
