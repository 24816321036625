import { useEffect, useRef, useState } from "react";
import { isTempField } from "../profile-form";

interface ProfileEntryBase {
  id: string;
}

interface UseProfileEntryListProps<T extends ProfileEntryBase> {
  defaultValues?: T[];
}
export const useProfileEntryList = <T extends ProfileEntryBase>({
  defaultValues = [],
}: UseProfileEntryListProps<T>) => {
  const [fields, setFields] = useState<T[]>(defaultValues);

  const prevDefaultValues = useRef(defaultValues);

  // make sure fields updated when async default values are set
  useEffect(() => {
    if (
      defaultValues &&
      JSON.stringify(defaultValues) !==
        JSON.stringify(prevDefaultValues.current)
    ) {
      prevDefaultValues.current = defaultValues;

      setFields((currentFields) => [
        // don't overwrite temporary fields when default values change
        ...currentFields.filter((field) => isTempField(field)),
        ...defaultValues,
      ]);
    }
  }, [defaultValues]);

  const append = (input: T) => {
    setFields([...fields, input]);
  };

  const remove = (id: string) => {
    setFields((currentFields) => currentFields.filter((f) => f.id !== id));
  };

  const setField = (id: string, value: Partial<T>) =>
    setFields((currentFields) =>
      currentFields.map((f) => (f.id === id ? { ...f, ...value } : f))
    );

  return { fields, append, remove, setField, setFields };
};
