import { H4, Row, Text, useDripsyTheme, View } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { Menu } from "app/components/menu";
import { MenuTriggerButton } from "app/components/menu-trigger";
import { useProfileCardContext } from "./context";

interface ProfileCardMenuProps {
  onDelete: () => void;
}
const ProfileCardMenu = ({ onDelete }: ProfileCardMenuProps) => {
  const { theme } = useDripsyTheme();
  const { setShowForm } = useProfileCardContext();
  return (
    <Menu
      trigger={(triggerProps) => (
        <MenuTriggerButton
          variant="ghost"
          containerSx={{ p: 0, alignSelf: "flex-start" }}
          sx={{ py: 0, px: 0 }}
          icon={
            <Feather
              name="more-vertical"
              color={theme.colors.$black}
              size={24}
            />
          }
          accessibilityLabel="More"
          {...triggerProps}
        />
      )}
      placement="bottom right"
    >
      <Menu.Item onPress={() => setShowForm(true)}>Update</Menu.Item>
      <Menu.Item onPress={onDelete}>Delete</Menu.Item>
    </Menu>
  );
};

export interface ProfileCardHeaderProps extends ProfileCardMenuProps {
  header?: string;
  subheader?: string;
}

export const ProfileCardHeader = ({
  header,
  subheader,
  onDelete,
}: ProfileCardHeaderProps) => (
  <Row sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
    <View sx={{ flexShrink: 1 }}>
      <H4 sx={{ mb: "$1" }}>{header}</H4>
      <Text>{subheader}</Text>
    </View>
    <ProfileCardMenu onDelete={onDelete} />
  </Row>
);
