import { styled, View } from "dripsy";
import { STEP_SCREEN_FORM_MAX_WIDTH } from "app/components/step-screen-form";

const CareerQuizStepContainer = styled(View)({
  marginTop: (theme) => 0 - theme.space.$2,
  width: "100%",
  maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
});

const CareerQuizOption = styled(View)({
  mt: "$2",
});

export { CareerQuizStepContainer, CareerQuizOption };
