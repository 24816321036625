import { Sx } from "dripsy";
import { useThemeValue } from "app/hooks/use-theme-value";
import { Accent, DEFAULT_ACCENT_WIDTH } from "app/design/card";
import { ButtonLink } from "app/design/button";
import {
  OverviewCard,
  OverviewHeader,
  OverviewText,
} from "app/components/overview-card";
import { pagesPath } from "app/lib/$path";

export const TakeQuizCard = ({ sx }: { sx: Sx }) => {
  const cardPadding = useThemeValue((theme) => theme.card.default.p);

  return (
    <OverviewCard
      variant="default"
      sx={{ pl: cardPadding + DEFAULT_ACCENT_WIDTH, ...sx }}
    >
      <Accent />
      <OverviewHeader sx={{ color: "$blue" }}>
        Need help getting started?
      </OverviewHeader>

      <OverviewText sx={{ color: "$text", flexGrow: 1 }}>
        Take our career quiz to see advice and recommendations for your next
        steps.
      </OverviewText>
      <ButtonLink
        containerSx={{ alignSelf: "flex-start" }}
        href={pagesPath.career_quiz.$url()}
        variant="tertiary"
      >
        Take quiz
      </ButtonLink>
    </OverviewCard>
  );
};
