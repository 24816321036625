export const RED_LIST_COUNTRIES = [
  "AF",
  "AO",
  "BD",
  "BJ",
  "BF",
  "BI",
  "CM",
  "CF",
  "TD",
  "CG",
  "CD",
  "CI",
  "DJ",
  "GQ",
  "ER",
  "ET",
  "GA",
  "GM",
  "GH",
  "GN",
  "GW",
  "HT",
  "KI",
  "LS",
  "LR",
  "MG",
  "MW",
  "ML",
  "MR",
  "FM",
  "MZ",
  "NP",
  "NE",
  "NG",
  "PK",
  "PG",
  "SN",
  "SL",
  "SB",
  "SO",
  "SD",
  "TG",
  "UG",
  "TZ",
  "VU",
  "YE",
];

export const ENGLISH_SPEAKING_COUNTRIES = [
  "AG",
  "AU",
  "BS",
  "BB",
  "BZ",
  "CA",
  "DM",
  "GD",
  "GY",
  "IE",
  "JM",
  "NZ",
  "KN",
  "LC",
  "VC",
  "TT",
  "GB",
  "US",
];
