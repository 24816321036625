import { gql, NetworkStatus } from "@apollo/client";
import { H5, useDripsyTheme, View } from "dripsy";
import { memo, useState } from "react";
import { Platform } from "react-native";
import {
  AllCommunityChannelsQuery,
  Scalars,
  useAllCommunityChannelsQuery,
} from "app/types/generated/schema";
import { Card } from "app/design/card";
import {
  QueryInfiniteScrollFlatListScreen,
  ListRenderItem,
  ListEmptyComponentText,
} from "app/components/screen/query-infinite-scroll-flat-list-screen";
import { Screen } from "app/components/screen";
import {
  FlatListBottomSpacer,
  FlatListItemWithSidebar,
  FlatListLoadingMore,
  FlatListSidebar,
} from "app/components/flat-list.common";
import { Layout } from "app/features/layout";
import { pagesPath } from "app/lib/$path";

import { conditionalParam } from "app/utils";

import { useEffectAfterRender } from "app/hooks";

import { FCC } from "app/types";
import { ScreenComponent } from "app/navigation/types";
import {
  ChannelCategoriesFilter,
  ChannelCategoriesFilterLoading,
} from "./channel/channel-categories-filter";

import {
  OverviewChannelCard,
  OverviewChannelCardLoading,
} from "./channel/overview-channel-card";

const isAndroid = Platform.OS === "android";

const ALL_COMMUNITY_CHANNELS_QUERY = gql`
  query AllCommunityChannels(
    $size: Int
    $after: String
    $orderBy: ChannelsOrderBy
    $where: CommunityPublicChannelsWhereInput
  ) {
    communityPublicChannels(
      where: $where
      orderBy: $orderBy
      _size: $size
      _cursor: $after
    ) {
      data {
        ...OverviewCommunityChannelCardChannelProps
        isMember
      }
      after
    }

    channelCategories {
      data {
        ...ChannelCategoryFilterProps
      }
    }
  }
  ${OverviewChannelCard.fragments.communityPublicChannel}
  ${ChannelCategoriesFilter.fragments.channelCategory}
`;

const Sidebar: FCC = ({ children }) => (
  <FlatListSidebar>
    <Card variant="filters" sx={{ mb: "$4" }}>
      <H5 sx={{ mb: "$4" }}>Categories</H5>
      {children}
    </Card>
  </FlatListSidebar>
);

const CommunityChannelsScreenDataLoading = () => (
  <>
    {Array.from({ length: 8 }).map((_, i) => (
      <OverviewChannelCardLoading key={i} />
    ))}
  </>
);

const CommunityChannelsScreenLoading = () => (
  <Screen scrollable={false}>
    <Sidebar>
      <ChannelCategoriesFilterLoading />
    </Sidebar>
    <FlatListItemWithSidebar>
      <CommunityChannelsScreenDataLoading />
    </FlatListItemWithSidebar>
  </Screen>
);

type CommunityChannel =
  AllCommunityChannelsQuery["communityPublicChannels"]["data"][number];

const renderItem: ListRenderItem<CommunityChannel> = ({ item: channel }) => (
  <FlatListItemWithSidebar
    sx={{
      mx: (theme) => (isAndroid ? theme.layout.screen.container.padding : "$0"),
    }}
  >
    <OverviewChannelCard
      channelId={channel.id}
      headerProps={{
        showMembership: true,
      }}
      href={pagesPath.community.channels._channelId(channel.id).$url()}
    />
  </FlatListItemWithSidebar>
);

const CommunityChannelsScreen: ScreenComponent = memo(
  () => {
    const [categoryIds, setCategoryIds] = useState<Scalars["MigrateID"][]>([]);
    const { theme } = useDripsyTheme();

    const queryResponse = useAllCommunityChannelsQuery({
      variables: {
        size: 10,
      },
      notifyOnNetworkStatusChange: true,
    });

    useEffectAfterRender(() => {
      queryResponse.refetch({
        where: {
          ...conditionalParam(
            {
              category: {
                in: categoryIds,
              },
            },
            categoryIds.length > 0
          ),
        },
      });
    }, [categoryIds]);

    const { data, after } = queryResponse.data?.communityPublicChannels || {};

    return (
      <QueryInfiniteScrollFlatListScreen
        queryHookResult={queryResponse}
        data={data}
        after={after}
        keyExtractor={(channel) => channel.id}
        renderItem={renderItem}
        // Show data loading state and allow header to handle own loading
        loading={({ networkStatus }) => networkStatus === NetworkStatus.loading}
        renderLoaderComponent={() => <CommunityChannelsScreenLoading />}
        stickyHeaderIndices={[0]}
        containerSx={{ width: "100%" }}
        // workaround for no overflow: visible in scroll views on android
        sx={isAndroid ? { px: 0, pt: 0 } : {}}
        renderListHeaderComponent={() => (
          <View
            sx={{
              pt: isAndroid ? theme.layout.screen.container.padding : "$0",
              px: isAndroid ? theme.layout.screen.container.padding : "$0",
            }}
          >
            <Sidebar>
              <ChannelCategoriesFilter
                onChange={(categories) => setCategoryIds(categories)}
              />
            </Sidebar>
          </View>
        )}
        renderListFooterComponent={({ networkStatus }) =>
          networkStatus === NetworkStatus.fetchMore ? (
            <FlatListItemWithSidebar>
              <FlatListLoadingMore sx={{ mb: "$4" }} />
            </FlatListItemWithSidebar>
          ) : (
            <FlatListBottomSpacer sx={{ borderTopWidth: 0, mb: "$4" }} />
          )
        }
        renderListEmptyComponent={({ networkStatus }) =>
          [NetworkStatus.loading, NetworkStatus.setVariables].includes(
            networkStatus
          ) ? (
            <FlatListItemWithSidebar>
              <CommunityChannelsScreenDataLoading />
            </FlatListItemWithSidebar>
          ) : (
            <FlatListItemWithSidebar>
              <ListEmptyComponentText>No channels found</ListEmptyComponentText>
            </FlatListItemWithSidebar>
          )
        }
      />
    );
  },
  () => true
);

const headerProps = {
  backButtonType: "back" as const,
  backHref: pagesPath.community.$url(),
  title: "Channels",
  showTitleOnDesktop: true,
  headerSx: {
    boxShadow: [null, "default"],
  },
};

CommunityChannelsScreen.displayName = "CommunityChannelsScreen";
CommunityChannelsScreen.headerProps = headerProps;
CommunityChannelsScreen.getLayout = (page: React.ReactElement) => {
  return <Layout headerProps={headerProps}>{page}</Layout>;
};

export { ALL_COMMUNITY_CHANNELS_QUERY, CommunityChannelsScreen };
