import { memo } from "react";
import { SxProp, useDripsyTheme, View } from "dripsy";
import { FCC } from "app/types/index";
import { Button, ButtonProps } from "app/design/button";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { STEP_SCREEN_FORM_MAX_WIDTH } from "./constants";

interface FooterButtonProps {
  sx: SxProp;
  variant?: ButtonProps["variant"];
  disabled?: boolean;
  loading?: boolean;
  onPress: () => void;
}

const FooterButton: FCC<FooterButtonProps> = ({
  sx,
  variant,
  disabled,
  loading,
  onPress,
  children,
}) => (
  <View sx={sx}>
    <Button
      onPress={onPress}
      variant={variant}
      size="lg"
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Button>
  </View>
);

const Footer: React.FC<{
  submitting?: boolean;
  disabled?: boolean;
  onBack: () => void;
  onNext: () => void;
  children: React.ReactNode;
}> = memo(({ submitting, onBack, onNext, disabled, children = "Next" }) => {
  const safeArea = useSafeArea();
  const { theme } = useDripsyTheme();

  return (
    <View
      sx={{
        alignItems: "center",
        p: "$4",
        pt: ["$4", "$6"],
        pb: [
          safeArea.bottom + theme.space.$4,
          safeArea.bottom + theme.space.$6,
        ],
      }}
    >
      <View
        sx={{
          flexDirection: "row",
          maxWidth: STEP_SCREEN_FORM_MAX_WIDTH,
          width: "100%",
          flexGrow: 1,
        }}
      >
        <FooterButton
          sx={{ pr: "$2", width: "50%" }}
          variant="secondary"
          onPress={onBack}
        >
          Back
        </FooterButton>
        <FooterButton
          sx={{ pl: "$2", width: "50%" }}
          onPress={onNext}
          disabled={disabled}
          loading={submitting}
        >
          {children}
        </FooterButton>
      </View>
    </View>
  );
});
Footer.displayName = "StepScreenFooter";

export { Footer };
