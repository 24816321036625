import axios from "axios";
import { EncodingType, readAsStringAsync } from "expo-file-system";
import { decode as base64decode } from "base64-arraybuffer";
// @ts-ignore @types/react-native-mime-types package doesn't exist
import { lookup as lookupContentType } from "react-native-mime-types";

type WebFile = File;

interface ExpoFile {
  mimeType?: string;
  name?: string;
  size?: number;
  uri: string;
}

type AnyFile = WebFile | ExpoFile;

const isFileFromExpoFileSystem = (file: AnyFile): file is ExpoFile => {
  return !!(file as ExpoFile)?.uri;
};

const isFile = (file: AnyFile): file is WebFile => {
  return !!(file as WebFile).type;
};

interface ParsedFile {
  file: ArrayBuffer | WebFile;
  contentType: string;
}

const parseNativeFile = async ({
  uri,
  name,
  mimeType,
}: ExpoFile): Promise<ParsedFile> => {
  const file = await readAsStringAsync(uri, {
    encoding: EncodingType.Base64,
  });
  const buffer = base64decode(file);
  const contentType = lookupContentType(name);

  return {
    file: buffer,
    contentType: mimeType || contentType,
  };
};

const parseWebFile = (file: WebFile): ParsedFile => ({
  file,
  contentType: file.type,
});

const executeUpload = async (
  putUrl: string,
  { file, contentType }: ParsedFile
) =>
  axios({
    method: "put",
    url: putUrl,
    data: file,
    headers: {
      "Content-Type": contentType,
    },
  });

const uploadFile = async (putUrl: string, file: AnyFile) => {
  if (isFileFromExpoFileSystem(file)) {
    const parsedFile = await parseNativeFile(file);
    return executeUpload(putUrl, parsedFile);
  }
  if (isFile(file)) return executeUpload(putUrl, parseWebFile(file));
  throw new Error("Unknown file type");
};

export type { AnyFile, ExpoFile, WebFile };
export { uploadFile };
