import { Maybe } from "app/types/generated/schema";

interface GetMessageContentSummaryInput<TAttachment> {
  attachment?: TAttachment;
  content?: Maybe<string>;
}

export const getMessageContentSummary = <TAttachment>({
  attachment,
  content,
}: GetMessageContentSummaryInput<TAttachment>) => {
  if (attachment) return "Sent an attachment";
  if (content) return content;
  return "(message deleted)";
};
