import { gql } from "@apollo/client";
import {
  MatchResponseType,
  Scalars,
  useHideJobMatchMutation,
} from "app/types/generated/schema";
import { useAsyncToast } from "app/components/use-toast";
import { JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS } from "app/graphql/job-matches";
import {
  createRespondToMatchOptimisticResponse,
  updateMyMatchedJobs,
  updateMyMatchResponses,
} from "../cache";

const HIDE_JOB_MATCH_MUTATION = gql`
  mutation HideJobMatch($jobId: MigrateID!) {
    respondToMatch(input: { jobId: $jobId, type: HIDE }) {
      ...JobMatchActionMatchResponseProps
    }
  }
  ${JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS}
`;

interface UseHideJobMatchProps {
  jobId: Scalars["MigrateID"];
  existingMatchResponseId?: Scalars["MigrateID"];
}

const useHideJobMatch = () => {
  const [hideJobMatchMutation] = useHideJobMatchMutation();

  const withToast = useAsyncToast({
    success: null,
    error: {
      title: "Unable to hide match",
      description: "Please try again or contact support.",
    },
  });

  return withToast(
    async ({ jobId, existingMatchResponseId }: UseHideJobMatchProps) => {
      hideJobMatchMutation({
        variables: { jobId },
        optimisticResponse: createRespondToMatchOptimisticResponse({
          __typename: "GenericMatchResponse",
          jobId,
          type: MatchResponseType.Hide,
          existingMatchResponseId,
        }),
        update: (cache, { data }) => {
          const matchResponse = data?.respondToMatch;
          if (!matchResponse) return null;

          cache.modify({
            fields: {
              myMatchedJobs: updateMyMatchedJobs({
                cache,
                matchResponse,
              }),
              myMatchResponses: updateMyMatchResponses({
                cache,
                matchResponse,
                matchResponseType: MatchResponseType.Hide,
              }),
            },
          });
        },
      });
    }
  );
};

export type { UseHideJobMatchProps };
export { HIDE_JOB_MATCH_MUTATION, useHideJobMatch };
