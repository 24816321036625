import { H2, H4, Row, styled, useDripsyTheme, View } from "dripsy";
// @ts-ignore @types/currency-formatter package doesn't exist
import currencyFormatter from "currency-formatter";
import { JobDetailsJobPropsFragment, Maybe } from "app/types/generated/schema";
import { Button } from "app/design/button";
import { OrganisationAvatar } from "app/design/avatar";
import { Text } from "app/components/text";
import { useWebLink } from "app/components/web-link";
import { FCC } from "app/types";

import { DividerSecondary } from "./divider";

const Title = styled(H2)({ textAlign: "center", mb: "$4" });
const Salary: FCC = ({ children }) => {
  const { theme } = useDripsyTheme();
  return (
    <View
      sx={{
        ...theme.alert.success,
        pl: "$4",
        pr: "$4",
        py: "$2",
        borderRadius: "md",
        mb: "$4",
      }}
    >
      <Text
        sx={{
          ...theme.h3,
          textAlign: "center",
          color: theme.alert.success.color,
        }}
      >
        {children}
      </Text>
    </View>
  );
};

const formatCurrency = (value: number) =>
  currencyFormatter.format(value, { code: "GBP" });

interface HeaderProps {
  title: string;
  salary?: Maybe<number[]>;
  organisationName: string;
  organisation: JobDetailsJobPropsFragment["organisation"];
}

const Header = ({
  title,
  salary,
  organisationName,
  organisation,
}: HeaderProps) => {
  const webLinkProps = useWebLink(organisation.url);

  return (
    <>
      <Title>{title}</Title>

      {salary ? (
        <Salary>
          {salary.length > 1
            ? `${formatCurrency(salary[0]!)} - ${formatCurrency(
                salary[1]!
              )} a year`
            : `${formatCurrency(salary[0]!)} a year`}
        </Salary>
      ) : null}

      <Row>
        <OrganisationAvatar organisation={organisation} size={12} />
        <View
          sx={{
            ml: ["$2", "$3"],
            flexShrink: 1,
            alignItems: "flex-start",
            alignSelf: "center",
          }}
        >
          <H4>{organisationName}</H4>

          {organisation.url ? (
            <Button {...webLinkProps} variant="link" size="sm">
              View employer website
            </Button>
          ) : null}
        </View>
      </Row>

      <DividerSecondary />
    </>
  );
};

export { Header };
