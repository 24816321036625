import { createContext, useContext } from "react";

const ProfileCardContext = createContext<{
  showForm: boolean;
  setShowForm: (showForm: boolean) => void;
}>({
  showForm: false,
  setShowForm: () => {},
});

const useProfileCardContext = () => useContext(ProfileCardContext);

export { ProfileCardContext, useProfileCardContext };
