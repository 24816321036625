import {
  init,
  track,
  setUserId,
  reset as sdkReset,
} from "@amplitude/analytics-browser";
import { AMPLITUDE_KEY, IS_LOCAL } from "./config";

(() => {
  if (IS_LOCAL) return;
  init(AMPLITUDE_KEY);
})();

const sendAnalyticsEvent = IS_LOCAL ? () => {} : track;
const setAnalyticsUser = IS_LOCAL ? () => {} : setUserId;
const reset = IS_LOCAL ? () => {} : sdkReset;

export { sendAnalyticsEvent, setAnalyticsUser, reset };
