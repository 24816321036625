// import { Tooltip } from "native-base";
import { H4, Row, useDripsyTheme, View } from "dripsy";
import { Platform, ScrollView, useWindowDimensions } from "react-native";
import { cloneElement, useEffect } from "react";
import { useRouter } from "solito/router";

// import { Badged } from "app/design/badge";
// import { IconButton, IconButtonLink } from "app/design/icon-button";
// import { MigrateIcon } from "app/design/migrate-icon";
import { ButtonIconBag, ButtonLink } from "app/design/button";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { DataError } from "app/components/data-error";
import { Popover } from "app/components/popover";
import { pagesPath } from "app/lib/$path";
import { useCurrentPathname } from "app/hooks";

import {
  NotificationCard,
  NotificationCardLoading,
  NotificationCardGroup,
} from "./notification-card";
import { useErrorFilteredNotificationsQuery } from "./hooks/use-notifications-query";
import { NotificationsEmpty } from "./empty";

const NotificationsContent = () => {
  const { data, loading } = useErrorFilteredNotificationsQuery();

  if (loading)
    return (
      <NotificationCardGroup>
        {Array.from({ length: 10 }, (_, i) => (
          <NotificationCardLoading key={i} />
        ))}
      </NotificationCardGroup>
    );

  if (!data) return <DataError sx={{ m: "$4", width: "auto" }} />;

  const notifications = data.notifications.data;
  if (notifications.length === 0) return <NotificationsEmpty />;

  return (
    <NotificationCardGroup>
      {notifications.map((notification) => (
        <NotificationCard key={notification.id} notification={notification} />
      ))}
    </NotificationCardGroup>
  );
};

interface NotificationMenuProps {
  children: JSX.Element;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const NotificationsMenu = ({
  children,
  isOpen,
  onOpen,
  onClose,
}: NotificationMenuProps) => {
  const router = useRouter();
  // This is here for prefetching to avoid loading state on clicking the menu
  // Should have resolver for `unread` in the future to populate count
  useErrorFilteredNotificationsQuery();
  const pathname = useCurrentPathname();
  const { width } = useWindowDimensions();
  const { theme } = useDripsyTheme();

  useEffect(() => {
    if (!isOpen) return;

    // Popover needs to be manually closed e.g. if navigation happens
    onClose();
  }, [pathname]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        if (Platform.OS === "web") {
          onOpen();
        } else {
          router.push(pagesPath.notifications.$url());
        }
      }}
      onClose={onClose}
      trigger={(triggerProps) =>
        cloneElement(children, {
          ...triggerProps,
          renderIcon: (props: ButtonIconBag) => (
            // TODO: Add `Unread` count (not complete notifications count)
            // Needs specific backend query, which may timeout for big numbers.
            // Will probably implement with a plus datatype on backend (e.g. 99+)
            // to keep things fast.
            // <Badged value={data?.notifications.count}>
            <ColorAnimatedIcon {...props} />
            // </Badged>
          ),
        })
      }
      contentSx={{ maxWidth: 400, width: width - theme.space.$6, mx: "$3" }}
    >
      <Row
        sx={{
          py: "$4",
          px: "$6",
          borderBottomWidth: 1,
          borderColor: "$ui",
          alignItems: "center",
        }}
      >
        <H4 sx={{ flexGrow: 1 }}>Notifications</H4>
        {/* Removing for now to be releasable */}
        {/* <Tooltip label="Mark all as read">
          <View>
            <IconButton
              onPress={() => console.log("Marked all as read")}
              size="xs"
              variant="ghost"
              containerSx={{ ml: "$2" }}
              icon={<MigrateIcon name="mark-all-read" size={18} />}
              accessibilityLabel="Mark all as read"
            />
          </View>
        </Tooltip> */}
        {/* Removing for now to be releaseable */}
        {/* <Tooltip label="Notification settings">
          <View>
            <IconButtonLink
              href={pagesPath.notifications.$url()}
              size="xs"
              variant="ghost"
              containerSx={{ ml: "$2" }}
              icon={<Feather name="settings" size={18} />}
              accessibilityLabel="Notification settings"
            />
          </View>
        </Tooltip> */}
      </Row>
      <ScrollView style={{ maxHeight: 320 }}>
        <NotificationsContent />
      </ScrollView>
      <View
        sx={{
          py: "$3",
          px: "$4",
          borderTopWidth: 1,
          borderColor: "$ui",
          alignItems: "center",
        }}
      >
        <ButtonLink
          variant="link"
          size="sm"
          href={pagesPath.notifications.$url()}
        >
          See all notifications
        </ButtonLink>
      </View>
    </Popover>
  );
};

export { NotificationsMenu };
