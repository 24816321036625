import { Tooltip } from "native-base";
import { cloneElement, ReactElement } from "react";
import { Pressable } from "react-native";

interface DisableButtonWithTooltipProps {
  when: boolean;
  tooltip: string;
  children: ReactElement;
  overrideOnPress?: () => void;
}

export const DisableButtonWithTooltip = ({
  when,
  tooltip,
  children,
  overrideOnPress,
}: DisableButtonWithTooltipProps) => {
  if (!when) return children;

  return (
    <Tooltip label={tooltip}>
      <Pressable onPress={overrideOnPress}>
        {cloneElement(
          children,
          !!overrideOnPress
            ? { disableInteraction: true, disabled: true }
            : { disabled: true }
        )}
      </Pressable>
    </Tooltip>
  );
};
