import { gql } from "@apollo/client";
import { styled, View } from "dripsy";
import { Skeleton } from "native-base";
import {
  Scalars,
  useMessagePreviewQuery,
  MessagePreviewFragmentFragment as MessageFragment,
} from "app/types/generated/schema";
import { QueryWrapper } from "app/components/query-wrapper";
import { Text } from "app/components/text";
import { formatDateConcise } from "app/utils";
import { getMessageContentSummary } from "../utils/message";

export const MESSAGE_PREVIEW_ATTACHMENT_FRAGMENT = gql`
  fragment MessagePreviewAttachmentFragment on Attachment {
    id
  }
`;

export const MESSAGE_PREVIEW_AUTHOR_FRAGMENT = gql`
  fragment MessagePreviewAuthorFragment on User {
    id
    displayName
  }
`;

export const MESSAGE_PREVIEW_FRAGMENT = gql`
  fragment MessagePreviewFragment on Message {
    id
    createdAt
    content
    attachment {
      ...MessagePreviewAttachmentFragment
    }
    author {
      ...MessagePreviewAuthorFragment
    }
  }
  ${MESSAGE_PREVIEW_ATTACHMENT_FRAGMENT}
  ${MESSAGE_PREVIEW_AUTHOR_FRAGMENT}
`;

export const MESSAGE_PREVIEW_QUERY = gql`
  query MessagePreview($messageId: MigrateID!) {
    message(id: $messageId) {
      ...MessagePreviewFragment
    }
  }
  ${MESSAGE_PREVIEW_FRAGMENT}
`;

const Container = styled(View)();

const MessagePreviewLoadingContent = () => {
  return <Skeleton.Text lines={2} />;
};

export const MessagePreviewLoading = styled(() => {
  return (
    <Container>
      <MessagePreviewLoadingContent />
    </Container>
  );
})();

interface HeaderProps {
  authorName?: string;
  createdAt: Scalars["MigrateDateTime"];
  unread?: boolean;
}

const Header: React.FC<HeaderProps> = ({ authorName, createdAt, unread }) => {
  return (
    <View
      sx={{
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Text variant="sm" sx={{ color: "$black", fontWeight: "500" }}>
        {authorName ?? "(Unknown user)"}
      </Text>
      <Text
        variant="sm"
        sx={{
          fontWeight: "500",
          ml: "$2",
          ...(unread && { color: "$blue" }),
        }}
      >
        {formatDateConcise(createdAt)}
      </Text>
      {unread ? (
        <View
          sx={{
            backgroundColor: "$blue",
            width: 6,
            height: 6,
            borderRadius: 99,
            ml: "$2",
            top: -1,
          }}
        />
      ) : null}
    </View>
  );
};

interface MessagePreviewContentProps {
  message: MessageFragment;
  unread?: boolean;
}

export const MessagePreviewContent: React.FC<MessagePreviewContentProps> = ({
  message,
  unread,
}) => {
  return (
    <>
      <Header
        authorName={message.author?.displayName}
        createdAt={message.createdAt}
        unread={unread}
      />

      <Text variant="sm" numberOfLines={1}>
        {getMessageContentSummary(message)}
      </Text>
    </>
  );
};

interface MessagePreviewProps {
  messageId: Scalars["MigrateID"];
  unread?: boolean;
}

const MessagePreviewComponent = ({
  messageId,
  unread,
}: MessagePreviewProps) => {
  const queryResult = useMessagePreviewQuery({
    variables: {
      messageId,
    },
  });

  return (
    <Container>
      <QueryWrapper
        queryHookResult={queryResult}
        loaderComponent={() => <MessagePreviewLoadingContent />}
      >
        {({ data: { message } }) => {
          return <MessagePreviewContent message={message} unread={unread} />;
        }}
      </QueryWrapper>
    </Container>
  );
};

MessagePreviewComponent.fragments = {
  message: MESSAGE_PREVIEW_FRAGMENT,
  attachment: MESSAGE_PREVIEW_ATTACHMENT_FRAGMENT,
  author: MESSAGE_PREVIEW_AUTHOR_FRAGMENT,
};

export const MessagePreview = MessagePreviewComponent;
