import { Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { authClient } from "app/use-auth";
import { captureException } from "../../error";

const createAuthorizationHeader = async () => {
  if (!authClient.isSoftAuthenticated()) {
    return {};
  }

  return {
    Authorization: await authClient.getAuthorizationHeader({
      forceFetchToken: true,
    }),
  };
};

export const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  const isAuthenticationError = graphQLErrors?.some(
    (e) =>
      e.extensions.code === "UNAUTHENTICATED" || e.message === "unauthorized"
  );
  if (isAuthenticationError) {
    const oldHeaders = operation.getContext().headers;
    return new Observable((observer) => {
      createAuthorizationHeader()
        .then((authHeader) => {
          operation.setContext({
            headers: {
              ...oldHeaders,
              ...authHeader,
            },
          });
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
  graphQLErrors?.map(({ message }) => captureException(message));
});
