import { AntDesign } from "@expo/vector-icons";
import { MatchResponseType, Maybe, Scalars } from "app/types/generated/schema";
import {
  FloatingActionButtonProps,
  FloatingActionToggleButton,
} from "app/components/floating-action-list";
import { ColorAnimatedIconProps } from "app/components/color-animated-icon";
import { sendAnalyticsEvent } from "app/client/analytics";
import { ToggleIcon } from "./toggle-icon";
import { MatchResponse, OnActionToggle } from "./types";
import { useSaveJobMatch, useUnsaveJobMatch } from "./hooks";

interface SaveIconProps {
  saved: boolean;
  size?: number;
  colorStates: ColorAnimatedIconProps["colorStates"];
}

const SaveIcon = ({ saved, size, colorStates }: SaveIconProps) => {
  return (
    <ToggleIcon
      iconProps={{ size }}
      checked={saved}
      CheckedIcon={<AntDesign name="heart" />}
      UncheckedIcon={<AntDesign name="hearto" />}
      colorStates={colorStates}
    />
  );
};

interface UseToggleSaveActionButtonProps {
  jobId: Scalars["MigrateID"];
  matchResponse?: Maybe<MatchResponse>;
}

const useSaveActionToggle = ({
  jobId,
  matchResponse,
}: UseToggleSaveActionButtonProps) => {
  const handleSave = useSaveJobMatch();
  const handleUnsave = useUnsaveJobMatch();

  if (!matchResponse) {
    return {
      saved: false,
      handleToggle: () => handleSave({ jobId }),
    };
  }

  const saved = matchResponse.type === MatchResponseType.Save;

  return {
    saved,
    handleToggle: saved
      ? async () => {
          sendAnalyticsEvent("unsave job", { jobId });
          handleUnsave({ matchResponse });
        }
      : async () => {
          sendAnalyticsEvent("save job", { jobId });
          handleSave({ jobId, existingMatchResponseId: matchResponse.id });
        },
  };
};

interface ToggleSaveActionButtonProps
  extends Omit<FloatingActionButtonProps, "onPress" | "icon" | "children"> {
  saved?: boolean;
  onPress?: () => void;
}

const ToggleSaveActionButton = ({
  variant = "card",
  saved = false,
  onPress,
  ...props
}: ToggleSaveActionButtonProps) => {
  return (
    <FloatingActionToggleButton
      renderToggleIcon={({ colorStates, size }) => (
        <SaveIcon saved={saved} size={size} colorStates={colorStates} />
      )}
      onPress={onPress}
      variant={variant}
      {...props}
    >
      {saved ? "Unsave" : "Save"}
    </FloatingActionToggleButton>
  );
};

type JobToggleSaveActionButtonProps = UseToggleSaveActionButtonProps &
  Omit<
    FloatingActionButtonProps,
    "onPress" | "icon" | "children" | "variant"
  > & {
    variant?:
      | FloatingActionButtonProps["variant"]
      | ((saved: boolean) => FloatingActionButtonProps["variant"]);
    onToggle?: OnActionToggle;
  };

const JobToggleSaveActionButton = ({
  jobId,
  matchResponse,
  variant: _variant,
  onToggle = ({ handleToggle }) => handleToggle(),
  ...props
}: JobToggleSaveActionButtonProps) => {
  const { saved, handleToggle } = useSaveActionToggle({
    jobId,
    matchResponse,
  });

  const variant = typeof _variant === "function" ? _variant(saved) : _variant;

  return (
    <ToggleSaveActionButton
      onPress={() => onToggle({ toggled: saved, handleToggle })}
      saved={saved}
      variant={variant}
      {...props}
    />
  );
};

export type { UseToggleSaveActionButtonProps as ToggleSaveActionButtonProps };
export { JobToggleSaveActionButton, ToggleSaveActionButton };
