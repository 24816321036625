import { useState } from "react";
import { Button, ButtonLink } from "app/design/button";
import { Form } from "app/components/form";
import { PasswordControl } from "app/components/form/password-control";
import { useToast } from "app/components/use-toast";
import { useYupForm, yup } from "app/utils/yup";
import { useAuth } from "app/use-auth";
import { pagesPath } from "app/lib/$path";
import { checkExhaustiveness } from "app/types";
import { captureException } from "app/client/error";
import { useRequestPasswordReset } from "./email-step";

const schema = yup
  .object({
    newPassword: yup
      .string()
      .required()
      .min(8, "Password must be at least 8 characters")
      .label("New password"),
    newPasswordConfirm: yup
      .string()
      .label("New password")
      .when("newPassword", (newPassword, _schema) =>
        newPassword
          ? _schema.required("Please confirm your new password")
          : _schema
      )
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    code: yup.string().length(6).required().label("Verification code"),
  })
  .required();

interface ResetInput {
  newPassword: string;
  newPasswordConfirm: string;
  code: string;
}

interface ResetStepProps {
  email: string;
}

const ResetStep = ({ email }: ResetStepProps) => {
  const { login, resetPasswordWithCode } = useAuth();
  const toast = useToast();

  const [requestPasswordReset, { loading: requesting }] =
    useRequestPasswordReset();
  const [canRequestNewCode, setCanRequestNewCode] = useState(false);

  const { control, handleSubmit, formState } = useYupForm<ResetInput>({
    schema,
    defaultValues: {
      newPassword: "",
      newPasswordConfirm: "",
      code: "",
    },
  });

  const onSubmit = async ({ newPassword, code }: ResetInput) => {
    try {
      const resetPasswordRes = await resetPasswordWithCode({
        email,
        newPassword,
        code,
      });
      if (!resetPasswordRes.success) {
        if (resetPasswordRes.error.type === "InvalidCodeError") {
          toast.error({
            title: "The code you provided is invalid",
            description: "Please try again or request a new code.",
          });
          setCanRequestNewCode(true);
          return;
        }
        checkExhaustiveness(resetPasswordRes.error.type);
      }
      const loginRes = await login({ email, password: newPassword });
      if (!loginRes.success) {
        throw new Error("Failed to login with password reset credentials");
      }
      toast.success({
        title: "Password reset!",
      });
    } catch (e) {
      captureException(e);
      toast.error({
        title: "Couldn't reset password",
      });
      setCanRequestNewCode(true);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PasswordControl
          name="newPassword"
          control={control}
          inputProps={{
            label: "New password",
          }}
        />

        <PasswordControl
          name="newPasswordConfirm"
          control={control}
          inputProps={{
            label: "Confirm new password",
          }}
        />

        <PasswordControl
          name="code"
          control={control}
          inputProps={{
            label: "Verification code",
            keyboardType: "number-pad",
            textContentType: "oneTimeCode",
            autoCapitalize: "none",
          }}
        />

        {canRequestNewCode ? (
          <Button
            variant="link"
            onPress={() => requestPasswordReset(email)}
            loading={requesting}
          >
            Request new code
          </Button>
        ) : (
          <></>
        )}

        <Form.SubmitButton
          containerSx={{ my: "$4" }}
          loading={formState.isSubmitting}
        >
          Continue
        </Form.SubmitButton>
      </Form>

      <ButtonLink
        variant="link"
        containerSx={{ mb: "$8", alignSelf: "center" }}
        textSx={{ textDecorationLine: "underline" }}
        href={pagesPath.login.$url()}
      >
        Cancel password reset
      </ButtonLink>
    </>
  );
};

export type { ResetStepProps };
export { ResetStep };
