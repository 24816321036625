import React, { useRef, useState } from "react";
import { useWindowDimensions } from "react-native";
import ReanimatedCarousel, {
  ICarouselInstance,
} from "react-native-reanimated-carousel";
import { View, styled, useBreakpoints } from "dripsy";
import { MotiView } from "moti";
import { PaginationDots } from "./dots";
import { PaginationButton, PaginationDirection } from "./button";
import { CarouselPage } from "./page";

const DripsyMotiView = styled(MotiView)();

const IMAGE_HEIGHT_PERCENTAGE = 0.35;
const CAROUSEL_MAX_WIDTH = 600;
const SUBTITLE_SPILL_BREAKPOINT = 602;

const HEADER_LINE_HEIGHT = 40;
const HEADER_MARGIN_TOP = 32;
const HEADER_MARGIN_BOTTOM = 32;
const SUBTITLE_LINE_HEIGHT = 24;
const SUBTITLE_MARGIN_BOTTOM = 32;

const Carousel = () => {
  const data = React.useRef([
    require("app/design/images/slide-1.png"),
    require("app/design/images/slide-2.png"),
    require("app/design/images/slide-3.png"),
  ]).current;

  const carouselRef = useRef<ICarouselInstance>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width, height } = useWindowDimensions();
  const [, tabletBreakpoint] = useBreakpoints();

  const imageHeight = height * IMAGE_HEIGHT_PERCENTAGE;
  const isNarrow = width <= SUBTITLE_SPILL_BREAKPOINT;
  const carouselHeight =
    imageHeight +
    (HEADER_LINE_HEIGHT + HEADER_MARGIN_TOP + HEADER_MARGIN_BOTTOM) +
    ((isNarrow ? SUBTITLE_LINE_HEIGHT * 3 : SUBTITLE_LINE_HEIGHT * 2) +
      SUBTITLE_MARGIN_BOTTOM);

  const paginate = (direction: PaginationDirection) => () => {
    const modifier = direction === "left" ? -1 : 1;
    const newIndex = currentIndex + modifier;
    if (direction === "right") carouselRef.current?.next({ animated: true });
    else carouselRef.current?.prev({ animated: true });
    setCurrentIndex(newIndex);
  };

  const showPagination = tabletBreakpoint && width >= tabletBreakpoint;

  return (
    <View sx={{ justifyContent: "center" }}>
      <View
        sx={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {showPagination && (
          <PaginationButton
            direction="left"
            paginate={paginate}
            disabled={currentIndex === 0}
          />
        )}
        <ReanimatedCarousel
          ref={carouselRef}
          width={Math.min(CAROUSEL_MAX_WIDTH, width)}
          height={carouselHeight}
          data={data}
          onSnapToItem={(index) => setCurrentIndex(index % data.length)}
          renderItem={({ item, index }) => (
            <CarouselPage index={index} url={item} imageHeight={imageHeight} />
          )}
        />
        {showPagination && (
          <PaginationButton
            direction="right"
            paginate={paginate}
            disabled={currentIndex === data.length - 1}
          />
        )}
      </View>

      <DripsyMotiView
        from={{
          opacity: 0,
        }}
        animate={{ opacity: 1 }}
      >
        <PaginationDots
          currentIndex={currentIndex}
          numberOfSteps={data.length}
        />
      </DripsyMotiView>
    </View>
  );
};

export { Carousel };
