import { gql } from "@apollo/client";

import { memo } from "react";
import {
  ChannelsOrderBy,
  ChannelType,
  JoinedChannelsQuery,
  Scalars,
  useJoinedChannelsQuery,
} from "app/types/generated/schema";
import { checkExhaustiveness } from "app/types";
import { pagesPath } from "app/lib/$path";

import { ScreenComponent } from "app/navigation/types";
import { ChannelList, ChannelListRenderItem } from "./channel/channel-list";
import {
  ApplicationChannelCard,
  CommunityChannelCard,
  OrganisationChannelCard,
} from "./channel/channel-list/channel-list-card";
import { ChannelContainer, ChannelLayout } from "./channel-layout";
import { ChannelListEmpty } from "./channel/channel-list/channel-list-empty";

export const JOINED_CHANNELS_QUERY = gql`
  query JoinedChannels(
    $size: Int
    $after: String
    $orderBy: ChannelsOrderBy
    $where: JoinedChannelsWhereInput
  ) {
    joinedChannels(
      where: $where
      orderBy: $orderBy
      _size: $size
      _cursor: $after
    ) {
      data {
        id
        ...ApplicationDMChannelListCardProps
        ...OrganisationPrivateChannelListCardProps
        ...CommunityPublicChannelListCardProps
      }
      after
    }
  }
  ${ApplicationChannelCard.fragments.applicationDMChannel}
  ${OrganisationChannelCard.fragments.organisationPrivateChannel}
  ${CommunityChannelCard.fragments.communityPublicChannel}
`;

type JoinedChannel = JoinedChannelsQuery["joinedChannels"]["data"][number];

export const renderChannelCard: ChannelListRenderItem<JoinedChannel> = ({
  item: channel,
  extraData: { selectedChannelId } = {},
}) => {
  if (channel.__typename === "ApplicationDMChannel") {
    return (
      <ApplicationChannelCard
        channel={channel}
        selectedChannelId={selectedChannelId}
      />
    );
  }
  if (channel.__typename === "CommunityPublicChannel") {
    return (
      <CommunityChannelCard
        channel={channel}
        selectedChannelId={selectedChannelId}
      />
    );
  }
  if (channel.__typename === "OrganisationPrivateChannel") {
    return (
      <OrganisationChannelCard
        channel={channel}
        selectedChannelId={selectedChannelId}
      />
    );
  }
  if (channel.__typename === "DirectDMChannel" || !channel.__typename) {
    throw Error(`${channel.__typename} channel type is not supported`);
  }
  checkExhaustiveness(channel.__typename);
  return null;
};

const InboxChannelsEmpty = () => (
  <ChannelListEmpty
    icon="mail"
    header="No messages yet"
    text="Receive direct messages from other Migrate users and employers regarding applications here"
  />
);

export const InboxChannels: React.FC<{
  selectedChannelId?: Scalars["MigrateID"];
}> = ({ selectedChannelId }) => {
  const queryResult = useJoinedChannelsQuery({
    variables: {
      where: { not: { type: ChannelType.Community } },
      orderBy: ChannelsOrderBy.UpdatedDesc,
      size: 20,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <ChannelList
      selectedChannelId={selectedChannelId}
      renderItem={renderChannelCard}
      queryData={queryResult.data?.joinedChannels}
      queryHookResult={queryResult}
      ListEmptyComponent={() => <InboxChannelsEmpty />}
    />
  );
};

const InboxScreen: ScreenComponent = memo(
  () => <ChannelContainer channelList={<InboxChannels />} />,
  () => true
);

const headerProps = {
  title: "Inbox",
  backHref: pagesPath.$url(),
};

InboxScreen.displayName = "InboxScreen";
InboxScreen.headerProps = headerProps;
InboxScreen.getLayout = (page: React.ReactElement) => {
  return (
    <ChannelLayout headerProps={headerProps} overridePathname="inbox">
      {page}
    </ChannelLayout>
  );
};

export { InboxScreen };
