import { Sx, Row, styled, useSx, View } from "dripsy";
import { MotiPressable } from "moti/interactions";
import { useState } from "react";
import { useScreenSize } from "app/hooks";

const DripsyMotiPressable = styled(MotiPressable)();

const PROGRESS_DOT_SIZE = 10;

interface ProgressDotProps {
  active: boolean;
  onPress: () => void;
  containerSx?: Sx;
}

const ProgressDot = ({
  containerSx = {},
  active,
  onPress,
}: ProgressDotProps) => {
  const { isMobile } = useScreenSize();
  const sx = useSx();

  return (
    <DripsyMotiPressable
      disabled={isMobile}
      from={{
        opacity: 0,
        scale: 0.5,
      }}
      animate={{
        opacity: 1,
        scale: active ? 1.25 : 1,
      }}
      transition={{
        type: "spring",
      }}
      onPress={onPress}
      sx={{
        width: PROGRESS_DOT_SIZE,
        height: PROGRESS_DOT_SIZE,
        borderRadius: PROGRESS_DOT_SIZE,
        bg: active ? "$blue" : "$ui",
      }}
      containerStyle={sx(containerSx)}
    />
  );
};

interface UseTabsStateProps {
  index?: number;
  defaultIndex?: number;
  onChange?: (index: number) => void;
}

const useTabsState = ({
  index,
  defaultIndex = 0,
  onChange,
}: UseTabsStateProps) => {
  const internalTabsState = useState<number>(defaultIndex);
  return [
    index ?? internalTabsState[0],
    (nextIndex: number) => {
      internalTabsState[1](nextIndex);
      onChange?.(nextIndex);
    },
  ] as const;
};

const ProgressDotsContainer = styled(Row)({
  alignItems: "center",
  justifyContent: "center",
  mb: ["$4", "$6"],
});

interface ProgressDotsProps extends UseTabsStateProps {
  length: number;
}

const ProgressDots = ({
  length,
  index,
  defaultIndex,
  onChange,
}: ProgressDotsProps) => {
  const [activeIndex, setActiveIndex] = useTabsState({
    index,
    defaultIndex,
    onChange,
  });
  return (
    <ProgressDotsContainer>
      {Array.from({ length }, (_, _index) => (
        <ProgressDot
          key={_index}
          containerSx={{ mr: _index === length - 1 ? 0 : "$2" }}
          active={_index === activeIndex}
          onPress={() => {
            setActiveIndex(_index);
          }}
        />
      ))}
    </ProgressDotsContainer>
  );
};

// Empty shell for now
const ProgressDotsLoading = () => (
  <ProgressDotsContainer>
    <View sx={{ height: PROGRESS_DOT_SIZE }} />
  </ProgressDotsContainer>
);

export type { ProgressDotsProps };
export { ProgressDots, ProgressDotsLoading };
