import { gql } from "@apollo/client";
import { useState } from "react";

import {
  Scalars,
  useChannelCategoriesFilterQuery,
} from "app/types/generated/schema";
import { ActivityIndicator } from "app/design/activity-indicator";
import { QueryWrapper } from "app/components/query-wrapper";
import {
  ToggleButton,
  CollapseableToggleButtonGroup,
} from "app/components/form/toggle-button";

const CHANNEL_CATEGORY_FILTER_PROPS = gql`
  fragment ChannelCategoryFilterProps on ChannelCategory {
    id
    name
  }
`;

const CHANNEL_CATEGORIES_FILTER_QUERY = gql`
  query ChannelCategoriesFilter {
    channelCategories(_size: 100) {
      data {
        ...ChannelCategoryFilterProps
      }
    }
  }
  ${CHANNEL_CATEGORY_FILTER_PROPS}
`;

const ChannelCategoriesFilterLoading = () => <ActivityIndicator />;

interface ChannelCategoriesFilterProps {
  onChange?: (categories: Scalars["MigrateID"][]) => void;
}

const ChannelCategoriesFilter = ({
  onChange,
}: ChannelCategoriesFilterProps) => {
  const [selectedCategories, setSelectedCategories] = useState<
    Scalars["MigrateID"][]
  >([]);
  const queryResponse = useChannelCategoriesFilterQuery();

  const handleToggle = (id: Scalars["MigrateID"]) => () => {
    const newSelectedCategories = selectedCategories.includes(id)
      ? selectedCategories.filter((categoryId) => categoryId !== id)
      : [...selectedCategories, id];

    onChange?.(newSelectedCategories);
    setSelectedCategories(newSelectedCategories);
  };

  return (
    <QueryWrapper queryHookResult={queryResponse}>
      {({
        data: {
          channelCategories: { data: channelCategories },
        },
      }) => {
        return (
          <CollapseableToggleButtonGroup
            sx={{ justifyContent: "flex-start" }}
            count={5}
            toggleButtonProps={{ variant: "uiToggle", size: "sm" }}
          >
            {channelCategories.map(({ id, name }) => {
              return (
                <ToggleButton
                  key={id}
                  checked={selectedCategories.includes(id)}
                  onChange={handleToggle(id)}
                >
                  {name}
                </ToggleButton>
              );
            })}
          </CollapseableToggleButtonGroup>
        );
      }}
    </QueryWrapper>
  );
};

ChannelCategoriesFilter.fragments = {
  channelCategory: CHANNEL_CATEGORY_FILTER_PROPS,
};

export {
  CHANNEL_CATEGORY_FILTER_PROPS,
  CHANNEL_CATEGORIES_FILTER_QUERY,
  ChannelCategoriesFilterLoading,
  ChannelCategoriesFilter,
};
