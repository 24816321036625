import { View as RNView, ViewProps } from "react-native";
import {
  DripsyFinalTheme,
  styled,
  useDripsyTheme,
  SxProp,
  View,
  StyledProps,
} from "dripsy";

const Card = styled(RNView, {
  themeKey: "card",
  defaultVariant: "default",
})();

type CardProps = StyledProps<"card"> & ViewProps;

interface AccentProps {
  sx?: SxProp;
}

const DEFAULT_ACCENT_WIDTH = 6;

const Accent = ({ sx }: AccentProps) => {
  const { theme } = useDripsyTheme();
  const { borderWidth, borderRadius } = theme.card.default;
  return (
    <View
      sx={{
        position: "absolute",
        top: -borderWidth,
        bottom: -borderWidth,
        left: -borderWidth,
        borderRadius,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: "$blue",
        width: DEFAULT_ACCENT_WIDTH,
        ...sx,
      }}
    />
  );
};
interface WithAccentConfig {
  accentColor?: keyof DripsyFinalTheme["colors"] | string;
  accentSx?: SxProp;
}

const withAccent = (
  CardComponent: React.ComponentType<{
    sx?: SxProp;
    children?: React.ReactNode;
  }>,
  {
    accentColor: defaultStripeColor = "$blue",
    accentSx: defaultSx = {},
  }: WithAccentConfig = {}
) => {
  const WithAccent = ({
    sx,
    children,
    accentColor = defaultStripeColor,
    accentSx = defaultSx,
  }: CardProps & WithAccentConfig) => {
    return (
      <CardComponent sx={sx}>
        <Accent sx={{ bg: accentColor, ...accentSx }} />
        {children}
      </CardComponent>
    );
  };

  WithAccent.displayName = `WithAccent(${CardComponent.displayName})`;

  return WithAccent;
};

const AccentCard = withAccent(Card);

export type { CardProps };
export { Accent, AccentCard, Card, DEFAULT_ACCENT_WIDTH, withAccent };
