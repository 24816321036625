import { forwardRef, useRef } from "react";
import { MotiPressable } from "moti/interactions";
import { useCheckbox } from "@react-native-aria/checkbox";
import { AriaCheckboxProps } from "@react-types/checkbox";
import { useFocusRing } from "@react-native-aria/focus";
import { useDripsyTheme } from "@dripsy/core";
import { Text, styled } from "dripsy";
import { useToggleState } from "app/hooks/use-toggle-state";
import { AnimatedCheckIcon } from "./animated-check-icon";

const DripsyMotiPressable = styled(MotiPressable)();

export interface CheckboxProps extends AriaCheckboxProps {
  label?: string;
  onChange?: (checked: boolean) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, onChange, ...props }, ref) => {
    const inputRef = useRef(null);
    const { theme } = useDripsyTheme();
    const { focusProps } = useFocusRing();
    const checkboxState = useToggleState(props);
    const { inputProps } = useCheckbox(props, checkboxState, inputRef);

    return (
      <DripsyMotiPressable
        ref={ref}
        sx={{
          flexDirection: "row",
          flexShrink: 1,
          cursor: inputProps.disabled ? "not-allowed" : "pointer",
        }}
        containerStyle={{
          alignSelf: "flex-start",
        }}
        {...inputProps}
        {...focusProps}
        onPress={() => {
          if (onChange) onChange(!checkboxState.isSelected);
          checkboxState.toggle();
        }}
      >
        <AnimatedCheckIcon
          sx={{ mr: "$2", mt: 2 }}
          checked={checkboxState.isSelected}
          shouldAnimateOpacity={false}
          checkedStates={{
            backgroundColor: {
              to: theme.colors.$blue,
              from: theme.colors.$white,
            },
          }}
          disabled={inputProps.disabled}
        />
        <Text
          variant="sm"
          sx={{
            flexShrink: 1,
          }}
        >
          {label}
        </Text>
      </DripsyMotiPressable>
    );
  }
);
Checkbox.displayName = "Checkbox";
