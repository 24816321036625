import { UrlObject } from "url";
import { H5, Row, SxProp, useDripsyTheme, View } from "dripsy";
import { Platform } from "react-native";

import {
  Scalars,
  QuestionTopic,
  QuestionsQuery,
  UserHeaderFragmentFragment,
} from "app/types/generated/schema";
import { useThemeValue } from "app/hooks/use-theme-value";
import { Badge } from "app/design/badge";
import { pagesPath } from "app/lib/$path";

import {
  Author,
  Card,
  CardLoading,
  Footer,
  PressableCard,
  PressableCardProps,
  Title,
} from "./card";
import { QuestionMetadata } from "./question-metadata";
import { QuestionLink } from "./question-link";
import { Answer } from "./answer";

export interface QuestionCardBaseProps {
  variant?: PressableCardProps["variant"];
  id?: string;
  author: UserHeaderFragmentFragment;
  title: string;
  titleSx?: SxProp;
  createdAt: string;
  sx?: SxProp;
  containerSx?: SxProp;
  answers: {
    count: number;
  };
}

export interface PressableQuestionCardSummaryProps
  extends QuestionCardBaseProps {
  href: UrlObject;
}

export const PressableQuestionCardSummary = ({
  title,
  titleSx,
  createdAt,
  answers,
  href,
  author,
  ...sxProps
}: PressableQuestionCardSummaryProps) => {
  return (
    <PressableCard href={href} accessibilityLabel="View question" {...sxProps}>
      <Author author={author} />
      <Title sx={titleSx} numberOfLines={3}>
        {title}
      </Title>
      <QuestionMetadata createdAt={createdAt} answerCount={answers.count} />
    </PressableCard>
  );
};

export const QuestionCardLoading = CardLoading;

export interface PressableQuestionCardProps extends QuestionCardBaseProps {
  questionId: Scalars["MigrateID"];
  topics?: Partial<QuestionTopic>[];
  highlightedAnswer?:
    | QuestionsQuery["questions"]["data"][number]["highlightedAnswer"]
    | null;
}

const Divider = () => {
  const cardPadding = useThemeValue((theme) => theme.card.default.p);
  return (
    <View
      sx={{
        my: "$4",
        height: 1,
        bg: "$ui",
        mx: 0 - cardPadding - 2,
      }}
    />
  );
};

const DeletedQuestionCard = ({
  sx,
  containerSx,
  highlightedAnswer,
}: Pick<
  PressableQuestionCardProps,
  "highlightedAnswer" | "sx" | "containerSx"
>) => (
  <View sx={containerSx}>
    <Card sx={sx}>
      {highlightedAnswer ? (
        <>
          <H5>Question deleted</H5>
          <Divider />
          <Answer
            {...highlightedAnswer}
            accessibilityLabel="View question"
            answerId={highlightedAnswer.id}
            sx={{
              p: "$0",
              mb: "$0",
              bg: "transparent",
              backdropFilter: Platform.OS === "web" ? "none" : undefined,
              boxShadow: "none",
              borderWidth: 0,
            }}
            groupVariant="last"
          />
        </>
      ) : null}
    </Card>
  </View>
);

const PressableQuestionCard = ({
  questionId,
  title,
  author,
  createdAt,
  answers,
  sx,
  topics = [],
  containerSx,
  highlightedAnswer,
}: PressableQuestionCardProps) => {
  const { theme } = useDripsyTheme();

  return (
    <PressableCard
      sx={sx}
      containerSx={containerSx}
      href={pagesPath.community.questions._questionId(questionId).$url()}
      accessibilityLabel="View question"
    >
      {topics.length > 0 ? (
        <Row sx={{ flexWrap: "wrap", mt: 0 - theme.space.$2, mb: "$4" }}>
          {topics.map((topic) => (
            <Badge key={topic.id} sx={{ mt: "$2" }}>
              {topic.name}
            </Badge>
          ))}
        </Row>
      ) : null}
      <Author author={author} />
      <Title>{title}</Title>
      <Footer>
        <QuestionMetadata
          sx={{
            flexDirection: highlightedAnswer ? "row" : "column",
            justifyContent: highlightedAnswer ? "space-between" : "flex-start",
            flexGrow: 1,
          }}
          createdAt={createdAt}
          answerCount={answers.count}
        />
        {!!highlightedAnswer || <QuestionLink />}
      </Footer>
      {highlightedAnswer ? (
        <>
          <Divider />
          <Answer
            {...highlightedAnswer}
            accessibilityLabel="View question"
            answerId={highlightedAnswer.id}
            sx={{
              p: "$0",
              mb: "$0",
              bg: "transparent",
              backdropFilter: Platform.OS === "web" ? "none" : undefined,
              boxShadow: "none",
              borderWidth: 0,
            }}
            groupVariant="last"
            footerAction={<QuestionLink />}
          />
        </>
      ) : null}
    </PressableCard>
  );
};

export { PressableQuestionCard, DeletedQuestionCard };
