import { Skeleton } from "native-base";
import { DocumentCardLoading } from "app/components/document-card";
import { ProfileCardContainer } from "../../card";

export const LanguageResultsLoading = () => {
  return (
    <>
      <DocumentCardLoading />
      <ProfileCardContainer>
        <Skeleton.Text
          lines={1}
          _line={{ width: 200, height: 4, rounded: 3 }}
          mb={2}
        />
        <Skeleton.Text
          lines={1}
          _line={{ width: 100, height: 3, rounded: 3 }}
        />
      </ProfileCardContainer>
    </>
  );
};
