import { DripsyFinalTheme, styled, Sx, useDripsyTheme, View } from "dripsy";

type BubbleVariant = keyof DripsyFinalTheme["bubble"];

const useBubbleTheme = () => {
  const { theme } = useDripsyTheme();
  return (variant: BubbleVariant) => theme.bubble[variant];
};

interface BubbleNotchProps {
  color: string;
  position: "left" | "right";
}

const BubbleNotch = styled(View)(({ position, color }: BubbleNotchProps) => ({
  position: "absolute",
  borderWidth: 5,
  borderColor: "transparent",
  top: 15,
  [position]: -10,
  [position === "right" ? "borderLeftColor" : "borderRightColor"]: color,
}));

interface BubbleProps {
  notchPosition: "left" | "right";
  children: React.ReactNode;
  variant?: keyof DripsyFinalTheme["bubble"];
  sx?: Sx;
  showNotch?: boolean;
}

const Bubble = ({
  variant = "default",
  notchPosition,
  showNotch,
  children,
  sx,
}: BubbleProps) => {
  const getTheme = useBubbleTheme();
  const { backgroundColor, borderColor, _container, ...themeSx } =
    getTheme(variant);

  return (
    <View
      sx={{
        backgroundColor,
        borderColor,
        borderRadius: 6,
        px: "$3",
        py: "$2",
        ...themeSx,
        ...sx,
      }}
    >
      {showNotch ? (
        <BubbleNotch position={notchPosition} color={borderColor} />
      ) : null}
      <View sx={{ justifyContent: "flex-end" }}>{children}</View>
    </View>
  );
};

export type { BubbleProps, BubbleVariant };
export { useBubbleTheme, Bubble };
