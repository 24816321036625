import { gql } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import { useRouter } from "solito/router";
import { useEffect, useState } from "react";
import isEmailValidator from "validator/lib/isEmail";

import {
  Scalars,
  SearchStage,
  useOnboardingQuery,
  useSignupToMailingListMutation,
  useUpdateJobPreferencesMutation,
} from "app/types/generated/schema";
import { ButtonLink } from "app/design/button";
import { Header, StepScreenForm } from "app/components/step-screen-form";
import { useToast } from "app/components/use-toast";
import { useYupForm, yup } from "app/utils/yup";
import { pagesPath } from "app/lib/$path";
import { useAuth } from "app/use-auth";
import { useFormPersist } from "app/use-form-persist";

import { checkExhaustiveness } from "app/types";
import { captureException } from "app/client/error";
import { CountryStep, IneligibleCountryContent } from "./steps/country";
import { CreateAccountStep } from "./steps/create-account";
import { JobTypesStep } from "./steps/job-types";
import { LocationStep, LOCATION_DONT_MIND } from "./steps/location";
import {
  IneligibleProfessionContent,
  ProfessionStep,
} from "./steps/profession";
import { SearchStageStep } from "./steps/search-stage";
import { NewUserIneligibleModal } from "./steps/ineligible-modal";

const ONBOARDING_QUERY = gql`
  query Onboarding {
    getProfessions(_size: 100) {
      ...ProfessionStepProfessionPageProps
    }
    locations {
      ...LocationStepLocationProps
    }
  }
  ${LocationStep.fragments.location}
  ${ProfessionStep.fragments.professionPage}
`;

const SIGNUP_TO_MAILING_LIST_MUTATION = gql`
  mutation SignupToMailingList {
    signupToMailingList
  }
`;

interface OnboardingScreenHeaderProps {
  stepProgress: number;
}

const OnboardingScreenHeader = ({
  stepProgress,
}: OnboardingScreenHeaderProps) => (
  <Header stepProgress={stepProgress}>
    <ButtonLink variant="ghost" href={pagesPath.login.$url()}>
      Log in
    </ButtonLink>
    <Header.MigrateLogo />
  </Header>
);

const preferencesSchema = yup
  .object({
    professionId: yup.string().required("Please select a profession"),
    country: yup.string().required("Please select a country"),
    tagPreferenceIds: yup
      .array()
      .of(yup.string())
      .min(1, "Please select your job type preferences")
      .required(),
    locationPreferenceIds: yup
      .array()
      .of(yup.string())
      .min(1, "Please select your location preferences")
      .required(),
    searchStage: yup.string().required("Please choose an option"),
  })
  .required();

const signupSchema = yup
  .object({
    forenames: yup.string().required().label("First name").trim(),
    surname: yup.string().required().label("Last name").trim(),
    email: yup
      .string()
      .email("Email is invalid")
      .required()
      .label("Email")
      .test(
        "is-valid",
        (message) => `${message.path} is invalid`,
        (value) =>
          value
            ? isEmailValidator(value)
            : new yup.ValidationError("Invalid value")
      ),
    password: yup
      .string()
      .required()
      .min(8, "Please enter at least 8 characters"),
    signUpToMailingList: yup.bool(),
  })
  .required();

type SignupFormValues = yup.InferType<typeof signupSchema>;

interface PreferencesFormValues {
  professionId?: string;
  country?: string;
  tagPreferenceIds?: Scalars["MigrateID"][];
  locationPreferenceIds?: Scalars["MigrateID"][];
  searchStage?: SearchStage;
}

const OnboardingScreen = () => {
  const toast = useToast();
  const [showIneligibleCountryModal, setShowIneligibleCountryModal] =
    useState(false);
  const [showIneligibleProfessionModal, setShowIneligibleProfessionModal] =
    useState(false);
  const router = useRouter();
  const { signup, login } = useAuth();

  const [signupToMailingList] = useSignupToMailingListMutation();
  const [updateJobPreferences] = useUpdateJobPreferencesMutation();

  useOnboardingQuery();

  const signupForm = useYupForm({
    schema: signupSchema,
    defaultValues: {
      forenames: "",
      surname: "",
      email: "",
      password: "",
      signUpToMailingList: false,
    },
  });

  const preferencesForm = useYupForm<PreferencesFormValues>({
    schema: preferencesSchema,
    defaultValues: {
      professionId: "",
      country: "",
      tagPreferenceIds: [],
      locationPreferenceIds: [],
      // Type: Requires SearchStage type
      // TODO: Fix with transformer / resolver
      searchStage: "" as unknown as undefined,
    },
    mode: "all",
  });

  useEffect(() => {
    const subscription = preferencesForm.watch((_, { name, type }) => {
      if (name === "professionId" && type === "change") {
        preferencesForm.setValue("tagPreferenceIds", []);
      }
    });
    return () => subscription.unsubscribe();
  }, [preferencesForm.watch]);

  useFormPersist("onboarding", {
    storage:
      Platform.OS === "web"
        ? (typeof window !== "undefined" && localStorage) || undefined
        : AsyncStorage,
    dirty: true,
    validate: true,
    ...preferencesForm,
  });

  const sanitizePreferences = ({
    professionId,
    searchStage,
    country,
    tagPreferenceIds,
    locationPreferenceIds,
  }: PreferencesFormValues): PreferencesFormValues => ({
    /**
     * In sign-up, it's possible for users to bypass setting most preferences by
     * not having a profession. In this case, we need to replace all "" / []
     * values with undefined to remove them from the object and prevent the API
     * input validation not passing
     */
    professionId: professionId || undefined,
    searchStage: searchStage || undefined,
    country: country || undefined,
    tagPreferenceIds:
      tagPreferenceIds && tagPreferenceIds.length > 0
        ? tagPreferenceIds
        : undefined,
    locationPreferenceIds:
      locationPreferenceIds && locationPreferenceIds.length > 0
        ? locationPreferenceIds.filter((id) => id !== LOCATION_DONT_MIND.id)
        : undefined,
  });

  const CREATE_ACCOUNT_STEP_PATH = "create-account";

  const onIneligible = () => {
    router.push(pagesPath.register._step(CREATE_ACCOUNT_STEP_PATH).$url());
    setShowIneligibleCountryModal(false);
    setShowIneligibleProfessionModal(false);
  };

  const onSubmit = async (data: SignupFormValues) => {
    const { email, password } = data;
    const preferences = preferencesForm.getValues();
    try {
      const signupRes = await signup(data);

      if (!signupRes.success) {
        if (signupRes.error.type === "AccountAlreadyExistsError") {
          toast.error({
            title: `Account for ${email} already exists`,
            description: "Please log in or use another email.",
          });
          return;
        }

        checkExhaustiveness(signupRes.error.type);
      }

      const loginRes = await login(
        { email, password },
        {
          preAuthenticateHook: async () => {
            try {
              await updateJobPreferences({
                variables: {
                  input: sanitizePreferences(preferences),
                },
              });
            } catch (error) {
              captureException(error);
            }
          },
          postAuthenticateHook: async () => {
            if (!data.signUpToMailingList) return;
            try {
              await signupToMailingList();
            } catch (error) {
              captureException(error);
            }
          },
        }
      );

      if (!loginRes.success) {
        throw new Error("Login failed with signup credentials");
      }
    } catch (e) {
      captureException(e);
      toast.error({
        title: "Couldn't create account",
      });
    }
  };

  const onSubmitSignupForm = signupForm.handleSubmit(onSubmit);

  return (
    <>
      <StepScreenForm
        HeaderComponent={OnboardingScreenHeader}
        onSubmit={onSubmitSignupForm}
        exitPath={pagesPath.welcome}
        path={pagesPath.register}
        submitting={signupForm.formState.isSubmitting}
        {...preferencesForm}
      >
        <ProfessionStep
          onIneligible={() => setShowIneligibleProfessionModal(true)}
          stepPath="profession"
          name="professionId"
          {...preferencesForm}
        />
        <CountryStep
          onIneligible={() => setShowIneligibleCountryModal(true)}
          stepPath="country"
          name="country"
          {...preferencesForm}
        />
        <JobTypesStep
          stepPath="job-types"
          name="tagPreferenceIds"
          professionId={preferencesForm.watch("professionId")}
          {...preferencesForm}
        />
        <LocationStep
          stepPath="location"
          name="locationPreferenceIds"
          {...preferencesForm}
        />
        <SearchStageStep
          stepPath="search-stage"
          name="searchStage"
          {...preferencesForm}
        />
        <CreateAccountStep
          stepPath={CREATE_ACCOUNT_STEP_PATH}
          names={{
            email: "email",
            forenames: "forenames",
            surname: "surname",
            password: "password",
            signUpToMailingList: "signUpToMailingList",
          }}
          onSubmit={onSubmitSignupForm}
          {...signupForm}
        />
      </StepScreenForm>
      <NewUserIneligibleModal
        isOpen={showIneligibleProfessionModal}
        onClose={() => setShowIneligibleProfessionModal(false)}
        onConfirm={onIneligible}
      >
        <IneligibleProfessionContent />
      </NewUserIneligibleModal>
      <NewUserIneligibleModal
        isOpen={showIneligibleCountryModal}
        onClose={() => setShowIneligibleCountryModal(false)}
        onConfirm={onIneligible}
      >
        <IneligibleCountryContent />
      </NewUserIneligibleModal>
    </>
  );
};

export { OnboardingScreen, ONBOARDING_QUERY, SIGNUP_TO_MAILING_LIST_MUTATION };
