import { Platform, TextProps } from "react-native";
import { SxProp } from "dripsy";
import { Linking } from "react-native";
import * as ExpoWebBrowser from "expo-web-browser";
import { ReactNode } from "react";
import { conditionalParam } from "app/utils";
import { Text } from "./text";

const useWebLink = (url: string) => {
  return {
    ...conditionalParam(
      { href: url, accessibilityRole: "link", target: "_blank" },
      Platform.OS === "web"
    ),
    onPress: (e?: any) => {
      e?.preventDefault();
      if (Platform.OS === "web") {
        window.open(url, "_blank");
      } else {
        if (url.includes("mailto")) {
          Linking.openURL(url);
          return;
        }
        ExpoWebBrowser.openBrowserAsync(url);
      }
    },
  };
};

const useWebLinkProps = () => {
  return useWebLink;
};

interface WebLinkProps extends TextProps {
  sx?: SxProp;
  url: string;
  children: ReactNode;
}

const WebLink = ({ sx, url, children, ...rest }: WebLinkProps) => {
  const webLinkProps = useWebLink(url);

  return (
    <Text
      sx={{
        textDecorationLine: "underline",
        ...sx,
      }}
      {...rest}
      {...webLinkProps}
    >
      {children}
    </Text>
  );
};

export type { WebLinkProps };
export { useWebLink, useWebLinkProps, WebLink };
