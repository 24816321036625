import { gql } from "@apollo/client";
import { Sx, Row, View, H4 } from "dripsy";
import { Scalars, useJobCardQuery } from "app/types/generated/schema";
import { OrganisationAvatar } from "app/design/avatar";
import { AnimatedArrow } from "app/components/animated-arrow";
import { DataError } from "app/components/data-error";
import { PressableCard } from "app/components/pressable-card";
import { Text } from "app/components/text";
import { pagesPath } from "app/lib/$path";
import { JobCardMenu } from "./job-card.menu";
import { JobCardLoading } from "./job-card.loading";

interface JobCardProps {
  jobId: Scalars["MigrateID"];
  sx?: Sx;
}

const JOB_CARD_PROPS = gql`
  fragment JobCardProps on Job {
    id
    title
    organisationName
    organisation {
      id
      name
      photoUrl
    }
    myMatchResponse {
      id
      type
      ... on ApplicationApprovedMatchResponse {
        application {
          id
          channel {
            id
          }
        }
      }
    }
  }
`;

const JOB_CARD_QUERY = gql`
  query JobCard($jobId: MigrateID!) {
    job(id: $jobId) {
      ...JobCardProps
    }
  }
  ${JOB_CARD_PROPS}
`;

const JobCard = ({ sx, jobId }: JobCardProps) => {
  const { data, loading, error } = useJobCardQuery({ variables: { jobId } });

  if (loading) return <JobCardLoading sx={sx} />;
  if (!data || error) return <DataError />;

  const { title, organisation, myMatchResponse } = data.job;

  return (
    <PressableCard
      containerSx={{ flex: 1, ...sx }}
      sx={{ flex: 1 }}
      href={pagesPath.jobs._jobId(jobId).$url()}
    >
      <View sx={{ flex: 1 }}>
        <Row sx={{ alignItems: "flex-start", mb: ["$2", "$3"] }}>
          <OrganisationAvatar
            organisation={organisation}
            size={10}
            sx={{ mr: "$4" }}
          />
          <View sx={{ flex: 1, alignSelf: "center" }}>
            <Row
              sx={{
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              <H4
                sx={{ fontSize: 16, lineHeight: 20, mr: "$4" }}
                numberOfLines={2}
              >
                {data.job.organisationName}
              </H4>
              <JobCardMenu jobId={jobId} myMatchResponse={myMatchResponse} />
            </Row>
            <Text variant="sm" numberOfLines={2}>
              {title}
            </Text>
          </View>
        </Row>
      </View>
      <Row sx={{ justifyContent: "flex-end" }}>
        <Text
          variant="buttonSmall"
          sx={{ color: "$blue", mr: "$2", fontWeight: "500" }}
        >
          View job
        </Text>

        <AnimatedArrow size={20} />
      </Row>
    </PressableCard>
  );
};

JobCard.fragments = {
  job: JOB_CARD_PROPS,
};

export { JobCard, JOB_CARD_QUERY };
