import { Row } from "dripsy";
import { ReactElement } from "react";
import { formatDateConcise } from "app/utils";
import { NotificationText } from "./common";

type RenderHeaderRightBag = {
  createdAt: string;
};

const defaultRenderHeaderRight = ({ createdAt }: RenderHeaderRightBag) => (
  <NotificationText
    sx={{
      marginLeft: "$4",
      fontWeight: "500",
    }}
  >
    {formatDateConcise(createdAt)}
  </NotificationText>
);

interface NotificationHeaderProps {
  createdAt: string;
  children: string | ReactElement | (string | ReactElement)[];
  renderHeaderRight?: (
    renderHeaderRightBag: RenderHeaderRightBag
  ) => ReactElement;
}

const NotificationHeader = ({
  createdAt,
  children,
  renderHeaderRight = defaultRenderHeaderRight,
}: NotificationHeaderProps) => (
  <Row
    sx={{
      flexShrink: 1,
      justifyContent: "space-between",
      alignItems: "flex-start",
    }}
  >
    <NotificationText sx={{ color: "$black", flexShrink: 1 }} numberOfLines={2}>
      {children}
    </NotificationText>
    {renderHeaderRight({ createdAt })}
  </Row>
);

export { NotificationHeader };
export type { NotificationHeaderProps };
