import { setContext } from "@apollo/client/link/context";
import { authClient } from "app/use-auth";

export const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: await authClient.getAuthorizationHeader(),
    },
  };
});
