import { useDripsyTheme, View, styled, ActivityIndicator } from "dripsy";
import { Feather } from "@expo/vector-icons";
import { Tooltip } from "native-base";
import { ProfileCompletionQuery } from "app/types/generated/schema";
import { Avatar, convertNativeBaseSize } from "app/design/avatar";
import { FileUploadWrapper } from "app/components/file-upload-wrapper";
import { ProgressCircle } from "./progress-circle";
import { useUploadProfilePhoto } from "./use-upload-profile-photo";

const CameraWrapper = styled(View)({
  position: "absolute",
  top: -35,
  left: 70,
  borderWidth: 1,
  borderColor: "$cream",
  borderRadius: 16,
  backgroundColor: "$black",
  justifyContent: "center",
  alignItems: "center",
  width: 32,
  height: 32,
  shadowColor: "$cream",
  shadowOffset: { width: 1, height: 1 },
  shadowOpacity: 0.1,
  shadowRadius: 1,
});

interface ProfileAvatarProps {
  currentUser: ProfileCompletionQuery["me"];
  percentage: number;
}

const AVATAR_SIZE = 104;
const CONVERTED_AVATAR_SIZE = convertNativeBaseSize(AVATAR_SIZE);

const ACCEPT_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];

const ProfileAvatar = ({ currentUser, percentage }: ProfileAvatarProps) => {
  const { theme } = useDripsyTheme();
  const offsetTop = CONVERTED_AVATAR_SIZE / 2;
  const [upload, { loading }] = useUploadProfilePhoto();

  return (
    <View
      sx={{
        height: CONVERTED_AVATAR_SIZE,
        width: CONVERTED_AVATAR_SIZE,
        borderRadius: CONVERTED_AVATAR_SIZE / 2,
        bg: "$white",
        mb: "$3",
        mt: [0, null, null, 0 - offsetTop],
      }}
    >
      <Avatar
        sx={{ top: 2, left: 2 }}
        size={AVATAR_SIZE - 4}
        userId={currentUser.id}
        photoUrl={!loading ? currentUser.photoUrl : null}
      >
        {loading ? <ActivityIndicator color="$blue" /> : null}
      </Avatar>
      <ProgressCircle
        sx={{ position: "absolute", top: 0, left: 0 }}
        size={CONVERTED_AVATAR_SIZE}
        percent={percentage}
      />
      <FileUploadWrapper
        onUpload={(file) => upload({ file })}
        acceptTypes={ACCEPT_TYPES}
      >
        <Tooltip
          label={`${
            !!currentUser.photoUrl ? "Change" : "Upload"
          } profile photo`}
        >
          <CameraWrapper>
            <Feather name="camera" size={16} color={theme.colors.$cream} />
          </CameraWrapper>
        </Tooltip>
      </FileUploadWrapper>
    </View>
  );
};

export { AVATAR_SIZE, CONVERTED_AVATAR_SIZE, ProfileAvatar };
