import { Platform } from "react-native";

const rootFontName = "Work Sans";
const headingFontName = "TT Norms Pro";

const webFont = (
  font: string,
  modifier?: string // used by native only as we need to specify different font weights
) =>
  Platform.select({
    web: `${font}, Geneva, Verdana, sans-serif`,
    default: `${font}${modifier ? " " + modifier : ""}`,
  });

// https://www.dripsy.xyz/usage/theming/create
const customFonts = {
  [rootFontName]: {
    bold: webFont(rootFontName, "SemiBold"),
    default: webFont(rootFontName),
    normal: webFont(rootFontName),
    "400": webFont(rootFontName),
    "500": webFont(rootFontName, "Medium"),
    "600": webFont(rootFontName, "SemiBold"),
    "700": webFont(rootFontName, "SemiBold"),
    "800": webFont(rootFontName, "SemiBold"),
    "900": webFont(rootFontName, "SemiBold"),
  },
  [headingFontName]: {
    bold: webFont(headingFontName),
    default: webFont(headingFontName),
    normal: webFont(headingFontName),
    "400": webFont(headingFontName),
    "500": webFont(headingFontName),
    "600": webFont(headingFontName),
    "700": webFont(headingFontName),
    "800": webFont(headingFontName),
    "900": webFont(headingFontName),
  },
};

const fonts = {
  root: rootFontName,
  heading: headingFontName,
};

export { customFonts, fonts, rootFontName, headingFontName };
