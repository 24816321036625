import { Sx, View, useDripsyTheme } from "dripsy";
import { MigrateStonesSolid } from "app/design/svgs/migrate-stones";
import { Text } from "./text";

type UserType =
  | "AccountManager"
  | "Candidate"
  | "CandidateV2"
  | "Staff"
  | "TeamMember";

type ColorConfig = {
  [__typename in UserType | "default"]: string;
};

interface UsernameProps {
  sx?: Sx;
  user?: {
    __typename?: string;
    displayName?: string;
  } | null;
  colorConfig?: Partial<ColorConfig>;
}

const Username = ({ sx = {}, user, ...props }: UsernameProps) => {
  const { theme } = useDripsyTheme();

  const colorConfig: Partial<ColorConfig> = {
    Staff: "$blue",
    default: "$black",
    ...props.colorConfig,
  };

  const color =
    theme.colors[
      colorConfig[
        (user?.__typename || "default") as keyof ColorConfig
      ] as keyof typeof theme.colors
    ] || theme.colors.$black;

  return (
    <Text
      sx={{
        ...sx,
        color,
      }}
    >
      {user?.displayName || "Unknown user"}
      {user?.__typename === "Staff" && (
        <View sx={{ pl: 4, width: [20, 24], top: 2 }}>
          <MigrateStonesSolid fill={color} />
        </View>
      )}
    </Text>
  );
};

export type { UsernameProps };
export { Username };
