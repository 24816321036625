import { brandColors, themeColors } from "../colors";

const dropdownMenu = {
  activeSurfaceColor: brandColors.cream,
  item: {
    iconWidth: 25,
    px: "$3",
    py: "$2",
    content: {
      color: {
        destructive: themeColors.$error,
        default: themeColors.$text,
      },
    },
  },
};

export { dropdownMenu };
