import { styled, SxProp, useDripsyTheme, View } from "dripsy";
import { MotiView } from "moti";
import { ViewProps } from "react-native";
import { MigrateHorizontalLogo } from "app/design/svgs/migrate-horizontal-logo";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";

const DripsyMotiView = styled(MotiView)();

const MigrateLogo = () => {
  const LOGO_SIZE = {
    width: [106, 117] as const,
    height: [20, 22] as const,
  };

  return (
    <View
      sx={{
        ...(LOGO_SIZE as object),

        left: [0, "50%"],
        marginLeft: [0, 0 - LOGO_SIZE.width[1] / 2],
        position: ["relative", "absolute"],
      }}
    >
      <MigrateHorizontalLogo />
    </View>
  );
};

interface HeaderProps extends ViewProps {
  sx?: SxProp;
  stepProgress: number;
}

const Header = ({ children, sx, stepProgress, ...props }: HeaderProps) => {
  const safeArea = useSafeArea();
  const { theme } = useDripsyTheme();

  const PROGRESS_BAR_HEIGHT = 10;

  return (
    <View
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomWidth: PROGRESS_BAR_HEIGHT,
        borderBottomColor: "$grey",

        p: children ? "$4" : 0,
        pt: safeArea.top + (children ? theme.space.$4 : 0),

        ...sx,
      }}
      {...props}
    >
      {children}
      <DripsyMotiView
        animate={{
          width: `${stepProgress * 100}%`,
        }}
        sx={{
          position: "absolute",
          bottom: 0 - PROGRESS_BAR_HEIGHT,
          left: 0,
          width: 0,
          height: PROGRESS_BAR_HEIGHT,
          backgroundColor: "$blue",
        }}
      />
    </View>
  );
};

Header.MigrateLogo = MigrateLogo;

export { Header };
export type { HeaderProps };
