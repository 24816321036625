import { View } from "dripsy";
import { gql } from "@apollo/client";
import { Avatar } from "app/design/avatar";
import { SpeechBubbleIcon } from "app/design/svgs/icons/speech-bubble";
import { useToast } from "app/components/use-toast";
import {
  CandidateQuestionAnsweredNotification,
  Notification,
  NotificationType,
  QuestionAnsweredFieldsFragment,
} from "app/types";
import { pagesPath } from "app/lib/$path";
import {
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
} from "./common";
import { NotificationHeader } from "./header";
import {
  NotificationCardWrapper,
  NotificationCardWrapperProps,
} from "./wrapper";

interface QuestionAnsweredCardProps
  extends Omit<NotificationCardWrapperProps, "children" | "href"> {
  notification: Pick<
    CandidateQuestionAnsweredNotification,
    "createdAt" | "id"
  > &
    QuestionAnsweredFieldsFragment;
}

const QuestionAnsweredCard = ({
  notification,
  renderHeaderRight,
  ...rest
}: QuestionAnsweredCardProps) => {
  const toast = useToast();
  const question = notification.answer.question;
  const questionExists = !!question;
  return (
    <NotificationCardWrapper
      notification={notification}
      href={
        questionExists
          ? pagesPath.community.questions._questionId(question.id).$url()
          : undefined
      }
      onPress={() => {
        if (questionExists) return;
        toast.show({
          status: "error",
          title: "This question was deleted",
        });
      }}
      {...rest}
    >
      <View>
        <Avatar size={12} photoUrl={notification.answer.author.photoUrl} />
        <Badge icon={<SpeechBubbleIcon />} />
      </View>
      <NotificationContent>
        <NotificationHeader
          createdAt={notification.createdAt}
          renderHeaderRight={renderHeaderRight}
        >
          <Highlight>{notification.answer.author.displayName}</Highlight>{" "}
          answered your question{" "}
          <Highlight>
            &ldquo;{notification.answer.question?.title || "Deleted"}&rdquo;
          </Highlight>
        </NotificationHeader>
        <NotificationBody>{notification.answer.content}</NotificationBody>
      </NotificationContent>
    </NotificationCardWrapper>
  );
};

QuestionAnsweredCard.fragment = gql`
  fragment QuestionAnsweredFields on CandidateQuestionAnsweredNotification {
    answer {
      id
      author {
        id
        displayName
        photoUrl
      }
      question {
        id
        title
      }
      content
    }
  }
`;

QuestionAnsweredCard.typeguard = (
  notification: Pick<Notification, "type">
): notification is CandidateQuestionAnsweredNotification =>
  notification.type === NotificationType.CandidateQuestionAnswered;

export { QuestionAnsweredCard };
