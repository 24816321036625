import { gql } from "@apollo/client";
import { add } from "date-fns";
import { useAsyncToast } from "app/components/use-toast";
import {
  useMuteAllChannelsMutation,
  useUnmuteAllChannelsMutation,
} from "app/types";

const MUTE_ALL_CHANNELS_MUTATION = gql`
  mutation muteAllChannels($input: MuteAllChannelsInput!) {
    muteAllChannels(input: $input) {
      id
      notificationsMutedUntil
    }
  }
`;

const UNMUTE_ALL_CHANNELS_MUTATION = gql`
  mutation unmuteAllChannels {
    unmuteAllChannels {
      id
      notificationsMutedUntil
    }
  }
`;

const useAllChannelsMute = () => {
  const [muteChannels] = useMuteAllChannelsMutation();
  const [unmuteChannels] = useUnmuteAllChannelsMutation();

  const withMuteToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't mute all channels",
    },
  });

  const withUnmuteToast = useAsyncToast({
    success: null,
    error: {
      title: "Couldn't unmute all channels",
    },
  });

  const muteAllChannels = (minutes: number | null) =>
    withMuteToast(async () => {
      const until = minutes ? add(new Date(), { minutes }) : null;
      await muteChannels({
        variables: { input: { until } },
      });
    });

  const unmuteAllChannels = withUnmuteToast(async () => {
    await unmuteChannels();
  });

  return {
    muteAllChannels,
    unmuteAllChannels,
  };
};

export {
  MUTE_ALL_CHANNELS_MUTATION,
  UNMUTE_ALL_CHANNELS_MUTATION,
  useAllChannelsMute,
};
