import { Row, Text } from "dripsy";
import { MotiPressableProps } from "moti/interactions";

import { AnimatedArrow } from "app/components/animated-arrow";
import { FCC } from "app/types/index";

export const QuestionLink: FCC<MotiPressableProps> = ({
  children,
  ...props
}) => {
  return (
    <Row sx={{ alignItems: "center" }} {...props}>
      <Text
        variant="button"
        sx={{ color: "$blue", mr: "$2", fontWeight: "500" }}
        // TODO: Remove when dripsy fixes fontWeight issue
        style={{ fontWeight: "500" }}
      >
        {children || "View question"}
      </Text>
      <AnimatedArrow />
    </Row>
  );
};
