import { gql } from "@apollo/client";
import { H4, P } from "dripsy";
import {
  ChannelsOrderBy,
  ChannelType,
  useCommunityHomeChannelsQuery,
} from "app/types/generated/schema";
import { ButtonLink } from "app/design/button";
import { Card } from "app/design/card";
import { Grid, GridItem } from "app/design/grid";
import { DataError } from "app/components/data-error";
import { FCC } from "app/types";
import { pagesPath } from "app/lib/$path";
import { ProtectCommunityFeature } from "../../protect-feature";
import {
  JoinedChannelCard,
  JoinedChannelCardLoading,
} from "./joined-channel-card";

export const COMMUNITY_HOME_CHANNELS_QUERY = gql`
  query CommunityHomeChannels(
    $size: Int
    $after: String
    $orderBy: ChannelsOrderBy
    $where: JoinedChannelsWhereInput
  ) {
    joinedChannels(
      where: $where
      orderBy: $orderBy
      _size: $size
      _cursor: $after
    ) {
      data {
        id
        ...JoinedCommunityChannel
      }
      after
    }
  }
  ${JoinedChannelCard.fragments.channel}
`;

const JoinedChannelsGrid: FCC = ({ children }) => (
  <Grid gutter={16}>{children}</Grid>
);

const JoinedChannelsGridItem: FCC = ({ children }) => (
  <GridItem widths={[1, 1 / 2]}>{children}</GridItem>
);

const JoinedChannelsLoading = () => (
  <JoinedChannelsGrid>
    {Array.from({ length: 4 }, (_, i) => (
      <JoinedChannelsGridItem key={i}>
        <JoinedChannelCardLoading />
      </JoinedChannelsGridItem>
    ))}
  </JoinedChannelsGrid>
);

const JoinedChannels: React.FC = () => {
  const { data, loading, error } = useCommunityHomeChannelsQuery({
    variables: {
      where: { type: ChannelType.Community },
      orderBy: ChannelsOrderBy.UpdatedDesc,
    },
  });

  if (loading) return <JoinedChannelsLoading />;
  if (error || !data) return <DataError sx={{ mb: "$4" }} />;

  return data.joinedChannels.data.length > 0 ? (
    <JoinedChannelsGrid>
      {data.joinedChannels.data.map(({ id }) => (
        <JoinedChannelsGridItem key={id}>
          <JoinedChannelCard channelId={id} />
        </JoinedChannelsGridItem>
      ))}
    </JoinedChannelsGrid>
  ) : (
    <Card sx={{ alignItems: "center", mb: "$4" }}>
      <H4 sx={{ color: "$text", my: "$3", textAlign: "center" }}>
        No channels joined yet!
      </H4>
      <ProtectCommunityFeature.JoinChannels>
        <ButtonLink
          variant="link"
          containerSx={{ alignItems: "center" }}
          href={pagesPath.community.channels.all.$url()}
        >
          Explore our community channels
        </ButtonLink>
      </ProtectCommunityFeature.JoinChannels>
    </Card>
  );
};

export { JoinedChannels };
