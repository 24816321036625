import React from "react";
import { format } from "date-fns";
import { gql } from "@apollo/client";
import { Skeleton, Tooltip } from "native-base";
import { Feather } from "@expo/vector-icons";
import { Row, View } from "dripsy";
import { Scalars, useAnswerQuery, VoteType } from "app/types/generated/schema";
import { IconButton } from "app/design/icon-button";
import { QueryWrapper } from "app/components/query-wrapper";
import { Text } from "app/components/text";
import { HyperlinkText } from "app/components/hyperlink-text";
import { useCurrentUser } from "app/hooks";
import {
  Card,
  Footer,
  Content,
  CardProps,
  PressableCard,
  PressableCardProps,
} from "./card";
import { VoteButtons } from "./vote-buttons";
import { UserHeader, UserHeaderLoading } from "./user-header";
import { useDeleteAnswer } from "./hooks/use-delete";

const ANSWER_FRAGMENT = gql`
  fragment AnswerFragment on Answer {
    id
    createdAt
    author {
      ...UserHeaderFragment
    }
    content
    upvotes
    downvotes
    myVoteType
  }
  ${UserHeader.fragments.user}
`;

const ANSWER_QUERY = gql`
  ${ANSWER_FRAGMENT}
  query Answer($answerId: MigrateID!) {
    answer(id: $answerId) {
      ...AnswerFragment
    }
  }
`;

const formatAnswerDate = (date: string) =>
  format(new Date(date), "MMM d, yyyy");

interface BaseAnswerCardProps {
  footerAction?: React.ReactNode;
  showDate?: boolean;
  showDelete?: boolean;
}

interface AnswerCardContentProps extends CardProps, BaseAnswerCardProps {
  answerId: Scalars["MigrateID"];
  userId: Scalars["MigrateID"];
  createdAt: string;
  content: string;
  upvotes: number;
  downvotes: number;
  myVoteType?: VoteType | null;
}

const AnswerCardContent: React.FC<AnswerCardContentProps> = ({
  answerId,
  userId,
  footerAction,
  showDate = false,
  showDelete = false,
  createdAt,
  content,
  upvotes,
  downvotes,
  myVoteType,
}) => {
  const { currentUser } = useCurrentUser();
  const deleteAnswer = useDeleteAnswer(answerId);
  return (
    <>
      {(showDate || showDelete) && (
        <Row sx={{ mb: "$2", justifyContent: "space-between" }}>
          {showDate && (
            <Text variant="caption">{formatAnswerDate(createdAt)}</Text>
          )}
          {showDelete && currentUser?.id === userId && (
            <Tooltip label="Delete">
              <View>
                <IconButton
                  icon={<Feather name="trash" size={20} />}
                  onPress={deleteAnswer}
                  accessibilityLabel="Delete question"
                  size="sm"
                  variant="ghost"
                />
              </View>
            </Tooltip>
          )}
        </Row>
      )}
      <UserHeader userId={userId} />
      <Content>
        <HyperlinkText
          // Make links clickable in top-answer PressableCards
          linkSx={{ position: "relative", zIndex: 1 }}
        >
          {content}
        </HyperlinkText>
      </Content>
      <Footer>
        <VoteButtons
          answerId={answerId}
          upvotes={upvotes}
          downvotes={downvotes}
          myVoteType={myVoteType}
        />
        {footerAction}
      </Footer>
    </>
  );
};

const AnswerCardLoadingContent = () => (
  <>
    <UserHeaderLoading />
    <Content>
      <Skeleton.Text lines={2} />
    </Content>
  </>
);

const AnswerCardLoading: React.FC<CardProps> = ({ ...cardProps }) => (
  <Card {...cardProps}>
    <AnswerCardLoadingContent />
  </Card>
);

interface AnswerProps extends PressableCardProps, BaseAnswerCardProps {
  answerId: Scalars["MigrateID"];
}

const AnswerComponent = ({
  answerId,
  footerAction,
  showDate,
  showDelete = false,
  ...cardProps
}: AnswerProps) => {
  const queryResult = useAnswerQuery({ variables: { answerId } });

  const CardComponent =
    cardProps.href || cardProps.onPress ? PressableCard : Card;

  return (
    <CardComponent {...cardProps}>
      <QueryWrapper
        queryHookResult={queryResult}
        loaderComponent={() => <AnswerCardLoadingContent />}
      >
        {({ data }) => {
          const { answer } = data;
          return (
            <AnswerCardContent
              key={answer.id}
              answerId={answer.id}
              content={answer.content}
              createdAt={answer.createdAt}
              userId={answer.author.id}
              footerAction={footerAction}
              showDate={showDate}
              showDelete={showDelete}
              upvotes={answer.upvotes}
              downvotes={answer.downvotes}
              myVoteType={answer.myVoteType}
            />
          );
        }}
      </QueryWrapper>
    </CardComponent>
  );
};

AnswerComponent.fragments = {
  answer: ANSWER_FRAGMENT,
};

export type { BaseAnswerCardProps, AnswerCardContentProps, AnswerProps };
export {
  ANSWER_QUERY,
  ANSWER_FRAGMENT,
  AnswerCardContent,
  AnswerCardLoadingContent,
  AnswerCardLoading,
  AnswerComponent as Answer,
};
