import { useModal } from "react-native-modalfy";
import { ReactElement } from "react";
import { useRouter } from "solito/router";
import { useCurrentUser, useRedirect } from "app/hooks";
import { useAuth } from "app/use-auth";
import { pagesPath } from "app/lib/$path";
import { DisableButtonWithTooltip } from "./disable-button-with-tooltip";

interface ProtectFeatureProps {
  label?: string;
  children: ReactElement;
}

const ProtectFeature = ({ label, children }: ProtectFeatureProps) => {
  const { isVerified } = useCurrentUser();
  const { openModal } = useModal();

  return (
    <DisableButtonWithTooltip
      when={!isVerified}
      tooltip={label || "Verify your account to access this feature!"}
      overrideOnPress={() => openModal("VerifyAccountModal", undefined)}
    >
      {children}
    </DisableButtonWithTooltip>
  );
};

const LoginToAccess = ({ label, children }: ProtectFeatureProps) => {
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const { pathWithRedirectToCurrentLocation } = useRedirect();

  return (
    <DisableButtonWithTooltip
      when={!isAuthenticated}
      tooltip={label || "Log in or sign up to access this feature!"}
      overrideOnPress={() =>
        router.push(
          pathWithRedirectToCurrentLocation(pagesPath.welcome.$url().pathname)
        )
      }
    >
      {children}
    </DisableButtonWithTooltip>
  );
};

export { ProtectFeature, LoginToAccess };
export type { ProtectFeatureProps };
