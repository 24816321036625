import { forwardRef } from "react";
import { View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Button } from "app/design/button";
import { SortFilterOption } from "./types";

export const SortFilterTrigger = forwardRef<
  View,
  {
    orderBy: SortFilterOption;
    onPress?: () => void;
  }
>(({ orderBy, ...rest }, ref) => (
  <Button
    ref={ref}
    variant="ui"
    icon={<Feather name="chevron-down" size={24} />}
    iconPosition="right"
    containerSx={{ mb: [0, null, null, "$4"] }}
    {...rest}
  >
    {orderBy}
  </Button>
));
SortFilterTrigger.displayName = "SortFilterTrigger";

export const TopicsFilterTrigger = forwardRef<
  View,
  {
    loading?: boolean;
    onPress?: () => void;
  }
>(({ loading, ...rest }, ref) => (
  <Button
    ref={ref}
    variant="ui"
    icon={<Feather name="filter" size={24} />}
    loading={loading}
    {...rest}
  >
    Topics
  </Button>
));
TopicsFilterTrigger.displayName = "TopicsFilterTrigger";
