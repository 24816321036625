import {
  ActivityIndicator as DripsyActivityIndicator,
  useDripsyTheme,
  DripsyFinalTheme,
  Row,
} from "dripsy";
import { ComponentProps } from "react";
import { Text } from "app/components/text";

type ActivityIndicatorProps = Omit<
  ComponentProps<typeof DripsyActivityIndicator>,
  "color"
> & {
  color?: keyof DripsyFinalTheme["colors"];
};

const ActivityIndicator = ({
  color: _color = "$blue",
  ...props
}: ActivityIndicatorProps) => {
  const { theme } = useDripsyTheme();
  const color = theme.colors[_color];
  return <DripsyActivityIndicator color={color} {...props} />;
};

interface ActivityMessageProps extends ActivityIndicatorProps {
  children?: string;
}

const ActivityMessage = ({ children }: ActivityMessageProps) => (
  <Row>
    <Text variant="p">{children}</Text>
    <ActivityIndicator sx={{ ml: "$2" }} />
  </Row>
);

export type { ActivityIndicatorProps, ActivityMessageProps };
export { ActivityIndicator, ActivityMessage };
