import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 32;
const originalHeight = 24;
const aspectRatio = originalWidth / originalHeight;

const MigrateStones = ({ width, height, ...rest }: SvgProps) => (
  <View style={{ width, height, aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...rest}
    >
      <Path
        d="M14.6504 3.72119C9.92975 3.72119 7.17773 5.20657 7.17773 6.65274C7.17773 8.13302 9.69575 9.644 15.8696 9.644C21.2091 9.644 25.0904 8.85441 25.0904 7.41165C25.0904 5.62782 19.9933 3.72119 14.6504 3.72119Z"
        fill="#FFB5A4"
      />
      <Path
        d="M18.4281 10.9556C7.47096 10.9556 0 14.6784 0 18.0449C0 22.1156 9.18694 23.8432 17.4175 23.8432C25.9212 23.8432 32 22.2487 32 18.3996C31.9932 15.306 27.8135 10.9556 18.4281 10.9556Z"
        fill="#305ED9"
      />
      <Path
        d="M16.3172 0C18.5012 0 21.0649 0.530417 21.0649 1.50935C21.0649 2.16084 19.9187 2.75945 17.4244 2.75945C13.5245 2.75945 11.0488 2.18471 11.0488 1.51787C11.0421 0.533815 14.1145 0 16.3172 0Z"
        fill="#FF9200"
      />
    </Svg>
  </View>
);

const MigrateStonesSolid = (props: SvgProps) => (
  <View style={{ aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="#3A2F2E"
      {...props}
    >
      <Path d="M14.6504 3.72119C9.92975 3.72119 7.17773 5.20657 7.17773 6.65274C7.17773 8.13302 9.69575 9.644 15.8696 9.644C21.2091 9.644 25.0904 8.85441 25.0904 7.41165C25.0904 5.62782 19.9933 3.72119 14.6504 3.72119Z" />
      <Path d="M18.4281 10.9556C7.47096 10.9556 0 14.6784 0 18.0449C0 22.1156 9.18694 23.8432 17.4175 23.8432C25.9212 23.8432 32 22.2487 32 18.3996C31.9932 15.306 27.8135 10.9556 18.4281 10.9556Z" />
      <Path d="M16.3172 0C18.5012 0 21.0649 0.530417 21.0649 1.50935C21.0649 2.16084 19.9187 2.75945 17.4244 2.75945C13.5245 2.75945 11.0488 2.18471 11.0488 1.51787C11.0421 0.533815 14.1145 0 16.3172 0Z" />
    </Svg>
  </View>
);

export { MigrateStones, MigrateStonesSolid };
