import { forwardRef } from "react";
import { FieldValues } from "react-hook-form";
import { DatePicker, DatePickerProps } from "app/design/date-picker";
import { FormControl, FormControlProps } from "./form-control";

export interface InputControlProps<T extends FieldValues>
  extends Omit<FormControlProps<T>, "children"> {
  inputProps?: Omit<DatePickerProps, "value" | "onChange">;
}

export const DatePickerControl = forwardRef(
  <T extends FieldValues>(props: InputControlProps<T>, _ref: any) => {
    const { inputProps, ...rest } = props;

    return (
      <FormControl {...rest}>
        <DatePicker {...inputProps} />
      </FormControl>
    );
  }
);
DatePickerControl.displayName = "DatePickerControl";
