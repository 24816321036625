import * as React from "react";
import { View } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const originalWidth = 17;
const originalHeight = 24;
const aspectRatio = originalWidth / originalHeight;

const AvatarIcon = ({
  width = originalWidth,
  headFill = "#FFB5A4",
  bodyFill = "#C3B6BC",
  ...rest
}: SvgProps & { headFill?: string; bodyFill?: string }) => (
  <View style={{ width, aspectRatio }}>
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      fill="none"
      {...rest}
    >
      <Path
        d="M15.9406 23.8605C16.0885 23.8611 16.235 23.8292 16.3704 23.7668C16.5058 23.7044 16.6271 23.6129 16.7265 23.4982C16.8259 23.3836 16.9012 23.2483 16.9473 23.1012C16.9935 22.9542 17.0096 22.7986 16.9945 22.6446C16.6102 18.9788 14.6545 15.6745 12.1367 13.4256C12.1244 13.4135 12.1121 13.4028 12.0999 13.3935C11.0561 12.4674 9.79976 11.6686 8.23977 11.6259C3.59149 11.5018 0.747525 18.7008 0.0902026 22.1399C0.0626012 22.2864 0.0370444 22.4318 0.0135321 22.5772C-0.0109244 22.7357 -0.00215605 22.8978 0.0392293 23.0523C0.0806147 23.2068 0.153628 23.3501 0.253207 23.4721C0.352786 23.5942 0.476551 23.6922 0.615921 23.7592C0.755292 23.8263 0.906935 23.8608 1.06034 23.8605H15.9406Z"
        fill={bodyFill}
      />
      <Path
        d="M7.72718 0.91748C5.03415 0.91748 3.09082 3.25573 3.09082 5.72971C3.09082 8.27361 4.84265 10.0947 7.53459 10.0947C10.2907 10.0947 12.3635 8.43505 12.3635 5.82328C12.3635 3.28247 10.4518 0.91748 7.72718 0.91748Z"
        fill={headFill}
      />
    </Svg>
  </View>
);

export { AvatarIcon };
