import { useDeleteMessage, UseDeleteMessageProps } from "../../hooks";
import {
  ClosingActionsheetItem,
  ClosingActionsheetItemProps,
} from "./action-sheet-item";

type DeleteMessageItemProps = Omit<
  ClosingActionsheetItemProps,
  "onPress" | "iconName" | "children"
> &
  UseDeleteMessageProps;

const DeleteMessageItem = ({ message, ...props }: DeleteMessageItemProps) => {
  const deleteMessage = useDeleteMessage({ message });

  return (
    <ClosingActionsheetItem {...props} iconName="trash" onPress={deleteMessage}>
      Delete
    </ClosingActionsheetItem>
  );
};

export type { DeleteMessageItemProps };
export { DeleteMessageItem };
