import { gql } from "@apollo/client";

export const JOB_MATCH_ACTION_MATCH_RESPONSE_PROPS = gql`
  fragment JobMatchActionMatchResponseProps on MatchResponse {
    id
    createdAt
    type
    candidate {
      id
    }
    job {
      id
      myMatchResponse {
        id
        type
        updatedAt
      }
    }
  }
`;
