import { Row, styled, useDripsyTheme, View } from "dripsy";
import { MotiView } from "moti";

import { useOnboardingQuery } from "app/types/generated/schema";
import { useRedirect } from "app/hooks/use-redirect";
import { ButtonLink } from "app/design/button";
import { Carousel } from "app/components/carousel";
import { WelcomeLogo } from "app/components/welcome-logo";
import { useSafeArea } from "app/provider/safe-area/use-safe-area";
import { pagesPath } from "app/lib/$path";

import { Background } from "../layout/background";
import { FloatyStones } from "./stones";

const DripsyMotiView = styled(MotiView)();

const MAX_BUTTONS_WIDTH = 500;

export function WelcomeScreen() {
  const safeArea = useSafeArea();
  const { theme } = useDripsyTheme();
  const { redirect, pathWithRedirect } = useRedirect();

  useOnboardingQuery();

  return (
    <Background
      sx={{
        flex: 1,
        alignItems: "center",
        pt: [safeArea.top + theme.space.$6],
        pb: [
          safeArea.bottom + theme.space.$4,
          safeArea.bottom + theme.space.$6,
        ],
        overflow: "hidden",
      }}
    >
      <FloatyStones />
      <View
        sx={{
          width: "100%",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <WelcomeLogo from={{ opacity: 0 }} animate={{ opacity: 1 }} />
        <Carousel />
        <View sx={{ px: "$4", width: "100%", alignItems: "center" }}>
          <DripsyMotiView
            sx={{ width: "100%", maxWidth: MAX_BUTTONS_WIDTH }}
            from={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
          >
            <Row sx={{ ml: 0 - theme.space.$4 }}>
              <ButtonLink
                containerSx={{ ml: "$4", flexGrow: 1 }}
                href={pagesPath.register._step("profession").$url()}
                size="lg"
              >
                Sign up
              </ButtonLink>
              <ButtonLink
                containerSx={{ ml: "$4", flexGrow: 1 }}
                href={
                  redirect
                    ? pathWithRedirect(
                        pagesPath.login.$url().pathname,
                        redirect
                      )
                    : pagesPath.login.$url()
                }
                variant="secondary"
                size="lg"
              >
                Log in
              </ButtonLink>
            </Row>
          </DripsyMotiView>
        </View>
      </View>
    </Background>
  );
}
