import { memo } from "react";
import isEqual from "react-fast-compare";
import { ProfileCard } from "../../card";
import { useRegistrationDetailMutations } from "../hooks/use-registration-detail-mutations";
import { isTempField } from "../profile-form";
import {
  RegistrationDetailFormCard,
  RegistrationDetailFormValues,
} from "./form-card";

interface RegistrationDetailCardProps {
  field: RegistrationDetailFormValues;
  country: string;
  onCancel: () => void;
}

export const RegistrationDetailCard = memo(
  ({ field, country, onCancel }: RegistrationDetailCardProps) => {
    const { id, referenceNumber } = field;
    const { deleteRegistrationDetail } = useRegistrationDetailMutations(id);
    const completed = !isTempField({ id });

    return (
      <ProfileCard
        header={country}
        subheader={referenceNumber || "In progress"}
        form={
          <RegistrationDetailFormCard
            defaultValues={field}
            onCancel={onCancel}
          />
        }
        onDelete={deleteRegistrationDetail}
        showFormOnRender={!completed}
      />
    );
  },
  (a, b) => isEqual(a, b)
);
RegistrationDetailCard.displayName = "RegistrationDetailCard";
