import { memo } from "react";
import isEqual from "react-fast-compare";
import { ProfileCard } from "app/features/profile/card";
import {
  ApplicationQuestionFormCard,
  ApplicationQuestionFormValues,
} from "./form-card";
import { useDeleteApplicationQuestionAnswer } from "./use-application-question-mutations";

interface ApplicationQuestionCardProps {
  matchResponseId: string;
  question: string;
  field: ApplicationQuestionFormValues;
  onCancel: () => void;
}

export const ApplicationQuestionCard = memo(
  ({
    matchResponseId,
    question,
    field,
    onCancel,
  }: ApplicationQuestionCardProps) => {
    const { response } = field;

    const deleteApplicationQuestionAnswer =
      useDeleteApplicationQuestionAnswer(matchResponseId);

    return (
      <ProfileCard
        header={question}
        subheader={response}
        form={
          <ApplicationQuestionFormCard
            matchResponseId={matchResponseId}
            question={question}
            defaultValues={field}
            onCancel={onCancel}
          />
        }
        onDelete={deleteApplicationQuestionAnswer}
        showFormOnRender={!response}
      />
    );
  },
  (a, b) => isEqual(a.field, b.field)
);
ApplicationQuestionCard.displayName = "ApplicationQuestionCard";
