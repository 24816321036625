import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from "@apollo/client";

import { DocumentNode } from "graphql";

/**
 * Small wrapper around `useQuery` so that we can use it imperatively.
 * Workaround as useLazyQuery does not return a promise, which makes it a pain for chaining.
 * Note: refetch will ALWAYS bypass the cache. It does not care about your fetch option.
 *
 * @see Credit: https://github.com/apollographql/react-apollo/issues/3499#issuecomment-586039082
 *
 * @example
 * const callQuery = useImperativeQuery(query, options)
 * const handleClick = async () => {
 *   const{ data, error } = await callQuery()
 * }
 */

export function useImperativeQuery<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode,
  options: QueryHookOptions<TData, TVariables> = {}
): [QueryResult<TData, TVariables>["refetch"], QueryResult<TData, TVariables>] {
  const results = useQuery<TData, TVariables>(query, {
    ...options,
    skip: true,
  });
  const imperativelyCallQuery = (queryVariables: TVariables) => {
    return results.refetch(queryVariables);
  };
  return [imperativelyCallQuery, results];
}

export const createImperativeQuery =
  <TData, TVariables = OperationVariables>(query: DocumentNode) =>
  (options?: QueryHookOptions<TData, TVariables>) =>
    useImperativeQuery<TData, TVariables>(query, options);

export type AsImperativeQueryFunction<
  TData = any,
  TVariables = OperationVariables
> = (
  baseOptions: QueryHookOptions<TData, TVariables>
) => QueryResult<TData, TVariables>;

export const asImperativeQuery = <TData, TVariables = OperationVariables>(
  useGeneratedQuery: AsImperativeQueryFunction<TData, TVariables>,
  options: Omit<QueryHookOptions<TData, TVariables>, "skip"> = {}
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const results = useGeneratedQuery({
    ...options,
    skip: true,
  });
  const imperativelyCallQuery = (queryVariables?: TVariables) => {
    return results.refetch(queryVariables);
  };
  return [imperativelyCallQuery, results] as const;
};
