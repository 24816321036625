import { UrlObject } from "url";
import { styled, useDripsyTheme, View } from "dripsy";
import {
  MotiPressable,
  MotiPressableProps,
  useMotiPressable,
} from "moti/interactions";
import { MotiView } from "moti";
import { useLink } from "solito/link";

import { Platform, StyleSheet } from "react-native";
import { Hoverable } from "moti/build/interactions/pressable/hoverable";
import { useState } from "react";
import { FCC } from "app/types/index";
import { useIsLinkActive } from "app/hooks/use-is-link-active";
import { alphaColor } from "app/design/utils";
import { AbsoluteBadge, Badged, BadgedProps } from "app/design/badge";
import { ColorAnimatedView } from "app/components/color-animated-view";
import { ColorAnimatedIcon } from "app/components/color-animated-icon";
import { conditionalParam } from "app/utils";
import { Submenu, SubmenuConfig } from "./submenu";

const DripsyMotiPressable = styled(MotiPressable)();
const DripsyMotiView = styled(MotiView)();

const MenuItemText: FCC<{ active: boolean }> = ({ active, children }) => {
  const { theme } = useDripsyTheme();

  return (
    <ColorAnimatedView
      as="Text"
      colorStates={{
        color: {
          states: [
            [active, theme.colors.$blue],
            ["hovered", theme.colors.$black],
          ],
          initialColor: theme.colors.$text,
        },
      }}
      sx={{
        color: active ? theme.colors.$blue : theme.colors.$text,
        fontWeight: "500",
        fontSize: 16,
        lineHeight: 24,
        display: ["none", null, null, "flex"],
        ml: "$4",
      }}
    >
      {children}
    </ColorAnimatedView>
  );
};

interface MenuItemIconProps {
  active: boolean;
  icon: React.ReactElement;
}

const MenuItemIcon = ({ active, icon }: MenuItemIconProps) => {
  const { theme } = useDripsyTheme();

  return (
    <ColorAnimatedIcon
      icon={icon}
      colorStates={{
        color: {
          states: [[active, theme.colors.$blue]],
          initialColor: theme.colors.$black,
        },
      }}
      sx={{ color: active ? theme.colors.$blue : theme.colors.$black }}
    />
  );
};

const MenuItemBackground = ({ active }: { active?: boolean }) => {
  const { theme } = useDripsyTheme();
  const bodyState = useMotiPressable(({ hovered, pressed }) => {
    "worklet";
    return {
      opacity: hovered || pressed || active ? 1 : 0,
    };
  });

  return (
    <>
      <DripsyMotiView
        state={bodyState}
        sx={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: alphaColor(theme.colors.$blue, 0.1),
          borderWidth: 2,
          borderColor: alphaColor(theme.colors.$blue, 0.01),
          backdropFilter: Platform.OS === "web" ? "blur(5px)" : undefined,
          zIndex: 0,
        }}
      />
      <DripsyMotiView
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: -4,
          width: 4,
          backgroundColor: "$blue",
        }}
        transition={{
          type: "timing",
          duration: theme.transitionDurations.normal,
        }}
        animate={{
          translateX: active ? 4 : 0,
        }}
      />
    </>
  );
};

interface MenuItemProps extends Omit<MotiPressableProps, "href"> {
  icon: React.ReactElement;
  active?: boolean;
  badgeProps?: Omit<BadgedProps, "children">;
  submenuItems?: SubmenuConfig[];
}

const MenuItem: FCC<MenuItemProps> = ({
  children,
  icon,
  active = false,
  badgeProps = {},
  submenuItems = [],
  ...rest
}) => {
  const [hovered, setHovered] = useState(false);
  const { theme } = useDripsyTheme();

  return (
    <Hoverable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <View sx={{ mt: "$1" }}>
        <DripsyMotiPressable {...rest}>
          <Badged
            {...badgeProps}
            sx={{ display: ["flex", null, null, "none"], top: 4, ml: -20 }}
          >
            <View
              sx={{
                px: [0, null, null, "$6"],
                py: "$3",
                borderRadius: 6,
                overflow: "hidden",
                ...conditionalParam(
                  { clipPath: `inset(0 round 6px)` },
                  Platform.OS === "web"
                ),
              }}
            >
              <MenuItemBackground active={active} />
              <View
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: ["center", null, null, "flex-start"],
                  height: theme.space.$6,
                }}
              >
                <View
                  sx={{
                    width: 20,
                    height: 20,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MenuItemIcon active={active} icon={icon} />
                </View>
                <MenuItemText active={active}>{children}</MenuItemText>
                <View
                  sx={{
                    display: ["none", null, null, "flex"],
                    flexGrow: 1,
                  }}
                >
                  {!!badgeProps?.value ? (
                    <AbsoluteBadge
                      {...badgeProps}
                      sx={{
                        left: "auto",
                        right: 0,
                        ml: 0,
                      }}
                    >
                      {badgeProps.value}
                    </AbsoluteBadge>
                  ) : null}
                </View>
              </View>
            </View>
          </Badged>
        </DripsyMotiPressable>
        {submenuItems.length > 0 ? (
          <Submenu visible={hovered} items={submenuItems} />
        ) : null}
      </View>
    </Hoverable>
  );
};

interface MenuItemLinkProps extends MenuItemProps {
  href: UrlObject;
}

const MenuItemLink: FCC<MenuItemLinkProps> = ({ href, ...rest }) => {
  const linkProps = useLink({ href });
  const linkIsActive = useIsLinkActive(linkProps.href);
  const active = href ? linkIsActive : false;

  return <MenuItem {...linkProps} active={active} {...rest} />;
};

export { MenuItem, MenuItemLink };
