import { createContext, useContext, ReactNode } from "react";

export interface SliderContextValue {
  disableSwipeX?: [number, number];
  disableSwipeY?: [number, number];
}

const SliderScreenContext = createContext<SliderContextValue | undefined>(
  undefined
);

interface SliderScreenProviderProps extends SliderContextValue {
  children: ReactNode;
}

const SliderScreenProvider = ({
  disableSwipeX,
  disableSwipeY,
  children,
}: SliderScreenProviderProps) => {
  return (
    <SliderScreenContext.Provider
      value={{
        disableSwipeX,
        disableSwipeY,
      }}
    >
      {children}
    </SliderScreenContext.Provider>
  );
};

const useSliderContext = () => {
  const context = useContext(SliderScreenContext);
  if (context === undefined) {
    throw new Error("useSliderContext must be used within a SliderScreen");
  }
  return context;
};

export { SliderScreenProvider, useSliderContext };
