import { Platform } from "react-native";
import { ApolloClient, from, createHttpLink } from "@apollo/client";
import { typeDefs } from "app/graphql";
import { cache } from "../cache";
import { api } from "../config";
import { authLink } from "./links/auth";
import { errorLink } from "./links/error";

export const createApolloClient = () => {
  const uri = api.http;

  const apiLink = createHttpLink({
    uri,
  });

  if (Platform.OS === "web" && !process.browser)
    return new ApolloClient({ link: apiLink, cache });

  return new ApolloClient({
    link: from([authLink, errorLink, apiLink]),
    cache,
    typeDefs,
  });
};
