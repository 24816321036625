import { View } from "dripsy";
import { SpeechBubbleIcon } from "app/design/svgs/icons/speech-bubble";
import { Avatar } from "app/design/avatar";
import { pagesPath } from "app/lib/$path";
import { NewMessagePayloadFragment } from "app/types";
import {
  Badge,
  Highlight,
  NotificationBody,
  NotificationContent,
} from "../common";
import { NotificationHeader } from "../header";
import {
  MessageNotificationWrapper,
  MessageNotificationWrapperProps,
} from "./wrapper";

interface ApplicationDMMessageCardProps
  extends MessageNotificationWrapperProps {
  message: NewMessagePayloadFragment;
  renderHeaderRight: () => React.ReactElement;
}

const ApplicationDMMessageCard = ({
  message,
  renderHeaderRight,
  ...rest
}: ApplicationDMMessageCardProps) => {
  const { channel, content, author, createdAt } = message;

  return (
    <MessageNotificationWrapper
      href={pagesPath.inbox._channelId(channel.id).$url()}
      {...rest}
    >
      <View>
        <Avatar
          userId={author?.id}
          photoUrl={author?.photoUrl}
          isActive={author?.isActive}
          size={12}
        />
        <Badge icon={<SpeechBubbleIcon />} />
      </View>
      <NotificationContent>
        <NotificationHeader
          createdAt={createdAt}
          renderHeaderRight={renderHeaderRight}
        >
          <Highlight>{author?.displayName}</Highlight>
        </NotificationHeader>
        <NotificationBody>{content}</NotificationBody>
      </NotificationContent>
    </MessageNotificationWrapper>
  );
};

export { ApplicationDMMessageCard };
