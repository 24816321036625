import { Row, useDripsyTheme, View } from "dripsy";
import { Tooltip } from "native-base";
import { Feather } from "@expo/vector-icons";
import { format, isBefore } from "date-fns";
import { memo } from "react";
import {
  ChannelsOrderBy,
  ChannelType,
  Scalars,
  useJoinedChannelsQuery,
} from "app/types/generated/schema";
import { ButtonLink } from "app/design/button";
import { ShadowGradient } from "app/design/shadow-gradient";
import { IconButtonLink } from "app/design/icon-button";
import {
  MenuTriggerIconButton,
  TooltipMenuTriggerIconButton,
} from "app/components/menu-trigger";
import { Menu } from "app/components/menu";
import { pagesPath } from "app/lib/$path";

import { useCurrentUser } from "app/hooks";
import { ScreenComponent } from "app/navigation/types";
import { ChannelList } from "./channel/channel-list";
import { ChannelContainer, ChannelLayout } from "./channel-layout";

// TODO: Refactor - should be common module and not have deps between screens
import { renderChannelCard } from "./inbox-screen";
import { ChannelListEmpty } from "./channel/channel-list/channel-list-empty";
import { useAllChannelsMute } from "./use-all-channels-mute";

const CommunityChannelsEmpty = () => (
  <ChannelListEmpty
    icon="users"
    text="You haven't joined any community channels yet"
    footer={
      <ButtonLink
        variant="link"
        size="sm"
        containerSx={{ alignSelf: "center" }}
        href={pagesPath.community.channels.all.$url()}
      >
        Browse community channels
      </ButtonLink>
    }
  />
);

const MyCommunityChannels: React.FC<{
  selectedChannelId?: Scalars["MigrateID"];
}> = ({ selectedChannelId }) => {
  const queryResponse = useJoinedChannelsQuery({
    variables: {
      where: { type: ChannelType.Community },
      orderBy: ChannelsOrderBy.UpdatedDesc,
      size: 20,
    },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <ChannelList
      selectedChannelId={selectedChannelId}
      renderItem={renderChannelCard}
      queryHookResult={queryResponse}
      queryData={queryResponse.data?.joinedChannels}
      emptyListText="You haven't joined any community channels yet!"
      ListEmptyComponent={() => <CommunityChannelsEmpty />}
    />
  );
};

const CommunityChannelsListHeader = () => {
  const { theme } = useDripsyTheme();
  const { currentUser } = useCurrentUser();
  const { muteAllChannels, unmuteAllChannels } = useAllChannelsMute();

  const mutedUntil = currentUser?.notificationsMutedUntil || null;

  return (
    <Row
      sx={{
        borderBottomWidth: 2,
        borderColor: "$white",
        justifyContent: "flex-end",
        py: ["$2", null, "$5"],
        px: "$4",
      }}
    >
      <Tooltip label="Join more channels">
        <View>
          <IconButtonLink
            accessibilityLabel="Join more channels"
            href={pagesPath.community.channels.all.$url()}
            variant="ghost"
            size="sm"
            icon={<Feather name="plus" size={20} />}
          />
        </View>
      </Tooltip>
      <Menu
        trigger={(triggerProps) => (
          <TooltipMenuTriggerIconButton
            variant="ghost"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: theme.iconButton.sizes.sm.width,
              height: theme.iconButton.sizes.sm.height,
            }}
            label={mutedUntil ? "Unmute all channels" : "Mute all channels"}
            size="sm"
            icon={
              <Feather
                name={!!mutedUntil ? "bell-off" : "bell"}
                size={20}
                color={theme.colors.$black}
              />
            }
            {...triggerProps}
          />
        )}
        placement="bottom right"
      >
        {mutedUntil && isBefore(new Date(), new Date(mutedUntil)) ? (
          <Menu.Group
            title={`Muted until ${format(
              new Date(mutedUntil),
              "dd/MM h:mm a"
            )}`}
          >
            <Menu.Item onPress={unmuteAllChannels}>
              Unmute all channels
            </Menu.Item>
          </Menu.Group>
        ) : (
          <Menu.Group title="Mute all notifications">
            <Menu.Item onPress={muteAllChannels(15)}>For 15 minutes</Menu.Item>
            <Menu.Item onPress={muteAllChannels(60)}>For 1 hour</Menu.Item>
            <Menu.Item onPress={muteAllChannels(8 * 60)}>For 8 hours</Menu.Item>
            <Menu.Item onPress={muteAllChannels(24 * 60)}>
              For 24 hours
            </Menu.Item>
            <Menu.Item onPress={muteAllChannels(null)}>
              Until I turn it back on
            </Menu.Item>
          </Menu.Group>
        )}
      </Menu>
      <ShadowGradient />
    </Row>
  );
};

const headerProps = {
  title: "My channels",
  backHref: pagesPath.community.$url(),
  headerSx: {
    boxShadow: [null, "default"],
  },
};

const MyCommunityChannelsScreen: ScreenComponent = memo(
  () => (
    <ChannelContainer
      channelList={<MyCommunityChannels />}
      channelListHeader={<CommunityChannelsListHeader />}
    />
  ),
  () => true
);

MyCommunityChannelsScreen.displayName = "MyCommunityChannelsScreen";
MyCommunityChannelsScreen.headerProps = headerProps;
MyCommunityChannelsScreen.getLayout = (page: React.ReactElement) => {
  return (
    <ChannelLayout headerProps={headerProps} overridePathname="my-channels">
      {page}
    </ChannelLayout>
  );
};

export {
  MyCommunityChannelsScreen,
  MyCommunityChannels,
  CommunityChannelsListHeader,
};
